import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Input from "../../../../components/Input";
import Button from "../../../../components/ButtonSquare";
import TextareaWithValid from "../../../../components/TextArea";
import {
    addRequirementToTrip,
    selectedTripRequirement,
    updateTripRequirement,
    uploadTempFile,
} from "../../tripSlice";
import DatePickerMaterial from "../../../../components/DatePickerMaterial";
import { setCurrentSection } from "../../../layout/layoutSlice";
import { PAGE_SECTIONS } from "../../../../constants";
import moment from "moment";
import axios from "axios";
import FilePreview from "../../../../components/DropFileInput/FilePreview";
import DropFileInput from "../../../../components/DropFileInput/DropFileInput";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import fileImage from "../../../../assets/images/files.png";

const schema = yup
    .object({
        name: yup.string().required("This field is required"),
        description: yup.string().max(5000).required("This field is required"),
        deadline: yup.date().required("This field is required"),
    })
    .required("This field is required");

export default function TripRequirementForm() {
    const navigate = useNavigate();
    let { tripId, requirementId } = useParams();
    const requirement = useAppSelector(selectedTripRequirement);
    const [filesProgress, setFilesProgress] = useState<any[]>([]);
    const [tempFiles, setTempFiles] = useState<any[]>([]);
    const [deletedFilesState, setDeletedFilesState] = useState<any[]>([]);
    const [filesPreviews, setFilesPreviews] = useState<Array<any>>([]);
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            description: "",
            deadline: moment.utc().add(1, "day"),
        },
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_REQUIREMENTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (requirementId && requirement) {
            reset({
                name: requirement.name,
                description: requirement.description,
                deadline: moment.utc(requirement.deadline),
            });
            setFilesPreviews(
                Object.values(requirement.requirementIncludedFiles || {})
            );
        } else {
            const date = moment(moment.now()).utc().add(1, "day");
            reset({
                deadline: date,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requirement, reset]);

    const isUpdate = requirement && requirementId;
    const handleSubmitForm = async (data: any) => {
        const files = tempFiles.map((file) => file.path);
        const payload = {
            ...data,
        };
        if (!!files?.length) {
            payload["requirementIncludedFiles"] = files;
        }
        if (isUpdate) {
            if (!!deletedFilesState?.length) {
                payload["deletedFiles"] = deletedFilesState;
            }
            await dispatch(
                updateTripRequirement(
                    Number(tripId),
                    Number(requirementId),
                    payload
                )
            );
        } else {
            await dispatch(addRequirementToTrip(Number(tripId), payload));
        }

        goBack();
    };

    const goBack = () => {
        navigate(`/trips/${tripId}/requirements`);
    };

    const onFileProgress = (id: number, progress: number) => {
        setFilesProgress((prevFilePogressArray) => {
            return prevFilePogressArray.map((file) => {
                return {
                    ...file,
                    progress: id === file.id ? progress : file.progress,
                };
            });
        });
    };

    const removeTempFile = (id: number) => {
        let index = tempFiles.findIndex((img) => img.id === id);
        URL.revokeObjectURL(tempFiles[index].file);
        setTempFiles((prevTmpStateArray) => {
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const removeProgressFile = (id: any, cancel = false) => {
        setFilesProgress((prevTmpStateArray) => {
            const index = prevTmpStateArray.findIndex((file) => file.id === id);
            cancel && prevTmpStateArray[index]?.cancelToken.cancel();
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const onSelectFiles = (e: any) => {
        if (e.target.files) {
            Array.from(e.target.files).forEach(
                async (file: any, index: number) => {
                    const newtempFile = {
                        id: `${new Date().valueOf()}-${index}`,
                        file: file,
                        progress: 0,
                        cancelToken: axios?.CancelToken.source(),
                    };
                    setFilesProgress((prevArray) => {
                        return [...prevArray, newtempFile];
                    });

                    try {
                        const response = await uploadTempFile(
                            e.target.files[index],
                            newtempFile.id,
                            onFileProgress,
                            newtempFile?.cancelToken
                        );
                        setTempFiles((prev) => {
                            return [
                                ...prev,
                                {
                                    file: file,
                                    path: response,
                                    id: newtempFile.id,
                                },
                            ];
                        });
                    } catch (error) {
                    } finally {
                        removeProgressFile(newtempFile.id);
                    }
                }
            );
            return;
        }
    };

    const handleFileRemove = (index: number) => {
        setDeletedFilesState([
            ...deletedFilesState,
            filesPreviews[index].originalName,
        ]);
        const spliceArray = filesPreviews;
        spliceArray.splice(index, 1);

        setFilesPreviews([...spliceArray]);
    };

    return (
        <div className="mx-4">
            <div className="flex flex-col flex-wrap mb-6">
                <div className="text-left font-semibold text-lg flex-grow mb-4">
                    Add new document
                </div>
                <div className=" text-left font-medium text-sm text-neutral-800">
                    By creating this requirement, advisors will receive a
                    pending notification to upload the documents before the trip
                    begins.
                </div>
            </div>
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
                className="grid grid-cols-6 gap-4 items-center justify-center"
            >
                <div className="col-span-6 lg:col-span-3 flex flex-col">
                    <div className="col-span-6 lg:col-span-3 text-left lg:max-w-xs break-words mt-2">
                        <Input
                            markRequired
                            register={register}
                            label="Name"
                            type="text"
                            id="name"
                            name="name"
                            error={errors["name"]?.message}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-3 text-left lg:max-w-xs break-words mt-2">
                        <DatePickerMaterial
                            control={control}
                            minDate={moment(moment.now())
                                .add(1, "day")
                                .utc()
                                .startOf("day")
                                .toDate()}
                            name="deadline"
                            id="deadline"
                            label="Deadline"
                            error={errors["deadline"]?.message}
                        />
                    </div>
                </div>

                <div className="col-span-6 text-left break-words mt-2">
                    <TextareaWithValid
                        markRequired
                        register={register}
                        label="Description"
                        id="description"
                        name="description"
                        error={errors["description"]?.message}
                    />
                </div>
                <div className="col-span-6">
                    <div className="gap-x-12 flex-wrap mt-4 flex-grow">
                        <div className="text-left font-semibold text-sm text-neutral-800 mt-2">
                            Files
                        </div>
                        <div className="text-left font-medium text-xs text-neutral-800 my-4">
                            Add files or documents needed for this requirement
                        </div>
                        <div>
                            <DropFileInput
                                iconSrc={fileImage}
                                iconClass={"w-16"}
                                className={"!p-4"}
                                multiple={true}
                                onChange={onSelectFiles}
                            />
                        </div>
                        {filesProgress.map((file) => {
                            return (
                                <ProgressBar
                                    key={`fileProgress_${
                                        file.id || file?.file?.name
                                    }`}
                                    progress={file.progress}
                                    name={file.file.name}
                                    onRemove={() =>
                                        removeProgressFile(file.id, true)
                                    }
                                />
                            );
                        })}
                        <div className="flex flex-wrap gap-4 mt-4">
                            {tempFiles.map((file) => {
                                return (
                                    <FilePreview
                                        key={`filePrev_${
                                            file.id || file?.file?.name
                                        }`}
                                        onRemove={() => {
                                            removeTempFile(file.id);
                                        }}
                                        name={file?.file?.name}
                                    />
                                );
                            })}
                            {filesPreviews.map((file, index) => {
                                return (
                                    <FilePreview
                                        key={`file_${file.originalName}`}
                                        onRemove={() => {
                                            handleFileRemove(index);
                                        }}
                                        name={file.originalName}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="col-span-6 flex flex-row justify-end gap-4 px-4 lg:pt-16 text-right sm:px-6">
                    <Button onClick={goBack} outlined label="Cancel" />
                    <Button
                        disabled={!isValid}
                        type="submit"
                        label={isUpdate ? "Save" : "Create"}
                    />
                </div>
            </form>
        </div>
    );
}
