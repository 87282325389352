interface ProgressBarProps {
    progress: any;
    name: any;
    onRemove: any;
}

export default function ProgressBar(props: ProgressBarProps) {
    const { progress, onRemove, name } = props;
    return (
        <div className="border rounded-lg mt-4 p-6">
            <div className="text-xs text-neutral-800">
                {`${progress}% complete`}
            </div>
            <div className="text-xs text-gray-400">{`Uploading ${name}`}</div>
            <div className="mt-2 flex items-center">
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                        className={`bg-orange-500 h-2.5 rounded-full`}
                        style={{
                            width: `${progress}%`,
                        }}
                    ></div>
                </div>
                <div
                    className="ml-4 text-orange-500 hover:text-orange-600 font-semibold cursor-pointer"
                    onClick={onRemove}
                >
                    X
                </div>
            </div>
        </div>
    );
}
