interface DetailItemProps {
    label: string;
    children: React.ReactNode;
}

const DetailItem = ({ label, children }: DetailItemProps) => (
    <div className="flex items-start gap-4">
        <div className="font-medium sm:text-base text-sm text-gray-500">
            {label}
        </div>
        <div className="sm:text-base text-sm whitespace-break-spaces">
            {children}
        </div>
    </div>
);

export default DetailItem;
