import { useEffect, useMemo, useRef, useState } from "react";
import Icon from "../../assets/svg/Icon";
import useDebounce from "../../hooks/useDebounce";
import useGoogleMaps from "../../hooks/usePlacesService";
import useTurnOffOutsideClick from "../../hooks/useTurnOffOutsideClick";
import Loader from "../Loader";
import colors from "tailwindcss/colors";

const CitiesAutocomplete = (props: any) => {
    const [searchText, setSearchText] = useState<any>("");
    const [showResults, setShowResults] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(true);
    const [value, setValue] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<Array<any> | null>(null);
    const mapService = useGoogleMaps();
    const wrapperRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const {
        label,
        id,
        name,
        type,
        placeholder,
        defaultValue,
        autoComplete,
        changeTextOnUpdate,
        error,
        onChange,
        onSearch,
        onTextChange,
        className,
        ...others
    } = props;

    const handleOnSearch = async (text: string) => {
        try {
            setSearchResults([]);
            setLoading(true);
            const promises = [
                mapService.getPlacePredictions(text, {
                    types: ["(cities)"],
                }),
            ];
            const responses: Array<any> = await Promise.all(promises);

            setSearchResults(responses[0]);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const debouncedSearchText = useDebounce(searchText, 500);
    useEffect(() => {
        if (debouncedSearchText) handleOnSearch(debouncedSearchText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchText]);

    useEffect(() => {
        const newValue = defaultValue;
        setValue(newValue);
        onTextChange && onTextChange(newValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    useEffect(() => {
        if (inputRef?.current?.focus && !inputDisabled)
            inputRef?.current?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef?.current, inputDisabled]);

    const handleChange = (e: any) => {
        const newValue = e.target.value;
        setSearchText(newValue);
        setValue(newValue);
        setShowResults(true);
        onTextChange && onTextChange(e.target.value);
    };

    const handleOptionSelect = async (item: any) => {
        if (item) {
            setSearchText(item?.description);
            setValue(item.description);
            setShowResults(false);
            if (onChange) onChange(item);
        }
    };

    const showNoResultsLabel = useMemo(() => {
        return (
            showResults && !loading && searchText && searchResults?.length === 0
        );
    }, [showResults, loading, searchText, searchResults?.length]);

    const showResultsList = useMemo(() => {
        return showResults && searchResults && searchResults?.length > 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showResults, searchResults?.length]);

    const showEditIcon = useMemo(() => {
        return !loading && !value && !searchText;
    }, [loading, value, searchText]);

    const { ref } = useTurnOffOutsideClick(true, () => {
        setShowResults(false);
    });
    const handleClickRemove = () => {
        setSearchText("");
        setValue("");
        setShowResults(false);
        setInputDisabled(true);
        if (onChange) onChange(null);
        if (onTextChange) onTextChange('');
    };

    const handleEditIconClick = () => {
        setInputDisabled((prev) => {
            return false;
        });
        inputRef?.current?.focus();
    };

    return (
        <div ref={wrapperRef} className="w-full">
            <div className="relative flex items-center">
                <div
                    onClick={handleEditIconClick}
                    className={`${
                        !inputDisabled ? "hidden" : ""
                    } text-sm text-gray-400 pr-2 cursor-pointer`}
                >
                    {value || "All world"}
                </div>
                <input
                    autoFocus="autoFocus"
                    ref={inputRef}
                    //disabled={inputDisabled}
                    className={`${className || "border-b"} ${
                        inputDisabled ? "hidden" : ""
                    } text-sm text-gray-400 w-full`}
                    value={value}
                    onChange={handleChange}
                    label={label}
                    type={type}
                    name={name}
                    id={id}
                    placeholder={"All world"}
                    error={error}
                    autoComplete={autoComplete}
                    {...others}
                />
                {!!loading && (
                    <div className="-ml-7">
                        <Loader />
                    </div>
                )}
                {!loading && value && (
                    <div className="">
                        <Icon
                            icon={"CloseIcon"}
                            color={colors.gray[400]}
                            width={20}
                            className="text-gray-400 cursor-pointer"
                            onClick={handleClickRemove}
                        />
                    </div>
                )}
                {showEditIcon && (
                    <div className="">
                        <Icon
                            icon={"PencilIcon"}
                            color={"black"}
                            width={20}
                            className="text-gray-400 w-5 cursor-pointer"
                            onClick={handleEditIconClick}
                        />
                    </div>
                )}
            </div>

            {showResultsList && (
                <div ref={ref} className="relative w-full child:w-full">
                    <ul className="shadow absolute z-10 bg-white text-sm text-neutral-800 text-left rounded-md border">
                        {searchResults?.map((r: any, index: number) => (
                            <li
                                onClick={(e: any) => {
                                    handleOptionSelect(r);
                                }}
                                tabIndex={0}
                                key={`${r.description} - ${r.id}`}
                                className={`px-9 cursor-pointer py-4 hover:bg-gray-50 border-b border-b-gray-200`}
                            >
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <Icon
                                            icon={"MarkerIcon"}
                                            className="w-6 h-6"
                                        />
                                    </div>

                                    <div>
                                        <div className="text-sm font-medium text-gray-600">
                                            {r["description"]}
                                        </div>
                                        <div className="text-xs font-medium text-gray-400 pt-1"></div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {showNoResultsLabel && (
                <div className="mt-1 text-sm">No results found</div>
            )}
        </div>
    );
};
export default CitiesAutocomplete;
