import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../../components/Input";
import ButtonSquared from "../../../../components/ButtonSquare";
import { ButtonSize } from "../../../../constants";
import TextAreaInput from "../../../../components/TextArea";

interface SendMessageFormProps {
    handleClose: () => void;
    handleSendMessage: (subject: string, body: string) => Promise<void>;
}

export default function SendMessageForm({
    handleClose,
    handleSendMessage,
}: SendMessageFormProps) {
    const schema = yup
        .object({
            subject: yup.string().max(250).required("This field is required"),
            body: yup.string().max(500).required("This field is required"),
        })
        .required("This field is required");
    const {
        register,
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const handleSubmitEvent = async (data: any) => {
        await handleSendMessage(data?.subject, data?.body);
    };

    return (
        <form
            onSubmit={handleSubmit(handleSubmitEvent)}
            className="m-4"
            method="POST"
        >
            <Input
                markRequired
                register={register}
                name={"subject"}
                label={"Subject"}
                error={errors["subject"]?.message}
            />

            <div className="mt-2">
                <TextAreaInput
                    markRequired
                    register={register}
                    name={"body"}
                    label={"Body"}
                    error={errors["body"]?.message}
                />
            </div>

            <div className="flex justify-end gap-x-2 mt-4">
                <ButtonSquared
                    outlined
                    className="px-4"
                    size={ButtonSize.FIT}
                    type="button"
                    label={"Cancel"}
                    onClick={handleClose}
                    disabled={isSubmitting}
                />
                <ButtonSquared
                    size={ButtonSize.SMALL}
                    type="submit"
                    label={"Send"}
                    disabled={!isValid || isSubmitting}
                />
            </div>
        </form>
    );
}
