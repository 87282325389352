import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Button from "../../components/ButtonSquare";
import TabComponent from "../../components/TabComponent";
import {
    ButtonSize,
    PAGE_ROWS_TRIP_LIST,
    PAGE_SECTIONS,
    TripStatus,
    UserStatusOnTrip,
} from "../../constants";
import { setCurrentSection } from "../layout/layoutSlice";
import TripCard from "./Trip/TripCard";
import { getPendingTrips, getTrips, selectPendingTrips } from "./tripSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import EmptyIcon from "../../assets/images/EmptyScreenIcon.png";
import GiftIcon from "../../assets/images/gift.png";
import { selectProfile } from "../authentication/userSlice";
import { useAuthStateContext } from "../../context/Auth";
import { PastTripItem } from "../home/components/PastTripsCard";

let tripsList: any[] = [];
export default function TripsList(props: any) {
    const tabItemsIndex = {
        activeTrips: TripStatus.ACTIVE,
        FinishedTrips: TripStatus.FINISHED,
    };
    const initialTabs = [
        {
            id: tabItemsIndex.activeTrips,
            text: "Active Trips",
        },
        {
            id: tabItemsIndex.FinishedTrips,
            text: "Past Trips",
        },
    ];
    const [tabItems, setTabItems] = useState<any[]>(initialTabs);
    const [selectedTab, setSelectedTab] = useState<string | number>(
        tabItemsIndex.activeTrips
    );
    const [searchParams] = useSearchParams();
    const [trips, setTrips] = useState<any>([]);
    const [count, setCount] = useState<any>(0);
    const [loading, setLoading] = useState<any>(false);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const pendingTrips = useAppSelector(selectPendingTrips);
    const [tripStatus, setTripStatus] = useState<any>(TripStatus.ACTIVE);
    const currentPlan = useAppSelector((state: any) => state.plans.currentPlan);
    const profile = useAppSelector(selectProfile);

    const [skip, setSkip] = useState<number>(0);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setCurrentSection({ currentSection: PAGE_SECTIONS.TRIPS }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            setTabItems(initialTabs);
            setSkip(0);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (profile?.email) {
            if (!isFirstLoad) {
                let status = searchParams.get("status");
                changeTab(status || TripStatus.ACTIVE);
                dispatch(getPendingTrips());
                setIsFirstLoad(true);
            } else {
                getTripsInfo(PAGE_ROWS_TRIP_LIST, skip, tripStatus, [
                    UserStatusOnTrip.CONFIRMED,
                    UserStatusOnTrip.BLOCKED,
                ]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripStatus, isFirstLoad, profile?.email]);

    const getTripsInfo = async (
        take: number,
        newSkip: number,
        newStatus: string,
        newUserStatus: Array<UserStatusOnTrip>
    ) => {
        setLoading(true);
        const { data, ...other }: any = await dispatch(
            getTrips(take, newSkip, newStatus, newUserStatus)
        );
        const currentListIds = tripsList.map((trp) => trp.id);
        if (skip > 0) {
            let newTrips = data.filter(
                (newTrip: any) => !currentListIds.includes(newTrip.id)
            );
            tripsList = tripsList.concat(newTrips);
            setTrips(tripsList);
        } else {
            tripsList = data;
        }
        setLoading(false);
        setCount(other.count);
        setTrips(tripsList);
        setSkip(skip + PAGE_ROWS_TRIP_LIST);
    };

    const handleNewTripClick = (e: any) => {
        navigate("/trips/new");
    };

    const handleTripClick = (tripId: number) => {
        navigate(`/trips/${tripId}`);
    };

    const changeTab = (tabIndex: string | number) => {
        if (selectedTab !== tabIndex) {
            setTrips([]);
            setSelectedTab(tabIndex);
            setTripStatus(tabIndex);
            setSkip(0);
        }
    };

    const showPendingTrips = useMemo(() => {
        return !!pendingTrips?.data?.length && tripStatus === TripStatus.ACTIVE;
    }, [pendingTrips, tripStatus]);

    const data = useMemo(() => {
        return showPendingTrips
            ? [
                  ...(trips || []),
                  ...(pendingTrips?.data?.map((t) => ({
                      ...t,
                      pending: true,
                  })) || []),
              ].sort(
                  (a, b) =>
                      new Date(a.startDate).valueOf() -
                      new Date(b.startDate).valueOf()
              )
            : trips;
    }, [trips, pendingTrips]);

    const fetchData = () => {
        getTripsInfo(PAGE_ROWS_TRIP_LIST, skip, tripStatus, [
            UserStatusOnTrip.CONFIRMED,
            UserStatusOnTrip.BLOCKED,
        ]);
    };

    const { getTripsCount, isFreeSupplier, tripsCount } = useAuthStateContext();
    useEffect(() => {
        getTripsCount && getTripsCount();
    }, []);

    return (
        <>
            <div className="lg:px-6 flex flex-wrap my-6 items-center">
                <TabComponent
                    className="flex-grow text-left"
                    onChange={changeTab}
                    items={tabItems}
                    defaultSelected={selectedTab}
                />
                <div className="">
                    <Button
                        disabled={!profile?.isAdmin && currentPlan?.suspended}
                        size={ButtonSize.SMALL}
                        onClick={handleNewTripClick}
                        label="Create Trip"
                    />
                </div>
            </div>
            {(trips || showPendingTrips) && (
                <InfiniteScroll
                    dataLength={trips?.length || 0} //This is important field to render the next data
                    next={fetchData}
                    hasMore={trips?.length < count}
                    loader={""}
                >
                    <div className="grid grid-cols-6 gap-4 lg:gap-8 w-full mt-2">
                        {data &&
                            data?.map((trip: any) => {
                                return selectedTab === TripStatus.ACTIVE ? (
                                    <div
                                        key={`tripCard_past${trip.id}`}
                                        className="w-full col-span-6 md:col-span-3 lg:col-span-2"
                                    >
                                        <TripCard
                                            pending={trip.pending}
                                            onClick={() =>
                                                handleTripClick(trip.id)
                                            }
                                            trip={trip}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        key={`tripCard_past${trip.id}`}
                                        className="w-full col-span-6 md:col-span-3 lg:col-span-2"
                                    >
                                        <div
                                            onClick={() =>
                                                handleTripClick(trip.id)
                                            }
                                            className="flex flex-row rounded-lg bg-neutral-100 p-2 hover:bg-neutral-200 cursor-pointer"
                                        >
                                            <PastTripItem trip={trip} />
                                        </div>
                                    </div>
                                );
                            })}
                        {loading &&
                            (selectedTab === TripStatus.ACTIVE ? (
                                <div className="w-full col-span-6 md:col-span-3 lg:col-span-2">
                                    <Skeleton className="!rounded-xl min-h-[300px]" />
                                </div>
                            ) : (
                                <div className="w-full col-span-6 md:col-span-3 lg:col-span-2">
                                    <Skeleton className="!rounded-xl min-h-[70px]" />
                                </div>
                            ))}
                    </div>
                    {!trips?.length &&
                        !showPendingTrips &&
                        !loading &&
                        (!!isFreeSupplier && tripsCount === 0 ? (
                            <div className="my-24 flex flex-col text-neutral-400 items-center justify-center text-center">
                                <img
                                    alt="gift"
                                    src={GiftIcon}
                                    className="h-28 m-0 mb-6"
                                />
                                <div className="text-lg font-semibold text-neutral-900">
                                    Enjoy FamGuru's one-time free trip
                                </div>
                                <div className="text-base text-neutral-800 font-semibold my-4">
                                    Explore all our features so you can find the
                                    perfect plan for you.
                                </div>
                                <div
                                    onClick={handleNewTripClick}
                                    className="cursor-pointer text-lg text-orange-600 font-semibold"
                                >
                                    Create free trip
                                </div>
                            </div>
                        ) : (
                            <div className="my-24 flex flex-col text-neutral-400 items-center justify-center text-center">
                                <img
                                    alt="empty"
                                    src={EmptyIcon}
                                    className="h-28 m-0 mb-12"
                                />
                                <div className="text-base">
                                    There are no trips.
                                </div>
                            </div>
                        ))}
                </InfiniteScroll>
            )}
            {!!trips?.length && !loading && trips?.length < count && (
                <div
                    onClick={() => {
                        setSkip(trips?.length);
                        fetchData();
                    }}
                    className="text-orange-600 hover:text-orange-500 cursor-pointer mt-12 font-semibold"
                >
                    Load more
                </div>
            )}
        </>
    );
}
