/* This example requires Tailwind CSS v2.0+ */

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import {
    ACTIVITY_FORM_STEPS_IDS,
    PAGE_SECTIONS,
} from "../../../../../../../constants";
import {
    activityFormCurrentStep,
    setActivityFormCurrentStep,
} from "../../../../../tripSlice";
import ActivityFormPreview from "./FormPreview";
import HotelDetailsForm from "../ActivityDetailsForm";
import HotelInfoForm from "./HotelInfo";
import HotelSelectionForm from "./HotelSelection";
import { setCurrentSection } from "../../../../../../layout/layoutSlice";

export default function NewHotelActivityForm(props: {
    currentSection: PAGE_SECTIONS;
    onSubmit: (activity: any) => void;
    navigateBack: () => void;
    isLibraryElement?: boolean;
}) {
    const dispatch = useAppDispatch();
    const { currentSection, navigateBack, isLibraryElement, onSubmit } = props;
    const currentStep = useAppSelector(activityFormCurrentStep);
    useEffect(() => {
        dispatch(
            setActivityFormCurrentStep({
                step: ACTIVITY_FORM_STEPS_IDS.HOTEL_SELECTION,
            })
        );
        dispatch(
            setCurrentSection({
                currentSection,
            })
        );
    }, []);

    return (
        <>
            {currentStep === ACTIVITY_FORM_STEPS_IDS.HOTEL_SELECTION ? (
                <HotelSelectionForm
                    isLibraryElement={isLibraryElement}
                    navigateBack={navigateBack}
                />
            ) : currentStep === ACTIVITY_FORM_STEPS_IDS.HOTEL_INFO ? (
                <HotelInfoForm isLibraryElement={isLibraryElement} />
            ) : currentStep === ACTIVITY_FORM_STEPS_IDS.HOTEL_DETAILS ? (
                <HotelDetailsForm />
            ) : currentStep === ACTIVITY_FORM_STEPS_IDS.HOTEL_CONFIRM ? (
                <ActivityFormPreview
                    isLibraryElement={isLibraryElement}
                    onSubmit={onSubmit}
                />
            ) : null}
        </>
    );
}
