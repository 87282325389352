import { useMemo } from "react";
import HotelItemDetail from "../../components/HotelItemDetail/HotelItemDetail";
import GalleryImage from "../../../../../../../../components/ImagesGallery/Image";
import {
    CommonTypeOptions,
    COMMON_TYPE,
} from "../../../../../../../../constants/insight";

interface CommonDetailItemProps {
    item: any;
}

const CommonInsightDetails = ({ item }: CommonDetailItemProps) => {
    const images = useMemo(() => {
        return item?.images
            ? Object.values(item?.images)?.map((img: any) => ({
                  ...img,
              }))
            : [];
    }, [item?.images]);
    const type = useMemo(() => {
        return item?.type !== COMMON_TYPE.OTHER
            ? CommonTypeOptions.find((v) => {
                  return v.value === item.type;
              })?.label || ""
            : item?.typeOther;
    }, [item]);
    return (
        <div>
            <div className="flex items-center gap-6 flex-wrap w-full">
                {!!images?.length && (
                    <div className="flex flex-row flex-wrap gap-2 mt-4">
                        {images?.map((image: any, index: number) => (
                            <div className="w-fit" key={image.url}>
                                <GalleryImage
                                    viewOnly
                                    src={image.url}
                                    onImageClick={() => {}}
                                    imageClass="object-cover"
                                    description={image?.description}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="mt-4">
                <HotelItemDetail label={"Name"} value={item?.name} />
            </div>
            <div className="mt-4">
                <HotelItemDetail label={"Type"} value={type} />
            </div>

            <div className="mt-4">
                <HotelItemDetail label="Comments" value={item?.comments} />
            </div>
        </div>
    );
};

export default CommonInsightDetails;
