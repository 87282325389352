import React from "react";
import InputErrorLabel from "../InputErrorLabel";
import InputLabel from "../InputLabel";
import TextareaAutosize from "react-autosize-textarea";
const inputClasses = {
  normal:
    "border-b border-gray-200 focus-within:border-b focus-within:border-orange-600 hover:border-orange-600 py-2 block w-full text-base font-normal",
  error:
    "border-b border-alert_red py-2 block w-full text-base font-normal",
};


const TextAreaInput = React.forwardRef((props: any, ref: any) => {
  const {
    label,
    id,
    name,
    placeholder,
    defaultValue,
    comment,
    tooltip,
    error,
    rows,
    className,
    ...others
  } = props;
  return (
      <>
          <InputLabel
              tooltip={tooltip}
              inputId={id}
              label={label}
              error={error}
              markRequired={props.markRequired}
          />
          <div className="mt-1">
              <TextareaAutosize
                  ref={ref}
                  id={id}
                  name={name}
                  rows={rows || 3}
                  className={`${error ? inputClasses.error : inputClasses.normal} ${className}`}
                  placeholder={placeholder}
                  defaultValue={defaultValue}
                  {...others}
              />
          </div>
          {/* {comment && <p className="mt-2 text-sm text-gray-500">{comment}</p>} */}
          <InputErrorLabel error={error} />
      </>
  );
});

export default TextAreaInput;
