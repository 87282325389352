import Icon from "../../../../assets/svg/Icon";
import EmptyScreen from "../../../../components/EmptyScreen/EmptyScreen";
import Paginator from "../../../../components/Paginator";
import Table from "../../../../components/Table";
import { PAGE_ROWS } from "../../../../constants";

const tableHeaders = ["Name", "Description", ""];
interface IFilesTable {
    handleDelete: Function;
    handlePaginatorChange: Function;
    files: any;
    canEdit: boolean;
    handleClick: Function;
}
export default function TripFilesTable({
    handleDelete,
    handlePaginatorChange,
    files,
    canEdit,
    handleClick,
}: IFilesTable) {
    const { data, count, isLoading, currentPage, totalPages, skip } = files;

    return (
        <>
            <Table headers={tableHeaders} isLoading={isLoading}>
                {data &&
                    data.map((file: any) => {
                        return (
                            <tr
                                className={`${
                                    canEdit
                                        ? "cursor-pointer hover:bg-gray-50"
                                        : ""
                                }`}
                                onClick={() => {
                                    return handleClick(file);
                                }}
                                key={file.id}
                            >
                                <td className="break-all whitespace-normal">
                                    <div className="text-left max-w-[400px] truncate">
                                        <span className="text-left">
                                            {file.name}
                                        </span>
                                    </div>
                                </td>
                                <td className="break-all whitespace-normal">
                                    <div className="text-left max-w-[400px] truncate">
                                        <span className="text-left">
                                            {file.description}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex items-center justify-start">
                                        <span
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            className="text-left"
                                        >
                                            <a
                                                href={file.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                download={true}
                                            >
                                                {" "}
                                                <Icon
                                                    tabIndex={0}
                                                    icon={"DownloadIcon"}
                                                    className="w-6 h-6 text-gray-400 hover:!text-gray-900"
                                                />
                                            </a>
                                        </span>
                                        {canEdit && (
                                            <div className="ml-4">
                                                <Icon
                                                    tabIndex={0}
                                                    icon={"TrashIcon"}
                                                    className="w-6 h-6 cursor-pointer hover:text-gray-900"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDelete(file);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
            </Table>

            <div className="w-full mt-8">
                {count > PAGE_ROWS && (
                    <Paginator
                        count={count}
                        take={PAGE_ROWS}
                        onChange={handlePaginatorChange}
                        totalPages={totalPages}
                        currentPage={currentPage}
                    />
                )}
            </div>
        </>
    );
}
