export const optionsDone = [
    "Unlimited Fam Trips",
    "Unlimited Advisors and Admins",
    "Utilize all of Famguru’s great features and capabilities",
];

export const notPlan = [
    "Unlimited Fam Trips",
    "Unlimited Advisors and Admins",
    "Utilize all of Famguru’s great features and capabilities",
];

export const optionsDoneAnnual = [
    ...optionsDone,
    "Save over our regular introductory rates",
    "Lock in our lowest rates until the end of 2024",
];

export const optionsNotDone = (formatDate: string) => [
    `You will not be charged until ${formatDate}`,
    "After 30 days, introductory pricing is $99/month.",
    "We will send you a reminder 7 days before you get paid.",
    "Cancel at any time. Terms and conditions of the offer apply.",
];

export const optionsNotDoneAnnual = [
    "Annual subscription of $75/month for 2024 (save 25%).",
    ...optionsNotDone("").slice(2),
];

export enum AvailablePlanType {
    PRIMARY = "Special Limited Time Offer",
    SECONDARY = "Free Trial + Introductory Rate",
}

export enum UserStatusEnum {
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
    BLOCKED = "BLOCKED",
    INACTIVE = "INACTIVE",
}

export const PlansPermissionsInputArray = [
    {
        name: "maxAdminsPerTrip",
        defaultValue: 0,
        inputType: "number",
        description: "Max admins per trip",
    },
    {
        name: "maxMembersPerTrip",
        defaultValue: 0,
        inputType: "number",
        description: "Max articipants per trip",
    },
    {
        name: "maxNumberOfTrips",
        defaultValue: 999999,
        inputType: "number",
        description: "Max number of trips owned",
    },
];
