interface TextDividerProps {
    text: string;
}
export const TextDivider = ({ text }: TextDividerProps) => {
    return (
        <div className="inline-flex items-center justify-center w-full">
            <hr className="w-64 h-px my-8 bg-neutral-300" />
            <span className="absolute px-3 font-medium text-neutral-600 -translate-x-1/2 bg-white left-1/2 text-xs">
                {text}
            </span>
        </div>
    );
};
