import { FC, InputHTMLAttributes, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import GoogleMap from "../../../../components/Map";
import {
  selectedTripLocalPartner,
} from "../../tripSlice";
import moment from "moment";
import ContactCard from "../TripItinerary/ContactCard";
import Button from "../../../../components/ButtonSquare";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  text?: string;
}

const DetailItem: FC<InputProps> = ({ label, text, ...rest }) => {
  return (
    <>
      <div className="font-medium text-sm text-gray-500">{label}</div>
      <h4 className="text-lg font-normal">
        {text}
        {rest.children}
      </h4>
    </>
  );
};

export default function LocalPartnerDetails() {
  const [mapMarker, setmapMarker] = useState<any | null>(null);
  const navigate = useNavigate();
  let {  localPartnerId } = useParams();
  const localPartnerOnTrip = useAppSelector(
    selectedTripLocalPartner(Number(localPartnerId))
  );

  const { startDate, endDate, contacts, localPartner } =
    localPartnerOnTrip || {};
  const { lat, lng, name, fullAddress, website } = localPartner || {};
  const startNormalized = moment(startDate).format("yyyy-MM-DD");
  const endNormalized = moment(endDate).format("yyyy-MM-DD");

  useEffect(() => {
    if (lat && lng) {
      const marker = {
        position: {
          lat: lat,
          lng: lng,
        },
        title: name,
      };
      setmapMarker(marker);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng]);

  const contactsNormalized = contacts
    ? Object.keys(contacts).map((cKey) => contacts[cKey])
    : [];

  const handleEditClick = () => {
    navigate(`edit`);
  };
  return (
    <>
      <div className="w-full flex justify-end mt-3">
        <Button label="Edit" onClick={handleEditClick} />
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="lg:grid lg:grid-cols-3 lg:gap-6">
          <div className="mt-6 lg:mt-0 lg:col-span-6">
            {localPartnerOnTrip && (
              <>
                <div className="col-span-6 sm:col-span-3 mt-3 text-left">
                  <DetailItem label="Name" text={name} />
                </div>

                <div className="col-span-6 sm:col-span-3 mt-3 text-left">
                  <DetailItem label="Address">
                    <a href={`http://maps.google.com/?q=${fullAddress}`}>
                      {fullAddress}
                    </a>
                  </DetailItem>
                </div>
                <div className="col-span-6 sm:col-span-3 mt-3 text-left">
                  <DetailItem label="Website">
                    <a href={website}>{website}</a>
                  </DetailItem>
                </div>

                <div className="col-span-6 sm:col-span-3 mt-3 text-left">
                  <DetailItem label="Start Date" text={startNormalized} />
                </div>
                <div className="col-span-6 sm:col-span-3 mt-3 text-left">
                  <DetailItem label="End Date" text={endNormalized} />
                </div>

                <div className="col-span-6 sm:col-span-3 mt-3">
                  {mapMarker && <GoogleMap marker={mapMarker} />}
                </div>

                <div className="col-span-6 sm:col-span-3 mt-3">
                  <div>Contacts</div>
                  {contactsNormalized && (
                    <div className="flex gap-3">
                      {contactsNormalized.map((c: any) => (
                        <ContactCard
                          contact={c}
                          key={`${c.firstName}-${c.phone}`}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </>
  );
}
