import moment from "moment";
import StatusBadgeComponent from "../../../../../../components/StatusBadge/StatusBadgeComponent";
import RequirementAction from "./RequirementAction";
import { TripRequirementFileStatus } from "../../../../../../constants";
import { useMemo, useState } from "react";
import ButtonSquared from "../../../../../../components/ButtonSquare";
import Modal from "../../../../../../components/Modal";
import TripRequirementFileForm from "../../../TripRequirements/TripRequirement/RequirementFileForm";
import { Paragraph } from "../../../../../../components/Paragraph/Paragraph";

interface DocumentRequirementsModalProps {
    requirement: any;
    onUpdate: any;
}
export default function DocumentRequirementsModal(
    props: DocumentRequirementsModalProps
) {
    const { requirement, onUpdate } = props;
    const [uploadModal, setUploadModal] = useState(false);
    const showUploadButton = useMemo(() => {
        return [
            TripRequirementFileStatus.REJECTED,
            TripRequirementFileStatus.NOT_SUBMITTED,
        ].includes(requirement?.status);
    }, [requirement?.status]);
    return (
        <>
            <Modal
                open={uploadModal}
                onClose={() => {
                    setUploadModal(false);
                }}
            >
                <TripRequirementFileForm
                    requirement={requirement}
                    userEmail={requirement?.userEmail}
                    onSubmit={onUpdate}
                />
            </Modal>
            {requirement && (
                <div className="md:p-2 flex flex-col">
                    <div className="flex">
                        <div className="text-lg font-semibold flex-grow">
                            {requirement.name}
                        </div>
                        <div className="">
                            <StatusBadgeComponent
                                size="base"
                                status={requirement?.status}
                            />
                        </div>
                    </div>
                    <div className="py-2 text-left text-sm text-neutral-400">
                        Deadline:{" "}
                        {moment(requirement?.deadline).format("DD MMM, YYYY")}
                    </div>
                    <Paragraph className="text-left text-sm">
                        {requirement.description}
                    </Paragraph>
                    <div className="md:p-3">
                        {requirement?.files?.map((file: any) => (
                            <RequirementAction
                                key={file.id}
                                tripId={requirement.tripId}
                                requirement={requirement}
                                file={file}
                                onActionTaken={onUpdate}
                            />
                        ))}
                    </div>

                    {showUploadButton && (
                        <div className="md:p-3">
                            <div className="flex items-center">
                                <ButtonSquared
                                    className="p-4 mx-auto"
                                    type="button"
                                    label="Add info"
                                    onClick={() => {
                                        setUploadModal(true);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
