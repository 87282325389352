import Avatar from "../../../../components/Avatar";
import { PAGE_ROWS } from "../../../../constants";
import Paginator from "../../../../components/Paginator";
import Table from "../../../../components/Table";
import moment from "moment";
import { useState } from "react";
import Modal from "../../../../components/Modal";

const tableHeaders = ["From", "Subject", "Body", "Date"];

interface IMessageTable {
    messages: any;
    canEdit: boolean;
    handlePaginatorChange: Function;
}

export default function TripMessagesTable({
    messages,
    canEdit,
    handlePaginatorChange,
}: IMessageTable) {
    const { data, count, isLoading, currentPage, totalPages, take } = messages;
    const formatDateTime = (date: string) => {
        return moment(date).format("DD MMM YYYY - hh:mm:ss");
    };
    const [selectedMessage, setSelectedMessage] = useState<any>(null);

    return (
        <>
            <Modal
                open={!!selectedMessage}
                onClose={() => {
                    setSelectedMessage(null);
                }}
            >
                <div className="flex flex-col p-4">
                    <div className="mb-4 text-lg font-semibold">
                        {selectedMessage?.subject}
                    </div>
                    <div className="text-normal">{selectedMessage?.body}</div>
                </div>
            </Modal>
            <Table headers={tableHeaders} isLoading={isLoading}>
                {data?.map((m: any) => {
                    return (
                        <tr
                            key={m.id}
                            className="hover:bg-neutral-50 cursor-pointer"
                            onClick={() => {
                                setSelectedMessage(m);
                            }}
                        >
                            <td>
                                <div className="flex gap-3 items-center justify-start">
                                    <Avatar
                                        src={m.user?.avatar}
                                        className="w-6 h-6"
                                    />
                                    <span className="max-w-[150] truncate text-left whitespace-pre-line break-before-all">
                                        {m?.user?.email}
                                    </span>
                                </div>
                            </td>
                            <td className="text-left max-w-[200px] truncate">
                                {m.subject}
                            </td>
                            <td className="text-left max-w-[200px] truncate">
                                {m.body}
                            </td>

                            <td className="text-left max-w-[200px] truncate">
                                {formatDateTime(m.createdAt)}
                            </td>
                        </tr>
                    );
                })}
            </Table>
            <div className="w-full mt-8">
                {count > PAGE_ROWS && (
                    <Paginator
                        count={count}
                        take={take}
                        onChange={handlePaginatorChange}
                        totalPages={totalPages}
                        currentPage={currentPage}
                    />
                )}
            </div>
        </>
    );
}
