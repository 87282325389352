import React, { useState } from "react";
import Modal, { Modal_Size } from "../../../../components/Modal";
import { Paragraph } from "../../../../components/Paragraph/Paragraph";
import Loader from "../../../../components/Loader";
import Button from "../../../../components/ButtonSquare";
import TextAreaInput from "../../../../components/TextArea";

interface ReportModalProps {
    open: boolean;
    handleCancel: () => void;
    handleSubmit: (reason: string) => void;
}

const ReportModal: React.FunctionComponent<ReportModalProps> = ({
    open,
    handleCancel,
    handleSubmit,
}) => {
    const [reason, setReason] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = () => {
        setIsLoading(true);
        handleSubmit(reason);
        setIsLoading(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleCancel}
            size={Modal_Size.md}
            hideCloseButton={false}
            className="min-h-[250px]"
        >
            <div className="px-6">
                <div className="text-xl font-semibold text-neutral-900 justify-center pb-2 text-left">
                    Report
                </div>
                <Paragraph className="text-base font-base justify-center text-left">
                    To help us address the issue, tell us why you're reporting
                    this user.
                </Paragraph>
                <div className="text-md font-semibold text-neutral-400 justify-center mt-5 pb-4 text-left">
                    Reason
                </div>
                <TextAreaInput
                    register={() => {}}
                    type="text"
                    id="reason"
                    name="reason"
                    error=""
                    onChange={(e) => setReason(e.target.value)}
                />
                {!isLoading && (
                    <div className="flex flex-grow items-center">
                        <div className="flex flex-grow flex-row gap-6 lg:px-4 mt-8 justify-end text-right">
                            <Button outlined label="Cancel" onClick={handleCancel} />

                            <Button type="submit" onClick={onSubmit} label="Confirm" disabled={!reason.length} />
                        </div>
                    </div>
                )}
                {!!isLoading && (
                    <div className="w-fit m-auto py-4">
                        <Loader />
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ReportModal;
