import React from "react";
import { IChatMessage } from "../../../../types/chat.type";
import { useChatForm } from "../../hooks/useChat.hook";

const CurrentUserMessageBubble: React.FC<{ message: IChatMessage }> = ({
    message,
}) => {
    const { getFormattedDate } = useChatForm();
    const formattedDate = getFormattedDate(message.timestamp);

    if (!message.content) return null;

    return (
        <div className="flex justify-end ml-auto mb-4">
            <div className="relative bg-[#ffeae1] p-3 pb-1 rounded-tl-xl rounded-tr-xl rounded-bl-xl shadow-sm max-w-lg w-auto inline-block">
                <div className="flex items-end">
                    <p className="mr-2 font-medium text-start">
                        {message.content}
                    </p>
                    <span className="text-xs text-gray-500 flex-shrink-0">
                        {formattedDate}
                    </span>
                </div>
                <div
                    className="absolute bottom-[-6px] right-[-1.5px] bg-[#ffeae1] w-2 h-2 rounded-bl-lg rounded-tr-sm"
                    style={{
                        clipPath:
                            "polygon(0px 0px, 100% 0px, 100% 100%, 0% 0%, 15% 60%, 0% 0%)",
                        transform: "rotate(-25deg)",
                    }}
                ></div>
            </div>
        </div>
    );
};

export default CurrentUserMessageBubble;
