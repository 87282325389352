import React, { useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    deleteTripCustomInvite,
    selectedTripCustomInvite,
    selectedTripId,
} from "../../tripSlice";
import placeholder from "../../../../assets/images/placeholder.png";
import { useNavigate } from "react-router";
import Icon from "../../../../assets/svg/Icon";
import ModalConfirmation from "../../../../components/Modal/ConfirmationModal";
import { Paragraph } from "../../../../components/Paragraph/Paragraph";

const TripCustomInviteDetail = (props: any) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const customInvite = useAppSelector(selectedTripCustomInvite);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const tripId = useAppSelector(selectedTripId);
    const handleEditClick = () => {
        navigate(`/trips/${tripId}/invites/edit`);
    };
    const handleClickDelete = () => {
        dispatch(deleteTripCustomInvite(Number(tripId)));
        toogleDeleteModalOpen();
    };
    const toogleDeleteModalOpen = () => {
        setDeleteModalOpen((prev) => {
            return !prev;
        });
    };

    const imageSrc = useMemo(() => {
        if (customInvite?.image) {
            return customInvite?.image + "&" + new Date().valueOf();
        }
        return null;
    }, [customInvite]);

    return (
        <div className="w-full">
            <ModalConfirmation
                title="Are you sure?"
                description="You are about to remove the custom invite"
                open={deleteModalOpen}
                handleSubmit={handleClickDelete}
                handleCancel={toogleDeleteModalOpen}
            />
            <div className="flex w-full justify-end">
                <Icon
                    className="w-[24px] cursor-pointer ml-4 text-gray-600 hover:text-orange-600"
                    icon="PencilIcon"
                    onClick={handleEditClick}
                />
                {customInvite?.id && (
                    <Icon
                        className="w-[24px] cursor-pointer ml-4 text-gray-600 hover:text-orange-600"
                        icon="TrashIcon"
                        onClick={toogleDeleteModalOpen}
                    />
                )}
            </div>
            <div className="flex w-full justify-start items-center border rounded-md p-4 my-4">
                <Icon
                    className="w-[24px] cursor-pointer ml-4 text-blue-800"
                    icon="InfoIcon"
                />
                <span className="text-left text-xs text-gray-500 pl-4">
                    You can customize your trip invitation email here by
                    changing the image and description.
                </span>
            </div>
            <div className="text-left font-semibold	text-2xl text-gray-600 mb-4">
                {customInvite?.title}
            </div>

            <LazyLoadImage
                className="rounded-xl object-cover"
                width="100%"
                alt={customInvite?.title}
                effect="blur"
                src={imageSrc || placeholder}
                placeholderSrc={placeholder}
            />

            <Paragraph className="text-base text-gray-600 font-normal mt-5 text-left">
                {customInvite?.description}
            </Paragraph>
        </div>
    );
};

export default TripCustomInviteDetail;
