import { UserStatusOnOrganization } from ".";

export enum NOTIFICATION_TYPES {
    TRIP_INVITATION = "TRIP_INVITATION",
    ORGANIZATION_INVITATION = "ORGANIZATION_INVITATION",
    TRIP_CANCELLED = "TRIP_CANCELLED",
    TRIP_IMAGE_ADD = "TRIP_IMAGE_ADD",
    TRIP_REQUIREMENT_ADD = "TRIP_REQUIREMENT_ADD",
    TRIP_REQUIREMENT_FILE_ADD = "TRIP_REQUIREMENT_FILE_ADD",
    TRIP_REQUIREMENT_FILE_REJECTED = "TRIP_REQUIREMENT_FILE_REJECTED",
    TRIP_REQUIREMENT_FILE_RESPONSE = "TRIP_REQUIREMENT_FILE_RESPONSE",
    TRIP_INVITATION_RESPONSE = "TRIP_INVITATION_RESPONSE",
    TRIP_ACTIVITY_ADD = "TRIP_ACTIVITY_ADD",
    TRIP_ACTIVITY_REVIEW_ADD = "TRIP_ACTIVITY_REVIEW_ADD",
    TRIP_MESSAGES = "TRIP_MESSAGES",
    ORGANIZATION_INVITATION_RESPONSE = "ORGANIZATION_INVITATION_RESPONSE",
    REMINDER_REQUIREMENTS = "REMINDER_REQUIREMENT",
    REMINDER_INSIGHT = "REMINDER_INSIGHT",
    REMINDER_PUBLIC_REVIEW = "REMINDER_PUBLIC_REVIEW",
    ITEM_REPORTED_ACCEPTED = "ITEM_REPORTED_ACCEPTED",
    ITEM_REPORTED_REJECTED = "ITEM_REPORTED_REJECTED",
    TRIP_INVITATION_CHANGE_ROLE_RESPONSE = "TRIP_INVITATION_CHANGE_ROLE_RESPONSE",
    TRIP_FILE_ADD = "TRIP_FILE_ADD",
    TRIP_ACTIVITY_UPDATE_DATE = "TRIP_ACTIVITY_UPDATE_DATE",
    TRIP_ACTIVITY_UPDATE_PLACE = "TRIP_ACTIVITY_UPDATE_PLACE",
    TRIP_ACTIVITY_UPDATE_TIME = "TRIP_ACTIVITY_UPDATE_TIME",
    TRIP_ACTIVITY_REMOVED = "TRIP_ACTIVITY_UPDATE_REMOVED",
    TRIP_ITINERARY_HIDDEN = "TRIP_ITINERARY_HIDDEN",
    TRIP_ITINERARY_PUBLISHED = "TRIP_ITINERARY_PUBLISHED",
    NEW_USER_REQUEST = "NEW_USER_REQUEST",
    USER_FEEDBACK_RESPONSE = "USER_FEEDBACK_RESPONSE",
    USER_CHANGE_ROLE = "USER_CHANGE_ROLE",
    USER_CHANGE_EMAIL = "USER_CHANGE_EMAIL",
    NEW_CHAT_NOTIFICATION = "NEW_CHAT_NOTIFICATION",
}

export enum SSO_ACTIONS {
    REMOVE_USER = "REMOVE_USER",
}

export const NOTIFICATIONS_WORDAGE = {
    [NOTIFICATION_TYPES.TRIP_REQUIREMENT_FILE_REJECTED]: {
        type: NOTIFICATION_TYPES.TRIP_REQUIREMENT_FILE_REJECTED,
        title: "Documents Rejected",
        description: "Your documents were rejected",
    },
    [NOTIFICATION_TYPES.ORGANIZATION_INVITATION]: {
        type: NOTIFICATION_TYPES.ORGANIZATION_INVITATION,
        title: "Organization Invitation",
        description:
            "You've been invited to join the organization {{organizationName}}",
    },
    [NOTIFICATION_TYPES.ORGANIZATION_INVITATION_RESPONSE]: {
        type: NOTIFICATION_TYPES.ORGANIZATION_INVITATION_RESPONSE,
        title: "Invitation {{response}}",
        description:
            "{{fromName}}'s {{response}} to join the organization {{organizationName}}",
    },
    [NOTIFICATION_TYPES.TRIP_INVITATION]: {
        type: NOTIFICATION_TYPES.TRIP_INVITATION,
        title: "Trip Invitation",
        description: "You've been invited to join the trip {{tripName}}",
    },

    [NOTIFICATION_TYPES.TRIP_CANCELLED]: {
        type: NOTIFICATION_TYPES.TRIP_CANCELLED,
        title: "Trip {{tripName}} has been cancelled",
        description: "Sorry",
    },
    [NOTIFICATION_TYPES.TRIP_IMAGE_ADD]: {
        type: NOTIFICATION_TYPES.TRIP_IMAGE_ADD,
        title: "New images added",
        description: "New images added to the trip {{tripName}}",
    },
    [NOTIFICATION_TYPES.TRIP_REQUIREMENT_ADD]: {
        type: NOTIFICATION_TYPES.TRIP_REQUIREMENT_ADD,
        title: "New Requirement - {{tripTitle}}",
        description: "New requirement added to the trip - {{tripName}}",
    },
    [NOTIFICATION_TYPES.TRIP_REQUIREMENT_FILE_ADD]: {
        type: NOTIFICATION_TYPES.TRIP_REQUIREMENT_FILE_ADD,
        title: "{{tripName}} - Requirement file added by {{fromName}}",
        description: "New requirements added to the trip {{tripName}}",
    },
    [NOTIFICATION_TYPES.TRIP_REQUIREMENT_FILE_RESPONSE]: {
        type: NOTIFICATION_TYPES.TRIP_REQUIREMENT_FILE_RESPONSE,
        title: "{{tripName}} - Requirement documents {{response}}",
        description:
            "{{requirementName}} - Your documents have been {{response}} by the organizer",
    },
    [NOTIFICATION_TYPES.TRIP_INVITATION_RESPONSE]: {
        type: NOTIFICATION_TYPES.TRIP_INVITATION_RESPONSE,
        title: "{{tripName}} - Invitation Response",
        description: "{{fromName}} has {{response}} the invitation",
    },
    [NOTIFICATION_TYPES.TRIP_ACTIVITY_ADD]: {
        type: NOTIFICATION_TYPES.TRIP_ACTIVITY_ADD,
        title: "{{tripName}}",
        description: "New element {{activityType}} : {{activityTitle}}",
    },
    [NOTIFICATION_TYPES.TRIP_ACTIVITY_REVIEW_ADD]: {
        type: NOTIFICATION_TYPES.TRIP_ACTIVITY_REVIEW_ADD,
        title: "{{activityTitle}}",
        description: "New review from {{fromName}}",
    },
    [NOTIFICATION_TYPES.REMINDER_REQUIREMENTS]: {
        type: NOTIFICATION_TYPES.REMINDER_REQUIREMENTS,
        title: "Required documents pending on {{tripName}}",
        description:
            "There are some documents you need to submit before {{date}}",
    },
    [NOTIFICATION_TYPES.REMINDER_PUBLIC_REVIEW]: {
        type: NOTIFICATION_TYPES.REMINDER_PUBLIC_REVIEW,
        title: "How was {{activityName}}?",
        description: "Add a public review",
    },
    [NOTIFICATION_TYPES.REMINDER_INSIGHT]: {
        type: NOTIFICATION_TYPES.REMINDER_INSIGHT,
        title: "Have some insights on {{activityName}}?",
        description: "Add your insights",
    },
    [NOTIFICATION_TYPES.ITEM_REPORTED_ACCEPTED]: {
        type: NOTIFICATION_TYPES.ITEM_REPORTED_ACCEPTED,
        title: "A complaint reported on: {{complaintTitle}}",
        description: "Has been accepted and actions where taken.",
    },
    [NOTIFICATION_TYPES.ITEM_REPORTED_REJECTED]: {
        type: NOTIFICATION_TYPES.ITEM_REPORTED_REJECTED,
        title: "A complaint reported on: {{complaintTitle}}",
        description: "Has been rejected and no actions where taken.",
    },
    [NOTIFICATION_TYPES.TRIP_MESSAGES]: {
        type: NOTIFICATION_TYPES.TRIP_MESSAGES,
        title: "{{tripName}} message",
        description: "New message from {{fromName}}",
    },
};

export enum NOTIFICATION_CTA_TYPES {
    ACCEPT = "ACCEPT",
    DECLINE = "DECLINE",
}

export const ORGANIZATION_USER_STATUS_LABEL = {
    [UserStatusOnOrganization.CONFIRMED]: "Confirmed",
    [UserStatusOnOrganization.REJECTED]: "Rejected",
    [UserStatusOnOrganization.BLOCKED]: "Blocked",
    [UserStatusOnOrganization.EXPIRED]: "Expired",
    [UserStatusOnOrganization.PENDING]: "Pending",
    [UserStatusOnOrganization.DELETED]: "Deleted",
};

export const HIDE_NOTIFICATION_PREFERENCES_EMAIL = ["notificationTripImages"];
