import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../components/Input";
import PhoneInput from "../../../components/PhoneInput";
import Button from "../../../components/ButtonSquare";
import Avatar from "../../../components/Avatar";
import Icon from "@mdi/react";
import { mdiCameraOutline, mdiPlus } from "@mdi/js";
import InputInstagraWithValid from "../../../components/InputInstagram";
import DatePickerMaterial from "../../../components/DatePickerMaterial";
import { deNormalizeProfile, normalizeProfile } from "../../../bussiness/user";
import ControlledCitiesAutocomplete from "../../../components/CitiesSearchAutocomplete";
import {
    ButtonSize,
    FacebookUrlRegex,
    InstagramUrlRegex,
    LinkedinUrlRegex,
    SocialUserNameValidationRegex,
    VALID_IMAGES_FORMATS,
} from "../../../constants";
import { getInstagramUserName, resizeImageFile } from "../../../helpers";

const schema = yup.object({
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    birthDate: yup.date().nullable(),
    fullAddress: yup.string().nullable(),
    phone: yup.string().required("This field is required"),
    agency: yup.string().required("This field is required"),
    facebook: yup
        .string()
        .test("facebook", "Invalid username or url", (value?: string) => {
            if (value) {
                const socialUrl = FacebookUrlRegex.exec(value);
                const socialUsername =
                    SocialUserNameValidationRegex.exec(value);
                return !!socialUrl || !!socialUsername;
            }
            return true;
        })
        .nullable(),
    linkedin: yup
        .string()
        .test("linkedin", "Invalid username or url", (value?: string) => {
            if (value) {
                const socialUrl = LinkedinUrlRegex.exec(value);
                const socialUsername =
                    SocialUserNameValidationRegex.exec(value);
                return !!socialUrl || !!socialUsername;
            }
            return true;
        })
        .nullable(),
    emergencyContactName: yup.string().nullable(),
    emergencyContactPhone: yup.string().nullable(),
    instagram: yup
        .string()
        .test("instagram", "Invalid username or url", (value?: string) => {
            if (value) {
                const socialUrl = InstagramUrlRegex.exec(value);
                const socialUsername =
                    SocialUserNameValidationRegex.exec(value);
                return !!socialUrl || !!socialUsername;
            }
            return true;
        })
        .nullable(),
    location: yup
        .object({
            fullAddress: yup.string().required("This field is required"),
        })
        .nullable(),
});

export default function EditProfile(props: any) {
    const { profile, onSubmit, onCancel } = props;
    const [selectedFile, setSelectedFile] = useState<any>();
    const [preview, setPreview] = useState<string | undefined>("");
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const profileDenormalized = useMemo(() => {
        return deNormalizeProfile(profile);
    }, [profile]);

    useEffect(() => {
        if (profile?.firstName) {
            reset({
                firstName: profileDenormalized.firstName,
                lastName: profileDenormalized.lastName,
                birthDate: profileDenormalized.birthDate,
                phone: profileDenormalized.phone,
                email: profileDenormalized.email,
                agency: profileDenormalized.agency,
                instagram: profileDenormalized.instagram,
                facebook: profileDenormalized.facebook,
                linkedin: profileDenormalized.linkedin,
                emergencyContactName: profileDenormalized.emergencyContactName,
                emergencyContactPhone:
                    profileDenormalized.emergencyContactPhone,
                location: {
                    fullAddress: profile?.fullAddress,
                    city: profile?.city,
                    country: profile?.country,
                    state: profile?.state,
                    lat: profile?.lat,
                    lng: profile?.lng,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const handleSubmitForm = async (data: any) => {
        const { location, ...rest } = data;
        const formData = new FormData();
        if (selectedFile) formData.append("avatar", selectedFile);
        const fields = {
            ...normalizeProfile(rest),
            fullAddress: location?.fullAddress || "",
            city: location?.city || "",
            country: location?.country || "",
            state: location?.state || "",
            lat: location?.lat || null,
            lng: location?.lng || null,
            instagram: data.instagram
                ? getInstagramUserName(data.instagram)
                : undefined,
        };

        formData.append("fields", JSON.stringify(fields));
        onSubmit && onSubmit(formData);
    };

    const onSelectFile = async (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        const imageResized = await resizeImageFile(e.target.files[0]);
        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(imageResized);
    };

    return (
        <>
            <div className="flex flex-col">
                <div className="grid grid-cols-3 gap-x-4 lg:grid-cols-6 flex-wrap">
                    <div className="flex relative mb-6">
                        <label htmlFor="file-upload" className="text-left">
                            {preview || profile?.avatar ? (
                                <Avatar
                                    src={preview || profile?.avatar}
                                    className="w-24 h-24"
                                />
                            ) : (
                                <div className="flex items-center justify-center w-24 h-24 rounded-full border bg-neutral-100 border-dashed border-orange-600">
                                    <Icon
                                        path={mdiPlus}
                                        size="2rem"
                                        color="#ea580c"
                                    />
                                </div>
                            )}
                            <div className="inline">
                                {(preview || profile?.avatar) && (
                                    <div className="bg-orange-500 p-1 cursor-pointer absolute rounded-full inline-block top-16 left-16">
                                        <Icon
                                            path={mdiCameraOutline}
                                            size="1.5rem"
                                            horizontal
                                            vertical
                                            rotate={180}
                                            color="white"
                                        />
                                    </div>
                                )}
                                <input
                                    onChange={onSelectFile}
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    className="sr-only hidden"
                                    accept={VALID_IMAGES_FORMATS.join(", ")}
                                />
                            </div>
                        </label>
                    </div>
                </div>
                <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(handleSubmitForm)}
                    encType="multipart/form-data"
                >
                    <div className="grid grid-cols-3 gap-x-4 lg:grid-cols-6 flex-wrap">
                        <div className="text-left lg:max-w-xs break-words col-span-3">
                            <div className="flex flex-row">
                                <span className="block font-medium text-neutral-600 text-xs">
                                    First name
                                </span>
                                <span className="text-red-500 pl-1">*</span>
                            </div>

                            <Input
                                register={register}
                                type="text"
                                id="firstName"
                                name="firstName"
                                className="!pt-0"
                                error={errors["firstName"]?.message}
                            />
                        </div>
                        <div className="text-left lg:max-w-xs break-words col-span-3">
                            <div className="flex flex-row">
                                <span className="block font-medium text-neutral-600 text-xs">
                                    Last name
                                </span>
                                <span className="text-red-500 pl-1">*</span>
                            </div>

                            <Input
                                register={register}
                                type="text"
                                id="lastName"
                                name="lastName"
                                error={errors["lastName"]?.message}
                            />
                        </div>
                        <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                            <div className="flex flex-row">
                                <span className="block font-medium text-neutral-600 text-xs">
                                    Birth Date
                                </span>
                            </div>

                            <DatePickerMaterial
                                control={control}
                                id="birthDate"
                                name="birthDate"
                                error={errors["birthDate"]?.message}
                            />
                        </div>
                        <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                            <div className="flex flex-row">
                                <span className="block font-medium text-neutral-600 text-xs">
                                    My Location
                                </span>
                                <span className="text-red-500 pl-1">*</span>
                            </div>
                            <ControlledCitiesAutocomplete
                                control={control}
                                id="location"
                                name="location"
                                defaultValue={profile?.fullAddress || ""}
                                error={
                                    (errors as any)?.location?.fullAddress
                                        ?.message
                                }
                            />
                        </div>
                        <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                            <div className="flex flex-row">
                                <span className="block font-medium text-neutral-600 text-xs">
                                    Mobile Phone Number
                                </span>
                                <span className="text-red-500 pl-1">*</span>
                            </div>
                            <PhoneInput
                                control={control}
                                id="phone"
                                name="phone"
                                error={errors["phone"]?.message}
                            />
                        </div>
                        <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Email address
                            </span>

                            <Input
                                disabled
                                register={register}
                                type="text"
                                id="email"
                                name="email"
                                error={errors["email"]?.message}
                            />
                        </div>
                        <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                            <div className="flex flex-row">
                                <span className="block font-medium text-neutral-600 text-xs">
                                    Company Name
                                </span>
                                <span className="text-red-500 pl-1">*</span>
                            </div>

                            <Input
                                register={register}
                                type="text"
                                id="agency"
                                name="agency"
                                error={errors["agency"]?.message}
                            />
                        </div>
                        <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Instagram
                            </span>

                            <div className="flex items-center w-full">
                                <div className="w-full">
                                    <InputInstagraWithValid
                                        register={register}
                                        type="text"
                                        id="instagram"
                                        name="instagram"
                                        error={errors["instagram"]?.message}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                {" "}
                                LinkedIn
                            </span>

                            <Input
                                register={register}
                                type="text"
                                id="linkedin"
                                name="linkedin"
                                error={errors["linkedin"]?.message}
                            />
                        </div>
                        <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Facebook
                            </span>

                            <Input
                                register={register}
                                type="text"
                                id="facebook"
                                name="facebook"
                                error={errors["facebook"]?.message}
                            />
                        </div>

                        <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Emergency contact name
                            </span>

                            <Input
                                register={register}
                                type="text"
                                id="emergencyContactName"
                                name="emergencyContactName"
                                error={errors["emergencyContactName"]?.message}
                            />
                        </div>
                        <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Emergency contact phone
                            </span>

                            <PhoneInput
                                control={control}
                                id="emergencyContactPhone"
                                name="emergencyContactPhone"
                                error={errors["emergencyContactPhone"]?.message}
                            />
                        </div>
                    </div>

                    <div className="mt-12 flex gap-4 md:justify-end">
                        <Button
                            size={ButtonSize.NORMAL}
                            outlined
                            label="Cancel"
                            onClick={() => {
                                onCancel && onCancel();
                            }}
                        />

                        <Button
                            size={ButtonSize.NORMAL}
                            type="submit"
                            label="Save"
                        />
                    </div>
                </form>
            </div>
        </>
    );
}
