import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../app/store";

export interface IConfig {
    isLoading: Boolean;
    maintenanceMode: any;
    error: string;
}

const initialState: IConfig = {
    isLoading: false,
    maintenanceMode: null,
    error: "",
};

export const configSlice = createSlice({
    name: "config",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setMaintenanceMode: (
            state,
            action: PayloadAction<{ maintenanceMode: Boolean }>
        ) => {
            state.maintenanceMode = action.payload.maintenanceMode;
        },
    },
});

export const { isLoading, setMaintenanceMode, setError } = configSlice.actions;
export const selectMaitenanceMode = (state: RootState) =>
    state.config.maintenanceMode;

export const getMaintenanceMode =
    (): AppThunk => async (dispatch, getState, Firebase: any) => {
        try {
            Firebase.getMaintenanceMode((data: any) => {
                const isMaintainer =
                    window.localStorage.getItem("maintenanceMode");

                dispatch(
                    setMaintenanceMode({
                        maintenanceMode: {
                            ...(data || {}),
                            isMaintainer: !!isMaintainer,
                        },
                    })
                );
            });
        } catch (error) {
        }
    };

export default configSlice.reducer;
