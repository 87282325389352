import { MouseEventHandler, useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../../assets/svg/Icon";

interface ISidebarOption {
    label: string;
    icon: string;
    url: string;
    selected: boolean;
    handleClick?: MouseEventHandler;
    basePath: string;
}
const fillSelectedColor = "fill-primary_orange-900";
const fillHoverColor = "fill-primary_orange-900";
const fillNormalColor = "fill-neutral-800";

export const SidebarOption = (props: ISidebarOption) => {
    const [currentColor, setCurrentColor] = useState<string>(fillNormalColor);
    const { icon, label, url, selected, handleClick, basePath } = props;
    return (
        <Link
            onClick={handleClick}
            onMouseEnter={() => setCurrentColor(fillHoverColor)}
            onMouseLeave={() => setCurrentColor(fillNormalColor)}
            to={`${basePath}${url}`}
            className={`py-2 no-underline flex items-center text-left gap-4 text-base font-semibold ${
                selected ? "text-primary_orange-900" : "text-neutral-800"
            }  hover:text-primary_orange-900 cursor-pointer`}
        >
            <Icon
                icon={icon}
                className={`${selected ? fillSelectedColor : currentColor} w-6`}
            />
            <span className="hidden lg:block">{label}</span>
        </Link>
    );
};
