import { useState } from "react";
import { SupportVideItem } from "./SupportVideoItem";
import Icon from "../../assets/svg/Icon";
import Modal, { Modal_Size } from "../Modal";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import { getYoutubeVideoId, isYoutubeVideo } from "../../helpers";
import { mdiClose, mdiCursorMove, mdiYoutubeTv } from "@mdi/js";
import ButtonSquared from "../ButtonSquare";
import { FeedbackForm } from "../../features/layout/feedback/FeedbackForm";

interface ModuleSupportI {
    videoList: any[];
    open: boolean;
    onClose: any;
    title: string;
    tooltip?: string;
}

export default function ModuleSupport({
    videoList,
    open,
    onClose,
    tooltip,
    title,
}: ModuleSupportI) {
    const [floatingView, setFloatingView] = useState<any>(null);
    const [openFeedbackForm, setOpenFeedbackForm] = useState<boolean>(false);
    return (
        <>
            <Modal size={Modal_Size.lg} open={open} onClose={onClose}>
                <RenderSupportModule
                    tooltip={tooltip}
                    title={title}
                    videoList={videoList}
                    onFloatingSelect={(youtubeId: string) => {
                        setFloatingView(youtubeId);
                        onClose();
                    }}
                />
                <div className="flex w-full mt-4 justify-start px-2">
                    <div className="w-fit flex flex-col justify-start items-start">
                        <div className="text-left text-lg mb-2 text-neutral-800 font-semibold">
                            Report or send feedback
                        </div>
                        <ButtonSquared
                            onClick={() => {
                                onClose();
                                setOpenFeedbackForm(true);
                            }}
                            outlined
                            label="Send feedback"
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                size={Modal_Size.small}
                open={openFeedbackForm}
                onClose={() => {
                    setOpenFeedbackForm(false);
                }}
            >
                <FeedbackForm
                    onFinish={() => {
                        setOpenFeedbackForm(false);
                    }}
                />
            </Modal>
            {floatingView && (
                <Draggable handle=".handle-icon">
                    <div className="custom-youtube-player z-50">
                        <ResizableBox width={450} height={300}>
                            <iframe
                                title={"floatingVideo"}
                                id="player"
                                style={{ width: "100%", height: "100%" }}
                                src={`https://www.youtube.com/embed/${floatingView}`}
                            ></iframe>

                            <div
                                className="close-icon flex content-center"
                                onClick={() => setFloatingView(null)}
                            >
                                <Icon
                                    materialIcon={mdiClose}
                                    className={`w-7 text-white`}
                                />
                            </div>
                            <div className="handle-icon flex content-center">
                                <Icon
                                    materialIcon={mdiCursorMove}
                                    className={`w-7 text-white`}
                                />
                            </div>
                        </ResizableBox>
                    </div>
                </Draggable>
            )}
        </>
    );
}

const RenderSupportModule = ({
    videoList,
    onFloatingSelect,
    tooltip,
    title,
}: {
    videoList?: any[];
    onFloatingSelect: any;
    tooltip?: string;
    title?: string;
}) => {
    const [selectedItem, setSelectedItem] = useState<any>(null);
    if (!selectedItem) {
        return (
            <div className="w-full md:px-2">
                {!!tooltip?.length && (
                    <>
                        <div className="text-neutral-900 text-lg font-semibold mb-1">
                            {title}
                        </div>
                        <div className="text-sm text-neutral-800 mb-4">
                            {tooltip}
                        </div>
                    </>
                )}
                {!!videoList?.length && (
                    <>
                        <h1 className="text-neutral-900 text-lg font-semibold">
                            {"Video tutorials"}
                        </h1>
                        <div className="max-h-[80vh] overflow-auto mt-1">
                            {videoList?.map((v: any, index) => {
                                return (
                                    <SupportVideItem
                                        item={v}
                                        key={v.id}
                                        preview
                                        showTopBorder={index > 0}
                                        onSelect={() => {
                                            setSelectedItem(v);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        );
    } else {
        return (
            <div className="w-full md:px-2">
                <div className="flex flex-row gap-6">
                    <div
                        onClick={() => setSelectedItem(false)}
                        className="text-primary_orange-900 cursor-pointer w-fit flex flex-row items-center"
                    >
                        <Icon
                            icon={"ChevronLeftIcon"}
                            className={`w-7 text-primary_orange-900`}
                        />
                        Back
                    </div>
                </div>

                <SupportVideItem item={selectedItem} />
                {isYoutubeVideo(selectedItem?.videoLink) && (
                    <div
                        onClick={() =>
                            onFloatingSelect(
                                getYoutubeVideoId(selectedItem.videoLink)
                            )
                        }
                        className="text-primary_orange-900 cursor-pointer w-fit flex flex-row items-center"
                    >
                        <Icon
                            materialIcon={mdiYoutubeTv}
                            className={`w-7 text-primary_orange-900 mr-1`}
                        />
                        Mini player
                    </div>
                )}
            </div>
        );
    }
};
