import { useRef, useState } from "react";
import {
    TOAST_TYPE,
    VALID_IMAGES_FORMATS,
    BYTES_IN_KB,
    KB_IN_MB,
} from "../constants";
import { resizeImageFile, sendToast } from "../helpers";

const errorMessage =
    "Some files could not be uploaded because they are not valid images or are too big.";
const useDropImageFileInput = (onChange: Function) => {
    let [dragOverlay, setDragOverlay] = useState(false);
    let dragCounter = useRef(0);
    const [error, setError] = useState(false);

    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragIn = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;
        if (e.dataTransfer.items.length > 0) {
            setDragOverlay(true);
        }
    };
    const handleDragOut = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current === 0) {
            setDragOverlay(false);
        }
    };
    const handleDrop = (e: any) => {
        const files = e.dataTransfer.files;
        e.preventDefault();
        e.stopPropagation();
        setDragOverlay(false);
        dragCounter.current = 0;

        changeFile({ target: { files } });
    };

    const changeFile = async (e: any) => {
        let withError = false;
        const filesArray: any[] = Array.from(e.target.files);
        const myFiles: any[] = [];
        for (const file of filesArray) {
            const { type } = file;
            const validFormat = VALID_IMAGES_FORMATS.includes(type);
            // If the file is not an image, skip it
            if (!validFormat) {
                withError = withError || !validFormat;
                continue;
            }
            // If the file is an image, resize it
            const imageResized = await resizeImageFile(file);
            const { size } = imageResized;
            const validSize = size / BYTES_IN_KB / KB_IN_MB < 10;
            // If the image is too big, skip it
            if (!validSize) {
                withError = withError || !validSize;
                continue;
            }
            myFiles.push(new File([imageResized], file.name));
        }

        setError(withError);

        if (myFiles.length) {
            onChange({ target: { files: myFiles } });
        }
    };

    return {
        error,
        errorMessage,
        dragOverlay,
        handleDrag,
        handleDragIn,
        handleDrop,
        handleDragOut,
        changeFile,
    };
};
export default useDropImageFileInput;
