import moment from "moment";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../app/hooks";
import { getLinkFromNotification } from "../../bussiness/notifications";
import { markNotificationAsRead } from "../../features/notifications/notificationsSlice";
import ModalConfirmation from "../Modal/ConfirmationModal";
import { useState } from "react";
import { Modal_Size } from "../Modal";
import { hasComments } from "../../features/notifications/utils";

interface NotificationsCardProps {
    notification: {
        id: string;
        data: { _body: string; _title: string };
        read: boolean;
        date: number;
        type: string;
    };
}

export const NotificationsCard = ({ notification }: NotificationsCardProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedNotification, setSelectedNotification] =
        useState<any>(false);
    const handleClickNotification = () => {
        if (hasComments(notification?.type)) {
            setSelectedNotification(notification);
        } else {
            dispatch(markNotificationAsRead(notification.id));
            const to = getLinkFromNotification(notification);
            navigate(to);
        }
    };

    return (
        <>
            <div
                className="grid grid-cols-12 gap-4 p-2 items-baseline cursor-pointer hover:bg-neutral-100"
                onClick={handleClickNotification}
            >
                {!notification.read ? (
                    <div className="col-span-1 bg-orange-500 border-2 border-orange-500 h-2 w-2 rounded-full"></div>
                ) : (
                    <div className="col-span-1 h-2 w-2 mr-4"></div>
                )}
                <div className="truncate col-span-11">
                    <div className="flex items-start gap-2">
                        <div className="text-sm font-semibold truncate">
                            {notification.data?._title}
                        </div>
                        <span className="text-xs self-center">
                            {moment(
                                new Date(Number(notification.date))
                            ).fromNow(true)}
                        </span>
                    </div>
                    <span className="text-sm pt-1 break-words whitespace-pre-wrap line-clamp-2">
                        {notification.data?._body}
                    </span>
                </div>
            </div>
            <ModalConfirmation
                open={!!selectedNotification}
                title={selectedNotification?.data?.title}
                description={selectedNotification?.data?.description}
                cancelButtonText="Close"
                size={Modal_Size.medium}
                handleCancel={() => {
                    setSelectedNotification(false);
                }}
            />
        </>
    );
};
