import React, { FC, InputHTMLAttributes } from "react";
import TextAreaInput from "./TextArea";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: any;
  register?: any;
  className?: string;
  markRequired?: boolean;
}

const TextareaWithValid: FC<InputProps> = ({
  register,
  name,
  error,
  label,
  ...rest
}) => {
  return (
    <TextAreaInput
      aria-invalid={error ? "true" : "false"}
      {...register(name)}
      error={error}
      label={label}
      name={name}
      {...rest}
    />
  );
};

export default TextareaWithValid;
