import { useMemo } from "react";
import { TopTripBanner } from "./TopTripBanner";
import moment from "moment";
import { useNavigate } from "react-router";

export const SuspendedTripBanner = ({
    suspended,
    link,
    gracePeriodStarts,
    gracePeriodEnds,
    inGracePeriod,
    billingDateFormat,
    isOwner,
}: any) => {
    const navigate = useNavigate();
    const onRenew = () => {
        if (link) {
            window.open(link, "_blank");
        } else {
            navigate("/plans");
        }
    };
    const daysLeft = useMemo(() => {
        return moment(gracePeriodEnds, billingDateFormat).diff(
            moment(moment.now()),
            "days"
        );
    }, [gracePeriodEnds, billingDateFormat]);

    if (!suspended && !inGracePeriod) return null;

    return (
        <TopTripBanner
            title={
                suspended
                    ? "Your trip has been suspended."
                    : "Membership Plan Subscription Grace Period: Renew Soon to Avoid Trip Disruption"
            }
            text={
                suspended
                    ? `Your trip has been suspended and converted to read-only mode due to lack of payment. Please pay for your plan to resume your trip and regain full access to all features.`
                    : `Your subscription ended on ${gracePeriodStarts}, but you still have ${daysLeft} days to renew your plan before your trip is disrupted. Renew today to avoid read-only mode and continue your trip without interruption.`
            }
            action={isOwner ? onRenew : null}
            actionLabel="Renew Subscription"
        />
    );
};
