import { PAGE_SECTIONS } from "../../../../../../../../constants";
import { addTripActivityOtherInsight } from "../../../../../../../hotelInsight/hotelInsightSlice";
import InsightNotesView from "../../components/InsightNotesView/InsightNotesView";

export default function RestaurantNotesInsightReview() {
    const currentSection =
        PAGE_SECTIONS.TRIP_ITINERARY_OTHER_PRIVATE_REVIEW_DETAILS;

    return (
        <InsightNotesView
            insightKey="otherInsight"
            currentSection={currentSection}
            addInsightNotes={addTripActivityOtherInsight}
        />
    );
}
