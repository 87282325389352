import Icon from "../../../../../assets/svg/Icon";
import IconBox from "../../../../../components/IconBox";
import { ActivityType } from "../../../../../constants";

interface ItineraryActionsListProps {
    date: string;
    onActivitySelected: any;
    className?: string;
}

export const ItineraryActionsList = (props: ItineraryActionsListProps) => {
    const { onActivitySelected, date, className } = props;
    const iconClassname = "cursor-pointer w-5 h-5 lg:w-7 lg:h-7 text-orange-600 float-left"

    return (
        <div className={`flex gap-5 ${className}`}>
            <IconBox
                title="Add Hotel Stay"
                onClick={() => {
                    return onActivitySelected(ActivityType.HOTEL, date);
                }}
            >
                <Icon icon={"BedIcon"}  className={iconClassname} />
            </IconBox>
            <IconBox
                title="Add Site Inspection"
                onClick={() => {
                    return onActivitySelected(ActivityType.VISIT, date);
                }}
            >
                <Icon icon={"BuildingIcon"}  className={iconClassname} />
            </IconBox>
            <IconBox
                title="Add Restaurant"
                onClick={() => {
                    return onActivitySelected(ActivityType.RESTAURANT, date);
                }}
            >
                <Icon icon={"RestaurantIcon"}  className={iconClassname} />
            </IconBox>
            <IconBox
                title="Add other element"
                hover=" (activities, transfers, etc..)"
                onClick={() => {
                    return onActivitySelected(ActivityType.OTHER, date);
                }}
            >
                <Icon icon={"CalendarPlusIcon"}  className={iconClassname} />
            </IconBox>
        </div>
    );
};

export default ItineraryActionsList;
