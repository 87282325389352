import React from "react";
import SelectInput from "../InputSelect/SelectInput";
import countries from "i18n-iso-countries";
import enLocal from "i18n-iso-countries/langs/en.json";
countries.registerLocale(enLocal);

const countryObj = countries.getNames("en", { select: "official" });
const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
        label: value,
        value: key,
    };
});

const CountrySelectInput = React.forwardRef((props: any, ref: any) => {
    const {
        label,
        id,
        name,
        type,
        placeholder,
        defaultValue,
        error,
        onChange,
        value,
        ...others
    } = props;

    return (
        <>
            <SelectInput
                
                options={countryArr}
                onChange={onChange}
                innerRef={ref}
                id={id}
                name={name}
                value={value}
                defaultValue={defaultValue}
                {...others}
            />
        </>
    );
});

export default CountrySelectInput;
