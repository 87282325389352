// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.main {
    min-height: calc(100vh - 68px);
}

.custom-youtube-player {
    position: fixed;
    width: 450px;
    height: 300px;
    bottom: 40px;
    right: 40px;
    background: #000;
}
.custom-youtube-player .close-icon,
.custom-youtube-player .handle-icon {
    position: absolute;
    right: 0px;
    top: -30px;
    width: 32px;
    height: 32px;
    background: #000;
    color: #fff;
    opacity: 1;
    padding: 3px;
    cursor: pointer;
}
.custom-youtube-player .handle-icon {
    right: 32px;
    padding-top: 8px;
    cursor: move;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI;QACI,iDAAiD;IACrD;AACJ;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;AACjC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,2BAA2B;IAC/B;IACA;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;AACA;;IAEI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,WAAW;IACX,UAAU;IACV,YAAY;IACZ,eAAe;AACnB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".App {\n    text-align: center;\n}\n\n.App-logo {\n    height: 40vmin;\n    pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n    .App-logo {\n        animation: App-logo-float infinite 3s ease-in-out;\n    }\n}\n\n.App-header {\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n}\n\n.App-link {\n    color: rgb(112, 76, 182);\n}\n\n@keyframes App-logo-float {\n    0% {\n        transform: translateY(0);\n    }\n    50% {\n        transform: translateY(10px);\n    }\n    100% {\n        transform: translateY(0px);\n    }\n}\n\n.main {\n    min-height: calc(100vh - 68px);\n}\n\n.custom-youtube-player {\n    position: fixed;\n    width: 450px;\n    height: 300px;\n    bottom: 40px;\n    right: 40px;\n    background: #000;\n}\n.custom-youtube-player .close-icon,\n.custom-youtube-player .handle-icon {\n    position: absolute;\n    right: 0px;\n    top: -30px;\n    width: 32px;\n    height: 32px;\n    background: #000;\n    color: #fff;\n    opacity: 1;\n    padding: 3px;\n    cursor: pointer;\n}\n.custom-youtube-player .handle-icon {\n    right: 32px;\n    padding-top: 8px;\n    cursor: move;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
