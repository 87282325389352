import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonGroup from "../../../components/ButtonGroup";
import { PAGE_SECTIONS, periodOptions, TIME_PERIOD } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import { getOverview, selectOverview } from "../adminSlice";
import OverviewElement from "./components/overviewElement";

export default function OverviewAdminHome(props: any) {
    const dispatch = useAppDispatch();
    const [period, setPeriod] = useState(TIME_PERIOD.DAY);
    const overview = useAppSelector(selectOverview);

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_OVERVIEW })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    const getData = () => {
        dispatch(getOverview(period));
    };

    return (
        <div className="mb-12">
            <div className="text-neutral-900 text-lg text-left font-semibold mb-6">
                Performance
            </div>

            <ButtonGroup
                selectedOption={period}
                options={periodOptions}
                onSelectOption={setPeriod}
            />

            <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                Users
            </div>
            <div className="flex flex-row flex-wrap gap-12 text-left">
                <OverviewElement
                    label="Total Active Users"
                    value={overview?.totalUsers}
                />
                <OverviewElement
                    label="Last LogIns"
                    value={overview?.logIns}
                    trend={overview?.logInsRate}
                    period={period}
                />
                <OverviewElement
                    label="Sign-ups"
                    value={overview?.signUps}
                    trend={overview?.signUpsRate}
                    period={period}
                />
            </div>

            <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                Trips
            </div>
            <div className="flex flex-row flex-wrap gap-x-12 text-left">
                <OverviewElement
                    label="Total Trips"
                    value={overview?.totalTrips}
                    trend={overview?.totalTripsRate}
                    period={period}
                />
                <OverviewElement
                    label="Active Trips"
                    value={overview?.activeTrips}
                    trend={overview?.activeTripsRate}
                    period={period}
                />
            </div>
            <div className="flex flex-row flex-wrap  gap-x-12">
                <div className="flex flex-col flex-grow">
                    <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                        Insights
                    </div>
                    <div className="flex flex-row flex-wrap gap-x-12 text-left">
                        <OverviewElement
                            label="Total"
                            value={overview?.totalInsights}
                            trend={overview?.newInsightsRate}
                            period={period}
                        />
                        <OverviewElement
                            label="New"
                            value={overview?.newInsights}

                        />
                    </div>
                </div>
                <div className="flex flex-col flex-grow">
                    <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                        Reviews
                    </div>
                    <div className="flex flex-row flex-wrap gap-12 text-left">
                        <OverviewElement
                            label="Total"
                            value={overview?.totalReviews}
                            trend={overview?.newReviewsRate}
                            period={period}
                        />
                        <OverviewElement
                            label="New"
                            value={overview?.newReviews}

                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row flex-wrap  gap-x-12">
                <div className="flex flex-col flex-grow">
                    <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                        Hotels
                    </div>
                    <div className="flex flex-row flex-wrap gap-x-12 text-left">
                        <OverviewElement
                            label="Total"
                            value={overview?.totalHotels}
                            trend={overview?.newHotelsRate}
                            period={period}
                        />
                        <OverviewElement
                            label="New"
                            value={overview?.newHotels}

                        />
                    </div>
                </div>
                <div className="flex flex-col flex-grow">
                    <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                        Restaurants
                    </div>
                    <div className="flex flex-row flex-wrap gap-12 text-left">
                        <OverviewElement
                            label="Total"
                            value={overview?.totalRestaurants}
                            trend={overview?.newRestaurantsRate}
                            period={period}
                        />
                        <OverviewElement
                            label="New"
                            value={overview?.newRestaurants}

                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-x-12">
                <div className="flex flex-col flex-grow">
                    <div className="text-neutral-900 text-md text-left font-semibold mt-8 mb-4">
                        Others
                    </div>
                    <div className="flex flex-row flex-wrap gap-x-12 text-left">
                        <OverviewElement
                            label="Total"
                            value={overview?.totalOthers}
                            trend={overview?.newOthersRate}
                            period={period}
                        />
                        <OverviewElement
                            label="New"
                            value={overview?.newOthers}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
