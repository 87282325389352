import React, { FC, useEffect, useState } from "react";
import { PAGE_SECTIONS } from "../../../../../../../../constants";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../../../../app/hooks";
import { isLoadingSelector } from "../../../../../../../layout";
import { setCurrentSection } from "../../../../../../../layout/layoutSlice";
import InsightHeader from "../InsightHeader";

interface InsightDetailWrapperProps {
    form: FC<{ closeForm: () => void }>;
    insight: {
        insightId: number;
        [key: string]: any;
    };
    children: React.ReactNode;
    currentSection: PAGE_SECTIONS;
    title?: React.ReactNode | string;
    showFormCondition?: boolean;
}

export default function InsightDetailWrapper({
    form: Form,
    insight,
    children,
    currentSection,
    title,
    showFormCondition,
}: InsightDetailWrapperProps) {
    const dispatch = useAppDispatch();
    const loading = useSelector(isLoadingSelector);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loading) return;
        // Shows the formif there is no insightId aka is a new insight or if the showFormCondition is true
        setShowForm(
            showFormCondition !== undefined
                ? showFormCondition
                : !insight?.insightId
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, insight]);

    const toggleShowForm = () => setShowForm((prev) => !prev);

    if (showForm) {
        return <Form closeForm={toggleShowForm} />;
    }

    return (
        <div className="flex flex-col text-left">
            <InsightHeader
                title={title}
                showEditButton={!showForm}
                onEdit={toggleShowForm}
            />
            {children}
        </div>
    );
}
