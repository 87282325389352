import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch } from "../../../../../../../../app/hooks";
import { PAGE_SECTIONS } from "../../../../../../../../constants";
import {
    addHotelCommonInsight,
    removeHotelCommonInsight,
} from "../../../../../../../hotelInsight/hotelInsightSlice";
import {
    COMMON_TYPE,
    CommonTypeOptions,
} from "../../../../../../../../constants/insight";
import { FormCommonItem } from "./FormCommonItem";
import { useSelector } from "react-redux";
import { selectActivityInsight } from "../../../../../../tripSlice";
import { ListInsightItems } from "../../components/ListInsightItems";
import InsightGenericView from "../../components/InsightGenericView";
import CommonInsightDetails from "./CommonInsightDetails";

const schema = yup
    .object({
        comments: yup.string().max(5000),
        name: yup.string().max(500).required("This field is required"),
        type: yup.string().max(500).required("This field is required"),
        typeOther: yup
            .string()
            .max(500)
            .when("type", {
                is: COMMON_TYPE.OTHER,
                then: yup.string().required("This field is required"),
            }),
    })
    .required("This field is required");

export default function CommonInsightReview() {
    const dispatch = useAppDispatch();
    const activityInsight = useSelector(selectActivityInsight);
    const hotelInsight = activityInsight?.hotelInsight;
    const { activityId, tripId } = useParams();

    const listCommonInsight = hotelInsight?.commonInsights || [];

    const handleSubmitForm = async (data: any) => {
        const payload = {
            ...data,
            type: data.type || COMMON_TYPE.OTHER,
            typeOther: data.type === COMMON_TYPE.OTHER ? data.typeOther : "",
        };
        await dispatch(
            addHotelCommonInsight(
                Number(tripId),
                Number(activityId),
                activityInsight?.id,
                payload
            )
        );
    };

    const getItemTitle = (item: any) => {
        if (item.type === COMMON_TYPE.OTHER) {
            return item.typeOther;
        }
        const typeLabel = CommonTypeOptions.find(
            (option) => option.value === item.type
        )?.label;
        return typeLabel || "";
    };

    const handleRemoveInsight = (itemId: number) => {
        dispatch(
            removeHotelCommonInsight(
                Number(tripId),
                Number(activityId),
                activityInsight?.id,
                itemId
            )
        );
    };

    return (
        <InsightGenericView
            currentSection={
                PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_COMMON
            }
            label="Common Areas"
            items={listCommonInsight}
            commentsKey="commonComments"
            imagesKey="commonImages"
            itemDetailsView={CommonInsightDetails}
            onRemove={handleRemoveInsight}
            listInsightItems={(props) => (
                <ListInsightItems
                    {...props}
                    getItemTitle={getItemTitle}
                    subtitleKey="name"
                    icon={"CommonAreaIcon"}
                />
            )}
            formInsightItem={FormCommonItem}
            onSubmit={handleSubmitForm}
            schema={schema}
        />
    );
}
