import moment from "moment";
import { useMemo } from "react";
import { useAppSelector } from "../../../../app/hooks";
import DatePickerMaterial from "../../../../components/DatePickerMaterial/DatePickerMaterial";
import DotsMenu from "../../../../components/DotsMenu";
import InputSearch from "../../../../components/InputSearch";
import Paginator from "../../../../components/Paginator";
import Table from "../../../../components/Table";
import { PAGE_ROWS } from "../../../../constants";
import { ElementAdminDotMenuOptions } from "../../../../constants/admin";
import { IPaginatedData } from "../../../../types/dataTypes";
import { selectCurrentBreakpoint } from "../../../layout/layoutSlice";
import ElementsAdminList from "./ElementsAdminList";
import { nonUndefinedString } from "../../../../helpers";

interface elementsTableProps {
    title: string;
    elements: IPaginatedData;
    startDate: any;
    endDate: any;
    handleSearchChange: Function;
    handleStartDateChange: Function;
    handleEndDateChange: Function;
    handlePaginatorChange: Function;
    handleClickDotsMenu?: Function;
}

export const ElementsAdminTable = (props: elementsTableProps) => {
    const {
        title,
        elements,
        startDate,
        endDate,
        handleSearchChange,
        handleStartDateChange,
        handleEndDateChange,
        handlePaginatorChange,
        handleClickDotsMenu,
    } = props;
    const tableHeaders = ["Name", "Date Added", "Location", "Owner", ""];
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    const normalizedElements = useMemo(() => {
        return {
            ...elements,
            data: (elements?.data || []).map((e: any) => {
                return {
                    ...e,
                    formattedDate: moment(e.createdAt).format("DD MMM YY"),
                    location: `${nonUndefinedString(
                        e?.city || e?.state || ""
                    )} - ${nonUndefinedString(e?.country)}`,
                    formattedUSer:
                        e.createdBy?.lastName || e.createdBy?.firstName
                            ? `${e.createdBy?.firstName} ${e.createdBy?.lastName}`
                            : e.createdBy?.email,
                };
            }),
        };
    }, [elements]);

    return (
        <>
            <div className="text-neutral-900 text-left font-semibold mb-4">
                {title}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-12 items-end gap-6 lg:gap-10">
                <div className="col-span-1 lg:col-span-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>

                <div className="col-span-1 lg:col-span-3">
                    <DatePickerMaterial
                        name="startDate"
                        value={startDate}
                        id="startDate"
                        label="From date"
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className="col-span-1 lg:col-span-3">
                    <DatePickerMaterial
                        name="endDate"
                        value={endDate}
                        id="startDate"
                        label="To date"
                        onChange={handleEndDateChange}
                    />
                </div>
            </div>

            {isDesktop ? (
                <>
                    <Table
                        headers={tableHeaders}
                        isLoading={elements?.isLoading}
                    >
                        {normalizedElements?.data?.map(
                            (element: any, index: number) => (
                                <tr key={`${element?.id} - ${index}`}>
                                    <td
                                        className="text-left max-w-[250px] truncate"
                                        title={element?.name}
                                    >
                                        {element?.name}
                                    </td>
                                    <td className="text-left">
                                        {element?.formattedDate}
                                    </td>
                                    <td
                                        className="text-left max-w-[250px] truncate"
                                        title={element?.location}
                                    >
                                        {element?.location}
                                    </td>
                                    <td
                                        className="text-left max-w-[250px] truncate"
                                        title={element?.formattedUSer}
                                    >
                                        {element?.formattedUSer}
                                    </td>
                                    <td className="text-right">
                                        <DotsMenu
                                            options={ElementAdminDotMenuOptions}
                                            handleClickMenu={(k: any) => {
                                                handleClickDotsMenu &&
                                                    handleClickDotsMenu(
                                                        k,
                                                        element
                                                    );
                                            }}
                                        />
                                    </td>
                                </tr>
                            )
                        )}
                    </Table>
                    {normalizedElements?.count > PAGE_ROWS && (
                        <div className="self-end text-xs z-20 mt-4">
                            <Paginator
                                count={normalizedElements?.count}
                                take={PAGE_ROWS}
                                onChange={handlePaginatorChange}
                                totalPages={normalizedElements?.totalPages}
                                currentPage={normalizedElements?.currentPage}
                            />
                        </div>
                    )}
                </>
            ) : (
                <ElementsAdminList
                    elements={normalizedElements}
                    handlePaginatorChange={handlePaginatorChange}
                    handleClickDotsMenu={handleClickDotsMenu}
                />
            )}
        </>
    );
};
