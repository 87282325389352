import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Button from "../../../../../components/ButtonSquare";
import {
    addActivityToLibrary,
    selectActivityInsight,
    selectedTrip,
    selectedTripActivity,
} from "../../../tripSlice";
import { selectProfile } from "../../../../authentication/userSlice";
import Icon from "../../../../../assets/svg/Icon";
import {
    ButtonSize,
    UserRoleOnTrip,
    UserStatusOnTrip,
} from "../../../../../constants";
import { useConstantsContext } from "../../../../../context/Constants";

interface ActivityDetailButtonsI {
    setDeleteActivityModalOpen: (v: boolean) => void;
}

const ActivityDetailButtons = ({
    setDeleteActivityModalOpen,
}: ActivityDetailButtonsI) => {
    const navigate = useNavigate();
    const trip = useAppSelector(selectedTrip);
    const selectedActivity = useAppSelector(selectedTripActivity);
    const activityInsight = useAppSelector(selectActivityInsight);
    const profile = useAppSelector(selectProfile);
    const dispatch = useAppDispatch();
    const { constants } = useConstantsContext();

    const handleEditClick = () => {
        navigate(`edit`);
    };

    const handleAddPrivateInsight = () => {
        const activityType = selectedActivity?.type.toLowerCase();
        navigate(`add-private-${activityType}-insight`);
    };

    const handleAddToLibrary = () => {
        const tripId = trip?.id;
        const activityId = selectedActivity?.id;
        dispatch(addActivityToLibrary(tripId, activityId));
    };

    const handleGoToLibraryElement = () => {
        const organizationId = trip?.organization?.id;
        const activityType = selectedActivity?.type;
        const libraryElementId = selectedActivity?.libraryElementId;
        navigate(
            `/organizations/${organizationId}/library/${activityType}/${libraryElementId}`
        );
    };

    const canEdit = profile?.isAdmin || (trip?.canEdit && !trip?.suspended);
    const userOnTrip = trip?.users?.find(
        (u: { userEmail: string }) => u.userEmail === profile?.email
    );
    const canAddReview =
        !trip?.suspended &&
        userOnTrip?.status === UserStatusOnTrip.CONFIRMED &&
        userOnTrip?.role !== UserRoleOnTrip.VIEWER;
    const activityInsightExists = (() => {
        if (!Object.keys(selectedActivity || {}).length) return false;
        const activityType = selectedActivity?.type.toLowerCase();
        const activity = activityInsight?.[`${activityType}Insight`];
        return !!activity;
    })();

    return (
        <div
            className={`flex justify-${
                canAddReview ? "between" : "end"
            } w-full`}
        >
            {canAddReview && (
                <Button
                    outlined
                    label={`${
                        activityInsightExists ? "View" : "Add"
                    } your Insights`}
                    onClick={handleAddPrivateInsight}
                />
            )}
            {canEdit && (
                <div className="flex gap-4 items-center">
                    {constants?.featureFlags?.LIBRARY && (
                        <>
                            {!!selectedActivity?.libraryElementId ? (
                                <span
                                    className="flex text-md whitespace-nowrap text-orange-600 font-bold cursor-pointer"
                                    onClick={handleGoToLibraryElement}
                                >
                                    See library element
                                </span>
                            ) : (
                                <Button
                                    outlined
                                    label="Add to Library"
                                    onClick={handleAddToLibrary}
                                />
                            )}
                        </>
                    )}
                    <Button
                        outlined
                        label={
                            <Icon
                                icon="PencilIconOutline"
                                className="w-6 h-6 text-neutral-400 hover:border-neutral-300"
                            />
                        }
                        onClick={handleEditClick}
                        className="p-2 hover:!border-neutral-300 !border-neutral-400"
                        size={ButtonSize.FIT}
                    />
                    <Button
                        outlined
                        size={ButtonSize.FIT}
                        label={
                            <Icon
                                icon="TrashIcon"
                                className="w-6 h-6 text-neutral-400 hover:border-neutral-300"
                            />
                        }
                        className="p-2 hover:!border-neutral-300 !border-neutral-400"
                        onClick={() => setDeleteActivityModalOpen(true)}
                    />
                </div>
            )}
        </div>
    );

};

export default ActivityDetailButtons;
