import Icon from "../../../../assets/svg/Icon";
import DotsMenu from "../../../../components/DotsMenu";
import ListWithPagination from "../../../../components/ListWithPagination";
import { ActivityIcon } from "../../../../constants";
import { IPaginatedData } from "../../../../types/dataTypes";

interface InsightsResponsiveListPropsI {
    insights: IPaginatedData;
    handleNext?: any;
    DotMenuOptions: any;
    handleClickDotsMenu: any;
    handlePaginatorChange: any;
    onInsightClick?: any;
}

const InsightsResponsiveList = (props: InsightsResponsiveListPropsI) => {
    const {
        insights,
        DotMenuOptions,
        handleClickDotsMenu,
        handlePaginatorChange,
        onInsightClick,
    } = props;

    const renderInsight = ({ item: insight, index }: any) => {
        return (
            <div
                onClick={() => onInsightClick && onInsightClick(insight)}
                className={`mb-4 pt-4 ${
                    !!index ? "border-t border-neutral-100" : ""
                } ${
                    onInsightClick ? "cursor-pointer hover:bg-neutral-50" : ""
                }`}
            >
                <div className="flex flex-row text-left">
                    {insight?.type && (
                        <Icon
                            className="w-[24px] mr-1 text-orange-600"
                            icon={
                                ActivityIcon[
                                    insight?.type as keyof typeof ActivityIcon
                                ]
                            }
                        />
                    )}{" "}
                    <span className="truncate text-base font-semibold flex-grow">
                        {insight?.title}
                    </span>
                    <span>
                        <DotsMenu
                            options={DotMenuOptions}
                            handleClickMenu={(k: any) => {
                                handleClickDotsMenu(k, insight);
                            }}
                        />
                    </span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="MarkerIcon"
                    />

                    <span className="truncate text-xs">
                        {insight?.location}
                    </span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="TripIcon"
                    />

                    <span className="truncate text-xs">{insight?.trip}</span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="CalendarMonthIcon"
                    />
                    <span className="truncate text-xs">{insight?.date}</span>
                </div>
            </div>
        );
    };

    return (
        <ListWithPagination
            items={insights}
            renderItem={renderInsight}
            handlePaginatorChange={handlePaginatorChange}
            itemKey={(insight: any) => `insight_${insight.id}`}
        />
    );
};

export default InsightsResponsiveList;
