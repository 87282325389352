import React, { useEffect, useState } from "react";
import server from "../../api/server";
import useGoogleMaps from "../../hooks/usePlacesService";
import AutocompleteInput from "../Autocomplete/AutocompleteInput";
import CitiesAutocomplete from "../CitiesSearchAutocomplete/CitiesAutocomplete";
import ResultsList from "./ResultsList";

const RestaurantsAutocomplete = React.forwardRef((props: any, ref: any) => {
    const [city, setCity] = useState<any>(props?.city);
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<Array<any> | null>(null);
    const mapService = useGoogleMaps();
    const {
        label,
        id,
        name,
        type,
        placeholder,
        defaultValue,
        error,
        onChange,
        onTextChange,
        handleSelect,
        onChangeCity,
        ...others
    } = props;

    useEffect(() => {
        if (defaultValue && onChange) onChange(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        if (searchText) handleOnSearch(searchText, city);
    }, [searchText, city?.description]);

    const handleOnSearch = async (text: string, city: any | null) => {
        try {
            setSearchResults([]);
            setLoading(true);
            const textToSearch = `${text} ${city?.description || ""}`;
            const promises = [
                getServerRestaurants(textToSearch),
                mapService.getPlacePredictions(textToSearch, {
                    types: ["restaurant", "night_club", "bar", "cafe"],
                }),
            ];
            const responses = await Promise.all(promises);
            let newResults: Array<any> = [];
            if (responses && Array.isArray(responses)) {
                newResults = [...responses[0], ...responses[1]];
            }
            setSearchResults(newResults);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getServerRestaurants = async (text: any) => {
        const response = await server.getRestaurants(text);
        return response.data || [];
    };

    const handleSelectR = async (selectedOption: any) => {
        let place = selectedOption;
        if (selectedOption.place_id) {
            place = await mapService.getPlaceDetails(selectedOption.place_id);
        }
        if (onChange) onChange(place);
        if (handleSelect) handleSelect(place);
    };

    const handleChangeCity = (city: any) => {
        setCity(city);
        if (onChangeCity) onChangeCity(city);
    };

    return (
        <>
            <AutocompleteInput
                onSearch={(text: string) => {
                    setSearchText(text);
                }}
                onChange={handleSelectR}
                changeTextOnUpdate={true}
                label={label}
                ref={ref}
                type={type}
                name={name}
                id={id}
                defaultValue={defaultValue?.name || ""}
                placeholder={placeholder}
                error={error}
                searchResults={searchResults}
                searchResultLabel="name"
                searchResultSubheader="formatted_address"
                onTextChange={onTextChange}
                loading={loading}
                showOptions={false}
                {...others}
            />
            <div className="flex w-full gap-2 mb-4 mt-2">
                <div className="whitespace-nowrap text-gray-400 text-sm">
                    You are searching in
                </div>{" "}
                <CitiesAutocomplete
                    onChange={handleChangeCity}
                    defaultValue={city?.description}
                />
            </div>
            <ResultsList
                searchResults={searchResults}
                searchResultLabel="description"
                searchResultSubheader="formatted_address"
                onChange={handleSelectR}
            />
        </>
    );
});
export default RestaurantsAutocomplete;
