import React from "react";
import fileImage from "../../assets/images/rafiki.png";
import { VALID_IMAGES_FORMATS } from "../../constants";
import useDropImageFileInput from "../../hooks/useDropImageFileInput";
import InputErrorLabel from "../InputErrorLabel";

const DropImageFileInput = React.forwardRef(
    (
        { onChange, preview, imgClass, imgComponentClass, iconClass, className, ...others }: any,
        ref: any
    ) => {
        const {
            error,
            errorMessage,
            changeFile,
            dragOverlay,
            handleDrag,
            handleDragIn,
            handleDrop,
            handleDragOut,
        } = useDropImageFileInput(onChange);

        return (
            <label
                htmlFor="img-file-upload"
                className={`${
                    dragOverlay ? "bg-gray-300" : ""
                } rounded-xl cursor-pointer`}
                onDragEnter={handleDragIn}
                onDragLeave={handleDragOut}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >
                {preview ? (
                    <div className={imgClass}>
                        <img
                            className={imgComponentClass}
                            src={preview}
                            width="100%"
                            alt=""
                        />
                    </div>
                ) : (
                    <>
                        <div
                            className={`flex flex-wrap items-center min-h-full justify-center p-12 text-sm border-orange-600 rounded-xl border border-dashed ${className}`}
                        >
                            <div className="mr-6">
                                <img
                                    className={`${iconClass}`}
                                    src={fileImage}
                                    alt=""
                                />
                            </div>
                            <div className="flex flex-col text-left text-gray-600">
                                <span className="font-semibold pb-4">
                                    {" "}
                                    <span className="text-orange-600">
                                        Browse
                                    </span>{" "}
                                    or drop your image here.
                                </span>
                                <span>Accepted format: .png, .jpg</span>
                                <span>Maximum size: 10MB</span>
                            </div>
                        </div>
                        <InputErrorLabel className="mb-8" error={error && errorMessage} />
                    </>
                )}
                <input
                    onChange={changeFile}
                    ref={ref}
                    id="img-file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only hidden"
                    accept={VALID_IMAGES_FORMATS.join(", ")}
                    {...others}
                />
            </label>
        );
    }
);
export default DropImageFileInput;
