import Icon from "../../../../assets/svg/Icon";
import DotsMenu from "../../../../components/DotsMenu";
import List from "../../../../components/List";

const AdminPlansList = (props: any) => {
    const { plans, handleClickDotsMenu, menuOptions } = props;

    const renderElement = ({ item: element, index }: any) => {
        return (
            <div
                key={element.id}
                className={`lg:mx-14 mb-4 pt-4 ${
                    !!index ? "border-t border-neutral-100" : ""
                }`}
            >
                <div className="flex flex-row text-left">
                    <span className="truncate text-base font-semibold flex-grow">
                        {element?.name}
                    </span>
                    {handleClickDotsMenu && (
                        <div className="text-left">
                            <DotsMenu
                                options={menuOptions}
                                handleClickMenu={(k: any) => {
                                    handleClickDotsMenu(k, element);
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="ParticipantsIcon"
                    />

                    <span className="truncate text-xs">{element?.role}</span>
                </div>
            </div>
        );
    };

    return (
        <>
            {plans?.map((item: any, index: number) =>
                renderElement({ item, index })
            )}
        </>
    );
};

export default AdminPlansList;
