import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../../../app/store";
import serverApi from "../../../../api/server";
import { handleError } from "../../../authentication/userSlice";
import { IPaginatedData, IPaginatedDataInitialState } from "../../../../types/dataTypes";

export interface MessagesState {
    isLoading: boolean;
    messages: IPaginatedData;
    error: string;
}

const initialState: MessagesState = {
    isLoading: false,
    messages: IPaginatedDataInitialState,
    error: "",
};

export const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        isLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setMessages: (state, action: PayloadAction<{ messages: any }>) => {
            state.messages = action.payload.messages;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(sendMessageToAllTripUsers.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(sendMessageToAllTripUsers.fulfilled, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(sendMessageToAllTripUsers.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message || "";
        });
    },
});

export const { isLoading, setMessages, setError } = messagesSlice.actions;
export const selectMessages = (state: RootState) => state.messages.messages;

export const getTripMessages =
    (
        tripId: number,
        search: string = "",
        take: number,
        skip: number
    ): AppThunk =>
        async (dispatch, getState) => {
            try {

                dispatch(
                    setMessages({
                        messages: { ...IPaginatedDataInitialState, isLoading: true },
                    })
                );
                const response = await serverApi.getTripMessages(tripId, search, take, skip);
                dispatch(
                    setMessages({
                        messages: {
                            ...response.data,
                            isLoading: false,
                        },
                    })
                );


                dispatch(isLoading(false));
            } catch (error) {
                dispatch(isLoading(false));
                dispatch(handleError(error));
            }
        };

export const sendMessageToAllTripUsers = createAsyncThunk(
    "messages/sendMessageToAllTripUsers",
    async ({ tripId, subject, body }: { tripId: number; subject: string; body: string }, { rejectWithValue }) => {
        try {
            const response = await serverApi.sendMessageToAllTripUsers(tripId, { subject, body });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);





export default messagesSlice.reducer;
