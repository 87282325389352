import Icon from "../../../../assets/svg/Icon";
import DotsMenu from "../../../../components/DotsMenu";
import Paginator from "../../../../components/Paginator";
import Table from "../../../../components/Table";
import { ActivityIcon, PAGE_ROWS } from "../../../../constants";

const InsightsTable = (props: any) => {
    const {
        insights,
        DotMenuOptions,
        handleClickDotsMenu,
        handlePaginatorChange,
        onInsightClick,
    } = props;
    const tableHeaders = ["Element", "Date", "Location", "Trip", ""];
    return (
        <>
            <Table headers={tableHeaders} isLoading={insights.isLoading}>
                {insights?.data?.map((insight: any) => (
                    <tr
                        key={insight?.id}
                        className={`${
                            onInsightClick
                                ? "cursor-pointer hover:bg-neutral-50"
                                : ""
                        }`}
                        onClick={() =>
                            onInsightClick && onInsightClick(insight)
                        }
                    >
                        <td className="text-left">
                            <div className="flex flex-row">
                                <div>
                                    {insight?.type && (
                                        <Icon
                                            className="w-[24px] mr-4 text-orange-600"
                                            icon={
                                                ActivityIcon[
                                                    insight?.type as keyof typeof ActivityIcon
                                                ]
                                            }
                                        />
                                    )}
                                </div>
                                <div className="max-w-[250px] truncate">
                                    {insight?.title}
                                </div>
                            </div>
                        </td>
                        <td className="text-left">{insight?.date}</td>
                        <td className="text-left max-w-[250px] truncate">
                            {insight?.location}
                        </td>
                        <td className="text-left max-w-[250px] truncate">
                            {insight?.trip}
                        </td>
                        <td className="text-left">
                            <DotsMenu
                                options={DotMenuOptions}
                                handleClickMenu={(k: any) => {
                                    handleClickDotsMenu(k, insight);
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </Table>
            {insights?.count > PAGE_ROWS && (
                <div className=" self-center lg:self-end text-xs z-20 mt-4">
                    <Paginator
                        count={insights?.count}
                        take={PAGE_ROWS}
                        onChange={handlePaginatorChange}
                        totalPages={insights?.totalPages}
                        currentPage={insights?.currentPage}
                    />
                </div>
            )}
        </>
    );
};

export default InsightsTable;
