import { mdiHelpCircleOutline } from "@mdi/js";
import Icon from "../assets/svg/Icon";

interface SupportIconI {
    showIcon: boolean;
    handleOpenModal: () => void;
}

export const SupportIcon = ({ showIcon, handleOpenModal }: SupportIconI) => {
    if (!showIcon) return null;
    return (
        <div
            onClick={handleOpenModal}
            className="flex max-w-s flex-row text-white text-sm font-semibold py-1 px-2 rounded-full bg-cyan-600 cursor-pointer"
        >
            <div className="items-center mr-1">
                <Icon
                    materialIcon={mdiHelpCircleOutline}
                    className={`w-5 h-5 text-neutral-200`}
                />
            </div>
            <div>Help</div>
        </div>
    );
};
