import { FC } from "react";
import Icon from "../../../../../../../../assets/svg/Icon";

export interface IInsightItem {
    id: number;
    [key: string]: any;
}

export interface CommonProps {
    activeItem: IInsightItem | null;
    handleEditItem: (item: IInsightItem) => void;
    handleShowDetails: (item: IInsightItem) => void;
    titleKey?: string;
    subtitleKey?: string;
    getItemTitle?: (item: IInsightItem) => string;
    icon?: string;
}

export interface InsightItemProps extends CommonProps {
    item: IInsightItem;
    index: number;
}

export interface ListInsightItemsProps extends CommonProps {
    list: IInsightItem[];
}
export interface ItemDetailsViewProps  {
    item: IInsightItem;
}

export type ItemDetailsViewType = FC<ItemDetailsViewProps>;
export type ListInsightItemsType = FC<ListInsightItemsProps>;

const InsightItem = ({
    item,
    index,
    activeItem,
    handleEditItem,
    icon,
    titleKey,
    subtitleKey,
    getItemTitle,
    handleShowDetails,
}: InsightItemProps) => {
    const isActiveClass =
        activeItem?.id === item.id ? "border-primary_orange-500" : "";
    return (
        <div
            className={`flex flex-col gap-2 border p-4 sm:w-[200px] w-[97%] rounded-lg cursor-pointer ${isActiveClass}`}
            key={index}
            onClick={() => handleShowDetails(item)}
        >
            <div className="flex items-center gap-2">
                {icon && (
                    <div className="w-[16px]">
                        <Icon icon={icon} className="w-[16px] mr" />
                    </div>
                )}
                <div className="text-sm text-neutral-900 font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis flex-grow">
                    {getItemTitle
                        ? getItemTitle(item)
                        : titleKey && item[titleKey]}
                </div>

                <div>
                    <Icon
                        icon="PencilIconOutline"
                        className="w-[24px] cursor-pointer text-orange-600"
                        onClick={(ev) => {
                            ev.stopPropagation();
                            ev.preventDefault();
                            handleEditItem(item);
                        }}
                    />
                </div>
            </div>
            <div className="text-sm text-neutral-500 whitespace-nowrap overflow-hidden overflow-ellipsis">
                {subtitleKey && item[subtitleKey]}
            </div>
        </div>
    );
};

export const ListInsightItems = ({ list, ...rest }: ListInsightItemsProps) => {
    if (!list?.length) return null;

    return (
        <div className="flex items-end gap-4 flex-wrap mostly-customized-scrollbar overflow-y-auto max-h-[460px] my-6">
            {list.map((item, index) => (
                <InsightItem
                    key={`list_insight_item_${item.id}`}
                    item={item}
                    index={index}
                    {...rest}
                />
            ))}
        </div>
    );
};
