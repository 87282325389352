import { useMemo } from "react";
import { useAppSelector } from "../../../../../../app/hooks";
import { selectCurrentBreakpoint } from "../../../../../layout/layoutSlice";

interface IStep {
    isLast?: boolean;
    current?: boolean;
    finished?: boolean;
    label: string;
    index: number;
    isDesktop: boolean;
    hideLines?: boolean;
    onClick?: () => void;
    total: number;
}

interface IStepper {
    steps: Array<any>;
    current: string | null;
    hideLines?: boolean | undefined;
}
function Step({ isLast, current, finished, label, index, isDesktop, hideLines, onClick, total }: IStep) {
    const lineClass = useMemo(() => {
        return isLast ? "" : `lg:w-1 box-content transition-colors relative lg:pb-4 h-1 lg:h-5 ${
            hideLines ?
            "bg-l-2 bg-solid bg-transparent" :
                current
                    ? "bg-l-2 bg-solid bg-pink-600"
                    : finished
                    ? "bg-l-2 bg-solid bg-[#E77EAF]"
                    : "bg-l-2 bg-solid bg-gray-400"
        }  `;
    }, [current, finished, isLast]);

    const labelClass = useMemo(() => {
        return `w-full ml-4 text-left text-base transition-colors ${
            !isLast ? "border-b border-solid border-gray-300 pb-4" : ""
        } ${
            current
                ? "font-semibold text-pink-600"
                : finished
                ? "text-[#E77EAF] font-medium"
                : "text-gray-500 font-medium"
        }`;
    }, [current, finished, isLast]);

    const circleClass = useMemo(() => {
        return `${
            current
                ? "bg-pink-600 font-semibold"
                : finished
                ? "bg-[#E77EAF] font-medium"
                : "bg-gray-400 font-medium"
        } transition-colors rounded-full w-7 h-7 text-white text-xs flex items-center justify-center `;
    }, [current, finished]);
    //absolute -left-[15px]
    return (
        <div className={`flex flex-row items-start ${onClick ? "cursor-pointer" : ""}`} onClick={onClick && onClick}>
            <div className="flex flex-row lg:flex-col items-center justify-center">
                <div className={circleClass}>{index}</div>
                <div style={{ width: !isLast && !isDesktop ? `calc(${Math.floor(100/total)}vw - 28px)` : "" }} className={lineClass} />
            </div>
            {isDesktop && <div className={labelClass}>{label}</div>}
        </div>
    );
}
export default function Stepper({ steps, current, hideLines }: IStepper) {
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    const currentStep = steps?.findIndex((step) => step.id === current);
    return (
        <div className="flex flex-row lg:block justify-between">
            {steps &&
                steps.map((step: any, index) => {
                    const finished = currentStep > index;
                    const isCurrent = currentStep === index;
                    const isLast = index === steps.length - 1;
                    return (
                        <Step
                            key={step?.id}
                            finished={finished}
                            label={step.label}
                            current={isCurrent}
                            isLast={isLast}
                            index={index + 1}
                            isDesktop={isDesktop}
                            hideLines={hideLines}
                            onClick={step.onClick}
                            total={steps.length}
                        />
                    );
                })}
        </div>
    );
}
