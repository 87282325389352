import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PAGE_ROWS } from "../../../constants";
import { TripsDotMenuKeys } from "../../../constants/admin";
import TripFormComponent from "../../trips/TripForm/TripFormComponent";
import {
    deleteTrip,
    getAdminTrips,
    selectAdminTrips,
    updateTrip,
} from "../adminSlice";
import { parseMomentUtc } from "../../../helpers";
import { TripsTable } from "./components/TripsTable";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import TripUpdateDatesProvider from "../../trips/TripForm/TripUpdateDatesContext";

export default function AllIncomingTrips(props: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [newPage, setNewPage] = useState<any>({});
    const trips = useAppSelector(selectAdminTrips);
    const [selectedTrip, setSelectedTrip] = useState<any>(null);
    const [tripToDelete, setTripToDelete] = useState<any>(null);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip]);

    const getData = () => {
        dispatch(
            getAdminTrips(
                "",
                moment().startOf("day").toDate(),
                parseMomentUtc(moment().add(12, "months"))
                    .endOf("day")
                    .toDate(),
                PAGE_ROWS,
                newPage?.skip
            )
        );
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };
    const handleClickDotsMenu = (key: string, trip: any) => {
        switch (key) {
            case TripsDotMenuKeys.edit:
                setSelectedTrip(trip);
                break;
            case TripsDotMenuKeys.view:
                navigate(`/trips/${trip?.id}`);
                break;
            case TripsDotMenuKeys.delete:
                setTripToDelete(trip);
                break;
        }
    };

    const handleSubmitTrip = async (data: any) => {
        try {
            await dispatch(updateTrip({ ...data, id: selectedTrip.id }));
            setSelectedTrip(null);
        } catch (error) {}
    };

    const handleDeleteTrip = async () => {
        try {
            await dispatch(deleteTrip(tripToDelete?.id));
            setTripToDelete(null);
        } catch (error) {}
    };

    if (!!selectedTrip) {
        return (
            <TripUpdateDatesProvider>
                <TripFormComponent
                    trip={selectedTrip}
                    onSubmit={handleSubmitTrip}
                    onCancel={() => setSelectedTrip(null)}
                />
            </TripUpdateDatesProvider>
        );
    }

    return (
        <>
            <ModalConfirmation
                open={!!tripToDelete}
                title="Are you sure?"
                description={`You are about to remove the trip ${tripToDelete?.title}`}
                handleCancel={() => {
                    setTripToDelete(null);
                }}
                handleSubmit={handleDeleteTrip}
            />
            <div className="text-neutral-900 text-left font-semibold mb-6">
                Incoming trips List
            </div>
            <TripsTable
                trips={trips}
                handleClickDotsMenu={handleClickDotsMenu}
                handlePaginatorChange={handlePaginatorChange}
            />
            {!trips?.isLoading && !trips?.data.length && (
                <div className="text-neutral-400 text-sm py-6">
                    There are no incoming trips to show.
                </div>
            )}
        </>
    );
}
