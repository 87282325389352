import { useEffect, useMemo } from "react";
import {
    SUPPORT_MODULE_SECTIONS_OPTIONS,
    SupportPlatformOptions,
} from "../../../../constants";
import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentBreakpoint } from "../../../layout/layoutSlice";
import InputSearch from "../../../../components/InputSearch";
import SelectInput from "../../../../components/InputSelect/SelectInput";
import AllSupportModulesTable from "./AllSupportModulesTable";
import AllSupportModulesList from "./AllSupportModulesList";
import { useTableAdminContext } from "../../../../context/TableAdmin";
import { normalizeSupportModules } from "../../../../helpers";

const SupportSectionOptions = [
    { value: "ALL", label: "All" },
    ...SUPPORT_MODULE_SECTIONS_OPTIONS,
];

const SupportModulesList = () => {
    const {
        fetchedData: supportModules,
        setEditModalOpen,
        setCurrentElement,
        setCustomQueryParams,
        loading,
        handleClickDotsMenu,
        resetPage,
        handlePaginatorChange,
        handleSearchChange,
        resetState,
    } = useTableAdminContext();
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);

    useEffect(() => {
        resetPage();
        setCustomQueryParams({});

        return () => {
            resetState();
        };
    }, []);

    const onDotsMenuClick = (action: string, element: any) => {
        switch (action) {
            case "edit":
                setCurrentElement(element);
                setEditModalOpen(true);
                break;
            case "delete":
                handleClickDotsMenu(action, element);
                break;
        }
    };

    const handleSelectFilterChange = (
        key: "section" | "platform",
        value: string
    ) => {
        const formattedValue = value === "ALL" ? "" : value;
        resetPage();
        setCustomQueryParams((prev) => ({ ...prev, [key]: formattedValue }));
    };

    const supportVideosNormalized = useMemo(() => {
        return {
            ...supportModules,
            data: (supportModules?.data || [])?.map((sup: any) => {
                return { ...sup, ...normalizeSupportModules(sup) };
            }),
        };
    }, [supportModules?.data]);

    const AllSupportModules = isDesktop
        ? AllSupportModulesTable
        : AllSupportModulesList;

    return (
        <>
            <div className="text-neutral-900 text-left font-semibold mb-6">
                Videos
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-12 items-end gap-x-6 lg:gap-x-10">
                <div className="col-span-1 lg:col-span-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>
                <div className="col-span-1 lg:col-span-3">
                    <SelectInput
                        onChange={(value: string) =>
                            handleSelectFilterChange("section", value)
                        }
                        label="Section"
                        options={SupportSectionOptions}
                        defaultValue={SupportSectionOptions[0].value}
                    />
                </div>
                <div className="col-span-1 lg:col-span-3">
                    <SelectInput
                        onChange={(value: string) =>
                            handleSelectFilterChange("platform", value)
                        }
                        label="Platform"
                        options={SupportPlatformOptions}
                        defaultValue={SupportPlatformOptions[0].value}
                    />
                </div>
            </div>
            <AllSupportModules
                loading={loading}
                supportModules={supportVideosNormalized}
                handleClickDotsMenu={onDotsMenuClick}
                handlePaginatorChange={handlePaginatorChange}
            />
            {!loading && !supportModules?.data?.length && (
                <div className="text-neutral-400 text-sm py-6">
                    There are no videos to show.
                </div>
            )}
        </>
    );
};

export default SupportModulesList;
