import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import {
    activityFormCurrentStep,
    selectActivityForm,
    selectedTrip,
    submitActivityFormStep,
} from "../../../../../tripSlice";

import { ActivityType, ButtonSize, PAGE_SECTIONS } from "../../../../../../../constants";
import { setCurrentSection } from "../../../../../../layout/layoutSlice";
import Modal, { Modal_Size } from "../../../../../../../components/Modal";
import ButtonSquared from "../../../../../../../components/ButtonSquare";
import RestaurantsAutocompleteWithValid from "../../../../../../../components/RestaurantsAutocomplete";
import {
    selectedRestaurant,
    setSelectedRestaurant,
} from "../../../../../../restaurants/restaurantSlice";
import RestaurantForm from "../../../../../../restaurants/RestaurantForm";
import SelectFromLibraryModal from "../../../Components/SelectFromLibrary";
import { useConstantsContext } from "../../../../../../../context/Constants";

const schema = yup.object({
    restaurant: yup
        .object({
            googlePlaceId: yup.string().nullable(),
            streetNumber: yup.string().nullable(),
            streetAddress: yup.string().nullable(),
            zipCode: yup.string().nullable(),
            city: yup.string().nullable(),
            state: yup.string().nullable(),
            website: yup.string().nullable(),
            phone: yup.string().nullable(),
            country: yup.string().required("This field is required"),
            fullAddress: yup.string().required("This field is required"),
            lat: yup.number().required("This field is required"),
            lng: yup.number().required("This field is required"),
        })
        .required("The full restaurant address is required"),
});

const RestaurantSelectionForm = React.forwardRef((props: any, ref: any) => {
    const activityForm = useAppSelector(selectActivityForm);
    const [searchValue, setSearchValue] = useState<any>("");
    const [city] = useState<any>(activityForm?.city);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [selectFromLibraryModal, setSelectFromLibraryModal] = useState(false);
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    let { activityType } = useParams();
    const { constants } = useConstantsContext();
    const { isLibraryElement } = props;
    const currentStep = useAppSelector(activityFormCurrentStep);
    const currentRestaurant = useAppSelector(selectedRestaurant);

    const { formState, handleSubmit, control, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    const { isValid } = formState;

    useEffect(() => {
        setValue("restaurant", currentRestaurant);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRestaurant?.id, currentRestaurant?.name]);

    useEffect(() => {
        dispatch(
            setSelectedRestaurant({
                selectedRestaurant: activityForm?.restaurant || null,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityForm?.restaurant]);

    const handleSubmitForm = (data: any) => {
        saveData(data?.restaurant, activityType);
    };

    const saveData = (restaurant: any, actType: string | undefined) => {
        const activity: any = {
            type: actType,
            restaurant: restaurant,
            city: city,
        };
        dispatch(
            submitActivityFormStep(
                activity,
                activityType || ActivityType.RESTAURANT,
                currentStep || ""
            )
        );
    };

    const handleSelect = (restaurant: any) => {
        if (restaurant) saveData(restaurant, activityType);
    };

    const onSelectFromLibrary = (element: any) => {
        setSelectedRestaurant({
            selectedRestaurant: element?.restaurant || null,
        });
        dispatch(
            submitActivityFormStep(
                element,
                ActivityType.RESTAURANT,
                currentStep || ""
            )
        );
        setSelectFromLibraryModal(false);
    };

    const handleOpenSelectFromLibraryModal = () => {
        dispatch(setSelectedRestaurant({ selectedRestaurant: null }));
        setSelectFromLibraryModal(true);
    };

    const handleRestaurantModalToggle = (open: boolean) => {
        setAddModalOpen(open);
    };

    const handleOnchangeSearch = (val: any) => {
        setSearchValue(val);
    };

    const handleRestaurantModal = () => {
        handleRestaurantModalToggle(true);
    };

    return (
        <div className="lg:min-h-full pb-16" ref={ref}>
            <Modal
                open={addModalOpen}
                size={Modal_Size.lg}
                onClose={() => {
                    handleRestaurantModalToggle(false);
                }}
            >
                <RestaurantForm
                    name={searchValue}
                    onCancel={() => {
                        handleRestaurantModalToggle(false);
                    }}
                    onChange={(newRestaurant: any) => {
                        handleRestaurantModalToggle(false);
                        handleSelect(newRestaurant);
                    }}
                />
            </Modal>
            <SelectFromLibraryModal
                isOpen={selectFromLibraryModal}
                onClose={() => setSelectFromLibraryModal(false)}
                activityType={ActivityType.RESTAURANT}
                onSelectFromLibrary={onSelectFromLibrary}
            />
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
                className="text-left lg:px-6 lg:min-h-full"
            >
                <div className="text-lg font-semibold mb-6">
                    Select the restaurant where you would like to go
                </div>

                <div className="">
                    <RestaurantsAutocompleteWithValid
                        control={control}
                        name="restaurant"
                        className={`py-2 pl-12 !border-[.5px] rounded-[40px] border-[#E5E5E5] block w-full text-base font-normal bg-[left_12px_top_12px] !bg-[url('./assets/svg/MagnifyIcon.svg')] bg-no-repeat`}
                        handleSelect={handleSelect}
                        onTextChange={handleOnchangeSearch}
                        defaultValue={
                            activityForm?.restaurant || currentRestaurant
                        }
                        placeholder="Search"
                        city={city}
                        onChangeCity={handleRestaurantModal}
                    />
                </div>

                {searchValue && (
                    <div className="ml-4 mt-4 flex text-sm">
                        Couldn't find the Restaurant? You can{" "}
                        <span
                            onClick={() => {
                                dispatch(
                                    setSelectedRestaurant({
                                        selectedRestaurant: null,
                                    })
                                );
                                handleRestaurantModalToggle(true);
                            }}
                            className="mx-2 cursor-pointer font-semibold text-orange-600"
                        >
                            add
                        </span>
                        one
                    </div>
                )}
                {constants?.featureFlags?.LIBRARY && !isLibraryElement && !searchValue && !!trip?.organization?.id && (
                    <div className="mt-8">
                        <div className="text-lg font-semibold mb-6">
                            Or choose from library
                        </div>
                        <ButtonSquared
                            outlined
                            label="Select element from library"
                            onClick={handleOpenSelectFromLibraryModal}
                            size={ButtonSize.FIT}
                            className="py-2 px-7"
                        />
                    </div>
                )}
                <div className="flex gap-3 justify-end px-4 py-3 text-right mt-12 lg:absolute right-4 bottom-4">
                    <ButtonSquared
                        onClick={props.navigateBack}
                        outlined
                        type="button"
                        label="Cancel"
                    />
                    <ButtonSquared
                        type="submit"
                        label="Next"
                        disabled={!isValid}
                    />
                </div>
            </form>
        </div>
    );
});

export default RestaurantSelectionForm;
