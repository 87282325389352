/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Icon from "../../assets/svg/Icon";

export const Modal_Size = {
    xs: "w-[400px]",
    small: "md:w-[538px] lg:w-[538px]",
    medium: "md:w-[700px] lg:w-[700px]",
    md: "md:w-[700px] lg:w-[700px]",
    lg: "w-full md:w-[800px] mx-2",
    xl: "w-full md:w-[1000px] mx-2",
    full: "w-full",
};

export interface ModalProps {
    children: any;
    open?: boolean;
    onClose?: any;
    size?: string;
    translucid?: boolean;
    className?: string;
    bodyClass?: string;
    hideCloseButton?: boolean;
    closeOnBackClick?: boolean;
}

export default function Modal(props: ModalProps) {
    const {
        children,
        open,
        onClose,
        size,
        translucid,
        className,
        hideCloseButton,
        closeOnBackClick,
        bodyClass,
    } = props;
    const handleClose = () => {
        onClose && onClose();
    };
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-[100] inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={closeOnBackClick ? handleClose : () => {}}
            >
                <div className="flex items-center lg:items-end justify-center min-h-screen text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className={`${
                                size ? size : Modal_Size.small
                            } inline-block align-bottom ${
                                translucid
                                    ? ""
                                    : "bg-white rounded-lg shadow-xl sm:my-8 p-4"
                            } text-left transform transition-all sm:align-middle ${className}`}
                        >
                            <div className="w-full">
                                <div
                                    className={`${
                                        hideCloseButton ? "hidden" : ""
                                    } w-full flex justify-end`}
                                >
                                    <Icon
                                        icon="CloseIcon"
                                        width={20}
                                        className="cursor-pointer"
                                        onClick={handleClose}
                                    />
                                </div>
                            </div>
                            <div className={`w-full ${bodyClass}`}>
                                {children}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
