import React from 'react';

const InputSwitch = React.forwardRef((props: any, ref: any) => {
	const { id, name, defaultChecked, onClick, disabled, value = 'true' } = props;
	return (
		<div>
			<label className='flex items-center cursor-pointer relative'>
				<input
                    ref={ref}
					id={id}
					name= {name}
					defaultChecked={defaultChecked}
					onClick={onClick}
					value={value}
					type='checkbox'
					disabled={disabled}
					className='sr-only'
				/>
				<div className='toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full'></div>
			</label>
		</div>
	);
});
export default InputSwitch;
