import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import Modal from "../../../../components/Modal";
import ButtonSquared from "../../../../components/ButtonSquare";
import { ActivityType, ButtonSize, PAGE_ROWS, TripFilters } from "../../../../constants";
import moment from "moment";
import { useAppSelector } from "../../../../app/hooks";
import { getTripsByOrganization, selectActivityElementToAddToTrip, selectedOrganization, setLibraryElementToAddToTrip } from "../../organizationSlice";
import { useDispatch } from "react-redux";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addActivityToTrip } from "../../../trips/tripSlice";
import { useNavigate, useParams } from "react-router";
import LibraryTripListEmptyView from "./LibraryTripListEmptyView";
import LibraryElementDatesForm from "./LibraryElementDatesForm";
import TripCard from "./LibraryTripCard";
import { parseLibraryElementToActivity } from "../library.util";

const schema = yup.object({
    startDate: yup.date().required("This field is required"),
    endDate: yup.date().required("This field is required"),
    startHour: yup
        .mixed()
        .test(`test-hour-format`, "Invalid date format", function (value) {
            return !!moment(value).isValid();
        })
        .required("This field is required"),
    endHour: yup
        .mixed()
        .test(`test-hour-format`, "Invalid date format", function (value) {
            return !!moment(value).isValid();
        })
        .required("This field is required"),
    isVisit: yup.string().when("isHotel", {
        is: true,
        then: yup.string().required("This field is required"),
        otherwise: yup.string(),
    }),
    isHotel: yup.boolean(),
});

const AddLibraryElementToTrip = ({
    open,
    setOpen,
    activityElement,
    activityElementToAddToNewTrip,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    activityElement: any;
    activityElementToAddToNewTrip?: any;
}) => {
    const organization = useAppSelector(selectedOrganization);
    const [selectedTrip, setSelectedTrip] = useState<any>(null);
    const [trips, setTrips] = useState<any>([]);
    const [newPage, setNewPage] = useState<any>({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activityType } = useParams();

    const { formState, handleSubmit, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    const { isValid, errors } = formState;
    const startDate = watch("startDate");
    const isVisit = watch("isVisit");
    const isHotel = activityType === ActivityType.HOTEL;

    useEffect(() => {
        if (organization?.id) {
            fetchTrips();
        }
    }, [organization?.id]);

    useEffect(() => {
        return () => {
            setTimeout(() => {
                setSelectedTrip(null);
                reset();
            }, 200);
        }
    }, [open]);

    useEffect(() => {
        if (selectedTrip) {
            const initialStartDate = moment.utc(selectedTrip?.startDate).format("YYYY-MM-DD");
            const initialEndDate = moment
                .utc(initialStartDate)
                .add(1, "day")
                .format("YYYY-MM-DD");
            reset({
                startDate: initialStartDate,
                endDate: initialEndDate,
                startHour: moment("09:00", "HH:mm"),
                endHour: moment("10:00", "HH:mm"),
                isHotel: activityType === ActivityType.HOTEL,
            });
        }
    }, [selectedTrip]);

    const fetchTrips = async () => {
        const filters = {
            search: "",
            status: TripFilters.ACTIVE,
            take: newPage?.take || PAGE_ROWS,
            skip: newPage?.skip || 0,
            filterUserTrips: true,
        };
        const fetchedTrips = await dispatch(
            getTripsByOrganization(organization.id, filters)
        );
        setTrips(fetchedTrips);
    };

    const handleAddToNewTrip = () => {
        setOpen(false);
        const libraryElementToAddToTrip = parseLibraryElementToActivity(activityElementToAddToNewTrip);
        dispatch(
            setLibraryElementToAddToTrip({ libraryElementToAddToTrip })
        )
        navigate("/trips/new?fromLibrary=true&oId=" + organization.id);
    };

    const handleAddToItinerary = async (data: any) => {
        setOpen(false);
        const isVisit = data?.isVisit === "true";
        const endDate = isHotel && !isVisit ? data.endDate : data.startDate;
        const endHour = isVisit ? data.startHour : data.endHour;
        if (activityElement?.type === ActivityType.HOTEL) {
            activityElement.title = `${
                !isVisit ? "Hotel Stay in" : "Site Inspection at"
            } ${activityElement.title || ""}`;
        }
        const activity = {
            ...activityElement,
            startDate: new Date(
                moment.utc(data.startDate).format("YYYY-MM-DD")
            ),
            endDate: new Date(moment.utc(endDate).format("YYYY-MM-DD")),
            startHour: moment(data.startHour).format("HH:mm"),
            endHour: moment(endHour).format("HH:mm"),
        };
        const tripId = selectedTrip?.id;
        await dispatch(addActivityToTrip(Number(tripId), { activity }));
        return navigate(
            `/trips/${tripId}/itinerary?date=${moment
                .utc(activity.startDate)
                .format("YYYY-MM-DD")}`
        );
    };

    const tripsNotEmpy = trips && trips.data && trips.data.length > 0;

    const renderTrips = () =>
        tripsNotEmpy ? (
            <>
                <div className="text-base font-base text-neutral-400">
                    Select the trip you want to add it to
                </div>
                <div className="flex flex-col gap-3 mt-3 mb-6 max-h-[300px] overflow-auto pr-4 mostly-customized-scrollbar">
                    {trips &&
                        trips.data &&
                        trips.data.map((trip: any) => (
                            <TripCard
                                key={trip.id}
                                trip={trip}
                                isSelected={trip.id === selectedTrip?.id}
                                onClick={() => setSelectedTrip(trip)}
                            />
                        ))}
                </div>
                {selectedTrip && startDate && (
                    <LibraryElementDatesForm
                        control={control}
                        errors={errors}
                        isHotel={isHotel}
                        isVisit={isVisit}
                        selectedTrip={selectedTrip}
                    />
                )}
                <div className="flex justify-end">
                    {/* <ButtonSquared
                        outlined
                        label={"Add to a new trip"}
                        onClick={handleAddToNewTrip}
                        size={ButtonSize.NORMAL}
                    /> */}
                    <ButtonSquared
                        label={"Add to itinerary"}
                        onClick={handleSubmit(handleAddToItinerary)}
                        size={ButtonSize.NORMAL}
                        disabled={!isValid && !selectedTrip}
                    />
                </div>
            </>
        ) : (
            <LibraryTripListEmptyView onAdd={handleAddToNewTrip} />
        );

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div className="px-6">
                <div className="text-2xl font-semibold pb-4 ">Assign Trip</div>
                {renderTrips()}
            </div>
        </Modal>
    );
};

export default AddLibraryElementToTrip;