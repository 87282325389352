import Button from "../../../../components/ButtonSquare";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useTableAdminContext } from "../../../../context/TableAdmin";
import SelectInputWithValid from "../../../../components/InputSelect";
import InputWithValid from "../../../../components/Input";
import { SUPPORT_MODULE_SECTIONS_OPTIONS } from "../../../../constants";
import TextareaWithValid from "../../../../components/TextArea";
import InputRadioWithValid from "../../../../components/Radio";
import { SupportPlatform } from "../../../../constants";
import InputErrorLabel from "../../../../components/InputErrorLabel";

interface ModuleFormProps {
    onCancel?: any;
    name?: string;
}

const schema = yup.object({
    section: yup.string().required("This field is required"),
    title: yup.string().required("This field is required"),
    platform: yup.string().required("This field is required"),
    videoLink: yup.string().required("This field is required"),
    description: yup.string().nullable(),
});

const supportModuleSectionsOptions = SUPPORT_MODULE_SECTIONS_OPTIONS.sort((a, b) => a.label.localeCompare(b.label));

export default function CreateModuleForm(props: ModuleFormProps) {
    const { currentElement, handleAddElement, handleUpdateElement } =
        useTableAdminContext();
    const { onCancel } = props;
    const [isUpdate, setIsUpdate] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        control,
        setValue,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (currentElement) {
            setIsUpdate(true);
            reset({
                section: currentElement?.section,
                title: currentElement?.title,
                description: currentElement?.description,
                platform: currentElement?.platform,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentElement?.id]);

    const handleSubmitForm = async (data: any) => {

        if (currentElement?.id) {
            await handleUpdateElement(currentElement.id, data);
        } else {
            await handleAddElement(data);
        }
    };

    const handleRadioChange = (e: any) => {
        clearErrors("platform");
        setValue(e.target.name, e.target.value);
    }

    return (
        <div className="mx-6">
            <div className="flex flex-wrap">
                <div className="text-left font-semibold text-lg flex-grow">
                    {isUpdate ? "Edit video" : "Add video"}
                </div>
            </div>
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="flex flex-col">
                    <div className="mt-3 w-full lg:w-[400px]">
                        <InputWithValid
                            register={register}
                            label="Title"
                            type="text"
                            id="title"
                            name="title"
                            error={errors["title"]?.message}
                            defaultValue={currentElement?.title}
                        />
                    </div>

                    <div className="my-3 w-full lg:w-[400px]">
                        <SelectInputWithValid
                            control={control}
                            isMulti={false}
                            label="Section"
                            options={supportModuleSectionsOptions}
                            id="section"
                            name="section"
                            error={errors["section"]?.message}
                            defaultValue={currentElement?.section}
                        />
                    </div>
                    <div className="mt-3 w-full lg:w-[400px]">
                        <InputWithValid
                            register={register}
                            label="Link"
                            type="text"
                            id="videoLink"
                            name="videoLink"
                            error={errors["videoLink"]?.message}
                            defaultValue={currentElement?.videoLink}
                        />
                    </div>
                    <div className="mt-3 w-full lg:w-[400px]">
                        <div className="flex gap-3">
                            <InputRadioWithValid
                                control={control}
                                onChange={handleRadioChange}
                                value={SupportPlatform.MOBILE}
                                label="Mobile"
                                name="platform"
                                id="platformMobile"
                            />
                            <InputRadioWithValid
                                control={control}
                                value={SupportPlatform.WEB}
                                label="Web"
                                name="platform"
                                id="platformWeb"
                                onChange={handleRadioChange}
                            />
                        </div>
                        <InputErrorLabel
                            error={
                                errors["platform"] &&
                                errors["platform"]?.message
                            }
                        />
                    </div>
                    <div className="mt-3 w-full lg:w-[400px]">
                        <TextareaWithValid
                            register={register}
                            label="Description"
                            type="text"
                            id="description"
                            name="description"
                            error={errors["description"]?.message}
                            defaultValue={currentElement?.description}
                        />
                    </div>
                </div>
                <div className="my-6 items center justify-center flex gap-5">
                    <Button
                        outlined
                        type="button"
                        label="Cancel"
                        onClick={onCancel}
                    />
                    <Button type="submit" label="Save" />
                </div>
            </form>
        </div>
    );
}
