import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch } from "../../../../../../../../app/hooks";
import { PAGE_SECTIONS } from "../../../../../../../../constants";
import { addHotelDinningInsight, removeHotelDinningInsight } from "../../../../../../../hotelInsight/hotelInsightSlice";
import { useSelector } from "react-redux";
import { selectActivityInsight } from "../../../../../../tripSlice";
import { FormDinningItem } from "./FormDinningItem";
import { ListInsightItems } from "../../components/ListInsightItems";
import InsightGenericView from "../../components/InsightGenericView";
import DiningInsightDetails from "./DiningInsightDetails";

const schema = yup
    .object({
        comments: yup.string().max(5000).nullable().notRequired(),
        name: yup.string().max(500).required("This field is required"),
    })
    .required("This field is required");

export default function DinningInsightReview() {
    const dispatch = useAppDispatch();
    const activityInsight = useSelector(selectActivityInsight);
    const hotelInsight = activityInsight?.hotelInsight;
    const { activityId, tripId } = useParams();

    const listDinningInsight = hotelInsight?.dinningInsights || [];

    const handleSubmitForm = async (data: any) => {
        const payload = {
            ...data,
        };
        await dispatch(
            addHotelDinningInsight(
                Number(tripId),
                Number(activityId),
                activityInsight?.id,
                payload
            )
        );
    };

    const handleRemoveInsight = (itemId: number) => {
        dispatch(
            removeHotelDinningInsight(
                Number(tripId),
                Number(activityId),
                activityInsight?.id,
                itemId
            )
        );
    };

    return (
        <InsightGenericView
            currentSection={
                PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DINNING
            }
            label="Dining & Drinking"
            typesLabel="Outlets"
            items={listDinningInsight}
            commentsKey="dinningComments"
            imagesKey="dinningImages"
            titleKey="name"
            subtitleKey="comments"
            itemDetailsView={DiningInsightDetails}
            onRemove={handleRemoveInsight}
            listInsightItems={(props) => (
                <ListInsightItems
                    {...props}
                    titleKey="name"
                    subtitleKey="comments"
                    icon="RoomServiceIcon"
                />
            )}
            formInsightItem={FormDinningItem}
            onSubmit={handleSubmitForm}
            schema={schema}
        />
    );
}
