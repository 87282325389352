import Skeleton from "react-loading-skeleton"

export const ItinerarySkeleton = () => {
    return <div className="flex flex-col text-left">
            <div>
            <Skeleton className="!rounded-xl min-h-[50px] max-w-[300px]" />
            </div>
            <div className="text-right">
            <Skeleton className="!rounded-xl min-h-[100px] m-3 max-w-[700px]" />
            </div>
            <div className="text-right">
            <Skeleton className="!rounded-xl min-h-[100px] m-3 max-w-[700px]" />
            </div>
            <div className="text-right">
            <Skeleton className="!rounded-xl min-h-[100px] m-3 max-w-[700px]" />
            </div>
        </div>
}
