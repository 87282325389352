import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getLinkFromNotification } from "../../../../bussiness/notifications";
import { PAGE_ROWS, PAGE_SECTIONS } from "../../../../constants";
import {
    NOTIFICATION_CTA_TYPES,
} from "../../../../constants/notifications";
import { useAuthStateContext } from "../../../../context/Auth";
import { setCurrentSection } from "../../../layout/layoutSlice";

import {
    handleCTAButtons,
    markNotificationAsRead,
} from "../../../notifications/notificationsSlice";
import {
    getTripNotifications,
    selectedTrip,
    setSelectedTripNotifications,
    tripNotifications,
} from "../../tripSlice";
import NotificationsList from "../../../notifications/NotificationList";

export default function TripNotifications() {
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const { user } = useAuthStateContext();
    const tripNotificationsInfo = useAppSelector(tripNotifications);

    const navigate = useNavigate();
    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_NOTIFICATIONS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchNotifications(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            dispatch(
                setSelectedTripNotifications({
                    selectedTripNotifications: {
                        data: [],
                        count: 0,
                        currentPage: 0,
                        isLoading: false,
                        skip: 0,
                        take: 0,
                        totalPages: 0,
                    },
                })
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip?.id]);

    const fetchNotifications = async (first?: boolean) => {
        if (trip?.id)
            await dispatch(
                getTripNotifications(
                    trip.id,
                    PAGE_ROWS,
                    first ? 0 : tripNotificationsInfo?.data?.length || 0
                )
            );
    };

    const handleClickAccept = (notification: any) => {
        if (user?.email)
            dispatch(
                handleCTAButtons(
                    user?.email,
                    notification,
                    NOTIFICATION_CTA_TYPES.ACCEPT
                )
            );
        return;
    };

    const handleClickDecline = (notification: any) => {
        if (user?.email)
            dispatch(
                handleCTAButtons(
                    user?.email,
                    notification,
                    NOTIFICATION_CTA_TYPES.DECLINE
                )
            );
        return;
    };

    const handleClickNotification = (notification: any) => {
        dispatch(markNotificationAsRead(notification.id));
        const to = getLinkFromNotification(notification);
        navigate(to);
    };

    return (
        <div>
            <div className="flex w-full justify-between items-start mb-5">
                <h3 className="text-left text-lg font-semibold">
                    Notifications
                </h3>
            </div>

            <NotificationsList
                isLoading={tripNotificationsInfo?.isLoading}
                userNotifications={tripNotificationsInfo?.data || []}
                fetchNotifications={fetchNotifications}
                handleClickNotification={handleClickNotification}
                handleClickAccept={handleClickAccept}
                handleClickDecline={handleClickDecline}
                notificationsPageCounter={{
                    totalPages: tripNotificationsInfo?.totalPages || 0,
                    currentPage: tripNotificationsInfo?.currentPage || 0,
                }}
            />
        </div>
    );
}
