export const defaultHotelInsightData = {
    roomsCount: 0,
    stars: 0,
    connectingRooms: false,
    generalComments: '',
    roomComments: '',
    wellnessComments: '',
    dinningComments: '',
    commonComments: '',
    goodForWhichClients: '',
  };

export const mapPayloadToFormData = (payload: any) => {
    const keys = Object.keys(payload);
    const formData = new FormData();
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === "newImages") {
        const values = payload[keys[i]];
        for (let index = 0; index < values.length; index++) {
          formData.append(`newImages`, values[index]);
        }
      } else {
        formData.append(keys[i], payload[keys[i]])
      }
    }
    return formData;
}