import { useNavigate } from "react-router";
import ButtonSquared from "../../../components/ButtonSquare";
import { ActivityType, PAGE_SECTIONS } from "../../../constants";
import Icon from "../../../assets/svg/Icon";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentSection } from "../../layout/layoutSlice";


export default function LibraryElements(props: any) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.ORGANIZATION_LIBRARY,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const elements = [
        {
            key: ActivityType.HOTEL,
            name: "Hotel",
            onClick: () => navigate(`${ActivityType.HOTEL}`),
        },
        {
            key: ActivityType.RESTAURANT,
            name: "Restaurant",
            onClick: () => navigate(`${ActivityType.RESTAURANT}`),
        },
        {
            key: ActivityType.OTHER,
            name: "Other",
            onClick: () => navigate(`${ActivityType.OTHER}`),
        },
    ];

    return (
        <>
            <div className="w-full flex items-center justify-between">
                <div className="flex flex-col items-start">
                    <div className="font-semibold text-base xl:text-lg">
                        Itinerary elements
                    </div>
                    <div className="font-semibold text-base text-gray-400">
                        Find or create reusable elements to add to your
                        itinerary
                    </div>
                </div>
            </div>
            <div>
                {elements.map((element) => (
                    <div
                        key={element.key}
                        className="flex flex-row items-center justify-between w-full border-b border-neutral-200 py-4 cursor-pointer hover:text-primary_orange-500"
                        onClick={element.onClick}
                    >
                        <div className="text-lg font-semibold">
                            {element.name}
                        </div>
                        <Icon
                            icon="ChevronRightIcon"
                            className="text-primary_magenta-500 w-7 h-7"
                        />
                    </div>
                ))}
            </div>
        </>
    );
};