import { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { AdminEndpoints, ButtonSize, PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import SupportModulesList from "./components/SupportModulesList";
import ButtonSquared from "../../../components/ButtonSquare";
import { useTableAdminContext } from "../../../context/TableAdmin";
import Modal from "../../../components/Modal";
import CreateSupportModuleForm from "./components/SupportModuleForm";
import { isLoading } from "../adminSlice";

export default function SupportModulesAdmin() {
    const dispatch = useAppDispatch();
    const {
        handleEndpoint,
        handleCloseEditModal,
        editModalOpen,
        handleClickAddNew,
        savingData,
    } = useTableAdminContext();

    useEffect(() => {
        handleEndpoint(AdminEndpoints.supportModules, true, 15);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.ADMIN_MODULES_SUPPORT,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(isLoading(savingData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savingData]);


    return (
        <div className="flex flex-col">
            <Modal open={editModalOpen} onClose={handleCloseEditModal}>
                <CreateSupportModuleForm onCancel={handleCloseEditModal} />
            </Modal>
            <div className="flex flex-row w-full justify-end">
                <div className="text-lg text"></div>
                <ButtonSquared
                    size={ButtonSize.SMALL}
                    label={"Add new"}
                    onClick={handleClickAddNew}
                />
            </div>
            <SupportModulesList />
        </div>
    );
}
