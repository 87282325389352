import { useMemo } from "react";
import Icon from "../../../assets/svg/Icon";
import DotsMenu from "../../../components/DotsMenu";
import ListWithPagination from "../../../components/ListWithPagination";
import { SHORT_YEAR_DATE_FORMAT, tripTypeOptions } from "../../../constants";
import { TripsDotMenuOptionsForAdmin } from "../../../constants/admin";
import { formatDate, getSelectOptionLabel } from "../../../helpers";

interface IAdminAllTripsList {
    trips: any;
    handlePaginatorChange: Function;
    handleClickDotsMenu: Function;
}

export default function AllTripsList({
    trips,
    handlePaginatorChange,
    handleClickDotsMenu,
}: IAdminAllTripsList) {
    const tripNormalized = useMemo(() => {
        const dataN = trips?.data?.map((trip: any) => {
            return {
                ...trip,
                dates: `${formatDate(
                    trip?.startDate,
                    SHORT_YEAR_DATE_FORMAT
                )} - ${formatDate(trip?.endDate, SHORT_YEAR_DATE_FORMAT)}`,
                owner: trip?.users[0]?.userEmail,
            };
        });
        return { ...trips, data: dataN };
    }, [trips.data]);

    const renderItem = ({ item: trip }: any) => {
        return (
            <div className="border-b border-b-gray-300 py-4">
                <div className="flex justify-between">
                    <div className="">
                        <div
                            className="text-base font-semibold text-left max-w-[250px] truncate"
                            title={trip?.title}
                        >
                            {trip?.title}
                        </div>
                        <div className="flex flex-row text-left mt-1">
                            <Icon
                                className="w-[12px] mr-1 text-neutral-400"
                                icon="CalendarMonthIcon"
                            />
                            <span className="truncate text-xs">
                                {trip?.dates}
                            </span>
                        </div>
                    </div>

                    <div className="text-left">
                        <DotsMenu
                            options={TripsDotMenuOptionsForAdmin}
                            handleClickMenu={(k: any) => {
                                handleClickDotsMenu(k, trip);
                            }}
                        />
                    </div>
                </div>

                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="ParticipantsIcon"
                    />
                    <span className="truncate text-xs">{trip?.owner}</span>
                </div>
            </div>
        );
    };
    return (
        <>
            <ListWithPagination
                renderItem={renderItem}
                items={tripNormalized}
                handlePaginatorChange={handlePaginatorChange}
                itemKey={(trip: any) => {
                    return `adminTripsItem_${trip?.id}`;
                }}
            />
        </>
    );
}
