import React, { FC, InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import InputPlaceAutocomplete from "./InputPlaceAutocomplete";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: any;
  register?: any;
  className?: string;
  control?: any;
  markRequired?: boolean;
}

const InputPlaceWithValid: FC<InputProps> = ({
  name,
  error,
  label,
  control,
  onChange,
  ...rest
}) => {
  const handleChange = (e : any, onChangeController: Function) => {
    if(onChange) onChange(e)
    if(onChangeController) onChangeController(e)
  }
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }: any) => (
        <InputPlaceAutocomplete
          name={name}
          label={label}
          onChange={(e: any) =>{handleChange(e, onChange)}}
          onBlur={onBlur}
          ref={ref}
          error={error && Object.keys(error).length > 0 && 'Required field'   }
          {...rest}
        />
      )}
    />
  );
};

export default InputPlaceWithValid;
