import React from 'react';
interface ShowMoreButtonProps {
    onClick: () => void;
    className?: string;
}
const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({ onClick, className = '' }) => {
    return (
        <div className={`flex text-center py-2 px-6 !bg-white ${className}`}>
            <div className="flex-grow">
                <span
                    onClick={onClick}
                    className="text-orange-500 hover:text-orange-600 text-sm cursor-pointer font-semibold self-center"
                >
                    Show more
                </span>
            </div>
        </div>
    );
};
export default ShowMoreButton;
