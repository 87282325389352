import { useState } from "react";

interface TabProps {
    items: { id: string | number; text: string; value?: any }[];
    defaultSelected: string | number;
    className?: string;
    onChange?: any;
    small?: boolean;
}

export default function TabBar(props: TabProps) {
    const [selectedItem, setSelectedItem] = useState<string | number>();
    const selectItem = (item: string | number) => {
        setSelectedItem(item);
        props.onChange && props.onChange(item);
    };
    return (
        <div
            className={`max-w-full flex items-center flex-grow ${props.className} overflow-x-scroll remove-scrollbar`}
        >
            <div className="flex">
                {props.items.map((item, index: number) => (
                    <div
                        key={`tabBarItem_${item?.id}`}
                        className={`flex-1 text-center mx-2 w-fit group ${
                            (selectedItem || props.defaultSelected) !== item.id
                                ? "cursor-pointer"
                                : ""
                        }`}
                    >
                        <div
                            key={item.id}
                            onClick={() => {
                                selectItem(item.id);
                            }}
                            className={`font-semibold ${
                                props.small ? "text-sm" : "text-base"
                            } xl:text-lg min-w-[150px] whitespace-nowrap ${
                                (selectedItem || props.defaultSelected) ===
                                item.id
                                    ? " border-b-orange-600 text-orange-600"
                                    : "text-gray-400 group-hover:text-orange-500  group-hover:border-b-orange-500"
                            }`}
                        >
                            {item.text}
                        </div>
                        <div className="flex justify-center mt-2">
                            <div
                                className={`${
                                    (selectedItem || props.defaultSelected) ===
                                    item.id
                                        ? " bg-orange-600"
                                        : "bg-neutral-400 group-hover:bg-orange-500"
                                } text-center h-1 align-middle self-center w-[110%]`}
                            ></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
