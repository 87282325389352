import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { PAGE_SECTIONS } from "../../constants";

export interface ILayout {
    isLoading: Boolean;
    currentSection: PAGE_SECTIONS | null;
    error: string;
    currentBreakpoint: any;
}

const initialState: ILayout = {
    isLoading: false,
    currentSection: null,
    error: "",
    currentBreakpoint: {},
};

export const layoutSlice = createSlice({
    name: "layout",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setCurrentSection: (
            state,
            action: PayloadAction<{ currentSection: PAGE_SECTIONS }>
        ) => {
            state.currentSection = action.payload.currentSection;
        },
        setCurrentBreakpoint: (
            state,
            action: PayloadAction<{ currentBreakpoint: any }>
        ) => {
            state.currentBreakpoint = action.payload.currentBreakpoint;
        },
    },
});

export const { isLoading, setCurrentSection, setError, setCurrentBreakpoint } =
    layoutSlice.actions;
export const selectCurrentSection = (state: RootState) =>
    state.layout.currentSection;
export const selectCurrentBreakpoint = (state: RootState) =>
    state.layout.currentBreakpoint;

export default layoutSlice.reducer;
