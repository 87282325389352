import React, { useEffect, useState } from "react";
import InputErrorLabel from "../InputErrorLabel";
import InputLabel from "../InputLabel";
import MagnifyIcon from "../../assets/svg/MagnifyIcon.svg";
import useDebounce from "../../hooks/useDebounce";
const inputClasses = {
  normal:
    "px-3 py-2 pl-12 border-[.5px] rounded-[40px] border-[#E5E5E5] block w-full text-base font-normal",
  error:
    "px-3 py-2 pl-12 border-[.5px] rounded-[40px] border-alert_red  block w-full text-base font-normal",
};

const InputSearch = React.forwardRef((props: any, ref: any) => {
  const [searchText, setSearchText] = useState("");
  const {
    label,
    id,
    name,
    type,
    placeholder,
    defaultValue,
    error,
    onChange,
    ...others
  } = props;

  const debouncedSearchText = useDebounce(searchText, 500);
  useEffect(() => {
    onChange(debouncedSearchText);
  }, [debouncedSearchText]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    setSearchText(value);
  };
  return (
    <>
      <InputLabel inputId={id} label={label} error={error} />
      <input
        style={{
          background: `url(${MagnifyIcon}) no-repeat scroll 20px 12px`,
        }}
        ref={ref}
        type={type}
        name={name}
        id={id}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className={error ? inputClasses.error : inputClasses.normal}
        onChange={handleChange}
        {...others}
      />
      <InputErrorLabel error={error} />
    </>
  );
});
export default InputSearch;
