import { useMemo, useState } from "react";
import Icon from "../../assets/svg/Icon";
import ModalConfirmation from "../Modal/ConfirmationModal";
import { getYoutubeVideoId, isYoutubeVideo } from "../../helpers";
import { SUPPORT_MODULE_SECTIONS_OPTIONS } from "../../constants";
import defaultImage from "../../assets/images/support/home.png";

export const SupportVideItem = (props: any) => {
    const { item, onSelect, preview, onEdit, onDelete, showTopBorder } = props;
    const [deleteModalOpen, setdeleteModalOpen] = useState(false);

    const videoId = useMemo(() => {
        return getYoutubeVideoId(item?.videoLink);
    }, [item]);
    const isYoutube = useMemo(() => {
        return isYoutubeVideo(item?.videoLink);
    }, [item]);

    const sectionImage = useMemo(() => {
        return SUPPORT_MODULE_SECTIONS_OPTIONS.find(
            (i) => i.value === item?.section
        )?.image;
    }, [item?.section]);

    return (
        <>
            {!!showTopBorder && <div className="border-t mr-4 mt-2 pt-2"></div>}

            <div
                onClick={onSelect}
                className={`w-full py-2 gap-x-6 gap-y-4 md:flex md:flex-row flex-wrap ${
                    onSelect ? "hover:bg-gray-50 cursor-pointer" : ""
                }`}
            >
                <ModalConfirmation
                    open={deleteModalOpen}
                    title="Are you sure?"
                    description={`You are about to delete a video item`}
                    handleCancel={() => {
                        setdeleteModalOpen(false);
                    }}
                    handleSubmit={() => {
                        onDelete(item);
                        setdeleteModalOpen(false);
                    }}
                />
                {!!isYoutube && (
                    <div
                        className={`w-full ${preview ? "max-w-[300px]" : ""} `}
                    >
                        {preview ? (
                            <img
                                className="aspect-video"
                                src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
                                alt=""
                                height={"auto"}
                            />
                        ) : (
                            <iframe
                                className="aspect-video"
                                width="100%"
                                height="auto"
                                src={`https://www.youtube.com/embed/${videoId}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            />
                        )}
                    </div>
                )}

                {!isYoutube && (
                    <div
                        onClick={() => {}}
                        className={`w-full cursor-pointer ${
                            preview ? "max-w-[300px]" : ""
                        } `}
                    >
                        <a
                            href={item?.videoLink}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={`flex flex-row items-center text-base text-left font-semibold hover:text-orange-600 text-orange-500 mt-2 w-fit`}
                        >
                            <img
                                className="object-cover"
                                src={sectionImage || defaultImage}
                                alt=""
                                height={"auto"}
                            />
                        </a>
                    </div>
                )}

                <div className={`block ${preview ? "w-[90%] md:w-3/6" : ""}`}>
                    <div
                        className={`text-lg text-left font-semibold text-neutral-900 ${
                            preview
                                ? "truncate"
                                : "whitespace-pre-line break-before-all break-words"
                        }`}
                    >
                        {item?.title}
                    </div>
                    <div
                        className={`text-base text-left font-semibold text-neutral-800 ${
                            preview
                                ? "truncate"
                                : "whitespace-pre-line break-before-all"
                        }`}
                    >
                        {item?.description}
                    </div>
                    {/* {!isYoutube && (
                        <a
                            href={item?.videoLink}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={`flex flex-row items-center text-base text-left font-semibold hover:text-orange-600 text-orange-500 mt-2 w-fit`}
                        >
                            <Icon
                                materialIcon={mdiPlayCircleOutline}
                                className={`w-7 `}
                            />
                            <div className="ml-1">View tutorial</div>
                        </a>
                    )} */}
                    {(onEdit || onDelete) && (
                        <div className="flex flex-row gap-2 my-2">
                            <Icon
                                tabIndex={0}
                                icon={"TrashIcon"}
                                onClick={(e) => {
                                    setdeleteModalOpen(true);
                                }}
                                className="w-5 h-5 text-neutral-800 cursor-pointer hover:text-gray-900"
                            />
                            <Icon
                                tabIndex={0}
                                icon={"PencilIconOutline"}
                                onClick={(e) => {
                                    onEdit(item);
                                }}
                                className="w-5 h-5 text-neutral-800 cursor-pointer hover:text-gray-900"
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
