import React, { useMemo } from "react";

const ButtonGroupSwitchInput = React.forwardRef((props: any, ref) => {
    const { value, onChange, disabled } = props;

    const activeClasses = useMemo(() => {
        return disabled
            ? "bg-orange-300 text-white font-semibold"
            : "bg-orange-500 text-white font-semibold";
    }, [disabled]);
    return (
        <div
            className={`flex items-center border border-neutral-200 bg-white rounded-lg p-1 text-base ${
                disabled ? "text-neutral-400" : "text-neutral-900"
            }`}
        >
            <div
                className={`rounded-md py-1 px-3 cursor-pointer ${
                    value ? activeClasses : ""
                }`}
                onClick={() => {
                    !disabled && onChange(true);
                }}
            >
                Yes
            </div>

            <div
                className={`rounded-md py-1 px-3 cursor-pointer ${
                    !value ? activeClasses : ""
                }`}
                onClick={() => {
                    !disabled && onChange(false);
                }}
            >
                No
            </div>
        </div>
    );
});

export default ButtonGroupSwitchInput;
