import { useEffect, useMemo, useState } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "tailwindcss/defaultTheme"; // Fix the path

const fullConfig: any = resolveConfig(tailwindConfig as any);

export const getBreakpointValue = (value: string): number =>
    fullConfig?.screens[value]?.slice(
        0,
        fullConfig.screens[value]?.indexOf("px")
    );

export const getCurrentBreakpoint = (width: number): string => {
    let currentBreakpoint: string = "sm";
    let biggestBreakpointValue = 0
    for (const breakpoint of Object.keys(fullConfig.screens)) {
        const breakpointValue = Number(getBreakpointValue(breakpoint));
        if (
            breakpointValue > biggestBreakpointValue &&
            width >= breakpointValue
        ) {
            biggestBreakpointValue = breakpointValue;
            currentBreakpoint = breakpoint;
           
        }
    }
    
    return currentBreakpoint;
};

export const isMobile = (currentBreakpoint: string) =>
    currentBreakpoint === "sm";
export const isTablet = (currentBreakpoint: string) =>
    currentBreakpoint === "md";

export const isDesktop = (currentBreakpoint: string) => {
    const breakpointValue = Number(getBreakpointValue(currentBreakpoint));
    const desktopValue = Number(getBreakpointValue("lg"));
    return breakpointValue >= desktopValue;
};

export const useBreakpoints = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const currentB = useMemo(() => {
        return getCurrentBreakpoint(windowWidth);
    }, [windowWidth]);
    const isMobile = currentB === "sm";
    return { currentBreakpoint: currentB, isMobile };
};
