import { MouseEventHandler } from "react";
import { MemberCard } from "./MemberCard";

interface IParticipantsList {
    isLoading: boolean;
    members: Array<any> | undefined | null;
    handleClickMember: MouseEventHandler;
    canEdit?: boolean;
}
export const ParticipantsList = ({
    isLoading,
    members,
    handleClickMember,
    canEdit,
}: IParticipantsList) => {
    return (
        <div className="p-4">
            {members?.map((prt) => {
                return (
                    <MemberCard
                        key={`memberCard_${prt?.userEmail}`}
                        isLoading={isLoading}
                        member={prt}
                        canEdit={canEdit}
                        handleClick={handleClickMember}
                    />
                );
            })}
        </div>
    );
};
