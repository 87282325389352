import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { ActivityType, ACTIVITY_FORM_STEPS, PAGE_SECTIONS } from "../../../constants";
import { usePrompt } from "../../../hooks/useCallbackPrompt";
import { activityFormCurrentStep, setActivityForm } from "../../trips/tripSlice";
import NewHotelActivityForm from "../../trips/Trip/TripItinerary/Activity/NewActivityForm/Hotel";
import NewOtherActivityForm from "../../trips/Trip/TripItinerary/Activity/NewActivityForm/Other";
import NewRestaurantActivityForm from "../../trips/Trip/TripItinerary/Activity/NewActivityForm/Restaurant";
import Stepper from "../../trips/Trip/TripItinerary/Activity/NewActivityForm/Stepper";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import AddLibraryElementToTrip from "./AddLibraryElementToTrip/AddLibraryElementToTrip";
import { useDispatch } from "react-redux";
import { addLibraryElement, handleRedirectToTripActivityFormAfterNewElement, selectedOrganization } from "../organizationSlice";

export default function NewActivityForm(props: any) {
    const { activityType } = useParams();
    const navigate = useNavigate();
    const type: any = activityType;
    const steps = ACTIVITY_FORM_STEPS[type];
    const organization = useAppSelector(selectedOrganization);
    const currentStep = useAppSelector(activityFormCurrentStep);
    const dispatch = useDispatch();
    const [showExitDialog, setShowExitDialog] = useState(false);
    const [addElementToTripModal, setAddElementToTripModal] = useState(false);
    const [selectTripToAddElementModal, setSelectTripToAddElementModal] = useState(false);
    const [activityElementToAddToExistingTrip, setActivityElementToAddToExistingTrip] = useState<any>(null);
    const [activityElementToAddToNewTrip, setActivityElementToAddToNewTrip] = useState<any>(null);
    usePrompt("Are you sure? you will lose your changes", !!showExitDialog);

    useEffect(() => {
        setShowExitDialog(true);
        return () => {
            setShowExitDialog(false);
            dispatch(setActivityForm({ activityForm: {} }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateBack = () => {
        navigate(-1);
    };

    const onSubmit = async (activity: any) => {
        setShowExitDialog(false);
        // This is to avoid creating the activity element twice
        if (!activityElementToAddToNewTrip) {
            const activityElementToAddToNewTrip = await dispatch(
                addLibraryElement(organization?.id, activity)
            );
            setActivityElementToAddToNewTrip(activityElementToAddToNewTrip);
            dispatch(
                handleRedirectToTripActivityFormAfterNewElement(
                    activityElementToAddToNewTrip,
                    navigate
                )
            );
        }
        setActivityElementToAddToExistingTrip(activity);
        setAddElementToTripModal(true);
    }

    const { FormComponent, currentSection } = (() => {
        switch (type) {
            case ActivityType.HOTEL:
                return {
                    FormComponent: NewHotelActivityForm,
                    currentSection: PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_HOTEL,
                };
            case ActivityType.RESTAURANT:
                return {
                    FormComponent: NewRestaurantActivityForm,
                    currentSection: PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_RESTAURANT,
                };
            default:
                return {
                    FormComponent: NewOtherActivityForm,
                    currentSection: PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_OTHER,
                };
        }
    })();

    return (
        <div className="w-full grid grid-cols-6 gap-4 lg:min-h-full">
            <div className="col-span-6 lg:col-span-2">
                <div className="w-full flex flex-row lg:justify-start justify-center">
                    <Stepper steps={steps} current={currentStep} />
                </div>
            </div>
            <div className="col-span-6 lg:col-span-4 lg:border-l lg:border-solid-gray-300">
                <FormComponent
                    isLibraryElement
                    currentSection={currentSection}
                    navigateBack={navigateBack}
                    onSubmit={onSubmit}
                />
                <ModalConfirmation
                    open={addElementToTripModal}
                    title="Add element to an existing trip?"
                    description={`Would you like to add this activity to one of your existing trips?`}
                    cancelButtonText="No, thanks"
                    okButtonText="Yes, add to trip"
                    handleCancel={() => {
                        setAddElementToTripModal(false);
                        navigateBack();
                    }}
                    handleSubmit={(e) => {
                        setAddElementToTripModal(false);
                        setSelectTripToAddElementModal(true);
                    }}
                />
                <AddLibraryElementToTrip
                    open={selectTripToAddElementModal}
                    setOpen={setSelectTripToAddElementModal}
                    activityElement={activityElementToAddToExistingTrip}
                    activityElementToAddToNewTrip={activityElementToAddToNewTrip}
                />
            </div>
        </div>
    );
}