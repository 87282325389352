import FamguruLogo from "../../../assets/svg/LogoSquared.svg";
import FamguruText from "../../../assets/svg/Famguru.svg";
export const ExpiredLinkView = () => {
    return (
        <div>
            <div className="w-full min-h-screen mb-12 flex flex-col items-center justify-center">
                <div className="flex flex-row items-center gap-4">
                    <a href="/">
                        <img src={FamguruLogo} alt="" />
                    </a>
                    <a href="/">
                        <img src={FamguruText} alt="" />
                    </a>
                </div>
                <div className="mt-10 text-2xl text-center font-semibold text-orange-800">
                    The link you are trying to access
                </div>
                <div className="text-2xl text-center font-semibold text-orange-800">
                    has expired.
                </div>
                <div className="mt-6 text-base text-center font-semibold text-neutral-400">
                    Please request it again.
                </div>
            </div>
        </div>
    );
};
