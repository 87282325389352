import { mdiAlertCircleOutline } from "@mdi/js";
import Icon from "../../../../assets/svg/Icon";
import ButtonSquared from "../../../../components/ButtonSquare";
import { ButtonSize } from "../../../../constants";

export const TopTripBanner = ({
    title,
    text,
    action,
    actionLabel,
    secondAction,
    secondAactionLabel,
    type = "warning",
    className,
    hideIcon,
    actionWrapperClass,
}: {
    title: any;
    text?: string;
    action?: any;
    actionLabel?: string;
    secondAction?: any;
    secondAactionLabel?: string;
    type?: "warning";
    className?: string;
    hideIcon?: boolean;
    actionWrapperClass?: string;
}) => {
    const typeStyles = {
        warning: {
            card: "bg-[#FDF2EE] border border-[#E26027]",
            icon: "text-[#DC458B]",
        },
    };
    return (
        <div
            className={`${typeStyles[type].card} rounded-lg py-4 px-6  ${className}`}
        >
            <div className="flex flex-row gap-x-2">
                {!hideIcon && (
                    <div>
                        <Icon
                            materialIcon={mdiAlertCircleOutline}
                            className={`${typeStyles[type].icon} w-5`}
                        />
                    </div>
                )}

                <div className="flex flex-col">
                    <div className="flex flex-row gap-x-2 flex-wrap">
                        <span className="font-semibold text-sm text-left">
                            {title}
                        </span>
                    </div>
                    <div className="text-neutral-500 font-normal text-sm text-left">
                        {text}
                    </div>
                </div>
            </div>
            {!!action && !!actionLabel && (
                <div className={`mt-4 ${actionWrapperClass}`}>
                    {!!secondAactionLabel && !!secondAction && (
                        <ButtonSquared
                            outlined
                            onClick={secondAction}
                            size={ButtonSize.FIT}
                            className="px-4 mr-4"
                            label={secondAactionLabel}
                        />
                    )}
                    <ButtonSquared
                        onClick={action}
                        size={ButtonSize.FIT}
                        className="px-4"
                        label={actionLabel}
                    />
                </div>
            )}
        </div>
    );
};
