import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from "@mdi/js";
import Icon from "../../assets/svg/Icon";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const imgStyle: any = {
    transform: "scale(0.97)",
    transition:
        "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
};

const ImageGalleryItem = ({
    photo,
    onImageClick,
    onImageDelete,
    className,
    selectionMode,
    selected,
}: any) => {
    return (
        <div className={`w-[250px] cursor-pointer`}>
            <div className="gallery-image__item relative">
                {onImageDelete &&
                    photo?.canEdit === "true" &&
                    !selectionMode && (
                        <div
                            onClick={() => {
                                onImageDelete && onImageDelete(photo);
                            }}
                            className="absolute z-10 top-4 right-4 bg-neutral-400/50 hover:bg-neutral-400 text-white rounded-lg text-xs p-1 hover:text-lg"
                        >
                            <Icon
                                icon="TrashIcon"
                                className="w-7 cursor-pointer z-10"
                            />
                        </div>
                    )}
                {!!selectionMode && (
                    <div
                        onClick={() => {
                            onImageClick && onImageClick(photo);
                        }}
                        className="absolute z-10 top-4 right-4"
                    >
                        {selected ? (
                            <Icon
                                materialIcon={mdiCheckboxMarked}
                                className="w-7 cursor-pointer text-orange-600 rounded-lg text-xs bg-white"
                            />
                        ) : (
                            <Icon
                                materialIcon={mdiCheckboxBlankOutline}
                                className="w-7 cursor-pointer text-white"
                            />
                        )}
                    </div>
                )}
                {onImageDelete &&
                    photo?.canEdit === "true" &&
                    !selectionMode && (
                        <div
                            onClick={() => {
                                onImageDelete && onImageDelete(photo);
                            }}
                            className="absolute z-10 top-4 right-4 bg-neutral-400/50 hover:bg-neutral-400 text-white rounded-lg text-xs p-1 hover:text-lg"
                        >
                            <Icon
                                icon="TrashIcon"
                                className="w-7 cursor-pointer z-10"
                            />
                        </div>
                    )}

                <LazyLoadImage
                    onClick={() => {
                        onImageClick && onImageClick(photo);
                    }}
                    style={selectionMode ? undefined : imgStyle}
                    className={`${className || ""} cursor-pointer`}
                    alt={photo?.title}
                    effect="blur"
                    src={photo?.src}
                    placeholderSrc={photo?.thumbnail}
                />
            </div>
        </div>
    );
};

export default ImageGalleryItem;
