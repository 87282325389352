import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import ShowMoreButton from "../../../components/ShowMoreButton";
import placeholder from "../../../assets/images/placeholder.png";
import Avatar from "../../../components/Avatar";
import { presenterTrip } from "../../../bussiness/trip";
import Icon from "../../../assets/svg/Icon";
import { mdiArrowRight } from "@mdi/js";
import moment from "moment";
import { useMemo } from "react";

export default function PastTripsCard(props: {
    title: string;
    trips: any[];
    narrowCard?: boolean;
    isLoading?: boolean;
    showMoreClick?: any;
}) {
    const { title, trips, isLoading, narrowCard, showMoreClick } = props;
    const navigate = useNavigate();
    const openTrip = (tripId: number) => {
        navigate(`/trips/${tripId}`);
    };

    if (isLoading) {
        return (
            <Skeleton
                className={`rounded-xl ${
                    narrowCard ? "min-h-[300px]" : "min-h-[432px]"
                } w-full h-full`}
            />
        );
    }

    return (
        <div
            className={`flex flex-col bg-white shadow-card rounded-xl pt-4 px-6`}
        >
            <div className="text-lg text-neutral-900 text-left font-semibold mb-2">
                {title}
            </div>
            {trips && !trips?.length && (
                <div className="mt-12 w-full text-center text-sm text-neutral-400">
                    There are no trips to show.
                </div>
            )}
            <div className="flex flex-col items-start justify-start lg:flex-row lg:flex-wrap overflow-hidden">
                {trips?.map((trip) => {
                    return (
                        <div
                            key={`tripPast_${trip?.id}`}
                            className="flex flex-row cursor-pointer hover:bg-neutral-50 w-full lg:w-[50%] p-2"
                            onClick={() => {
                                return openTrip(trip?.id);
                            }}
                        >
                            <PastTripItem trip={trip} />
                        </div>
                    );
                })}
            </div>
            <ShowMoreButton onClick={showMoreClick} />
        </div>
    );
}

export const PastTripItem = ({ trip }: { trip: any }) => {
    const presentedTrip = useMemo(() => {
        return presenterTrip(trip);
    }, [trip]);
    return (
        <>
            <Avatar
                squared
                src={
                    presenterTrip(trip)?.coverImage
                        ? presentedTrip?.coverImage
                        : placeholder
                }
                className="w-16 h-16"
            />
            <div className="pl-4 flex flex-col text-left truncate">
                <div className="font-semibold text-neutral-900 text-lg truncate">
                    {trip.title}
                </div>

                <span className="flex flex-row items-center text-sm text-neutral-600">
                    {presentedTrip?.startDate}

                    <>
                        <Icon
                            materialIcon={mdiArrowRight}
                            className="w-[16px]"
                        />
                        {presentedTrip?.endDate}
                    </>
                </span>
            </div>
        </>
    );
};
