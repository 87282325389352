import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal, { Modal_Size } from ".";
import { ButtonSize, ComplaintType } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import { UserTypeVariant } from "../UserType/UserType";
import {
    addUserRequest,
} from "../../features/authentication/userSlice";
import TextAreaInput from "../TextArea/TextArea";

export interface ModalProps {
    open?: boolean;
    handleClose: () => void;
    currentUserType: UserTypeVariant;
}

const revertUserType = {
    [UserTypeVariant.SUPPLIER]: UserTypeVariant.ADVISOR,
    [UserTypeVariant.ADVISOR]: UserTypeVariant.SUPPLIER,
};
export const UserRequestChangeRoleModal: React.FunctionComponent<ModalProps> = (
    props
) => {
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [closeMessage, setCloseMessage] = useState<string>("Close");
    const [reason, setReason] = useState<string>("");
    const [showConfirmButton, setShowConfirmButton] = useState<boolean>(true);
    const { open, handleClose: onClose } = props;
    const userProfile = useSelector((state: any) => state?.user?.profile);

    const dispatch = useDispatch();

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = async () => {
        await dispatch(
            addUserRequest({
                itemType: ComplaintType.USER_ROLE,
                itemId: userProfile.id,
                comments: `User ${userProfile?.name} (ID: ${
                    userProfile?.id
                }) wants to change role to ${
                    revertUserType[props?.currentUserType]
                }`,
                reason,
                title: `Change role - ${
                    revertUserType[props?.currentUserType]
                }`,
                data: { role: revertUserType[props?.currentUserType] },
            })
        );
        setTitle(`Your role change request has been submitted`);
        setDescription(
            `We have received your request to change your role and we are currently reviewing it. We will contact you with a decision shortly.`
        );
        setCloseMessage(`Close`);
        setShowConfirmButton(false);
    };

    useEffect(() => {
        setTitle(
            `Are you sure you want to change your role to ${revertUserType[
                props?.currentUserType
            ]?.toLowerCase()}?`
        );
        setDescription(
            `Please confirm your request and we will review it. We will notify you of the decision as soon as possible.`
        );
    }, [props?.currentUserType]);

    return (
        <Modal open={open} size={Modal_Size.small} onClose={handleClose}>
            <div>
                <div className="text-xl text-center font-semibold mb-2 text-neutral-900">
                    {title}
                </div>

                <div className="text-center mb-4">
                    <p className="lg:text-center text-sm text-neutral-600">
                        {description}
                    </p>
                </div>

                {showConfirmButton && (
                    <>
                        <div className="text-center mb-4">
                            <p className="lg:text-center text-sm text-neutral-600 font-bold">
                                Enter a brief explanation for changing your role
                                to a{" "}
                                {revertUserType[
                                    props?.currentUserType
                                ]?.toLowerCase()}
                            </p>
                        </div>

                        <div className="col-span-6">
                            <TextAreaInput
                                label="Reason for Request"
                                id="description"
                                name="description"
                                maxLength="5000"
                                onChange={(e: any) => setReason(e.target.value)}
                            />
                        </div>
                    </>
                )}

                <div className="flex flex-row gap-6 w-full justify-center text-center py-4 flex-wrap">
                    <ButtonSquared
                        outlined
                        onClick={onClose}
                        className="px-3"
                        label={closeMessage}
                        size={ButtonSize.SMALL}
                    />
                    {showConfirmButton && (
                        <ButtonSquared
                            label="Send request"
                            className="px-3"
                            disabled={!reason?.length}
                            onClick={handleSubmit}
                            size={ButtonSize.SMALL}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};
