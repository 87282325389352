import { useState } from "react";
import Icon from "../../assets/svg/Icon";
import ModalConfirmation from "../Modal/ConfirmationModal";

interface FilePreviewProps {
    name: string;
    onRemove?: any;
    viewOnly?: boolean;
}
export default function FilePreview(props: FilePreviewProps) {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { name, onRemove, viewOnly } = props;
    return (
        <>
            <ModalConfirmation
                open={deleteModalOpen}
                title="Are you sure?"
                description={`You are about to remove ${name}`}
                handleCancel={() => {
                    setDeleteModalOpen(false);
                }}
                handleSubmit={onRemove}
            />
            <div
                className={`p-4 flex content-center items-center justify-between border text-sm text-neutral-600 border-gray-200 rounded-lg w-full lg:w-fit ${
                    viewOnly ? "cursor-pointer hover:bg-neutral-100" : ""
                }`}
                title={name}
            >
                <div className="flex content-center items-center justify-between">
                    <div className="mr-4">
                        <Icon icon="FileOutlineIcon" className="w-7" />
                    </div>
                    <div>
                        {name.length > 20
                            ? "..." +
                              name.substring(name.length - 20, name.length)
                            : name}
                    </div>
                </div>

                {!viewOnly && (
                    <div className="ml-4 cursor-pointer hover:!text-gray-800">
                        <Icon
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDeleteModalOpen(true);
                            }}
                            icon="TrashIcon"
                            className="w-7"
                        />
                    </div>
                )}
            </div>
        </>
    );
}
