import { useMemo } from "react";
import HotelItemDetail from "../../components/HotelItemDetail/HotelItemDetail";
import GalleryImage from "../../../../../../../../components/ImagesGallery/Image";
import {
    ROOM_VIEW_TYPE,
    RoomViewTypeOptions,
} from "../../../../../../../../constants/insight";

interface RoomDetailItemProps {
    item: any;
}

const RoomInsightDetails = ({ item }: RoomDetailItemProps) => {
    const images = useMemo(() => {
        return item?.images
            ? Object.values(item?.images)?.map((img: any) => ({
                  ...img,
              }))
            : [];
    }, [item?.images]);

    const view = useMemo(() => {
        return item?.view !== ROOM_VIEW_TYPE.OTHER
            ? RoomViewTypeOptions.find((v) => {
                  return v.value === item.view;
              })?.label || ""
            : item?.viewOther;
    }, [item]);

    return (
        <div>
            <div className="flex items-center gap-6 flex-wrap w-full">
                {!!images?.length && (
                    <div className="flex flex-row flex-wrap gap-2 mt-4">
                        {images?.map((image: any, index: number) => (
                            <div className="w-fit" key={image.url}>
                                <GalleryImage
                                    viewOnly
                                    src={image.url}
                                    onImageClick={() => {}}
                                    imageClass="object-cover"
                                    description={image?.description}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="flex items-center gap-6 flex-wrap w-full mt-4">
                <HotelItemDetail
                    className="w-full lg:w-2/5"
                    label={"Category"}
                    value={item?.category}
                />
                <HotelItemDetail
                    className="w-full lg:w-2/5"
                    label={"Number/Name"}
                    value={item?.name}
                />
                <HotelItemDetail
                    className="w-full lg:w-2/5"
                    label={"View"}
                    value={view}
                />
                <HotelItemDetail
                    className="w-full lg:w-2/5"
                    label={"Connecting rooms"}
                    value={item?.connecting ? "Yes" : "No"}
                />
                <HotelItemDetail
                    className="w-full"
                    label="Comments"
                    value={item?.comments}
                />
            </div>
        </div>
    );
};

export default RoomInsightDetails;
