/* This example requires Tailwind CSS v2.0+ */


import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import {
    ACTIVITY_FORM_STEPS_IDS,
    PAGE_SECTIONS,
} from "../../../../../../../constants";
import {
    activityFormCurrentStep,
    setActivityFormCurrentStep,
} from "../../../../../tripSlice";
import ActivityDetailsForm from "../ActivityDetailsForm";
import ActivityFormPreview from "./FormPreview";
import OtherInfoForm from "./OtherInfo";
import OtherSelectionForm from "./OtherSelection";
import { setCurrentSection } from "../../../../../../layout/layoutSlice";

export default function NewOtherActivityForm(props: {
    currentSection: PAGE_SECTIONS;
    onSubmit: (activity: any) => void;
    navigateBack: () => void;
    isLibraryElement?: boolean;
}) {
    const dispatch = useAppDispatch();
    const { currentSection, navigateBack, isLibraryElement, onSubmit } = props;
    const currentStep = useAppSelector(activityFormCurrentStep);

    useEffect(() => {
        dispatch(
            setActivityFormCurrentStep({
                step: ACTIVITY_FORM_STEPS_IDS.OTHER_SELECTION,
            })
        );
        dispatch(
            setCurrentSection({
                currentSection,
            })
        );
    }, []);

    //const typeN: ActivityType = type || activityType;

    return (
        <>
            {currentStep === ACTIVITY_FORM_STEPS_IDS.OTHER_SELECTION ? (
                <OtherSelectionForm
                    isLibraryElement={isLibraryElement}
                    navigateBack={navigateBack}
                />
            ) : currentStep === ACTIVITY_FORM_STEPS_IDS.OTHER_INFO ? (
                <OtherInfoForm isLibraryElement={isLibraryElement} />
            ) : currentStep === ACTIVITY_FORM_STEPS_IDS.OTHER_DETAILS ? (
                <ActivityDetailsForm />
            ) : currentStep === ACTIVITY_FORM_STEPS_IDS.OTHER_CONFIRM ? (
                <ActivityFormPreview
                    isLibraryElement={isLibraryElement}
                    onSubmit={onSubmit}
                />
            ) : null}
        </>
    );
}
