import * as yup from "yup";
import Input from "../../../../../../../../components/Input";
import Button from "../../../../../../../../components/ButtonSquare";

import { addTripActivityRestaurantInsight } from "../../../../../../../hotelInsight/hotelInsightSlice";

import { InsightImages } from "../../../../../components/InsightImages";
import TextAreaInput from "../../../../../../../../components/TextArea";
import { useEffect } from "react";
import { useInsightState } from "../../../../../../../../hooks/useInsightState";
import InsightFormContainer from "../../components/InsightFormContainer";
import InsightInputContainer from "../../components/InsightInputContainer";
import { restaurantDetailComments } from "./restaurantExperience.util";
import SelectInput from "../../../../../../../../components/InputSelect/SelectInput";
import {
    restaurantAmbianceOptions,
    restaurantCuisineOptions,
} from "../../../../../../../../constants";
import { selectedTripActivity } from "../../../../../../tripSlice";
import { useSelector } from "react-redux";
import InsightHeader from "../../components/InsightHeader";
import { BlockModal } from "../../../../../../../../components/Modal/BllockerModal";

interface FormRestaurantDetailsProps {
    closeForm: () => void;
}

const schema = yup
    .object({
        comments: yup.string().max(5000).notRequired(),
        foodComments: yup.string().max(5000).notRequired(),
        drinkComments: yup.string().max(5000).notRequired(),
        expenseComments: yup.string().max(5000).notRequired(),
        serviceComments: yup.string().max(5000).notRequired(),
        dressCode: yup.string().max(5000).notRequired(),
        cuisine: yup.array().nullable(),
        ambiance: yup.array().nullable(),
        chef: yup.string().max(50).notRequired(),
    })
    .required("This field is required");

export default function FormRestaurantDetails({
    closeForm,
}: FormRestaurantDetailsProps) {
    const selectedActivity = useSelector(selectedTripActivity);
    const {
        restaurantInsight,
        activityId,
        tripId,
        dispatch,
        handleUploadFile,
        register,
        errors,
        handleSubmit,
        reset,
        control,
        setValue,
        isDirty,
    } = useInsightState({
        initialItem: null,
        schema,
    });

    useEffect(() => {
        const { contactInsights, ...initialRestaurantInsight } =
            restaurantInsight || {};
        reset(initialRestaurantInsight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurantInsight]);

    const handleCancel = () => {
        closeForm();
    };

    const handleSubmitForm = async (data: any) => {
        const restaurantId =
            selectedActivity?.restaurantActivity?.restaurant?.id;
        const payload = {
            ...data,
            restaurantId,
        };

        await dispatch(
            addTripActivityRestaurantInsight(
                Number(tripId),
                Number(activityId),
                payload,
                restaurantInsight?.insightId
            )
        );
        closeForm();
    };

    return (
        <div className="flex flex-col text-left">
            <InsightHeader showEditButton={false} onEdit={() => {}} />
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="flex-grow text-left text-lg font-semibold text-neutral-900 mt-3">
                    Restaurant Experience
                </div>
                <div className="col-span-6 sm:col-span-3 mt-4">
                    <InsightFormContainer className="gap-8">
                        {restaurantDetailComments.map(({ label, key }) => (
                            <InsightInputContainer>
                                <TextAreaInput
                                    register={register}
                                    label={label}
                                    type="text"
                                    defaultValue={restaurantInsight?.[key]}
                                    // id="key"
                                    name={key}
                                    error={errors[key]?.message}
                                />
                            </InsightInputContainer>
                        ))}
                        <InsightInputContainer>
                            <SelectInput
                                isMulti
                                control={control}
                                label="Cuisine Type"
                                options={restaurantCuisineOptions}
                                id="cuisine"
                                name="cuisine"
                                onChange={(value: string[]) =>
                                    setValue("cuisine", value, {
                                        shouldDirty: true,
                                    })
                                }
                                error={errors["cuisine"]?.message}
                                defaultValue={restaurantInsight?.cuisine}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <SelectInput
                                control={control}
                                isMulti
                                register={register}
                                label="Ambiance"
                                options={restaurantAmbianceOptions}
                                id="ambiance"
                                name="ambiance"
                                onChange={(value: string[]) =>
                                    setValue("ambiance", value, {
                                        shouldDirty: true,
                                    })
                                }
                                error={errors["ambiance"]?.message}
                                defaultValue={restaurantInsight?.ambiance}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <Input
                                register={register}
                                label="Chef name"
                                type="text"
                                name="chef"
                                defaultValue={restaurantInsight?.chef}
                                error={errors["chef"]?.message}
                            />
                        </InsightInputContainer>
                    </InsightFormContainer>
                </div>
                <div className="p-8 text-right flex gap-5 w-full lg:justify-end">
                    <BlockModal shouldBlock={isDirty} />
                    {!!restaurantInsight?.insightId && (
                        <Button
                            onClick={handleCancel}
                            outlined
                            label="Cancel"
                        />
                    )}
                    <Button type="submit" label={"Save"} />
                </div>
            </form>
        </div>
    );
}
