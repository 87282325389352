import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import useTripAccessControl from "../../../../../hooks/useAccessControl";
import { selectProfile } from "../../../../authentication/userSlice";
import { getActivity, selectError, setError, setSelectedTripActivity, getActivityReview } from "../../../tripSlice";
import { getActivityInsight } from "../../../../hotelInsight/hotelInsightSlice";

export default function Activity() {
    const dispatch = useAppDispatch();
    const profile = useAppSelector(selectProfile);
    const error = useAppSelector(selectError);
    let { tripId, activityId } = useParams();
    const location = useLocation();
    const fromLibrary = new URLSearchParams(location.search).get("fromLibrary") === "true";
    const navigate = useNavigate();
    const { allowed } = useTripAccessControl();

    useEffect(() => {
        if (error) {
            navigate("/trips");
        }

        return () => {
            dispatch(setError({ error: false }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (tripId && profile?.email) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
             dispatch(setSelectedTripActivity({ selectedTripActivity: null }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripId, profile?.email]);

    const fetchData = async () => {
        if (fromLibrary) return;
        await Promise.all([
            dispatch(getActivity(Number(tripId), Number(activityId))),
            dispatch(getActivityReview(Number(tripId), Number(activityId))),
            dispatch(getActivityInsight(Number(tripId), Number(activityId)))
        ]);
    };

    return allowed ? <Outlet /> : <Navigate to="/trips" />;
}
