import { AppThunk } from "../../app/store";
import { tripSlice } from "../trips/tripSlice";
import { handleError } from "../authentication/userSlice";
import serverApi from "../../api/server";
import {
    defaultHotelInsightData,
} from "./hotelInsight.util";

export const { isLoading, setActivityInsight } = tripSlice.actions;

export const deleteInsight = (insightId: number): AppThunk => {
    return (dispatch) => {
        dispatch({
            type: "",
            insightId: Number(insightId),
        });
    };
};

export const addTripActivityRestaurantInsight =
    (
        tripId: number,
        activityId: number,
        data: any,
        insightId?: number
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const restaurantId =
                getState()?.trip?.selectedTripActivity?.restaurantActivity
                    ?.restaurantId;
            const payload = { ...data };
            let response;
            if (insightId) {
                response = await serverApi.updateActivityRestaurantInsight(
                    tripId,
                    activityId,
                    insightId,
                    payload
                );
            } else {
                response = await serverApi.addActivityRestaurantInsight(
                    tripId,
                    activityId,
                    { ...payload, restaurantId}
                );
            }

            await dispatch(getActivityInsight(tripId, activityId));
            dispatch(isLoading(false));
            return response?.data?.id;
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };


export const addRestaurantContactInsight =
    (
        tripId: number,
        activityId: number,
        insightId?: number,
        data?: any
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const iId: any =
                insightId ||
                (await dispatch(
                    addTripActivityRestaurantInsight(tripId, activityId, {})
                ));
            const { id: contactId, ...payload } = data;
            if (contactId) {
                await serverApi.updateRestaurantContactInsight(
                    tripId,
                    activityId,
                    iId,
                    contactId,
                    payload
                );
            } else {
                await serverApi.addRestaurantContactInsight(
                    tripId,
                    activityId,
                    iId,
                    payload
                );
            }

            await dispatch(getActivityInsight(tripId, activityId));

            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const removeRestaurantContactInsight =
    (
        tripId: number,
        activityId: number,
        insightId: number,
        contactId: number
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeRestaurantContactInsight(
                tripId,
                activityId,
                insightId,
                contactId
            );
            await dispatch(getActivityInsight(tripId, activityId));

            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const addTripActivityOtherInsight =
    (
        tripId: number,
        activityId: number,
        data: any,
        insightId?: number
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const otherId =
                getState()?.trip?.selectedTripActivity?.otherActivity
                    ?.otherId;
            const payload = { ...data };
            let response;
            if (insightId) {
                response = await serverApi.updateActivityOtherInsight(
                    tripId,
                    activityId,
                    insightId,
                    payload
                );
            } else {
                response = await serverApi.addActivityOtherInsight(
                    tripId,
                    activityId,
                    { ...payload, otherId }
                );
            }

            await dispatch(getActivityInsight(tripId, activityId));
            dispatch(isLoading(false));
            return response?.data?.id;
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const getActivityInsight =
    (tripId: number, activityId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const insight = await serverApi.getActivityInsight(
                tripId,
                activityId
            );
            dispatch(setActivityInsight({ activityInsight: insight?.data || {} }));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const addTripActivityHotelInsight =
    (
        tripId: number,
        activityId: number,
        data: any,
        insightId?: number
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const hotelId =
                getState()?.trip?.selectedTripActivity?.hotelActivity?.hotelId;
            const payload = { ...defaultHotelInsightData, ...data };

            let response;
            if (insightId) {
                response = await serverApi.updateActivityHotelInsight(
                    tripId,
                    activityId,
                    insightId,
                    payload
                );
            } else {
                response = await serverApi.addActivityHotelInsight(
                    tripId,
                    activityId,
                   { ...payload, hotelId}
                );
            }

            await dispatch(getActivityInsight(tripId, activityId));
            dispatch(isLoading(false));
            return response?.data?.id;
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const addHotelRoomInsight =
    (
        tripId: number,
        activityId: number,
        insightId?: number,
        data?: any
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const iId: any =
                insightId ||
                (await dispatch(
                    addTripActivityHotelInsight(tripId, activityId, {})
                ));
            const { id: roomId, ...payload } = data;
            if (!roomId) {
                await serverApi.addHotelRoomInsight(
                    tripId,
                    activityId,
                    iId,
                    payload
                );
            } else {
                await serverApi.updateHotelRoomInsight(
                    tripId,
                    activityId,
                    iId,
                    roomId,
                    payload
                );
            }

            await dispatch(getActivityInsight(tripId, activityId));

            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const removeHotelRoomInsight =
    (
        tripId: number,
        activityId: number,
        insightId: number,
        roomId: number,
        cb?: any
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeHotelRoomInsight(
                tripId,
                activityId,
                insightId,
                roomId
            );
            await dispatch(getActivityInsight(tripId, activityId));
            dispatch(isLoading(false));
            return cb && cb();
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const addHotelDinningInsight =
    (
        tripId: number,
        activityId: number,
        insightId: number,
        data: any
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const iId: any =
                insightId ||
                (await dispatch(
                    addTripActivityHotelInsight(tripId, activityId, {})
                ));
            const { id: dinningId, ...payload } = data;

            if (!dinningId) {
                await serverApi.addHotelDinningInsight(
                    tripId,
                    activityId,
                    iId,
                    payload
                );
            } else {
                await serverApi.updateHotelDinningInsight(
                    tripId,
                    activityId,
                    iId,
                    dinningId,
                    payload
                );
            }

            await dispatch(getActivityInsight(tripId, activityId));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const removeHotelDinningInsight =
    (tripId: any, activityId: any, insightId: any, dinningId: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeHotelDinningInsight(
                tripId,
                activityId,
                insightId,
                dinningId
            );

            await dispatch(getActivityInsight(tripId, activityId));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const addHotelWellnessInsight =
    (
        tripId: number,
        activityId: number,
        insightId?: number,
        data?: any
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const iId: any =
                insightId ||
                (await dispatch(
                    addTripActivityHotelInsight(tripId, activityId, {})
                ));
            const { id: wellnessId, ...payload } = data;
            if (!wellnessId) {
                await serverApi.addHotelWellnessInsight(
                    tripId,
                    activityId,
                    iId,
                    payload
                );
            } else {
                await serverApi.updateHotelWellnessInsight(
                    tripId,
                    activityId,
                    iId,
                    wellnessId,
                    payload
                );
            }

            await dispatch(getActivityInsight(tripId, activityId));

            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const removeHotelWellnessInsight =
    (
        tripId: number,
        activityId: number,
        insightId: number,
        wellnessId: number
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeHotelWellnessInsight(
                tripId,
                activityId,
                insightId,
                wellnessId
            );
            await dispatch(getActivityInsight(tripId, activityId));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const addHotelCommonInsight =
    (
        tripId: number,
        activityId: number,
        insightId?: number,
        data?: any
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const iId: any =
                insightId ||
                (await dispatch(
                    addTripActivityHotelInsight(tripId, activityId, {})
                ));
            const { id: commonId, ...payload } = data;

            if (!commonId) {
                await serverApi.addHotelCommonInsight(
                    tripId,
                    activityId,
                    iId,
                    payload
                );
            } else {
                await serverApi.updateHotelCommonInsight(
                    tripId,
                    activityId,
                    iId,
                    commonId,
                    payload
                );
            }

            await dispatch(getActivityInsight(tripId, activityId));

            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const removeHotelCommonInsight =
    (
        tripId: number,
        activityId: number,
        insightId: number,
        commonId: number
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeHotelCommonInsight(
                tripId,
                activityId,
                insightId,
                commonId
            );

            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const addHotelContactInsight =
    (
        tripId: number,
        activityId: number,
        insightId?: number,
        data?: any
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const iId: any =
                insightId ||
                (await dispatch(
                    addTripActivityHotelInsight(tripId, activityId, {})
                ));
            const { id: contactId, ...payload } = data;
            if (contactId) {
                await serverApi.updateHotelContactInsight(
                    tripId,
                    activityId,
                    iId,
                    contactId,
                    payload
                );
            } else {
                await serverApi.addHotelContactInsight(
                    tripId,
                    activityId,
                    iId,
                    payload
                );
            }

            await dispatch(getActivityInsight(tripId, activityId));

            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const removeHotelContactInsight =
    (
        tripId: number,
        activityId: number,
        insightId: number,
        contactId: number
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeHotelContactInsight(
                tripId,
                activityId,
                insightId,
                contactId
            );
            await dispatch(getActivityInsight(tripId, activityId));

            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const removeHotelInsight =
    (tripId: number, activityId: number, insightId: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeHotelInsight(tripId, activityId, insightId);
            dispatch(deleteInsight(insightId));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };
