import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Button from "../../components/ButtonSquare";
import { createHotel, selectedHotel, updateHotel } from "./hotelSlice";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputPlaceAutocomplete from "../../components/InputPlaceAutocomplete";
import InputWithValid from "../../components/Input";
import { useEffect, useMemo, useState } from "react";
import GoogleMap from "../../components/Map";
import PhoneInput from "../../components/PhoneInput";

interface HotelFormProps {
    onChange?: any;
    onCancel?: any;
    name?: string;
    addNew?: boolean;
}

const schema = yup.object({
    name: yup.string().required("This field is required"),
    phone: yup.string().nullable(),
    website: yup.string().url("Enter a valid url").nullable(),
    pacInput: yup
        .object({
            street_number: yup.string().nullable(),
            route: yup.string().nullable(),
            postal_code: yup.string().nullable(),
            postal_code_suffix: yup.string().nullable(),
            locality: yup.string().nullable(),
            administrative_area_level_1: yup.string().nullable(),
            country: yup.string().required("This field is required"),
            fullAddress: yup.string().required("This field is required"),
            lat: yup.number().required("This field is required"),
            lng: yup.number().required("This field is required"),
        })
        .required("This field is required"),
});

export default function CreateHotelForm(props: HotelFormProps) {
    const { onCancel, onChange } = props;
    const [mapMarker, setmapMarker] = useState<any | null>(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const selectedhotel = useAppSelector(selectedHotel);
    const dispatch = useAppDispatch();

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        control,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const values = getValues();

    const hotel = useMemo(() => {
        return !props?.addNew ? selectedhotel : undefined;
    }, [props?.addNew, selectedhotel]);

    useEffect(() => {
        if (hotel) {
            setIsUpdate(true);
            reset({
                name: hotel?.name,
                website: hotel?.website,
                pacInput: {
                    street_number: hotel?.streetNumber,
                    route: hotel?.streetAddress,
                    postal_code: hotel?.zipCode,
                    locality: hotel?.city,
                    administrative_area_level_1: hotel?.state,
                    country: hotel?.country,
                    lat: hotel?.lat,
                    lng: hotel?.lng,
                    fullAddress: hotel?.fullAddress,
                },
                phone: hotel?.phone,
            });
            const marker = {
                position: {
                    lat: hotel?.lat,
                    lng: hotel?.lng,
                },
                title: hotel?.name,
            };
            setmapMarker(marker);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotel?.id, reset]);

    const changeAddress = (e: any) => {
        if (e.lat && e.lng) {
            const marker = {
                position: {
                    lat: e?.lat,
                    lng: e?.lng,
                },
                title: values?.name,
            };
            setmapMarker(marker);
        }
    };

    const handleSubmitForm = async (data: any) => {
        const payload = {
            name: data.name,
            phone: data.phone,
            website: data.website,
            fullAddress: data.pacInput.fullAddress,
            streetAddress: data.pacInput.route,
            streetNumber: data.pacInput.street_number,
            city: data.pacInput.locality,
            state: data.pacInput.administrative_area_level_1,
            country: data.pacInput.country,
            zipCode: data.pacInput.postal_code,
            lat: data.pacInput.lat,
            lng: data.pacInput.lng,
        };

        let newHotel;
        if (hotel?.id) {
            newHotel = await dispatch(updateHotel(hotel.id, payload));
        } else {
            newHotel = await dispatch(createHotel(payload));
        }
        onChange(newHotel);
    };

    return (
        <div className="mt-6 lg:mt-0 mx-6">
            <div className="flex flex-wrap">
                <div className="text-left font-semibold text-lg flex-grow">
                    {isUpdate ? "Edit hotel" : "Add hotel"}
                </div>
            </div>
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="flex flex-col">
                    <div className="py-5 bg-white">
                        <div className="mt-3 w-full lg:w-[400px]">
                            <InputWithValid
                                register={register}
                                label="Name"
                                type="text"
                                id="name"
                                name="name"
                                error={errors["name"]?.message}
                                defaultValue={hotel?.name || props.name}
                            />
                        </div>
                        <div className="mt-3 w-full lg:w-[400px]">
                            <InputPlaceAutocomplete
                                control={control}
                                label="Address"
                                type="text"
                                id="pacInput"
                                placeholder=""
                                name="pacInput"
                                onChange={changeAddress}
                                error={errors["pacInput"]}
                                defaultValue={hotel?.fullAddress}
                            />
                        </div>
                        <div className="mt-8">
                            {<GoogleMap marker={mapMarker} />}
                        </div>
                        <div className="flex flex-wrap w-full">
                            <div className="mt-6 w-full lg:w-[400px]">
                                <span className="block font-medium text-neutral-600 text-sm">
                                    Phone number
                                </span>
                                <PhoneInput
                                    control={control}
                                    id="phone"
                                    name="phone"
                                    error={errors["phone"]?.message}
                                />
                            </div>
                            <div className="hidden lg:block lg:flex-grow">
                                {" "}
                            </div>
                            <div className="mt-6 w-full lg:w-[400px] self-end">
                                <InputWithValid
                                    register={register}
                                    label="Website"
                                    type="text"
                                    id="website"
                                    name="website"
                                    error={errors["website"]?.message}
                                    defaultValue={hotel?.website}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3 text-right flex gap-5">
                    <Button outlined label="Cancel" onClick={onCancel} />
                    <Button type="submit" label="Save" />
                </div>
            </form>
        </div>
    );
}
