import { useMemo } from "react";
import { BADGE_COLORS } from "../../constants";

interface BadgeProps {
    text: string;
    color: "red" | "orange" | "green" | "gray" | "lightGray" | "blue" | "yellow";
    outlined?: boolean;
    size?: string;
    notBold?: boolean;
}
export default function Badge(props: BadgeProps) {
    const { text, outlined, size, color, notBold } = props;


    const BadgeClass = useMemo(() => {
        return `lg:min-w-[100px] text-${size ? size : "xs"} ${
            notBold ? "" : "font-semibold"
        } text-center  px-2 md:px-4 py-1 rounded-full ${
            outlined ? (notBold ? "border" : "border-2") : ""
        }  ${outlined ? BADGE_COLORS[color].border : BADGE_COLORS[color].bg} ${
            BADGE_COLORS[color].text
        }`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color, outlined, size]);
    return <div className={BadgeClass}>{text}</div>;
}
