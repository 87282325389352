import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextAreaInput from "../../../../components/TextArea";
import Input from "../../../../components/Input";
import CountrySelectInput from "../../../../components/InputCountrySelect";
import ButtonSquared from "../../../../components/ButtonSquare";
import {
    deNormalizeProfile,
    normalizeProfile,
} from "../../../../bussiness/user";
import { specializationOptions } from "../../../../constants";
import SelectInput from "../../../../components/InputSelect";

const schema = yup
    .object({
        awards: yup.string(),
        description: yup.string(),
        emergencyContactName: yup.string(),
        emergencyContactPhone: yup.string(),
        businessStartedYear: yup.number().typeError('This field must be a number'),
        destinationWant: yup.array(),
        destinationSell: yup.array(),
        specialization: yup.array(),
    })
    .required("This field is required");

export default function ProfessionalInfoForm({
    profile,
    onSubmit,
    onCancel,
}: any) {
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const profileDenormalized = useMemo(() => {
        return deNormalizeProfile(profile);
    }, [profile]);
    useEffect(() => {
        if (profileDenormalized) {
            reset({
                description: profileDenormalized.description,
                emergencyContactName: profileDenormalized.emergencyContactName,
                emergencyContactPhone:
                    profileDenormalized.emergencyContactPhone,
                destinationWant: profileDenormalized.destinationWant,
                destinationSell: profileDenormalized.destinationSell,
                awards: profileDenormalized.awards,
                businessStartedYear: profileDenormalized.businessStartedYear,
                specialization: profileDenormalized.specialization,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileDenormalized]);

    const handleSubmitForm = async (data: any) => {
        const formData = new FormData();
        const fields = normalizeProfile(data);
        formData.append("fields", JSON.stringify(fields));
        onSubmit && onSubmit(formData);
    };

    return (
        <>
            <div className="flex flex-col">
                <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(handleSubmitForm)}
                    encType="multipart/form-data"
                >
                    <div className="grid grid-cols-3 gap-x-4 lg:grid-cols-6 flex-wrap">
                        <div className="text-left break-words col-span-6 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Brief description about you
                            </span>

                            <TextAreaInput
                                register={register}
                                type="text"
                                id="description"
                                name="description"
                                error={errors["description"]?.message}
                            />
                        </div>
                        <div className="text-left break-words col-span-6 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Awards and Recognition
                            </span>

                            <TextAreaInput
                                register={register}
                                type="text"
                                id="awards"
                                name="awards"
                                error={errors["awards"]?.message}
                            />
                        </div>

                        <div className="text-left lg:max-w-xs break-words col-span-3">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Business Started Year
                            </span>

                            <Input
                                register={register}
                                type="number"
                                id="businessStartedYear"
                                name="businessStartedYear"
                                error={errors["businessStartedYear"]?.message}
                            />
                        </div>
                        <div className="text-left break-words col-span-6 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                {" "}
                                Destinations you want to know better (up to 10)
                            </span>

                            <CountrySelectInput
                                control={control}
                                id="destinationWant"
                                name="destinationWant"
                                label={"Destinations I want to know better"}
                                error={errors["destinationWant"]?.message}
                                multiple={true}
                                isMulti={true}
                                defaultValue={
                                    profileDenormalized.destinationWant
                                }
                                closeMenuOnSelect={false}
                                closeMenuOnScroll={true}
                                selectedOptionsMaxLength={10}
                            />
                        </div>
                        <div className="text-left break-words col-span-6 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Destinations you sell most often (up to 15)
                            </span>

                            <CountrySelectInput
                                control={control}
                                id="destinationSell"
                                name="destinationSell"
                                error={errors["destinationSell"]?.message}
                                multiple={true}
                                isMulti={true}
                                defaultValue={
                                    profileDenormalized.destinationSell
                                }
                                closeMenuOnSelect={false}
                                closeMenuOnScroll={true}
                                selectedOptionsMaxLength={15}
                                /* isOptionDisabled={(
                                        selectedOptions: Array<any>
                                    ) => {
                                        return selectedOptions.length >= 3;
                                    }} */
                            />
                        </div>
                        <div className="text-left break-words col-span-6 mt-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Specialization (up to 5)
                            </span>
                            <SelectInput
                                isMulti={true}
                                control={control}
                                options={specializationOptions}
                                closeMenuOnSelect={false}
                                id="specialization"
                                name="specialization"
                                error={errors["specialization"]?.message}
                                selectedOptionsMaxLength={5}
                                defaultValue={
                                    profileDenormalized?.specialization
                                }
                            />
                        </div>
                    </div>

                    <div className="mt-12 flex justify-end">
                        <span className="mr-4">
                            <ButtonSquared
                                outlined
                                label="Cancel"
                                onClick={onCancel}
                            />
                        </span>
                        <span>
                            <ButtonSquared type="submit" label="Save" />
                        </span>
                    </div>
                </form>
            </div>
        </>
    );
}
