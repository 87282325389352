import React, { FC, InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import CheckBoxInput from "./Checkbox";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    error?: object;
    register?: any;
    className?: string;
    control?: any;
}

const CheckBox: FC<InputProps> = ({
    name,
    error,
    label,
    control,
    value: val,
    ...rest
}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }: any) => (
                <CheckBoxInput
                    value={value}
                    name={name}
                    label={label}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={
                        error &&
                        Object.keys(error).length > 0 &&
                        "Required field"
                    }
                    {...rest}
                />
            )}
        />
    );
};

export default CheckBox;
