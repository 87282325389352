import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import server from "../api/server";
import { ElementAdminDotMenuKeys } from "../constants/admin";
import ModalConfirmation from "../components/Modal/ConfirmationModal";
import { PAGE_ROWS, TOAST_TYPE } from "../constants";
import { sendToast } from "../helpers";
import { AxiosResponse } from "axios";
import {
    IPaginatedData,
    IPaginatedDataInitialState as paginatedDataInitalState,
} from "../types/dataTypes";

/**
 * Represents the context for an element in the admin table.
 */
interface IElementAdminContext {
    id?: number;
    [key: string]: any;
}

/**
 * Represents the context for managing table administration.
 */
interface ITableAdminContext {
    /**
     * Handles the endpoint for fetching data.
     * @param url - The URL of the endpoint.
     * @param loadInitialData - Optional flag to indicate whether to load initial data.
     * @param take - Optional number of items to take.
     * @param skip - Optional number of items to skip.
     * @param customQueryParams - Optional object containing custom query parameters.
     */
    handleEndpoint: (
        url: string,
        loadInitialData?: boolean,
        take?: number,
        skip?: number,
        customQueryParams?: { [key: string]: any }
    ) => void;

    /**
     * Custom query parameters.
     * @example {
     *          section: "HOTEL", // The section of the element.
     *          platform: "WEB", // The platform of the element.
     *          startDate: "2021-09-01", // The start date of the element.
     *         endDate: "2021-09-30", // The end date of the element.
     *      }
     */
    customQueryParams: { [key: string]: any };

    /**
     * Sets the custom query parameters.
     */
    setCustomQueryParams: Dispatch<
        SetStateAction<{
            [key: string]: any;
        }>
    >;

    /**
     * The fetched data.
     */
    fetchedData: IPaginatedData;

    /**
     * Reset the fetched data.
     */
    resetState: () => void;

    /**
     * Handles closing the edit modal.
     */
    handleCloseEditModal: () => void;

    /**
     * Handles clicking the dots menu.
     * @param key - The key of the element.
     * @param element - The element object.
     */
    handleClickDotsMenu: (key: string, element: any) => void;

    /**
     * The current element.
     */
    currentElement: IElementAdminContext | null;

    /**
     * Handles adding a new element.
     * @param values - The values of the new element.
     */
    handleAddElement: (values: any) => void;

    /**
     * Handles updating an element.
     * @param id - The ID of the element to update.
     * @param values - The updated values of the element.
     */
    handleUpdateElement: (id: number, values: any) => void;

    /**
     * Handles clicking the "Add New" button.
     */
    handleClickAddNew: () => void;

    /**
     * Indicates whether the edit modal is open.
     */
    editModalOpen: boolean;

    /**
     * Sets the state of the edit modal.
     */
    setEditModalOpen: Dispatch<SetStateAction<boolean>>;

    /**
     * Indicates whether data is being loaded.
     */
    loading: boolean;

    /**
     * Sets the current element.
     */
    setCurrentElement: Dispatch<SetStateAction<IElementAdminContext | null>>;

    /**
     * Sends a custom element request.
     * @param url - The URL of the request.
     * @param method - The HTTP method of the request.
     * @param values - The values of the request.
     * @returns A promise that resolves to a boolean or an AxiosResponse object.
     */
    customElementRequest: (
        url: string,
        method: "GET" | "POST" | "PUT" | "DELETE",
        values: any
    ) => Promise<boolean | AxiosResponse<any, any>>;

    /**
     * Handles the search query change.
     * @param query - The new search query.
     */
    handleSearchChange: (query: string) => void;

    /**
     * Handles the paginator change.
     * @param newPage - The new page number.
     * @param skip - The number of items to skip.
     */
    handlePaginatorChange: (newPage: number, skip: number) => void;

    /**
     * Resets the page number.
     */
    resetPage: () => void;

    /**
     * Indicates whether data is being saved.
     */
    savingData: boolean;
}

const initialState: ITableAdminContext = {
    fetchedData: paginatedDataInitalState,
    customQueryParams: {},
    setCustomQueryParams: () => {},
    handleEndpoint: () => {},
    handleCloseEditModal: () => {},
    handleClickDotsMenu: () => {},
    handleAddElement: () => {},
    handleUpdateElement: () => {},
    handleClickAddNew: () => {},
    setCurrentElement: () => {},
    resetState: () => {},
    customElementRequest: () => new Promise((resolve, reject) => {}),
    setEditModalOpen: () => {},
    handleSearchChange: () => {},
    handlePaginatorChange: () => {},
    resetPage: () => {},
    editModalOpen: false,
    loading: false,
    currentElement: {},
    savingData: false,
};

const TableAdminContext = createContext<ITableAdminContext>(initialState);

export default function TableAdminProvider({ children }: any): JSX.Element {
    const [endpointURL, setEndpointURL] = useState<string>("");
    const [newPage, setNewPage] = useState<{
        skip: number;
        currentPage: number;
    }>({
        skip: 0,
        currentPage: 1,
    });
    const [search, setSearch] = useState<string>("");
    const [customQueryParams, setCustomQueryParams] = useState<{
        [key: string]: any;
    }>({});
    const [loading, setLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState<IPaginatedData>(
        paginatedDataInitalState
    );
    const [currentElement, setCurrentElement] =
        useState<IElementAdminContext | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [savingData, setSavingData] = useState(false);

    useEffect(() => {
        if (endpointURL) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, search, JSON.stringify(customQueryParams)]);

    const getData = async (
        _endpointURL?: string,
        _take?: number,
        _skip?: number,
        _customQueryParams?: { [key: string]: any }
    ) => {
        setLoading(true);
        setFetchedData(paginatedDataInitalState);
        try {
            const customParams = Object.keys(
                _customQueryParams || customQueryParams
            ).reduce((acc, key) => {
                return `${acc}&${key}=${customQueryParams[key]}`;
            }, "&");
            const skip = _skip || newPage.skip;

            const url = `${_endpointURL || endpointURL}?take=${
                _take || PAGE_ROWS
            }&skip=${skip || 0}&search=${search}${customParams}`;

            const response = await server.requestForAdmin(url, "GET");
            setFetchedData(response?.data);
        } catch (error) {
            sendToast(
                TOAST_TYPE.ERROR,
                "Something went wgrong getting the data"
            );
        }
        setLoading(false);
    };

    const resetPage = () => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const resetState = () => {
        setFetchedData(paginatedDataInitalState);
        resetPage();
        setCustomQueryParams({});
        setSearch("");
    };

    const handleSearchChange = (query: string) => {
        resetPage();
        setSearch(query);
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const handleClickDotsMenu = (key: string, element: any) => {
        switch (key) {
            case ElementAdminDotMenuKeys.edit:
                setCurrentElement(element);
                setEditModalOpen(true);
                break;
            case ElementAdminDotMenuKeys.delete:
                setCurrentElement(element);
                setDeleteModalOpen(true);
                break;
        }
    };

    const handleDeleteElement = async () => {
        setSavingData(true);
        setLoading(true);
        setDeleteModalOpen(false);
        try {
            const url = currentElement
                ? `${endpointURL}/${currentElement.id}`
                : "";
            await server.requestForAdmin(url, "DELETE");
            getData();
        } catch (error) {
            sendToast(
                TOAST_TYPE.ERROR,
                "Something went wgrong deleting the element"
            );
            setLoading(false);
        }
        setCurrentElement(null);
        setSavingData(false);
    };

    const handleAddElement = async (values: any) => {
        setSavingData(true);
        setCurrentElement(null);
        setLoading(true);
        try {
            await server.requestForAdmin(endpointURL, "POST", values);
            getData();
            setEditModalOpen(false);
        } catch (error) {
            sendToast(
                TOAST_TYPE.ERROR,
                "Something went wgrong adding the element"
            );
            setLoading(false);
        }
        setSavingData(false);
    };

    const customElementRequest = async (
        url: string,
        method: "GET" | "POST" | "PUT" | "DELETE",
        values: any
    ) => {
        setLoading(true);
        try {
            const response = await server.requestForAdmin(url, method, values);
            getData();
            return response || true;
        } catch (error) {
            sendToast(TOAST_TYPE.ERROR, "Something went wgrong");
            setLoading(false);
            return false;
        }
    };

    const handleUpdateElement = async (id: number, values: any) => {
        setCurrentElement(null);
        setLoading(true);
        setSavingData(true);
        try {
            const url = `${endpointURL}/${id}`;
            await server.requestForAdmin(url, "PUT", values);
            getData();
            setEditModalOpen(false);
        } catch (error) {
            sendToast(
                TOAST_TYPE.ERROR,
                "Something went wgrong updating the element"
            );
            setLoading(false);
        }
        setSavingData(false);
    };

    const handleClickAddNew = () => {
        setCurrentElement(null);
        setEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
    };

    const handleEndpoint = (
        url: string,
        loadInitialData: boolean = true,
        take?: number,
        skip?: number,
        customQueryParams?: { [key: string]: any }
    ) => {
        setEndpointURL(url);
        setCurrentElement(null);
        setFetchedData(paginatedDataInitalState);
        if (loadInitialData) {
            getData(url, take, skip, customQueryParams);
        }
    };

    const providerProps = {
        // state
        fetchedData,
        customQueryParams,
        editModalOpen,
        currentElement,
        loading,
        savingData,
        // actions
        handleEndpoint,
        setCustomQueryParams,
        handleCloseEditModal,
        handleClickDotsMenu,
        setCurrentElement,
        setEditModalOpen,
        handleAddElement,
        handleUpdateElement,
        handleClickAddNew,
        customElementRequest,
        handleSearchChange,
        handlePaginatorChange,
        resetPage,
        resetState,
    };

    return (
        <TableAdminContext.Provider value={providerProps}>
            <ModalConfirmation
                open={deleteModalOpen}
                title="Are you sure?"
                description={`You are about to remove this element`}
                handleCancel={() => {
                    setDeleteModalOpen(false);
                }}
                handleSubmit={handleDeleteElement}
            />
            {children}
        </TableAdminContext.Provider>
    );
}

export const useTableAdminContext = () => useContext(TableAdminContext);
