import Avatar from "../../../../../components/Avatar";
import StatusBadgeComponent from "../../../../../components/StatusBadge/StatusBadgeComponent";
import Table from "../../../../../components/Table";
import { TripUserRoleOptions } from "../../../../../constants";
import { getStatusFromFiles } from "../../../../../helpers";

export default function RequirementUsersTable(props: {
    participants: any[];
    onUserClick: (u: any) => any;
}) {
    const { participants, onUserClick } = props;
    const tableHeaders = ["Name / Email", "Role", "Status"];
    const tableHeadersClasses = ["", "", "text-center"];
    return (
        <Table
            headers={tableHeaders}
            tableHeadersClasses={tableHeadersClasses}
            isLoading={!participants}
        >
            {participants?.map((u: any) => {
                const roleLabel = TripUserRoleOptions.find(
                    (rO) => rO.value === u?.role
                );

                return (
                    <tr
                        key={u.userEmail}
                        className={"hover:bg-neutral-50 cursor-pointer"}
                        onClick={() => onUserClick(u)}
                    >
                        <td>
                            <div className="flex gap-3 items-center justify-start">
                                <Avatar
                                    src={
                                        !!u?.user?.avatar_thumb?.length
                                            ? u?.user?.avatar_thumb
                                            : u?.user?.avatar
                                    }
                                    className="w-6 h-6"
                                />

                                <span className="text-left">
                                    {u.user?.firstName
                                        ? `${u.user?.firstName} ${u.user?.lastName}`
                                        : u?.userEmail}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="flex gap-3 text-left items-center justify-start">
                                <div className="w-full">
                                    <div>{roleLabel?.label}</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="flex justify-start">
                                <span className="w-full text-left">
                                    <StatusBadgeComponent
                                        status={getStatusFromFiles(
                                            u.requirementFiles
                                        )}
                                    />
                                </span>
                            </div>
                        </td>
                    </tr>
                );
            })}
        </Table>
    );
}
