import { mdiAlert } from "@mdi/js";
import Icon from "../assets/svg/Icon";

type InputErrorLabelProps = {
    error?: any;
    message?: string;
    className?: string;
};

const errorClasses = {
    normal: "absolute mb-1 flex flex-row gap-1 items-center pt-1 text-left text-xs font-medium text-gray-700",
    error: "absolute mb-1 flex flex-row gap-1 items-center pt-1 text-left text-xs font-medium text-alert_red",
};

export default function InputErrorLabel(props: InputErrorLabelProps) {
    const { error, message, className = "mb-5" } = props;
    return (
        <div className={className}>
            {error ? (
                <p className={errorClasses.error}>
                    <Icon
                        materialIcon={mdiAlert}
                        className={`text-alert_red w-4`}
                    />
                    {error}
                </p>
            ) : message ? (
                <p className={errorClasses.normal}>{message}</p>
            ) : (
                <></>
            )}
        </div>
    );
}
