import React from "react";
import { IChatEntity } from "../../../types/chat.type";
import Avatar from "../../../components/Avatar";
import { useChatForm } from "../hooks/useChat.hook";
import { useSelector } from "react-redux";

interface ChatsListProps {
    conversations: IChatEntity[];
    setIsModalOpen: (isOpen: boolean) => void;
}

const ChatsList: React.FC<ChatsListProps> = ({
    conversations,
    setIsModalOpen,
}) => {
    const { onSelectedChat, getInitials, getFormattedDate, selectedChat } =
        useChatForm();
    const currentUser = useSelector((state: any) => state?.user?.profile);

    const handleSelectConversation = (conversationId: string) => {
        onSelectedChat(conversationId);
    };

    if (!conversations.length) {
        return (
            <div>
                <div className="text-center mt-12 font-medium text-sm text-neutral-800">
                    <div>Find colleagues you want to connect with.</div>
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="text-orange-500 mt-2"
                    >
                        Start new chat
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col w-full">
            {conversations.map((chat) => {
                const messagesArray = chat.messages
                    ? Object.values(chat.messages)
                    : [];
                const lastMessageObj =
                    messagesArray.length > 0
                        ? messagesArray[messagesArray.length - 1]
                        : null;
                const lastMessageContent = lastMessageObj
                    ? lastMessageObj.content
                    : "";
                const lastMessageDate = lastMessageObj
                    ? getFormattedDate(lastMessageObj.timestamp)
                    : "";

                const unreadMessages = messagesArray.filter(
                    (msg: any) => !msg.readBy || !msg.readBy[currentUser?.uid]
                ).length;

                
                return (
                    <div
                        key={chat.id}
                        className={`border-b flex-nowrap cursor-pointer pr-2 pb-2 pt-4 ${
                            chat.id === selectedChat?.id
                                ? "bg-neutral-100"
                                : "hover:bg-neutral-50"
                        }  mr-2`}
                        onClick={() => handleSelectConversation(chat.id)}
                    >
                        <div className="flex-grow pl-2">
                            <div className="flex justify-start items-center">
                                <div className="w-10 h-10 rounded-full bg-gray-200 mr-2 flex-shrink-0 relative">
                                    {chat.avatar ? (
                                        <Avatar
                                            src={chat.avatar}
                                            className="rounded-full w-full h-full object-cover"
                                        />
                                    ) : (
                                        <div className="w-full h-full flex items-center justify-center bg-gray-300 rounded-full text-gray-600">
                                            {getInitials(chat.title)}
                                        </div>
                                    )}
                                    {unreadMessages > 0 && (
                                        <span className="absolute top-0 right-[-7px] bg-primary_magenta-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                                            {unreadMessages}
                                        </span>
                                    )}
                                </div>
                                <div className="font-semibold truncate text-left">
                                    {chat?.title}
                                </div>
                            </div>
                            <div className="flex flex-row items-center mt-2 mx-2">
                                <div className="text-xs text-left font-semibold text-gray-400 truncate">
                                    {lastMessageContent}
                                </div>
                                <div className="flex-grow text-right text-xs font-semibold text-gray-400 whitespace-nowrap ml-1">
                                    {lastMessageDate}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ChatsList;
