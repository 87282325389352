import React, { useMemo, useState } from "react";
import Avatar from "../../../components/Avatar";
import DotsMenu from "../../../components/DotsMenu";
import { useChatForm } from "../hooks/useChat.hook";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import ReportModal from "./modals/ReportModal";

const ChatHeader: React.FC<{
    title: string;
    avatarUrl: string;
    onTitleClick: () => void;
    isGroup: boolean;
}> = ({ title, avatarUrl, onTitleClick, isGroup }) => {
    const { selectedUser, selectedChat, onBlockUser, onReportUser } = useChatForm();
    const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);

    const menuOptions = useMemo(
        () => [
            {
                label: "Block",
                key: "block",
                icon: "BlockIcon",
            },
            {
                label: "Report",
                key: "report",
                icon: "ReportIcon",
            },
        ],
        []
    );
    

    // Report
    const handleReportClick = () => {
        setIsReportModalOpen(true);
    };
    const handleReportConfirm = (reason: string) => {
        if (!selectedUser) return;
        if (reason === "") {
            alert("Please enter a reason");
            return;
        }
        onReportUser(reason);
        setIsReportModalOpen(false);
    };
    const handleReportCancel = () => {
        setIsReportModalOpen(false);
    };

    // Block
    const handleBlockClick = () => {
        console.log("Block clicked");
        setIsBlockModalOpen(true);
    };
    const handleBlockConfirm = () => {
        if (!selectedUser) return;
        onBlockUser();
        setIsBlockModalOpen(false);
    };
    const handleBlockCancel = () => {
        setIsBlockModalOpen(false);
    };

    const handleClickMenu = (key: string) => {
        if (key === "block" && selectedUser) {
            handleBlockClick();
        } else if (key === "report" && selectedUser) {
            handleReportClick();
        }
    };

    return (
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <div className="flex-grow" />
            <div className="flex-grow flex justify-center items-center flex-col">
                <Avatar src={avatarUrl} className="w-8 h-8 mb-1" />
                <h3
                    className={`text-lg font-semibold ${isGroup && 'cursor-pointer'}`}
                    onClick={onTitleClick}
                >
                    {title} &gt;
                </h3>
            </div>
            <div className="flex items-center justify-end flex-grow">
                {!selectedChat?.isGroup && <DotsMenu
                    options={menuOptions}
                    handleClickMenu={handleClickMenu}
                    className="text-xl"
                />}
            </div>
            <ModalConfirmation
                open={isBlockModalOpen}
                title={`Are you sure you want to block ${selectedUser?.firstName} ${selectedUser?.lastName}?`}
                description={`Blocking ${selectedUser?.firstName} ${selectedUser?.lastName} will prevent them from messaging you.`}
                okButtonText="Confirm"
                cancelButtonText="Cancel"
                handleCancel={handleBlockCancel}
                handleSubmit={handleBlockConfirm}
                className="min-h-[250px]"
            />
            <ReportModal
                open={isReportModalOpen}
                handleCancel={handleReportCancel}
                handleSubmit={handleReportConfirm}
            />
        </div>
    );
};

export default ChatHeader;
