import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import server from "../api/server";
interface IConstantsContext {
    constants: any;
}
const ConstantsContext = createContext<IConstantsContext>({ constants: {} });

export default function ConstantsProvider({ children }: any): JSX.Element {
    const [constants, setConstants] = useState({});
    const getConstantsFromServer = useCallback(async () => {
        try {
            const response = await server.getConstants();
            setConstants(response.data);
        } catch (error) {}
    }, []);

    useEffect(() => {
        getConstantsFromServer();
    }, [getConstantsFromServer]);

    return (
        <ConstantsContext.Provider value={{ constants }}>
            {children}
        </ConstantsContext.Provider>
    );
}

export const useConstantsContext = () => useContext(ConstantsContext);
