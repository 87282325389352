import moment from "moment";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import {
    ActivityIcon,
    ActivityType,
    activityTypeOnModel,
} from "../../../../constants";
import { groupBy } from "../../../../helpers";
import Icon from "../../../../assets/svg/Icon";
import _ from "lodash";
import ShowMoreButton from "../../../../components/ShowMoreButton";

interface ItineraryCardProps {
    trip?: any;
}
export const ItineraryCard = (props: ItineraryCardProps) => {
    const { trip } = props;
    const navigate = useNavigate();

    const normalizeActivities = (activities: Array<any>) => {
        let actNormalized: any = [];

        if (activities) {
            let activitiesToShow = activities
                ?.map((act: any) => {
                    return {
                        id: act[
                            activityTypeOnModel[
                                act.type as keyof typeof activityTypeOnModel
                            ]
                        ].activityId,
                        name: act[
                            activityTypeOnModel[
                                act.type as keyof typeof activityTypeOnModel
                            ]
                        ][act.type.toLowerCase()].name,
                        ...act,

                        startDate: moment
                            .utc(act.startDate)
                            .format("YYYY-MM-DD"),
                    };
                })
                .sort((a, b) =>
                    a.startDate < b.startDate
                        ? -1
                        : a.startDate === b.startDate
                        ? 0
                        : 1
                );
            const filteredActivities = activitiesToShow.filter((a) =>
                moment(a.startDate).isSameOrAfter(moment.now(), "day")
            );

            activitiesToShow = filteredActivities.length
                ? filteredActivities
                : activitiesToShow;

            actNormalized = groupBy(activitiesToShow.slice(0, 3), "startDate");
        }
        Object.keys(actNormalized)?.forEach((date: any) => {
            actNormalized[date] = _.sortBy(
                actNormalized[date],
                (a) => a.startHour
            );
        });
        return actNormalized;
    };

    const normalizedActivities = useMemo<any>(
        () => trip && normalizeActivities(trip.activities),
        [trip]
    );

    const handleActivityClick = (activityId: number) => {
        navigate(`/trips/${trip?.id}/itinerary/${activityId}`);
    };

    const handleItineraryClick = () => {
        navigate(`/trips/${trip?.id}/itinerary/`);
    };

    const getIcon = (act: any) => {
        return (
            act.type &&
            ActivityIcon[
                act.type === ActivityType.HOTEL &&
                moment.utc(act.startDate).format("DD-MM-YYYY") ===
                    moment.utc(act.endDate).format("DD-MM-YYYY")
                    ? ActivityType.VISIT
                    : (act.type as keyof typeof ActivityIcon)
            ]
        );
    };

    return !trip ? (
        <Skeleton className="h-full w-full shadow-sm rounded-xl" />
    ) : (
        <div className="flex flex-col text-left bg-white shadow-sm pb-2 rounded-xl lg:min-h-[432px] w-full">
            <div className="p-4 px-6 rounded-xl mb-4 flex-grow">
                <div className="font-semibold">Next Activities</div>
                {trip && !trip?.activities?.length && (
                    <div className="my-6 text-center text-sm text-neutral-400">
                        There are no future activities.
                    </div>
                )}
                {Object.keys(normalizedActivities).map((day: any) => {
                    return (
                        <div key={day} className="flex mt-4 gap-x-6">
                            <div className="flex flex-col mt-2 text-center text-neutral-600">
                                <div className="text-lg">
                                    {Number(
                                        moment(day, "YYYY-MM-DD").format("DD")
                                    )}
                                </div>
                                <div className="text-sm">
                                    {moment(day, "YYYY-MM-DD").format("ddd")}
                                </div>
                            </div>
                            <div className="flex flex-col flex-grow gap-y-4 overflow-hidden">
                                {normalizedActivities[day].map((act: any) => {
                                    const icon = getIcon(
                                        act
                                    ) as keyof typeof ActivityIcon;
                                    return (
                                        <div
                                            key={act.id}
                                            onClick={() => {
                                                handleActivityClick(act.id);
                                            }}
                                            className="flex bg-[#F7F7F7] hover:bg-neutral-100 items-center flex-grow p-4 rounded-lg cursor-pointer"
                                        >
                                            <div className="flex items-center bg-white p-2 w-10 h-10 rounded-lg mr-3">
                                                <Icon
                                                    icon={icon}
                                                    className="text-orange-600 w-8"
                                                />
                                            </div>
                                            <div className="flex flex-col overflow-hidden">
                                                <div className="text-md font-semibold truncate">
                                                    {act.name}
                                                </div>
                                                <div className="text-xs text-neutral-400">
                                                    {moment(
                                                        act.startHour,
                                                        "HH:mm"
                                                    ).format("hh:mm A")}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            <ShowMoreButton onClick={handleItineraryClick} />
        </div>
    );
};
