import { mdiMagnify } from '@mdi/js';
import { Icon as MDIcon } from "@mdi/react";

interface SearchProps {
  onChange: (value: string) => void;
}

const Search = (props: SearchProps) => {
  const { onChange } = props;

  return (
    <div className="relative mb-4">
      <input
        type="text"
        placeholder="Search"
        name="search"
        className="w-full p-2 bg-[#F7F7F7] rounded-lg pl-10"
        onChange={(e) => onChange(e.target.value)}
      />
      <MDIcon
            path={mdiMagnify}
            size={1}
            color="black"
            className="absolute top-2 left-3"
        />
    </div>
  );
};

export default Search;
