import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import {
    getProfileFull,
    selectProfile,
    selectProfileExtra,
    updateProfile,
    updateProfileExtra,
} from "../userSlice";
import { ButtonSize } from "../../../constants";
import Button from "../../../components/ButtonSquare";
import EditProfile from "./EditProfile";
import TabComponent from "../../../components/TabComponent";
import DietaryEdit from "./Dietary/DietaryEdit";
import DietaryView from "./Dietary/DietaryView";
import ProfessionalInfoForm from "./Professional/ProfessionalInfoForm";
import ProfessionalInfo from "./Professional/ProfessionalInfo";
import ProfileDetails from "./ProfileDetails";
import { useSelector } from "react-redux";

const tabItemsIndex = { personalInfo: 0, dietaryReqs: 1, professionalInfo: 2 };
const tabItems = [
    {
        id: tabItemsIndex.personalInfo,
        text: "About Me",
    },
    {
        id: tabItemsIndex.professionalInfo,
        text: "Professional information",
    },
    {
        id: tabItemsIndex.dietaryReqs,
        text: "Dietary restrictions",
    },
];

export default function Profile() {
    const token = useSelector((state: any) => state?.user?.auth?.token);
    const dispatch = useAppDispatch();
    const [editting, setEditting] = useState<boolean>(false);

    const [selectedItem, setSelectedItem] = useState<string | number>(
        tabItems[0].id
    );

    const profile = useAppSelector(selectProfile);
    const profileExtra = useAppSelector(selectProfileExtra);

    useEffect(() => {
        if (token?.length) {
            dispatch(getProfileFull());
        }

        return () => {
            // cleanup
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        dispatch(setCurrentSection({ currentSection: PAGE_SECTIONS.PROFILE }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleEdit = () => {
        setEditting(!editting);
    };

    const changeTab = (tabIndex: string | number) => {
        setSelectedItem(tabIndex);
        setEditting(false);
    };

    const handleUpdateUserProfile = async (data: any) => {
        await dispatch(updateProfile(data));
        setEditting(false);
    };

    const handleDietaryUpdate = async (data: any) => {
        await dispatch(updateProfileExtra(data));
        setEditting(false);
    };

    return (
        <>
            <div className="flex mb-4 flex-wrap">
                <TabComponent
                    className="flex-grow text-left md:my-6"
                    onChange={changeTab}
                    items={tabItems}
                    defaultSelected={selectedItem}
                    small
                />
                {!editting && (
                    <div className="my-4">
                        <Button
                            outlined
                            size={ButtonSize.SMALL}
                            onClick={() => {
                                toggleEdit();
                            }}
                            label="Edit"
                        />
                    </div>
                )}
            </div>
            {selectedItem === tabItemsIndex.personalInfo &&
                (editting ? (
                    <EditProfile
                        profile={profile}
                        onSubmit={handleUpdateUserProfile}
                        onCancel={toggleEdit}
                    />
                ) : (
                    <ProfileDetails profile={profile} />
                ))}
            {selectedItem === tabItemsIndex.dietaryReqs &&
                (editting ? (
                    <DietaryEdit
                        profileExtra={profileExtra}
                        onSubmit={handleDietaryUpdate}
                        onCancel={toggleEdit}
                    />
                ) : (
                    <DietaryView
                        profileExtra={profileExtra}
                        isMe
                        onEdit={() => toggleEdit()}
                    />
                ))}
            {selectedItem === tabItemsIndex.professionalInfo &&
                (editting ? (
                    <ProfessionalInfoForm
                        profile={profile}
                        onSubmit={handleUpdateUserProfile}
                        onCancel={toggleEdit}
                    />
                ) : (
                    <ProfessionalInfo profile={profile} />
                ))}
        </>
    );
}
