import { useMemo } from "react";
import Paginator from "../../../components/Paginator";
import Table from "../../../components/Table";
import {
    PAGE_ROWS,
    SHORT_YEAR_DATE_FORMAT,
    tripTypeOptions,
} from "../../../constants";
import {
    TripsDotMenuKeys,
} from "../../../constants/admin";
import { formatDate, getSelectOptionLabel } from "../../../helpers";
import Icon from "../../../assets/svg/Icon";

interface IAdminAllTripsTable {
    trips: any;
    handlePaginatorChange: Function;
    handleClickDotsMenu: Function;
}

export default function AllTripsTable({
    trips,
    handlePaginatorChange,
    handleClickDotsMenu,
}: IAdminAllTripsTable) {
    const tableHeaders = ["Name", "Owner", "Dates", "Trip Access"];
    const tableHeadersClasses = [
        "",
        "",
        "text-center",
        "whitespace-nowrap",
    ];
    const tripNormalized = useMemo(() => {
        return trips?.data?.map((trip: any) => {
            return {
                ...trip,
                dates: `${formatDate(
                    trip?.startDate,
                    SHORT_YEAR_DATE_FORMAT
                )} - ${formatDate(trip?.endDate, SHORT_YEAR_DATE_FORMAT)}`,
                owner: trip?.users[0]?.userEmail,
            };
        });
    }, [trips.data]);

    return (
        <>
            <Table
                headers={tableHeaders}
                tableHeadersClasses={tableHeadersClasses}
                isLoading={trips?.isLoading}
                loadingRows={PAGE_ROWS}
            >
                {tripNormalized?.map((trip: any) => (
                    <tr className="text-sm" key={trip?.id}>
                        <td
                            className="text-left max-w-[250px] truncate"
                            title={trip?.title}
                        >
                            {trip?.title}
                        </td>
                        <td
                            className="text-left max-w-[250px] truncate"
                            title={trip?.owner}
                        >
                            {trip?.owner}
                        </td>
                        <td className="text-center">{trip?.dates}</td>
                        <td className="flex flex-row items-center justify-center gap-2">
                            <Icon
                                className="w-[24px] cursor-pointer text-neutral-400 hover:text-neutral-900"
                                icon="ArrowTopRightIcon"
                                onClick={() => {
                                    handleClickDotsMenu(
                                        TripsDotMenuKeys.view,
                                        trip
                                    );
                                }}
                            />
                            <Icon
                                className="w-[24px] cursor-pointer text-neutral-400 hover:text-neutral-900"
                                icon="TrashIcon"
                                onClick={() => {
                                    handleClickDotsMenu(
                                        TripsDotMenuKeys.delete,
                                        trip
                                    );
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </Table>
            {trips?.count > PAGE_ROWS && (
                <div className="self-end text-xs z-20 mt-4">
                    <Paginator
                        count={trips?.count}
                        take={PAGE_ROWS}
                        onChange={handlePaginatorChange}
                        totalPages={trips?.totalPages}
                        currentPage={trips?.currentPage}
                    />
                </div>
            )}
        </>
    );
}
