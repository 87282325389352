import AccountGroupIcon from "../../assets/svg/AccountGroupOutline.svg";
import { FC, useEffect, useMemo, useState } from "react";
import Icon from "../../assets/svg/Icon";
import ItineraryActionsList from "../../features/trips/Trip/TripItinerary/Components/ItineraryActionsList";
import moment from "moment";
import ActivityCard from "../../features/trips/Trip/TripItinerary/ActivityCard";
import useMeasure from "react-use-measure";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentBreakpoint } from "../../features/layout/layoutSlice";
import Modal, { Modal_Size } from "../Modal";
import { DulicateActivityForm } from "../../features/trips/Trip/TripItinerary/Activity/DuplicateActivityForm";

const Timeline: FC<any> = ({
    canEdit,
    title,
    current,
    withLocalPartner,
    children,
    onClickLocalPartner,
    activities,
    ActivityDate,
    isFirst,
    isLast,
    handleClickNewActivity,
    handleActivityClick,
    trip,
    ...rest
}) => {
    const [expanded, setExpanded] = useState(true);
    const [ref, bounds] = useMeasure();
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    const [duplicateSelectedActivity, setDuplicateSelectedActivity] =
        useState<any>(null);
    useEffect(() => {
        setExpanded(activities?.length > 0);
    }, [activities?.length]);

    const renderDate = useMemo(() => {
        return (
            <div className="flex items-center text-gray-600">
                {title}
                <Icon
                    icon={!expanded ? "ChevronUpIcon" : "ChevronDownIcon"}
                    className={`w-7 ml-2 text-neutral-300 cursor-pointer`}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                />
            </div>
        );
    }, [expanded, title]);

    const formattedDate = useMemo(
        () => moment.utc(ActivityDate).format("YYYY-MM-DD"),
        [ActivityDate]
    );

    const handleDuplicateActivity = (activity: any) => {
        setDuplicateSelectedActivity(activity);
    };

    return (
        <>
            <Modal
                open={!!duplicateSelectedActivity}
                onClose={() => {
                    setDuplicateSelectedActivity(null);
                }}
                size={Modal_Size.small}
            >
                <DulicateActivityForm
                    activity={duplicateSelectedActivity}
                    minDate={trip?.startDate}
                    maxDate={trip?.endDate}
                />
            </Modal>
            <div id={formattedDate} className="flex">
                <div className="flex text-neutral-400 justify-center lg:min-w-[140px]">
                    <div className="flex flex-col">
                        {isDesktop && renderDate}
                        {withLocalPartner && (
                            <div
                                className="rounded-lg bg-white shadow-lg w-fit p-2 cursor-pointer"
                                onClick={onClickLocalPartner}
                            >
                                <img
                                    alt="Add Activity Hotel"
                                    src={AccountGroupIcon}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`ml-2 ${
                        isLast
                            ? ""
                            : "border-l-2 border-dashed border-orange-600"
                    }  relative pb-4`}
                >
                    <div
                        className={`${
                            isFirst
                                ? "bg-orange-600"
                                : "border-4 border-orange-600 bg-white"
                        } rounded-full w-6 h-6 absolute -left-3`}
                    />
                </div>
                <div className="flex-grow pb-6">
                    <div className="ml-6 lg:ml-12 pr-6 lg:pr-12 mt-[-.5rem] w-full">
                        {!isDesktop && renderDate}
                        {canEdit && (
                            <ItineraryActionsList
                                date={formattedDate}
                                onActivitySelected={handleClickNewActivity}
                            />
                        )}
                        <div
                            className={`transition[height] ease-in-out duration-500 overflow-hidden ${
                                expanded ? "mb-12" : "mb-6"
                            }`}
                            style={{ height: expanded ? bounds.height : "0px" }}
                        >
                            <div ref={ref}>
                                {activities ? (
                                    activities?.map((activity: any) => {
                                        return (
                                            <div
                                                key={activity.id}
                                                className="pt-6 flex-grow"
                                            >
                                                <ActivityCard
                                                    activity={activity}
                                                    handleClick={() =>
                                                        handleActivityClick(
                                                            activity.id
                                                        )
                                                    }
                                                    onDuplicate={() => {
                                                        handleDuplicateActivity(
                                                            activity
                                                        );
                                                    }}
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="text-neutral-400 text-sm py-6">
                                        There are no activities for this date.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Timeline;
