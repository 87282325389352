import React, { useState } from "react";
import useGoogleMaps from "../../hooks/usePlacesService";
import AutocompleteInput from "../Autocomplete/AutocompleteInput";

const InputPlaceAutocomplete = React.forwardRef((props: any, ref: any) => {
    const [searchResults, setSearchResults] = useState<Array<any>>([]);
    const mapService = useGoogleMaps();
    const {
        label,
        id,
        name,
        type,
        placeholder,
        defaultValue,
        error,
        onChange,
        ...others
    } = props;

    const handleOnSearch = async (text: string) => {
        setSearchResults([]);
        try {
            const response: any = await mapService.findPlaces(text);
            setSearchResults(response);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePlaceSelect = async (selectedOption: any) => {
        let place = selectedOption;
        if (selectedOption.googlePlaceId) {
            const hasWindow = typeof window !== "undefined";
            const width = hasWindow ? window.innerWidth : null;
            const height = hasWindow ? window.innerHeight : null;
            place = await mapService.getPlaceDetails(
                selectedOption.googlePlaceId,
                {
                    maxWidth: width,
                    maxHeight: height,
                }
            );
        }

        if (onChange) onChange(place);
    };

    return (
        <>
            <AutocompleteInput
                onSearch={handleOnSearch}
                onChange={handlePlaceSelect}
                changeTextOnUpdate={true}
                label={label}
                ref={ref}
                type={type}
                name={name}
                id={id}
                defaultValue={defaultValue || ""}
                placeholder={placeholder}
                error={error}
                searchResults={searchResults}
                searchResultLabel="name"
                searchResultSubheader="fullAddress"
                {...others}
            />
        </>
    );
});
export default InputPlaceAutocomplete;
