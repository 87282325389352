import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectProfile } from "../../authentication/userSlice";
import {
    PAGE_SECTIONS,
    PROFILE_ORGANIZATION_TABS,
    PROFILE_TAB_ITEMS_INDEX,
} from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import Avatar from "../../../components/Avatar";
import TabComponent from "../../../components/TabComponent";
import ProfileDetails from "../../authentication/profile/ProfileDetails";
import DietaryInfo from "../../authentication/profile/Dietary/DietaryView";
import ProfessionalInfo from "../../authentication/profile/Professional/ProfessionalInfo";
import {
    getOrganizationUserProfile,
    selectedOrganizationUser,
    setSelectedOrganizationMember,
} from "../organizationSlice";
import Icon from "../../../assets/svg/Icon";
import { mdiArrowLeft } from "@mdi/js";

export default function ParticipantProfile() {
    const user: any = useAppSelector(selectedOrganizationUser);
    const dispatch = useAppDispatch();
    const profile = useAppSelector(selectProfile);
    const navigate = useNavigate();
    const { organizationId, userId } = useParams();

    const hidePersonalInfo = useMemo(() => {
        return !(profile?.isAdmin || profile?.email === user?.email);
    }, [profile?.email, profile?.isAdmin, user?.email]);

    const [selectedTab, setSelectedTab] = useState<string | number>(
        PROFILE_ORGANIZATION_TABS[0].id
    );

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.ORGANIZATION_MEMBERS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userId && organizationId) {
            dispatch(
                getOrganizationUserProfile(
                    Number(organizationId),
                    Number(userId)
                )
            );
        }

        return () => {
            dispatch(
                setSelectedOrganizationMember({
                    selectedOrganizationMember: null,
                })
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, organizationId]);

    const changeTab = (tabIndex: string | number) => {
        setSelectedTab(tabIndex);
    };

    const onGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            {!!user && (
                <div className="flex flex-col">
                    {onGoBack && (
                        <div
                            className="w-fit cursor-pointer flex flex-row items-center mb-4"
                            onClick={() => onGoBack()}
                        >
                            <div className="bg-neutral-100 rounded-full p-1 mr-2">
                                <Icon
                                    materialIcon={mdiArrowLeft}
                                    className={`w-5`}
                                />
                            </div>
                            <div className="text-sm">Back to organization details</div>
                        </div>
                    )}
                    <div className="flex flex-col lg:flex-row w-full justify-center items-center mb-6 gap-3 lg:gap-6">
                        <Avatar
                            src={user?.avatar}
                            className="w-10 h-10 lg:w-20 lg:h-20"
                        />

                        <div className="flex flex-grow flex-col text-left text-neutral-400">
                            <span className="text-lg font-semibold">
                                {user?.firstName} {user?.lastName}
                            </span>
                            <span className="text-base">{user?.email}</span>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <TabComponent
                            className="my-4 flex-grow text-left"
                            onChange={changeTab}
                            items={PROFILE_ORGANIZATION_TABS}
                            defaultSelected={selectedTab}
                        />
                    </div>
                    {selectedTab === PROFILE_TAB_ITEMS_INDEX.personalInfo && (
                        <div>
                            <ProfileDetails
                                profile={user}
                                hideAvatar={true}
                                hidePersonalInfo={hidePersonalInfo}
                            />
                        </div>
                    )}
                    {selectedTab === PROFILE_TAB_ITEMS_INDEX.dietaryReqs && (
                        <div>
                            <DietaryInfo
                                profile={user}
                                profileExtra={user?.profileExtra}
                                isMe={profile?.email === user?.email}
                            />
                        </div>
                    )}
                    {selectedTab ===
                        PROFILE_TAB_ITEMS_INDEX.professionalInfo && (
                        <div>
                            <ProfessionalInfo profile={user} />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
