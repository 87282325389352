import { FC, InputHTMLAttributes } from "react";
import Input from "./InputInstagram";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: any;
  register?: any;
  className?: string;
  inputStyle?: any
}

const InputInstagraWithValid: FC<InputProps> = ({
  register,
  name,
  error,
  label,
  inputStyle,
  ...rest
}) => {
  return (
    <Input
      inputStyle={inputStyle}
      aria-invalid={error ? "true" : "false"}
      {...register(name)}
      error={error}
      label={label}
      name={name}
      {...rest}
    />
  );
};

export default InputInstagraWithValid;
