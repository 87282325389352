import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { PAGE_ROWS, UserStatusOnOrganization } from "../../constants";
import { handleError } from "../authentication/userSlice";
import {
    NOTIFICATION_CTA_TYPES,
    NOTIFICATION_TYPES,
} from "../../constants/notifications";
import {
    rejectInvitation,
    updateUserStatusOnOrganization,
} from "../organizations/organizationSlice";
import { acceptInvite, rejectInvite } from "../trips/tripSlice";
import _ from "lodash";

interface INotifications {
    data: Array<any>;
    count: number;
    currentPage: number;
    totalPages: number;
    skip: number;
    take: number;
    isLoading: boolean;
    notReadCount: number;
}

const INotificationsInitialState = {
    data: [],
    count: 0,
    currentPage: 1,
    totalPages: 1,
    skip: 0,
    take: PAGE_ROWS,
    isLoading: false,
    notReadCount: 0,
};
const initialState: INotifications = INotificationsInitialState;

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        addNotification: (
            state,
            action: PayloadAction<{ notification: any }>
        ) => {
            state.data = _.orderBy(
                [action.payload.notification, ...state.data],
                "date",
                "desc"
            );
            state.count = state.count + 1;
            state.notReadCount = state.notReadCount + 1;
        },
        updateNotification: (
            state,
            action: PayloadAction<{
                notificationId: string;
                fieldsToUpdate: any;
            }>
        ) => {
            state.data = _.orderBy(
                state.data.map((noti: any) =>
                    noti.id === action.payload.notificationId
                        ? {
                              ...noti,
                              ...action.payload.fieldsToUpdate,
                          }
                        : noti
                ),
                "date",
                "desc"
            );
        },
        markAsRead: (
            state,
            action: PayloadAction<{ notificationId: string }>
        ) => {
            state.notReadCount = state.notReadCount
                ? state.notReadCount - 1
                : 0;
            state.data = state.data.map((noti: any) => {
                return noti.id === action.payload.notificationId
                    ? { ...noti, read: true }
                    : noti;
            });
        },
        markAllAsRead: (state, action: PayloadAction<{}>) => {
            state.notReadCount = 0;
            state.data = state.data.map((noti: any) => {
                return { ...noti, read: true };
            });
        },
        markTripInvitesAsRead: (
            state,
            action: PayloadAction<{ tripId: number; email: string }>
        ) => {
            state.notReadCount = 0;
            state.data = state.data.map((noti: any) => {
                if (noti?.data?.tripId === action.payload.tripId)
                    return { ...noti, read: true, showCTA: false };
                else return noti;
            });
        },
        setNotifications: (
            state,
            action: PayloadAction<{ notifications: any }>
        ) => {
            state.data = [
                ..._.orderBy(action.payload.notifications.data, "date", "desc"),
            ];
            state.count = action.payload.notifications.count;
            state.currentPage = action.payload.notifications.currentPage;
            state.isLoading = action.payload.notifications.isLoading;
            state.skip = action.payload.notifications.skip;
            state.take = action.payload.notifications.take;
            state.totalPages = action.payload.notifications.totalPages;
        },
        setNotReadNotificationsCount: (
            state,
            action: PayloadAction<{ notReadCount: number }>
        ) => {
            state.notReadCount = action.payload.notReadCount;
        },
    },
});

export const {
    isLoading,
    setNotifications,
    setNotReadNotificationsCount,
    addNotification,
    markAllAsRead,
    updateNotification,
    markAsRead,
    markTripInvitesAsRead,
} = notificationsSlice.actions;
export const selectNotificationsIsLoading = (state: RootState) =>
    state.notifications?.isLoading;
export const selectNotifications = (state: RootState) =>
    state.notifications?.data;
export const selectNotificationsNotReadCount = (state: RootState) =>
    state.notifications?.notReadCount;
export const selectNotificationsPagination = (state: RootState) => {
    return {
        totalPages: state.notifications?.totalPages,
        currentPage: state.notifications?.currentPage,
        count: state.notifications?.count,
    };
};
export const listenToNotifications =
    (email: string): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            const unsubscribe = Firebase.onNotifications(
                email,
                (notification: any) => {
                    dispatch(
                        addNotification({
                            notification: notification,
                        })
                    );
                }
            );
            return unsubscribe;
        } catch (error) {
            dispatch(handleError(error));
        }
    };

export const getNotificationsCount =
    (email: string): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            const count = await Firebase.getUnreadNotificationsCount(email);
            dispatch(setNotReadNotificationsCount({ notReadCount: count }));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const editNotification =
    (notificationId: string, fieldsToUpdate: any): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(updateNotification({ notificationId, fieldsToUpdate }));
            await Firebase.updateNotifications([
                { id: notificationId, ...fieldsToUpdate },
            ]);
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const editNotificationBy =
    (
        conditions: Array<{ key: string; value: any }>,
        fieldsToUpdate: any
    ): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            await Firebase.updateNotificationBy(conditions, {
                ...fieldsToUpdate,
            });
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const markAllNotificationsAsRead =
    (email: string): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        const currentNotif = getState().notifications;
        try {
            dispatch(markAllAsRead({}));
            await Firebase.markAllNotificationsAsRead(email);
            return;
        } catch (error) {
            dispatch(setNotifications({ notifications: currentNotif }));
            dispatch(
                setNotReadNotificationsCount({
                    notReadCount: currentNotif.notReadCount,
                })
            );
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const markNotificationAsRead =
    (notificationId: string): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        const currentNotif = getState().notifications;
        try {
            dispatch(markAsRead({ notificationId }));
            await Firebase.markNotificationAsRead(notificationId);
            return;
        } catch (error) {
            dispatch(setNotifications({ notifications: currentNotif }));
            dispatch(
                setNotReadNotificationsCount({
                    notReadCount: currentNotif.notReadCount,
                })
            );
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const getUserNotifications =
    (take: number = 10, skip: number = 0): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().notifications;
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getUserNotifications(take, skip);
            const { data, ...others } = response.data;
            dispatch(
                setNotifications({
                    notifications: {
                        ...others,
                        data: currentState?.data?.concat(data),
                    },
                })
            );
            dispatch(isLoading(false));
            return response?.data;
        } catch (error) {
            dispatch(
                setNotifications({
                    notifications: currentState,
                })
            );
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const handleCTAButtons =
    (
        email: string,
        notification: any,
        ctaType: NOTIFICATION_CTA_TYPES
    ): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        const currentNotif = getState().notifications;
        try {
            const promises = [];
            switch (notification.type) {
                case NOTIFICATION_TYPES.ORGANIZATION_INVITATION:
                    promises.push(
                        dispatch(
                            ctaType === NOTIFICATION_CTA_TYPES.ACCEPT
                                ? updateUserStatusOnOrganization(
                                      notification.data.organizationId,
                                      email,

                                      UserStatusOnOrganization.CONFIRMED
                                  )
                                : rejectInvitation(
                                      notification.data.organizationId,
                                      email
                                  )
                        )
                    );
                    break;
                case NOTIFICATION_TYPES.TRIP_INVITATION:
                    promises.push(
                        dispatch(
                            ctaType === NOTIFICATION_CTA_TYPES.ACCEPT
                                ? acceptInvite(notification.data.tripId, email)
                                : rejectInvite(notification.data.tripId, email)
                        )
                    );
                    break;

                default:
                    break;
            }
            promises.push(dispatch(markNotificationAsRead(notification.id)));
            promises.push(
                dispatch(editNotification(notification.id, { showCTA: false }))
            );
            return await Promise.all(promises);
        } catch (error) {
            dispatch(setNotifications({ notifications: currentNotif }));
            dispatch(
                setNotReadNotificationsCount({
                    notReadCount: currentNotif.notReadCount,
                })
            );
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export default notificationsSlice.reducer;
