import moment from "moment";
import DatePickerMaterial from "../../../../../components/DatePickerMaterial";
import TimePickerMaterial from "../../../../../components/TimePickerMaterial";
import InputLabel from "../../../../../components/InputLabel";
import InputSwitch from "../../../../../components/InputSwitch/InputSwitch";
import { parseMomentUtc, parseMomentUtcToLocal } from "../../../../../helpers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { ActivityType, ButtonSize } from "../../../../../constants";
import ButtonSquared from "../../../../../components/ButtonSquare";
import { useDispatch } from "react-redux";
import { duplicateTripActivity, selectedTrip } from "../../../tripSlice";
import { useAppSelector } from "../../../../../app/hooks";
import { useNavigate } from "react-router";

export const DulicateActivityForm = ({
    minDate,
    maxDate,
    activity,
}: {
    minDate: string;
    maxDate: string;
    activity: any;
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showEndHourInput, setShowEndHourInput] = useState<boolean>(false);
    const [startDateState, setStartDateState] = useState<any>(maxDate);
    const trip = useAppSelector(selectedTrip);

    const isHotel = useMemo(() => {
        return (
            (activity?.type === ActivityType.HOTEL &&
                parseMomentUtc(activity?.endDate).isAfter(
                    parseMomentUtc(activity?.startDate),
                    "days"
                )) ||
            activity?.isStayDay
        );
    }, [
        activity?.endDate,
        activity?.isStayDay,
        activity?.startDate,
        activity?.type,
    ]);

    const {
        formState: { errors },
        handleSubmit,
        reset,
        control,
    } = useForm({
        resolver: yupResolver(
            yup.object({
                startDate: yup
                    .date()
                    .required("This field is required")
                    .nullable(),
                endDate: yup
                    .mixed()
                    .nullable()
                    .transform((curr, orig) =>
                        orig === "" || !orig ? null : curr
                    )
                    .required("This field is required"),
                startHour: yup
                    .mixed()
                    .test(
                        `test-hour-format`,
                        "Invalid date format",
                        function (value) {
                            return !!moment(value).isValid();
                        }
                    )
                    .required("This field is required")
                    .nullable(),
                endHour: yup
                    .mixed()
                    .test(
                        `test-hour-format`,
                        "Invalid date format",
                        function (value) {
                            return !!moment(value).isValid();
                        }
                    )
                    .required("This field is required")
                    .nullable(),
            })
        ),
    });

    useEffect(() => {
        if (activity) {
            reset({
                startDate: moment.utc(activity?.startDate).format("yyyy-MM-DD"),
                endDate: moment.utc(activity?.endDate).format("yyyy-MM-DD"),
                startHour: moment(
                    activity?.startHour || "09:00",
                    "HH:mm"
                ) as any,
                endHour: moment(activity?.endHour || "09:00", "HH:mm") as any,
            });
            setStartDateState(
                moment.utc(activity?.startDate).format("yyyy-MM-DD")
            );
            setShowEndHourInput(activity?.startHour !== activity?.endHour);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity, reset]);
    const handleSwitchChange = (e: any) => {
        setShowEndHourInput(!!e.target.checked);
    };

    const handleSubmitForm = async (data: any) => {
        const startHour = data.startHour.format("HH:mm");
        const endHour = data.endHour.format("HH:mm");
        const payload = {
            startDate: moment(data.startDate).toDate(),
            endDate: isHotel
                ? moment(data.endDate).toDate()
                : moment(data.startDate).toDate(),
            startHour: startHour,
            endHour: isHotel || showEndHourInput ? endHour : startHour,
        };

        if (isHotel && payload.startDate >= payload.endDate) {
            return;
        }
        try {
            const newActivity: any = await dispatch(
                duplicateTripActivity(Number(trip?.id), activity?.id, payload)
            );
            navigate(`/trips/${trip?.id}/itinerary/${newActivity?.id}`);
        } catch (error) {}
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)} className="mb-4 mx-4">
            <div className="text-lg font-semibold text-neutral-900 mb-4">
                Duplicate this activity
            </div>
            <div className="grid grid-cols-6 gap-x-12">
                <div className="col-span-6 lg:col-span-3">
                    <DatePickerMaterial
                        control={control}
                        minDate={parseMomentUtcToLocal(minDate).toDate() as any}
                        maxDate={
                            moment.utc(maxDate).endOf("day").toDate() as any
                        }
                        defaultValue={activity?.startDate}
                        onChange={(date) => {
                            setStartDateState(date);
                        }}
                        name="startDate"
                        id="startDate"
                        label={isHotel ? "Check In" : "Start date"}
                        error={errors["startDate"]?.message}
                    />
                </div>

                <div className="col-span-6 lg:col-span-3">
                    <TimePickerMaterial
                        control={control}
                        label="Start Hour"
                        defaultValue={
                            moment(
                                activity?.startHour || "09:00",
                                "HH:mm"
                            ) as any
                        }
                        id="startHour"
                        name="startHour"
                        error={errors["startHour"]?.message}
                    />
                </div>
                {isHotel && (
                    <div className="col-span-6 lg:col-span-3">
                        <DatePickerMaterial
                            control={control}
                            minDate={
                                moment
                                    .utc(startDateState)
                                    .add(1, "day")
                                    .toDate() as any
                            }
                            maxDate={
                                moment.utc(maxDate).endOf("day").toDate() as any
                            }
                            name="endDate"
                            id="endDate"
                            label="Check Out"
                            error={errors["endDate"]?.message}
                        />
                    </div>
                )}
                {(isHotel || activity?.type !== ActivityType.HOTEL) && (
                    <div className="col-span-6 lg:col-span-3">
                        {isHotel ? (
                            <TimePickerMaterial
                                control={control}
                                label="End Hour"
                                defaultValue={
                                    moment(
                                        activity?.endHour || "09:00",
                                        "HH:mm"
                                    ) as any
                                }
                                id="endHour"
                                name="endHour"
                                error={errors["endHour"]?.message}
                            />
                        ) : (
                            <>
                                {" "}
                                <InputLabel
                                    inputId={"endHour"}
                                    label={"End Hour"}
                                    error={false}
                                />
                                <div className="flex flex-row gap-4 items-center">
                                    <InputSwitch
                                        id="showEndHour"
                                        name="showEndHour"
                                        defaultChecked={
                                            activity?.startHour !==
                                            activity?.endHour
                                        }
                                        onClick={handleSwitchChange}
                                    />
                                    {showEndHourInput && (
                                        <div className="flex-grow">
                                            <TimePickerMaterial
                                                control={control}
                                                defaultValue={
                                                    moment(
                                                        activity?.endHour ||
                                                            "09:00",
                                                        "HH:mm"
                                                    ) as any
                                                }
                                                id="endHour"
                                                name="endHour"
                                                error={
                                                    errors["endHour"]?.message
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
            <div className="flex flex-roww-full justify-center items-center mt-4">
                <ButtonSquared
                    label="Confirm"
                    size={ButtonSize.FIT}
                    className="px-4"
                />
            </div>
        </form>
    );
};
