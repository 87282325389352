import moment from "moment";
import { UserRoles } from "../../../constants";

export const userPresenter = (user: any) => {
    return {
        ...user,
        fullName: `${user?.firstName} ${user?.lastName}`,
        createdAtFormatted: moment(user.createdAt).format("DD MMM YY"),
        lastActiveFormatted: user?.lastActive
            ? moment(user?.lastActive).format("DD MMM YY")
            : "-",
        isAdmin: (user?.UserRoles || []).map((u: any) => u.role).includes(UserRoles.ADMIN)
    };
};

export default userPresenter;
