import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

interface TripsNumberBarProps {
    activeTrips: number;
    finishedTrips: number;
    isLoading?: boolean;
}

export default function TripsNumberBar(props: TripsNumberBarProps) {
    const { activeTrips, finishedTrips, isLoading } = props;
    const activeTripsNumber = useMemo(() => {
        return activeTrips < 10
            ? "0" + activeTrips || "0"
            : activeTrips || "00";
    }, [activeTrips]);

    const finishedTripsNumber = useMemo(() => {
        return finishedTrips < 10
            ? "0" + finishedTrips || "0"
            : finishedTrips || "00";
    }, [finishedTrips]);

    return isLoading ? (
        <Skeleton className="text-xs text-neutral-300 shadow-card rounded-xl px-5 lg:px-12 w-full h-[50px]" />
    ) : (
        <div className="flex flex-wrap justify-between gap-4 lg:gap-6 text-xs text-neutral-300 bg-white shadow-card rounded-xl py-2 px-4 lg:px-12 w-full">
            <div className="flex flex-grow items-center">
                <span className="text-neutral-900 text-xl font-semibold mr-2 lg:mr-6">
                    {activeTripsNumber}
                </span>
                <span className="text-neutral-400 text-base font-semibold">
                    ACTIVE TRIPS
                </span>
            </div>
            <div className="flex flex-grow items-center">
                <span className="text-neutral-900 text-xl font-semibold mr-2 lg:mr-6">
                    {finishedTripsNumber}
                </span>
                <span className="text-neutral-400 text-base font-semibold">
                    PAST TRIPS
                </span>
            </div>
        </div>
    );
}
