import moment from "moment";
import { useMemo, useState } from "react";
import { parseMomentUtcToLocal } from "../../../../helpers";
import DatePickerMaterial from "../../../../components/DatePickerMaterial";
import TimePickerMaterial from "../../../../components/TimePickerMaterial";
import InputLabel from "../../../../components/InputLabel";
import InputSwitch from "../../../../components/InputSwitch/InputSwitch";
import { Control, FieldErrors, FieldValues } from "react-hook-form";
import InputRadioWithValid from "../../../../components/Radio";

interface ILibraryElementDatesFormProps {
    selectedTrip: {
        startDate: string;
        endDate: string;
        [key: string]: any;
    };
    isHotel: boolean;
    isVisit?: string;
    control: Control<FieldValues, any>;
    errors: FieldErrors<FieldValues>;
}

const LibraryElementDatesForm = ({
    selectedTrip,
    isHotel,
    isVisit,
    control,
    errors,
}: ILibraryElementDatesFormProps) => {
    const [showEndHourInput, setShowEndHourInput] = useState<boolean>(false);

    const minEndDate = useMemo(() => {
        return moment
            .utc(selectedTrip?.startDate)
            .add(1, "day")
            .toDate() as any;
    }, [selectedTrip?.startDate]);

    const minStartDate = useMemo(() => {
        return parseMomentUtcToLocal(selectedTrip?.startDate).toDate() as any;
    }, [selectedTrip?.startDate]);

    const maxEndDate = useMemo(() => {
        return moment.utc(selectedTrip?.endDate).endOf("day").toDate() as any;
    }, [selectedTrip?.endDate]);

    const maxStartDate = useMemo(() => {
        return moment.utc(selectedTrip?.endDate).endOf("day").toDate() as any;
    }, [selectedTrip?.endDate]);

    const handleSwitchChange = (e: any) => {
        setShowEndHourInput(!!e.target.checked);
    };

    const showDateInputs = !isHotel || (isHotel && isVisit !== undefined);
    const isHotelStay = isHotel && isVisit === "false";

    return (
        <div>
            {isHotel && (
                <>
                    <div className="text-base font-base text-neutral-400 mb-6">
                        Choose which type of element would you like to create
                    </div>
                    <div className="flex gap-10 mb-6">
                        <InputRadioWithValid
                            control={control}
                            name="isVisit"
                            id="hotel"
                            label="Hotel Stay"
                            value="false"
                        />
                        <InputRadioWithValid
                            control={control}
                            name="isVisit"
                            id="visit"
                            label="Site inspection"
                            value="true"
                        />
                    </div>
                </>
            )}
            {showDateInputs && (
                <>
                    <div className="text-base font-base text-neutral-400">
                        Now choose dates for this element
                    </div>
                    <div className="grid grid-cols-6 gap-x-12 mb-6">
                        <div className="mt-6 col-span-6 lg:col-span-3">
                            <DatePickerMaterial
                                control={control}
                                minDate={minStartDate}
                                maxDate={maxStartDate}
                                name="startDate"
                                id="startDate"
                                label={isHotelStay ? "Check In" : "Start Date"}
                                error={errors["startDate"]?.message}
                            />
                        </div>

                        <div className="mt-6 col-span-6 lg:col-span-3">
                            {isHotelStay && (
                                <DatePickerMaterial
                                    control={control}
                                    minDate={minEndDate}
                                    maxDate={maxEndDate}
                                    name="endDate"
                                    id="endDate"
                                    label="Check Out"
                                    error={errors["endDate"]?.message}
                                />
                            )}
                        </div>
                        <div className="mt-6 col-span-6 lg:col-span-3">
                            <TimePickerMaterial
                                control={control}
                                label={
                                    isHotelStay ? "Check In Hour" : "Start Hour"
                                }
                                defaultValue={moment("09:00", "HH:mm") as any}
                                id="startHour"
                                name="startHour"
                                error={errors["startHour"]?.message}
                            />
                        </div>
                        {isHotelStay && (
                            <div className="mt-6 col-span-6 lg:col-span-3">
                                <TimePickerMaterial
                                    control={control}
                                    label="Check Out Hour"
                                    defaultValue={
                                        moment("10:00", "HH:mm") as any
                                    }
                                    id="endHour"
                                    name="endHour"
                                    error={errors["endHour"]?.message}
                                />
                            </div>
                        )}
                        {!isHotel && !isVisit && (
                            <div className="mt-6 col-span-6 lg:col-span-3">
                                <InputLabel
                                    inputId={"endHour"}
                                    label={"End Hour"}
                                    error={false}
                                />
                                <div className="flex flex-row gap-4 items-center">
                                    <InputSwitch
                                        id="showEndHour"
                                        name="showEndHour"
                                        onClick={handleSwitchChange}
                                    />
                                    {showEndHourInput && (
                                        <div className="flex-grow">
                                            <TimePickerMaterial
                                                control={control}
                                                defaultValue={
                                                    moment(
                                                        "10:00",
                                                        "HH:mm"
                                                    ) as any
                                                }
                                                id="endHour"
                                                name="endHour"
                                                error={
                                                    errors["endHour"]?.message
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default LibraryElementDatesForm;