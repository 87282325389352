import axios, { AxiosInstance, CancelTokenSource } from "axios";
import config from "../config";
import {
    AddHotelRequest,
    BACKEND_DATE_FORMAT,
    IRestaurantBody,
    ITripBody,
    ITripLocalPartner,
    PAGE_ROWS,
    PlanType,
    TIME_PERIOD,
    UserRoleOnOrganization,
    UserRoleOnTrip,
    UserStatusOnOrganization,
    UserStatusOnTrip,
} from "../constants";
import sanitize from "sanitize-filename";
import { getUriComponent } from "../helpers";
import { UserStatusEnum } from "../features/plans/constants";
import moment from "moment";
import { IChatPushPayload } from "../types/chat.type";

interface CommonHeaderProperties {
    Authorization?: string;
    [key: string]: any;
}

interface ICancelPlan {
    subscriptionId?: string;
    stripeSubscriptionId?: string;
}

class ApiService {
    api: AxiosInstance;
    private static singleton: ApiService;
    private authToken: string;

    constructor() {
        this.constroller = new AbortController();
        this.api = axios.create({
            baseURL: config.serverDomain,
            signal: this.constroller.signal,
        });
        this.authToken = "";
    }
    constroller: AbortController;
    static getInstance(): ApiService {
        if (this.singleton) {
            return this.singleton;
        } else {
            this.singleton = new ApiService();
            return this.singleton;
        }
    }

    setAuthToken(token: string) {
        this.authToken = token;
    }

    withAuthHeaders(newToken?: string): Object {
        return {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${newToken || this.authToken}`,
            },
        };
    }

    withHeaders(
        token: string | undefined,
        extraHeaders: object | undefined
    ): Object {
        let headers: CommonHeaderProperties = {
            ...extraHeaders,
        };
        if (token) headers["Authorization"] = `Bearer ${token}`;

        return {
            headers: headers,
        };
    }

    async getProfile(newToken?: string) {
        const data = await this.api.get(
            "/profile",
            this.withAuthHeaders(newToken)
        );
        return data;
    }

    async createProfile(fields: any) {
        const response = await this.api.post(
            `/users`,
            fields,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateProfile(fields: any) {
        const response = await this.api.put(
            "/profile",
            {
                fields: JSON.stringify(fields),
            },
            this.withAuthHeaders()
        );
        return response;
    }

    async updateProfileMessagingToken(token: any) {
        const response = await this.api.put(
            "/profile/messagingtokens",
            {
                token,
            },
            this.withAuthHeaders()
        );
        return response;
    }

    async updateProfileFormData(data: any) {
        const response = await this.api.put(
            "/profile",
            data,
            this.withHeaders(this.authToken, {
                "Content-Type": "multipart/form-data",
            })
        );
        return response;
    }

    async signUpEmailPassword(
        email: String,
        password: String,
        firstName: String,
        lastName: String
    ) {
        const data = await this.api.post("/auth/signup", {
            email,
            password,
            firstName,
            lastName,
        });
        return data;
    }

    async changePassword(password: string) {
        const response = await this.api.post(
            "/auth/password",
            { password },
            this.withAuthHeaders()
        );
        return response;
    }

    // Users

    async getUser(userId: number) {
        const data = await this.api.get(
            `/profile/${userId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getUserReviews(userId: number, take: number, skip: number) {
        const data = await this.api.get(
            `/users/${userId}/reviews?take=${take}&skip=${skip}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getUserSettings() {
        const data = await this.api.get(`/settings`, this.withAuthHeaders());
        return data;
    }
    async updateUserSettings(fields: any) {
        const data = await this.api.put(
            `/settings`,
            fields,
            this.withAuthHeaders()
        );
        return data;
    }

    async getTripUserReviews(
        tripId: number,
        userId: number,
        take: number,
        skip: number
    ) {
        const data = await this.api.get(
            `trips/${tripId}/users/${userId}/reviews?take=${take}&skip=${skip}`,
            this.withAuthHeaders()
        );
        return data;
    }

    // --- Trips
    async getTrips(take: number, skip: number, status?: any, userStatus?: any) {
        const data = await this.api.get(
            `/trips?take=${take}&skip=${skip}&status=${status}${
                userStatus ? "&userStatus=" + userStatus : ""
            }&date=${moment().format(BACKEND_DATE_FORMAT)}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getTrip(tripId: Number) {
        const data = await this.api.get(
            `/trips/${tripId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getTripDatesLimit(tripId: Number) {
        const data = await this.api.get(
            `/trips/${tripId}/activities-date-limit`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getPlans() {
        const data = await this.api.get(
            `/stripe/get-products`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getPaymentsByEmail(email: string) {
        const data = await this.api.get(
            `/stripe/payment-history-by-email?email=${email}&limit=100`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getAdminTransfers(
        take: number = 10,
        skip: number = 0,
        type: string = "",
        search: string = "",
        dateFrom: Date,
        dateTo: Date
    ) {
        const response = await this.api.get(
            `/stripe/payment-history-admin?take=${take}&skip=${skip}&type=${type}&search=${encodeURIComponent(
                search
            )}&dateFrom=${encodeURIComponent(
                dateFrom.toISOString()
            )}&dateTo=${encodeURIComponent(dateTo.toISOString())}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getUserPayment() {
        const data = await this.api.get(
            `/payments/user`,
            this.withAuthHeaders()
        );
        return data;
    }

    async cancelPaymentUser() {
        const data = await this.api.delete(
            `/payments/user`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getPlan(planId: Number) {
        const data = await this.api.get(
            `/plans/:${planId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getCurrentPlan() {
        const data = await this.api.get(
            `/plans/current-plan`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getPlanByOrganization(organizationId: Number) {
        const data = await this.api.get(
            `/plans/current-plan/organization/${organizationId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async cancelCurrentPlan(payload: ICancelPlan) {
        const data = await this.api.post(
            `/plans/current-plan`,
            payload,
            this.withAuthHeaders()
        );
        return data;
    }

    async createPlan(fields: any) {
        const data = await this.api.post(
            `/plans`,
            fields,
            this.withAuthHeaders()
        );
        return data;
    }

    async createPayment(fields: any) {
        const data = await this.api.post(
            `/payments`,
            fields,
            this.withAuthHeaders()
        );
        return data;
    }

    async getBrochure(tripId: Number) {
        const data = await this.api.get(`/trips/${tripId}/brochure`, {
            ...this.withAuthHeaders(),
            responseType: "arraybuffer",
        });
        const url = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/pdf" })
        );
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `brochure_${tripId}.pdf`);
        document.body.appendChild(link);
        link.click();
        return data;
    }

    async getTripUsersXlsx(tripId: Number) {
        const data = await this.api.get(`/trips/${tripId}/participants-xls`, {
            ...this.withAuthHeaders(),
            responseType: "arraybuffer",
        });
        const url = window.URL.createObjectURL(
            new Blob([data.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
        );
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `participants_${tripId}.xlsx`);
        document.body.appendChild(link);
        link.click();
        return data;
    }

    async getTripUserPDF(tripId: Number, userId: number, userName: string) {
        const data = await this.api.get(`/trips/${tripId}/user/${userId}/pdf`, {
            ...this.withAuthHeaders(),
            responseType: "arraybuffer",
        });
        const url = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/pdf" })
        );
        var link = document.createElement("a");
        link.href = url;
        let name = sanitize(userName).replace(/\s/g, "_");
        link.setAttribute("download", `${name}_${userId}_${tripId}.pdf`);
        document.body.appendChild(link);
        link.click();
        return data;
    }

    async getTripHome(tripId: Number) {
        const data = await this.api.get(
            `/trips/${tripId}/home`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getTripsCount() {
        const data = await this.api.get(`/trips/count`, this.withAuthHeaders());
        return data;
    }

    async getTripUserRequirements(
        tripId: number,
        userEmail: string,
        take: number,
        skip: number,
        search: string = "",
        status: string = ""
    ) {
        const data = await this.api.get(
            `/trips/${tripId}/users/${userEmail}/requirements?take=${take}&skip=${skip}&search=${search}&status=${status}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getTripUserProfile(tripId: number, userId: number) {
        const data = await this.api.get(
            `/trips/${tripId}/users/${userId}/profile`,
            this.withAuthHeaders()
        );
        return data;
    }

    async updateTripUserRequirement(
        tripId: number,
        requirementId: number,
        fileId: number,
        file: any
    ) {
        const data = await this.api.put(
            `/trips/${tripId}/requirements/${requirementId}/files/${fileId}`,
            file,
            this.withAuthHeaders()
        );
        return data;
    }

    async addRequirementFile(
        tripId: number,
        requirementId: number,
        files: Array<string>,
        description: string,
        userEmail?: string,
        sensitiveInformation?: boolean
    ) {
        const payload: any = {
            files,
            description,
            sensitiveInformation,
        };
        if (userEmail) {
            payload["userEmail"] = userEmail;
        }
        const response = await this.api.post(
            `/trips/${tripId}/requirements/${requirementId}`,
            payload,
            this.withAuthHeaders()
        );
        return response;
    }

    async getAvailablePlans(type: PlanType, role?: string) {
        const data = await this.api.get(
            `/plans?role=${role || ""}&type=${type || ""}`,
            this.withAuthHeaders()
        );
        return data;
    }

    //----NOTIFICATIONS
    async getTripNotifications(
        tripId: Number,
        take: number,
        skip: number,
        search: string = ""
    ) {
        const data = await this.api.get(
            `/trips/${tripId}/notifications?take=${take}&skip=${skip}&search=${search}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getUserNotifications(take: number, skip: number) {
        const data = await this.api.get(
            `/notifications?take=${take}&skip=${skip}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async addTrip(formData: ITripBody) {
        const data = await this.api.post(
            `/trips`,
            formData,
            this.withAuthHeaders()
        );
        return data;
    }

    async duplicateTrip(tripId: number, fields: object) {
        const data = await this.api.post(
            `/trips/${tripId}/duplicate`,
            fields,
            this.withAuthHeaders()
        );
        return data;
    }

    async removeTrip(tripId: number) {
        const data = await this.api.delete(
            `/trips/${tripId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getActivity(tripId: Number, activityId: Number) {
        const data = await this.api.get(
            `/trips/${tripId}/activities/${activityId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async removeActivity(tripId: Number, activityId: Number) {
        const data = await this.api.delete(
            `/trips/${tripId}/activities/${activityId}`,
            this.withAuthHeaders()
        );
        return data;
    }
    async getActivities(tripId: Number) {
        const data = await this.api.get(
            `/trips/${tripId}/activities/`,
            this.withAuthHeaders()
        );
        return data;
    }

    async addActivityToTrip(tripId: number, formData: any) {
        const response = await this.api.post(
            `/trips/${tripId}/activities`,
            formData,
            this.withAuthHeaders()
        );
        return response;
    }

    async duplicateTripActivity(
        tripId: number,
        activityId: number,
        formData: any
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/duplicate`,
            formData,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateActivity(tripId: number, activityId: number, formData: any) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}`,
            formData,
            this.withAuthHeaders()
        );
        return response;
    }

    async addActivityToLibrary(tripId: number, activityId: number) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/add-to-library`,
            {},
            this.withAuthHeaders()
        );
        return response;
    }

    // --- Trip files
    async addFileToTrip(tripId: number, formData: any) {
        const response = await this.api.post(
            `/trips/${tripId}/files`,
            formData,
            this.withHeaders(this.authToken, {
                "Content-Type": "multipart/form-data",
            })
        );
        return response;
    }

    async editTripFile(tripId: number, fileId: number, formData: any) {
        const response = await this.api.put(
            `/trips/${tripId}/files/${fileId}`,
            formData,
            this.withHeaders(this.authToken, {
                "Content-Type": "multipart/form-data",
            })
        );
        return response;
    }

    async addTempFile(
        formData: FormData,
        id?: any,
        onProgress?: any,
        cancelTokenSource?: CancelTokenSource
    ) {
        const config = {
            onUploadProgress: function (progressEvent: any) {
                const prog = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                onProgress && onProgress(id, prog);
            },
            cancelToken: cancelTokenSource?.token,
            ...this.withHeaders(this.authToken, {
                "Content-Type": "multipart/form-data",
            }),
        };
        const response = await this.api.post(`/temp-files`, formData, config);
        return response;
    }

    async getTripFile(tripId: number, fileId: number) {
        const response = await this.api.get(
            `/trips/${tripId}/files/${fileId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getTripFiles(
        tripId: string,
        take: number,
        skip: number,
        search: string = ""
    ) {
        const response = await this.api.get(
            `/trips/${tripId}/files?take=${take}&skip=${skip}&search=${search}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getTripRequirements(
        tripId: string,
        take: number,
        skip: number,
        search: string = ""
    ) {
        const response = await this.api.get(
            `/trips/${tripId}/requirements?take=${take}&skip=${skip}&search=${search}&withFiles=true`,
            this.withAuthHeaders()
        );
        return response;
    }

    async removeFileFromTrip(tripId: number, fileId: number) {
        const response = await this.api.delete(
            `/trips/${tripId}/files/${fileId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    // --- Trip Images

    async getTripImages(
        tripId: string,
        take: number,
        skip: number,
        search: string = ""
    ) {
        const response = await this.api.get(
            `/trips/${tripId}/images?take=${take}&skip=${skip}&search=${search}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async addImageToTrip(tripId: number, formData: any) {
        const response = await this.api.post(
            `/trips/${tripId}/images`,
            formData,
            this.withHeaders(this.authToken, {
                "Content-Type": "multipart/form-data",
            })
        );
        return response;
    }

    async addImageToChat(conversationId: string, formData: any) {
        const response = await this.api.post(
            `/chats/${conversationId}/images`,
            formData,
            this.withHeaders(this.authToken, {
                "Content-Type": "multipart/form-data",
            })
        );
        return response;
    }

    async editTripImageDescription(
        tripId: number,
        imageId: number,
        description: string
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/images/${imageId}`,
            { description },
            this.withAuthHeaders()
        );
        return response;
    }

    async removeImageFromTrip(tripId: number, filesId: Array<number>) {
        const response = await this.api.post(
            `/trips/${tripId}/images/delete`,
            {
                filesId,
            },
            this.withAuthHeaders()
        );
        return response;
    }

    async updateTrip(tripId: number, fields: object) {
        const data = await this.api.put(
            `/trips/${tripId}`,
            fields,
            this.withAuthHeaders()
        );
        return data;
    }

    // --- Hotels
    async getHotels(searchText: string) {
        const data = await this.api.get(
            `/hotels?search=${searchText}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getHotel(hotelId: number) {
        const data = await this.api.get(
            `/hotels/${hotelId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async addHotel(data: AddHotelRequest) {
        const {
            name,
            website,
            lat,
            lng,
            streetAddress,
            streetNumber,
            city,
            state,
            country,
            zipCode,
            phone,
            fullAddress,
        } = data;
        const response = await this.api.post(
            `/hotels`,
            {
                name,
                website,
                lat,
                lng,
                streetAddress,
                streetNumber,
                city,
                state,
                country,
                zipCode,
                phone,
                fullAddress,
            },
            this.withAuthHeaders()
        );
        return response;
    }

    async updateHotel(hotelId: number, fields: any) {
        const data = await this.api.put(
            `/hotels/${hotelId}`,
            fields,
            this.withAuthHeaders()
        );
        return data;
    }

    // --- Restaurants
    async getRestaurants(searchText: string) {
        const data = await this.api.get(
            `/restaurants?search=${searchText}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getRestaurant(restaurantId: number) {
        const data = await this.api.get(
            `/restaurants/${restaurantId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async addRestaurant(data: IRestaurantBody) {
        const response = await this.api.post(
            `/restaurants`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async addRestaurantContactInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        data: object
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/restaurant-insights/${insightId}/contacts`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateRestaurantContactInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        contactId: number,
        data: object
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}/restaurant-insights/${insightId}/contacts/${contactId}`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }
    async removeRestaurantContactInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        contactId: number
    ) {
        const response = await this.api.delete(
            `/trips/${tripId}/activities/${activityId}/restaurant-insights/${insightId}/contacts/${contactId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async removeRestaurantInsight(
        tripId: number,
        activityId: number,
        insightId: number
    ) {
        const response = await this.api.delete(
            `/trips/${tripId}/activities/${activityId}/restaurant-insights/${insightId}/hotel`,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateRestaurant(restaurantId: number, fields: any) {
        const data = await this.api.put(
            `/restaurants/${restaurantId}`,
            fields,
            this.withAuthHeaders()
        );
        return data;
    }

    // --- Trip Users
    async getTripMembers(
        tripId: number,
        take: number,
        skip: number,
        search: string = "",
        status: UserStatusOnTrip | "" | null | undefined,
        role: UserRoleOnTrip | "" | undefined
    ) {
        const data = await this.api.get(
            `/trips/${tripId}/users?take=${take}&skip=${skip}&search=${
                search || ""
            }&status=${status || ""}&role=${role || ""}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async addUsersToTrip(
        tripId: number,
        users: Array<{ email: string; role: UserRoleOnTrip }>
    ) {
        const data = await this.api.post(
            `/trips/${tripId}/users`,
            {
                users,
            },
            this.withAuthHeaders()
        );
        return data;
    }

    async updateOrganizationToTrip(tripId: number, organizationId: number) {
        const data = await this.api.post(
            `/trips/${tripId}/organization`,
            {
                organizationId,
            },
            this.withAuthHeaders()
        );
        return data;
    }

    async resendInvitationUserToTrip(tripId: number, email: string) {
        const data = await this.api.post(
            `/trips/${tripId}/users/resend-invite`,
            {
                email,
            },
            this.withAuthHeaders()
        );
        return data;
    }

    async updateUserOnTrip(tripId: number, email: string, fields: object) {
        const data = await this.api.put(
            `/trips/${tripId}/users`,
            {
                email,
                fields,
            },
            this.withAuthHeaders()
        );
        return data;
    }

    async removeUserFromTrip(tripId: number, email: string) {
        const data = await this.api.delete(
            `/trips/${tripId}/users/${email}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async changeTripOwner(tripId: number, email: string) {
        const data = await this.api.post(
            `/trips/${tripId}/users/${email}/owner`,
            {},
            this.withAuthHeaders()
        );
        return data;
    }

    // --- REQUIREMENTS
    async addRequirementToTrip(tripId: number, data: any) {
        const response = await this.api.post(
            `/trips/${tripId}/requirements`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateTripRequirement(
        tripId: number,
        requirementId: number,
        fields: any
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/requirements/${requirementId}`,
            fields,
            this.withAuthHeaders()
        );
        return response;
    }

    async removeTripRequirement(tripId: number, requirementId: number) {
        const response = await this.api.delete(
            `/trips/${tripId}/requirements/${requirementId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getTripRequirement(tripId: number, requirementId: number) {
        const response = await this.api.get(
            `/trips/${tripId}/requirements/${requirementId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getTripRequirementUsers(tripId: number, requirementId: number) {
        const response = await this.api.get(
            `/trips/${tripId}/requirements/${requirementId}/users`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getTripRequirementFiles(tripId: number, requirementId: number) {
        const response = await this.api.get(
            `/trips/${tripId}/requirements/${requirementId}/files`,
            this.withAuthHeaders()
        );
        return response;
    }
    // Trip Local Partners
    async addLocalPartnerToTrip(tripId: number, payload: ITripLocalPartner) {
        const response = await this.api.post(
            `/trips/${tripId}/local-partners`,
            payload,
            this.withAuthHeaders()
        );
        return response;
    }
    // --- ORGANIZATIONS

    async getOrganizations() {
        const data = await this.api.get(
            `/organizations`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getOrganizationMembers(
        organizationId: number,
        take: number,
        skip: number,
        search: string = "",
        status: UserStatusOnOrganization | "" | null | undefined,
        role: UserRoleOnOrganization | "" | null | undefined,
        canEdit: boolean = false
    ) {
        const data = await this.api.get(
            `/organizations/${organizationId}/users?take=${take}&skip=${skip}&search=${search}&status=${status}&role=${role}&canEdit=${canEdit}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getOrganization(organizationId: number) {
        const data = await this.api.get(
            `/organizations/${organizationId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async addOrganization(formData: any) {
        const response = await this.api.post(
            "/organizations",
            formData,
            this.withHeaders(this.authToken, {
                "Content-Type": "multipart/form-data",
            })
        );
        return response;
    }

    async getTripsByOrganization(organizationId: Number, filters: any) {
        const { search, status, skip, take, filterUserTrips } = filters;
        const data = await this.api.get(
            `/organizations/${organizationId}/trips?search=${search}&status=${status}&skip=${skip}&take=${take}${
                filterUserTrips ? "&filterUserTrips=true" : ""
            }`,
            this.withAuthHeaders()
        );
        return data;
    }

    async addUsersToOrganization(
        organizationId: number,
        users: Array<{ email: string; role: UserRoleOnOrganization }>
    ) {
        const data = await this.api.post(
            `/organizations/${organizationId}/users`,
            {
                users,
            },
            this.withAuthHeaders()
        );
        return data;
    }

    async resendInvitationUserToOrganization(
        organizationId: number,
        email: string
    ) {
        const data = await this.api.post(
            `/organizations/${organizationId}/users/resend-invite`,
            {
                email,
            },
            this.withAuthHeaders()
        );
        return data;
    }

    async resendInviteUserToOrganization(
        organizationId: number,
        email: string
    ) {
        const data = await this.api.post(
            `/organizations/${organizationId}/users/resend-invite`,
            {
                email,
            },
            this.withAuthHeaders()
        );
        return data;
    }

    async removeOrganization(organizationId: number) {
        const data = await this.api.delete(
            `/organizations/${organizationId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async removeUserFromOrganization(organizationId: number, email: string) {
        const data = await this.api.delete(
            `/organizations/${organizationId}/users/${email}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async updateUserOnOrganization(
        organizationId: number,
        email: string,
        fields: object
    ) {
        const data = await this.api.put(
            `/organizations/${organizationId}/users/${email}`,
            {
                fields,
            },
            this.withAuthHeaders()
        );
        return data;
    }

    async updateOrganization(organizationId: number, formData: any) {
        const data = await this.api.put(
            `/organizations/${organizationId}`,
            formData,
            this.withAuthHeaders()
        );
        return data;
    }

    async getOrganizationUserProfile(organizationId: number, userId: number) {
        const data = await this.api.get(
            `/organizations/${organizationId}/users/${userId}/profile`,
            this.withAuthHeaders()
        );
        return data;
    }

    // --- Library

    async getLibraryElements(
        organizationId: number,
        activityType: string,
        take: number,
        skip: number,
        search: string = "",
        hotelType: string = ""
    ) {
        const data = await this.api.get(
            `organizations/${organizationId}/library?take=${take}&skip=${skip}&search=${search}&type=${activityType}${
                hotelType ? "&hotelType=" + hotelType : ""
            }`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getLibraryElement(organizationId: number, elementId: number) {
        const data = await this.api.get(
            `organizations/${organizationId}/library/${elementId}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async addLibraryElement(organizationId: number, data: any) {
        const response = await this.api.post(
            `/organizations/${organizationId}/library`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateLibraryElement(
        organizationId: number,
        elementId: number,
        data: any
    ) {
        const response = await this.api.put(
            `/organizations/${organizationId}/library/${elementId}`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async removeLibraryElement(organizationId: number, elementId: number) {
        const response = await this.api.delete(
            `/organizations/${organizationId}/library/${elementId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async duplicateLibraryElement(organizationId: number, elementId: number) {
        const response = await this.api.post(
            `/organizations/${organizationId}/library/${elementId}/duplicate`,
            {},
            this.withAuthHeaders()
        );
        return response;
    }

    // --- Profile
    async updateUserType(formData: object) {
        const data = await this.api.put(
            `/profile/user-type`,
            formData,
            this.withAuthHeaders()
        );
        return data;
    }

    async updateUserProfile(formData: object) {
        const data = await this.api.put(
            `/user-profiles`,
            formData,
            this.withAuthHeaders()
        );
        return data;
    }

    async getUserProfile() {
        const data = await this.api.get(
            `/user-profiles`,
            this.withAuthHeaders()
        );
        return data;
    }

    // --- Others
    async addOther(formData: any) {
        const response = await this.api.post(
            "/others",
            formData,
            this.withHeaders(this.authToken, {
                "Content-Type": "multipart/form-data",
            })
        );
        return response;
    }

    async updateOther(otherId: number, fields: any) {
        const response = await this.api.put(
            "/others/" + otherId,
            fields,
            this.withAuthHeaders()
        );
        return response;
    }

    async getOthers(text: string) {
        const response = await this.api.get(
            `/others?text=${text}`,
            this.withAuthHeaders()
        );
        return response;
    }

    // --- Local Partners
    async addLocalPartner(formData: any) {
        const response = await this.api.post(
            "/local-partners",
            formData,
            this.withAuthHeaders()
        );
        return response;
    }

    async getLocalPartners(text: string) {
        const response = await this.api.get(
            `/local-partners?text=${text}`,
            this.withAuthHeaders()
        );
        return response;
    }

    // --- Hotel Groups
    async addHotelGroup(formData: any) {
        const response = await this.api.post(
            "/hotel-groups",
            formData,
            this.withAuthHeaders()
        );
        return response;
    }

    async getHotelGroups(text: string) {
        const response = await this.api.get(
            `/hotel-groups?text=${text}`,
            this.withAuthHeaders()
        );
        return response;
    }

    // --- Host Agencies
    async addHostAgency(formData: any) {
        const response = await this.api.post(
            "/host-agencies",
            formData,
            this.withAuthHeaders()
        );
        return response;
    }

    async getHostAgencies(text: string) {
        const response = await this.api.get(
            `/host-agencies?text=${text}`,
            this.withAuthHeaders()
        );
        return response;
    }
    async getTripCustomInvite(tripId: number | string) {
        const response = await this.api.get(
            `/trips/${tripId}/invites`,
            this.withAuthHeaders()
        );
        return response;
    }

    async addTripCustomInvite(tripId: number | string, formData: any) {
        const response = await this.api.post(
            `/trips/${tripId}/invites`,
            formData,
            this.withAuthHeaders()
        );
        return response;
    }
    async updateTripCustomInvite(tripId: number | string, formData: any) {
        const response = await this.api.put(
            `/trips/${tripId}/invites`,
            formData,
            this.withAuthHeaders()
        );
        return response;
    }
    async deleteTripCustomInvite(tripId: number | string) {
        const response = await this.api.delete(
            `/trips/${tripId}/invites`,
            this.withAuthHeaders()
        );
        return response;
    }

    // --- Pending Actions
    async getPendingActions(take?: number, skip?: number) {
        const data = await this.api.get(
            `/trips/requirements/actions?take=${take}&skip=${skip}`,
            this.withAuthHeaders()
        );
        return data;
    }

    // --- Insights
    async getActivityInsight(tripId: number, activityId: number) {
        const response = await this.api.get(
            `/trips/${tripId}/activities/${activityId}/insights`,
            this.withAuthHeaders()
        );
        return response;
    }

    async addActivityOtherInsight(
        tripId: number,
        activityId: number,
        data: object
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/insights/other`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateActivityOtherInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        data: object
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/other`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async addActivityHotelInsight(
        tripId: number,
        activityId: number,
        data: object
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/insights/hotel`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateActivityHotelInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        data: object
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/hotel`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async addActivityRestaurantInsight(
        tripId: number,
        activityId: number,
        data: object
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/insights/restaurant`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateActivityRestaurantInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        data: object
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/restaurant`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async syncActivityHotelInsight(
        tripId: number,
        activityId: number,
        data: object
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/insights/hotel/sync`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async addHotelRoomInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        data: object
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/rooms`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateHotelRoomInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        roomId: number,
        data: object
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/rooms/${roomId}`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }
    async removeHotelRoomInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        roomId: number
    ) {
        const response = await this.api.delete(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/rooms/${roomId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async addHotelDinningInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        data: object
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/dinnings`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateHotelDinningInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        dinningId: number,
        data: object
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/dinnings/${dinningId}`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }
    async removeHotelDinningInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        dinningId: number
    ) {
        const response = await this.api.delete(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/dinnings/${dinningId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async addHotelWellnessInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        data: object
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/wellness`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateHotelWellnessInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        wellnessId: number,
        data: object
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/wellness/${wellnessId}`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }
    async removeHotelWellnessInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        wellnessId: number
    ) {
        const response = await this.api.delete(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/wellness/${wellnessId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async addHotelCommonInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        data: object
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/commons`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateHotelCommonInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        commonId: number,
        data: object
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/commons/${commonId}`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }
    async removeHotelCommonInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        commonId: number
    ) {
        const response = await this.api.delete(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/commons/${commonId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async addHotelContactInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        data: object
    ) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/contacts`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateHotelContactInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        contactId: number,
        data: object
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/contacts/${contactId}`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }
    async removeHotelContactInsight(
        tripId: number,
        activityId: number,
        insightId: number,
        contactId: number
    ) {
        const response = await this.api.delete(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/contacts/${contactId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async removeHotelInsight(
        tripId: number,
        activityId: number,
        insightId: number
    ) {
        const response = await this.api.delete(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/hotel`,
            this.withAuthHeaders()
        );
        return response;
    }

    async removeOtherInsight(
        tripId: number,
        activityId: number,
        insightId: number
    ) {
        const response = await this.api.delete(
            `/trips/${tripId}/activities/${activityId}/insights/${insightId}/other`,
            this.withAuthHeaders()
        );
        return response;
    }
    // --- Insights
    async shareInsight(insightId: number, emails: string[]) {
        const response = await this.api.post(
            "/insights/shared",
            { insightId, emails },
            this.withAuthHeaders()
        );
        return response;
    }

    async deleteSharedInsight(sharedinsightId: number) {
        const response = await this.api.delete(
            `/insights/shared/${sharedinsightId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateSharedInsight(otherId: number, formData: any) {
        const response = await this.api.put(
            "/others/" + otherId,
            formData,
            this.withHeaders(this.authToken, {
                "Content-Type": "multipart/form-data",
            })
        );
        return response;
    }

    async getInsights(
        take: number = 10,
        skip: number = 0,
        type: string = "",
        search: string = "",
        startDate: Date,
        endDate: Date
    ) {
        const response = await this.api.get(
            `/insights/me?take=${take}&skip=${skip}&type=${type}&search=${encodeURIComponent(
                search
            )}&startDate=${encodeURIComponent(
                startDate.toISOString()
            )}&endDate=${encodeURIComponent(endDate.toISOString())}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getUserInsights(text: string) {
        const response = await this.api.get(
            `/others?text=${text}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getAdminTripsList(
        search: string = "",
        dateFrom: Date,
        dateTo: Date,
        take: number = PAGE_ROWS,
        skip: number = 0
    ) {
        const response = await this.api.get(
            `/admin/trips?take=${take}&skip=${skip}&search=${search}&dateTo=${dateTo.toISOString()}&dateFrom=${dateFrom.toISOString()}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getSharedInsights(
        take: number = 10,
        skip: number = 0,
        type: string = "",
        search: string = "",
        startDate: Date,
        endDate: Date
    ) {
        const response = await this.api.get(
            `/insights/shared?take=${take}&skip=${skip}&type=${type}&search=${encodeURIComponent(
                search
            )}&startDate=${encodeURIComponent(
                startDate.toISOString()
            )}&endDate=${encodeURIComponent(endDate.toISOString())}`,
            this.withAuthHeaders()
        );
        return response;
    }
    async getAdminUsersList(
        search: string = "",
        dateFrom: Date,
        dateTo: Date,
        take: number = PAGE_ROWS,
        skip: number = 0
    ) {
        const response = await this.api.get(
            `/admin/users?take=${take}&skip=${skip}&search=${search}&dateTo=${dateTo.toISOString()}&dateFrom=${dateFrom.toISOString()}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getInsightPdf(insightId: Number) {
        const data = await this.api.get(`/insights/${insightId}/pdf`, {
            ...this.withAuthHeaders(),
            responseType: "arraybuffer",
        });
        const url = window.URL.createObjectURL(
            new Blob([data.data], { type: "application/pdf" })
        );
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `insight_${insightId}.pdf`);
        document.body.appendChild(link);
        link.click();
        return data;
    }

    async getAdminInsights(
        take: number = 10,
        skip: number = 0,
        type: string = "",
        search: string = "",
        dateFrom: Date,
        dateTo: Date
    ) {
        const response = await this.api.get(
            `/admin/insights?take=${take}&skip=${skip}&type=${type}&search=${encodeURIComponent(
                search
            )}&dateFrom=${encodeURIComponent(
                dateFrom.toISOString()
            )}&dateTo=${encodeURIComponent(dateTo.toISOString())}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async newComplaint(complaint: any) {
        const response = await this.api.post(
            `/complaints`,
            complaint,
            this.withAuthHeaders()
        );
        return response;
    }

    async getComplaints(
        take: number = 10,
        skip: number = 0,
        status: string = "",
        search: string = "",
        dateFrom: Date,
        dateTo: Date,
        itemType: string = ""
    ) {
        const response = await this.api.get(
            `/complaints?take=${take}&skip=${skip}&status=${status}&itemType=${itemType}&search=${encodeURIComponent(
                search
            )}&dateFrom=${encodeURIComponent(
                dateFrom.toISOString()
            )}&dateTo=${encodeURIComponent(dateTo.toISOString())}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async deleteComplaintAdmin(complaintId: number) {
        const response = await this.api.delete(
            `/complaints/${complaintId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateComplaint(complaint: any) {
        const response = await this.api.put(
            `/complaints/${complaint.id}/status`,
            complaint,
            this.withAuthHeaders()
        );
        return response;
    }

    async getComplaintItem(complaint: any) {
        const response = await this.api.get(
            `/complaints/${complaint.id}/item`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getAdminReviews(
        take: number = 10,
        skip: number = 0,
        type: string = "",
        search: string = "",
        dateFrom: Date,
        dateTo: Date
    ) {
        const response = await this.api.get(
            `/admin/reviews?take=${take}&skip=${skip}&type=${type}&search=${encodeURIComponent(
                search
            )}&dateFrom=${encodeURIComponent(
                dateFrom.toISOString()
            )}&dateTo=${encodeURIComponent(dateTo.toISOString())}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getAdminHotels(
        take: number = 10,
        skip: number = 0,
        search: string = "",
        dateFrom?: Date,
        dateTo?: Date
    ) {
        const response = await this.api.get(
            `/admin/hotels?take=${take}&skip=${skip}&search=${encodeURIComponent(
                search
            )}&dateFrom=${getUriComponent(
                dateFrom?.toISOString()
            )}&dateTo=${getUriComponent(dateTo?.toISOString())}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async getAdminOthers(
        take: number = 10,
        skip: number = 0,
        search: string = "",
        dateFrom?: Date,
        dateTo?: Date
    ) {
        const response = await this.api.get(
            `/admin/others?take=${take}&skip=${skip}&search=${encodeURIComponent(
                search
            )}&dateFrom=${getUriComponent(
                dateFrom?.toISOString()
            )}&dateTo=${getUriComponent(dateTo?.toISOString())}`,
            this.withAuthHeaders()
        );
        return response;
    }
    async getAdminRestaurants(
        take: number = 10,
        skip: number = 0,
        search: string = "",
        dateFrom?: Date,
        dateTo?: Date
    ) {
        const response = await this.api.get(
            `/admin/restaurants?take=${take}&skip=${skip}&search=${encodeURIComponent(
                search
            )}&dateFrom=${getUriComponent(
                dateFrom?.toISOString()
            )}&dateTo=${getUriComponent(dateTo?.toISOString())}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async updateReviewAdmin(review: any) {
        const response = await this.api.put(
            `/admin/reviews/${review.id}`,
            review,
            this.withAuthHeaders()
        );
        return response;
    }

    async deleteReviewAdmin(reviewId: number) {
        const response = await this.api.delete(
            `/admin/reviews/${reviewId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    // --- Reviews
    async getActivityReview(tripId: number, activityId: number) {
        const response = await this.api.get(
            `/trips/${tripId}/activities/${activityId}/reviews`,
            this.withAuthHeaders()
        );
        return response;
    }

    async addActivityReview(tripId: number, activityId: number, data: object) {
        const response = await this.api.post(
            `/trips/${tripId}/activities/${activityId}/reviews`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }
    async updateTripActivityReview(
        tripId: number,
        activityId: number,
        reviewId: number,
        data: object
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/activities/${activityId}/reviews/${reviewId}`,
            data,
            this.withAuthHeaders()
        );
        return response;
    }

    async getAdminOverview(period: TIME_PERIOD = TIME_PERIOD.DAY) {
        const response = await this.api.get(
            `/admin/overview?period=${period}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async changeUserRole(userId: number, role: string) {
        const response = await this.api.post(
            `/admin/users/role`,
            { userId: userId, role: role },
            this.withAuthHeaders()
        );
        return response;
    }

    async getUserAdminToken(userEmail: string) {
        const response = await this.api.post(
            `/admin/users/token`,
            { userEmail },
            this.withAuthHeaders()
        );
        return response;
    }

    async changeUserStatus(userEmail: string, status: UserStatusEnum) {
        const response = await this.api.post(
            `/admin/users/status`,
            { userEmail, status },
            this.withAuthHeaders()
        );
        return response;
    }

    async deleteHotel(hotelId: number) {
        const response = await this.api.delete(
            `/admin/hotels/${hotelId}`,
            this.withAuthHeaders()
        );
        return response;
    }
    async deleteRestaurant(restaurantId: number) {
        const response = await this.api.delete(
            `/admin/restaurants/${restaurantId}`,
            this.withAuthHeaders()
        );
        return response;
    }
    async deleteOther(otherd: number) {
        const response = await this.api.delete(
            `/admin/others/${otherd}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async replaceHotel(oldHotelId: number, newHotelId: number) {
        const response = await this.api.post(
            `/admin/hotels/replace`,
            { oldHotelId, newHotelId },
            this.withAuthHeaders()
        );
        return response;
    }
    async replaceRestaurant(oldRestaurantId: number, newRestaurantId: number) {
        const response = await this.api.post(
            `/admin/restaurants/replace`,
            { oldRestaurantId, newRestaurantId },
            this.withAuthHeaders()
        );
        return response;
    }
    async replaceOther(oldOtherId: number, newOtherId: number) {
        const response = await this.api.post(
            `/admin/others/replace`,
            { oldOtherId, newOtherId },
            this.withAuthHeaders()
        );
        return response;
    }

    async updateUserData(userId: number, fields: any) {
        const response = await this.api.put(
            `admin/users/${userId}`,
            fields,
            this.withHeaders(this.authToken, {
                "Content-Type": "multipart/form-data",
            })
        );
        return response;
    }
    async updateUserProfileData(userId: number, formData: any) {
        const data = await this.api.put(
            `/admin/users/${userId}/profile`,
            formData,
            this.withAuthHeaders()
        );
        return data;
    }

    async getTripMessages(
        tripId: number,
        search: string,
        take: number = 10,
        skip: number = 0
    ) {
        const response = await this.api.get(`/messages/${tripId}`, {
            params: { search, take, skip },
            ...this.withAuthHeaders(),
        });
        return response;
    }

    async createTripMessage(tripId: number, message: string) {
        const response = await this.api.post(
            `/trips/${tripId}/messages`,
            { message },
            this.withAuthHeaders()
        );
        return response;
    }

    async updateTripMessage(
        tripId: number,
        messageId: number,
        message: string
    ) {
        const response = await this.api.put(
            `/trips/${tripId}/messages/${messageId}`,
            { message },
            this.withAuthHeaders()
        );
        return response;
    }

    async deleteTripMessage(tripId: number, messageId: number) {
        const response = await this.api.delete(
            `/trips/${tripId}/messages/${messageId}`,
            this.withAuthHeaders()
        );
        return response;
    }

    async sendMessageToAllTripUsers(tripId: any, _messageData: any) {
        const data = await this.api.post(
            `messages/${tripId}`,
            _messageData,
            this.withAuthHeaders()
        );
        return data;
    }
    async getConstants() {
        const data = await this.api.get(`constants`, this.withAuthHeaders());
        return data;
    }

    async addSubscriptionToUserForAdmin(userId: number, priceId: string) {
        const response = await this.api.post(
            `/stripe/create-customer-subscription`,
            { userId, priceId },
            this.withAuthHeaders()
        );
        return response;
    }

    async addSubscriptionToUser(planId: number) {
        const response = await this.api.post(
            `/stripe/create-subscription-to-user`,
            { planId },
            this.withAuthHeaders()
        );
        return response;
    }

    async addSubscriptionToOrganization(
        planId: number,
        organizationId: number
    ) {
        const response = await this.api.post(
            `/stripe/create-subscription-to-organization`,
            { planId, organizationId },
            this.withAuthHeaders()
        );
        return response;
    }

    async extendSubscriptionToUser(
        subscriptionId: number,
        days: number,
        userId: number
    ) {
        const response = await this.api.post(
            `/stripe/extend-customer-subscription`,
            { subscriptionId, days, userId },
            this.withAuthHeaders()
        );
        return response;
    }

    async newUseRequest(request: any) {
        const data = await this.api.post(
            `/complaints`,
            request,
            this.withAuthHeaders()
        );
        return data;
    }

    async userEmailExist(email: string) {
        const data = await this.api.get(
            `/users/exist?email=${encodeURIComponent(email)}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async removeAccountRequest() {
        const data = await this.api.get(
            `/auth/remove/me`,
            this.withAuthHeaders()
        );
        return data;
    }
    async removeAccount(token: string) {
        const data = await this.api.post(
            `/auth/remove/me`,
            {},
            this.withAuthHeaders(token)
        );
        return data;
    }

    async requestForAdmin(url: string, method: string, payload?: any) {
        switch (method) {
            case "GET":
                return await this.api.get(url, this.withAuthHeaders());
            case "POST":
                return await this.api.post(
                    url,
                    payload,
                    this.withAuthHeaders()
                );
            case "PUT":
                return await this.api.put(url, payload, this.withAuthHeaders());
            case "DELETE":
                return await this.api.delete(url, this.withAuthHeaders());
        }
    }

    async getAllPlans() {
        const data = await this.api.get(`/plans/all`, this.withAuthHeaders());
        return data;
    }
    async savePlanPermissions(planId: number, permissions: any[]) {
        const data = await this.api.post(
            `/plans/${planId}/permissions`,
            { permissions },
            this.withAuthHeaders()
        );
        return data;
    }

    async getContacts(
        take: number = 10,
        skip: number = 0,
        search: string = "",
        omit: string[] = []
    ) {
        const data = await this.api.get(
            `users/contacts?take=${take}&skip=${skip}&search=${search}`,
            this.withAuthHeaders()
        );
        return data;
    }

    async getUsersContacts(
        search: string = "",
        take: number = PAGE_ROWS,
        skip: number = 0,
        cancelToken?: CancelTokenSource
    ) {
        const response = await this.api.get(
            `/users/contacts?take=${take}&skip=${skip}&search=${search}`,
            { cancelToken: cancelToken?.token, ...this.withAuthHeaders() }
        );
        return response;
    }

    async getTripsUsers(tripId: number = 0) {
        const response = await this.api.get(
            `/trips/${tripId}/users/chats`,
            this.withAuthHeaders()
        );
        return response;
    }

    async copyImageToTrip(tripId: number, chatId: string, attachments: any) {
        const response = await this.api.post(
            `/chats/copy-images`,
            { tripId, chatId, attachments },
            this.withAuthHeaders()
        );
        return response;
    }

    async sendChatPushNotification(payload: IChatPushPayload) {
        const response = await this.api.post(
            `/chats/send-push-notification`,
            payload,
            this.withAuthHeaders()
        );
        return response;
    }
}

export default ApiService.getInstance();
