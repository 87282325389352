import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../../app/hooks";
import Avatar from "../../../../../components/Avatar";
import { PAGE_SECTIONS } from "../../../../../constants";
import { setCurrentSection } from "../../../../layout/layoutSlice";


export default function NonRegisteredUser() {
    let { userEmail } = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_PARTICIPANTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex flex-col">
            <div className="flex items-center mb-6">
                <div className="mr-6">
                    <Avatar className="w-24 h-24" />
                </div>
                <div className="flex flex-col text-left text-neutral-400">
                    <span className="text-lg font-semibold">
                        {userEmail}
                    </span>
                    <span className="text-base">User not registered</span>
                </div>
            </div>
        </div>
    );
}
