import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import DatePickerMaterial from "../../../components/DatePickerMaterial/DatePickerMaterial";
import InputSearch from "../../../components/InputSearch";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import { PAGE_ROWS } from "../../../constants";
import { TripsDotMenuKeys } from "../../../constants/admin";
import TripFormComponent from "../../trips/TripForm/TripFormComponent";
import {
    deleteTrip,
    getAdminTrips,
    selectAdminTrips,
    updateTrip,
} from "../adminSlice";
import { parseMomentUtc } from "../../../helpers";
import { TripsTable } from "./components/TripsTable";
import TripUpdateDatesProvider from "../../trips/TripForm/TripUpdateDatesContext";

export default function AllTrips(props: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [newPage, setNewPage] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    const trips = useAppSelector(selectAdminTrips);
    const [selectedTrip, setSelectedTrip] = useState<any>(null);
    const [tripToDelete, setTripToDelete] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(
        moment().subtract(9, "months").startOf("day").toDate()
    );
    const [endDate, setEndDate] = useState<any>(moment().add(9, "months").endOf("day").toDate());

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, search, startDate, endDate]);

    const getData = () => {
        dispatch(
            getAdminTrips(
                search,
                parseMomentUtc(startDate).startOf("day").toDate(),
                parseMomentUtc(endDate).endOf("day").toDate(),
                PAGE_ROWS,
                newPage?.skip
            )
        );
    };

    const resetPage = () => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleSearchChange = (query: string) => {
        resetPage();
        setSearch(query);
    };

    const handleStartDateChange = (value: moment.Moment) => {
        resetPage();
        setStartDate(value.startOf("day").toDate());
    };
    const handleEndDateChange = (value: moment.Moment) => {
        resetPage();
        setEndDate(value.startOf("day").toDate());
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };
    const handleClickDotsMenu = (key: string, trip: any) => {
        switch (key) {
            case TripsDotMenuKeys.edit:
                setSelectedTrip(trip);
                break;
            case TripsDotMenuKeys.delete:
                setTripToDelete(trip);
                break;
            case TripsDotMenuKeys.view:
                navigate(`/trips/${trip?.id}`);
                break;
        }
    };

    const handleDeleteTrip = async () => {
        try {
            await dispatch(deleteTrip(tripToDelete?.id));
            setTripToDelete(null);
        } catch (error) {}
    };

    const handleSubmitTrip = async (data: any) => {
        try {
            await dispatch(updateTrip({ ...data, id: selectedTrip.id }));
            setSelectedTrip(null);
        } catch (error) {}
    };

    return !selectedTrip ? (
        <>
            <ModalConfirmation
                open={!!tripToDelete}
                title="Are you sure?"
                description={`You are about to remove the trip ${tripToDelete?.title}`}
                handleCancel={() => {
                    setTripToDelete(null);
                }}
                handleSubmit={handleDeleteTrip}
            />
            <div className="text-neutral-900 text-left font-semibold mb-6">
                Trips List
            </div>
            <div className="grid grid-cols-12 md:items-end gap-5 md:gap-10">
                <div className="col-span-12 md:col-span-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>

                <div className="col-span-6 md:col-span-3">
                    <DatePickerMaterial
                        name="startDate"
                        value={startDate}
                        id="startDate"
                        label="From date"
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className="col-span-6 md:col-span-3">
                    <DatePickerMaterial
                        name="endDate"
                        value={endDate}
                        id="endDate"
                        label="To date"
                        onChange={handleEndDateChange}
                    />
                </div>
            </div>
            <TripsTable
                trips={trips}
                handleClickDotsMenu={handleClickDotsMenu}
                handlePaginatorChange={handlePaginatorChange}
            />

            {!trips?.isLoading && !trips?.data.length && (
                <div className="text-neutral-400 text-sm py-6">
                    There are no trips to show.
                </div>
            )}
        </>
    ) : (
        <TripUpdateDatesProvider>
            <TripFormComponent
                trip={selectedTrip}
                onSubmit={handleSubmitTrip}
                onCancel={() => setSelectedTrip(null)}
            />
        </TripUpdateDatesProvider>
    );
}
