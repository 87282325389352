import { FC } from "react";
import Icon from "../../../assets/svg/Icon";

interface FilePreviewProps {
    files: File[];
    removeFile: (index: number) => void;
}

const FilePreview: FC<FilePreviewProps> = ({ files, removeFile }) => (
    <div className="flex space-x-1 h-16 items-center overflow-x-auto gap-d p-2">
        {files.map((file, index) => (
            <div key={index} className="relative h-full w-16">
                <img
                    src={URL.createObjectURL(file)}
                    alt={`file preview ${index}`}
                    className="object-cover h-full w-full"
                />
                <button
                    type="button"
                    onClick={() => removeFile(index)}
                    className="absolute top-0 right-0 bg-gray-200 rounded-full p-1 transform translate-x-1/2 -translate-y-1/2"
                >
                    <Icon icon="CloseIcon" className="w-3 h-3 text-gray-500" />
                </button>
            </div>
        ))}
    </div>
);

export default FilePreview;
