import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getLinkFromNotification } from "../../bussiness/notifications";
import { PAGE_ROWS, PAGE_SECTIONS } from "../../constants";
import {
    NOTIFICATION_CTA_TYPES,
} from "../../constants/notifications";
import { useAuthStateContext } from "../../context/Auth";
import { setCurrentSection } from "../layout/layoutSlice";
import NotificationsList from "./NotificationList";
import {
    getUserNotifications,
    handleCTAButtons,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    selectNotifications,
    selectNotificationsIsLoading,
    selectNotificationsPagination,
    setNotifications,
} from "./notificationsSlice";

const UserNotificationsList = (props: any) => {
    const dispatch = useAppDispatch();
    const { user } = useAuthStateContext();
    const userNotifications = useAppSelector(selectNotifications);
    const isLoading = useAppSelector(selectNotificationsIsLoading);
    const notificationsPageCounter = useAppSelector(
        selectNotificationsPagination
    );
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.NOTIFICATIONS_USER,
            })
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            dispatch(
                setNotifications({
                    notifications: {
                        data: [],
                        count: 0,
                        currentPage: 0,
                        isLoading: false,
                        skip: 0,
                        take: 0,
                        totalPages: 0,
                    },
                })
            );
        };
    }, []);

    const fetchNotifications = async () => {
        await dispatch(
            getUserNotifications(PAGE_ROWS, userNotifications.length)
        );
    };

    const handleClickAccept = (notification: any) => {
        if (user?.email)
            dispatch(
                handleCTAButtons(
                    user?.email,
                    notification,
                    NOTIFICATION_CTA_TYPES.ACCEPT
                )
            );
        return;
    };

    const handleClickDecline = (notification: any) => {
        if (user?.email)
            dispatch(
                handleCTAButtons(
                    user?.email,
                    notification,
                    NOTIFICATION_CTA_TYPES.DECLINE
                )
            );
        return;
    };

    const handleClickNotification = (notification: any) => {
        dispatch(markNotificationAsRead(notification.id));
        const to = getLinkFromNotification(notification);
        navigate(to);
    };

    const markAllAsRead = (e: any) => {
        if (user?.email) dispatch(markAllNotificationsAsRead(user?.email));
    };
    return (
        <div>
            <div className="flex w-full justify-between items-start mb-5">
                <h3 className="text-left text-lg font-semibold">
                    Notifications
                </h3>
                <div
                    className="cursor-pointer hover:text-orange-600"
                    onClick={markAllAsRead}
                >
                    Mark all as read
                </div>
            </div>

            <NotificationsList
                isLoading={isLoading}
                userNotifications={userNotifications}
                fetchNotifications={fetchNotifications}
                handleClickNotification={handleClickNotification}
                handleClickAccept={handleClickAccept}
                handleClickDecline={handleClickDecline}
                notificationsPageCounter={notificationsPageCounter}
            />
                
        </div>
    );
};

export default UserNotificationsList;
