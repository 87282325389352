import { useEffect, useState } from "react";

export default function  FlagComponent(props: any) {
    const [country, setCountry] = useState<string>("US");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        setCountry(props?.country || 'US')
    }, [props]);
    return (
        <div className="PhoneInputCountryIconImg">
            <img src={props.flagUrl?.replace("{XX}", country)} alt="" />
        </div>
    );
}
