import { useMemo } from "react";
import Icon from "../../../assets/svg/Icon";
import DotsMenu from "../../../components/DotsMenu";
import Paginator from "../../../components/Paginator";

import Table from "../../../components/Table";
import { ActivityIcon, PAGE_ROWS } from "../../../constants";
import { ReviewsDotMenuOptions } from "../../../constants/admin";
import { normalizeReviews } from "../../../helpers";
interface IAllReviews {
    reviews: any;
    handleClickDotsMenu: Function;
    handlePaginatorChange: Function;
}
const AllReviewsTable = (props: IAllReviews) => {
    const { reviews, handleClickDotsMenu, handlePaginatorChange } = props;
    const tableHeaders = ["Element", "Date", "Location", "User", ""];



    const reviewsNormalized = useMemo(() => {
        return (reviews?.data || [])?.map((review: any) => {
            return { ...review, ...normalizeReviews(review) };
        });
    }, [reviews?.data]);

    return (
        <>
            <Table headers={tableHeaders} isLoading={reviews?.isLoading}>
                {reviewsNormalized?.map((review: any) => (
                    <tr key={review?.id}>
                        <td
                            className="text-left flex flex-row"
                            title={review?.title}
                        >
                            {review?.type && (
                                <Icon
                                    className="w-[24px] mr-4 text-orange-600"
                                    icon={
                                        ActivityIcon[
                                            review?.type as keyof typeof ActivityIcon
                                        ]
                                    }
                                />
                            )}
                            <span className="max-w-[200px] truncate">
                                {review?.title}
                            </span>
                        </td>
                        <td className="text-left ">{review?.formattedDate}</td>
                        <td
                            className="text-left max-w-[200px] truncate"
                            title={review?.location}
                        >
                            {review?.location}
                        </td>
                        <td
                            className="text-left max-w-[200px] truncate"
                            title={review?.user}
                        >
                            {review?.user}
                        </td>
                        <td className="text-left">
                            <DotsMenu
                                options={ReviewsDotMenuOptions}
                                handleClickMenu={(k: any) => {
                                    handleClickDotsMenu(k, review);
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </Table>
            {reviews?.count > PAGE_ROWS && (
                <div className="self-end text-xs z-20 mt-4">
                    <Paginator
                        count={reviews?.count}
                        take={PAGE_ROWS}
                        onChange={handlePaginatorChange}
                        totalPages={reviews?.totalPages}
                        currentPage={reviews?.currentPage}
                    />
                </div>
            )}
        </>
    );
};

export default AllReviewsTable;
