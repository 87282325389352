import React, { useEffect, useState } from "react";
import server from "../../api/server";
import AutocompleteInput from "../Autocomplete/AutocompleteInput";
import ResultsList from "../HotelsAutocomplete/ResultsList";

const OthersAutocomplete = React.forwardRef((props: any, ref: any) => {
    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(false);

    const {
        label,
        id,
        name,
        type,
        placeholder,
        defaultValue,
        error,
        onChange,
        onTextChange,
        extraTextSearch,
        handleSelect,
        ...others
    } = props;

    useEffect(() => {
        if (defaultValue && onChange) onChange(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        if (searchText) handleOnSearch(searchText);
    }, [searchText]);

    const handleOnSearch = async (text: string) => {
        try {
            setSearchResults([]);
            setLoading(true);
            const response = await server.getOthers(text || "");
            setSearchResults(response?.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleOnChange = async (selectedOption: any) => {
        if (onChange) onChange(selectedOption);
    };

    const handleSelectOther = async (selectedOption: any) => {
        if (onChange) onChange(selectedOption);
        if (handleSelect) handleSelect(selectedOption);
    };

    return (
        <>
            <AutocompleteInput
                onSearch={(text: string) => {
                    setSearchText(text);
                }}
                changeTextOnUpdate={true}
                onChange={handleOnChange}
                label={label}
                ref={ref}
                type={type}
                name={name}
                id={id}
                defaultValue={defaultValue?.name || ""}
                placeholder={placeholder}
                error={error}
                //searchResults={searchResults}
                searchResultLabel="name"
                searchResultSubheader="formatted_address"
                onTextChange={onTextChange}
                loading={loading}
                {...others}
            />
            <ResultsList
                searchResults={searchResults}
                searchResultLabel="name"
                searchResultSubheader="formatted_address"
                onChange={handleSelectOther}
            />
        </>
    );
});
export default OthersAutocomplete;
