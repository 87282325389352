import HotelItemDetail from "../HotelItemDetail/HotelItemDetail";

interface DetailItemProps {
    contact: any;
}

const InsightContactDetails = ({ contact }: DetailItemProps) => {
    return (
        <div className="flex items-center gap-6 flex-wrap w-full">
            <HotelItemDetail
                className="w-full lg:w-2/5"
                label="Name"
                value={contact.name || ""}
            />
            <HotelItemDetail
                className="w-full lg:w-2/5"
                label="Title"
                value={contact.title || ""}
            />
            <HotelItemDetail
                className="w-full lg:w-2/5"
                label="Contact type"
                value={contact.type || ""}
            />
            <HotelItemDetail
                className="w-full lg:w-2/5"
                label="Email"
                value={contact.email || ""}
            />
            <HotelItemDetail
                className="w-full lg:w-2/5"
                label="Phone"
                value={contact?.phone?.phone || contact?.phone || ""}
            />
            <HotelItemDetail
                className="w-full lg:w-2/5"
                label="Mobile"
                value={contact?.mobile?.mobile || contact?.mobile || ""}
            />
            <HotelItemDetail
                className="w-full lg:w-2/5"
                label="Comments"
                value={contact.comments || ""}
            />
        </div>
    );
};

export default InsightContactDetails;
