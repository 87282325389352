import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AUTHENTICATED, AUTHENTICATION_LOADING } from "../../api/firebase";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useAuthStateContext } from "../../context/Auth";
import HeaderBar from "./HeaderBar";
import {
    selectProfile,
    signOut as signOutProfile,
    updateBreadCrumbOptions,
} from "../../features/authentication/userSlice";
import LoadingView from "./LoadingView";
import { RootState } from "../../app/store";
import SideBar from "./SideBar";
import { selectCurrentSection } from "./layoutSlice";
import {
    AuthProviders,
    LayoutSectionHeader,
    LayoutSectionMenuItems,
    PAGE_SECTIONS,
    TripType,
} from "../../constants";
import Main from "./Main";
import { getBasePath, getBreadcrumbOptions } from "../../bussiness/general";
import {
    getNotificationsCount,
    listenToNotifications,
    selectNotificationsNotReadCount,
} from "../notifications/notificationsSlice";
import { MENU_OPTIONS_SHOW_FOR } from "../../constants/menu";
import BottomBar from "./BottomBar";
import { selectedTrip, selectedTripActivity } from "../trips/tripSlice";
import { useConstantsContext } from "../../context/Constants";
import { selectedLibraryElement, selectedOrganization } from "../organizations/organizationSlice";

export const isLoadingSelector = (state: RootState) =>
    state.trip?.isLoading ||
    state.hotel?.isLoading ||
    state.user?.isLoading ||
    state.organization?.isLoading ||
    state.others?.isLoading ||
    state.restaurant?.isLoading ||
    state.userprofile?.isLoading ||
    state.insights?.isLoading ||
    state.chat?.isLoading ||
    state.admin.isLoading;

function Layout() {
    const { status, signOut } = useAuthStateContext();
    const user = useAppSelector(selectProfile);
    const { tripId, userId, activityId, organizationId, activityType } = useParams();
    const userIsLoggedIn = status === AUTHENTICATED;
    const dispatch = useAppDispatch();
    const isLoading =
        useAppSelector(isLoadingSelector) || status === AUTHENTICATION_LOADING;
    const currentSection = useAppSelector(selectCurrentSection);
    const notificationsNotRead = useAppSelector(
        selectNotificationsNotReadCount
    );
    const profile = useAppSelector(selectProfile);
    const trip = useAppSelector(selectedTrip);
    const { title } = trip || {};
    const activity = useAppSelector(selectedTripActivity);
    const organization = useAppSelector(selectedOrganization)
    const libraryElement = useAppSelector(selectedLibraryElement);
    const { constants } = useConstantsContext();
    const basePath = useMemo(() => {
        return currentSection
            ? getBasePath(currentSection, {
                  tripId: Number(tripId),
                  userId: Number(userId),
                  activityId: Number(activityId),
                  organizationId: Number(organizationId),
              })
            : "/";
    }, [
        currentSection,
        tripId,
        userId,
        activityId,
        organizationId,
    ]);

    useEffect(() => {
        if (currentSection) {
            const options = getBreadcrumbOptions(currentSection, {
                tripId: Number(tripId),
                userId: Number(userId),
                activityId: Number(activityId),
                tripTitle: title || "",
                activityTitle: activity?.title,
                organizationId: organization?.id,
                organizationName: organization?.name,
                libraryElementTitle: libraryElement?.title,
                activityType,
            });
            dispatch(updateBreadCrumbOptions(options));
        }
    }, [
        currentSection,
        tripId,
        userId,
        activityId,
        title,
        activityType,
        organization,
        libraryElement,
        activity?.title,
        dispatch,
    ]);

    useEffect(() => {
        let unsubscribe: any;
        if (user?.email) {
            const fetch = async () => {
                unsubscribe = await dispatch(
                    listenToNotifications(user?.email)
                );
                dispatch(getNotificationsCount(user.email));
                return unsubscribe;
            };
            fetch();
            return () => {
                unsubscribe();
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.email]);

    const handleSignOut = async () => {
        if (signOut) await signOut();
        dispatch(signOutProfile());
    };

    const sectionHeader = currentSection
        ? LayoutSectionHeader[currentSection]
        : {};

    const filterMenuOptions = (
        currentSection: PAGE_SECTIONS | null,
        provider: any,
        canEdit: boolean
    ) => {
        const sectionSidebarItems = currentSection
            ? LayoutSectionMenuItems[currentSection]
            : [];

        return sectionSidebarItems
            ?.filter((option: { showFor: any[] }) => {
                return option.showFor.some((s) => {
                    switch (s) {
                        case MENU_OPTIONS_SHOW_FOR.emailUsers:
                            return provider?.includes(AuthProviders.PASSWORD);
                        case MENU_OPTIONS_SHOW_FOR.canEdit:
                            return !!canEdit;
                        case MENU_OPTIONS_SHOW_FOR.all:
                            return true;
                        case MENU_OPTIONS_SHOW_FOR.admin:
                            return profile?.isAdmin;
                        case MENU_OPTIONS_SHOW_FOR.organizedTrip:
                            return trip?.type === TripType.ORGANIZER;
                        case MENU_OPTIONS_SHOW_FOR.organizationConfirmedUser:
                            return (profile?.isAdmin ||
                                organization?.status ===
                                constants?.UserStatusOnOrganization.CONFIRMED
                            );
                        default:
                            return false;
                    }
                });
            })
            .filter((option: { featureFlags: any[] }) => {
                return (
                    !option?.featureFlags ||
                    !option?.featureFlags.length ||
                    option.featureFlags.some(
                        (f) =>
                            constants?.featureFlags && constants.featureFlags[f]
                    )
                );
            });
    };

    const filteredOptions = useMemo(() => {
        return filterMenuOptions(
            currentSection,
            profile?.provider,
            trip?.canEdit || profile?.isAdmin
        );
    }, [profile?.provider, currentSection, trip, organization?.status]);

    const bottomBarOptions = useMemo(() => {
        return filteredOptions?.filter(
            (opt: any) => opt?.key !== PAGE_SECTIONS.TRIP_NOTIFICATIONS
        );
    }, [filteredOptions]);

    const withHeaderBar = useMemo(() => {
        return ![PAGE_SECTIONS.TRIP_INVITE_GUEST].includes(
            currentSection as any
        );
    }, [currentSection]);

    const fullWidth = useMemo(() => {
        return [
            PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_DETAILS,
            PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_CONTACTS,
            PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_EXPERIENCE,
            PAGE_SECTIONS.TRIP_ITINERARY_OTHER_PRIVATE_REVIEW_DETAILS,
            PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DETAILS,
            PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_CONTACTS,
            PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_ROOMS,
            PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DINNING,
            PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_WELLNESS,
            PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_COMMON,
        ].includes(currentSection as any);
    }, [currentSection]);

    const withSidebar = !!filteredOptions?.length;
    const renderSidebar = useMemo(() => {
        return withSidebar ? (
            <SideBar
                handleSignOut={handleSignOut}
                items={filteredOptions}
                basePath={basePath}
                currentSection={currentSection}
            />
        ) : null;
    }, [filteredOptions, basePath, currentSection]);

    const renderBottomBar = useMemo(() => {
        return withSidebar ? (
            <BottomBar
                items={bottomBarOptions}
                basePath={basePath}
                currentSection={currentSection}
                handleSignOut={handleSignOut}
            />
        ) : null;
    }, [bottomBarOptions, withSidebar, basePath, currentSection]);

    return (
        <div className="App">
            {withHeaderBar && (
                <header>
                    <nav>
                        <HeaderBar
                            userIsLoggedIn={userIsLoggedIn}
                            user={user}
                            notificationsNotRead={notificationsNotRead}
                        />
                    </nav>
                </header>
            )}
            <main className="2xl:max-w-screen-2xl mx-auto mt-[68px] main overflow-x-hidden">
                {renderSidebar}
                <Main
                    {...sectionHeader}
                    withSidebar={withSidebar}
                    withHeaderBar={withHeaderBar}
                    fullWidth={fullWidth}
                />
                {isLoading && <LoadingView />}
                {renderBottomBar}
            </main>
        </div>
    );
}

export default Layout;
