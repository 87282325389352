import { ButtonSize } from "../../../../../../../../constants";
import Icon from "../../../../../../../../assets/svg/Icon";
import Button from "../../../../../../../../components/ButtonSquare";
import { useAppSelector } from "../../../../../../../../app/hooks";
import { selectCurrentBreakpoint } from "../../../../../../../layout/layoutSlice";
import { mdiArrowLeftThin } from "@mdi/js";

interface InsightHeaderProps {
    title?: React.ReactNode | string;
    showEditButton: boolean;
    onEdit?: () => void;
    onBack?: () => void;
    backButtonLabel?: string;
}

const InsightHeader = ({
    showEditButton,
    onEdit,
    title,
    onBack,
    backButtonLabel = "",
}: InsightHeaderProps) => {
    const { isMobile } = useAppSelector(selectCurrentBreakpoint);

    return (
        <>
            {!!onBack && (
                <div
                    className="flex items-center sm:text-base text-orange-500 font-semibold cursor-pointer mb-1"
                    onClick={onBack}
                >
                    <Icon
                        materialIcon={mdiArrowLeftThin}
                        className="h-7 text-orange-500"
                    />
                    {backButtonLabel}
                </div>
            )}
            <div className="w-full flex flex-row items-end content-end justify-end">
                <div className="flex flex-grow gap-2 sm:text-xl text-lg text-neutral-900 font-semibold">
                    {title}
                </div>
                {showEditButton &&
                    (!isMobile ? (
                        <Button
                            onClick={onEdit}
                            size={ButtonSize.FIT}
                            label={"Edit"}
                            className="px-6 self-end"
                        />
                    ) : (
                        <Icon
                            icon="PencilIconOutline"
                            className="w-[24px] cursor-pointer text-pink-600"
                            onClick={onEdit}
                        />
                    ))}
            </div>
        </>
    );
};

export default InsightHeader;
