interface ListOptionProps {
    options: string[];
    icon: any;
    itemStyle?: string;
}

export const ListOption = ({ options, icon, itemStyle }: ListOptionProps) => {
    return (
        <div className="w-full text-sm flex gap-4 justify-between">
            <ul>
                {options?.map((option, index) => {
                    return (
                        <li key={index}>
                            <div className={`flex  mb-4 ${itemStyle}`}>
                                <div className="w-8">{icon}</div>
                                {option}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
