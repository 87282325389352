import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Icon from "../../../../assets/svg/Icon";
import Button from "../../../../components/ButtonSquare";
import EmptyScreen from "../../../../components/EmptyScreen/EmptyScreen";
import ImageGalleryGrid from "../../../../components/ImagesGalleryGrid";
import Modal from "../../../../components/Modal";
import {
    ButtonSize,
    PAGE_ROWS,
    PAGE_SECTIONS,
    UserRoleOnTrip,
} from "../../../../constants";
import { selectProfile } from "../../../authentication/userSlice";
import { setCurrentSection } from "../../../layout/layoutSlice";
import {
    editGalleryImage,
    getTripImages,
    isLoading,
    removeImagesFromTrip,
    selectedTrip,
} from "../../tripSlice";
import { EditImageFormModal } from "./EditImageFormModal";
import { mdiArrowLeftThin, mdiDownload } from "@mdi/js";
import { downloadZipFileFromLinkks } from "../../../../helpers";
import server from "../../../../api/server";
import { useDispatch } from "react-redux";
import { GalleryImageModal } from "./GalleryImageModal";

let imagesList: any[] = [];
export default function TripImagesGallery(props: any) {
    const [images, setImages] = useState<any>(undefined);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const trip = useAppSelector(selectedTrip);
    const profile = useAppSelector(selectProfile);
    const [loading, setLoading] = useState<any>(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [deleteFileModalOpen, setDeleteFileModalOpen] = useState(false);
    const [editFileModalOpen, setEditFileModalOpen] = useState(false);
    const [showSelectMenu, setShowSelectMenu] = useState(false);
    const [selectedImages, setSelectedImages] = useState<any[]>([]);
    const [selectedImage, setSelectedImage] = useState<any>();
    const [count, setCount] = useState<number>(0);
    const [skip, setSkip] = useState<number>(0);
    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.TRIP_GALLERY })
        );
        return () => {
            imagesList = [];
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (profile && trip) {
            getImages(PAGE_ROWS, 0);
        }
        setSkip(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip, profile]);

    const handleNewFileClick = (e: any) => {
        navigate("new");
    };

    const removeImage = async () => {
        await dispatch(
            removeImagesFromTrip(Number(trip?.id), [selectedImage.id])
        );
        imagesList = imagesList.filter((img) => img.id !== selectedImage.id);
        setImages(imagesList);
        setDeleteFileModalOpen(false);
    };

    const normalizeImages = (imagesArray: Array<any>) => {
        const response = imagesArray
            ? imagesArray.map((img: any) => {
                  const {
                      id,
                      name,
                      description,
                      url,
                      thumb,
                      width,
                      height,
                      userEmail,
                      ...other
                  } = img || {};
                  const imgNormalized = {
                      ...other,
                      id: id,
                      name: name,
                      description: description,
                      src: url,
                      thumbnail: thumb,
                      width: width,
                      height: height,
                      canEdit:
                          profile.isAdmin ||
                          ((trip?.canEdit || userEmail === profile.email) &&
                              !trip?.suspended)
                              ? "true"
                              : "false",
                  };

                  return imgNormalized;
              })
            : [];

        return response;
    };

    const getImages = async (take: number, newSkip: number) => {
        setLoading(true);
        const { data, ...other }: any = await dispatch(
            getTripImages(trip?.id, take, newSkip, "")
        );
        const imagesResponse = normalizeImages(data);
        const currentListIds = imagesList.map((img) => img.id);

        let newImages = imagesResponse.filter(
            (newImg: any) => !currentListIds.includes(newImg.id)
        );
        imagesList = imagesList.concat(newImages);
        setImages(imagesList);

        setLoading(false);
        setCount(other.count);
        setImages(imagesList);
        setSkip(skip + PAGE_ROWS);
    };

    const fetchData = () => {
        getImages(PAGE_ROWS, skip);
    };

    const handleClickImage = (img: any) => {
        if (showSelectMenu) {
            const found = selectedImages?.find((i: any) => img.id === i.id);
            if (!!found) {
                setSelectedImages([
                    ...selectedImages.filter((i) => i.id !== found.id),
                ]);
            } else {
                setSelectedImages([...selectedImages, img]);
            }
            return;
        }
        setSelectedImage(img);
        setImageModalOpen(true);
    };

    const handleImageUpdate = async (data: { description: string }) => {
        setEditFileModalOpen(false);
        const image = await dispatch(
            editGalleryImage(trip?.id, selectedImage?.id, data.description)
        );
        const imagesUpdated = images.map((i: any) =>
            i.id === image?.id ? { ...i, description: data.description } : i
        );
        setImages([...imagesUpdated]);
    };

    const selectedImageIndex = useMemo(() => {
        return images?.findIndex((i: any) => {
            return i.id === selectedImage?.id;
        });
    }, [selectedImage, images]);

    return (
        <>
            <GalleryImageModal
                open={imageModalOpen}
                onClose={() => setImageModalOpen(false)}
                onDelete={(img: any) => {
                    setImageModalOpen(false);
                    setSelectedImage(img);
                    setDeleteFileModalOpen(true);
                }}
                onEdit={(img: any) => {
                    setImageModalOpen(false);
                    setSelectedImage(img);
                    setEditFileModalOpen(true);
                }}
                images={images}
                openOnIndex={selectedImageIndex}
            />
            <EditImageFormModal
                image={selectedImage}
                open={editFileModalOpen}
                onClose={() => setEditFileModalOpen(false)}
                onSubmit={handleImageUpdate}
            />
            <Modal
                open={deleteFileModalOpen}
                onClose={() => {
                    setDeleteFileModalOpen(false);
                }}
            >
                <div className="px-6">
                    <div className="text-base font-semibold text-gray-600">
                        Are you sure you want to remove this image?
                    </div>
                    <div className="px-4 mt-8 text-right">
                        <span className="mr-4">
                            <Button
                                outlined={true}
                                label="Cancel"
                                onClick={() => {
                                    setDeleteFileModalOpen(false);
                                }}
                                size={ButtonSize.SMALL}
                            />
                        </span>
                        <Button
                            label="Remove"
                            onClick={removeImage}
                            size={ButtonSize.SMALL}
                        />
                    </div>
                </div>
            </Modal>
            <div className="flex flex-col">
                <div className="overflow-x-auto">
                    <div className="align-middle min-w-full ">
                        {!!showSelectMenu ? (
                            <SelectImagesMenu
                                selectedImages={selectedImages}
                                onClose={() => {
                                    setSelectedImages([]);
                                    setShowSelectMenu(false);
                                }}
                            />
                        ) : (
                            <>
                                {(profile.isAdmin ||
                                    (!trip?.suspended &&
                                        trip?.role !==
                                            UserRoleOnTrip.VIEWER)) && (
                                    <div className="flex justify-end mb-6 items-center">
                                        <span
                                            onClick={() => {
                                                setShowSelectMenu(true);
                                            }}
                                            className="text-base mr-4 font-semibold text-orange-600 hover:text-orange-500 cursor-pointer"
                                        >
                                            Select images
                                        </span>
                                        <Button
                                            size={ButtonSize.SMALL}
                                            label="Upload"
                                            onClick={handleNewFileClick}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        <div className="w-full h-full">
                            {images?.length > 0 && (
                                <InfiniteScroll
                                    dataLength={images?.length} //This is important field to render the next data
                                    next={fetchData}
                                    hasMore={images?.length < count}
                                    loader={""}
                                    className={
                                        "no-scrollbar justify-center items-center"
                                    }
                                >
                                    <ImageGalleryGrid
                                        selectionMode={showSelectMenu}
                                        images={images}
                                        onImageClick={handleClickImage}
                                        // onImageDelete={handleRemoveClick}
                                        selectedImages={selectedImages}
                                        loading={loading}
                                    />
                                </InfiniteScroll>
                            )}

                            {!images?.length && !loading && (
                                <EmptyScreen
                                    title="You have no photos uploaded yet."
                                    text="Upload photos and add descriptions to highlight itinerary places and events."
                                />
                            )}
                            {!!images?.length &&
                                !loading &&
                                images?.length < count && (
                                    <div
                                        onClick={() =>
                                            getImages(PAGE_ROWS, images?.length)
                                        }
                                        className="text-orange-600 hover:text-orange-500 cursor-pointer mt-12 font-semibold"
                                    >
                                        Load more
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const SelectImagesMenu = ({ onClose, selectedImages = [] }: any) => {
    const trip = useAppSelector(selectedTrip);
    const dispatch = useDispatch();
    const downLoadSelected = async () => {
        try {
            dispatch(isLoading(true));
            await downloadZipFileFromLinkks(
                selectedImages.map((f: any) => {
                    return { name: f.originalName, link: f?.src };
                }),
                `trip_${trip.id}_selected_images`
            );
        } catch (error) {
        } finally {
            dispatch(isLoading(false));
        }
    };

    const downloadAll = async () => {
        try {
            dispatch(isLoading(true));
            const response = await server.getTripImages(trip?.id, 100000, 0);
            const imagesData = response.data.data;
            const images = imagesData.map((f: any) => {
                return { name: f.originalName, link: f?.url };
            });
            await downloadZipFileFromLinkks(images, `trip_${trip.id}_images`);
        } catch (error) {
        } finally {
            dispatch(isLoading(false));
        }
    };
    return (
        <div className="text-left flex flex-row flex-wrap mb-4 items-center justify-between">
            <div className="flex flex-row items-center justify-start">
                <div onClick={() => onClose()}>
                    <Icon
                        className="w-[32px] text-orange-600 cursor-pointer"
                        materialIcon={mdiArrowLeftThin}
                    />
                </div>

                <span className="mx-4 text-sm font-semibold text-neutral-800">
                    {selectedImages?.length} photos selected
                </span>
                {!!selectedImages?.length && (
                    <div
                        onClick={downLoadSelected}
                        title="Download selected"
                        className="cursor-pointer"
                    >
                        <Icon
                            className="w-[24px] text-neutral-800 hover:text-neutral-900"
                            materialIcon={mdiDownload}
                        />
                    </div>
                )}
            </div>
            <div className="flex flex-row items-center justify-end gap-x-4">
                <span
                    onClick={downloadAll}
                    title="Download all gallery images"
                    className="ml-4 text-sm cursor-pointer font-semibold text-orange-600 hover:text-orange-500"
                >
                    Download all
                </span>
            </div>
        </div>
    );
};
