import { createContext, useContext, useState } from "react";
import moment from "moment";
import Modal, { Modal_Size } from "../../../components/Modal";
import { Paragraph } from "../../../components/Paragraph/Paragraph";
import ButtonSquared from "../../../components/ButtonSquare";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import { DatePickerMaterial as DatePickerStandAlone } from "../../../components/DatePickerMaterial/DatePickerMaterial";
import { ButtonSize } from "../../../constants";

interface ITripUpdateDatesContext {
    checkNewDatesBeforeSubmit: Function;
}
const TripUpdateDatesContext = createContext<ITripUpdateDatesContext>({
    checkNewDatesBeforeSubmit: Function,
});

export default function TripUpdateDatesProvider({
    children,
}: any): JSX.Element {
    const [dateRangeTooShort, setDateRangeTooShort] = useState<boolean>(false);
    const [confirmationModalData, setConfirmationModalData] =
        useState<any>(undefined);
    const [mustShiftDates, setMustShiftDates] = useState<any>(null);
    const [itineraryStartDate, setitineraryStartDate] = useState<any>(
        new Date()
    );
    const [shiftMinDate, setShiftMinDate] = useState<any>(new Date());
    const [shiftDates, setShiftDates] = useState<boolean>(false);
    const [shiftMaxDate, setShiftMaxDate] = useState<any>(new Date());
    const [onSubmit, setOnSubmit] = useState<any>(null);


    const checkNewDatesBeforeSubmit = (
        payload: any,
        trip: any,
        submitFunction: any,
        limits: {
            minDate: string;
            maxDate: string;
        } | null
    ) => {
        setOnSubmit({submit: submitFunction});

        if (limits) {
            const itineraryLength = moment(limits?.maxDate).diff(
                moment(limits?.minDate),
                "days"
            );
            const newDateRangeLength = moment
                .utc(payload?.endDate)
                .startOf("day")
                .diff(moment.utc(payload.startDate).startOf("day"), "days");

            if (newDateRangeLength < itineraryLength && false) {
                setDateRangeTooShort(true);
                return;
            }

            setShiftMaxDate(
                moment(payload.endDate)
                    .subtract(itineraryLength, "days")
                    .toDate()
            );
            setShiftMinDate(moment(payload.startDate).toDate());
            setitineraryStartDate(moment(payload.startDate).toDate());

            const startDaySame = moment
                .utc(trip.startDate)
                .startOf("day")
                .isSame(moment.utc(payload?.startDate).startOf("day"), "day");

            const startDayAfter =
                moment
                    .utc(payload?.startDate)
                    .startOf("day")
                    .isAfter(
                        moment.utc(limits?.minDate).startOf("day"),
                        "days"
                    ) ||
                moment
                    .utc(payload?.startDate)
                    .startOf("day")
                    .isAfter(
                        moment.utc(limits?.maxDate).startOf("day"),
                        "days"
                    );

            const endDayBefore =
                moment
                    .utc(payload?.endDate)
                    .startOf("day")
                    .isBefore(
                        moment.utc(limits?.maxDate).startOf("day"),
                        "days"
                    ) ||
                moment
                    .utc(payload?.endDate)
                    .startOf("day")
                    .isBefore(
                        moment.utc(limits?.minDate).startOf("day"),
                        "days"
                    );

            if (endDayBefore || startDayAfter) {
                setConfirmationModalData(payload);
                setShiftDates(true);
                setMustShiftDates(true);
                return;
            }
            if (!startDaySame) {
                if (
                    moment(shiftMaxDate).isSame(moment(shiftMinDate), "day") &&
                    moment
                        .utc(limits?.minDate)
                        .isSame(moment(shiftMinDate), "day")
                ) {
                    return;
                }
                setConfirmationModalData(payload);
                setMustShiftDates(false);
                return;
            }
        }
        submitFunction(payload);
    };

    return (
        <TripUpdateDatesContext.Provider value={{ checkNewDatesBeforeSubmit }}>
            <ModalConfirmation
                hideCloseButton
                open={dateRangeTooShort}
                description="Your current itinerary does not fit in the new date range. Please check your dates or rearrange your activities"
                okButtonText="Close"
                handleSubmit={() => setDateRangeTooShort(false)}
            />
            <Modal
                onClose={() => {
                    setShiftDates(false)
                    setConfirmationModalData(false);
                    setDateRangeTooShort(false)
                }}
                open={!!confirmationModalData}
                size={Modal_Size.xs}
            >
                <div className="px-6">
                    <div className="text-lg font-semibold text-neutral-400 justify-center pb-4 text-center">
                        You are updating the trip dates
                    </div>

                    {shiftDates && (
                        <>
                            <Paragraph className="text-base font-base justify-center text-center mb-4">
                                {mustShiftDates
                                    ? "Your itinerary is outside the new date range, please set a new starting date for your activities, and they will be shifted to fit between the new dates provided."
                                    : "Select the new starting date for your itinerary"}
                            </Paragraph>
                            <DatePickerStandAlone
                                label={"Itinerary starts on"}
                                onChange={setitineraryStartDate}
                                value={itineraryStartDate}
                                minDate={shiftMinDate}
                                maxDate={shiftMaxDate}
                            />
                            <div className="flex flex-grow items-center">
                                <div className="flex flex-grow flex-row gap-4 lg:px-4 mt-8 justify-center text-right">
                                    <ButtonSquared
                                        type="button"
                                        label={"Cancel"}
                                        outlined
                                        onClick={() => {
                                            setShiftDates(false);
                                            mustShiftDates &&
                                                setConfirmationModalData(false);
                                        }}
                                        size={ButtonSize.SMALL}
                                    />
                                    <ButtonSquared
                                        label={"Submit"}
                                        type="button"
                                        onClick={() => {
                                            onSubmit &&
                                                onSubmit?.submit({
                                                    ...confirmationModalData,
                                                    itineraryStartDate: moment
                                                        .utc(itineraryStartDate)
                                                        .format("yyyy-MM-DD"),
                                                });
                                            setConfirmationModalData(false);
                                        }}
                                        size={ButtonSize.SMALL}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {!shiftDates && (
                        <>
                            <Paragraph className="text-base font-base justify-center text-center">
                                Do you want to update your itinerary to have a
                                new starting day (The first activity will be
                                shifted to a new date of your choice)
                            </Paragraph>
                            <div className="flex flex-grow items-center">
                                <div className="flex flex-grow flex-row gap-4 lg:px-4 mt-8 justify-center text-right">
                                    <ButtonSquared
                                        type="button"
                                        label={"Shift activities"}
                                        outlined
                                        onClick={() => setShiftDates(true)}
                                        size={ButtonSize.SMALL}
                                    />
                                    <ButtonSquared
                                        type="button"
                                        label={"Keep current"}
                                        onClick={() => {
                                            onSubmit &&
                                            onSubmit?.submit(confirmationModalData);
                                            setConfirmationModalData(false);
                                        }}
                                        size={ButtonSize.SMALL}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
            {children}
        </TripUpdateDatesContext.Provider>
    );
}

export const useTripUpdateDatesContext = () =>
    useContext(TripUpdateDatesContext);
