import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import TabComponent from "../../components/TabComponent";
import { PAGE_SECTIONS } from "../../constants";
import { setCurrentSection } from "../layout/layoutSlice";
import MyInsightsList from "./insightsList/MyInsightsList";
import SharedInsightsList from "./insightsList/SharedInsightsList";
import { selectProfile } from "../authentication/userSlice";

export default function InsightsSection(props: any) {
    const dispatch = useAppDispatch();
    const profile = useAppSelector(selectProfile);
    useEffect(() => {
        dispatch(setCurrentSection({ currentSection: PAGE_SECTIONS.INSIGHTS }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const tabItemsIndex = {
        myInsights: 0,
        sharedInsights: 1,
    };
    const tabItems = [
        {
            id: tabItemsIndex.myInsights,
            text: "All my insights",
        },
        {
            id: tabItemsIndex.sharedInsights,
            text: "Shared with me",
        },
    ];
    const [selectedItem, setSelectedItem] = useState<string | number>(
        tabItems[0].id
    );
    const changeTab = (tabIndex: string | number) => {
        setSelectedItem(tabIndex);
    };

    return profile?.email ? (
        <div className="flex-grow">
            <TabComponent
                className="text-left mb-6"
                onChange={changeTab}
                items={tabItems}
                defaultSelected={selectedItem}
            />
            {selectedItem === tabItemsIndex.myInsights && <MyInsightsList />}
            {selectedItem === tabItemsIndex.sharedInsights && (
                <SharedInsightsList />
            )}
        </div>
    ) : null;
}
