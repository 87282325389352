import moment from "moment";
import {
    ActivityType,
    ACTIVITY_FORM_STEPS,
    TRIP_DEFAULT_IMAGES,
    TRIP_USERS_LIST_OPTIONS,
    UserRoleOnTrip,
    UserStatusOnTrip,
} from "../constants";

export const getActivityFormNextStep = (
    activityType: ActivityType,
    currentStep: string
) => {
    const activitySteps = ACTIVITY_FORM_STEPS[activityType];
    const currentStepIndex = activitySteps.findIndex(
        (step: any) => step.id === currentStep
    );
    const newIndex = currentStepIndex + 1;
    return newIndex <= activitySteps.length
        ? activitySteps[newIndex]
        : activitySteps[currentStepIndex];
};

export const getActivityFormPrevStep = (
    activityType: ActivityType,
    currentStep: string
) => {
    const activitySteps = ACTIVITY_FORM_STEPS[activityType];
    const currentStepIndex = activitySteps.findIndex(
        (step: any) => step.id === currentStep
    );
    const newIndex = currentStepIndex - 1;
    return newIndex >= 0
        ? activitySteps[newIndex]
        : activitySteps[currentStepIndex];
};

// TODO: add more presenter values that can be formatted like status label, createdAt, etc
export const presenterTrip = (trip: any = {}, dateFormat = "MM/DD/YY") => {
    return {
        ...trip,
        coverImage:
            trip?.coverImage ||
            (
                trip?.coverImage_default &&
                TRIP_DEFAULT_IMAGES.find(
                    (i) => i.id === trip?.coverImage_default
                )
            )?.src,
        startDate: moment.utc(trip?.startDate).format(dateFormat),
        endDate: moment.utc(trip?.endDate).format(dateFormat),
    };
};

export const getUserOnTripMenuOptions = (
    meOnTrip: any,
    user: any,
    isUserAdmin: boolean
) => {
    if (user?.role === UserRoleOnTrip.OWNER) {
        return [];
    }
    if (
        meOnTrip?.userEmail === user?.userEmail ||
        meOnTrip?.status !== UserStatusOnTrip.CONFIRMED ||
        !isAdmin(meOnTrip?.role)
    )
        return isUserAdmin
            ? TRIP_USERS_LIST_OPTIONS[
                  user?.status || UserStatusOnTrip.CONFIRMED
              ]
            : [];
    else return TRIP_USERS_LIST_OPTIONS[user?.status];
};

export const isAdmin = (userStatus: UserRoleOnTrip) => {
    return [UserRoleOnTrip.ADMIN, UserRoleOnTrip.OWNER].includes(userStatus);
};
