import ListWithPagination from "../../../../../components/ListWithPagination";
import StatusBadgeComponent from "../../../../../components/StatusBadge/StatusBadgeComponent";
import {
    DocumentRequirementsStatus,
    GeneralStatus,
} from "../../../../../constants";
import { formatDate } from "../../../../../helpers";

interface ParticipantDocumentsListProps {
    handleClickDocument: Function;
    handlePaginatorChange: Function;
    tripRequirements: any;
}
export default function ParticipantDocumentsList({
    handleClickDocument,
    handlePaginatorChange,
    tripRequirements,
}: ParticipantDocumentsListProps) {
    const renderItem = ({ item: req }: any) => (
        <div
            key={req.id}
            onClick={() => handleClickDocument(req)}
            className="hover:bg-neutral-50 cursor-pointer border-b border-b-gray-300 py-4"
        >
            <div className="w-full flex items-center justify-between">
                <div className="text-left font-semibold text-gray-600">
                    {req.name}
                </div>
                <StatusBadgeComponent
                    status={
                        req?.status === DocumentRequirementsStatus.PENDING
                            ? GeneralStatus.AWAITING_APPROVAL
                            : req?.status
                    }
                />
            </div>
            <div className="w-full flex items-center justify-between mt-2">
                <div className="text-left text-xs text-gray-400 whitespace-pre-line break-before-all">
                    {req.description}
                </div>
                <div className="text-left text-xs text-gray-400">
                    {formatDate(req.deadline, "MM/DD/YY")}
                </div>
            </div>
        </div>
    );
    return (
        <>
            <ListWithPagination
                renderItem={renderItem}
                items={tripRequirements}
                itemKey={(req: any) => `tripMemberDoc_${req?.id}`}
                handlePaginatorChange={handlePaginatorChange}
            />
        </>
    );
}
