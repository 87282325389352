import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { PAGE_SECTIONS, PlanType } from "../../constants";
import { setCurrentSection } from "../layout/layoutSlice";
import { CurrentPlan } from "./CurrentPlan";
import { getAvailablePlans, getCurrentPlan } from "./plansSlice";
import { isLoading } from "../trips/tripSlice";
import { useSelector } from "react-redux";
import TabComponent from "../../components/TabComponent";
import { MyDetailsList } from "./MyDetailsList";

const tabItemsIndex = { myPlan: 0, details: 1 };
const tabItems = [
    {
        id: tabItemsIndex.myPlan,
        text: "My Plan",
    },
    {
        id: tabItemsIndex.details,
        text: "Details",
    },
];

export default function UserPlans() {
    const user = useSelector((state: any) => state?.user?.profile);
    const dispatch = useAppDispatch();
    const [editting, setEditting] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string | number>(
        tabItems[0].id
    );

    const changeTab = (tabIndex: string | number) => {
        setSelectedItem(tabIndex);
        setEditting(false);
    };

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.PLAN_USER,
            })
        );
    }, []);

    const fetchPlans = async () => {
        dispatch(isLoading(true));
        await dispatch(getCurrentPlan());
        await dispatch(
            getAvailablePlans(
                PlanType.USER,
                (user?.userType &&
                    user?.userType !== "null" &&
                    user?.userType) ||
                    "SUPPLIER"
            )
        );
        dispatch(isLoading(false));
    };

    const getDetails = (filters: any) => {
        // dispatch(
        //     getDetails(
        //         filters.take,
        //         filters.skip,
        //         filters.status,
        //         filters.search,
        //     )
        // );
    };

    useEffect(() => {
        setTimeout(() => {
            fetchPlans();
        }, 300);
    }, []);

    return (
        <>
            <div className="flex flex-col mb-4 flex-wrap">
                <TabComponent
                    className="flex-grow text-left md:my-6"
                    onChange={changeTab}
                    items={tabItems}
                    defaultSelected={selectedItem}
                />
            </div>
            {selectedItem === tabItemsIndex.myPlan && <CurrentPlan />}
            {selectedItem === tabItemsIndex.details && <MyDetailsList />}
        </>
    );
}
