import InsightDetailItem from "../../../../../components/InsightDetailItem";
import Input from "../../../../../../../../components/Input";
import Button from "../../../../../../../../components/ButtonSquare";
import { InsightImages } from "../../../../../components/InsightImages";
import TextAreaInput from "../../../../../../../../components/TextArea";
import InsightFormContainer from "../../components/InsightFormContainer";
import InsightInputContainer from "../../components/InsightInputContainer";

interface DinningItemProps {
    showForm: boolean;
    register: any;
    errors: any;
    activeItem?: any;
    handleCancel: () => void;
    handleUploadFile: (e: any) => void;
    isValid: boolean;
}
export const FormDinningItem = ({
    showForm,
    register,
    errors,
    activeItem,
    handleCancel,
    handleUploadFile,
    isValid,
}: DinningItemProps) => {
    if (!showForm) return null;
    return (
        <>
            <InsightImages
                handleUploadFile={handleUploadFile}
                images={activeItem?.images}
            />
            <div className="col-span-6 sm:col-span-3 mt-3">
                <InsightDetailItem label="Dinning & Drinking types">
                    <InsightFormContainer className="gap-8">
                        <InsightInputContainer>
                            <Input
                                register={register}
                                label="Name"
                                type="text"
                                markRequired
                                defaultValue={activeItem?.name}
                                name="name"
                                error={errors["name"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <TextAreaInput
                                register={register}
                                label="Comments"
                                type="text"
                                defaultValue={activeItem?.comments}
                                name="comments"
                                error={errors["comments"]?.message}
                            />
                        </InsightInputContainer>
                    </InsightFormContainer>
                </InsightDetailItem>
            </div>
            <div className="p-8 text-right flex lg:justify-end w-full gap-4">
                <Button onClick={handleCancel} outlined label="Cancel" />
                <Button disabled={!isValid} type="submit" label={"Save"} />
            </div>
        </>
    );
};
