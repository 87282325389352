/* This example requires Tailwind CSS v2.0+ */

import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { ActivityType, ACTIVITY_FORM_STEPS, PAGE_SECTIONS } from "../../../../../../constants";
import { usePrompt } from "../../../../../../hooks/useCallbackPrompt";
import {
    activityFormCurrentStep,
    addActivityToTrip,
    initializeActivityForm,
    selectActivityFormExitDialog,
    selectedTrip,
    setActivityForm,
} from "../../../../tripSlice";
import NewHotelActivityForm from "./Hotel";
import NewOtherActivityForm from "./Other";
import NewRestaurantActivityForm from "./Restaurant";
import Stepper from "./Stepper";
import moment from "moment";

export default function NewActivityForm() {
    const { activityId, activityType } = useParams();
    const typeN: any = activityType;
    const steps = ACTIVITY_FORM_STEPS[typeN];
    const currentStep = useAppSelector(activityFormCurrentStep);
    const showExitDialog = useAppSelector(selectActivityFormExitDialog);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const trip = useAppSelector(selectedTrip);
    usePrompt("Are you sure? you will lose your changes", !!showExitDialog);

    useEffect(() => {
        dispatch(initializeActivityForm());
        return () => {
            dispatch(setActivityForm({ activityForm: {} }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateBack = () => {
        const date = searchParams.get("date");
        return navigate(
            `/trips/${trip.id}/itinerary${
                activityId ? "/" + activityId : ""
            }?date=${date}`
        );
    };

    const onSubmit = async (activity: any) => {
        try {
            const tripId = trip?.id;
            await dispatch(addActivityToTrip(Number(tripId), { activity }));
            return navigate(
                `/trips/${tripId}/itinerary?date=${moment
                    .utc(activity.startDate)
                    .format("YYYY-MM-DD")}`
            );
        } catch (error) {}
    }

    return (
        <div className="w-full grid grid-cols-6 gap-4 lg:min-h-full">
            <div className="col-span-6 lg:col-span-2">
                <div className="w-full flex flex-row lg:justify-start justify-center">
                    <Stepper steps={steps} current={currentStep} />
                </div>
            </div>
            <div className="col-span-6 lg:col-span-4 lg:border-l lg:border-solid-gray-300">
                {typeN === ActivityType.HOTEL ||
                typeN === ActivityType.VISIT ? (
                    <NewHotelActivityForm
                        currentSection={
                            typeN === ActivityType.VISIT
                                ? PAGE_SECTIONS.TRIP_ITINERARY_VISIT
                                : PAGE_SECTIONS.TRIP_ITINERARY_HOTEL
                        }
                        navigateBack={navigateBack}
                        onSubmit={onSubmit}
                    />
                ) : typeN === ActivityType.RESTAURANT ? (
                    <NewRestaurantActivityForm
                        currentSection={PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT}
                        navigateBack={navigateBack}
                        onSubmit={onSubmit}
                    />
                ) : (
                    <NewOtherActivityForm
                        currentSection={PAGE_SECTIONS.TRIP_ITINERARY_OTHER}
                        navigateBack={navigateBack}
                        onSubmit={onSubmit}
                    />
                )}
            </div>
        </div>
    );
}
