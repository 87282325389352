import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import {
    ACTIVITY_FORM_STEPS_IDS,
    ActivityType,
    ITripLocalPartner,
    PAGE_ROWS,
    TOAST_TYPE,
    TripStatus,
    UserRoleOnTrip,
    UserStatusOnTrip,
} from "../../constants";
import { CancelTokenSource } from "axios";
import { handleError } from "../authentication/userSlice";
import {
    getUserPermissionsOnTrip,
    normalizeReviews,
    sendToast,
} from "../../helpers";
import { markTripInvitesAsRead } from "../notifications/notificationsSlice";
import {
    getActivityFormNextStep,
    getActivityFormPrevStep,
} from "../../bussiness/trip";
import _ from "lodash";
import { UserTypeVariant } from "../../components/UserType/UserType";
import {
    checkConversationExists,
    createNewGroupConversation,
    removeUserFromGroupConversation,
    updateGroupConversation,
} from "../../helpers/chat.helper";
import { setLibraryElementToAddToTrip } from "../organizations/organizationSlice";
import { TRIP_DEFAULT_IMAGES } from "../../constants";
import { IContact } from "../../types/chat.type";
export interface TripState {
    isLoading: boolean;
    trips: IPaginatedData;
    selectedTrip: any | null;
    selectedTripHome: any | null;
    error: any;
    selectedTripMembers: IPaginatedData;
    selectedTripFiles: IPaginatedData;
    selectedTripImages: IPaginatedData;
    selectedTripRequirements: IPaginatedData;
    selectedTripNotifications: IPaginatedData;
    selectedTripFile: any;
    selectedTripActivities: any;
    selectedTripActivity: any;
    selectedTripRequirement: any;
    selectedTripRequirementUsers: any;
    selectedTripRequirementFiles: any;
    pendingTrips: IPaginatedData;
    selectedUserOnTrip: any;
    selectedUserOnTripReviews: IPaginatedData;
    selectedUserOnTripRequirements: IPaginatedData;
    selectedTripCustomInvite: any;
    activityForm: any;
    activityFormCurrentStep: string | null;
    activityInsight: any | null;
    activityReview: any | null;
}

interface IPaginatedData {
    data: Array<any>;
    count: number;
    currentPage: number;
    totalPages: number;
    skip: number;
    take: number;
    isLoading: boolean;
    requirements?: number;
    adminsCount?: number;
}

export const IPaginatedDataInitialState = {
    data: [],
    count: 0,
    currentPage: 1,
    totalPages: 1,
    skip: 0,
    take: PAGE_ROWS,
    isLoading: false,
};

const initialState: TripState = {
    isLoading: false,
    trips: IPaginatedDataInitialState,
    selectedTrip: null,
    selectedTripHome: null,
    error: false,
    selectedTripMembers: IPaginatedDataInitialState,
    selectedTripFiles: IPaginatedDataInitialState,
    selectedTripImages: IPaginatedDataInitialState,
    selectedTripRequirements: IPaginatedDataInitialState,
    selectedTripNotifications: IPaginatedDataInitialState,
    selectedTripFile: null,
    selectedTripActivity: null,
    selectedTripActivities: null,
    selectedTripRequirement: null,
    selectedTripRequirementUsers: null,
    selectedTripCustomInvite: null,
    pendingTrips: IPaginatedDataInitialState,
    selectedUserOnTrip: null,
    selectedUserOnTripReviews: IPaginatedDataInitialState,
    selectedUserOnTripRequirements: IPaginatedDataInitialState,
    selectedTripRequirementFiles: null,
    activityForm: null,
    activityFormCurrentStep: null,
    activityInsight: null,
    activityReview: null,
};

export const tripSlice = createSlice({
    name: "trip",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: any }>) => {
            state.error = action.payload.error;
        },
        setTrips: (state, action: PayloadAction<{ trips: any }>) => {
            state.trips = action.payload.trips;
        },
        setSelectedTrip: (
            state,
            action: PayloadAction<{ selectedTrip: Object | null }>
        ) => {
            state.selectedTrip = action.payload.selectedTrip
                ? {
                      ...action.payload.selectedTrip,
                  }
                : null;
        },
        setSelectedTripHome: (
            state,
            action: PayloadAction<{ selectedTripHome: Object | null }>
        ) => {
            state.selectedTripHome = action.payload.selectedTripHome
                ? { ...action.payload.selectedTripHome }
                : null;
        },
        setSelectedTripCustomInvite: (
            state,
            action: PayloadAction<{ customInvite: object | null }>
        ) => {
            state.selectedTripCustomInvite = action.payload.customInvite
                ? { ...action.payload.customInvite }
                : null;
        },
        removeUserTrip: (
            state,
            action: PayloadAction<{ userEmail: string }>
        ) => {
            state.selectedTrip = {
                ...state.selectedTrip,
                users: state.selectedTrip.users.filter(
                    (u: any) => u.userEmail !== action.payload.userEmail
                ),
            };
        },
        selectedTripMembersRemove: (
            state,
            action: PayloadAction<{ userEmail: string }>
        ) => {
            state.selectedTripMembers = {
                ...state.selectedTripMembers,
                count: state.selectedTripMembers.count - 1,
                data: state.selectedTripMembers.data.filter(
                    (u: any) => u.userEmail !== action.payload.userEmail
                ),
            };
        },
        selectedTripMembersUpdate: (
            state,
            action: PayloadAction<{ userEmail: string; data: any }>
        ) => {
            state.selectedTripMembers = {
                ...state.selectedTripMembers,
                data: state.selectedTripMembers.data.map((u: any) =>
                    u.userEmail !== action.payload.userEmail
                        ? u
                        : { ...u, ...(action.payload.data || {}) }
                ),
            };
        },
        setSelectedTripFiles: (
            state,
            action: PayloadAction<{ selectedTripFiles: any }>
        ) => {
            state.selectedTripFiles = {
                ...action.payload.selectedTripFiles,
            } || { data: [], count: 0 };
        },
        removeTripFile: (state, action: PayloadAction<{ fileId: Number }>) => {
            state.selectedTripFiles = {
                ...state.selectedTripFiles,
                count: state.selectedTripFiles?.count - 1,
                data: state.selectedTripFiles?.data?.filter(
                    (f) => f?.id !== action.payload.fileId
                ),
            };
        },
        setSelectedTripImages: (
            state,
            action: PayloadAction<{ selectedTripImages: any }>
        ) => {
            state.selectedTripImages = {
                ...action.payload.selectedTripImages,
            } || { data: [], count: 0 };
        },
        setSelectedTripRequirements: (
            state,
            action: PayloadAction<{ selectedTripRequirements: any }>
        ) => {
            state.selectedTripRequirements = {
                ...action.payload.selectedTripRequirements,
            } || { data: [], count: 0 };
        },
        removeSelectedTripRequirement: (
            state,
            action: PayloadAction<{ requirementId: number }>
        ) => {
            state.selectedTripRequirements = {
                ...state.selectedTripRequirements,
                data: state?.selectedTripRequirements?.data.filter(
                    (req) => req.id !== action.payload.requirementId
                ),
                count: state.selectedTripRequirements?.count - 1,
            };
        },
        setSelectedTripFile: (
            state,
            action: PayloadAction<{ selectedTripFile: any }>
        ) => {
            state.selectedTripFile = {
                ...action.payload.selectedTripFile,
            };
        },
        setSelectedTripActivities: (
            state,
            action: PayloadAction<{ selectedTripActivities: any }>
        ) => {
            state.selectedTripActivities =
                { ...action.payload.selectedTripActivities } || null;
        },
        setSelectedTripActivity: (
            state,
            action: PayloadAction<{ selectedTripActivity: any }>
        ) => {
            state.selectedTripActivity = {
                ...action.payload.selectedTripActivity,
            };
        },
        setSelectedTripRequirement: (
            state,
            action: PayloadAction<{ selectedTripRequirement: any }>
        ) => {
            state.selectedTripRequirement = {
                ...action.payload.selectedTripRequirement,
            };
        },
        setSelectedTripRequirementUsers: (
            state,
            action: PayloadAction<{ selectedTripRequirementUsers: any }>
        ) => {
            state.selectedTripRequirementUsers =
                action.payload.selectedTripRequirementUsers;
        },
        setSelectedTripRequirementFiles: (
            state,
            action: PayloadAction<{ selectedTripRequirementFiles: any }>
        ) => {
            state.selectedTripRequirementFiles =
                action.payload.selectedTripRequirementFiles;
        },
        setSelectedTripNotifications: (
            state,
            action: PayloadAction<{ selectedTripNotifications: any }>
        ) => {
            state.selectedTripNotifications = {
                ...action.payload.selectedTripNotifications,
            };
        },
        setSelectedTripMembers: (
            state,
            action: PayloadAction<{ selectedTripMembers: IPaginatedData }>
        ) => {
            state.selectedTripMembers = action.payload.selectedTripMembers
                ? { ...action.payload.selectedTripMembers }
                : IPaginatedDataInitialState;
        },
        setPendingTrips: (
            state,
            action: PayloadAction<{ pending: IPaginatedData }>
        ) => {
            state.pendingTrips = action.payload.pending
                ? action.payload.pending
                : IPaginatedDataInitialState;
        },
        setSelectedUserOnTrip: (
            state,
            action: PayloadAction<{ user: any }>
        ) => {
            state.selectedUserOnTrip = action.payload.user;
        },
        setSelectedUserOnTripRequirements: (
            state,
            action: PayloadAction<{ requirements: any }>
        ) => {
            state.selectedUserOnTripRequirements = action.payload.requirements;
        },

        setSelectedUserOnTripReviews: (
            state,
            action: PayloadAction<{ reviews: any }>
        ) => {
            state.selectedUserOnTripReviews = action.payload.reviews;
        },
        setActivityForm: (
            state,
            action: PayloadAction<{ activityForm: any }>
        ) => {
            state.activityForm = action.payload.activityForm;
        },
        updateActivityForm: (
            state,
            action: PayloadAction<{ activityForm: any }>
        ) => {
            state.activityForm = {
                ...(state?.activityForm || {}),
                ...action.payload.activityForm,
            };
        },
        setActivityFormCurrentStep: (
            state,
            action: PayloadAction<{ step: any }>
        ) => {
            state.activityFormCurrentStep = action.payload.step;
        },
        setActivityInsight: (
            state,
            action: PayloadAction<{ activityInsight: any }>
        ) => {
            state.activityInsight = action.payload?.activityInsight;
        },
        setActivityReview: (
            state,
            action: PayloadAction<{ activityReview: any }>
        ) => {
            state.activityReview = action.payload?.activityReview;
        },
    },
});

export const {
    isLoading,
    setTrips,
    setSelectedTrip,
    setSelectedTripHome,
    setError,
    removeUserTrip,
    setSelectedTripMembers,
    setSelectedTripFiles,
    setSelectedTripImages,
    setSelectedTripFile,
    setSelectedTripActivities,
    setSelectedTripActivity,
    setSelectedTripRequirements,
    setSelectedTripNotifications,
    setSelectedTripRequirement,
    setSelectedTripRequirementUsers,
    setSelectedUserOnTrip,
    setPendingTrips,
    setSelectedUserOnTripRequirements,
    setSelectedUserOnTripReviews,
    setSelectedTripCustomInvite,
    setActivityForm,
    updateActivityForm,
    setActivityFormCurrentStep,
    setSelectedTripRequirementFiles,
    setActivityInsight,
    setActivityReview,
    removeSelectedTripRequirement,
    selectedTripMembersRemove,
    selectedTripMembersUpdate,
    removeTripFile,
} = tripSlice.actions;

export const selectTrips = (state: RootState) => state.trip?.trips;
export const selectError = (state: RootState) => state.trip?.error;
export const selectPendingTrips = (state: RootState) =>
    state.trip?.pendingTrips;
export const selectedTrip = (state: RootState) => state.trip?.selectedTrip;
export const selectedTripId = (state: RootState) =>
    state.trip?.selectedTrip?.id;
export const selectedTripHome = (state: RootState) =>
    state.trip?.selectedTripHome;
export const tripFiles = (state: RootState) => state.trip?.selectedTrip?.files;
export const tripImages = (state: RootState) => state.trip?.selectedTripImages;
export const tripRequirements = (state: RootState) =>
    state.trip?.selectedTripRequirements;
export const tripNotifications = (state: RootState) =>
    state.trip?.selectedTripNotifications;
export const selectedTripRequirement = (state: RootState) =>
    state.trip?.selectedTripRequirement;
export const selectedTripRequirementUsers = () => (state: RootState) =>
    state.trip?.selectedTripRequirementUsers;
export const selectedTripUsers = (state: RootState) =>
    state.trip?.selectedTripMembers;
export const selectUserOnTrip = (state: RootState) =>
    state.trip?.selectedUserOnTrip;
export const selectUserOnTripRequirements = (state: RootState) =>
    state.trip?.selectedUserOnTripRequirements;
export const selectUserOnTripReviews = (state: RootState) =>
    state.trip?.selectedUserOnTripReviews;
export const selectedTripFiles = (state: RootState) =>
    state.trip?.selectedTripFiles;
export const selectedTripFile = (state: RootState) =>
    state.trip?.selectedTripFile;
export const selectTripItinerary = (state: RootState) =>
    state.trip?.selectedTrip?.activities;
export const selectTripActivities = (state: RootState) =>
    state.trip?.selectedTripActivities;
export const selectedTripActivity = (state: RootState) =>
    state.trip?.selectedTripActivity;
export const selectTripLocalPartners = (state: RootState) =>
    state.trip?.selectedTrip?.localPartnerOnTrip;
export const selectedTripLocalPartner =
    (localPartnerId: number) => (state: RootState) =>
        state.trip?.selectedTrip?.localPartnerOnTrip?.find(
            (lp: any) => lp.id === localPartnerId
        );
export const selectedTripCustomInvite = (state: RootState) =>
    state.trip?.selectedTripCustomInvite;
export const activityFormCurrentStep = (state: RootState) =>
    state.trip?.activityFormCurrentStep;
export const selectActivityForm = (state: RootState) =>
    state.trip?.activityForm;
export const selectActivityFormExitDialog = (state: RootState) =>
    state.trip?.activityForm?.showExitDialog;
export const selectTripRequirementFiles = (state: RootState) =>
    state.trip?.selectedTripRequirementFiles;

export const selectActivityInsight = (state: RootState) =>
    state.trip?.activityInsight;

export const selectActivityReview = (state: RootState) =>
    state.trip?.activityReview;

export const selectMeOnTrip = (email: string) => (state: RootState) =>
    state.trip?.selectedTrip?.users?.find((u: any) => u?.userEmail === email);

export const getTrips =
    (
        take: number = 10,
        skip: number = 0,
        status?: any,
        userStatus?: Array<UserStatusOnTrip> | string
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.trips;
        const userStatusString = Array.isArray(userStatus)
            ? userStatus.toString()
            : "";
        try {
            dispatch(
                setTrips({
                    trips: {
                        count: currentState?.count,
                        data: [],
                        isLoading: true,
                        take: take,
                        skip: skip,
                        currentPage: 1,
                        totalPages: currentState?.totalPages,
                    },
                })
            );
            const response = await serverApi.getTrips(
                take,
                skip,
                status,
                userStatusString
            );
            dispatch(setTrips({ trips: response.data }));
            return response?.data;
        } catch (error) {
            dispatch(
                setTrips({
                    trips: currentState,
                })
            );
            dispatch(handleError(error));
            return currentState;
        }
    };

export const getPendingTrips = (): AppThunk => async (dispatch, getState) => {
    const currentState = getState().trip.pendingTrips;
    try {
        dispatch(
            setPendingTrips({
                pending: IPaginatedDataInitialState,
            })
        );
        const response = await serverApi.getTrips(
            50,
            0,
            TripStatus.ACTIVE,
            UserStatusOnTrip.PENDING
        );
        dispatch(
            setPendingTrips({
                pending: response?.data,
            })
        );
    } catch (error) {
        dispatch(
            setPendingTrips({
                pending: currentState,
            })
        );
        dispatch(handleError(error));
    }
};

export const getTripNotifications =
    (
        tripId: number,
        take: number = 10,
        skip: number = 0,
        search?: any
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripNotifications;
        try {
            dispatch(
                setSelectedTripNotifications({
                    selectedTripNotifications: {
                        ...currentState,
                        isLoading: true,
                    },
                })
            );
            const response = await serverApi.getTripNotifications(
                tripId,
                take,
                skip,
                search
            );
            const { data, ...others } = response.data;
            dispatch(
                setSelectedTripNotifications({
                    selectedTripNotifications: {
                        ...others,
                        data: _.orderBy(
                            currentState.data.concat(data),
                            "date",
                            "desc"
                        ),
                    },
                })
            );
            return response?.data;
        } catch (error) {
            dispatch(
                setSelectedTripNotifications({
                    selectedTripNotifications: {
                        ...currentState,
                        isLoading: false,
                    },
                })
            );
            dispatch(handleError(error));
        }
    };

export const createTrip =
    (data: any): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const trip = await serverApi.addTrip(data);
            const currentUser = getState().user.profile;
            if (trip.data.id){
                createNewGroupConversation(trip.data.id, trip.data.title, trip.data.coverImage, currentUser);
            }
            dispatch(isLoading(false));
            return trip.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const duplicateTrip =
    (tripId: number, formData: any): AppThunk<any> =>
    async (dispatch) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                dispatch(isLoading(true));
                const trip = await serverApi.duplicateTrip(tripId, formData);
                dispatch(isLoading(false));

                resolve(trip.data);
            } catch (error) {
                dispatch(isLoading(false));
                dispatch(handleError(error));
                resolve(null);
            }
        });
    };

export const deleteTrip =
    (tripId: number): AppThunk<any> =>
    async (dispatch) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                dispatch(isLoading(true));
                await serverApi.removeTrip(tripId);
                dispatch(isLoading(false));
                resolve(true);
            } catch (error) {
                dispatch(isLoading(false));
                dispatch(handleError(error));
                resolve(null);
            }
        });
    };

const mapUser = (profile: any) => {
    const userContact = {
        avatar: profile?.avatar || profile?.avatarThumb || "",
        avatarThumb: profile?.avatarThumb || profile?.avatar || "",
        email: profile?.email,
        firstName: profile?.firstName,
        id: profile?.id,
        lastName: profile?.lastName,
        uid: profile?.uid
    }
    return userContact
}
export const leaveTrip =
    (tripId: number): AppThunk<any> =>
    async (dispatch, getState) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                dispatch(isLoading(true));
                const email = getState().user.profile.email;
                dispatch(selectedTripMembersRemove({ userEmail: email }));
                removeUserFromGroupConversation(tripId, mapUser(getState().user.profile) as IContact);
                await serverApi.removeUserFromTrip(tripId, email);
                dispatch(isLoading(false));
                resolve(true);
            } catch (error) {
                dispatch(isLoading(false));
                dispatch(handleError(error));
                resolve(null);
            }
        });
    };

export const updateTrip =
    (tripId: number, data: any): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const { invitationText, ...formData } = data;
                const currentState = getState().trip.selectedTrip;
                dispatch(isLoading(true));
                const response = await serverApi.updateTrip(tripId, formData);
                const email = getState().user.profile.email;
                const newTrip = {
                    ...currentState,
                    ...response.data,
                };
                if (
                    getState().user?.profile?.userType ===
                    UserTypeVariant.SUPPLIER
                ) {
                    const customInvite = await serverApi.getTripCustomInvite(
                        tripId
                    );
                    dispatch(
                        updateTripCustomInvite(tripId, {
                            ...(customInvite.data || { title: data.title }),
                            description: invitationText?.length
                                ? invitationText
                                : " ",
                        })
                    );
                }
                dispatch(
                    setSelectedTrip({
                        selectedTrip: {
                            ...newTrip,
                            ...getUserPermissionsOnTrip(newTrip, email),
                        },
                    })
                );

                dispatch(isLoading(false));
                resolve(response.data);
            } catch (error) {
                dispatch(isLoading(false));
                dispatch(handleError(error));
                resolve(false);
            }
        });
    };


export const getTripHome =
    (tripId: number, showLoading = true): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSelectedTripHome({ selectedTripHome: null }));
            dispatch(isLoading(showLoading));
            const response = await serverApi.getTripHome(tripId);
            dispatch(setSelectedTripHome({ selectedTripHome: response.data }));
            // dispatch(setSelectedTrip({ selectedTrip: response.data }));
            dispatch(isLoading(false));
            return response.data;
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
            return null;
        }
    };

export const getTripUser =
    (tripId: number, userId: number, showLoading = true): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(showLoading));
            dispatch(setSelectedUserOnTrip({ user: null }));
            const response = await serverApi.getTripUserProfile(tripId, userId);
            dispatch(setSelectedUserOnTrip({ user: response.data }));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const getTripCustomInvite =
    (tripId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getTripCustomInvite(tripId);
            dispatch(
                setSelectedTripCustomInvite({ customInvite: response.data })
            );
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const addTripCustomInvite =
    (tripId: number, formData: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addTripCustomInvite(
                tripId,
                formData
            );
            dispatch(
                setSelectedTripCustomInvite({ customInvite: response.data })
            );
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const updateTripCustomInvite =
    (tripId: number, formData: any): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripCustomInvite;
        try {
            dispatch(setSelectedTripCustomInvite({ customInvite: null }));
            dispatch(isLoading(true));
            const response = await serverApi.updateTripCustomInvite(
                tripId,
                formData
            );
            dispatch(
                setSelectedTripCustomInvite({ customInvite: response.data })
            );
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(
                setSelectedTripCustomInvite({ customInvite: currentState })
            );
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const deleteTripCustomInvite =
    (tripId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.deleteTripCustomInvite(tripId);
            dispatch(setSelectedTripCustomInvite({ customInvite: null }));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const getTripUserRequirements =
    (
        tripId: number,
        userEmail: string,
        take: number = 10,
        skip: number = 0,
        search: string = "",
        status: string = ""
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedUserOnTripRequirements;
        try {
            dispatch(
                setSelectedUserOnTripRequirements({
                    requirements: {
                        ...IPaginatedDataInitialState,
                        isLoading: true,
                    },
                })
            );
            const response = await serverApi.getTripUserRequirements(
                tripId,
                userEmail,
                take,
                skip,
                search,
                status
            );
            dispatch(
                setSelectedUserOnTripRequirements({
                    requirements: response.data,
                })
            );
        } catch (error) {
            dispatch(handleError(error));
            dispatch(
                setSelectedUserOnTripRequirements({
                    requirements: currentState,
                })
            );
        }
    };

export const updateRequirementFile =
    (
        tripId: number,
        requirementId: number,
        fileId: number,
        file: any
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.updateTripUserRequirement(
                tripId,
                requirementId,
                fileId,
                file
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };


export const getTripUserConfirmed = (tripId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await serverApi.getTripMembers(tripId, 1000, 0, "", UserStatusOnTrip.CONFIRMED, "");
            return response.data;
        } catch (error) {
            return null;
        }

    };

export const getTrip =
    (tripId: number, showLoading = true, showErrorToast = true): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setError({ error: false }));
            dispatch(setSelectedTrip({ selectedTrip: null }));
            dispatch(isLoading(showLoading));
            const response = await serverApi.getTrip(tripId);
            const email = getState().user.profile.email;
            dispatch(
                setSelectedTrip({
                    selectedTrip: {
                        ...response.data,
                        ...getUserPermissionsOnTrip(response.data, email),
                    },
                })
            );
            const conversationExists = await checkConversationExists(tripId);

            const data = response.data;

            const coverImage = data?.coverImage ||
            (
                data?.coverImage_default &&
                TRIP_DEFAULT_IMAGES.find(
                    (i) => i.id === data?.coverImage_default
                )
            )?.src
            if (conversationExists) {
                await updateGroupConversation(tripId, coverImage, response.data?.title);
            } else {
                const currentUser = getState().user.profile;
                await createNewGroupConversation(tripId, response.data?.title, coverImage, currentUser);
            }
            dispatch(isLoading(false));
            return response.data;
        } catch (error: any) {
            showErrorToast && dispatch(handleError(error));
            dispatch(isLoading(false));
            dispatch(setError({ error: error?.response?.status }));
            return null;
        }
    };

export const getTripBrochure =
    (tripId: number, showLoading = true): AppThunk =>
    async (dispatch) => {
        showLoading && dispatch(isLoading(true));
        try {
            await serverApi.getBrochure(tripId);
            showLoading && dispatch(isLoading(false));
        } catch (error) {
            dispatch(handleError({ message: "Error downloading brochure" }));
        }
        showLoading && dispatch(isLoading(false));
    };

export const getTripUsersXls =
    (tripId: number, showLoading = true): AppThunk =>
    async (dispatch) => {
        showLoading && dispatch(isLoading(true));
        try {
            await serverApi.getTripUsersXlsx(tripId);
            showLoading && dispatch(isLoading(false));
        } catch (error) {
            dispatch(
                handleError({ message: "Error downloading participants file" })
            );
        }
        showLoading && dispatch(isLoading(false));
    };

export const getTripUserPDF =
    (
        tripId: number,
        userId: number,
        userName: string,
        showLoading = true
    ): AppThunk =>
    async (dispatch) => {
        showLoading && dispatch(isLoading(true));
        try {
            await serverApi.getTripUserPDF(tripId, userId, userName);
            showLoading && dispatch(isLoading(false));
        } catch (error) {}
        showLoading && dispatch(isLoading(false));
    };

export const addUsersToTrip =
    (
        tripId: number,
        users: Array<{ email: string; role: UserRoleOnTrip }>
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.addUsersToTrip(tripId, users);
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const addFileToTrip =
    (tripId: number, formData: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.addFileToTrip(tripId, formData);
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const updateOrganizationOnTrip =
    (
        tripId: number,
        organizationId: number,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.updateOrganizationToTrip(tripId, organizationId);
            if (response.data) {
                const trip = getState().trip.selectedTrip;
                const organization = await serverApi.getOrganization(organizationId);
                dispatch(
                    setSelectedTrip({
                        selectedTrip: {
                            ...trip,
                            organization: organization.data[0],
                        },
                    })
                );
            }
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const editTripFile =
    (tripId: number, fileId: number, formData: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.editTripFile(
                tripId,
                fileId,
                formData
            );
            dispatch(
                setSelectedTripFile({
                    selectedTripFile: response.data,
                })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const getTripFile =
    (tripId: number, fileId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getTripFile(tripId, fileId);
            dispatch(
                setSelectedTripFile({
                    selectedTripFile: response.data,
                })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const getTripActivities =
    (tripId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setSelectedTripActivities({
                    selectedTripActivities: null,
                })
            );
            const response = await serverApi.getActivities(tripId);
            dispatch(
                setSelectedTripActivities({
                    selectedTripActivities: response.data,
                })
            );
            return;
        } catch (error) {
            dispatch(handleError(error));
            return;
        }
    };

export const getTripRequirement =
    (tripId: number, requirementId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setSelectedTripRequirement({
                    selectedTripRequirement: null,
                })
            );
            dispatch(isLoading(true));
            const response = await serverApi.getTripRequirement(
                tripId,
                requirementId
            );
            dispatch(
                setSelectedTripRequirement({
                    selectedTripRequirement: response.data,
                })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const getTripRequirementUsers =
    (tripId: number, requirementId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(
                setSelectedTripRequirementUsers({
                    selectedTripRequirementUsers: null,
                })
            );
            const response = await serverApi.getTripRequirementUsers(
                tripId,
                requirementId
            );
            dispatch(
                setSelectedTripRequirementUsers({
                    selectedTripRequirementUsers: response.data,
                })
            );
            return;
        } catch (error) {
            dispatch(handleError(error));
            return;
        }
    };

export const getTripRequirementFiles =
    (tripId: number, requirementId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getTripRequirementFiles(
                tripId,
                requirementId
            );
            dispatch(
                setSelectedTripRequirementFiles({
                    selectedTripRequirementFiles: response.data,
                })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

// TRIP FILES
export const getTripFiles =
    (
        tripId: any,
        take: number = 10,
        skip: number = 0,
        newCurrentPage: number,
        search: string = ""
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripFiles;
        try {
            dispatch(
                setSelectedTripFiles({
                    selectedTripFiles: {
                        count: currentState?.count,
                        data: [],
                        isLoading: true,
                        take: take,
                        skip: skip,
                        currentPage: newCurrentPage,
                        totalPages: currentState?.totalPages,
                    },
                })
            );
            const response = await serverApi.getTripFiles(
                tripId,
                take,
                skip,
                search
            );
            const selectedTripFiles = response.data;
            dispatch(
                setSelectedTripFiles({
                    selectedTripFiles: selectedTripFiles,
                })
            );
        } catch (error) {
            dispatch(
                setSelectedTripFiles({
                    selectedTripFiles: currentState,
                })
            );
            dispatch(handleError(error));
        }
    };

export const getTripFilesMobile =
    (
        tripId: any,
        take: number = 10,
        skip: number = 0,
        newCurrentPage: number,
        search: string = ""
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripFiles;
        const resetData = !skip || Number(skip) === 0;
        try {
            const initPayload = !resetData
                ? { ...currentState, isLoading: true }
                : {
                      count: currentState?.count,
                      data: [],
                      isLoading: true,
                      take: take,
                      skip: skip,
                      currentPage: newCurrentPage,
                      totalPages: currentState?.totalPages,
                  };
            dispatch(
                setSelectedTripFiles({
                    selectedTripFiles: initPayload,
                })
            );
            const response = await serverApi.getTripFiles(
                tripId,
                take,
                skip,
                search
            );
            const selectedTripFiles = response.data;

            const finalPayload = !resetData
                ? {
                      ...selectedTripFiles,
                      data: currentState.data.concat(selectedTripFiles?.data),
                  }
                : selectedTripFiles;

            dispatch(
                setSelectedTripFiles({
                    selectedTripFiles: finalPayload,
                })
            );
        } catch (error) {
            dispatch(
                setSelectedTripFiles({
                    selectedTripFiles: currentState,
                })
            );
            dispatch(handleError(error));
        }
    };

export const getActivity =
    (tripId: any, ActivityId: any): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripActivity;
        try {
            dispatch(
                setSelectedTripActivity({
                    selectedTripActivity: null,
                })
            );
            dispatch(isLoading(true));
            const response = await serverApi.getActivity(tripId, ActivityId);
            const selectedTripActivity = response.data;
            dispatch(
                setSelectedTripActivity({
                    selectedTripActivity: selectedTripActivity,
                })
            );
            dispatch(isLoading(false));
        } catch (error: any) {
            dispatch(
                setSelectedTripActivity({
                    selectedTripActivity: {
                        ...currentState,
                        error: error?.message || error,
                    },
                })
            );
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const deleteActivity =
    (tripId: any, ActivityId: any): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripActivity;
        try {
            dispatch(isLoading(true));
            await serverApi.removeActivity(tripId, ActivityId);
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(
                setSelectedTripActivity({
                    selectedTripActivity: currentState,
                })
            );
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const getTripImages =
    (
        tripId: any,
        take: number = 10,
        skip: number = 0,
        search: string = ""
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripImages;
        try {
            dispatch(
                setSelectedTripImages({
                    selectedTripImages: {
                        count: currentState?.count,
                        data: [],
                        isLoading: true,
                        take: take,
                        skip: skip,
                        totalPages: currentState?.totalPages,
                    },
                })
            );
            const response = await serverApi.getTripImages(
                tripId,
                take,
                skip,
                search
            );
            const selectedTripImages = response.data;
            dispatch(
                setSelectedTripImages({
                    selectedTripImages: selectedTripImages,
                })
            );
            return selectedTripImages;
        } catch (error) {
            dispatch(
                setSelectedTripImages({
                    selectedTripImages: currentState,
                })
            );
            dispatch(handleError(error));
        }
    };

export const getTripRequirements =
    (
        tripId: any,
        take: number = 10,
        skip: number = 0,
        newCurrentPage: number,
        search: string = ""
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripRequirements;
        try {
            const initPayload = {
                count: currentState?.count,
                data: [],
                isLoading: true,
                take: take,
                skip: skip,
                currentPage: newCurrentPage,
                totalPages: currentState?.totalPages,
            };
            dispatch(
                setSelectedTripRequirements({
                    selectedTripRequirements: initPayload,
                })
            );
            const response = await serverApi.getTripRequirements(
                tripId,
                take,
                skip,
                search
            );
            const selectedTripRequirements = response.data;
            dispatch(
                setSelectedTripRequirements({
                    selectedTripRequirements: selectedTripRequirements,
                })
            );
        } catch (error) {
            dispatch(
                setSelectedTripRequirements({
                    selectedTripRequirements: currentState,
                })
            );
            dispatch(handleError(error));
        }
    };

export const getTripRequirementsMobile =
    (
        tripId: any,
        take: number = 10,
        skip: number = 0,
        newCurrentPage: number,
        search: string = ""
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripRequirements;
        try {
            const resetData = !skip || skip === 0;
            const initPayload = !resetData
                ? { ...currentState, isLoading: true }
                : {
                      count: currentState?.count,
                      data: [],
                      isLoading: true,
                      take: take,
                      skip: skip,
                      currentPage: newCurrentPage,
                      totalPages: currentState?.totalPages,
                  };
            dispatch(
                setSelectedTripRequirements({
                    selectedTripRequirements: initPayload,
                })
            );
            const response = await serverApi.getTripRequirements(
                tripId,
                take,
                skip,
                search
            );
            const selectedTripRequirements = response.data;
            const finalPayload = !resetData
                ? {
                      ...selectedTripRequirements,
                      data: currentState.data.concat(
                          selectedTripRequirements?.data
                      ),
                  }
                : selectedTripRequirements;
            dispatch(
                setSelectedTripRequirements({
                    selectedTripRequirements: finalPayload,
                })
            );
        } catch (error) {
            dispatch(
                setSelectedTripRequirements({
                    selectedTripRequirements: currentState,
                })
            );
            dispatch(handleError(error));
        }
    };

export const addImageToTrip =
    (tripId: number, formData: any): AppThunk =>
    async (dispatch) => {
        try {
            await serverApi.addImageToTrip(tripId, formData);

            return;
        } catch (error) {
            dispatch(handleError(error));
            return;
        }
    };

export const editGalleryImage =
    (
        tripId: number,
        imageId: number,
        description: any
    ): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripImages;
        try {
            dispatch(isLoading(true));
            const image = await serverApi.editTripImageDescription(
                tripId,
                imageId,
                description
            );
            setSelectedTripImages({
                selectedTripImages: {
                    ...currentState,
                    data: currentState.data.map((i) => {
                        return i.id === imageId ? image.data : i;
                    }),
                },
            });
            dispatch(isLoading(false));
            return image.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const updateUserOnTrip =
    (tripId: number, email: string, fields: object): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.updateUserOnTrip(
                tripId,
                email,
                fields
            );
            dispatch(
                selectedTripMembersUpdate({
                    userEmail: email,
                    data: response.data,
                })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const removeUserFromTrip =
    (tripId: number, user: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeUserFromTrip(tripId, user?.userEmail)
            dispatch(selectedTripMembersRemove({ userEmail: user?.userEmail }));
            const payloadUser = mapUser({
                uid: user?.uid,
                email: user?.userEmail,
                ...user.user,
            });
            if (user?.uid) {
                await removeUserFromGroupConversation(
                    tripId,
                    payloadUser as any
                );
            }
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const updateTripOwner =
    (tripId: number, email: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.changeTripOwner(tripId, email);
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const removeFileFromTrip =
    (tripId: number, fileId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeFileFromTrip(tripId, fileId);
            dispatch(removeTripFile({ fileId: fileId }));
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const removeImagesFromTrip =
    (tripId: number, filesId: Array<number>): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeImageFromTrip(tripId, filesId);
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const addRequirementToTrip =
    (tripId: number, data: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.addRequirementToTrip(tripId, data);
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const updateTripRequirement =
    (tripId: number, requirementId: number, data: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.updateTripRequirement(tripId, requirementId, data);
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const removeRequirementFromTrip =
    (tripId: number, requirementId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.removeTripRequirement(tripId, requirementId);
            dispatch(
                removeSelectedTripRequirement({ requirementId: requirementId })
            );
            dispatch(isLoading(false));

            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const addActivityToTrip =
    (tripId: number, activity: any): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch(isLoading(true));

                const response = await serverApi.addActivityToTrip(
                    tripId,
                    activity
                );
                dispatch(
                    updateActivityForm({
                        activityForm: { showExitDialog: false },
                    })
                );
                dispatch(
                    setLibraryElementToAddToTrip({ libraryElementToAddToTrip: null })
                );
                dispatch(isLoading(false));
                resolve(response.data);
            } catch (error) {
                dispatch(isLoading(false));
                dispatch(handleError(error));
                reject(error);
            }
        });
    };

export const duplicateTripActivity =
    (tripId: number, activityId: number, data: any): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch(isLoading(true));

                const response = await serverApi.duplicateTripActivity(
                    tripId,
                    activityId,
                    data
                );
                dispatch(isLoading(false));
                resolve(response.data);
            } catch (error) {
                dispatch(isLoading(false));
                dispatch(handleError(error));
                reject(error);
            }
        });
    };

export const updateTripActivity =
    (tripId: number, activityId: number, formData: any): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.updateActivity(
                tripId,
                activityId,
                formData
            );
            const payload = {
                ...response.data,
            };
            dispatch(
                setSelectedTripActivity({ selectedTripActivity: payload })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const addActivityToLibrary =
    (tripId: number, activityId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addActivityToLibrary(
                tripId,
                activityId,
            );
            const payload = {
                ...response.data,
            };
            dispatch(
                setSelectedTripActivity({ selectedTripActivity: payload })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const addLocalPartnerToTrip =
    (tripId: number, payload: ITripLocalPartner): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.addLocalPartnerToTrip(tripId, payload);
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const getTripMembers =
    (
        tripId: number,
        take: number = 10,
        skip: number = 0,
        newCurrentPage: number,
        search: string = "",
        status: UserStatusOnTrip | "" | null | undefined,
        role: UserRoleOnTrip | "" | null | undefined
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripMembers;
        try {
            dispatch(
                setSelectedTripMembers({
                    selectedTripMembers: {
                        count: getState().organization
                            .selectedOrganizationMembers.count,
                        data: [],
                        isLoading: true,
                        take: take,
                        skip: skip,
                        currentPage: newCurrentPage,
                        totalPages: currentState?.totalPages,
                    },
                })
            );
            const response = await serverApi.getTripMembers(
                tripId,
                take,
                skip,
                search,
                status,
                role || undefined
            );
            const selectedTripMembers = response.data;
            dispatch(
                setSelectedTripMembers({
                    selectedTripMembers: selectedTripMembers,
                })
            );
        } catch (error) {
            dispatch(
                setSelectedTripMembers({
                    selectedTripMembers: currentState,
                })
            );
            dispatch(handleError(error));
        }
    };

export const getTripMembersMobile =
    (
        tripId: number,
        take: number = 10,
        skip: number = 0,
        newCurrentPage: number,
        search: string = "",
        status: UserStatusOnTrip | "" | null | undefined,
        role: UserRoleOnTrip | "" | null | undefined
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedTripMembers;
        const resetData = !skip || Number(skip) === 0;
        try {
            const initPayload = resetData
                ? {
                      count: getState().organization.selectedOrganizationMembers
                          .count,
                      data: [],
                      isLoading: true,
                      take: take,
                      skip: skip,
                      currentPage: newCurrentPage,
                      totalPages: currentState?.totalPages,
                  }
                : { ...currentState, isLoading: true };
            dispatch(
                setSelectedTripMembers({
                    selectedTripMembers: initPayload,
                })
            );
            const response = await serverApi.getTripMembers(
                tripId,
                take,
                skip,
                search,
                status,
                role || undefined
            );
            const selectedTripMembers = response.data;
            const finalPayload = resetData
                ? selectedTripMembers
                : {
                      ...selectedTripMembers,
                      data: currentState.data?.concat(selectedTripMembers.data),
                      isLoading: false,
                  };
            dispatch(
                setSelectedTripMembers({
                    selectedTripMembers: finalPayload,
                })
            );
        } catch (error) {
            dispatch(
                setSelectedTripMembers({
                    selectedTripMembers: currentState,
                })
            );
            dispatch(handleError(error));
        }
    };

export const getActivityReview =
    (tripId: number, activityId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const review = await serverApi.getActivityReview(
                tripId,
                activityId
            );
            dispatch(
                setActivityReview({ activityReview: review?.data || {} })
            );
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const addTripActivityReview=
    (
        tripId: number,
        activityId: number,
        data: any,
        reviewId?: number
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const payload = { ...data };

            let response;
            if (reviewId) {
                response = await serverApi.updateTripActivityReview(
                    tripId,
                    activityId,
                    reviewId,
                    payload
                );
            } else {
                response = await serverApi.addActivityReview(
                    tripId,
                    activityId,
                    payload,
                );
            }

            await dispatch(getActivityReview(tripId, activityId));
            dispatch(isLoading(false));
            return response?.data?.id;
        } catch (error) {
            dispatch(isLoading(false));

            dispatch(handleError(error));
            return;
        }
    };

export const getTripUserReviews =
    (
        tripId: number,
        userId: number,
        take: number = 10,
        skip: number = 0
    ): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().trip.selectedUserOnTripReviews || {};
        try {
            dispatch(
                setSelectedUserOnTripReviews({
                    reviews: { ...currentState, isLoading: true },
                })
            );
            const response = await serverApi.getTripUserReviews(
                tripId,
                userId,
                take,
                skip
            );
            const { data, ...others } = response.data;
            const reviews = data.map((r: any) => normalizeReviews(r));
            dispatch(
                setSelectedUserOnTripReviews({
                    reviews: {
                        ...others,
                        data: currentState.data
                            ? currentState.data.concat(reviews)
                            : reviews,
                        isLoading: false,
                    },
                })
            );
            return response?.data;
        } catch (error) {
            dispatch(
                setSelectedUserOnTripReviews({
                    reviews: { ...currentState, isLoading: false },
                })
            );
            dispatch(handleError(error));
        }
    };

export const uploadTempFile = async (
    file: any,
    id: any,
    onProgress?: any,
    cancelTokenSource?: CancelTokenSource
) => {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    const response = await serverApi.addTempFile(
        formData,
        id,
        onProgress,
        cancelTokenSource
    );
    return response.data;
};

export const acceptInvite =
    (tripId: number, userEmail: string): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            await dispatch(
                updateUserOnTrip(tripId, userEmail, {
                    status: UserStatusOnTrip.CONFIRMED,
                })
            );

            const selectedTrip = getState().trip.selectedTrip;
            const usersOnTripNew = selectedTrip?.users?.map((u: any) =>
                u.userEmail === userEmail
                    ? {
                          ...u,
                          status: UserStatusOnTrip.CONFIRMED,
                      }
                    : u
            );
            dispatch(
                setSelectedTrip({
                    ...selectedTrip,
                    users: usersOnTripNew,
                    ...getUserPermissionsOnTrip(
                        { ...selectedTrip, users: usersOnTripNew },
                        userEmail
                    ),
                })
            );
            dispatch(markTripInvitesAsRead({ tripId, email: userEmail }));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
            return;
        }
    };

export const rejectInvite =
    (tripId: number, userEmail: string): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            const payload = {
                status: UserStatusOnTrip.REJECTED,
            };
            await serverApi.updateUserOnTrip(tripId, userEmail, payload);

            const selectedTrip = getState().trip?.selectedTrip;
            const usersOnTripNew = selectedTrip?.users?.map((u: any) =>
                u.userEmail !== userEmail
                    ? u
                    : { ...u, status: UserStatusOnTrip.REJECTED }
            );
            dispatch(
                setSelectedTrip({
                    ...selectedTrip,
                    users: usersOnTripNew,
                    ...getUserPermissionsOnTrip(
                        { ...selectedTrip, users: usersOnTripNew },
                        userEmail
                    ),
                })
            );

            dispatch(markTripInvitesAsRead({ tripId, email: userEmail }));
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
            return;
        }
    };

export const resendTripUserInvitation =
    (tripId: number, email: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.resendInvitationUserToTrip(tripId, email);
            dispatch(isLoading(false));
            sendToast(TOAST_TYPE.SUCCESS, "Invitation resent");
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const copyTripUserInvitationLink =
    (): AppThunk => async (dispatch, getState) => {
        try {
            const tripShareableLink =
                getState().trip?.selectedTrip?.tripShareableLink;
            if (tripShareableLink) {
                navigator.clipboard.writeText(tripShareableLink);
                sendToast(TOAST_TYPE.SUCCESS, "Copied to clipboard");
            } else sendToast(TOAST_TYPE.WARNING, "Can't get the invite link");
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const submitActivityFormStep =
    (
        activityForm: any,
        activityType: string,
        currentStepId: string
    ): AppThunk =>
    (dispatch, getState) => {
        try {
            dispatch(updateActivityForm({ activityForm: activityForm }));
            const nextStep = getActivityFormNextStep(
                activityType as ActivityType,
                currentStepId
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
            dispatch(setActivityFormCurrentStep({ step: nextStep?.id }));
        } catch (error) {
            dispatch(handleError(error));
        }
    };

export const backFormStep =
    (activityType: string, currentStepId: string): AppThunk =>
    (dispatch, getState) => {
        try {
            const nextStep = getActivityFormPrevStep(
                activityType as ActivityType,
                currentStepId
            );
            dispatch(setActivityFormCurrentStep({ step: nextStep?.id }));
        } catch (error) {
            dispatch(handleError(error));
        }
    };

export const addTripRequirementFile =
    (
        tripId: number,
        requirementId: number,
        description: string,
        files: any,
        userEmail?: string,
        sensitiveInformation?: boolean,
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            await serverApi.addRequirementFile(
                tripId,
                requirementId,
                files,
                description,
                userEmail,
                sensitiveInformation,
            );

            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
            return;
        }
    };

export const blockUnblockUserFromTrip =
    (tripId: number, userEmail: string, status: UserStatusOnTrip): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        try {
            dispatch(isLoading(true));
            await serverApi.updateUserOnTrip(tripId, userEmail, {
                status: status,
            });
            dispatch(
                selectedTripMembersUpdate({
                    userEmail: userEmail,
                    data: { status: status },
                })
            );
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
            return;
        }
    };

export const initializeActivityForm =
    (): AppThunk => async (dispatch, getState) => {
        try {
            const libraryElementToAddToTrip =
                getState().organization.libraryElementToAddToTrip;
            dispatch(
                setActivityForm({
                    activityForm: {
                        showExitDialog: true,
                        ...(libraryElementToAddToTrip
                            ? libraryElementToAddToTrip
                            : {}),
                    },
                })
            );
            if (libraryElementToAddToTrip) {
                switch (libraryElementToAddToTrip.type) {
                    case ActivityType.HOTEL:
                        dispatch(
                            setActivityFormCurrentStep({
                                step: ACTIVITY_FORM_STEPS_IDS.HOTEL_INFO
                            })
                        );
                        break;
                    case ActivityType.RESTAURANT:
                        dispatch(
                            setActivityFormCurrentStep({
                                step: ACTIVITY_FORM_STEPS_IDS.RESTAURANT_INFO
                            })
                        );
                        break;
                    case ActivityType.OTHER:
                        dispatch(
                            setActivityFormCurrentStep({
                                step: ACTIVITY_FORM_STEPS_IDS.OTHER_INFO
                            })
                        );
                        break;
                    default:
                        break;
                }
                dispatch(setLibraryElementToAddToTrip({ libraryElementToAddToTrip: null }));
            }
        } catch (error) {
            dispatch(handleError(error));
        }
    };
export default tripSlice.reducer;
