import { ReactNode } from 'react';

const InsightInputContainer = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <div className="col-span-6 lg:col-span-3">
            {children}
        </div>
    );
}

export default InsightInputContainer;