import Icon from "../../assets/svg/Icon";
import ListWithPagination from "../ListWithPagination";
import {
    ComplaintsDotMenuKeys,
} from "../../constants/admin";
import { IPaginatedData } from "../../types/dataTypes";
import StatusBadgeComponent from "../StatusBadge/StatusBadgeComponent";

interface ComplaintsAdminResponsiveListPropsI {
    complaints: IPaginatedData;
    handlePaginatorChange: Function;
    handleClickDotsMenu: Function;
}

const ComplaintsAdminResponsiveList = (
    props: ComplaintsAdminResponsiveListPropsI
) => {
    const { complaints, handlePaginatorChange, handleClickDotsMenu } = props;

    const renderComplaints = ({ item: complaint, index }: any) => {
        return (
            <div
                className={`mb-4 pt-4 cursor-pointer ${
                    !!index ? "border-t border-neutral-100" : ""
                }`}
                onClick={() => {
                    handleClickDotsMenu(ComplaintsDotMenuKeys.view, complaint);
                }}
            >
                <div className="flex flex-row text-left items-center">
                    <span className="text-base font-semibold flex-grow truncate">
                       {complaint?.typeFormatted} - {complaint?.title}
                    </span>
                    <Icon
                        className="w-[24px] mr-1 text-neutral-300"
                        icon="ChevronRightIcon"
                    />
                </div>

                <div className="flex flex-row text-left my-2">
                    <StatusBadgeComponent status={complaint?.status} />
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="CalendarMonthIcon"
                    />
                    <span className="truncate text-xs">{complaint?.date}</span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="ParticipantsIcon"
                    />
                    <span className="truncate text-xs">
                        {complaint?.userFormatted}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <ListWithPagination
            items={complaints}
            renderItem={renderComplaints}
            handlePaginatorChange={handlePaginatorChange}
            itemKey={(complaint: any) => `complaint_${complaint?.id}`}
        />
    );
};

export default ComplaintsAdminResponsiveList;
