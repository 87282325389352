import * as yup from "yup";
import { PAGE_SECTIONS } from "../../../../../../../../constants";
import {
    addHotelContactInsight,
    removeHotelContactInsight,
} from "../../../../../../../hotelInsight/hotelInsightSlice";
import { FormContactItem } from "./FormContactItem";
import { ButtonPlus } from "../../../../../../../../components/ButtonPlus";
import { useInsightState } from "../../../../../../../../hooks/useInsightState";
import InsightHeader from "../InsightHeader";
import { IContactItem, ListInsightContacts } from "./ListInsightContacts";
import InsightContactDetails from "./InsightContactDetails";
import ModalConfirmation from "../../../../../../../../components/Modal/ConfirmationModal";
import { useState } from "react";
import Icon from "../../../../../../../../assets/svg/Icon";

const schema = yup
    .object({
        comments: yup.string().max(5000).notRequired(),
        name: yup.string().max(500).required("This field is required"),
        type: yup.string().max(500).required("This field is required"),
        title: yup.string().max(500).required("This field is required"),
        email: yup.string().max(500).notRequired(),
        phone: yup.string().max(500).notRequired(),
    })
    .required("This field is required");

interface IInsightContactViewProps {
    insightKey: "hotelInsight" | "restaurantInsight";
    currentSection: PAGE_SECTIONS;
    addContact: (
        tripId: number,
        activityId: number,
        insightId: number,
        payload: any
    ) => any;
    removeContact: (
        tripId: number,
        activityId: number,
        insightId: number,
        contactId: number
    ) => any;
}

export default function InsightContactView({
    insightKey,
    currentSection,
    addContact,
    removeContact,
}: IInsightContactViewProps) {
    const {
        activeItem,
        showForm,
        activityInsight,
        activityId,
        tripId,
        control,
        dispatch,
        handleCancel,
        handleEditItem,
        handleShowDetails,
        handleClickNewInsightItem,
        handleChangeType,
        register,
        errors,
        handleSubmit,
        isValid,
        showDetails,
        isDirty,
        ...insightState
    } = useInsightState({
        initialItem: null,
        schema,
        currentSection,
    });

    const [deleteInsight, setDeleteInsight] = useState(false);

    const listContacts = insightState?.[insightKey]?.contactInsights || [];

    const handleSubmitForm = async (data: any) => {
        const { images, ...rest } = data;
        const payload = {
            ...rest,
            phone: {
                phone: data.phone || undefined,
            },
            mobile: {
                mobile: data.mobile || undefined,
            },
        };
        await dispatch(
            addContact(
                Number(tripId),
                Number(activityId),
                activityInsight?.id,
                payload
            )
        );
        handleCancel();
    };

    const handleEditContact = (item: any = {}) => {
        const newContact = {
            ...item,
            phone: item.phone?.phone || "",
            mobile: item.mobile?.mobile || "",
        };
        handleEditItem(newContact);
    };

    const handleRemoveInsight = (itemId: number) => {
        dispatch(
            removeContact(
                Number(tripId),
                Number(activityId),
                activityInsight?.id,
                itemId
            )
        );
    };

    return (
        <div className="flex flex-col text-left">
            {(!!showForm || !!showDetails) && (
                <div className="flex-grow text-left text-lg font-semibold text-neutral-900 mb-4">
                    <InsightHeader
                        title={"Contact Details"}
                        showEditButton={showDetails}
                        onEdit={() => {
                            handleEditContact(activeItem);
                        }}
                        onBack={() => handleCancel()}
                        backButtonLabel="Back to contacts"
                    />
                </div>
            )}
            {!showForm && !showDetails && (
                <>
                    <InsightHeader title="Contacts" showEditButton={false} />
                    <ListInsightContacts
                        list={listContacts}
                        handleEdit={handleEditContact}
                        handleShowDetails={handleShowDetails}
                        activeItem={activeItem as IContactItem}
                    />
                    <div className="py-4">
                        <ButtonPlus
                            onClick={handleClickNewInsightItem}
                            label="Add contact"
                            // showButton={activeItem?.id || !showForm}
                            showButton={!showForm}
                        />
                    </div>
                </>
            )}
            {showDetails && <InsightContactDetails contact={activeItem} />}
            {showDetails && (
                <>
                    <div
                        className="w-fit flex flex-row items-center mt-8 text-base font-semibold text-red-500 hover:text-red-600 cursor-pointer"
                        onClick={() => {
                            setDeleteInsight(true);
                        }}
                    >
                        <Icon className="w-[24px]" icon="TrashIcon" />
                        <div>Remove Contact</div>
                    </div>
                    <ModalConfirmation
                        open={!!deleteInsight}
                        title="Are you sure?"
                        description={`You are about to remove this item`}
                        handleCancel={() => {
                            setDeleteInsight(false);
                        }}
                        handleSubmit={() => {
                            handleRemoveInsight(activeItem!.id);
                            handleCancel();
                        }}
                    />
                </>
            )}
            {showForm && (
                <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(handleSubmitForm)}
                >
                    <FormContactItem
                        isDirty={isDirty}
                        control={control}
                        register={register}
                        errors={errors}
                        isValid={isValid}
                        showForm={showForm}
                        handleCancel={handleCancel}
                        activeItem={activeItem}
                        setType={handleChangeType}
                    />
                </form>
            )}
        </div>
    );
}
