import Icon from "../../assets/svg/Icon";
import placeholder from "../../assets/images/placeholder.png";

interface OrganizationItemProps {
    organization: any;
    checked?: boolean;
    handleClick: () => void;
}

export const OrganizationItem = ({
    organization,
    checked = false,
    handleClick,
}: OrganizationItemProps) => {
    return (
        <div
            className={`flex items-center justify-between border rounded-lg p-4 cursor-pointer ${
                checked
                    ? "border-orange-500 bg-[#FDF2EE]"
                    : "hover:border-neutral-300 border-gray-300 bg-neutral-50"
            }`}
            onClick={handleClick}
        >
            <div className="flex items-center">
                <img
                    src={organization.image_thumb || placeholder}
                    alt={`${organization.name} Logo`}
                    className="h-12 w-12 rounded-md mr-4 object-cover"
                />
                <div className="flex flex-col justify-between flex-grow">
                    <span className="font-semibold text-md text-neutral-800">
                        {organization.name}
                    </span>
                    <span className="text-xs font-normal text-neutral-400">
                        {organization.type}
                    </span>
                </div>
            </div>

            <div>
                {checked && (
                    <Icon
                        className="w-[32px] text-orange-600"
                        icon={"CheckCircleIcon"}
                    />
                )}
            </div>
        </div>
    );
};
