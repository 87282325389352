import { useCallback } from "react";
import ImageGalleryItem from "./ImageGalleryItem";
import Skeleton from "react-loading-skeleton";

const ImageGalleryGrid = (props: any) => {
    const {
        images,
        onImageClick,
        onImageDelete,
        loading,
        selectionMode,
        selectedImages,
    } = props || {};

    const imageRenderer = useCallback(
        (image) => (
            <ImageGalleryItem
                onImageClick={onImageClick}
                onImageDelete={onImageDelete}
                photo={image}
                className={"w-[250px] h-[350px] object-cover"}
                selectionMode={selectionMode}
                selectedImages={selectedImages}
                selected={!!selectedImages?.find((i: any) => image.id === i.id)}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectionMode, selectedImages]
    );

    return (
        <div className="flex flex-row flex-wrap items-center mx-auto max-w-[250px] lg:max-w-[750px] md:max-w-[500px]">
            {images?.map((img: any) => (
                <div className="w-[250px] h-[350px]" key={img?.src}>
                    {imageRenderer(img)}
                </div>
            ))}
            {loading && (
                <div className="w-[250px] rounded-xl">
                    <Skeleton className="!rounded-xl w-[250px] h-[350px]" />
                </div>
            )}
        </div>
    );
};

export default ImageGalleryGrid;
