interface insightDetailsProps {
    insight: any;
}

export default function InsightDetails(props: insightDetailsProps) {
    const { insight } = props;
    return <div>
        {insight.title}
    </div>;
}
