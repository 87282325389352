import { MouseEventHandler, useEffect, useMemo, useState } from "react";
import Icon from "../../assets/svg/Icon";
import { PAGE_SECTIONS, TRIP_SECTIONS } from "../../constants";
import { SidebarOption } from "./SidebarOption";
import { useAppSelector } from "../../app/hooks";
import { selectProfile } from "../authentication/userSlice";
import AdminToast from "../../components/adminToast";

interface ISidebar {
    items: Array<any>;
    basePath: string;
    currentSection: PAGE_SECTIONS | null;
    handleSignOut: MouseEventHandler;
}

function SideBar(props: ISidebar) {
    const { items, basePath, currentSection, handleSignOut } = props;
    const [isTripSection, setIsTripSection] = useState(false);
    const profile = useAppSelector(selectProfile);
    const isAdmin = profile?.isAdmin;

    const isInTripSection = (currentSection: PAGE_SECTIONS | null) => {
        return TRIP_SECTIONS.some((section) => section === currentSection);
    }

    useEffect(() => {
        setIsTripSection(isInTripSection(currentSection))
    }, [currentSection]);

    const renderItem = useMemo(() => {
        return items
            .map((i: any, index: number) => {
                return (
                    <SidebarOption
                        key={`sidebar_${i.key}${index}`}
                        basePath={basePath}
                        icon={i.icon}
                        label={i.label}
                        url={i.url}
                        selected={i.key === currentSection}
                    />
                );
            });
    }, [items, currentSection, basePath, isAdmin]);

    const renderAdminToast = useMemo(() => {
        return isTripSection && isAdmin && <AdminToast />
    }, [isAdmin, isTripSection]);

    return (
        <div className="w-[200px] xl:w-[256px] pt-9 pb-16 overflow-y-auto px-5 space-y-4 h-screen fixed bg-neutral-100 shadow-lg hidden lg:block">
            {renderItem}
            {renderAdminToast}
            {currentSection === PAGE_SECTIONS.PROFILE && (
                <div
                    onClick={handleSignOut}
                    className="absolute flex gap-4 text-neutral-300 text-sm font-normal hover:text-neutral-400 no-underline cursor-pointer justify-center items-center text-center bottom-24"
                >
                    <Icon icon={"LogOutIcon"} className={`w-6 fill-neutral-400`} />
                    <div>Log Out</div>
                </div>
            )}
        </div>
    );
}

export default SideBar;

