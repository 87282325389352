import placeholder from "../../assets/images/placeholder.png";
import { useNavigate } from "react-router";
import Avatar from "../Avatar";
import { IOrganization } from "../../types/IOrganization";
import { useMemo } from "react";
import { UserStatusOnOrganization } from "../../constants";

interface OrganizationsCardProps {
    organization: IOrganization;
}

export const OrganizationsCard = ({ organization }: OrganizationsCardProps) => {
    const navigate = useNavigate();
    const handleClickOrganization = () => {
        navigate(`/organizations/${organization.id}`);
    };

    const membersCount = useMemo(() => {
        return organization?.users.filter((u) => {
            return u.status === UserStatusOnOrganization.CONFIRMED;
        }).length;
    }, [organization?.users]);

    return (
        <div
            className="flex flex-row mt-3 mb-2 p-1 items-center cursor-pointer hover:bg-neutral-100"
            onClick={handleClickOrganization}
        >
            <div className="flex flex-col mr-2">
                <Avatar
                    squared
                    src={
                        organization?.image_thumb
                            ? organization?.image_thumb
                            : placeholder
                    }
                    className="w-10 h-10"
                />
            </div>
            <div className="flex flex-col">
                <div className="flex items-start gap-2">
                    <div className="text-sm font-semibold truncate mt-1">
                        {organization.name}
                    </div>
                </div>
                <span className="text-sm truncate break-words whitespace-pre-wrap self-start">
                    {membersCount} members
                </span>
            </div>
        </div>
    );
};
