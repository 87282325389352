import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Icon from "../../../assets/svg/Icon";
import ButtonSquared from "../../../components/ButtonSquare";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import { ButtonSize } from "../../../constants";
import { selectProfile } from "../../authentication/userSlice";
import {
    acceptInvitation,
    rejectInvitation,
} from "../organizationSlice";

const OrganizationInviteActions: FC<any> = ({ organizationId }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    const { email } = useAppSelector(selectProfile);
    const handleDecline = async () => {
        await dispatch(rejectInvitation(organizationId, email));
        if (modalOpen) toogleOpenModal();
    };
    const handleAccept = async () => {
        await dispatch(acceptInvitation(organizationId, email));
        if (modalOpen) toogleOpenModal();
    };
    const toogleOpenModal = () => {
        setModalOpen((prevState) => {
            return !prevState;
        });
    };

    return (
        <div className="w-full flex col-span-6">
            <ModalConfirmation
                open={modalOpen}
                title="Are you sure?"
                description=""
                handleCancel={toogleOpenModal}
                handleSubmit={handleDecline}
            />
            <div className="mr-4">
                <Icon icon="BellIcon" className="w-5" />
            </div>
            <div className="font-semibold text-left flex-grow">
                You have been invited to join this organization
            </div>
            <div className="gap-x-2 flex">
                <ButtonSquared
                    size={ButtonSize.SMALL}
                    outlined
                    label="Decline"
                    type="button"
                    className="w-[100px]"
                    onClick={toogleOpenModal}
                />
                <ButtonSquared
                    size={ButtonSize.SMALL}
                    type="button"
                    label="Accept"
                    className="w-[100px]"
                    onClick={handleAccept}
                />
            </div>
        </div>
    );
};

export default OrganizationInviteActions;
