import { NotificationsCard } from "../NotificationsCard/NotificationsCard";
import ShowMoreButton from "../ShowMoreButton";

interface NotificationsCardProps {
    notifications: any[];
    isLoading: boolean;
    viewAllClick: any;
}

export const NotificationsList = (props: NotificationsCardProps) => {
    const { notifications, isLoading, viewAllClick } = props;

    if (!notifications || isLoading)
        return (
            <div className="react-loading-skeleton h-full w-full min-h-full shadow-sm rounded-xl">
                {" "}
            </div>
        );

    return (
        <div
            className={`flex flex-col text-left bg-white shadow-sm pb-2 rounded-xl lg:min-h-[432px] flex-1 mb-8`}
        >
            <div className="shadow-card p-4 pb-0 rounded-xl mb-0 flex-grow">
                <div className="font-semibold text-lg mb-2">
                    Latest notifications
                </div>
                {!notifications?.length && (
                    <div className="my-6 text-center text-sm text-neutral-400">
                        There are no notifications to show.
                    </div>
                )}
                {notifications
                    ?.slice(0, 4)
                    .map((notification: any, index: number) => (
                        <NotificationsCard
                            key={index}
                            notification={notification}
                        />
                    ))}
            </div>
            <ShowMoreButton onClick={viewAllClick} />
        </div>
    );
};
