import Icon from "../../../../assets/svg/Icon";
import StatusBadgeComponent from "../../../../components/StatusBadge/StatusBadgeComponent";
import { DATE_FORMAT, formatDate, getStatusFromFiles } from "../../../../helpers";

export default function TripRequirementsListItem({
    requirement,
    handleViewClick,
    handleAddFileClick,
    handleRemoveClick,
    canEdit,
    meOnTrip,
    ...props
}: any) {
    return (
        <div
            className={
                "w-full flex items-center justify-between cursor-pointer hover:bg-gray-50 border-b border-b-gray-300 pb-2 mb-2"
            }
            onClick={() => {
                handleViewClick(Number(requirement.id));
            }}
            key={requirement.id}
        >
            <div className="text-left break-all whitespace-normal">
                <div className="font-semibold text-base text-gray-800">
                    {requirement.name}
                </div>

                <div className="whitespace-normal text-left text-gray-600">
                    {formatDate(requirement.deadline, DATE_FORMAT)}
                </div>
            </div>

            {!canEdit && (
                <div className="flex gap-2 justify-start text-left items-center">
                    <StatusBadgeComponent
                        status={getStatusFromFiles(requirement.files)}
                    />
                </div>
            )}

            {canEdit && (
                <div className="">
                    <Icon
                        tabIndex={0}
                        icon={"TrashIcon"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleRemoveClick(requirement);
                        }}
                        className="w-6 h-6 cursor-pointer text-gray-600  hover:text-gray-900"
                    />
                </div>
            )}
        </div>
    );
}
