import { mdiCellphoneLink } from "@mdi/js";
import Icon from "../../../../assets/svg/Icon";
import DotsMenu from "../../../../components/DotsMenu";
import ListWithPagination from "../../../../components/ListWithPagination";
import { ElementBasicAdminDotMenuOptions } from "../../../../constants/admin";
import { IAllSupportModules } from "./AllSupportModulesTable";

const ListItem = ({
    item: video,
    handleClickDotsMenu,
}: {
    item: {
        id: number;
        title: string;
        sectionLabel: string;
        platform: string;
    };
    handleClickDotsMenu: (key: string, video: any) => void;
}) => {
    return (
        <div className="border-b border-b-gray-300 py-4">
            <div className="flex justify-between">
                <div
                    className="text-base font-semibold text-left max-w-[250px] truncate"
                    title={video?.title}
                >
                    {video?.title}
                </div>
                <div className="text-left">
                    <DotsMenu
                        options={ElementBasicAdminDotMenuOptions}
                        handleClickMenu={(k: string) => {
                            handleClickDotsMenu(k, video);
                        }}
                    />
                </div>
            </div>

            <div className="flex flex-row text-left mt-1">
                <Icon
                    className="w-[12px] mr-1 text-neutral-400"
                    icon="ModuleIcon"
                />
                <span className="truncate text-xs">{video?.sectionLabel}</span>
            </div>
            <div className="flex flex-row text-left mt-1">
                <Icon
                    className="w-[12px] mr-1 text-neutral-400"
                    materialIcon={mdiCellphoneLink}
                />

                <span className="truncate text-xs capitalize">
                    {video?.platform?.toLowerCase() || "-"}
                </span>
            </div>
        </div>
    );
};

export default function AllTripsList({
    handleClickDotsMenu,
    handlePaginatorChange,
    supportModules,
    loading,
}: IAllSupportModules) {

    return (
        <>
            <ListWithPagination
                renderItem={(props: any) => (
                    <ListItem
                        handleClickDotsMenu={handleClickDotsMenu}
                        {...props}
                    />
                )}
                items={supportModules}
                handlePaginatorChange={handlePaginatorChange}
                itemKey={(video: { id: number }) => {
                    return `supportModuleItem_${video?.id}`;
                }}
            />
        </>
    );
}
