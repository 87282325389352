import axios, { AxiosInstance } from 'axios';
import config from '../config';

interface CommonHeaderProperties {
    Authorization?: string;
    [key: string]: any;
}

interface SendMessagePayload {
    content: string;
    conversationId: string;
    mediaUrl?: string;
    mediaType?: string;
  }

class ChatApiService {
  api: AxiosInstance;
  private static singleton: ChatApiService;
  private authToken: string;

  constructor() {
    this.api = axios.create({
      baseURL: config.chatApiUrl,
    });
    this.authToken = "";
  }

  static getInstance(): ChatApiService {
    if (this.singleton) {
      return this.singleton;
    } else {
      this.singleton = new ChatApiService();
      return this.singleton;
    }
  }

  setAuthToken(token: string) {
    this.authToken = token;
  }

  withAuthHeaders(newToken?: string): Object {
    return {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${newToken || this.authToken}`,
      },
    };
  }

  withHeaders(
    token: string | undefined,
    extraHeaders: object | undefined
    ): Object {
        let headers: CommonHeaderProperties = {
            ...extraHeaders,
        };
        if (token) headers["Authorization"] = `Bearer ${token}`;

        return {
            headers: headers,
        };
    }

  async getConversations() {
    const response = await this.api.get("/conversations", this.withAuthHeaders());
    return response.data;
  }

  async getMessages(conversationId: string) {
    const response = await this.api.get(`/messages/${conversationId}`, this.withAuthHeaders());
    return response.data;
  }

  async sendMessage(payload: SendMessagePayload) {
    const response = await this.api.post('/messages', payload, this.withAuthHeaders())
    return response.data;
  };

  async getSocialUsers() {
    const response = await this.api.get("/social", this.withAuthHeaders());
    return response;
  }

  async blockUser( isBlockedBy: string, conversationId: string) {
    const payload = {
      isBlockedBy,
    };
    const response = await this.api.patch(`/conversations/${conversationId}`, payload, this.withAuthHeaders());
    return response.data;
  }

  async unBlockUser( conversationId: string) {
    const payload = {
      isBlockedBy: null,
    };
    const response = await this.api.patch(`/conversations/${conversationId}`, payload, this.withAuthHeaders());
    return response.data;
  }


  async acceptUser(conversationId: string) {
    const payload = {
      isFriend : true,
    };
    const response = await this.api.patch(`/conversations/${conversationId}`, payload, this.withAuthHeaders());
    return response.data;
  }

  async updateProfile(uid: string, data: any) {
    const response = await this.api.put(`/conversations/users/${uid}`, data, this.withAuthHeaders());
    return response.data;
  }

  async getImageUrl(url: string) {
    const response = await this.api.get(`messages/image?url=${url}`, {
        ...this.withAuthHeaders(),
        responseType: 'arraybuffer' 
    });
    return response;
  }
}

export default ChatApiService.getInstance();
