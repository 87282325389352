import React, { useState } from "react";
import server from "../../api/server";
import { useAuthStateContext } from "../../context/Auth";
import AutocompleteInput from "../Autocomplete/AutocompleteInput";

const LocalPartnersAutocomplete = React.forwardRef((props: any, ref: any) => {
  const [searchResults, setSearchResults] = useState<Array<any>>([]);

  const {
    label,
    id,
    name,
    type,
    placeholder,
    defaultValue,
    error,
    onChange,
    ...others
  } = props;

  const handleOnSearch = async (text: string) => {
    const response = await server.getLocalPartners( text);
    setSearchResults(response?.data);
  };

  const handleOtherSelect = async (selectedOption: any) => {
    if (onChange) onChange(selectedOption);
  };
  return (
    <>
      <AutocompleteInput
        onSearch={handleOnSearch}
        onChange={handleOtherSelect}
        label={label}
        ref={ref}
        type={type}
        name={name}
        id={id}
        defaultValue={defaultValue}
        placeholder={placeholder}
        error={error}
        searchResults={searchResults}
        searchResultLabel="name"
        searchResultSubheader="fullAddress"
        {...others}
      />
    </>
  );
});
export default LocalPartnersAutocomplete;
