import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Button from "../../components/ButtonSquare";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputPlaceAutocomplete from "../../components/InputPlaceAutocomplete";
import InputWithValid from "../../components/Input";
import { useEffect, useMemo, useState } from "react";
import {
    createRestaurant,
    selectedRestaurant,
    updateRestaurant,
} from "./restaurantSlice";
import SelectInput from "../../components/InputSelect";
import {
    ActivityType,
    ButtonSize,
    restaurantCuisineOptions,
    restauranteTypeOptions,
} from "../../constants";
import PhoneInput from "../../components/PhoneInput";

interface RestaurantFormProps {
    onChange?: any;
    onCancel?: any;
    name?: string;
    addNew?: boolean;
}

const schema = yup.object({
    name: yup.string().required("This field is required").min(2),
    phone: yup.string().nullable(),
    type: yup.string().default(ActivityType.RESTAURANT).nullable(),
    cuisine: yup.array().nullable(),
    pacInput: yup.object({
        street_number: yup.string().nullable(),
        route: yup.string().nullable(),
        postal_code: yup.string().nullable(),
        postal_code_suffix: yup.string().nullable(),
        locality: yup.string().nullable(),
        administrative_area_level_1: yup.string().nullable(),
        country: yup.string().default(""),
        fullAddress: yup.string().default(""),
        lat: yup.number().default(0),
        lng: yup.number().default(0),
    }),
});

export default function RestaurantForm({
    onCancel,
    onChange,
    name,
    addNew,
}: RestaurantFormProps) {
    const [isUpdate, setIsUpdate] = useState(false);
    const restaurantSelected: any = useAppSelector(selectedRestaurant);
    const dispatch = useAppDispatch();

    const restaurant: any = useMemo(() => {
        return !addNew ? restaurantSelected : undefined;
    }, [addNew, restaurantSelected]);

    useEffect(() => {
        const isUpdate = !!restaurant?.id;
        setIsUpdate(isUpdate);
    }, [restaurant?.id]);

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
    } = useForm({
        defaultValues: {
            name: restaurant?.name,
            type: restaurant?.type || ActivityType.RESTAURANT,
            cuisine: restaurant?.cuisine || [],
            pacInput: {
                street_number: restaurant?.streetNumber,
                route: restaurant?.streetAddress,
                postal_code: restaurant?.zipCode,
                locality: restaurant?.city,
                administrative_area_level_1: restaurant?.state,
                country: restaurant?.country,
                lat: restaurant?.lat,
                lng: restaurant?.lng,
                fullAddress: restaurant?.fullAddress,
            },
            phone: restaurant?.phone,
        },
        resolver: yupResolver(schema),
    });

    const handleSubmitForm = async (data: any) => {
        const payload = {
            type: data.type,
            cuisine: data.cuisine || [],
            name: data.name,
            phone: data.phone,
            fullAddress: data.pacInput.fullAddress,
            streetAddress: data.pacInput.route,
            streetNumber: data.pacInput.street_number,
            city: data.pacInput.locality,
            state: data.pacInput.administrative_area_level_1,
            country: data.pacInput.country,
            zipCode: data.pacInput.postal_code,
            lat: data.pacInput.lat,
            lng: data.pacInput.lng,
        };

        let newRestaurant;
        if (isUpdate) {
            newRestaurant = await dispatch(
                updateRestaurant(restaurant.id, payload)
            );
        } else {
            newRestaurant = await dispatch(createRestaurant(payload));
        }
        if (onChange) onChange(newRestaurant);
    };

    return (
        <div className="mx-6">
            <div className="flex flex-wrap">
                <div className="text-left font-semibold text-lg flex-grow">
                    {isUpdate ? "Edit restaurant" : "Add restaurant"}
                </div>
            </div>

            <div className="mt-5 lg:mt-0 lg:col-span-2">
                <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(handleSubmitForm)}
                >
                    <div className="">
                        <div className="px-4 py-5 bg-white sm:p-6">
                            <div className="col-span-6 mt-3">
                                <InputWithValid
                                    register={register}
                                    label="Name"
                                    type="text"
                                    id="name"
                                    name="name"
                                    error={errors["name"]?.message}
                                    defaultValue={restaurant?.name || name}
                                />
                            </div>
                            <div className="col-span-6 mt-3">
                                <SelectInput
                                    control={control}
                                    isMulti={false}
                                    register={register}
                                    label="Type"
                                    options={restauranteTypeOptions}
                                    id="type"
                                    name="type"
                                    error={errors["type"]?.message}
                                    defaultValue={restaurant?.type}
                                />
                            </div>
                            <div className="col-span-6 mt-3">
                                <SelectInput
                                    isMulti={true}
                                    control={control}
                                    label="Cuisine Type"
                                    options={restaurantCuisineOptions}
                                    id="cuisine"
                                    name="cuisine"
                                    error={errors["cuisine"]?.message}
                                    defaultValue={restaurant?.cuisine}
                                />
                            </div>
                            <div className="col-span-6 mt-3">
                                <InputPlaceAutocomplete
                                    control={control}
                                    label="Address"
                                    type="text"
                                    id="pacInput"
                                    name="pacInput"
                                    error={errors["pacInput"]}
                                    defaultValue={restaurant?.fullAddress}
                                />
                            </div>
                            <div className="col-span-6 sm:col-span-3 mt-3">
                                <span className="text-left block font-medium text-neutral-600 text-sm">
                                    Phone number
                                </span>
                                <PhoneInput
                                    control={control}
                                    id="phone"
                                    name="phone"
                                    error={errors["phone"]?.message}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-3 text-right">
                        <span className="mr-4">
                            <Button
                                outlined
                                label="Cancel"
                                onClick={onCancel}
                                size={ButtonSize.SMALL}
                            />
                        </span>
                        <Button
                            type="submit"
                            label="Save"
                            size={ButtonSize.SMALL}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}
