import { MouseEventHandler } from "react";
import Icon from "../assets/svg/Icon";

interface ISLButton {
    icon: string;
    onClick: MouseEventHandler<HTMLDivElement>;
}
export default function SocialLoginButton(props: ISLButton) {
    const { icon, onClick } = props;
    return (
        <div
            onClick={onClick}
            className="cursor-pointer w-20 h-20 flex items-center justify-center border border-neutral-300 rounded-full"
        >
            <Icon icon={icon} className="fill-gray-900 text-neutral-900" />
        </div>
    );
}
