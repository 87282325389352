import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ButtonSquared from "../../components/ButtonSquare";
import { NotificationsList } from "../../components/NotificationsList/NotificationsList";
import {
    PAGE_SECTIONS,
    TripStatus,
    UserRoleOnOrganization,
    UserStatusOnOrganization,
} from "../../constants";
import { getUserPermissionsOnTrip } from "../../helpers";
import { selectProfile } from "../authentication/userSlice";
import { setCurrentSection } from "../layout/layoutSlice";
import {
    getOrganizationMembers,
    getOrganizations,
    selectedOrganization,
} from "../organizations/organizationSlice";
import HomeTripsCard from "./components/HomeTripsCard";
import TripsNumberBar from "./components/TripsNumberBar";
import {
    getActiveTrips,
    getFinishedTrips,
    getHomeNotifications,
    selectActiveTrips,
    selectFinishedTrips,
    selectHomeNotifications,
    selectPendingTrips,
} from "./homeSlice";
import { CancelPlanModal } from "../../components/Modal/CancelPlanModal";
import { TopTripBanner } from "../trips/Trip/components/TopTripBanner";
import { OrganizationsList } from "../../components/OrganizationsList/OrganizationsList";
import { useConstantsContext } from "../../context/Constants";
import { useAuthStateContext } from "../../context/Auth";
import PastTripsCard from "./components/PastTripsCard";

export default function HomePage() {
    const dispatch = useAppDispatch();
    const activeTrips = useAppSelector(selectActiveTrips);
    const pendingTrips = useAppSelector(selectPendingTrips);
    const finishedTrips = useAppSelector(selectFinishedTrips);
    const notifications = useAppSelector(selectHomeNotifications);
    const profile = useAppSelector(selectProfile);
    const navigate = useNavigate();
    const organization = useAppSelector(selectedOrganization);
    const [showcancelPlanModal, setShowcancelPlanModal] = useState(false);
    const currentPlan = useAppSelector((state: any) => state.plans.currentPlan);
    const { constants } = useConstantsContext();
    useEffect(() => {
        dispatch(setCurrentSection({ currentSection: PAGE_SECTIONS.HOME }));
        dispatch(getOrganizations(true));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (profile?.id) {
            dispatch(getFinishedTrips());
            dispatch(getActiveTrips());
            dispatch(getHomeNotifications());
        }

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile?.id]);

    const activeTripsSliced = useMemo(() => {
        return (activeTrips?.data || []).slice(0, 2).map((t) => {
            return { ...t, ...getUserPermissionsOnTrip(t, profile.email) };
        });
    }, [activeTrips, profile]);

    const pendingTripsSliced = useMemo(() => {
        return (pendingTrips?.data || []).slice(0, 2).map((t) => {
            return { ...t, ...getUserPermissionsOnTrip(t, profile.email) };
        });
    }, [pendingTrips, profile]);

    useEffect(() => {
        if (organization?.id) {
            dispatch(
                getOrganizationMembers(
                    organization.id,
                    6,
                    0,
                    0,
                    undefined,
                    UserStatusOnOrganization.CONFIRMED,
                    UserRoleOnOrganization.MEMBER
                )
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization?.id]);

    const showTrips = (status: string) => {
        navigate(`/trips?status=${status}`);
    };
    const viewNotifications = () => {
        navigate(`/notifications`);
    };

    const handleClosePlanModal = () => setShowcancelPlanModal(false);

    const handleNewTripClick = () => {
        navigate("/trips/new");
    };

    const {
        getTripsCount,
        isFreeSupplier,
        tripsLimitReached,
        setShowPlanModal,
    } = useAuthStateContext();
    useEffect(() => {
        getTripsCount && getTripsCount();
    }, []);

    return (
        <div className="flex flex-col">
            <CancelPlanModal
                open={showcancelPlanModal}
                handleCancel={handleClosePlanModal}
                handleSubmit={handleClosePlanModal}
                plan={currentPlan}
            />
            <div className="flex w-full">
                {!profile?.isAdmin && currentPlan?.suspended && (
                    <TopTripBanner
                        className="w-full mb-8"
                        title={"Your plan has been suspended"}
                        text="Your plan has been suspended and all your trips converted to read-only mode due to lack of payment. Please pay for your plan to recover full access to your trips and all features."
                        actionLabel="Renew Subscription"
                        action={() => {
                            if (currentPlan?.plan?.link) {
                                window.open(currentPlan?.plan?.link, "_blank");
                            } else {
                                navigate("/plans");
                            }
                        }}
                    />
                )}
                {!!isFreeSupplier && !tripsLimitReached && (
                    <TopTripBanner
                        hideIcon
                        title="Free trial"
                        text="Explore our plans to find out the perfect for you."
                        action={() => {
                            setShowPlanModal(true);
                        }}
                        actionLabel="View Plans"
                        secondAction={handleNewTripClick}
                        secondAactionLabel="Create free trip"
                        className={
                            "w-full mb-8 flex flex-row justify-between items-center flex-wrap"
                        }
                        actionWrapperClass="!mt-0"
                    />
                )}
            </div>
            <div className="flex flex-wrap-reverse lg:flex-nowrap mb-8 gap-8">
                <div className="flex flex-col flex-grow w-full lg:w-2/3">
                    <TripsNumberBar
                        activeTrips={activeTrips?.count}
                        finishedTrips={finishedTrips?.count}
                        isLoading={
                            finishedTrips.isLoading || activeTrips.isLoading
                        }
                    />
                </div>
                <div className="flex flex-col lg:w-1/3 justify-end">
                    <ButtonSquared
                        type="button"
                        outlined
                        disabled={!profile?.isAdmin && currentPlan?.suspended}
                        onClick={handleNewTripClick}
                        className="bg-white w-full p-0 px-6 h-full text-center items-center shadow-card rounded-xl"
                        label="Create New trip"
                    />
                </div>
            </div>
            <div className="w-full flex flex-wrap lg:flex-nowrap gap-8">
                <div className="flex gap-y-8 flex-col w-full lg:w-2/3">
                    <HomeTripsCard
                        title="Active trips"
                        trips={activeTripsSliced}
                        isLoading={activeTrips.isLoading}
                        showMoreClick={() => {
                            showTrips(TripStatus.ACTIVE);
                        }}
                    />
                </div>
                <div className="w-full h-full lg:w-1/3">
                    <NotificationsList
                        notifications={notifications.data}
                        isLoading={notifications.isLoading}
                        viewAllClick={viewNotifications}
                    />
                </div>
            </div>
            <div className="w-full flex flex-wrap lg:flex-nowrap gap-8">
                <div className="flex flex-col gap-8 w-full lg:w-2/3">
                    {!!pendingTripsSliced?.length && (
                        <HomeTripsCard
                            title="Awaiting confirmation"
                            trips={pendingTripsSliced}
                            showMoreClick={() => {
                                showTrips(TripStatus.ACTIVE);
                            }}
                        />
                    )}
                    <PastTripsCard
                        title="Past trips"
                        trips={finishedTrips.data}
                        isLoading={finishedTrips.isLoading}
                        showMoreClick={() => {
                            showTrips(TripStatus.FINISHED);
                        }}
                    />
                </div>
                <div className="w-full lg:w-1/3">
                    {constants?.featureFlags?.ORGANIZATIONS && (
                        <OrganizationsList
                            isLoading={finishedTrips.isLoading}
                            addNewClick={() => {
                                navigate("/organizations/new");
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
