import constructionImage from "../../../../../assets/images/construction.png";

export default function ItineraryHiddenPage() {
    return (
        <div className="my-24 flex flex-col text-neutral-400 items-center justify-center text-center">
            <img
                alt="empty"
                src={constructionImage}
                className="h-28 m-0 mb-12"
            />
            <div className="text-sm text-neutral-900 font-medium">
                The itinerary is still in progress.
            </div>
            <div className="text-sm text-neutral-900 font-medium">
                You 'll receive a notification when it's ready.
            </div>
        </div>
    );
}
