
import Button from '../../../components/ButtonSquare';
import EmptyIcon from '../../../assets/images/EmptyScreenIcon.png';


interface OrganizationEmptyProps {
	toggleEdit: any;
}

export default function OrganizationEmpty(props: OrganizationEmptyProps) {
	return (
		<div className='my-24 flex flex-col text-neutral-400 items-center justify-center text-center'>
			<img alt='empty' src={EmptyIcon} className='h-28 m-0 mb-12' />
            <div className='text-base'>You have no organizations yet.</div>
            <div className='text-base'>Would you like to <span onClick={props.toggleEdit} className='text-orange-600 cursor-pointer'>create</span>  one?</div>
            <div className='mt-6'><Button onClick={props.toggleEdit} type='submit' label='Start' /></div>
		</div>
	);
}
