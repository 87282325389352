import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { JsxElement } from "typescript";
import { AUTHENTICATED, AUTHENTICATION_LOADING } from "../api/firebase";
import { useAuthStateContext } from "../context/Auth";
import { useAppSelector } from "../app/hooks";
import {
    getProfile,
    selectProfile,
} from "../features/authentication/userSlice";
import Loader from "../components/Loader";
import Smartlook from "smartlook-client";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";

type Props = {
    children?: React.ReactNode | React.ReactChild | JsxElement;
    protectedRoute: boolean;
};

const RequireAuth = ({ children, protectedRoute }: Props): any => {
    const { status, token, user: authuser } = useAuthStateContext();
    const isLoading = !status || status === AUTHENTICATION_LOADING;
    const userIsLoggedIn = status === AUTHENTICATED;
    const location: any = useLocation();
    const dispatch = useDispatch();
    const profile = useAppSelector(selectProfile);
    const needRedirect =
        (!userIsLoggedIn && protectedRoute) ||
        (userIsLoggedIn && !protectedRoute);
    const redirectPath = location.state?.path
        ? location.state.path
        : protectedRoute
        ? "/signin"
        : "/home";

    useEffect(() => {
        if (!profile?.email && token?.length) {
            Smartlook.identify(`${authuser?.email}`, {
                name: `${authuser?.displayName}`,
                email: `${authuser?.email}`,
                // other custom properties
            });
            setTimeout(() => {
                dispatch(getProfile());
            }, 300);
            Sentry.setUser({
                email: authuser?.email,
                name: authuser?.displayName,
            });
        } else {
            Sentry.setUser(null);
        }
        return () => {
            // cleanup
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authuser?.email, token]);

    return isLoading ? null : !needRedirect ? (
        !profile?.email && userIsLoggedIn ? (
            <div>
                <div className="w-full min-h-screen mb-12 flex flex-col items-center justify-center">
                    <div className="w-fit">
                        <Loader />
                    </div>
                </div>
            </div>
        ) : (
            children
        )
    ) : (
        <Navigate
            to={redirectPath}
            replace
            state={{ path: location.pathname, search: location.search }}
        />
    );
};

export default RequireAuth;
