import { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import AllIncomingTrips from "./AllIncomingTrips";

export default function IncomingTripsAdminHome(props: any) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_INCOMING_TRIPS })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AllIncomingTrips />;
}
