import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AuthProviders, PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import { ChangePassword } from "./changePassword";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import {
    removeAccountRequest,
    selectProfile,
    signOut as signOutProfile,
} from "../userSlice";
import { useAuthStateContext } from "../../../context/Auth";

export function AccountManagement() {
    const dispatch = useAppDispatch();
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
    const [showRemoveAccountModal, setShowRemoveAccountModal] = useState(false);
    const profile = useAppSelector(selectProfile);
    const { signOut } = useAuthStateContext();
    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.MANAGE_ACCOUNT })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemoveAccount = async () => {
        try {
            await dispatch(removeAccountRequest());
            setShowRemoveAccountModal(false);
            if (signOut) await signOut();
            dispatch(signOutProfile());
        } catch (error) {}
    };

    return !showResetPasswordForm ? (
        <>
            <ModalConfirmation
                title="Remove Account"
                open={showRemoveAccountModal}
                description={`Removing your account is permanent. Your trip data will be lost. To be sure, we'll send an email for confirmation.`}
                handleCancel={() => {
                    setShowRemoveAccountModal(false);
                }}
                handleSubmit={handleRemoveAccount}
            />

            {profile?.provider?.includes(AuthProviders.PASSWORD) && (
                <div className="min-h-full text-left mb-6">
                    <h1 className="text-lg font-semibold text-neutral-900">
                        Authentication
                    </h1>
                    <div className="text-base mt-2 text-orange-600 hover:text-orange-500 font-semibold cursor-pointer">
                        <div
                            onClick={() => setShowResetPasswordForm(true)}
                            className="w-80"
                        >
                            Reset Password
                        </div>
                    </div>
                </div>
            )}
            <div className="min-h-full text-left ">
                <h1 className="text-lg font-semibold text-neutral-900">
                    Account
                </h1>
                <div className="max-w-md text-sm text-neutral-600">
                    Removing your account is irreversible. Once your account is
                    deleted, all your trip data will be permanently lost.
                </div>
                <div className="text-base mt-2 text-orange-600 hover:text-orange-500 font-semibold cursor-pointer">
                    <div
                        onClick={() => {
                            setShowRemoveAccountModal(true);
                        }}
                        className="w-80"
                    >
                        Remove Account
                    </div>
                </div>
            </div>
        </>
    ) : (
        <ChangePassword onClose={() => setShowResetPasswordForm(false)} />
    );
}
