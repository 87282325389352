import { LazyLoadImage } from "react-lazy-load-image-component";
import Modal from "../../../../components/Modal";
import ButtonSquared from "../../../../components/ButtonSquare";
import TextareaWithValid from "../../../../components/TextArea";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

export const EditImageFormModal = ({ onSubmit, open, image, onClose }: any) => {
    const { register, getValues, reset } = useForm();
    useEffect(() => {
        reset({ description: image?.description });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image?.description]);
    return (
        <Modal open={open} onClose={onClose}>
            <div className="flex flex-col items-center">
                <LazyLoadImage
                    className={"h-[200px] w-auto rounded-lg"}
                    alt={image?.description}
                    src={image?.src}
                    placeholderSrc={image?.thumbnail}
                />
            </div>
            <form action="#" method="POST" encType="multipart/form-data">
                <div className="py-5 bg-white">
                    <div className="col-span-6 mt-6">
                        <TextareaWithValid
                            register={register}
                            label="Description"
                            id="description"
                            name="description"
                        />
                    </div>
                </div>

                <div className="flex gap-3 justify-end px-4 py-3 text-right">
                    <ButtonSquared
                        onClick={onClose}
                        outlined
                        type="button"
                        label="Cancel"
                    />
                    <ButtonSquared
                        onClick={() => {
                            onSubmit(getValues());
                        }}
                        type="button"
                        label="Save"
                    />
                </div>
            </form>
        </Modal>
    );
};
