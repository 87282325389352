import { useMemo } from "react";
import Paginator from "../../../../components/Paginator";

import Table from "../../../../components/Table";
import { PAGE_ROWS } from "../../../../constants";
import { normalizeTransfers } from "../../../../helpers";
import { ITransfers } from "../../../../types/plan.type";
import StatusBadgeComponent from "../../../../components/StatusBadge/StatusBadgeComponent";
interface IAllTransfers {
    transfers: any;
    handlePaginatorChange: Function;
}
const AllTransfersTable = (props: IAllTransfers) => {
    const { transfers, handlePaginatorChange } = props;
    const tableHeaders = [
        "User",
        "Date",
        "plan name",
        "invoice code",
        "price",
        "status",
    ];

    const transfersNormalized = useMemo(() => {
        return (transfers?.data || [])?.map((review: any) => {
            console.log("review", review);
            return { ...review, ...normalizeTransfers(review) };
        });
    }, [transfers?.data]);

    return (
        <>
            <Table headers={tableHeaders} isLoading={transfers?.isLoading}>
                {transfersNormalized?.map((review: ITransfers) => (
                    <tr key={review?.invoiceCode}>
                        <td
                            className="text-left flex flex-row"
                            title={review?.user?.email}
                        >
                            <span className="max-w-[200px] truncate">
                                {review?.user?.firstName}{" "}
                                {review?.user?.lastName}
                            </span>
                        </td>
                        <td className="text-left ">{review?.date}</td>
                        <td className="text-left max-w-[200px] truncate">
                            {review?.planName}
                        </td>
                        <td className="text-left max-w-[200px] truncate">
                            {review?.invoiceCode}
                        </td>
                        <td className="text-left">${review?.price}</td>
                        <td className="text-left">
                            <div className="flex items-center">
                                <StatusBadgeComponent
                                    status={review?.status.toUpperCase() as any}
                                    customText={review?.status}
                                    size="sm"
                                />
                            </div>
                        </td>
                    </tr>
                ))}
            </Table>
            {transfers?.count > PAGE_ROWS && (
                <div className="self-end text-xs z-20 mt-4">
                    <Paginator
                        count={transfers?.count}
                        take={PAGE_ROWS}
                        onChange={handlePaginatorChange}
                        totalPages={transfers?.totalPages}
                        currentPage={transfers?.currentPage}
                    />
                </div>
            )}
        </>
    );
};

export default AllTransfersTable;
