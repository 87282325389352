import moment from "moment";
import { ActivityIcon, ActivityType, activityTypeOnModel } from "../../../../../constants";

export const activityCardPresenter = (activity: any) => {
    if(!activity) return {}
    const { type } = activity || {};
    const isVisit =
        type &&
        !activity.isStayDay &&
        type === ActivityType.HOTEL &&
        moment.utc(activity.startDate).format("DD-MM-YYYY") ===
            moment.utc(activity.endDate).format("DD-MM-YYYY");
    const checkoutDay =
        activity.isStayDay &&
        moment.utc(activity.startDate).format("DD-MM-YYYY") ===
            moment.utc(activity.endDate).format("DD-MM-YYYY");
    const icon =
        type &&
        ActivityIcon[
            isVisit ? ActivityType.VISIT : (type as keyof typeof ActivityIcon)
        ];
    const activityTypeKey =
        activityTypeOnModel[type as keyof typeof activityTypeOnModel];
    const { name } = activity[activityTypeKey][type.toLowerCase()];
    const startHourLabel =
        !isVisit && type === ActivityType.HOTEL ? "Check in" : "Start time";
    return {
        ...activity,
        isVisit,
        checkoutDay,
        icon,
        cardTitle: activity?.title || name,
        startHourLabel: startHourLabel,
    };
};
