import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import EmptyScreen from "../../../../../components/EmptyScreen/EmptyScreen";
import SelectInput from "../../../../../components/InputSelect/SelectInput";
import Modal from "../../../../../components/Modal";
import {
    DocumentRequirementsFilterOptions,
    PAGE_ROWS,
} from "../../../../../constants";
import { getStatusFromFiles } from "../../../../../helpers";
import { selectCurrentBreakpoint } from "../../../../layout/layoutSlice";
import {
    getTripUserRequirements,
    IPaginatedDataInitialState,
    selectUserOnTripRequirements,
    setSelectedUserOnTripRequirements,
} from "../../../tripSlice";
import ParticipantDocumentsList from "./ParticipantDocumentsList";
import ParticipantDocumentsTable from "./ParticipantDocumentsTable";
import DocumentRequirementsModal from "./RequirementFiles/DocumentRequirementsModal";

interface ParticipantDocumentsListProps {
    userEmail: string;
    tripId: number;
    documentPendingId?: string | null | undefined;
}
export default function ParticipantDocuments(
    props: ParticipantDocumentsListProps
) {
    const { userEmail, tripId, documentPendingId } = props;
    const [newPage, setNewPage] = useState<any>({});
    const [selectedReqeuirement, setSelectedRequirement] = useState<any>(null);
    const [openRequirementModal, setOpenRequirementModal] =
        useState<any>(false);
    const [status, setStatus] = useState("");
    const tripRequirements = useAppSelector(selectUserOnTripRequirements);
    const dispatch = useAppDispatch();
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    const { data, count, isLoading, currentPage, totalPages } =
        tripRequirements;

    useEffect(() => {
        if (userEmail && tripId) {
            getDocuments(PAGE_ROWS, newPage.skip, "", status);
        }

        return () => {
            setSelectedUserOnTripRequirements({
                requirements: { ...IPaginatedDataInitialState },
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEmail, tripId, newPage.skip, status]);

    const getDocuments = (
        take: number,
        newSkip: number,
        search: string,
        status: any
    ) => {
        dispatch(
            getTripUserRequirements(
                Number(tripId),
                userEmail,
                take,
                newSkip,
                search,
                status
            )
        );
    };

    const requirementsData = useMemo(() => {
        if (data) {
            return data?.map((req) => {
                return { ...req, status: getStatusFromFiles(req.files) };
            });
        } else {
            return [];
        }
    }, [data]);
    const requirementsNormalized = useMemo(() => {
        return {
            count,
            isLoading,
            currentPage,
            totalPages,
            data: requirementsData,
        };
    }, [count, isLoading, currentPage, totalPages, requirementsData]);

    useEffect(() => {
        if (documentPendingId && requirementsData?.length) {
            const req = requirementsData?.find(
                (req) => req?.id === documentPendingId
            );
            setSelectedRequirement(req);
            setOpenRequirementModal(true);
        }
    }, [requirementsData, documentPendingId]);

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const handleStatusFilterChange = (value: string) => {
        const formattedValue = value === "ALL" ? "" : value;
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
        setStatus(formattedValue);
    };

    const onRequirementUpdate = () => {
        setOpenRequirementModal(false);
        getDocuments(PAGE_ROWS, newPage.skip, "", status);
    };

    const handleClickDocument = (req: any) => {
        setSelectedRequirement(req);
        setOpenRequirementModal(true);
    };

    return (
        <>
            <Modal
                open={openRequirementModal}
                onClose={() => {
                    setOpenRequirementModal(false);
                }}
            >
                <DocumentRequirementsModal
                    onUpdate={onRequirementUpdate}
                    requirement={{ ...selectedReqeuirement, userEmail }}
                />
            </Modal>
            <div className="flex flex-col">
                <div className="mb-8 text-left max-w-fit min-w-[200px]">
                    <SelectInput
                        onChange={handleStatusFilterChange}
                        label="Status"
                        options={DocumentRequirementsFilterOptions}
                        defaultValue={
                            DocumentRequirementsFilterOptions[0].value
                        }
                    />
                </div>
                {isDesktop ? (
                    <ParticipantDocumentsTable
                        tripRequirements={requirementsNormalized}
                        handleClickDocument={handleClickDocument}
                        handlePaginatorChange={handlePaginatorChange}
                    />
                ) : (
                    <ParticipantDocumentsList
                        tripRequirements={requirementsNormalized}
                        handleClickDocument={handleClickDocument}
                        handlePaginatorChange={handlePaginatorChange}
                    />
                )}

                {!isLoading && requirementsData?.length === 0 && (
                    <EmptyScreen text="There are no requirements to show." />
                )}
            </div>
        </>
    );
}
