import { ActivityType } from "../../../constants";
import { ILibraryElement } from "../../../types/library.type";

export const parseLibraryElementToActivity = (libraryElement: ILibraryElement) => {
    const activity: any = {
        ...libraryElement,
    };
    switch (libraryElement.type) {
        case ActivityType.HOTEL:
            activity.hotelActivity = {
                hotel: libraryElement.hotel,
            };
            break;
        case ActivityType.RESTAURANT:
            activity.restaurantActivity = {
                restaurant: libraryElement.restaurant,
            };
            break;
        case ActivityType.OTHER:
            activity.otherActivity = {
                other: libraryElement.other,
            };
            break;
    }
    return activity;
}

export const libraryElementToActivityPayload = (libraryElement: ILibraryElement) => {
    // Remove all null values from the library element
    const activityPayload = Object.keys(libraryElement).reduce(
        (acc, key) => {
            if (libraryElement[key as keyof ILibraryElement] !== null) {
                acc[key] = libraryElement[key as keyof ILibraryElement];
            }
            return acc;
        },
        {} as any
    );
    // Add images and files to be imported from the library element
    activityPayload.imagesFromLibraryElement = Object.values(libraryElement.images || {});
    activityPayload.filesFromLibraryElement = Object.values(libraryElement.files || {});
    activityPayload.contacts = Object.values(libraryElement.contacts || {});
    activityPayload.libraryElementId = libraryElement.id;
    // Remove unnecessary fields
    delete activityPayload.organizationId;
    delete activityPayload.id;
    delete activityPayload.images;
    delete activityPayload.files;
    return activityPayload;
}
