import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import {
    ComplaintStatusFilterOptions,
    ComplaintTypesFilterOptions,
    PAGE_ROWS,
} from "../../../constants";
import { selectCurrentBreakpoint } from "../../layout/layoutSlice";
import { normalizeComplaint, parseMomentUtc } from "../../../helpers";
import DatePickerMaterial from "../../../components/DatePickerMaterial/DatePickerMaterial";
import InputSearch from "../../../components/InputSearch";
import SelectInput from "../../../components/InputSelect/SelectInput";

import ComplaintsAdminResponsiveList from "../../../components/Complaints/AllComplaintsResponsiveList";
import AllComplaintsTable from "../../../components/Complaints/AllComplaintsTable";

export default function AllComplaints({
    handleClickDotsMenu,
    fetchData,
    complaints,
}: any) {
    const [newPage, setNewPage] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [itemType, setItemType] = useState<string>("");
    const [startDate, setStartDate] = useState<any>(
        moment().subtract(6, "months").startOf("day").toDate()
    );
    const [endDate, setEndDate] = useState<any>(moment().endOf("day").toDate());
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage, search, status, startDate, endDate, itemType]);

    const getData = () => {
        fetchData({
            take: PAGE_ROWS,
            skip: newPage.skip,
            status,
            search,
            startDate: parseMomentUtc(startDate).startOf("day").toDate(),
            endDate: parseMomentUtc(endDate).endOf("day").toDate(),
            itemType,
        });
    };

    const resetPage = () => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleSearchChange = (query: string) => {
        resetPage();
        setSearch(query);
    };
    const handleStatusFilterChange = (value: string) => {
        const formattedValue = value === "ALL" ? "" : value;
        resetPage();
        setStatus(formattedValue);
    };
    const handleItemTypeFilterChange = (value: string) => {
        const formattedValue = value === "ALL" ? "" : value;
        resetPage();
        setItemType(formattedValue);
    };
    const handleStartDateChange = (value: moment.Moment) => {
        resetPage();
        setStartDate(value.startOf("day").toDate());
    };
    const handleEndDateChange = (value: moment.Moment) => {
        resetPage();
        setEndDate(value.startOf("day").toDate());
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const complaintsNormalized = useMemo(() => {
        return {
            ...complaints,
            data: (complaints?.data || [])?.map((complaint: any) => {
                return normalizeComplaint(complaint);
            }),
        };
    }, [complaints]);

    return (
        <>
            <div className="text-neutral-900 text-left font-semibold mb-6">
                Requests List
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-12 items-end gap-x-6 lg:gap-x-10">
                <div className="col-span-1 lg:col-span-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>

                <div className="col-span-1 lg:col-span-3">
                    <DatePickerMaterial
                        name="startDate"
                        value={startDate}
                        id="startDate"
                        label="From date"
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className="col-span-1 lg:col-span-3">
                    <DatePickerMaterial
                        name="endDate"
                        value={endDate}
                        id="startDate"
                        label="To date"
                        onChange={handleEndDateChange}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-12 items-end gap-x-6 lg:gap-x-10">
                <div className="col-span-1 lg:col-span-3">
                    <SelectInput
                        onChange={handleStatusFilterChange}
                        label="Status"
                        options={ComplaintStatusFilterOptions}
                        defaultValue={ComplaintStatusFilterOptions[0].value}
                    />
                </div>
                <div className="col-span-1 lg:col-span-3">
                    <SelectInput
                        onChange={handleItemTypeFilterChange}
                        label="Request type"
                        options={ComplaintTypesFilterOptions}
                        defaultValue={ComplaintTypesFilterOptions[0].value}
                    />
                </div>
            </div>
            {isDesktop ? (
                <AllComplaintsTable
                    complaints={complaintsNormalized}
                    handleClickDotsMenu={handleClickDotsMenu}
                    handlePaginatorChange={handlePaginatorChange}
                />
            ) : (
                <ComplaintsAdminResponsiveList
                    complaints={complaintsNormalized}
                    handlePaginatorChange={handlePaginatorChange}
                    handleClickDotsMenu={handleClickDotsMenu}
                />
            )}
            {!complaints?.isLoading && !complaintsNormalized?.data?.length && (
                <div className="text-neutral-400 text-sm py-6">
                    There are no complaints to show.
                </div>
            )}
        </>
    );
}
