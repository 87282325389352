import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { AddHotelRequest } from "../../constants";
import { handleError } from "../authentication/userSlice";
export interface HotelState {
    isLoading: Boolean;
    hotels: Array<any>;
    selectedHotel: any | null;
}

const initialState: HotelState = {
    isLoading: false,
    hotels: [],
    selectedHotel: null,
};

export const hotelSlice = createSlice({
    name: "hotel",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setHotels: (state, action: PayloadAction<{ hotels: Array<any> }>) => {
            state.hotels = [...action.payload.hotels];
        },
        setSelectedHotel: (
            state,
            action: PayloadAction<{ selectedHotel: Object | null }>
        ) => {
            state.selectedHotel = action.payload.selectedHotel
                ? { ...action.payload.selectedHotel }
                : null;
        },
    },
});

export const { isLoading, setHotels, setSelectedHotel } = hotelSlice.actions;
export const selectHotels = (state: RootState) => state.hotel?.hotels;
export const selectedHotel = (state: RootState) => state.hotel?.selectedHotel;

export const getHotels =
    (searchText: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getHotels(searchText);
            dispatch(setHotels({ hotels: response.data }));
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const createHotel =
    (data: AddHotelRequest): AppThunk<Promise<any>> =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addHotel(data);
            dispatch(setSelectedHotel({ selectedHotel: response.data }));
            dispatch(isLoading(false));
            return response.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const updateHotel =
    (hotelId: number, fields: AddHotelRequest): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.updateHotel(hotelId, fields);
            dispatch(setSelectedHotel({ selectedHotel: response.data }));
            dispatch(isLoading(false));
            return response.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const getHotel =
    (hotelId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getHotel(hotelId);
            dispatch(setSelectedHotel({ selectedHotel: response.data }));
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export default hotelSlice.reducer;
