import Icon from "../../assets/svg/Icon";
import { mdiCheckboxBlankOutline } from "@mdi/js";
import { mdiCheckboxMarked } from "@mdi/js";
import { Paragraph } from "../Paragraph/Paragraph";

export default function CheckBoxInput(props: any) {
    const { label, comment, value, onChange } = props;
    return (
        <div className="flex items-start">
            <div className="flex items-center h-5" onClick={() => onChange(!value)}>
                {value ? (
                    <Icon
                        className="w-[20px] cursor-pointer text-orange-500"
                        materialIcon={mdiCheckboxMarked}
                    />
                ) : (
                    <Icon
                        className="w-[20px] cursor-pointer text-orange-500"
                        materialIcon={mdiCheckboxBlankOutline}
                    />
                )}
            </div>
            <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">
                    {label}
                </label>
                {comment && <Paragraph className="text-gray-500">{comment}</Paragraph>}
            </div>
        </div>
    );
}
