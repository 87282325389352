import { useEffect, useMemo, useState } from "react";
import ResultsListItem from "./ResultsItem";

const ResultsList = (props: any) => {
    const [value, setValue] = useState<any>();
    const { label, id, name, onChange, searchResults, className } = props;

    useEffect(() => {
        setValue(null);
    }, [searchResults?.length]);

    const handleOptionSelect = async (activeIndex: number) => {
        const selectedResult = searchResults[activeIndex];
        if (onChange) onChange(selectedResult);
        setValue(activeIndex);
    };

    const showResultsList = useMemo(() => {
        return searchResults?.length > 0;
    }, [searchResults?.length]);

    if (!showResultsList) return null;

    return <div className="mt-4 w-full child:w-full">
        <div className="">
            {searchResults.map((r: any, index: number) => {
                const selected = index === value;

                return (
                    <ResultsListItem
                        key={`hotelList_${r?.id || r?.place_id}_${r?.name}`}
                        selected={selected}
                        handleOptionSelect={(e: any) => {
                            handleOptionSelect(index);
                        }}
                        header={r["description"] || r["name"]}
                        subHeader={""}
                    />
                );
            })}
        </div>
    </div>
};

export default ResultsList;
