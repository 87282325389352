import InputSwitch from "../../../../../components/InputSwitch/InputSwitch";

export default function PublishSwitch(props: {
    disabled: boolean;
    defaultChecked: boolean;
    onClick: any;
}) {
    const { disabled, defaultChecked, onClick } = props;
    return (
        <div>
            <div className="text-left text-sm text-gray-600 font-semibold">
                Visibility
            </div>
            <div className="flex gap-2 mb-2">
                <div className="text-sm text-gray-400 font-semibold">
                    Hidden
                </div>
                <InputSwitch
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                    onClick={onClick}
                />
                <div className="text-left text-sm text-gray-600 font-semibold">
                    Published
                </div>
            </div>
        </div>
    );
}
