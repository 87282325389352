import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { ACTIVITY_FORM_STEPS_IDS, PAGE_SECTIONS } from "../../../../../../../constants";
import {
    activityFormCurrentStep,
    setActivityFormCurrentStep,
} from "../../../../../tripSlice";
import ActivityDetailsForm from "../ActivityDetailsForm";
import ActivityFormPreview from "./FormPreview";
import RestaurantInfoForm from "./RestaurantInfo";
import RestaurantSelectionForm from "./RestaurantSelection";
import { setCurrentSection } from "../../../../../../layout/layoutSlice";

export default function NewRestaurantActivityForm(props: {
    currentSection: PAGE_SECTIONS;
    onSubmit: (activity: any) => void;
    navigateBack: () => void;
    isLibraryElement?: boolean;
}) {
    const dispatch = useAppDispatch();
    const { currentSection, navigateBack, isLibraryElement, onSubmit } = props;
    const currentStep = useAppSelector(activityFormCurrentStep);
    useEffect(() => {
        dispatch(
            setActivityFormCurrentStep({
                step: ACTIVITY_FORM_STEPS_IDS.RESTAURANT_SELECTION,
            })
        );
        dispatch(
            setCurrentSection({
                currentSection,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    switch (currentStep) {
        case ACTIVITY_FORM_STEPS_IDS.RESTAURANT_SELECTION:
            return (
                <RestaurantSelectionForm
                    isLibraryElement={isLibraryElement}
                    navigateBack={navigateBack}
                />
            );
        case ACTIVITY_FORM_STEPS_IDS.RESTAURANT_INFO:
            return <RestaurantInfoForm isLibraryElement={isLibraryElement} />;
        case ACTIVITY_FORM_STEPS_IDS.RESTAURANT_DETAILS:
            return <ActivityDetailsForm />;
        case ACTIVITY_FORM_STEPS_IDS.RESTAURANT_CONFIRM:
            return (
                <ActivityFormPreview
                    isLibraryElement={isLibraryElement}
                    onSubmit={onSubmit}
                />
            );
        default:
            return null;
    }
}
