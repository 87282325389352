import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getLibraryElement, getOrganization, setSelectedLibraryElement } from "../organizationSlice";


export default function LibraryElement() {
    const dispatch = useAppDispatch();
    const { organizationId, activityId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // If the library element is not selected, fetch it
        if (!organizationId || !activityId) {
            navigate("/organizations");
            return;
        }
        fetchData();
        return () => {
            dispatch(
                setSelectedLibraryElement({
                    selectedLibraryElement: null,
                })
            )
        }
    }, []);

    const fetchData = async () => {
        await Promise.all([
            dispatch(getOrganization(Number(organizationId))),
            dispatch(getLibraryElement(Number(organizationId), Number(activityId))),
        ]);
    }


    return <Outlet/>;
}