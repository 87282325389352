import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
    getTripRequirement,
    selectedTrip,
    setSelectedTripRequirement,
} from "../../../tripSlice";
import { setCurrentSection } from "../../../../layout/layoutSlice";
import { PAGE_SECTIONS } from "../../../../../constants";

export default function TripRequirementHome() {
    let { tripId, requirementId } = useParams();
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_REQUIREMENTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //TODO: move efects to parent OUTLET
    useEffect(() => {
        if (requirementId && trip?.id && Number(trip?.id) === Number(tripId)) {
            const fetchRequirement = async () => {
                dispatch(
                    getTripRequirement(
                        parseInt(tripId!),
                        parseInt(requirementId!)
                    )
                );
                // getUsersData();
            };
            fetchRequirement();
        }
        return () => {
            dispatch(
                setSelectedTripRequirement({
                    selectedTripRequirement: null,
                })
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip?.id, requirementId]);

    return <Outlet />;
}
