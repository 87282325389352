import { useMemo } from "react";
import { normalizeComplaint } from "../../helpers";
import ComplaintItem from "./ComplaintItem";
import { Paragraph } from "../Paragraph/Paragraph";
import { ComplaintStatus, ComplaintType } from "../../constants";
import { ChangeRoleReplyForm } from "./ChangeRoleReplyForm";
import Badge from "../Badge/Badge";

interface IComplaintReplyForm {
    complaint: any;
    setValue: any;
}

export default function ComplaintView(props: IComplaintReplyForm) {
    const { complaint } = props;

    const handleChangeRole = (role: string) => {
        const data = { role };
        props?.setValue && props.setValue("data", data);
    };

    const normalizeOverview: any = {
        [ComplaintType.INSIGHT]: "User report",
        [ComplaintType.REVIEW]: "User report",
        [ComplaintType.USER]: "User report",
        [ComplaintType.USER_ROLE]: "Request to change role",
        [ComplaintType.USER_EMAIL_CHANGE]: "Request to change email",
        [ComplaintType.USER_FEEDBACK]: "User feedback",
    };

    const badgeColor: any = {
        [ComplaintStatus.PENDING]: "yellow",
        [ComplaintStatus.ACCEPTED]: "green",
        [ComplaintStatus.REJECTED]: "red",
    };

    const normalizedComplaint = useMemo(() => {
        return complaint && normalizeComplaint(complaint);
    }, [complaint]);

    return (
        <>
            <div className="text-neutral-400 text-left font-semibold mb-6">
                Overview: {normalizeOverview[complaint.itemType]}
            </div>
            <div className="flex flex-col text-left p-4 bg-neutral-50 rounded-md">
                <div className="text-lg font-semibold flex flex-row">
                    <div className="flex flex-grow">
                        {normalizedComplaint?.title}
                    </div>
                    <div className="w-fit">
                        <Badge
                            size="xs"
                            text={complaint?.status}
                            color={
                                badgeColor[normalizedComplaint.statusNormalized]
                            }
                            notBold={true}
                        />
                    </div>
                </div>

                <div className="text-sm font-semibold text-left pt-4">
                    User: {normalizedComplaint?.userFormatted}
                </div>

                {!!normalizedComplaint?.reason?.length && (
                    <>
                        <div className="text-sm font-semibold text-left pt-4">
                            Reason:
                        </div>

                        <div className="text-base">
                            {normalizedComplaint?.reason}
                        </div>
                    </>
                )}
                {!!normalizedComplaint?.comments?.length &&
                    complaint.itemType !== ComplaintType.USER_ROLE && (
                        <>
                            <div className="text-sm font-semibold text-left pt-4">
                                Comments:
                            </div>
                            <Paragraph className="text-base">
                                {normalizedComplaint?.comments}
                            </Paragraph>
                        </>
                    )}
                <div className="flex flex-row justify-end gap-2 flex-wrap text-xs">
                    <div>{normalizedComplaint?.user?.email}</div>
                </div>
                <div className="flex flex-row justify-end gap-2 flex-wrap text-xs">
                    <div>{normalizedComplaint?.date}</div>
                </div>
            </div>

            {complaint.itemType === ComplaintType.USER_ROLE && (
                <ChangeRoleReplyForm
                    item={complaint}
                    handleChangeRole={handleChangeRole}
                />
            )}

            {!![
                ComplaintType.INSIGHT,
                ComplaintType.REVIEW,
                ComplaintType.USER,
                ComplaintType.USER_EMAIL_CHANGE,
            ].includes(complaint.itemType) && (
                <div>
                    <div className="text-neutral-400 text-left font-semibold my-6">
                        Element information
                    </div>
                    <ComplaintItem complaint={complaint} />
                </div>
            )}
        </>
    );
}
