import { useSelector } from "react-redux";
import {
    selectActivityInsight,
    selectActivityReview,
} from "../../../../../../tripSlice";
import GalleryImage from "../../../../../../../../components/ImagesGallery/Image";
import InsightNotesForm from "./InsightNotesForm";
import InsightDetailWrapper from "../../components/InsightDetailWrapper";
import InsightGalleryContainer from "../../components/InsightGalleryContainer";
import {
    PAGE_SECTIONS,
    goodForWhichClientsOptions,
} from "../../../../../../../../constants";
import HotelItemDetail from "../../components/HotelItemDetail/HotelItemDetail";
import Icon from "../../../../../../../../assets/svg/Icon";
import { useMemo } from "react";

interface IInsightNotesViewProps {
    insightKey: "hotelInsight" | "restaurantInsight" | "otherInsight";
    currentSection: PAGE_SECTIONS;
    getDefaultValues?: (insight?: Record<string, any>) => Record<string, any>;
    addInsightNotes: (
        tripId: number,
        activityId: number,
        payload: Record<string, any>,
        insightId?: number
    ) => any;
}

export default function InsightNotesView({
    insightKey,
    currentSection,
    getDefaultValues,
    addInsightNotes,
}: IInsightNotesViewProps) {
    const activityInsight = useSelector(selectActivityInsight);
    const activityReview = useSelector(selectActivityReview);
    // Insight is either "Hotel", "Restaurant", or "Other"
    // Ensure the insightKey is one of the following: 'hotelInsight', 'restaurantInsight', 'otherInsight'
    const insight = activityInsight?.[insightKey];

    const currentStars: number = activityReview?.stars || 0;

    const images: { url: string }[] = Object.values(
        activityReview?.images || {}
    );

    const goodForWhichClients = useMemo(() => {
        return insight?.goodForWhichClients
            ?.split(",")
            .map(
                (option: string) =>
                    goodForWhichClientsOptions.find((g) => g.value === option)
                        ?.label
            )
            ?.join(", ");
    }, [insight?.goodForWhichClients]);

    const ratingLabelByStars = (() => {
        const ratingValues: Record<number, string> = {
            0: "",
            1: "Very poor",
            2: "Poor",
            3: "Acceptable",
            4: "Good",
            5: "Very good",
        };
        return ratingValues[currentStars] || "";
    })();

    // If the insight is empty, always show the form
    const showFormCondition =
        !currentStars &&
        !images.length &&
        !activityReview?.comments &&
        (!insight?.generalComments || !insight?.comments) &&
        !insight?.goodForWhichClients &&
        !insight?.specificClients;

    return (
        <InsightDetailWrapper
            title={
                <div className="flex flex-grow gap-2 sm:text-xl text-lg items-center text-neutral-900 font-semibold">
                    <Icon icon={"EarthIcon"} className={`w-5 h-5 `} />
                    Public notes
                </div>
            }
            form={(props) => (
                <InsightNotesForm
                    {...props}
                    insightKey={insightKey}
                    getDefaultValues={getDefaultValues}
                    addInsightNotes={addInsightNotes}
                />
            )}
            insight={insight}
            currentSection={currentSection}
            showFormCondition={showFormCondition}
        >
            <div className="flex flex-col items-left my-4">
                <div className="font-semibold sm:text-lg text-sm text-gray-900 mb-2">
                    Your rating experience
                </div>
                <div className="flex flex-row items-center gap-1">
                    <div className="flex flex-row font-semibold sm:text-xl text-lg text-neutral-500">{`${currentStars}/5`}</div>
                    <div className="flex flex-row font-semibold sm:text-xl text-lg text-neutral-500">
                        {ratingLabelByStars}
                    </div>
                </div>
            </div>
            {!!images?.length && (
                <InsightGalleryContainer>
                    {images.map(
                        (
                            image: { url: string; description?: string },
                            index: number
                        ) => (
                            <div className="w-fit" key={image.url}>
                                <GalleryImage
                                    viewOnly
                                    src={image.url}
                                    onImageClick={() => {}}
                                    imageClass="object-cover"
                                    description={image?.description}
                                />
                            </div>
                        )
                    )}
                </InsightGalleryContainer>
            )}
            {activityReview?.comments && (
                <HotelItemDetail
                    className={`w-full ${!!images?.length ? "my-10" : "mb-10"}`}
                    label="Public comments"
                    value={activityReview?.comments || ""}
                />
            )}

            {(insight?.generalComments ||
                insight?.comments ||
                insight?.goodForWhichClients ||
                insight?.specificClients) && (
                <div className="bg-neutral-100 p-5 rounded-lg mb-10">
                    <div className="flex items-center font-semibold sm:text-xl text-lg text-gray-900 mb-2">
                        <Icon icon={"LockIcon"} className={`w-5 h-5 mr-2`} />
                        Private notes
                    </div>
                    <div className="flex flex-col gap-10 mt-5">
                        {(insight?.generalComments || insight?.comments) && (
                            <HotelItemDetail
                                className="w-full"
                                label="Your private comments"
                                value={
                                    insight?.generalComments ||
                                    insight?.comments ||
                                    ""
                                }
                            />
                        )}
                        {insight?.goodForWhichClients && (
                            <HotelItemDetail
                                className="w-full"
                                label="Good for which clients"
                                value={goodForWhichClients || ""}
                            />
                        )}
                        {insight?.specificClients && (
                            <HotelItemDetail
                                className="w-full"
                                label="Specific clients"
                                value={insight?.specificClients || ""}
                            />
                        )}
                    </div>
                </div>
            )}
        </InsightDetailWrapper>
    );
}
