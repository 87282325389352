import Stepper from "../NewActivityForm/Stepper";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
    ACTIVITY_PRIVATE_INSIGHT_SECTIONS,
    ActivityType,
    INSIGHT_CATEGORY_LABELS,
} from "../../../../../../constants";
import SidebarMenu from "./components/SidebarMenu";
import { useAppSelector } from "../../../../../../app/hooks";
import { selectCurrentBreakpoint } from "../../../../../layout/layoutSlice";
import { useSelector } from "react-redux";
import { selectedTripActivity } from "../../../../tripSlice";
import Icon from "../../../../../../assets/svg/Icon";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";
import { formatDate, groupBy } from "../../../../../../helpers";
import moment from "moment";

const AddPrivateInsight = () => {
    // Get current route
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    const selectedActivity = useSelector(selectedTripActivity);
    const activitySteps: Array<{ id: string, label: string, url: string, key: string, category: string, }> =
        ACTIVITY_PRIVATE_INSIGHT_SECTIONS[
            (selectedActivity?.type as keyof typeof ACTIVITY_PRIVATE_INSIGHT_SECTIONS) ||
                ActivityType.OTHER
        ];

    const steps = activitySteps.map(({ url, ...rest }) => ({
        ...rest,
        url,
        onClick: () => navigate(url),
    }));

    const sectionsGrouped = groupBy(steps, 'category');
    const sections = Object.keys(sectionsGrouped).map((key) => ({
        title: INSIGHT_CATEGORY_LABELS[key],
        sections: sectionsGrouped[key]
    }));
    const startDate = formatDate(
        selectedActivity?.startDate || selectedActivity?.startDate
    );
    const endDate = formatDate(
        selectedActivity?.endDate || selectedActivity?.endDate
    );
    const shouldShowEndDate = endDate && moment(startDate).isBefore(endDate);

    const goBackToActivityDetailView = () => {
        const { tripId, id } = selectedActivity || {};
        if (tripId && id)
            navigate(`../../../../trips/${tripId}/itinerary/${id}`);
    };

    const currentRoute = pathname.split("/").pop();
    const findCurrentStep =
        activitySteps.find(({ url }) => url === currentRoute) ||
        activitySteps[0];
    const currentStep = findCurrentStep.id;

    return (
        <>
            <div className="flex flex-col text-left">
                <div className="flex flex-row items-start mb-4 w-full border-b pb-4">
                    <span
                        className=" text-neutral-900 bg-neutral-100 hover:bg-neutral-200 rounded-full p-1 cursor-pointer"
                        onClick={() => goBackToActivityDetailView()}
                    >
                        <Icon
                            className="w-[20px]"
                            materialIcon={mdiArrowLeft}
                        />
                    </span>
                    <div className="ml-4 flex flex-col gap-y-2">
                        <div className="text-lg text-neutral-900 font-semibold">
                            {selectedActivity?.title}
                        </div>
                        <div>
                            <div className="flex flex-row items-center gap-x-2 text-sm text-neutral-800 font-semibold">
                                {startDate}
                                {shouldShowEndDate && (
                                    <>
                                        <Icon
                                            materialIcon={mdiArrowRight}
                                            className="w-[16px]"
                                        />
                                        {endDate}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full gap-5 flex lg:flex-row flex-col lg:min-h-full">
                    <div className="w-fit-content min-w-[200px]">
                        <div className="w-full  flex flex-row lg:justify-start justify-center">
                            {isDesktop ? (
                                <div className='w-full flex flex-col gap-3'>
                                    {sections.map((section, index) => (
                                        <>
                                            <SidebarMenu
                                                key={section.title}
                                                title={section.title}
                                                sections={section.sections}
                                                currentStep={currentStep}
                                            />
                                            {index !== sections.length - 1 && <div className="border-b border-solid-gray-300"/>}
                                        </>
                                    ))}
                                </div>
                            ) : (
                                <Stepper
                                    steps={steps}
                                    current={currentStep}
                                    hideLines={true}
                                />
                            )}
                        </div>
                    </div>
                    <div className="w-full lg:border-l lg:border-solid-gray-300 lg:px-5">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddPrivateInsight;
