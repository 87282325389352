import { FC } from "react";
import Icon from "../../../assets/svg/Icon";

interface FileInputButtonProps {
    showDropdown: boolean;
    toggleDropdown: () => void;
    onFileInputClick: () => void;
}

const FileInputButton: FC<FileInputButtonProps> = ({
    showDropdown,
    toggleDropdown,
    onFileInputClick,
}) => (
    <div className="relative">
        <button
            type="button"
            className="rounded-full bg-gray-200 cursor-pointer mr-1 p-2"
            onClick={toggleDropdown}
        >
            <Icon
                icon={showDropdown ? "CloseIcon" : "PlusIcon"}
                className="w-6 h-6 m-0 fill-gray-500 text-gray-500"
            />
        </button>
        {showDropdown && (
            <div className="absolute bottom-full mb-2 left-0 bg-white border text-gray-400 hover:text-orange-600 border-gray-300 rounded-md shadow-md z-10">
                {/*<button
                    className="flex items-center gap-2 px-4 py-2 cursor-pointer text-base font-semibold text-gray-400 hover:text-orange-600 whitespace-nowrap"
                    onClick={onFileInputClick}
                >
                    <Icon
                        icon="FileOutlineIcon"
                        className="text-gray-400 w-[20px] cursor-pointer hover:text-orange-600"
                    />
                    <span>File</span>
                </button>*/}
                <button
                    className="flex items-center gap-2 px-4 py-2 cursor-pointer text-base font-semibold  hover:text-orange-600 whitespace-nowrap"
                    onClick={onFileInputClick}
                >
                    <Icon
                        icon="ImageAddIcon"
                        className="w-[20px] cursor-pointer "
                    />
                    <span>Photo</span>
                </button>
            </div>
        )}
    </div>
);

export default FileInputButton;
