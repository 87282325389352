/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import FamguruLogo from "../../assets/svg/LogoSquared.svg";
import Avatar from "../../components/Avatar";
import FamguruText from "../../assets/svg/Famguru.svg";
import Icon from "../../assets/svg/Icon";
import Bellbadge from "../../components/BellBadge";
import { useChatForm } from "../chat/hooks/useChat.hook";
import { useSelector } from "react-redux";
import { useConstantsContext } from "../../context/Constants";

interface HeaderBarOptionProps {
    to: string;
    className?: string;
    icon: string;
    label: string;
}

const HeaderBarOption: React.FC<HeaderBarOptionProps> = ({
    to,
    className,
    icon,
    label,
}) => {
    const { chats } = useChatForm();
    const currentUser = useSelector((state: any) => state?.user?.profile);

    const unreadMessagesCount = chats
        .map((chat) => {
            const messagesArray = chat.messages
                ? Object.values(chat.messages)
                : [];
            const unreadMessages = messagesArray.filter(
                (msg: any) => !msg.readBy || !msg.readBy[currentUser?.uid]
            ).length;
            return unreadMessages;
        })
        .filter((unreadMessages) => unreadMessages > 0);

    return (
        <Link
            to={to}
            className={`no-underline cursor-pointer flex-col justify-center items-center text-center ${className}`}
        >
            <div className="flex flex-row items-center gap-1">
                <div className="flex w-full justify-center relative">
                    {icon && (
                        <Icon
                            icon={icon}
                            className="w-6 h-6 m-0 fill-neutral-400 text-neutral-400"
                        />
                    )}
                    {label === "Chat" && unreadMessagesCount.length > 0 && (
                        <span className="absolute top-0 left-[-10px] bg-primary_magenta-500 text-white text-[8px] rounded-full h-4 w-4 flex items-center justify-center">
                            {unreadMessagesCount.length}
                        </span>
                    )}
                </div>
                <div className="font-normal text-xs text-neutral-400 hidden lg:block">
                    {label}
                </div>
            </div>
        </Link>
    );
};
export default function HeaderBar(props: any) {
    const { constants } = useConstantsContext();
    const { user, userIsLoggedIn, notificationsNotRead, handleSignOut } = props;
    return (
        <Popover className="fixed top-0 left-0 bg-white h-[68px] shadow-md w-screen py-[13px] z-50">
            <div className="max-w-screen-2xl mx-auto px-5">
                <div className="flex justify-between items-center border-gray-100  lg:justify-start lg:space-x-10">
                    <div className="flex justify-start items-center gap-5 lg:w-0 lg:flex-1">
                        <a href="/">
                            <span className="sr-only">Famguru</span>
                            <img src={FamguruLogo} alt="" />
                        </a>
                        <a href="/" className="hidden lg:block">
                            <span className="sr-only hidden lg:block">
                                Famguru
                            </span>
                            <img src={FamguruText} alt="" />
                        </a>
                    </div>
                    {userIsLoggedIn && (
                        <Popover.Group
                            as="nav"
                            className="flex items-center justify-end gap-4 md:gap-8 lg:flex-1 lg:w-0"
                        >
                            {user.isAdmin && (
                                <HeaderBarOption
                                    to="/admin/overview"
                                    className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                    icon={"AnalyticsIcon"}
                                    label={"Admin"}
                                />
                            )}
                            <HeaderBarOption
                                to="/"
                                className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                icon={"HomeIcon"}
                                label={"Home"}
                            />
                            <HeaderBarOption
                                to="/trips"
                                className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                icon={"TripIcon"}
                                label="Trips"
                            />
                            <HeaderBarOption
                                to="/insights"
                                className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                icon={"InsightsIcon"}
                                label="Insights"
                            />
                            <Link to={"/notifications"}>
                                <Bellbadge
                                    notReadCount={notificationsNotRead}
                                    //className=""
                                    width={24}
                                    height={24}
                                    label="Notifications"
                                />
                            </Link>
                            {constants?.featureFlags?.FEATURE_CHATS ==
                                "true" && (
                                <HeaderBarOption
                                    to="/chat"
                                    className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                    icon={"MessageIcon"}
                                    label="Chat"
                                />
                            )}
                            {constants?.featureFlags?.ORGANIZATIONS && (
                                <HeaderBarOption
                                    to="/organizations"
                                    className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                    icon={"OrganizationIcon"}
                                    label="Organizations"
                                />
                            )}
                            <Menu
                                as="div"
                                className="relative inline-block text-left"
                            >
                                <Menu.Button>
                                    <Link to="/profile" className="">
                                        <div className="no-underline cursor-pointer flex-col justify-center items-center text-center">
                                            <div className="flex flex-row items-center gap-1 lg:w-[100px]">
                                                <Avatar
                                                    src={
                                                        !!user?.avatar_thumb
                                                            ?.length
                                                            ? user?.avatar_thumb
                                                            : user?.avatar
                                                    }
                                                    className="w-6 h-6 m-0"
                                                />
                                                <div className="font-normal text-xs text-neutral-400 mt-[2px] hidden lg:block">
                                                    Account
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Menu.Button>
                            </Menu>
                        </Popover.Group>
                    )}
                </div>
            </div>

            {userIsLoggedIn && (
                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
                    >
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <img
                                            className="h-8 w-auto"
                                            src={FamguruLogo}
                                            alt="Workflow"
                                        />
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">
                                                Close menu
                                            </span>
                                            <XIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex content-center p-6 justify-center">
                                {userIsLoggedIn && (
                                    <Popover.Group
                                        as="nav"
                                        className="flex flex-wrap gap-8"
                                    >
                                        <HeaderBarOption
                                            to="/"
                                            className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                            icon={"HomeIcon"}
                                            label={"Home"}
                                        />
                                        <HeaderBarOption
                                            to="/trips"
                                            className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                            icon={"TripIcon"}
                                            label="Trips"
                                        />
                                        <HeaderBarOption
                                            to="/notifications"
                                            className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                            icon={"BellIcon"}
                                            label="Notifications"
                                        />
                                        <HeaderBarOption
                                            to="/chat"
                                            className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                            icon={"MessageIcon"}
                                            label="Chat"
                                        />
                                        <Link
                                            to="/profile"
                                            className="no-underline cursor-pointer flex-col justify-center items-center text-center"
                                        >
                                            <div className="flex flex-row items-center gap-1">
                                                <Avatar
                                                    src={
                                                        !!user?.avatar_thumb
                                                            ?.length
                                                            ? user?.avatar_thumb
                                                            : user?.avatar
                                                    }
                                                    className="w-6 h-6 m-0"
                                                />
                                                <div className="font-normal text-xs text-neutral-400 mt-[2px]">
                                                    Profile
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/">
                                            <div
                                                onClick={handleSignOut}
                                                className="text-neutral-300 text-sm font-normal hover:text-neutral-400 no-underline cursor-pointer flex-col justify-center items-center text-center p-4"
                                            >
                                                Log out
                                            </div>
                                        </Link>
                                    </Popover.Group>
                                )}
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            )}
        </Popover>
    );
}
