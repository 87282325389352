import { useMemo } from "react";
import { getName } from "i18n-iso-countries";
import { deNormalizeProfile } from "../../../../bussiness/user";
import { Paragraph } from "../../../../components/Paragraph/Paragraph";

export default function ProfessionalInfo(props: any) {
    const { profile } = props;

    const profileDenormalized = useMemo(() => {
        return deNormalizeProfile(profile);
    }, [profile]);

    const dWantCountriesNames = useMemo(() => {
        return (
            profileDenormalized?.destinationWant &&
            profileDenormalized?.destinationWant.map((countryCode: string) => {
                return getName(countryCode, "en");
            })
        );
    }, [profileDenormalized?.destinationWant]);

    const dSellCountriesNames = useMemo(() => {
        return (
            profileDenormalized?.destinationSell &&
            profileDenormalized?.destinationSell.map((countryCode: string) => {
                return getName(countryCode, "en");
            })
        );
    }, [profileDenormalized?.destinationSell]);
    return (
        <>
            <div className="flex flex-col">
                <div className="grid grid-cols-3 gap-x-4 lg:grid-cols-6 flex-wrap">
                    <div className="text-left break-words col-span-6">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Brief description about you
                        </span>

                        <Paragraph className="text-base block mt-1 mb-2">
                            {profile?.description || "-"}
                        </Paragraph>
                    </div>
                    <div className="text-left break-words col-span-6 mt-6">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Awards and Recognition
                        </span>

                        <Paragraph className="text-base block mt-1 mb-2">
                            {profile?.awards || "-"}
                        </Paragraph>
                    </div>

                    <div className="text-left lg:max-w-xs break-words col-span-3 mt-6">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Business Started Year
                        </span>

                        <span className="text-base block mt-1 mb-2">
                            {profile?.businessStartedYear || "-"}
                        </span>
                    </div>

                    <div className="text-left break-words col-span-6 mt-6">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Destinations I want to know better
                        </span>

                        <span className="text-base block mt-1 mb-2">
                            {(dWantCountriesNames &&
                                dWantCountriesNames?.join(", ")) ||
                                "-"}
                        </span>
                    </div>
                    <div className="text-left break-words col-span-6 mt-6">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Destinations I sell most often
                        </span>

                        <span className="text-base block mt-1 mb-2">
                            {(dSellCountriesNames &&
                                dSellCountriesNames?.join(", ")) ||
                                "-"}
                        </span>
                    </div>
                    <div className="text-left break-words col-span-6 mt-6">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Specialization
                        </span>

                        <span className="text-base block mt-1 mb-2">
                            {(profileDenormalized.specialization &&
                                profileDenormalized.specialization?.join(
                                    ", "
                                )) ||
                                "-"}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
