import { Link } from "react-router-dom";

export default function NotFound(props: any) {
    return (
        <div className="px-40 py-20 bg-white">
            <div className="flex flex-col items-center">
                <h1 className="font-bold text-primary_orange-900 text-9xl">
                    404
                </h1>

                <h6 className="mb-2 text-2xl font-bold text-center text-neutral-900 lg:text-3xl">
                    <span className="text-alert_red">Oops!</span> Page not found
                </h6>

                <p className="mb-8 text-center text-gray-500 lg:text-lg">
                    The page you’re looking for doesn’t exist.
                </p>

                <Link to="/" className="cursor-pointer">Go home</Link>
            </div>
        </div>
    );
}
