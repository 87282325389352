import { useEffect, useState } from "react";
import { IChatEntity } from "../../../types/chat.type";
import Search from "./Search";
import ChatsList from "./ChatList";
import { useChatForm } from "../hooks/useChat.hook";

interface LeftComponentProps {
    handleOpenModal: (value: boolean) => void;
}

export const LeftComponent = (props: LeftComponentProps) => {
    const { handleOpenModal } = props;
    const { chats, error } = useChatForm();
    const [filteredChats, setFilteredChats] = useState(chats);
    const [selectedTab, setSelectedTab] = useState("All");

    const handleNewChatClick = () => {
        handleOpenModal(true);
    };

    const filterChatsByTab = (chats: IChatEntity[]): IChatEntity[] => {
        if (selectedTab === "All") {
            return chats;
        } else if (selectedTab === "Trips") {
            return chats.filter((chat: IChatEntity) => chat.isGroup && chat.id.includes("trip"))
        } else if (selectedTab === "Individual") {
            return chats.filter((chat: IChatEntity) => !chat.isGroup);
        } else if (selectedTab === "Organization") {
            return chats.filter((chat: IChatEntity) => chat.isGroup && chat.id.includes("organization"));
        }
        return chats;
    };

    useEffect(() => {
        setFilteredChats(filterChatsByTab(chats));
    }, [chats, selectedTab]);

    const handleChange = (value: string) => {
        const searchTerm = value.toLowerCase();
        const filtered = chats.filter((chat) =>
            chat.title.toLowerCase().includes(searchTerm)
        );
        setFilteredChats(filtered);
    };

    return (
        <div className="w-96 border-r border-gray-200 flex flex-col mr-4">
            <div className="pr-4">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-semibold">Messages</h3>
                    <button
                        onClick={handleNewChatClick}
                        className="text-xl text-white bg-orange-500 rounded-full w-7 h-7"
                    >
                        +
                    </button>
                </div>
                {!!chats?.length && <Search onChange={handleChange} />}
                <div className="mb-2">
                    {!!chats?.length && (
                        <div className="flex space-x-4">
                            <button
                                onClick={() => setSelectedTab("All")}
                                className={`px-3 py-1 rounded-full text-sm ${
                                    selectedTab === "All"
                                        ? "bg-orange-100 text-orange-500"
                                        : "bg-gray-100 text-gray-400"
                                }`}
                            >
                                All
                            </button>
                            <button
                                onClick={() => setSelectedTab("Trips")}
                                className={`px-2 py-1 rounded-full text-sm ${
                                    selectedTab === "Trips"
                                        ? "bg-orange-100 text-orange-500"
                                        : "bg-[#F7F7F7] text-gray-400"
                                }`}
                            >
                                Trips
                            </button>
                            {/* add individual */}
                            <button
                                onClick={() => setSelectedTab("Individual")}
                                className={`px-2 py-1 rounded-full text-sm ${
                                    selectedTab === "Individual"
                                        ? "bg-orange-100 text-orange-500"
                                        : "bg-[#F7F7F7] text-gray-400"
                                }`}
                            >
                                Users
                            </button>
                            <button
                                onClick={() => setSelectedTab("Organization")}
                                className={`px-2 py-1 rounded-full text-sm ${
                                    selectedTab === "Organization"
                                        ? "bg-orange-100 text-orange-500"
                                        : "bg-[#F7F7F7] text-gray-400"
                                }`}
                            >
                                Organization
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="w-96 overflow-hidden overflow-y-auto">
                <ChatsList
                    conversations={filteredChats}
                    setIsModalOpen={handleOpenModal}
                />
                {error && <div>Error: {error}</div>}
            </div>
        </div>
    );
};
