import { PAGE_ROWS } from "../../../../constants";
import { ElementAdminDotMenuKeys } from "../../../../constants/admin";
import { IPaginatedData } from "../../../../types/dataTypes";
import Paginator from "../../../../components/Paginator";
import Table from "../../../../components/Table";
import Icon from "../../../../assets/svg/Icon";

export interface IAllSupportModules {
    handleClickDotsMenu: Function;
    handlePaginatorChange: Function;
    supportModules: IPaginatedData;
    loading: boolean;
}

export default function AllSupportModules({
    handleClickDotsMenu,
    handlePaginatorChange,
    supportModules,
    loading,
}: IAllSupportModules) {
    const tableHeaders = ["Title", "Section", "Platform", "Actions"];

    return (
        <>
            <Table headers={tableHeaders} isLoading={loading}>
                {supportModules?.data?.map((video) => (
                    <tr
                        key={video?.id}
                        className="hover:bg-neutral-50 cursor-pointer"
                    >
                        <td className="text-left flex flex-row">
                            <span
                                className="w-[300px] truncate"
                                title={video?.title}
                            >
                                {video?.title}
                            </span>
                        </td>
                        <td className="text-left">
                            <span
                                className="max-w-[100px] truncate"
                                title={video?.sectionLabel}
                            >
                                {video?.sectionLabel}
                            </span>
                        </td>
                        <td
                            className="text-left max-w-[300px] truncate capitalize"
                            title={video?.platform}
                        >
                            {video?.platform?.toLowerCase() || "-"}
                        </td>
                        <td className="flex flex-row items-center justify-center gap-2">
                            <Icon
                                className="w-[24px] cursor-pointer text-neutral-400 hover:text-neutral-900"
                                icon="PencilIconOutline"
                                onClick={() => {
                                    handleClickDotsMenu(
                                        ElementAdminDotMenuKeys.edit,
                                        video
                                    );
                                }}
                            />
                            <Icon
                                className="w-[24px] cursor-pointer text-neutral-400 hover:text-neutral-900"
                                icon="TrashIcon"
                                onClick={() => {
                                    handleClickDotsMenu(
                                        ElementAdminDotMenuKeys.delete,
                                        video
                                    );
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </Table>
            {supportModules?.count > PAGE_ROWS && (
                <div className="self-end text-xs z-20 mt-4">
                    <Paginator
                        count={supportModules?.count}
                        take={PAGE_ROWS}
                        onChange={handlePaginatorChange}
                        totalPages={supportModules?.totalPages}
                        currentPage={supportModules?.currentPage}
                    />
                </div>
            )}{" "}
        </>
    );
}
