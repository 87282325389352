import { useMemo } from "react";
import ListWithPagination from "../../../components/ListWithPagination";
import userPresenter from "./userPresenter";
import { UserItem } from "./UserItem";

interface IAdminAllUsersTable {
    users: any;
    handleClickDotsMenu: Function;
    handlePaginatorChange: Function;
    getData: Function;
}
export default function AllUsersList({
    users,
    handleClickDotsMenu,
    handlePaginatorChange,
    getData,
}: IAdminAllUsersTable) {
    const normalizedUsers = useMemo(() => {
        const dataN = users?.data?.map((u: any) => {
            return userPresenter(u);
        });
        return { ...users, data: dataN };
    }, [users]);

    const RenderItem = ({ item: user }: any) => {
        return <UserItem user={user} handleClickDotsMenu={handleClickDotsMenu} getData={getData} />;
    };

    return (
        <>
            <ListWithPagination
                items={normalizedUsers}
                handlePaginatorChange={handlePaginatorChange}
                renderItem={RenderItem}
                itemKey={(user: any) => {
                    return `userListItem_${user?.id}`;
                }}
            />
        </>
    );
}
