import moment from "moment";
import React from "react";
import InputErrorLabel from "../InputErrorLabel";
import InputLabel from "../InputLabel";
import { DATE_FORMAT } from "../../helpers";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { createTheme } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import { ThemeProvider } from "@mui/system";

const theme = createTheme({
    palette: {
        primary: {
            ...orange,
        },
        secondary: {
            ...orange,
        },
    },
});
export const DatePickerMaterial = React.forwardRef(
    (
        { onChange, id, label, error, minDate, maxDate, value, ...others }: any,
        ref: any
    ) => {
        const handleBlur = (e: any) => {
            onChange(e?.target?.value);
        };
        return (
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="en-us"
            >
                <InputLabel inputId={id} label={label} error={error} />
                <ThemeProvider theme={theme}>
                    <DatePicker
                        slotProps={{
                            textField: {
                                contentEditable: false,
                                variant: "standard",
                                fullWidth: true,
                            },
                        }}
                        minDate={minDate ? moment(minDate) : undefined}
                        maxDate={maxDate ? moment(maxDate) : undefined}
                        onChange={(v: any) => {
                            onChange(v);
                        }}

                        format={DATE_FORMAT}
                        onBlur={handleBlur}
                        onAccept={onChange}
                        value={moment(value)}
                        {...others}
                    />
                </ThemeProvider>

                <InputErrorLabel error={error} />
            </LocalizationProvider>
        );
    }
);
export default DatePickerMaterial;
