import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/ButtonSquare";
import EmptyScreen from "../../../../components/EmptyScreen/EmptyScreen";
import InputSearch from "../../../../components/InputSearch";
import Modal from "../../../../components/Modal";
import { ButtonSize, PAGE_ROWS, PAGE_SECTIONS } from "../../../../constants";
import {
    selectCurrentBreakpoint,
    setCurrentSection,
} from "../../../layout/layoutSlice";
import {
    getTripFiles,
    getTripFilesMobile,
    removeFileFromTrip,
    selectedTrip,
    selectedTripFiles,
} from "../../tripSlice";
import TripFilesTable from "./FilesTable";
import TripFilesList from "./FilesList";
import { selectProfile } from "../../../authentication/userSlice";

export default function TripFiles(props: any) {
    const [search, setSearch] = useState<string>("");
    const [newPage, setNewPage] = useState<any>({});
    const [deleteFileModalOpen, setDeleteFileModalOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<any>();
    const profile = useAppSelector(selectProfile);
    const files = useAppSelector(selectedTripFiles);
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    const handleNewFileClick = (e: any) => {
        navigate("new");
    };
    const { data, isLoading } = files;

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.TRIP_FILES })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getFiles(PAGE_ROWS, newPage.skip, newPage.currentPage, search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, search, trip]);

    const canEdit = useMemo(() => {
        return profile?.isAdmin || (trip?.canEdit && !trip?.suspended);
    }, [trip?.suspended, profile?.isAdmin, trip?.canEdit]);

    const getFiles = (
        take: number,
        newSkip: number,
        newPage: number,
        search: string
    ) => {
        if (trip?.id) {
            const actionDispatch = isDesktop
                ? getTripFiles
                : getTripFilesMobile;
            dispatch(actionDispatch(trip?.id, take, newSkip, newPage, search));
        }
    };

    const handleRemoveClick = async () => {
        if (fileToDelete?.id) {
            await dispatch(
                removeFileFromTrip(Number(trip?.id), fileToDelete?.id)
            );
        }
        handleDeleteModalClose();
        // getFiles(PAGE_ROWS, skip, currentPage, search);
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const handleSearchChange = (value: string) => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
        setSearch(value);
    };

    const handleDeleteModalClose = () => {
        setDeleteFileModalOpen(false);
    };

    const handleDelete = (file: any) => {
        setFileToDelete(file);
        setDeleteFileModalOpen(true);
    };

    const handleClick = (file: any) => {
        canEdit && navigate(`${file?.id}`);
    };

    const renderEditButton = useMemo(() => {
        return canEdit ? (
            <div className="flex-grow text-right self-center">
                <Button
                    label="Create"
                    onClick={handleNewFileClick}
                    size={ButtonSize.SMALL}
                />
            </div>
        ) : null;
    }, [canEdit]);
    return (
        <>
            <Modal open={deleteFileModalOpen} onClose={handleDeleteModalClose}>
                <div className="md:px-6">
                    <div className="text-base font-semibold text-gray-600">
                        Are you sure you want to remove this file?
                    </div>
                    <div className="text-base font-base">
                        "{fileToDelete?.name}"
                    </div>
                    <div className="flex gap-4 px-4 mt-8 text-right">
                        <Button
                            outlined={true}
                            label="Cancel"
                            onClick={handleDeleteModalClose}
                            size={ButtonSize.SMALL}
                        />

                        <Button
                            label="Remove"
                            onClick={handleRemoveClick}
                            size={ButtonSize.SMALL}
                        />
                    </div>
                </div>
            </Modal>

            <div className="align-middle inline-block min-w-full">
                <div className="flex flex-row flex-wrap-reverse md:flex-wrap w-full gap-4 pb-8">
                    <div className="w-full md:w-fit text-left self-center">
                        <InputSearch
                            onChange={handleSearchChange}
                            name="search"
                            id="search"
                            placeholder="Search"
                        />
                    </div>
                    {renderEditButton}
                </div>
                {isDesktop ? (
                    <TripFilesTable
                        files={files}
                        canEdit={canEdit}
                        handleDelete={handleDelete}
                        handleClick={handleClick}
                        handlePaginatorChange={handlePaginatorChange}
                    />
                ) : (
                    <TripFilesList
                        files={files}
                        canEdit={canEdit}
                        handleDelete={handleDelete}
                        handleClick={handleClick}
                        handleNext={handlePaginatorChange}
                    />
                )}
                {!data?.length && !isLoading && (
                    <EmptyScreen text="There are no files to show." />
                )}
            </div>
        </>
    );
}
