import React, { FC, InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import OthersAutocomplete from "./OthersAutocomplete";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: object;
  register?: any;
  className?: string;
  control?: any;
  onTextChange?: any;
  handleSelect?: Function;
}

const OthersAutocompleteWithValid: FC<InputProps> = ({
  name,
  error,
  label,
  control,
  onChange,
  onTextChange,
  ...rest
}) => {
  const handleChange = (other: any, handleOnChangeValidator: Function) => {
    if (onChange) onChange(other);
    if (handleOnChangeValidator) handleOnChangeValidator(other);
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }: any) => (
        <OthersAutocomplete
          name={name}
          label={label}
          onChange={(a: any) => {
            handleChange(a, onChange);
          }}
          onTextChange={onTextChange}
          onBlur={onBlur}
          ref={ref}
          error={error && Object.keys(error).length > 0 && "Required field"}
          {...rest}
        />
      )}
    />
  );
};

export default OthersAutocompleteWithValid;
