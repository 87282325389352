import Icon from "../../../../assets/svg/Icon";

interface FileFormProps {
    file: any;
    handleClick?: any;
    handleRemove?: any;
    canEdit?: boolean;
}

export default function FileItem(props: FileFormProps) {
    const { file, handleRemove, handleClick, canEdit } = props;

    return (
        <div className="p-6 flex items-center justify-between border text-sm text-neutral-600 border-gray-200 rounded-lg">
            <div
                className="flex items-center"
                onClick={() => {
                    return handleClick && handleClick(file);
                }}
            >
                <div className="mr-4">
                    <Icon icon="FileOutlineIcon" className="w-7" />
                </div>
                <div>
                    {file?.name?.length > 25
                        ? "..." +
                          file?.name?.substring(
                              file?.name?.length - 25,
                              file?.name?.length
                          )
                        : file?.name}
                </div>
            </div>

            <div className="ml-4 cursor-pointer hover:!text-gray-800 flex items-center justify-end gap-4">
                {file?.url && (
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className="text-left"
                    >
                        <a
                            href={file?.url}
                            target="_blank"
                            rel="noreferrer"
                            download={true}
                        >
                            {" "}
                            <Icon
                                tabIndex={0}
                                icon={"DownloadIcon"}
                                className="w-6 h-6 text-gray-400 hover:!text-gray-900"
                            />
                        </a>
                    </span>
                )}
                {canEdit && (
                    <Icon
                        onClick={() => {
                            return handleRemove(file);
                        }}
                        icon="TrashIcon"
                        className="w-7"
                    />
                )}
            </div>
        </div>
    );
}
