import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import useGoogleMaps from "../../../../../../../hooks/usePlacesService";
import useActivityContact from "../../../../../../../hooks/useActivityContact";
import { ActivityType } from "../../../../../../../constants";
import ContactForm from "../../../ContactForm";
import Modal from "../../../../../../../components/Modal";
import GoogleMap from "../../../../../../../components/Map";
import InputWithValid from "../../../../../../../components/Input";
import DatePickerMaterial from "../../../../../../../components/DatePickerMaterial";
import TimePickerMaterial from "../../../../../../../components/TimePickerMaterial";
import ContactCard from "../../../ContactCard";
import TextareaWithValid from "../../../../../../../components/TextArea";
import ButtonSquared from "../../../../../../../components/ButtonSquare";
import {
    activityFormCurrentStep,
    backFormStep,
    selectActivityForm,
    selectedTrip,
    submitActivityFormStep,
    updateActivityForm,
} from "../../../../../tripSlice";
import accountImage from "../../../../../../../assets/images/accountAdd.png";
import InputSwitch from "../../../../../../../components/InputSwitch/InputSwitch";
import InputLabel from "../../../../../../../components/InputLabel";
import {
    parseMomentUtcToLocal,
    parsePhoneNumber,
} from "../../../../../../../helpers";
import { Paragraph } from "../../../../../../../components/Paragraph/Paragraph";
import PhoneInputWithValid from "../../../../../../../components/PhoneInput";
import OtherForm from "../../../../../../others/OtherForm";
import { selectProfile } from "../../../../../../authentication/userSlice";
import Icon from "../../../../../../../assets/svg/Icon";

const schema = yup.object({
    startDate: yup.date().when("isLibraryElement", {
        is: true,
        then: yup.date().required("This field is required"),
        otherwise: yup.date()
    }),
    endDate: yup.date().when("isLibraryElement", {
        is: true,
        then: yup.date().required("This field is required"),
        otherwise: yup.date()
    }),
    startHour: yup
        .mixed()
        .test(`test-hour-format`, "Invalid date format", function (value) {
            return !!moment(value).isValid();
        })
        .when("isLibraryElement", {
            is: true,
            then: yup.mixed().required("This field is required"),
            otherwise: yup.mixed()
        }),
    endHour: yup
        .mixed()
        .test(`test-hour-format`, "Invalid date format", function (value) {
            return !!moment(value).isValid();
        })
        .when("isLibraryElement", {
            is: true,
            then: yup.mixed().required("This field is required"),
            otherwise: yup.mixed()
        }),
    contacts: yup.array(),
    title: yup.string(),
    description: yup.string().max(5000).nullable(),
    website: yup.string().url("Enter a valid url").nullable(),
    phone: yup.string().nullable(),
    isLibraryElement: yup.boolean(),
});

const OtherInfoForm = React.forwardRef((props: any, ref: any) => {
    const { isLibraryElement } = props;
    const [searchParams] = useSearchParams();
    const [mapMarker, setmapMarker] = useState<any | null>(null);
    const [currentOther, setCurrentOther] = useState<any | null>(null);
    const [editOtherModalOpen, setEditOtherModalOpen] =
        useState<boolean>(false);
    const dispatch = useAppDispatch();
    const trip = useAppSelector(selectedTrip);
    const { register, formState, handleSubmit, control, setValue, reset } =
        useForm({
            resolver: yupResolver(schema),
            mode: "onChange",
        });
    const { isValid, errors } = formState;
    const [showEndHourInput, setShowEndHourInput] = useState<boolean>(false);
    const profile = useAppSelector(selectProfile);

    const mapService = useGoogleMaps();
    const {
        contacts,
        contactsModalOpen,
        handleContactEditClick,
        handleContactRemoveClick,
        handleContactSubmit,
        handleContactToggle,
        selectedContact,
        setSelectedContact,
        setContacts,
    } = useActivityContact([]);
    const currentStep = useAppSelector(activityFormCurrentStep);
    const activityForm = useAppSelector(selectActivityForm);
    const {
        type,
        other,
        contacts: currentContacts,
        description,
    } = activityForm || {};
    let { activityType } = useParams();

    useEffect(() => {
        setValue("contacts", contacts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts]);

    useEffect(() => {
        setValue("isLibraryElement", !!isLibraryElement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLibraryElement]);

    useEffect(() => {
        if (other?.lat && mapService.initialized && !mapService.isLoading) {
            // dispatch(setSelectedHotel({ selectedHotel: hotel }));
            setContacts(currentContacts);

            const marker = {
                position: {
                    lat: other?.lat,
                    lng: other?.lng,
                },
                title: other?.name,
            };
            setmapMarker(marker);
        }
        const iSDate =
            activityForm?.startDate || searchParams.get("date") || new Date();

        const iEDate = iSDate;
        const startDateInit = moment.utc(iSDate).format("yyyy-MM-DD");
        const endDateInit = activityForm.endDate
            ? moment.utc(activityForm.endDate).format("yyyy-MM-DD")
            : moment.utc(iEDate).add(1, "day").format("yyyy-MM-DD");

        reset({
            title: other?.name || "",
            description: description,
            startDate: startDateInit,
            endDate: endDateInit,
            startHour: moment(activityForm.startHour || "09:00", "HH:mm"),
            endHour: moment(activityForm.endHour || "09:00", "HH:mm"),
            website: activityForm?.website || other?.website,
            phone: activityForm?.phone || other?.phone,
        });
        setShowEndHourInput(activityForm.startHour !== activityForm.endHour);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        trip?.startDate,
        trip?.endDate,
        mapService.initialized,
        activityForm.hotel,
        activityForm.title,
        activityForm.startDate,
        activityForm.endDate,
        activityForm.startHour,
        activityForm.endHour,
        other?.lat,
    ]);

    const handleSubmitForm = async (data: any) => {
        const startHour = data.startHour.format("HH:mm");
        const endHour = data.endHour.format("HH:mm");
        const activity: any = {
            startDate: moment(data.startDate).format("yyyy-MM-DD"),
            endDate: moment(data.starDate).format("yyyy-MM-DD"),
            startHour: startHour,
            title: data.title,
            description: data.description,
            endHour: showEndHourInput ? endHour : startHour,
            contacts: data.contacts,
            website: data.website,
            phone: data.phone,
        };

        dispatch(
            submitActivityFormStep(
                activity,
                activityType || ActivityType.HOTEL,
                currentStep || ""
            )
        );
    };

    const navigateBack = () => {
        dispatch(backFormStep(type, currentStep || ""));
    };

    const minStartDate = useMemo(() => {
        return parseMomentUtcToLocal(trip?.startDate).toDate() as any;
    }, [trip?.startDate]);

    const maxStartDate = useMemo(() => {
        return moment.utc(trip?.endDate).endOf("day").toDate() as any;
    }, [trip?.endDate]);

    const handleSwitchChange = (e: any) => {
        setShowEndHourInput(!!e.target.checked);
    };

    return (
        <div ref={ref} className="pb-16">
            <Modal
                open={contactsModalOpen}
                onClose={() => handleContactToggle(false)}
                hideCloseButton
            >
                <ContactForm
                    onClose={() => handleContactToggle(false)}
                    onSubmit={handleContactSubmit}
                    contact={contacts && contacts[selectedContact]}
                />
            </Modal>
            {editOtherModalOpen && (
                <Modal
                    open={editOtherModalOpen}
                    onClose={() => {
                        setEditOtherModalOpen(false);
                    }}
                >
                    <OtherForm
                        onCancel={() => {
                            setEditOtherModalOpen(false);
                        }}
                        currentOther={currentOther}
                        onChange={(other: any) => {
                            dispatch(
                                updateActivityForm({
                                    activityForm: { ...activityForm, other },
                                })
                            );
                            setCurrentOther(other);
                            setEditOtherModalOpen(false);
                        }}
                    />
                </Modal>
            )}

            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
                className="text-left lg:px-6"
            >
                {other && (
                    <>
                        {other.userId === profile.id && (
                            <div className="flex w-full flex-row justify-end">
                                <Icon
                                    icon={"PencilIcon"}
                                    className={`ml-2 w-5 hover:text-black cursor-pointer text-neutral-600 mr-4`}
                                    onClick={() => {
                                        setEditOtherModalOpen(true);
                                    }}
                                />
                            </div>
                        )}

                        <div className="col-span-6">
                            <InputWithValid
                                register={register}
                                name="title"
                                id="title"
                                type="text"
                                label="Title"
                                defaultValue={
                                    activityForm?.title ||
                                    activityForm?.other?.name ||
                                    ""
                                }
                                placeholder="Activity title"
                            />
                        </div>

                        {mapMarker && (
                            <div className="mt-6 shadow-sm border border-gray-300 rounded-xl">
                                <GoogleMap marker={mapMarker} />
                                <div className="grid grid-cols-6 gap-x-12 px-4 py-2">
                                    <div className="mt-6 col-span-6 lg:col-span-4">
                                        <div className="text-sm font-medium text-neutral-600">
                                            Address
                                        </div>
                                        <div className="text-base">
                                            {other?.fullAddress}
                                        </div>
                                    </div>
                                    {other?.zipCode?.length &&
                                        other.zipCode !== "undefined" && (
                                            <div className="mt-6 col-span-6 lg:col-span-2">
                                                <div className="text-sm font-medium text-neutral-600">
                                                    Postal code
                                                </div>
                                                <div className="text-base">
                                                    {other.zipCode}
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}
                        <div className="grid grid-cols-6 gap-x-12">
                            {other.phone && other.phone !== "undefined" && (
                                <div className="mt-6 col-span-6 lg:col-span-3">
                                    <div className="text-sm font-medium text-neutral-600">
                                        Phone number
                                    </div>
                                    <div className="text-base">
                                        {parsePhoneNumber(other.phone)}
                                    </div>
                                </div>
                            )}
                            {other.website && (
                                <div className="mt-6 col-span-6">
                                    <div className="text-sm font-medium text-neutral-600">
                                        Website
                                    </div>
                                    <Paragraph className="text-base">
                                        <a
                                            className="text-base block mt-1 mb-2"
                                            target="_blank"
                                            rel="noreferrer"
                                            href={other.website}
                                        >
                                            {other.website}
                                        </a>
                                    </Paragraph>
                                </div>
                            )}
                        </div>
                        <div className="mb-12 grid grid-cols-6 gap-x-12">
                            {!isLibraryElement && (
                                <>
                                    <div className="mt-6 col-span-6 lg:col-span-3">
                                        <DatePickerMaterial
                                            control={control}
                                            minDate={minStartDate}
                                            maxDate={maxStartDate}
                                            name="startDate"
                                            id="startDate"
                                            label="Start date"
                                            error={errors["startDate"]?.message}
                                        />
                                    </div>

                                    <div className="mt-6 col-span-6 lg:col-span-3">
                                        <TimePickerMaterial
                                            control={control}
                                            label="Start Hour"
                                            defaultValue={
                                                moment(
                                                    activityForm?.startHour ||
                                                        "09:00",
                                                    "HH:mm"
                                                ) as any
                                            }
                                            id="startHour"
                                            name="startHour"
                                            error={errors["startHour"]?.message}
                                        />
                                    </div>
                                    <div className="mt-6 col-span-6 lg:col-span-3">
                                        <InputLabel
                                            inputId={"endHour"}
                                            label={"End Hour"}
                                            error={false}
                                        />
                                        <div className="flex flex-row gap-4 items-center">
                                            <InputSwitch
                                                id="showEndHour"
                                                name="showEndHour"
                                                onClick={handleSwitchChange}
                                                defaultChecked={
                                                    activityForm.startHour !==
                                                    activityForm.endHour
                                                }
                                            />
                                            {showEndHourInput && (
                                                <div className="flex-grow">
                                                    <TimePickerMaterial
                                                        control={control}
                                                        defaultValue={
                                                            moment(
                                                                activityForm?.endHour ||
                                                                    "09:00",
                                                                "HH:mm"
                                                            ) as any
                                                        }
                                                        id="endHour"
                                                        name="endHour"
                                                        error={
                                                            errors["endHour"]
                                                                ?.message
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="text-sm font-semibold mb-4">
                            Contacts
                        </div>
                        <div
                            onClick={() => {
                                setSelectedContact(null);
                                handleContactToggle(true);
                            }}
                            className="flex cursor-pointer mb-3 border border-gray-200 rounded-xl p-6 justify-center"
                        >
                            <div className="flex flex-wrap items-center lg:min-w-[300px]">
                                <img
                                    src={accountImage}
                                    className="h-16 mr-12"
                                    alt=""
                                />
                                <div className="flex flex-col">
                                    <div className="text-semibold text-orange-600">
                                        Add contact
                                    </div>
                                    <div className="text-sm text-neutral-400">
                                        Submit contact information
                                    </div>
                                </div>
                            </div>
                        </div>
                        {contacts && (
                            <div className="flex gap-6 flex-wrap">
                                {contacts.map((c: any, index: number) => (
                                    <ContactCard
                                        handleEdit={() => {
                                            handleContactEditClick(index);
                                        }}
                                        handleRemove={() => {
                                            handleContactRemoveClick(index);
                                        }}
                                        contact={c}
                                        key={index}
                                    />
                                ))}
                            </div>
                        )}

                        <div className="mt-6">
                            <TextareaWithValid
                                register={register}
                                label="Description"
                                id="description"
                                name="description"
                                error={errors["description"]?.message}
                            />
                        </div>
                        <div className="mt-6">
                            <InputWithValid
                                register={register}
                                label="Website"
                                type="text"
                                id="website"
                                name="website"
                                error={errors["website"]?.message}
                            />
                        </div>
                        <div className="mt-6">
                            <span className="block font-medium text-neutral-600 text-sm">
                                Phone number
                            </span>
                            <PhoneInputWithValid
                                control={control}
                                id="phone"
                                name="phone"
                                error={errors["phone"]?.message}
                            />
                        </div>
                    </>
                )}

                <div className="flex gap-3 justify-end px-4 py-3 text-right mt-12 lg:absolute right-4 bottom-4">
                    <ButtonSquared
                        onClick={navigateBack}
                        outlined
                        type="button"
                        label="Back"
                    />
                    <ButtonSquared
                        type="submit"
                        label="Next"
                        disabled={!isValid}
                    />
                </div>
            </form>
        </div>
    );
});

export default OtherInfoForm;
