import { Route } from "react-router-dom";
import InsightsSection from "../features/insights";
import RequireAuth from "../hooks/RequiresAuth";

export const InsightsRoute = (
    <Route path="/insights">
        <Route
            path=""
            element={
                <RequireAuth protectedRoute={true}>
                    <InsightsSection />
                </RequireAuth>
            }
        />
    </Route>
);
