import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import {
    getTripsByOrganization,
    selectedOrganization,
} from "../organizationSlice";
import OrganizationTripsCards from "./OrganizationTripsCards";
import InputSearch from "../../../components/InputSearch";
import SelectInput from "../../../components/InputSelect/SelectInput";
import {
    ButtonSize,
    PAGE_ROWS,
    PAGE_SECTIONS,
    StatusTripOrganizationOptions,
    TripFilters,
} from "../../../constants";
import ButtonSquared from "../../../components/ButtonSquare";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Paginator from "../../../components/Paginator";
import { setCurrentSection } from "../../layout/layoutSlice";
import { selectProfile } from "../../authentication/userSlice";
export default function OrganizationTrips(props: any) {
    const organization = useAppSelector(selectedOrganization);
    const [search, setSearch] = useState<string>("");
    const [status, setStatus] = useState<string>(TripFilters.ACTIVE);
    const [trips, setTrips] = useState<any>([]);
    const [newPage, setNewPage] = useState<any>({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profile = useAppSelector(selectProfile);

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.ORGANIZATION_TRIPS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);

    useEffect(() => {
        if (organization?.id) {
            fetchTrips();
        }
    }, [search, status, organization?.id, newPage?.skip]);

    const fetchTrips = async () => {
        const filters = {
            search,
            status,
            take: newPage?.take || PAGE_ROWS,
            skip: newPage?.skip || 0,
        };
        const fetchedTrips = await dispatch(
            getTripsByOrganization(organization.id, filters)
        );
        setTrips(fetchedTrips);
    };

    const handleSearchChange = (query: string) => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
        setSearch(query);
    };

    const handleElementFilterChange = (value: string) => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
        setStatus(value);
    };
    const handleAddNewTrip = () => {
        navigate("/trips/new");
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const renderAddButton = () => {
        return (
            (organization?.canEdit || profile?.isAdmin) && (
                <div className="col-span-1 mb-5 lg:col-span-6 w-full flex justify-end">
                    <ButtonSquared
                        size={ButtonSize.SMALL}
                        label={"Create Trip"}
                        onClick={handleAddNewTrip}
                    />
                </div>
            )
        );
    };

    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-12 items-start gap-6 lg:gap-10">
                <div className="col-span-1 lg:col-span-4 mt-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>
                <div className="col-span-1 lg:col-span-2">
                    <SelectInput
                        onChange={handleElementFilterChange}
                        label="Status"
                        options={StatusTripOrganizationOptions}
                        defaultValue={status}
                    />
                </div>
                {renderAddButton()}
            </div>
            <div className="grid grid-cols-6 gap-4 lg:gap-8 w-full">
                <OrganizationTripsCards trips={trips?.data} />
            </div>
            {trips?.count > PAGE_ROWS && (
                <div className="w-full mt-8">
                    <Paginator
                        count={trips?.count}
                        take={trips?.take}
                        onChange={handlePaginatorChange}
                        totalPages={trips?.totalPages}
                        currentPage={trips?.currentPage}
                    />
                </div>
            )}
            {!organization?.isLoading && !trips?.data?.length && (
                <div className="text-neutral-400 text-sm py-6">
                    There are no trips to show.
                </div>
            )}
        </>
    );
}
