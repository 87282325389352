import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ButtonSquared from "../../components/ButtonSquare";
import ModalConfirmation from "../../components/Modal/ConfirmationModal";
import { ButtonSize, PAGE_SECTIONS } from "../../constants";
import { selectProfile } from "../authentication/userSlice";
import { isLoadingSelector } from "../layout";
import { setCurrentSection } from "../layout/layoutSlice";
import OrganizationEmpty from "./Organization/OrganizationEmpty";
import {
    acceptInvitation,
    rejectInvitation,
    selectOrganizations,
} from "./organizationSlice";
import OrganizationsListItem from "./OrganizationsListCard";
import { Modal_Size } from "../../components/Modal";

export default function OrganizationsList(props: any) {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState<any>();
    const { email } = useAppSelector(selectProfile);
    const dispatch = useAppDispatch();
    const organizations = useAppSelector(selectOrganizations);
    const navigate = useNavigate();
    const isLoading = useAppSelector(isLoadingSelector);

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ORGANIZATIONS })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedOrg) {
            toggleOpenModal();
        }
    }, [selectedOrg]);

    const handleDecline = async (organizationId: number) => {
        const selectedOrganization = organizations.find(
            (org) => org.id === organizationId
        );
        setSelectedOrg(selectedOrganization);
    };
    const declineInvite: any = async () => {
        if (selectedOrg)
            await dispatch(rejectInvitation(selectedOrg.id, email));
        if (modalOpen) toggleOpenModal();
        setSelectedOrg(null);
    };
    const handleAccept = async (organizationId: number) => {
        await dispatch(acceptInvitation(organizationId, email));
        if (modalOpen) toggleOpenModal();
        navigate(`${organizationId}`);
    };
    const toggleOpenModal = () => {
        setModalOpen((prevState) => {
            return !prevState;
        });
    };

    const handleOrgClick = (organizationId: number) => {
        navigate(`${organizationId}`);
    };
    const handleClickNewOrg = () => {
        navigate("new");
    };
    return isLoading ? null : (
        <>
            <ModalConfirmation
                open={modalOpen}
                title={`Decline Invitation to ${selectedOrg?.name} organization?`}
                description={`Are you sure you don't want to join ${selectedOrg?.name}? You can always change your mind later.`}
                handleCancel={toggleOpenModal}
                handleSubmit={declineInvite}
                okButtonText="Decline invitation"
                okButtonsClass="w-48"
                cancelButtonsClass="w-28"
                size={Modal_Size.small}
                className=" !min-h-64 "
            />
            <div className="flex w-full justify-end mb-4">
                <ButtonSquared
                    size={ButtonSize.FIT}
                    label="Create Organization"
                    onClick={handleClickNewOrg}
                />
            </div>
            {organizations?.length ? (
                <div className="flex flex-row items-start justify-start gap-4 flex-wrap">
                    {organizations &&
                        organizations.map((organization) => (
                            <OrganizationsListItem
                                key={`organizations_${organization?.id}`}
                                organization={organization}
                                handleAccept={() => {
                                    handleAccept(organization?.id);
                                }}
                                handleDecline={() => {
                                    handleDecline(organization?.id);
                                }}
                                handleClick={() => {
                                    handleOrgClick(organization?.id);
                                }}
                            />
                        ))}
                </div>
            ) : (
                <OrganizationEmpty toggleEdit={handleClickNewOrg} />
            )}
        </>
    );
}
