import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { handleError } from "../authentication/userSlice";
import { PAGE_ROWS } from "../../constants";

interface IPaginatedData {
    data: Array<any>;
    count: number;
    currentPage: number;
    totalPages: number;
    skip: number;
    take: number;
    isLoading: boolean;
    requirements?: number;
}

const IPaginatedDataInitialState = {
    data: [],
    count: 0,
    currentPage: 1,
    totalPages: 1,
    skip: 0,
    take: PAGE_ROWS,
    isLoading: false,
};

export interface InsightsState {
    isLoading: Boolean;
    insights: IPaginatedData;
    sharedInsights: IPaginatedData;
    selectedInsight: any | null;
    error: string;
}

const initialState: InsightsState = {
    isLoading: false,
    insights: IPaginatedDataInitialState,
    sharedInsights: IPaginatedDataInitialState,
    selectedInsight: null,
    error: "",
};

export const insightsSlice = createSlice({
    name: "insights",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setInsights: (state, action: PayloadAction<{ insights: any }>) => {
            state.insights = { ...action.payload.insights };
        },
        setSharedInsights: (
            state,
            action: PayloadAction<{ sharedInsights: any }>
        ) => {
            state.sharedInsights = { ...action.payload.sharedInsights };
        },
        setSelectedInsight: (
            state,
            action: PayloadAction<{ selectedInsight: Object | null }>
        ) => {
            state.selectedInsight = action.payload.selectedInsight
                ? { ...action.payload.selectedInsight }
                : null;
        },
    },
});

export const {
    isLoading,
    setInsights,
    setSelectedInsight,
    setSharedInsights,
    setError,
} = insightsSlice.actions;
export const selectInsights = (state: RootState) => state.insights.insights;
export const selectSharedInsights = (state: RootState) =>
    state.insights.sharedInsights;
export const selectedInsight = (state: RootState) =>
    state.insights.selectedInsight;
export const selectedOrganization = (state: RootState) =>
    state.organization?.selectedOrganization;
export const selectedOrganizationUsers = (state: RootState) =>
    state.organization?.selectedOrganization?.users;

export const getInsights =
    (
        take: number,
        skip: number,
        type: string = "",
        search: string = "",
        startDate: Date,
        endDate: Date
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(
                setInsights({
                    insights: {
                        ...getState().insights.insights,
                        isLoading: true,
                    },
                })
            );
            const response = await serverApi.getInsights(
                take,
                skip,
                type,
                search,
                startDate,
                endDate
            );
            dispatch(setInsights({ insights: response.data }));
        } catch (error) {
            dispatch(
                setInsights({
                    insights: {
                        ...getState().insights.insights,
                        isLoading: false,
                    },
                })
            );
            dispatch(handleError(error));
        }
    };

export const shareInsight = async (insightId: number, emails: string[]) => {
    try {
        const response = await serverApi.shareInsight(insightId, emails);
        return response.data;
    } catch (error) {
        handleError(error);
        return [];
    }
};

export const deleteSharedInsight = async (sharedInsightId: number) => {
    try {
        const response = await serverApi.deleteSharedInsight(sharedInsightId);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const getSharedInsights =
    (
        take: number,
        skip: number,
        type: string = "",
        search: string = "",
        startDate: Date,
        endDate: Date
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(
                setSharedInsights({
                    sharedInsights: {
                        ...getState().insights.sharedInsights,
                        isLoading: true,
                    },
                })
            );
            const response = await serverApi.getSharedInsights(
                take,
                skip,
                type,
                search,
                startDate,
                endDate
            );
            dispatch(
                setSharedInsights({
                    sharedInsights: { ...response.data, isLoading: false },
                })
            );
        } catch (error) {
            dispatch(
                setSharedInsights({
                    sharedInsights: {
                        ...getState().insights.sharedInsights,
                        isLoading: false,
                    },
                })
            );
            dispatch(handleError(error));
        }
    };

export const downloadInsightPDF =
    (insightId: Number): AppThunk =>
    async (dispatch) => {
        dispatch(isLoading(true));
        try {
            await serverApi.getInsightPdf(insightId);
        } catch (error) {
            dispatch(handleError(error));
        }
        dispatch(isLoading(false));
    };

export default insightsSlice.reducer;
