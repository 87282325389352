import { ChevronRightIcon } from "@heroicons/react/solid";
import { FC, useMemo } from "react";
import { useAppSelector } from "../../../../app/hooks";
import Icon from "../../../../assets/svg/Icon";
import { selectCurrentBreakpoint } from "../../../layout/layoutSlice";
import { activityCardPresenter } from "./presenters";
import { timeToMeridianTime } from "../../../../helpers";
import { mdiContentDuplicate } from "@mdi/js";
import { selectedTrip } from "../../tripSlice";

const ActivityCard: FC<any> = ({
    title,
    activity,
    handleClick,
    onDuplicate,
    ...rest
}) => {
    const { checkoutDay, icon, cardTitle, startHourLabel } =
        activityCardPresenter(activity);
    const { isMobile } = useAppSelector(selectCurrentBreakpoint);
    const trip = useAppSelector(selectedTrip);
    const { endHour, startHour } = useMemo(() => {
        return {
            endHour: timeToMeridianTime((activity || {}).endHour),
            startHour: timeToMeridianTime((activity || {}).startHour),
        };
    }, [activity]);

    const handleDuplicateActivity = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onDuplicate && onDuplicate();
    };
    return (
        <div
            onClick={handleClick}
            className="flex shadow-md border border-neutral-200 rounded-xl py-6 px-6 lg:px-12 hover:bg-[#FDF2EE] cursor-pointer w-full"
        >
            <div className="flex items-center mr-4 lg:mr-8">
                <Icon icon={icon} className="text-orange-600 w-6 lg:w-8" />
            </div>
            <div className="flex-grow">
                <div className="text-left text-sm lg:text-base font-semibold">
                    {cardTitle}
                </div>
                <div className="w-full flex">
                    {!activity.isStayDay ? (
                        <>
                            <div className="text-left text-sm flex flex-col">
                                <span className="font-semibold text-xs text-neutral-400">
                                    {startHourLabel}
                                </span>
                                <span>{startHour}</span>
                            </div>
                        </>
                    ) : checkoutDay ? (
                        <div className="flex-grow">
                            <div className="text-left text-sm flex flex-col">
                                <span className="font-semibold text-xs text-neutral-400">
                                    Check out
                                </span>
                                <span>{endHour}</span>
                            </div>
                        </div>
                    ) : (
                        <div className="flex-grow">
                            <div className="text-left text-sm flex flex-col">
                                <span className="font-semibold text-xs text-neutral-400">
                                    Hotel Stay
                                </span>
                                <span></span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="justify-end flex flex-row items-center">
                {!!trip?.canEdit && (
                    <div
                        title="Duplicate"
                        onClick={handleDuplicateActivity}
                        className="col-span-1 flex items-center  h-fit p-2 rounded-lg mr-2 hover:text-alert_red hover:bg-white"
                    >
                        <Icon
                            materialIcon={mdiContentDuplicate}
                            className={`hover:text-alert_red w-5`}
                        />
                    </div>
                )}
                {!isMobile && (
                    <div className="col-span-1 flex items-center justify-end">
                        <ChevronRightIcon width={24} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ActivityCard;
