import Icon from "../../../../assets/svg/Icon";
import ButtonSquared from "../../../../components/ButtonSquare";
import DotsMenu from "../../../../components/DotsMenu";
import ListWithPagination from "../../../../components/ListWithPagination";
import { ButtonSize } from "../../../../constants";
import { ElementAdminDotMenuOptions } from "../../../../constants/admin";
import { IPaginatedData } from "../../../../types/dataTypes";

interface ElementsAdminListPropsI {
    elements: IPaginatedData;
    handlePaginatorChange: any;
    handleClickDotsMenu?: Function;
    onSelect?: Function;
}

const ElementsAdminList = (props: ElementsAdminListPropsI) => {
    const { elements, handlePaginatorChange, handleClickDotsMenu, onSelect } =
        props;

    const renderElement = ({ item: element, index }: any) => {
        return (
            <div
                className={`mb-4 pt-4 ${
                    !!index ? "border-t border-neutral-100" : ""
                }`}
            >
                <div className="flex flex-row text-left">
                    <span className="truncate text-base font-semibold flex-grow">
                        {element?.name}
                    </span>
                    {handleClickDotsMenu && (
                        <div className="text-left">
                            <DotsMenu
                                options={ElementAdminDotMenuOptions}
                                handleClickMenu={(k: any) => {
                                    handleClickDotsMenu(k, element);
                                }}
                            />
                        </div>
                    )}
                    {onSelect && (
                        <div className="text-left">
                            <ButtonSquared
                                size={ButtonSize.SMALL}
                                onClick={() => {
                                    onSelect(element);
                                }}
                                label="Select"
                            />
                        </div>
                    )}
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="CalendarMonthIcon"
                    />
                    <span className="truncate text-xs">{element?.date}</span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="MarkerIcon"
                    />

                    <span className="truncate text-xs">
                        {element?.location}
                    </span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="ParticipantsIcon"
                    />

                    <span className="truncate text-xs">{element?.user}</span>
                </div>
            </div>
        );
    };

    return (
        <ListWithPagination
            items={elements}
            renderItem={renderElement}
            handlePaginatorChange={handlePaginatorChange}
            itemKey={(element: any) => `element_${element.id}`}
        />
    );
};

export default ElementsAdminList;
