import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { PAGE_SECTIONS } from "../../constants";
import { selectProfile } from "../authentication/userSlice";
import { setCurrentSection } from "../layout/layoutSlice";
import {
    getOrganizations,
    setHidrated,
    setOrganizations,
} from "./organizationSlice";
import { Outlet } from "react-router";

export default function OrganizationsHome(props: any) {
    const profile = useSelector(selectProfile);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (profile?.id) {
            dispatch(getOrganizations(true));
        }
        return () => {
            dispatch(setHidrated(false));
            dispatch(setOrganizations({ organizations: [] }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile?.id]);

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ORGANIZATIONS })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Outlet />;
}
