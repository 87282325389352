import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Icon from "../../../assets/svg/Icon";
import DatePickerMaterial from "../../../components/DatePickerMaterial/DatePickerMaterial";
import InputSearch from "../../../components/InputSearch";
import SelectInput from "../../../components/InputSelect/SelectInput";
import Paginator from "../../../components/Paginator";
import Table from "../../../components/Table";
import {
    ActivityIcon,
    ActivityTypeFilterOptions,
    PAGE_ROWS,
} from "../../../constants";
import { selectCurrentBreakpoint } from "../../layout/layoutSlice";
import { getAdminInsights, selectAdminInsights } from "../adminSlice";
import InsightsAdminResponsiveList from "./AllInsightsResponsiveList";
import { DATE_FORMAT, parseMomentUtc } from "../../../helpers";

export const normalizeInsight = (insight: any) => {
    const activity =
        insight.activity?.hotelActivity?.hotel ||
        insight.activity?.otherActivity?.other ||
        insight.activity?.restaurantActivity?.restaurant;

    let location = "";

    if (
        activity?.city &&
        activity?.city != "null" &&
        activity?.city != "undefined"
    ) {
        location += `${activity?.city}, `;
    }

    if (
        activity?.country &&
        activity?.country != "null" &&
        activity?.country != "undefined"
    ) {
        location += `${activity?.country}`;
    }
    return {
        id: insight.id,
        type: insight?.activity?.type,
        title: insight.activity?.title || activity.name,
        date: moment(insight.activity?.startDate).format(DATE_FORMAT),
        location,
        user: `${insight?.user?.firstName}, ${insight?.user?.lastName}`,
    };
};

export default function AllInsightsList(props: any) {
    const tableHeaders = ["Element", "Date", "Location", "User"];
    const dispatch = useAppDispatch();
    const [newPage, setNewPage] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    const [type, setType] = useState<string>("");
    const insights = useAppSelector(selectAdminInsights);
    const [startDate, setStartDate] = useState<any>(
        moment().subtract(6, "months").startOf("day").toDate()
    );
    const [endDate, setEndDate] = useState<any>(moment().endOf("day").toDate());
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, search, type, startDate, endDate]);

    const getData = () => {
        dispatch(
            getAdminInsights(
                PAGE_ROWS,
                newPage.skip,
                type,
                search,
                parseMomentUtc(startDate).startOf("day").toDate(),
                parseMomentUtc(endDate).endOf("day").toDate()
            )
        );
    };

    const resetPage = () => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleSearchChange = (query: string) => {
        resetPage();
        setSearch(query);
    };
    const handleElementFilterChange = (value: string) => {
        const formattedValue = value === "ALL" ? "" : value;
        resetPage();
        setType(formattedValue);
    };
    const handleStartDateChange = (value: moment.Moment) => {
        resetPage();
        setStartDate(value.startOf("day").toDate());
    };
    const handleEndDateChange = (value: moment.Moment) => {
        resetPage();
        setEndDate(value.startOf("day").toDate());
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const insightsNormalized = useMemo(() => {
        return {
            ...insights,
            data: (insights?.data || [])?.map((insight: any) => {
                return normalizeInsight(insight);
            }),
        };
    }, [insights]);

    return (
        <>
            <div className="text-neutral-900 text-left font-semibold mb-6">
                Insights List
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-12 items-end gap-6 lg:gap-10">
                <div className="col-span-1 lg:col-span-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>

                <div className="col-span-1 lg:col-span-3">
                    <DatePickerMaterial
                        name="startDate"
                        value={startDate}
                        id="startDate"
                        label="From date"
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className="col-span-1 lg:col-span-3">
                    <DatePickerMaterial
                        name="endDate"
                        value={endDate}
                        id="startDate"
                        label="To date"
                        onChange={handleEndDateChange}
                    />
                </div>
                <div className="col-span-1 lg:col-span-2">
                    <SelectInput
                        onChange={handleElementFilterChange}
                        label="Element"
                        options={ActivityTypeFilterOptions}
                        defaultValue={ActivityTypeFilterOptions[0].value}
                    />
                </div>
            </div>
            {isDesktop ? (
                <>
                    <Table
                        headers={tableHeaders}
                        isLoading={insights?.isLoading}
                    >
                        {insightsNormalized?.data?.map((insight: any) => (
                            <tr key={insight?.id}>
                                <td
                                    className="text-left flex flex-row"
                                    title={insight?.title}
                                >
                                    {insight?.type && (
                                        <Icon
                                            className="w-[24px] mr-4 text-orange-600"
                                            icon={
                                                ActivityIcon[
                                                    insight?.type as keyof typeof ActivityIcon
                                                ]
                                            }
                                        />
                                    )}
                                    <span className="max-w-[200px] truncate">
                                        {insight?.title}
                                    </span>
                                </td>
                                <td className="text-left">{insight?.date}</td>
                                <td
                                    className="text-left max-w-[250px] truncate"
                                    title={insight?.location}
                                >
                                    {insight?.location}
                                </td>
                                <td
                                    className="text-left max-w-[250px] truncate"
                                    title={insight?.user}
                                >
                                    {insight?.user}
                                </td>
                            </tr>
                        ))}
                    </Table>
                    {insights?.count > PAGE_ROWS && (
                        <div className="self-end text-xs z-20 mt-4">
                            <Paginator
                                count={insights?.count}
                                take={PAGE_ROWS}
                                onChange={handlePaginatorChange}
                                totalPages={insights?.totalPages}
                                currentPage={insights?.currentPage}
                            />
                        </div>
                    )}{" "}
                </>
            ) : (
                <InsightsAdminResponsiveList
                    insights={insightsNormalized}
                    handlePaginatorChange={handlePaginatorChange}
                />
            )}
            {!insights?.isLoading && !insightsNormalized?.data?.length && (
                <div className="text-neutral-400 text-sm py-6">
                    There are no insights to show.
                </div>
            )}
        </>
    );
}
