import Avatar from "../Avatar";
import DotsMenu from "../DotsMenu";
import StatusBadgeComponent from "../StatusBadge/StatusBadgeComponent";

interface IOrgUsersList {
    user: any;
    showStatus?: boolean;
    showRole?: boolean;
    handleClickUserDotsMenu?: Function;
    dotMenuOptions?: any[];
    roleLabel?: string;
    handleUserClick?: Function;
}
export default function ParticipantsListCard({
    user,
    showStatus,
    showRole,
    handleClickUserDotsMenu,
    dotMenuOptions,
    roleLabel,
    handleUserClick,
}: IOrgUsersList) {
    return (
        <div
            key={`orgUserListItem_${user.userEmail}`}
            className="border-b border-b-gray-300 py-6"
        >
            <div className="flex flex-row">
                <div
                    className="flex flex-row flex-wrap flex-grow"
                    onClick={() => handleUserClick && handleUserClick(user)}
                >
                    <Avatar
                        src={user.user?.avatar_thumb?.length ? user.user?.avatar_thumb : user.user?.avatar}
                        className="w-12 h-12"
                    />
                    <div className="flex flex-col flex-grow ml-2">
                        <div className="flex flex-row flex-wrap items-center mb-1">
                            <span className="text-left text-sm font-semibold truncate mr-2">
                                {user.user?.firstName
                                    ? `${user.user?.firstName} ${user.user?.lastName}`
                                    : user?.userEmail}
                            </span>
                            {showRole && (
                                <span className="truncate content-start text-xs text-neutral-400">
                                    <span>{roleLabel}</span>
                                </span>
                            )}
                            {showStatus && (
                                <span className="truncate content-start">
                                    <StatusBadgeComponent
                                        status={user?.status}
                                    />
                                </span>
                            )}
                        </div>
                        {!!user.user?.firstName && (
                            <span className="text-left text-xs text-neutral-400 truncate">
                                {user.userEmail}
                            </span>
                        )}
                    </div>
                </div>

                {!!dotMenuOptions && handleClickUserDotsMenu && (
                    <div className="">
                        <DotsMenu
                            options={dotMenuOptions}
                            handleClickMenu={(k: any) => {
                                handleClickUserDotsMenu(k, user);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
