import { useEffect } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { PAGE_SECTIONS } from "../../../../constants";
import { setCurrentSection } from "../../../layout/layoutSlice";
import AllRestaurantsList from "./AllRestaurantsList";

export default function RestaurantsAdminHome(props: any) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_RESTAURANTS })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AllRestaurantsList />;
}
