import moment from "moment";
import Table from "../../components/Table";
import { paymentIntents } from "../../types/plan.type";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../app/store";

interface ITripUsersTable {}

export default function TableDetails({}: ITripUsersTable) {
    const { paymentIntents, subscriptions, isLoading } = useSelector(
        (state: RootState) => state.plans // Asegúrate de que esto coincida con cómo se llama tu slice en el store
    );

    const tableHeaders = [
        "DATE",
        "ROLE",
        "PLAN NAME",
        "INVOICE CODE",
        "PRICE",
        "STATUS",
    ];

    const transformedData = paymentIntents?.map((d: paymentIntents) => ({
        id: d.id,
        date: moment.unix(d.created).format("MM/DD/YYYY"),
        role: d.metadata?.role || "N/A", // Suponiendo que el role está en metadata
        planName: d.description || "N/A", // Descripción como el nombre del plan
        invoiceCode: d.latest_charge || "N/A", // Último cargo como código de factura
        price: (d.amount_received / 100).toFixed(2), // Dividir el amount_received entre 100 para obtener el monto en dólares
        status: d.status || "N/A", // Estado de la transacción
    })) || [] as paymentIntents[];

    console.log(transformedData);

    useEffect(() => {
        console.log(paymentIntents);
    }, [paymentIntents]);

    return (
        <>
            <Table
                headerClass="bg-white"
                headers={tableHeaders}
                isLoading={isLoading}
            >
                {transformedData.map((d) => {
                    return (
                        <tr key={d.id}>
                            <td>
                                <div className="flex gap-3 items-center justify-start">
                                    <span className="text-left">{d.date}</span>
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-start">
                                    <span className="w-full text-left">
                                        {d.role}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-start">
                                    <span className="w-full text-left">
                                        {d.planName}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-start">
                                    <span className="w-full text-left">
                                        {d.invoiceCode}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-start">
                                    <span className="w-full text-left">
                                        USD ${d.price}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="text-left flex justify-start">
                                    {/* <StatusBadgeComponent status={d.status} /> */}
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </Table>
        </>
    );
}
