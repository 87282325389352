import { PAGE_ROWS } from "../constants";

export interface IPaginatedData {
    data: Array<any>;
    count: number;
    currentPage: number;
    totalPages: number;
    skip: number;
    take: number;
    isLoading: boolean;
    requirements?: number;
}

export const IPaginatedDataInitialState = {
    data: [],
    count: 0,
    currentPage: 1,
    totalPages: 1,
    skip: 0,
    take: PAGE_ROWS,
    isLoading: false,
};
