import React from "react";

const RadioBox = React.forwardRef((props: any, ref: any) => {
  const { label, id, ...others } = props;
  return (
    <div className="flex items-center">
      <input
        ref={ref}
        id={id}
        type="radio"
        className="accent-orange-600 h-4 w-4 text-orange-600 border-2 border-orange-600 cursor-pointer"
        {...others}
      />
      <label
        htmlFor={id}
        className="ml-3 block text-sm font-medium text-gray-600"
      >
        {label}
      </label>
    </div>
  );
});

export default RadioBox;
