import { FC } from "react";
import TextAreaInput from "../../../../../../../../components/TextArea";
import InsightCommentBlock from "../InsightCommentBlock";
import { InsightImages } from "../../../../../components/InsightImages";
import InsightGalleryContainer from "../InsightGalleryContainer";
import GalleryImage from "../../../../../../../../components/ImagesGallery/Image";


interface InsightCommentsProps {
    showComentsForm: boolean;
    hotelInsight: any;
    commentsKey: string;
    imagesKey: string;
    register: any;
    errors: any;
    setValue: (name: string, value: any) => void;
    customComponent?: FC<{
        register: any;
        errors: any;
        setValue: (name: string, value: any) => void;
        showComentsForm: boolean;
    }>;
    handleUploadFileToInsightSection: (e: any) => void;
    toggleShowCommentsForm: () => void;
}

const InsightComments = ({
    showComentsForm,
    hotelInsight,
    commentsKey,
    imagesKey,
    register,
    errors,
    setValue,
    customComponent: CustomComponent,
    handleUploadFileToInsightSection,
    toggleShowCommentsForm,
 }: InsightCommentsProps) => {

    const images: { url: string; description?: string }[] = Object.values(
        hotelInsight?.[imagesKey] || {}
    );

    const showForm = showComentsForm || (!hotelInsight?.[commentsKey] && !images.length)

    const renderCustomComponent = () =>
        CustomComponent && (
            <CustomComponent
                showComentsForm={showForm}
                register={register}
                errors={errors}
                setValue={setValue}
            />
        );

    if (showForm) {
        return (
            <div className="flex flex-col w-full gap-10">
                <InsightImages
                    handleUploadFile={handleUploadFileToInsightSection}
                    //@ts-ignore
                    images={images}
                />
                <div>
                    <TextAreaInput
                        register={register}
                        label={`General comments`}
                        type="text"
                        markRequired
                        defaultValue={hotelInsight?.[commentsKey]}
                        name={commentsKey}
                        error={errors[commentsKey]?.message}
                    />
                </div>
                {renderCustomComponent()}
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-5">
            {!!images?.length && (
                <InsightGalleryContainer>
                    {images.map(
                        (
                            image: { url: string; description?: string },
                            index: number
                        ) => (
                            <div className="w-fit" key={image.url}>
                                <GalleryImage
                                    viewOnly
                                    src={image.url}
                                    onImageClick={() => {}}
                                    imageClass="object-cover"
                                    description={image?.description}
                                />
                            </div>
                        )
                    )}
                </InsightGalleryContainer>
            )}
            {hotelInsight?.[commentsKey] && (
                <InsightCommentBlock title={`General comments`}>
                    {hotelInsight?.[commentsKey]}
                </InsightCommentBlock>
            )}
            {renderCustomComponent()}
        </div>
    );
};

export default InsightComments;
