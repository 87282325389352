import { useState } from "react";
import { UserRequestChangeRoleModal } from "../../../components/Modal/UserRequestChangeRoleModal";
import { UserTypeVariant } from "../../../components/UserType/UserType";

export interface RequestChangeRoleProps {
    userType: UserTypeVariant;
}

export const RequestChangeRole = (props: RequestChangeRoleProps) => {
    const [openUserTypeModal, setOpenUserTypeModal] = useState(false);

    const handleOpenChoosePlanModal = () => {
        setOpenUserTypeModal(true);
    };

    const handleCloseUserTypeModal = () => {
        setOpenUserTypeModal(false);
    };

    return <div className="flex flex-row items-center">
        <span className="text-base font-normal text-neutral-800 mt-1 mb-2 mr-2 capitalize">
            {props?.userType?.toLowerCase()}
        </span>
        <span className="text-sm block mt-1 mb-2 whitespace-nowrap text-orange-600 font-bold cursor-pointer" onClick={handleOpenChoosePlanModal}>
            Request to Change Role
        </span>
        {openUserTypeModal && <UserRequestChangeRoleModal
            open={openUserTypeModal}
            handleClose={handleCloseUserTypeModal}
            currentUserType={props?.userType}
        />}
    </div>;
};
