const config = {
  fbApiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  fbAuthDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  fbProjectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  fbStorageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  fbMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  fbAppId: process.env.REACT_APP_FIREBASE_APPID,
  fbVapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
  chatApiUrl: process.env.REACT_APP_CHAT_API_URL,
  serverDomain: process.env.REACT_APP_SERVER_DOMAIN,
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
};
export default config;
