import Icon from "../../../assets/svg/Icon";
import ListWithPagination from "../../../components/ListWithPagination";
import { ActivityIcon } from "../../../constants";
import { IPaginatedData } from "../../../types/dataTypes";

interface InsightsAdminResponsiveListPropsI {
    insights: IPaginatedData;
    handlePaginatorChange: any;
}

const InsightsAdminResponsiveList = (props: InsightsAdminResponsiveListPropsI) => {
    const {
        insights,
        handlePaginatorChange,
    } = props;

    const renderInsights = ({ item: insight, index }: any) => {
        return (
            <div
                className={`mb-4 pt-4 ${
                    !!index ? "border-t border-neutral-100" : ""
                }`}
            >
                       <div className="flex flex-row text-left">
                    {insight?.type && (
                        <Icon
                            className="w-[24px] mr-1 text-orange-600"
                            icon={
                                ActivityIcon[
                                    insight?.type as keyof typeof ActivityIcon
                                ]
                            }
                        />
                    )}{" "}
                    <span className="truncate text-base font-semibold flex-grow">
                        {insight?.title}
                    </span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="CalendarMonthIcon"
                    />
                    <span className="truncate text-xs">{insight?.date}</span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="MarkerIcon"
                    />

                    <span className="truncate text-xs">
                        {insight?.location}
                    </span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[12px] mr-1 text-neutral-400"
                        icon="ParticipantsIcon"
                    />
                    <span className="truncate text-xs">{insight?.user}</span>
                </div>

            </div>
        );
    };

    return (
        <ListWithPagination
            items={insights}
            renderItem={renderInsights}
            handlePaginatorChange={handlePaginatorChange}
            itemKey={(insight: any) => `insight_${insight.id}`}
        />
    );
};

export default InsightsAdminResponsiveList;
