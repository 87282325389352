import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { handleError } from "../authentication/userSlice";

export interface PaymentsState {
    isLoading: boolean;
    payments: Array<any>;
    error: string;
    selectedPlan: any | null;
}

const initialState: PaymentsState = {
    isLoading: false,
    payments: [],
    error: "",
    selectedPlan: null,
};

export const paymentsSlice = createSlice({
    name: "payments",
    initialState,
    reducers: {
        isLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setPayments: (state, action: PayloadAction<{ plans: any  }>) => {
            if (Array.isArray(action.payload)) {
                state.payments = [...action.payload];
            } else {
                console.error('Error: action.payload.plans is not an array');
            }
        },
        setSelectedPlan: (state, action: PayloadAction<number>) => {
            state.selectedPlan = action.payload;
          },
    },
});

export const { isLoading, setPayments, setError, setSelectedPlan } =
    paymentsSlice.actions;


export const selectPayments = (state: RootState) => state.payments.payments;

export const createPayment = (payments: Object): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        dispatch(isLoading(true));
        const response = await serverApi.createPayment(payments);
        dispatch(setSelectedPlan(response.data.id));
        dispatch(isLoading(false));
    } catch (error) {
        dispatch(isLoading(false));
        dispatch(handleError(error));
    }
};


export default paymentsSlice.reducer;
