export const restaurantDetailComments = [
    {
        label: "Food comments",
        key: "foodComments",
    },
    {
        label: "Drink comments",
        key: "drinkComments",
    },
    {
        label: "Expense comments",
        key: "expenseComments",
    },
    {
        label: "Service comments",
        key: "serviceComments",
    },
    {
        label: "Dress code",
        key: "dressCode",
    },
];

export const joinRestaurantOptions = (
    options: { value: string; label: string }[],
    selected: string[]
) => {
    const selectedOptions = selected.map((selection) => {
        const foundSelection = options.find((o) => o.value === selection);
        return foundSelection?.label || selection;
    });
    return selectedOptions.join(", ");
};
