import React from "react";
import CitiesAutocomplete from "./CitiesAutocomplete";
import InputErrorLabel from "../InputErrorLabel";
import InputLabel from "../InputLabel";
import useGoogleMaps from "../../hooks/usePlacesService";

const CitiesAutocompleteInput = React.forwardRef(
    (
        props: any,
        ref: any
    ) => {

        const {
            label,
            id,
            name,
            type,
            placeholder,
            defaultValue,
            error,
            onChange,
            value: val,
            ...others
        } = props;
        const mapService = useGoogleMaps();
        const handlePlaceSelect = async (selectedOption: any) => {
            let place = selectedOption;
            if (selectedOption?.place_id) {
                const hasWindow = typeof window !== "undefined";
                const width = hasWindow ? window.innerWidth : null;
                const height = hasWindow ? window.innerHeight : null;
                place = await mapService.getPlaceDetails(
                    selectedOption.place_id,
                    {
                        maxWidth: width,
                        maxHeight: height,
                    }
                );
            }

            if (onChange) onChange(place);
        };

        return (
            <>
                <InputLabel inputId={id} label={label} error={error} />
                <CitiesAutocomplete
                    onChange={handlePlaceSelect}
                    defaultValue={defaultValue}
                    {...others}
                />
                <InputErrorLabel error={error} />
            </>
        );
    }
);
export default CitiesAutocompleteInput;
