import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import ButtonSquared from "../../../../../components/ButtonSquare";
import Modal, { Modal_Size } from "../../../../../components/Modal";
import { ActivityType, ButtonSize, PAGE_ROWS } from "../../../../../constants";
import {
    getOrganizationLibraryElements,
    selectOrganizationLibraryElements,
    setNextRouteAfterNewElement,
} from "../../../../organizations/organizationSlice";
import { selectedTrip, setActivityForm } from "../../../tripSlice";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { libraryElementToActivityPayload } from "../../../../organizations/Library/library.util";
import LibraryElement from "../../../../organizations/Library/LibraryElementItem";
import Skeleton from "react-loading-skeleton";
import Paginator from "../../../../../components/Paginator";
import { ILibraryElement } from "../../../../../types/library.type";
import InputSearch from "../../../../../components/InputSearch";
import EmptyBox from "../../../../../assets/images/EmptyBox.png";

interface SelectFromLibraryModalProps {
    isOpen: boolean;
    onClose: () => void;
    activityType: keyof typeof ActivityType;
    onSelectFromLibrary: (libraryElement: ILibraryElement) => void;
}

const SelectFromLibraryModal = ({
    isOpen,
    onClose,
    activityType,
    onSelectFromLibrary,
}: SelectFromLibraryModalProps) => {
    const trip = useAppSelector(selectedTrip);
    const [search, setSearch] = useState<string>("");
    const [newPage, setNewPage] = useState<any>({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedLibraryElement, setSelectedLibraryElement] =
        useState<ILibraryElement>();
    const libraryElements = useAppSelector(selectOrganizationLibraryElements);

    useEffect(() => {
        if (trip?.organization?.id) {
            fetchLibraryElements();
        }
    }, [search, trip?.organization?.id, newPage.currentPage, activityType]);

    const fetchLibraryElements = async () => {
        const filters = {
            search,
            take: newPage?.take || PAGE_ROWS,
            skip: newPage?.skip || 0,
            activityType,
        };
        await dispatch(
            getOrganizationLibraryElements(trip?.organization?.id, filters)
        );
    };

    const handleSearchChange = (query: string) => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
        setSearch(query);
    };

    const handleAddNewLibraryElements = () => {
        dispatch(setActivityForm({ activityForm: { showExitDialog: false } }));
        dispatch(
            setNextRouteAfterNewElement({
                nextRouteAfterNewElement: `${location.pathname}${location.search}`,
            })
        );
        setTimeout(() => {
            navigate(
                `/organizations/${trip?.organization?.id}/library/${activityType}/new`
            );
        }, 200);
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const onAddNewLibraryElement = () => {
        if (!selectedLibraryElement) return;
        const libraryElement = libraryElementToActivityPayload(
            selectedLibraryElement
        );
        onSelectFromLibrary(libraryElement);
    };

    const title = (() => {
        switch (activityType) {
            case ActivityType.HOTEL:
                return "Hotels";
            case ActivityType.RESTAURANT:
                return "Restaurants";
            case ActivityType.OTHER:
                return "Others";
            default:
                return "Library elements";
        }
    })();

    return (
        <Modal open={isOpen} onClose={onClose} size={Modal_Size.medium}>
            <div className="px-6">
                <div className="text-2xl font-semibold pb-4 ">
                    Select from library
                </div>
                <div className="text-base font-base text-neutral-400">
                    Select the element you want to add.
                </div>
                <div className="my-4">
                    <div className="text-lg font-semibold pb-4 ">{title}</div>
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                    <div className="flex flex-col gap-5 w-full max-h-[500px] overflow-auto  pr-4 mostly-customized-scrollbar">
                        {!libraryElements.isLoading &&
                            libraryElements &&
                            libraryElements?.data?.map((item: any) => (
                                <LibraryElement
                                    key={item.id}
                                    item={item}
                                    onClick={setSelectedLibraryElement}
                                    isSelected={
                                        item.id === selectedLibraryElement?.id
                                    }
                                />
                            ))}
                        {libraryElements.isLoading &&
                            [...Array(5)].map((_, index) => (
                                <Skeleton
                                    key={index}
                                    height={75}
                                    className="!rounded-lg"
                                />
                            ))}
                    </div>
                    {libraryElements?.count > PAGE_ROWS && (
                        <div className="w-full mt-8">
                            <Paginator
                                count={libraryElements?.count}
                                take={libraryElements?.take}
                                onChange={handlePaginatorChange}
                                totalPages={libraryElements?.totalPages}
                                currentPage={libraryElements?.currentPage}
                            />
                        </div>
                    )}
                    {!libraryElements.isLoading &&
                        !libraryElements?.data?.length && (
                            <div className="flex flex-col justify-center items-center h-full gap-3 flex-1">
                                <img
                                    alt="empty"
                                    src={EmptyBox}
                                    className="h-28 m-0 mb-12"
                                />
                                <div className="text-gray-500">
                                    Your library is empty.
                                </div>
                                <div className="text-gray-500 font-semibold w-56">
                                    Create reusable elements for your future
                                    trips.
                                </div>
                                <div
                                    className="text-primary_orange-500 font-semibold cursor-pointer"
                                    onClick={handleAddNewLibraryElements}
                                >
                                    Create new element
                                </div>
                            </div>
                        )}
                </div>
                <div className="flex justify-between">
                    <ButtonSquared
                        outlined
                        label={"Cancel"}
                        onClick={onClose}
                        size={ButtonSize.NORMAL}
                    />
                    <ButtonSquared
                        label={"Select element"}
                        onClick={onAddNewLibraryElement}
                        size={ButtonSize.NORMAL}
                        disabled={!selectedLibraryElement}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default SelectFromLibraryModal;
