import TripCard from "../../trips/Trip/TripCard";
import { useNavigate } from "react-router";

export interface OrganizationTripsCardsProps {
    trips: [];
}

export default function OrganizationTripsCards({
    trips,
}: OrganizationTripsCardsProps) {
    const navigate = useNavigate();
    const handleTripClick = (tripId: number) => {
        navigate(`/trips/${tripId}`);
    };

    return (
        <>
            { trips?.map((trip: any) => {
                return (
                    <div
                        key={`tripCard_past${trip.id}`}
                        className="w-full col-span-6 md:col-span-3 lg:col-span-2"
                    >
                        <TripCard
                            pending={trip.pending}
                            onClick={() =>
                                handleTripClick(trip.id)
                            }
                            trip={trip}
                        />
                    </div>
                )
            })}
            
        </>
    );
}
