import { FC, InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

const InsightDetailItem: FC<InputProps> = ({ label, ...rest }) => {
    return (
        <>
            <div className="font-medium text-lg text-gray-500 mb-8">{label}</div>
            <h4 className="text-base">
                {rest.children}
            </h4>
        </>
    );
};

export default InsightDetailItem