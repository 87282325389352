import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../app/hooks";
import Button from "../../../../components/ButtonSquare";
import TextareaWithValid from "../../../../components/TextArea";
import { addImageToTrip, isLoading } from "../../tripSlice";
import { getImageSize, resizeImageFile } from "../../../../helpers";
import DropImageFileInput from "../../../../components/DropImageFileInput/DropImageFileInput";
import GalleryImage from "../../../../components/ImagesGallery/Image";
import { useState } from "react";
import InputErrorLabel from "../../../../components/InputErrorLabel";

const schema = yup
    .object({
        description: yup.string().max(1000).nullable(),
        file: yup.object().required("Please select an image"),
    })
    .required("This field is required");

export default function AddImageToTrip() {
    const [preview, setPreview] = useState<any[]>([]);
    const navigate = useNavigate();
    let { tripId } = useParams();

    const {
        register,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useAppDispatch();

    const handleSubmitForm = async () => {
        if (preview.length) {
            const values = getValues();
            const promises: any[] = [];
            try {
                dispatch(isLoading(true));
                preview.forEach((img) => {
                    const formData = new FormData();
                    formData.append("file", img.file);
                    formData.append("name", "");
                    formData.append("description", values.description || "");
                    formData.append("width", img.size.width);
                    formData.append("height", img.size.height);
                    promises.push(
                        dispatch(addImageToTrip(Number(tripId), formData))
                    );
                });
                await Promise.all(promises);
                dispatch(isLoading(false));
                navigateBack();
            } catch (error) {
                dispatch(isLoading(false));
            }
        }
    };

    const navigateBack = () => {
        navigate(`/trips/${tripId}/images`);
    };

    const onSelectFile = async (e: any) => {
        const uploadedImages: any[] = e.target.files;
        let imgsArray: any[] = [];
        for (let index = 0; index < uploadedImages.length; index++) {
            const imageResized: Blob = await resizeImageFile(
                uploadedImages[index]
            );
            const size = await getImageSize(imageResized);
            const preview = URL.createObjectURL(imageResized);
            imgsArray.push({ size, file: imageResized, preview });
        }
        imgsArray = [...preview, ...imgsArray];
        setPreview([...imgsArray]);
        setValue("file", imgsArray[0]?.file);
    };

    const handleImageRemove = (index: number) => {
        const spliceArray = preview;
        spliceArray.splice(index, 1);
        setPreview([...spliceArray]);
        setValue("file", spliceArray[0]);
    };

    return (
        <>
            <form action="#" method="POST" encType="multipart/form-data">
                <div className="text-lg font-semibold text-left">
                    Add new photo
                </div>
                <div className="py-5 bg-white">
                    <div className="flex flex-col">
                        <div className="flex col-span-6 mt-3 gap-3">
                            <DropImageFileInput
                                multiple
                                preview={null}
                                imgClass={
                                    "max-h-60 max-w-[200px] overflow-hidden rounded-xl"
                                }
                                onChange={onSelectFile}
                            />
                        </div>
                        {!preview.length && errors["file"]?.message && (
                            <div>
                                <InputErrorLabel
                                    error={errors["file"]?.message}
                                />
                            </div>
                        )}
                    </div>

                    <div className="flex flex-wrap gap-4 mt-8">
                        {preview.map((i, index: number) => {
                            return (
                                <GalleryImage
                                    key={index}
                                    src={i.preview}
                                    onImageClick={() => {}}
                                    onImageRemove={() => {
                                        handleImageRemove(index);
                                    }}
                                />
                            );
                        })}
                    </div>
                    <div className="col-span-6 mt-6">
                        <TextareaWithValid
                            register={register}
                            label="Description"
                            id="description"
                            name="description"
                            error={errors["description"]?.message}
                        />
                    </div>
                </div>

                <div className="flex gap-3 justify-end px-4 py-3 text-right mt-12">
                    <Button
                        onClick={navigateBack}
                        outlined
                        type="button"
                        label="Cancel"
                    />
                    <Button
                        disabled={!preview.length}
                        onClick={handleSubmitForm}
                        type="button"
                        label="Save"
                    />
                </div>
            </form>
        </>
    );
}
