import { useEffect } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { PAGE_SECTIONS } from "../../../../constants";
import { setCurrentSection } from "../../../layout/layoutSlice";
import AllOthersList from "./AllOthersList";

export default function OthersAdminHome(props: any) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_OTHERS })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AllOthersList />;
}
