import { useMemo } from "react";
import { useAppSelector } from "../app/hooks";
import { PAGE_SECTIONS, UserRoleOnTrip, UserStatusOnTrip } from "../constants";
import { selectProfile } from "../features/authentication/userSlice";
import { isLoadingSelector } from "../features/layout";
import { selectCurrentSection } from "../features/layout/layoutSlice";
import { selectedTrip } from "../features/trips/tripSlice";

const ALLOWED_ROLE: any = {
    [PAGE_SECTIONS.PENDING_TRIP]: {
        [UserRoleOnTrip.MEMBER]: {
            allowed: true,
        },
        [UserRoleOnTrip.VIEWER]: {
            allowed: true,
        },
    },
    [PAGE_SECTIONS.TRIP]: {
        [UserRoleOnTrip.MEMBER]: {
            allowed: true,
        },
        [UserRoleOnTrip.VIEWER]: {
            allowed: true,
        },
    },
    [PAGE_SECTIONS.TRIP_EDIT]: {
        [UserRoleOnTrip.MEMBER]: {
            allowed: false,
        },
        [UserRoleOnTrip.VIEWER]: {
            allowed: false,
        },
    },
    [PAGE_SECTIONS.TRIP_FILES]: {
        [UserRoleOnTrip.MEMBER]: {
            allowed: true,
        },
        [UserRoleOnTrip.VIEWER]: {
            allowed: true,
        },
    },
    [PAGE_SECTIONS.TRIP_FILES_EDIT]: {
        [UserRoleOnTrip.MEMBER]: {
            allowed: false,
        },
        [UserRoleOnTrip.VIEWER]: {
            allowed: false,
        },
    },
    [PAGE_SECTIONS.TRIP_GALLERY]: {
        [UserRoleOnTrip.MEMBER]: {
            allowed: true,
        },
        [UserRoleOnTrip.VIEWER]: {
            allowed: true,
        },
    },
    [PAGE_SECTIONS.TRIP_ITINERARY]: {
        [UserRoleOnTrip.MEMBER]: {
            allowed: true,
        },
        [UserRoleOnTrip.VIEWER]: {
            allowed: true,
        },
    },
    [PAGE_SECTIONS.TRIP_PARTICIPANTS]: {
        [UserRoleOnTrip.MEMBER]: {
            allowed: true,
        },
        [UserRoleOnTrip.VIEWER]: {
            allowed: true,
        },
    },
    [PAGE_SECTIONS.TRIP_REQUIREMENTS]: {
        [UserRoleOnTrip.MEMBER]: {
            allowed: true,
        },
        [UserRoleOnTrip.VIEWER]: {
            allowed: true,
        },
    },
    [PAGE_SECTIONS.TRIP_CHAT]: {
        [UserRoleOnTrip.MEMBER]: {
            allowed: true,
        },
        [UserRoleOnTrip.VIEWER]: {
            allowed: false,
        },
    },
};
const ALLOWED_BY_STATUS: any = {
    [PAGE_SECTIONS.PENDING_TRIP]: {
        [UserStatusOnTrip.CONFIRMED]: {
            allowed: true,
        },
        [UserStatusOnTrip.BLOCKED]: {
            allowed: true,
        },
        [UserStatusOnTrip.PENDING]: {
            allowed: true,
        },
        [UserStatusOnTrip.REJECTED]: {
            allowed: false,
        },
        [UserStatusOnTrip.EXPIRED]: {
            allowed: false,
        },
    },
    [PAGE_SECTIONS.TRIP]: {
        [UserStatusOnTrip.CONFIRMED]: {
            allowed: true,
        },
        [UserStatusOnTrip.BLOCKED]: {
            allowed: true,
        },
        [UserStatusOnTrip.PENDING]: {
            allowed: true,
        },
        [UserStatusOnTrip.REJECTED]: {
            allowed: false,
        },
        [UserStatusOnTrip.EXPIRED]: {
            allowed: false,
        },
    },
    [PAGE_SECTIONS.TRIP_EDIT]: {
        [UserStatusOnTrip.CONFIRMED]: {
            allowed: true,
        },
        [UserStatusOnTrip.BLOCKED]: {
            allowed: false,
        },
        [UserStatusOnTrip.PENDING]: {
            allowed: false,
        },
        [UserStatusOnTrip.REJECTED]: {
            allowed: false,
        },
        [UserStatusOnTrip.EXPIRED]: {
            allowed: false,
        },
    },
    [PAGE_SECTIONS.TRIP_FILES]: {
        [UserStatusOnTrip.CONFIRMED]: {
            allowed: true,
        },
        [UserStatusOnTrip.BLOCKED]: {
            allowed: true,
        },
        [UserStatusOnTrip.PENDING]: {
            allowed: false,
        },
        [UserStatusOnTrip.REJECTED]: {
            allowed: false,
        },
        [UserStatusOnTrip.EXPIRED]: {
            allowed: false,
        },
    },
    [PAGE_SECTIONS.TRIP_FILES_EDIT]: {
        [UserStatusOnTrip.CONFIRMED]: {
            allowed: true,
        },
        [UserStatusOnTrip.BLOCKED]: {
            allowed: false,
        },
        [UserStatusOnTrip.PENDING]: {
            allowed: false,
        },
        [UserStatusOnTrip.REJECTED]: {
            allowed: false,
        },
        [UserStatusOnTrip.EXPIRED]: {
            allowed: false,
        },
    },
    [PAGE_SECTIONS.TRIP_GALLERY]: {
        [UserStatusOnTrip.CONFIRMED]: {
            allowed: true,
        },
        [UserStatusOnTrip.BLOCKED]: {
            allowed: true,
        },
        [UserStatusOnTrip.PENDING]: {
            allowed: false,
        },
        [UserStatusOnTrip.REJECTED]: {
            allowed: false,
        },
        [UserStatusOnTrip.EXPIRED]: {
            allowed: false,
        },
    },
    [PAGE_SECTIONS.TRIP_ITINERARY]: {
        [UserStatusOnTrip.CONFIRMED]: {
            allowed: true,
        },
        [UserStatusOnTrip.BLOCKED]: {
            allowed: true,
        },
        [UserStatusOnTrip.PENDING]: {
            allowed: false,
        },
        [UserStatusOnTrip.REJECTED]: {
            allowed: false,
        },
        [UserStatusOnTrip.EXPIRED]: {
            allowed: false,
        },
    },
    [PAGE_SECTIONS.TRIP_PARTICIPANTS]: {
        [UserStatusOnTrip.CONFIRMED]: {
            allowed: true,
        },
        [UserStatusOnTrip.BLOCKED]: {
            allowed: true,
        },
        [UserStatusOnTrip.PENDING]: {
            allowed: false,
        },
        [UserStatusOnTrip.REJECTED]: {
            allowed: false,
        },
        [UserStatusOnTrip.EXPIRED]: {
            allowed: false,
        },
    },
    [PAGE_SECTIONS.TRIP_REQUIREMENTS]: {
        [UserStatusOnTrip.CONFIRMED]: {
            allowed: true,
        },
        [UserStatusOnTrip.BLOCKED]: {
            allowed: true,
        },
        [UserStatusOnTrip.PENDING]: {
            allowed: false,
        },
        [UserStatusOnTrip.REJECTED]: {
            allowed: false,
        },
        [UserStatusOnTrip.EXPIRED]: {
            allowed: false,
        },
    },
};

const ALLOWED_BY_ROLE = (currentSection: any, role: UserRoleOnTrip) => {
    if (!currentSection || !role) return false;
    if ([UserRoleOnTrip.ADMIN, UserRoleOnTrip.OWNER].includes(role))
        return {
            allowed: true,
        };
    return ALLOWED_ROLE[currentSection]
        ? ALLOWED_ROLE[currentSection][role]
        : { allowed: true };
};

const isAllowed = (
    email: string,
    currentSection: any,
    tripUsers: Array<any>,
    isLoading: boolean | Boolean,
    users: any
) => {
    if (isLoading || !currentSection || !users) return true;
    const user = tripUsers?.find((u: any) => u.userEmail === email);
    if (!tripUsers?.length) return false;
    const { allowed } = ALLOWED_BY_ROLE(currentSection, user?.role || UserRoleOnTrip.VIEWER);
    const allowedStatus =
        currentSection && ALLOWED_BY_STATUS[currentSection]
            ? ALLOWED_BY_STATUS[currentSection][user?.status || UserStatusOnTrip.CONFIRMED]
            : { allowed: true };
    return allowed && allowedStatus.allowed;
};

export default function useTripAccessControl() {
    const { email } = useAppSelector(selectProfile);
    const trip = useAppSelector(selectedTrip);
    const currentSection = useAppSelector(selectCurrentSection);
    const profile = useAppSelector(selectProfile)
    const { users } = trip || {};
    const isLoading = useAppSelector(isLoadingSelector);

    const allowed = useMemo(() => {
        return isAllowed(email, currentSection, users, isLoading, users);
    }, [currentSection, email, users, isLoading]);

    if (profile?.isAdmin) return { allowed: true };

    return { allowed };
}
