import Icon from "../../assets/svg/Icon";
import famguruIcon from "../../assets/images/famguru_icon.png";
export default function Maintenance(props: any) {
    return (
        <div className="px-40 py-20 bg-white">
            <div className="flex flex-col items-center">
                <img src={famguruIcon} />
                <Icon icon={"MaintenanceIcon"} className={" mt-10 w-20 h-20 text-gray-500"} />
                <h1 className="font-bold text-orange-600 text-5xl my-5">
                    Maintenance
                </h1>

                <h6 className="mb-2 text-2xl font-bold text-center text-neutral-500 lg:text-3xl">
                    <span className="text-orange-600">Oops!</span> Sorry!
                </h6>

                <p className="mb-8 text-center text-gray-500 lg:text-lg">
                    We are under maintenance, please try again later
                </p>
            </div>
        </div>
    );
}
