import { Tooltip } from "@material-ui/core";
import Icon from "../assets/svg/Icon";

const labelClasses = {
    normal: "block text-left font-medium text-neutral-600 mb-1 text-sm",
    error: "block text-left text-sm font-medium text-alert_red mb-1",
};

type InputLabelProps = {
    label: string;
    inputId: string;
    error?: boolean;
    className?: string;
    tooltip?: string;
    markRequired?: boolean;
};

export default function InputLabel(props: InputLabelProps) {
    const { label, inputId, error, className, tooltip, markRequired } = props;
    return (
        <>
            <label
                htmlFor={inputId}
                className={`flex flex-row items-center ${
                    error ? labelClasses.error : labelClasses.normal
                } ${className}`}
            >
                {label}
                {!!tooltip && (
                    <Tooltip placement="top" title={tooltip}>
                        <div>
                            <Icon
                                className="w-[18px] ml-2 text-orange-500"
                                icon="InfoIcon"
                            />
                        </div>
                    </Tooltip>
                )}
                {!!markRequired && <span className="text-red-500 pl-1 self">*</span>}
            </label>
        </>
    );
}
