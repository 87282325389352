import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { PAGE_SECTIONS } from "../../../../../constants";
import { setCurrentSection } from "../../../../layout/layoutSlice";
import { getTripFile, selectedTripFile } from "../../../tripSlice";
import FileForm from "../FileForm";
import FileDetails from "./FileDetails";

export default function TripFile(props: any) {
    const dispatch = useAppDispatch();
    const file = useAppSelector(selectedTripFile);
    let { tripId, fileId } = useParams();
    const [editting, setEditting] = useState<boolean>(false);

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.TRIP_FILES })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const navigate = useNavigate();
    useEffect(() => {
        if ( fileId && tripId) {
            const fetchFile = async () => {
                return await dispatch(
                    getTripFile(parseInt(tripId!), parseInt(fileId!))
                );
            };
            fetchFile();
        }
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripId, fileId]);

    const goBack = () => {
        navigate(`/trips/${tripId}/files`);
    };

    const onCancel = () => {
        if (fileId) {
            setEditting(false);
        } else {
            goBack();
        }
    };

    return (
        <>
            {fileId && !editting && file && (
                <FileDetails
                    file={file}
                    startEditting={() => {
                        setEditting(true);
                    }}
                    goBack={goBack}
                />
            )}
            {(!fileId || editting) && (
                <FileForm file={file} onCancel={onCancel} onComplete={goBack} />
            )}
        </>
    );
}
