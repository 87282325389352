import { PAGE_ROWS } from "../../constants";
import { ComplaintsDotMenuKeys } from "../../constants/admin";
import { IPaginatedData } from "../../types/dataTypes";
import Paginator from "../Paginator";
import StatusBadgeComponent from "../StatusBadge/StatusBadgeComponent";
import Table from "../Table";

export default function AllComplaintsTable({
    handleClickDotsMenu,
    handlePaginatorChange,
    complaints,
}: {
    handleClickDotsMenu: Function;
    handlePaginatorChange: Function;
    complaints: IPaginatedData;
}) {
    const tableHeaders = ["Title", "Type", "User", "Date Added", "Status"];

    return (
        <>
            <Table headers={tableHeaders} isLoading={complaints?.isLoading}>
                {complaints?.data?.map((complaint) => (
                    <tr
                        key={complaint?.id}
                        className="hover:bg-neutral-50 cursor-pointer"
                        onClick={() => {
                            handleClickDotsMenu(
                                ComplaintsDotMenuKeys.view,
                                complaint
                            );
                        }}
                    >
                        <td
                            className="text-left flex flex-row"
                        >
                            <span
                                className="max-w-[300px] truncate"
                                title={complaint?.title}
                            >
                                {complaint?.title}
                            </span>
                        </td>
                        <td
                            className="text-left"
                        >
                            <span
                                className="max-w-[100px] truncate"
                                title={complaint?.typeFormatted}
                            >
                                {complaint?.typeFormatted}
                            </span>
                        </td>
                        <td
                            className="text-left max-w-[300px] truncate"
                            title={complaint?.userFormatted}
                        >
                            {complaint?.userFormatted}
                        </td>
                        <td
                            className="text-left max-w-[150px] truncate"
                            title={complaint?.date}
                        >
                            {complaint?.date}
                        </td>
                        <td className="text-left max-w-[150px] truncate">
                            <StatusBadgeComponent status={complaint?.status} />
                        </td>
                    </tr>
                ))}
            </Table>
            {complaints?.count > PAGE_ROWS && (
                <div className="self-end text-xs z-20 mt-4">
                    <Paginator
                        count={complaints?.count}
                        take={PAGE_ROWS}
                        onChange={handlePaginatorChange}
                        totalPages={complaints?.totalPages}
                        currentPage={complaints?.currentPage}
                    />
                </div>
            )}{" "}
        </>
    );
}
