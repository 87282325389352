import { useEffect, useState } from "react";
import { uploadTempFile } from "../../tripSlice";
import Image from "./Image";
import DropImageFileInput2 from "../../../../components/DropImageFileInput2/DropImageFileInput2";
import { TRIP_DEFAULT_IMAGES } from "../../../../constants";
import { resizeImageFile } from "../../../../helpers";

const tripCoverImagesDefault = TRIP_DEFAULT_IMAGES;

export default function TripImagesInput({
    handleSelectImage,
    handleSelectDefaultImage,
    trip,
}: any) {
    const [selectedImage, setSelectedImage] = useState<any>();
    const [preview, setPreview] = useState<string | undefined>();

    useEffect(() => {
        if (trip?.coverImage) {
            setSelectedImage({
                src: trip?.coverImage,
            });
        } else if (trip?.coverImage_default) {
            const img = tripCoverImagesDefault.find(
                (i) => i?.id === trip?.coverImage_default
            );
            if (img) setSelectedImage(img);
        } else setSelectedImage(tripCoverImagesDefault[0]);
    }, [trip?.coverImage, trip?.coverImage_default]);

    const onSelectFile = async (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        const imageResized: Blob = await resizeImageFile(file);
        if (file) {
            const objectUrl = URL.createObjectURL(imageResized);
            setPreview(objectUrl);
            setSelectedImage({ src: objectUrl });
            const response = await uploadTempFile(imageResized, file?.name);
            if (handleSelectImage) handleSelectImage(response);
        }
    };

    const handleImageClick = (image: any) => {
        setSelectedImage(image);
        handleSelectDefaultImage(image);
    };

    return (
        <div>
            <div className="min-w-full w-full h-[300px]">
                {selectedImage && (
                    <div
                        className="w-full h-[300px] bg-hero bg-no-repeat bg-cover bg-center  rounded-md"
                        style={{
                            backgroundImage: `url('${selectedImage?.src}')`,
                        }}
                    ></div>
                )}
            </div>
            <div className="flex items-end gap-4 overflow-x-scroll h-[160px] mostly-customized-scrollbar">
                <div className="max-h-[124px] mb-2">
                    <DropImageFileInput2
                        //  preview={preview}
                        imgClass={
                            "h-[124px] w-[200px] rounded-sm overflow-hidden box-border"
                        }
                        onChange={onSelectFile}
                    />
                </div>
                {preview && (
                    <Image
                        selected={preview === selectedImage?.src}
                        //onImageClick={handleImageClick}
                        key={preview}
                        photo={{ src: preview }}
                        className="h-[124px] min-w-[200px] z-1011111"
                    />
                )}
                {tripCoverImagesDefault.map((image, index) => (
                    <Image
                        selected={image.src === selectedImage?.src}
                        onImageClick={() => handleImageClick(image)}
                        key={image.src}
                        photo={{ src: image.src }}
                        className="h-[124px] min-w-[200px] z-1011111"
                    />
                ))}
            </div>
        </div>
    );
}
