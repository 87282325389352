import { useEffect, useRef } from "react";
import ChatHeader from "./ChatHeader";
import ChatMessageList from "./ChatMessageList";
import { useChatForm } from "../hooks/useChat.hook";
import { useSelector } from "react-redux";

import ActionPopup from "./ActionPopup";
import ChatInput from "./ChatInput";
import EmptyScreen from "../../../components/EmptyScreen/EmptyScreen";

interface ChatMessagesProps {
    onTitleClick: () => void;
    handleShowParticipants: (value: boolean) => void;
}

const ChatMessages: React.FC<ChatMessagesProps> = ({
    onTitleClick,
    handleShowParticipants
}) => {
    const currentUser = useSelector((state: any) => state?.user?.profile);
    const {
        selectedUser,
        selectedChat,
        selectedMessages,
        onBlockUser,
        onAcceptUser,
        onReportUser,
    } = useChatForm();

    const messagesEndRef = useRef<HTMLDivElement>(null);


    if (!selectedChat && !selectedUser) {
        return (
            <div className="flex flex-col h-full items-center justify-center">
                <EmptyScreen box text="There are no messages to show." />
            </div>
        );
    }

    const messages = selectedMessages || [];
    const title = selectedChat?.title || "";
    const avatar = selectedChat?.avatar || "";


    const userWhoStartedChat = messages.length > 0 ? messages[0]?.sender : null;

    const showActionPopup = !selectedChat?.isGroup &&
        selectedUser &&
        userWhoStartedChat &&
        userWhoStartedChat.uid !== currentUser?.uid &&
        selectedChat?.isBlockedBy === "" &&
        selectedChat?.isFriend === false;

    const isBlockedByCurrentUser =
        selectedChat?.isBlockedBy === currentUser.uid && !selectedChat?.isGroup;
    const isBlockedByOtherUser =
        selectedChat?.isBlockedBy === selectedUser?.uid &&
        !selectedChat?.isGroup;


    return (
        <div className="flex flex-col h-full min-h-[500px] ml-4 mb-4">
            <ChatHeader
                title={title}
                avatarUrl={avatar}
                onTitleClick={selectedChat?.isGroup ? onTitleClick : () => {}}
                isGroup={selectedChat?.isGroup || false}
            />
            <ChatMessageList
                messages={messages}
                currentUser={currentUser}
                messagesEndRef={messagesEndRef}
                chatId={selectedChat?.id || ""}
                isGroup={selectedChat?.isGroup || false}
                handleShowParticipants={handleShowParticipants}
                members={selectedChat?.members || []}
            />
            {!!showActionPopup && (
                <ActionPopup
                    onBlock={onBlockUser}
                    onReport={onReportUser}
                    onAccept={onAcceptUser}
                    otherMember={selectedUser}
                />
            )}
            {isBlockedByCurrentUser ? (
                <div className="flex justify-center items-center p-4 border-t border-gray-200">
                    <div className="text-center">
                        <p>You have blocked this user.</p>
                    </div>
                </div>
            ) : isBlockedByOtherUser ? (
                <div className="flex justify-center my-4">
                    <div className="flex items-center bg-gray-100 border  rounded-md py-2 px-4">
                        <span className="text-sm text-gray-600">
                            {`${selectedUser?.firstName} ${selectedUser?.lastName} has blocked you.`}
                        </span>
                    </div>
                </div>
            ) : (
                <ChatInput />
            )}
        </div>
    );
};

export default ChatMessages;
