import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectBredCrumpOptions } from "../features/authentication/userSlice";
import Icon from "../assets/svg/Icon";

const Breadcrumbs = () => {
    const options = useSelector(selectBredCrumpOptions);
    const navigate = useNavigate();

    const handleClick = (url: string) => {
        if (!url?.length) return;
        navigate(url);
    };

    const renderOptions = useMemo(() => {
        if (!options?.length) return null;
        const handleClickIcon = () =>
            options.length > 1 && handleClick(options[0].url);
        return (
            <div className="flex flex-row items-center text-neutral-200 font-normal text-sm">
                <Icon
                    icon={"HomeIcon"}
                    className={`w-4 h-4 m-0 fill-neutral-400 text-neutral-200 mr-2 ${
                        options?.length > 1 && "cursor-pointer"
                    }`}
                    onClick={handleClickIcon}
                />
                {options?.map((option, index) => {
                    const showSeparator = options?.length > index + 1;
                    return (
                        <div
                            className="flex items-center"
                            key={`bread-${index}`}
                        >
                            <span
                                className={`text-xs font-semibold focus:outline-none max-w-[120px] truncate ${
                                    showSeparator
                                        ? "cursor-pointer"
                                        : "text-white"
                                }`}
                                onClick={() => handleClick(option.url)}
                            >
                                {option.label}
                            </span>
                            {showSeparator && (
                                <span className="focus:outline-none mx-2 text-xl font-medium">
                                    &gt;
                                </span>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }, [options]);

    if (!options?.length || options?.length <= 1) return null;

    return (

            <div className="container flex flex-col lg:flex-row items-start lg:items-center justify-between mb-1">
                <div>{renderOptions}</div>
            </div>

    );
};

export default Breadcrumbs;
