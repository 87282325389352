import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useChatForm } from "../../chat/hooks/useChat.hook";
import { setCurrentSection } from "../../layout/layoutSlice";
import { PAGE_SECTIONS } from "../../../constants";
import { UserChatsList } from "../../chat/components/UserChatsList";
import { selectedOrganization } from "../organizationSlice";

const Chat = () => {
    const organization = useAppSelector(selectedOrganization);
    const dispatch = useAppDispatch();
    const { onSelectedChat } = useChatForm();

    useEffect(() => {
        setTimeout(() => {
            dispatch(
                setCurrentSection({
                    currentSection: PAGE_SECTIONS.ORGANIZATION_CHAT,
                })
            );
        }, 300);
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    useEffect(() => {
        if (organization) {
            onSelectedChat(`organization-${organization.id}`);
        }
    }, [onSelectedChat, organization]);

    return (
        <div>
            <UserChatsList leftComponent={false} />
        </div>
    );
}


export default Chat;