import { useEffect } from "react";
import { PlanDetailsStatus } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCurrentBreakpoint } from "../layout/layoutSlice";
import TableDetails from "./TableDetails";
import { fetchPaymentsByEmail } from "./plansSlice";

export interface Receipts {
    id: number;
    date: string;
    role: string;
    planName: string;
    invoiceCode: string;
    price: string;
    status: PlanDetailsStatus;
}
export interface detailsNormalized {
    data: Receipts[];
    count: number;
    currentPage: number;
    totalPages: number;
    skip: number;
    take: number;
    isLoading: boolean;
    requirements?: number;
}

export const MyDetailsList = () => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector((state) => state.user.profile);

    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);

    const fetchData = async () => {
        await dispatch(fetchPaymentsByEmail(currentUser?.email));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return <>{isDesktop ? <TableDetails /> : <TableDetails />}</>;
};
