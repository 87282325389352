import { useEffect } from "react";
import TextAreaInputWithValid from "../../../../components/TextArea";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../../../components/ButtonSquare";
import ButtonGroupSwitch from "../../../../components/ButtonGroupSwitch";
import Checkbox from "../../../../components/CheckBox";

const schema = yup
    .object({
        dietaryVegetarianComment: yup.string(),
        dietaryGlutenIntoleranceComment: yup.string(),
        dietaryLactoseIntoleranceComment: yup.string(),
        dietaryPeanutAllergiesComment: yup.string(),
        dietaryFoodRestrictionsComment: yup.string(),
        dietaryOtherComment: yup.string(),
    })
    .required("This field is required");

export default function DietaryEdit(props: any) {
    const { onSubmit, onCancel, profileExtra } = props;
    const { register, handleSubmit, reset, control, watch } = useForm({
        resolver: yupResolver(schema),
    });
    const hasNoRestrictions = watch().dietaryNoRestrictions;

    const handleDietaryUpdate = (data: any) => {
        const payload = {
            dietaryNoRestrictions: data?.dietaryNoRestrictions,
            dietaryVegetarian: hasNoRestrictions
                ? false
                : data?.dietaryVegetarian,
            dietaryVegetarianComment: hasNoRestrictions
                ? ""
                : data?.dietaryVegetarianComment,
            dietaryVegan: hasNoRestrictions ? false : data?.dietaryVegan,
            dietaryVeganComment: hasNoRestrictions
                ? ""
                : data?.dietaryVeganComment,
            dietaryGlutenIntolerance: hasNoRestrictions
                ? false
                : data?.dietaryGlutenIntolerance,
            dietaryGlutenIntoleranceComment: hasNoRestrictions
                ? ""
                : data?.dietaryGlutenIntoleranceComment,
            dietaryFoodAllergies: hasNoRestrictions
                ? false
                : data?.dietaryFoodAllergies,
            dietaryFoodAllergiesComment: hasNoRestrictions
                ? ""
                : data?.dietaryFoodAllergiesComment,
            dietaryPreferredBeverages: data?.dietaryPreferredBeverages,
            dietaryPreferredBeveragesComment: data?.dietaryPreferredBeveragesComment,
            dietaryAlcohol: data?.dietaryAlcohol,
            dietaryAlcoholComment: data?.dietaryAlcoholComment,
            dietaryOtherComment: data?.dietaryOtherComment,
        };
        onSubmit(payload);
    };

    useEffect(() => {
        reset({
            dietaryNoRestrictions: profileExtra?.dietaryNoRestrictions,
            dietaryVegetarian: profileExtra?.dietaryVegetarian,
            dietaryVegetarianComment: profileExtra?.dietaryVegetarianComment,
            dietaryVegan: profileExtra?.dietaryVegan,
            dietaryVeganComment: profileExtra?.dietaryVeganComment,
            dietaryGlutenIntolerance: profileExtra?.dietaryGlutenIntolerance,
            dietaryGlutenIntoleranceComment:
                profileExtra?.dietaryGlutenIntoleranceComment,
            dietaryFoodAllergies: profileExtra?.dietaryFoodAllergies,
            dietaryFoodAllergiesComment:
                profileExtra?.dietaryFoodAllergiesComment,
            dietaryPreferredBeverages: profileExtra?.dietaryPreferredBeverages,
            dietaryPreferredBeveragesComment:
                profileExtra?.dietaryPreferredBeveragesComment,
            dietaryAlcohol: profileExtra?.dietaryAlcohol,
            dietaryAlcoholComment: profileExtra?.dietaryAlcoholComment,
            dietaryOtherComment: profileExtra?.dietaryOtherComment,
        });
    }, [profileExtra, reset]);

    return (
        <>
            <div className="flex flex-col">
                <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(handleDietaryUpdate)}
                >
                    <div className="grid grid-cols-6 lg:gap-x-32 gap-x-6 gap-y-12">
                        <div className="col-span-6 flex flex-row border-b border-neutral-200 pb-8 items-center">
                            <Checkbox
                                control={control}
                                name={"dietaryNoRestrictions"}
                            />
                            <span>I don't have any dietary restrictions.</span>
                        </div>
                        {!hasNoRestrictions && (
                            <>
                                <div className="col-span-6 lg:col-span-3">
                                    <div className="flex max-w-xs">
                                        <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                            Vegetarian
                                        </div>
                                        <ButtonGroupSwitch
                                            name="dietaryVegetarian"
                                            control={control}
                                        />
                                    </div>
                                    <TextAreaInputWithValid
                                        register={register}
                                        name="dietaryVegetarianComment"
                                        id="dietaryVegetarianComment"
                                        placeholder="Comments"
                                    />
                                </div>
                                <div className="col-span-6 lg:col-span-3">
                                    <div className="flex max-w-xs">
                                        <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                            Vegan
                                        </div>
                                        <ButtonGroupSwitch
                                            name="dietaryVegan"
                                            control={control}
                                        />
                                    </div>
                                    <TextAreaInputWithValid
                                        register={register}
                                        name={"dietaryVeganComment"}
                                        placeholder={"Comments"}
                                    />
                                </div>
                                <div className="col-span-6 lg:col-span-3">
                                    <div className="flex max-w-xs">
                                        <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                            Gluten intolerance
                                        </div>

                                        <ButtonGroupSwitch
                                            name="dietaryGlutenIntolerance"
                                            control={control}
                                        />
                                    </div>
                                    <TextAreaInputWithValid
                                        register={register}
                                        name={"dietaryGlutenIntoleranceComment"}
                                        placeholder={"Comments"}
                                    />
                                </div>
                                <div className="col-span-6 lg:col-span-3">
                                    <div className="flex max-w-xs">
                                        <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                            Food allergies
                                        </div>
                                        <ButtonGroupSwitch
                                            name="dietaryFoodAllergies"
                                            control={control}
                                        />
                                    </div>
                                    <TextAreaInputWithValid
                                        register={register}
                                        name={"dietaryFoodAllergiesComment"}
                                        placeholder={"Comments"}
                                    />
                                </div>
                            </>
                        )}
                        <div className="col-span-6 lg:col-span-3">
                            <div className="flex max-w-xs">
                                <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                    Preferred Beverages
                                </div>
                                <ButtonGroupSwitch
                                    name="dietaryPreferredBeverages"
                                    control={control}
                                />
                            </div>
                            <TextAreaInputWithValid
                                register={register}
                                name={"dietaryPreferredBeveragesComment"}
                                placeholder={"Comments"}
                            />
                        </div>
                        <div className="col-span-6 lg:col-span-3">
                            <div className="flex max-w-xs">
                                <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                    Alcohol
                                </div>
                                <ButtonGroupSwitch
                                    name="dietaryAlcohol"
                                    control={control}
                                />
                            </div>
                            <TextAreaInputWithValid
                                register={register}
                                name={"dietaryAlcoholComment"}
                                placeholder={"Comments"}
                            />
                        </div>
                        <div className="col-span-6 lg:col-span-3">
                            <TextAreaInputWithValid
                                register={register}
                                name={"dietaryOtherComment"}
                                placeholder={"Other Comments"}
                            />
                        </div>
                    </div>
                    <div className="mt-24 flex justify-end">
                        <span className="mr-4">
                            <Button
                                outlined
                                label="Cancel"
                                onClick={onCancel}
                            />
                        </span>
                        <span>
                            <Button type="submit" label="Save" />
                        </span>
                    </div>
                </form>
            </div>
        </>
    );
}
