import { useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Icon from "../../../../assets/svg/Icon";

const imgStyle: any = {
    transform: "scale(1)",
    transition:
        "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
};

const Image = ({
    photo,
    onImageClick,
    onImageDelete,
    className,
    selected,
}: any) => {
    const imgClass = useMemo(() => {
        return `${className || ""} ${
            selected && "border-4 border-orange-600"
        } rounded-md cursor-pointer hover:border-2 hover:border-orange-600`;
    }, [className, selected]);
    return (
        <div>
            <div className="gallery-image__item-2 relative">
                {photo?.canEdit === "true" && (
                    <div
                        onClick={() => {
                            onImageDelete && onImageDelete(photo);
                        }}
                        className="absolute top-4 right-4 bg-neutral-400/50 hover:bg-neutral-400 text-white rounded-lg text-xs p-1 hover:text-lg"
                    >
                        <Icon
                            icon="TrashIcon"
                            className="w-7 cursor-pointer z-10"
                        />
                    </div>
                )}

                <LazyLoadImage
                    onClick={() => {
                        onImageClick && onImageClick(photo);
                    }}
                    className={imgClass}
                    alt={photo.title}
                    effect="blur"
                    src={photo.src}
                    placeholderSrc={photo.thumbnail}
                    style={imgStyle}
                    {...photo}
                />
            </div>
        </div>
    );
};

export default Image;
