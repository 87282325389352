interface DetailColumnProps {
    children: React.ReactNode;
    className?: string;
}

const DetailColumn = ({ children, className }: DetailColumnProps) => (
    <div className={`col-span-6 sm:col-span-3 ${className}`}>{children}</div>
);

export default DetailColumn;
