import React, { useEffect, useState } from "react";
import Avatar from "../../../../components/Avatar";
import { useChatForm } from "../../hooks/useChat.hook";
import { mdiMagnify } from "@mdi/js";
import { Icon as MDIcon } from "@mdi/react";
import server from "../../../../api/server";
import { ButtonSize, PAGE_ROWS } from "../../../../constants";
import Loader from "../../../../components/Loader";
import axios, { CancelTokenSource } from "axios";
import ButtonSquared from "../../../../components/ButtonSquare";
import useDebounce from "../../../../hooks/useDebounce";
import { IContact } from "../../../../types/chat.type";

interface UserContactListModalProps {
    onClose: () => void;
}

const UserContactListModal = ({ onClose }: UserContactListModalProps) => {
    const { onSelectedUser, handleClearSearch } = useChatForm();
    const [cancelToken, setCancelToken] = useState<
        CancelTokenSource | undefined
    >();
    const [searchText, setSearchText] = useState("");
    const [contactSearchLists, setContactSearchLists] = useState<any>();
    const [loading, setIsLoading] = useState<boolean>(false);
    const debouncedSearchText = useDebounce(searchText, 500);
    useEffect(() => {
        cancelToken?.cancel();
        setContactSearchLists({
            data: [],
            isLoading: false,
            take: PAGE_ROWS,
            skip: 0,
            currentPage: 1,
            totalPages: 1,
        });
        if (!!debouncedSearchText?.length) {
            getContacts(true);
        }
    }, [debouncedSearchText]);

    const getContacts = async (newSearch?: boolean) => {
        setIsLoading(true);
        try {
            const newCancelToken = axios.CancelToken.source();
            setCancelToken(newCancelToken);
            const response = await server.getUsersContacts(
                searchText,
                PAGE_ROWS,
                newSearch ? 0 : contactSearchLists?.skip,
                cancelToken
            );
            const { currentPage, data, ...others } = response?.data;
            const newSkip = currentPage * PAGE_ROWS;

            setContactSearchLists({
                ...others,
                data: [...(newSearch ? [] : contactSearchLists?.data), ...data],
                skip: newSkip,
                take: PAGE_ROWS,
                currentPage,
            });
        } catch (error) {
        } finally {
            setCancelToken(undefined);
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        handleClearSearch();
        onClose();
    };

    const handleUserClick = (user: IContact) => {
        if (!user) {
            return;
        }
        onSelectedUser(user);
        handleClose();
    };

    const searchContact = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value.toLowerCase() || "");
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg w-96 p-6">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-semibold">New Chat</h3>
                    <button
                        onClick={handleClose}
                        className="text-gray-600 text-2xl"
                    >
                        &times;
                    </button>
                </div>
                <div className="mb-4 relative">
                    <input
                        type="text"
                        placeholder="Search"
                        className="w-full p-2 border border-gray-300 rounded pl-10"
                        onChange={searchContact}
                    />
                    <MDIcon
                        path={mdiMagnify}
                        size={1}
                        color="black"
                        className="absolute top-2 left-3"
                    />
                </div>
                <div className="overflow-y-auto h-64">
                    {contactSearchLists?.data.map((user: any) => (
                        <div
                            key={user?.uid}
                            className={`flex items-center p-2 hover:bg-gray-100 cursor-pointer`}
                            onClick={() => handleUserClick(user)}
                        >
                            {user.avatarThumb || user.avatar ? (
                                <img
                                    src={user.avatarThumb || user.avatar}
                                    alt=""
                                    className="w-8 h-8 rounded-full mr-2"
                                />
                            ) : (
                                <Avatar className="w-8 h-8 mr-2" />
                            )}
                            <p className={`flex-grow text-black text-left`}>
                                {user.firstName || ""} {user.lastName || ""}
                            </p>
                        </div>
                    ))}
                    {loading && (
                        <div className="flex flex-1 items-center w-full justify-center my-6">
                            <Loader />
                        </div>
                    )}

                    {!loading && !contactSearchLists?.data?.length && (
                        <div className="flex flex-1 items-center w-full justify-center my-6">
                            <div className="text-neutral-800 text-base">
                                There are no results to show.
                            </div>
                        </div>
                    )}

                    {/* {!loading &&
                        !!contactSearchLists?.data?.length &&
                        contactSearchLists?.totalPages >
                            contactSearchLists?.currentPage && (
                            <div
                                onClick={() => {
                                    getContacts();
                                }}
                                className="text-orange-600 hover:text-orange-500 cursor-pointer mt-6 font-semibold"
                            >
                                Load more
                            </div>
                        )} */}
                </div>
                <div className="flex justify-end mt-4">
                    <ButtonSquared
                        onClick={handleClose}
                        size={ButtonSize.SMALL}
                        label="Close"
                    />
                </div>
            </div>
        </div>
    );
};

export default UserContactListModal;
