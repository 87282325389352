import React, { useState } from "react";
import server from "../../api/server";
import AutocompleteInput from "../Autocomplete/AutocompleteInput";

const HotelGroupsAutocomplete = React.forwardRef((props: any, ref: any) => {
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<Array<any>>([]);
    const {
        label,
        id,
        name,
        type,
        placeholder,
        defaultValue,
        error,
        onChange,
        ...others
    } = props;

    const handleOnSearch = async (text: string) => {
        setSearchResults([]);
        try {
            setLoading(true);
            const hotelGroups = await getHotelGroups(text);
            let newResults: Array<any> = [];
            if (hotelGroups && Array.isArray(hotelGroups)) {
                newResults = hotelGroups;
            }
            setSearchResults(newResults);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const getHotelGroups = async (text: any) => {
        const response = await server.getHotelGroups(text);
        return response.data || [];
    };

    const handleHotelSelect = async (selectedOption: any) => {
        if (onChange) onChange(selectedOption);
    };

    return (
        <>
            <AutocompleteInput
                loading={loading}
                onSearch={handleOnSearch}
                onChange={handleHotelSelect}
                changeTextOnUpdate={true}
                label={label}
                ref={ref}
                type={type}
                name={name}
                id={id}
                defaultValue={defaultValue || ""}
                placeholder={placeholder}
                error={error}
                searchResults={searchResults}
                searchResultLabel="name"
                searchResultSubheader="Hotel Groups"
                className={`py-2 pl-12 !border-[.5px] rounded-[40px] border-[#E5E5E5] block w-full text-base font-normal bg-[left_12px_top_12px] !bg-[url('./assets/svg/MagnifyIcon.svg')] bg-no-repeat`}
                {...others}
            />
        </>
    );
});
export default HotelGroupsAutocomplete;
