import React from "react";
import InputErrorLabel from "../InputErrorLabel";
import InputLabel from "../InputLabel";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Controller } from "react-hook-form";
import FlagComponent from "./FlagComponent";

const inputClasses = {
    normal: "border-b border-gray-200 focus-within:border-b focus-within:border-orange-600 hover:border-orange-600 pr-3 pb-1 block w-full text-lg font-normal",
    error: "border-b border-alert_red px-3 py-2 block w-full text-lg font-normal",
};

const PhoneInputw = React.forwardRef((props: any, ref: any) => {
    const { label, id, name, placeholder, control, error, markRequired } = props;
    return (
        <>
            <InputLabel markRequired={markRequired} inputId={id} label={label} error={error} />
            <Controller
                name={name}
                control={control}
                rules={{
                    validate: (value) => isValidPhoneNumber(value),
                }}
                render={({ field: { onChange, value } }) => (
                    <PhoneInput
                        flagComponent={FlagComponent}
                        internationalIcon={(props) => {return <img src="https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" alt="" />}}
                        international
                        value={value}
                        onChange={onChange}
                        defaultCountry="US"
                        id={id}
                        label={""}
                        placeholder={placeholder}
                        className={
                            error ? inputClasses.error : inputClasses.normal
                        }
                    />
                )}
            />

            <InputErrorLabel error={error} />
        </>
    );
});
export default PhoneInputw;
