import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { PAGE_ROWS, TripStatus, UserStatusOnTrip } from "../../constants";
import { handleError } from "../authentication/userSlice";
export interface TripState {
    isLoading: Boolean;
    activeTrips: IPaginatedData;
    pendingTrips: IPaginatedData;
    currentTrips: IPaginatedData;
    futureTrips: IPaginatedData;
    finishedTrips: IPaginatedData;
    homeNotifications: IPaginatedData;
    pendingActions: IPaginatedData;
    error: string;
}

interface IPaginatedData {
    data: Array<any>;
    count: number;
    isLoading: boolean;
}

const IPaginatedDataInitialState = {
    data: [],
    count: 0,
    currentPage: 1,
    skip: 0,
    take: PAGE_ROWS,
    isLoading: false,
};

const initialState: TripState = {
    isLoading: false,
    activeTrips: IPaginatedDataInitialState,
    pendingTrips: IPaginatedDataInitialState,
    currentTrips: IPaginatedDataInitialState,
    futureTrips: IPaginatedDataInitialState,
    finishedTrips: IPaginatedDataInitialState,
    homeNotifications: IPaginatedDataInitialState,
    pendingActions: IPaginatedDataInitialState,
    error: "",
};

export const homeSlice = createSlice({
    name: "home",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setActiveTrips: (state, action: PayloadAction<{ trips: any }>) => {
            state.activeTrips = action.payload.trips;
        },
        setPendingTrips: (state, action: PayloadAction<{ trips: any }>) => {
            state.pendingTrips = action.payload.trips;
        },
        setCurrentTrips: (state, action: PayloadAction<{ trips: any }>) => {
            state.currentTrips = action.payload.trips;
        },
        setFutureTrips: (state, action: PayloadAction<{ trips: any }>) => {
            state.futureTrips = action.payload.trips;
        },
        setFinishedTrips: (state, action: PayloadAction<{ trips: any }>) => {
            state.finishedTrips = action.payload.trips;
        },
        setPendingActions: (state, action: PayloadAction<{ pendingActions: any }>) => {
            state.pendingActions = action.payload.pendingActions;
        },
        setHomeNotifications: (
            state,
            action: PayloadAction<{ notifications: any }>
        ) => {
            state.homeNotifications = action.payload.notifications;
        },
    },
});

export const {
    isLoading,
    setCurrentTrips,
    setFutureTrips,
    setFinishedTrips,
    setHomeNotifications,
    setActiveTrips,
    setPendingTrips,
    setPendingActions,
    setError,
} = homeSlice.actions;

export const selectActiveTrips = (state: RootState) =>
    state.home?.activeTrips;
export const selectPendingTrips = (state: RootState) =>
    state.home?.pendingTrips;
export const selectCurrentTrips = (state: RootState) =>
    state.home?.currentTrips;
export const selectFutureTrips = (state: RootState) => state.home?.futureTrips;
export const selectFinishedTrips = (state: RootState) =>
    state.home?.finishedTrips;
export const selectHomeNotifications = (state: RootState) =>
    state.home?.homeNotifications;
export const selectPendingActions = (state: RootState) =>
    state.home?.pendingActions;


export const getActiveTrips = (): AppThunk => async (dispatch, getState) => {
    const currentState = getState()?.home?.activeTrips || {};
    try {
        dispatch(
            setActiveTrips({
                trips: {
                    count: currentState?.count,
                    data: [],
                    isLoading: true,
                },
            })
        );
        const [pendingTrips, activeTrips] = await Promise.all([
            serverApi.getTrips(
                2,
                0,
                TripStatus.ACTIVE,
                UserStatusOnTrip.PENDING
            ),
            serverApi.getTrips(
                2,
                0,
                TripStatus.ACTIVE,
                UserStatusOnTrip.CONFIRMED
            ),
        ]);
        dispatch(setActiveTrips({ trips: activeTrips.data }));
        dispatch(setPendingTrips({ trips: pendingTrips.data }));
    } catch (error) {
        dispatch(
            setActiveTrips({
                trips: currentState,
            })
        );
        dispatch(handleError(error));
    }
};

export const getCurrentTrips = (): AppThunk => async (dispatch, getState) => {
    const currentState = getState()?.home?.currentTrips || {};
    try {
        dispatch(
            setCurrentTrips({
                trips: {
                    count: currentState?.count,
                    data: [],
                    isLoading: true,
                },
            })
        );
        const response = await serverApi.getTrips(0, 0, TripStatus.IN_PROGRESS, UserStatusOnTrip.CONFIRMED);
        dispatch(setCurrentTrips({ trips: response.data }));
        return response?.data;
    } catch (error) {
        dispatch(
            setCurrentTrips({
                trips: currentState,
            })
        );
        dispatch(handleError(error));
    }
};

export const getFutureTrips = (): AppThunk => async (dispatch, getState) => {
    const currentState = getState()?.home?.futureTrips || {};
    try {
        dispatch(
            setFutureTrips({
                trips: {
                    count: currentState?.count,
                    data: [],
                    isLoading: true,
                },
            })
        );
        const response = await serverApi.getTrips(1, 0, TripStatus.FUTURE, UserStatusOnTrip.CONFIRMED);
        dispatch(setFutureTrips({ trips: response.data }));
        return response?.data;
    } catch (error) {
        dispatch(
            setFutureTrips({
                trips: currentState,
            })
        );
        dispatch(handleError(error));
    }
};
export const getFinishedTrips = (): AppThunk => async (dispatch, getState) => {
    const currentState = getState()?.home?.finishedTrips || {};
    try {
        dispatch(
            setFinishedTrips({
                trips: {
                    count: currentState?.count,
                    data: [],
                    isLoading: true,
                },
            })
        );
        const response = await serverApi.getTrips(8, 0, TripStatus.FINISHED, UserStatusOnTrip.CONFIRMED);
        dispatch(setFinishedTrips({ trips: response.data }));
        return response?.data;
    } catch (error) {
        dispatch(
            setFinishedTrips({
                trips: currentState,
            })
        );
        dispatch(handleError(error));
    }
};

export const getHomeNotifications =
    (take: number = 8, skip: number = 0, search?: any): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState()?.home?.homeNotifications || {};
        try {
            dispatch(
                setHomeNotifications({
                    notifications: {
                        count: currentState?.count,
                        data: [],
                        isLoading: true,
                        take: take,
                        skip: skip,
                    },
                })
            );
            const response = await serverApi.getUserNotifications(take, skip);
            dispatch(
                setHomeNotifications({
                    notifications: {
                        ...response.data,
                        isLoading: false,
                    },
                })
            );
            return response?.data;
        } catch (error) {
            dispatch(
                setHomeNotifications({
                    notifications: currentState,
                })
            );
            dispatch(handleError(error));
        }
    };

export const getPendingActions = (): AppThunk => async (dispatch, getState) => {
    const currentState = getState()?.home?.pendingActions || {};
    try {
        dispatch(
            setPendingActions({
                pendingActions: {
                    count: currentState?.count,
                    data: [],
                    isLoading: true,
                },
            })
        );
        const response = await serverApi.getPendingActions(10, 0);
        dispatch(setPendingActions({ pendingActions: response.data }));
        return response?.data;
    } catch (error) {
        dispatch(
            setPendingActions({
                pendingActions: currentState,
            })
        );
        dispatch(handleError(error));
    }
};

export default homeSlice.reducer;
