import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import {
    activityFormCurrentStep,
    selectActivityForm,
    selectedTrip,
    submitActivityFormStep,
} from "../../../../../tripSlice";
import { ActivityType, ButtonSize, PAGE_SECTIONS } from "../../../../../../../constants";
import { setCurrentSection } from "../../../../../../layout/layoutSlice";
import Modal, { Modal_Size } from "../../../../../../../components/Modal";
import ButtonSquared from "../../../../../../../components/ButtonSquare";
import OtherForm from "../../../../../../others/OtherForm";
import OthersAutocompleteWithValid from "../../../../../../../components/OthersAutocomplete";
import { setSelectedOther } from "../../../../../../others/othersSlice";
import SelectFromLibraryModal from "../../../Components/SelectFromLibrary";
import { useConstantsContext } from "../../../../../../../context/Constants";

const schema = yup.object({
    other: yup.object().required("This field is required"),
});

const OtherSelectionForm = React.forwardRef((props: any, ref: any) => {
    const [searchValue, setSearchValue] = useState<any>("");
    const [addOtherModalOpen, setaddOtherModalOpen] = useState(false);
    const [selectFromLibraryModal, setSelectFromLibraryModal] = useState(false);
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    let { activityType } = useParams();
    const { constants } = useConstantsContext();
    const { isLibraryElement } = props;
    const currentStep = useAppSelector(activityFormCurrentStep);
    const activityForm = useAppSelector(selectActivityForm);
    const { formState, handleSubmit, control } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    const { isValid } = formState;

    useEffect(() => {
        dispatch(
            setSelectedOther({ selectedOther: activityForm?.other || null })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityForm?.other]);

    const handleSubmitForm = async (data: any) => {
        saveData(data.other, activityType);
    };

    const saveData = (other: any, actType: string | undefined) => {
        const activity: any = {
            type: actType,
            other: other,
        };
        dispatch(
            submitActivityFormStep(
                activity,
                activityType || ActivityType.OTHER,
                currentStep || ""
            )
        );
    };

    const handleSelectOther = (other: any) => {
        if (other) saveData(other, activityType);
    };

    const onSelectFromLibrary = (element: any) => {
        dispatch(
            setSelectedOther({ selectedOther: element?.other || null })
        );
        dispatch(
            submitActivityFormStep(
                element,
                ActivityType.OTHER,
                currentStep || ""
            )
        );
        setSelectFromLibraryModal(false);
    };

    const handleOpenSelectFromLibraryModal = () => {
        dispatch(
            setSelectedOther({ selectedOther: null })
        );
        setSelectFromLibraryModal(true);
    };

    const handleOtherModalToggle = (open: boolean) => {
        setaddOtherModalOpen(open);
    };

    const handleOnchangeOtherSearch = (val: any) => {
        setSearchValue(val);
    };

    return (
        <div className="lg:min-h-full pb-16" ref={ref}>
            <Modal
                open={addOtherModalOpen}
                size={Modal_Size.lg}
                onClose={() => {
                    handleOtherModalToggle(false);
                }}
                hideCloseButton
            >
                <OtherForm
                    name={searchValue}
                    onCancel={() => {
                        handleOtherModalToggle(false);
                    }}
                    onChange={(other: any) => {
                        handleOtherModalToggle(false);
                        if (other) {
                            handleSelectOther(other);
                        }
                    }}
                />
            </Modal>
            <SelectFromLibraryModal
                isOpen={selectFromLibraryModal}
                onClose={() => setSelectFromLibraryModal(false)}
                activityType={ActivityType.OTHER}
                onSelectFromLibrary={onSelectFromLibrary}
            />
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
                className="text-left lg:px-6 lg:min-h-full"
            >
                <div className="text-lg font-semibold mb-6">
                    Select the activity where you would like to do
                </div>
                <div className="">
                    <OthersAutocompleteWithValid
                        control={control}
                        name="other"
                        className={`py-2 pl-12 !border-[.5px] rounded-[40px] border-[#E5E5E5] block w-full text-base font-normal bg-[left_12px_top_12px] !bg-[url('./assets/svg/MagnifyIcon.svg')] bg-no-repeat`}
                        handleSelect={handleSelectOther}
                        onTextChange={handleOnchangeOtherSearch}
                        type="text"
                        defaultValue={activityForm?.other || null}
                        placeholder="Search"
                    />
                </div>

                {searchValue && (
                    <div className="ml-4 mt-4 flex text-sm">
                        Couldn't you find the activity? You can{" "}
                        <span
                            onClick={() => {
                                dispatch(
                                    setSelectedOther({ selectedOther: null })
                                );
                                handleOtherModalToggle(true);
                            }}
                            className="mx-2 cursor-pointer font-semibold text-orange-600"
                        >
                            add
                        </span>
                        one
                    </div>
                )}
                {!searchValue && (
                    <div className="ml-4 mt-4 flex text-sm">
                        <span
                            onClick={() => {
                                dispatch(
                                    setSelectedOther({ selectedOther: null })
                                );
                                handleOtherModalToggle(true);
                            }}
                            className="mx-2 cursor-pointer font-semibold text-orange-600"
                        >
                            Add
                        </span>
                        a new one
                    </div>
                )}
                {constants?.featureFlags?.LIBRARY && !isLibraryElement &&  !searchValue && !!trip?.organization?.id && (
                    <div className="mt-8">
                        <div className="text-lg font-semibold mb-6">
                            Or choose from library
                        </div>
                        <ButtonSquared
                            outlined
                            label="Select element from library"
                            onClick={handleOpenSelectFromLibraryModal}
                            size={ButtonSize.FIT}
                            className="py-2 px-7"
                        />
                    </div>
                )}
                <div className="flex gap-3 justify-end px-4 py-3 text-right mt-12 lg:absolute right-4 bottom-4">
                    <ButtonSquared
                        onClick={props.navigateBack}
                        outlined
                        type="button"
                        label="Cancel"
                    />
                    <ButtonSquared
                        type="submit"
                        label="Next"
                        disabled={!isValid}
                    />
                </div>
            </form>
        </div>
    );
});

export default OtherSelectionForm;
