import { ButtonHTMLAttributes, useMemo } from "react";
import { ButtonSize } from "../constants";

const ButtonSizeValues: any = {
    [ButtonSize.NORMAL]: "min-w-[256px] px-6 py-2",
    [ButtonSize.SMALL]: "w-fit px-6 py-2",
    [ButtonSize.FIT]: "w-fit px-6 py-2",
};
const disabledClasses =
    "bg-neutral-300 hover:bg-neutral-300 border-neutral-300 text-white";
const disabledClassesOutlined =
    "border bg-transparent border-neutral-300 text-neutral-300";
const notDisabledClasses =
    "hover:text-white text-white hover:border-primary_orange-500 bg-primary_orange-900 hover:bg-primary_orange-500 border-primary_orange-900";
const notDisabledClassesOutlined =
    "hover:text-primary_orange-500 text-primary_orange-900 hover:border-primary_orange-500 border-primary_orange-900 border bg-transparent";
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string | JSX.Element;
    className?: string;
    size?: ButtonSize;
    outlined?: boolean;
}

const ButtonSquared: React.FC<ButtonProps> = ({
    label,
    size,
    outlined,
    disabled,
    className,
    ...rest
}: ButtonProps) => {
    const classes = useMemo(() => {
        const sizeVal = size
            ? ButtonSizeValues[size]
            : ButtonSizeValues[ButtonSize.NORMAL];
        const customClasses = disabled
            ? outlined
                ? disabledClassesOutlined
                : disabledClasses
            : outlined
            ? notDisabledClassesOutlined
            : notDisabledClasses;
        return `${sizeVal} inline-flex justify-center py-1 text-md font-semibold rounded-md focus:outline-none ${customClasses} ${className}`;
    }, [size, outlined, disabled, className]);

    return (
        <button disabled={disabled} className={classes} {...rest}>
            {label}
        </button>
    );
};

export default ButtonSquared;
