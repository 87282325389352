import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Input from "../../../../components/Input";
import Button from "../../../../components/ButtonSquare";
import PhoneInput from "../../../../components/PhoneInput";

const schema = yup
    .object({
        firstName: yup.string().required("This field is required").min(2),
        phone: yup.string().nullable(),
        mobilePhone: yup.string().nullable(),
        role: yup.string().nullable(),
        email: yup.string().email().nullable(),
    })
    .required("This field is required");

export default function ContactForm(props: any) {
    const { onSubmit, onClose, contact } = props;
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (contact) {
            reset({
                firstName: contact.firstName,
                role: contact.role,
                phone: contact.phone,
                mobilePhone: contact.mobilePhone,
                email: contact.email,
            });
            //  setIsUpdate(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact?.firstName, contact?.phone, contact?.mobilePhone]);

    const handleSubmitForm = async (data: any) => {
        const contact = {
            firstName: data.firstName,
            role: data.role,
            phone: data.phone,
            mobilePhone: data.mobilePhone,
            email: data.email,
        };
        if (onSubmit) onSubmit(contact);
    };

    return (
        <>
            <div className="pl-6 font-semibold">Contact information</div>
            <div className="p-6">
                <div className="mt-6 lg:mt-0 lg:col-span-6">
                    <form
                        action="#"
                        method="POST"
                        onSubmit={handleSubmit(handleSubmitForm)}
                    >
                        <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                            <div className="col-span-1 sm:col-span-2 mt-3">
                                <Input
                                    register={register}
                                    //defaultValue={selectedOther?.name}
                                    label="Name"
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    error={errors["firstName"]?.message}
                                />
                            </div>
                            <div className="col-span-1 sm:col-span-1 mt-3">
                                <Input
                                    register={register}
                                    label="Email"
                                    id="email"
                                    name="email"
                                    trim={true}
                                    error={errors["email"]?.message}
                                />
                            </div>
                            <div className="col-span-1 sm:col-span-1 mt-3">
                                <Input
                                    register={register}
                                    label="Position"
                                    id="role"
                                    name="role"
                                    type="text"
                                    error={errors["role"]?.message}
                                />
                            </div>
                            <div className="col-span-1 sm:col-span-1 mt-3">
                                <PhoneInput
                                    control={control}
                                    label="Phone number"
                                    id="phone"
                                    name="phone"
                                    error={errors["phone"]?.message}
                                />
                            </div>
                            <div className="col-span-1 sm:col-span-1 mt-3">
                                <PhoneInput
                                    label="Mobile number"
                                    control={control}
                                    id="mobilePhone"
                                    name="mobilePhone"
                                    error={errors["mobilePhone"]?.message}
                                />
                            </div>
                        </div>
                        <div className="mt-12 text-right flex justify-end gap-x-3">
                            <Button
                                type="button"
                                outlined
                                label="Cancel"
                                onClick={onClose}
                            />
                            <Button type="submit" label="Save" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
