import Skeleton from "react-loading-skeleton";
import { PAGE_ROWS } from "../constants";
//import "react-loading-skeleton/dist/skeleton.css";
import Paginator from "./Paginator";

interface IList {
    items: any;
    handlePaginatorChange: any;
    renderItem: any;
    itemKey: Function;
}
export default function ListWithPagination({
    items,
    handlePaginatorChange,
    renderItem: Item,
    itemKey,
}: IList) {
    const { data, count, totalPages, currentPage, isLoading } = items;

    const Loader = () => {
        return (
            <div>
                <Skeleton count={3} className="h-20 my-4" />
            </div>
        );
    };

    return isLoading ? (
        <Loader />
    ) : (
        data ? (
            <div>
                {data?.map((u: any, index: number) => {
                    const key = itemKey(u);
                    return <Item item={u} key={key} index={index} />;
                })}
                {count > PAGE_ROWS && (
                    <div className="self-end text-xs z-20 mt-4">
                        <Paginator
                            count={count}
                            take={PAGE_ROWS}
                            onChange={handlePaginatorChange}
                            totalPages={totalPages}
                            currentPage={currentPage}
                        />
                    </div>
                )}
            </div>
        ): null
    );
}
