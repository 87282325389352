import { ReactNode } from "react";

const InsightGalleryContainer = ({
    children,
    className,
}: {
    children?: ReactNode;
    className?: string;
}) => {
    return (
        <div
            className={`flex flex-wrap gap-4 max-h-[450px] mostly-customized-scrollbar overflow-y-auto py-2 sm:justify-normal justify-evenly ${className}`}
        >
            {children}
        </div>
    );
};

export default InsightGalleryContainer;
