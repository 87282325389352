import { mdiLogoutVariant } from '@mdi/js';
import { Icon as MDIcon } from "@mdi/react";

export default ({
  width = 20,
  height = 20,
  ...otherProps
}) => (
  <MDIcon path={mdiLogoutVariant} {...otherProps}/>
);
