import { useMemo } from "react";
import ButtonGroupSwitchInput from "../../../../components/ButtonGroupSwitch/ButtonGroupSwitchInput";
import { dietaryRestrictionsComplete } from "../../../../helpers";
import { Paragraph } from "../../../../components/Paragraph/Paragraph";
import ButtonSquared from "../../../../components/ButtonSquare";
import { ButtonSize } from "../../../../constants";

export default function DietaryInfo(props: any) {
    const { profileExtra, isMe, profile, onEdit, buttonText } = props;
    const dietaryCompleted = useMemo(() => {
        return dietaryRestrictionsComplete(profileExtra);
    }, [profileExtra]);

    return (
        <>
            <div className="flex flex-col">
                {!dietaryCompleted ? (
                    isMe ? (
                        <div className="text-center">
                            <div className="text-lg text-neutral-900 font-semibold mt-12">
                                What are your dietary preferences?
                            </div>
                            <div className="text-base text-neutral-900 my-4">
                                Sharing this information helps us personalize
                                <br />
                                your trips experiences.
                            </div>
                            <ButtonSquared
                                label={buttonText || "Add Restrictions"}
                                className="!px-4"
                                onClick={() => onEdit()}
                                size={ButtonSize.FIT}
                            />
                        </div>
                    ) : (
                        <div className="text-lg text-neutral-900 font-semibold mt-12">
                            Looks like {profile?.firstName || "this user"}{" "}
                            hasn't shared <br /> their dietary restrictions yet.
                        </div>
                    )
                ) : (
                    <div className="grid grid-cols-6 xl:gap-x-32 gap-x-6 gap-y-6">
                        {profileExtra?.dietaryNoRestrictions ? (
                            <div className="col-span-6 font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                I don't have any dietary restrictions.
                            </div>
                        ) : (
                            <>
                                <div className="col-span-6 lg:col-span-3">
                                    <div className="flex max-w-xs">
                                        <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                            Vegetarian
                                        </div>
                                        <ButtonGroupSwitchInput
                                            id="dietaryVegetarian"
                                            name="dietaryVegetarian"
                                            value={
                                                profileExtra?.dietaryVegetarian
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                    <Paragraph className="text-base text-gray-500 text-left mt-2">
                                        {profileExtra?.dietaryVegetarianComment}
                                    </Paragraph>
                                </div>
                                <div className="col-span-6 lg:col-span-3">
                                    <div className="flex max-w-xs">
                                        <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                            Vegan
                                        </div>
                                        <ButtonGroupSwitchInput
                                            id="dietaryVegan"
                                            name="dietaryVegan"
                                            value={profileExtra?.dietaryVegan}
                                            disabled={true}
                                        />
                                    </div>
                                    <Paragraph className="text-base text-gray-500 text-left mt-2">
                                        {profileExtra?.dietaryVeganComment}
                                    </Paragraph>
                                </div>
                                <div className="col-span-6 lg:col-span-3">
                                    <div className="flex max-w-xs">
                                        <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                            Gluten intolerance
                                        </div>
                                        <ButtonGroupSwitchInput
                                            id="dietaryGlutenIntolerance"
                                            name="dietaryGlutenIntolerance"
                                            value={
                                                profileExtra?.dietaryGlutenIntolerance
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="text-base text-gray-500 text-left mt-2 break-words">
                                        {
                                            profileExtra?.dietaryGlutenIntoleranceComment
                                        }
                                    </div>
                                </div>
                                <div className="col-span-6 lg:col-span-3">
                                    <div className="flex max-w-xs">
                                        <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                            Food allergies
                                        </div>
                                        <ButtonGroupSwitchInput
                                            id="dietaryFoodAllergies"
                                            name="dietaryFoodAllergies"
                                            value={
                                                profileExtra?.dietaryFoodAllergies
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="text-base text-gray-500 text-left mt-2 break-words">
                                        {
                                            profileExtra?.dietaryFoodAllergiesComment
                                        }
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="col-span-6 lg:col-span-3">
                            <div className="flex max-w-xs">
                                <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                    Preferred Beverages
                                </div>
                                <ButtonGroupSwitchInput
                                    id="dietaryPreferredBeverages"
                                    name="dietaryPreferredBeverages"
                                    value={
                                        profileExtra?.dietaryPreferredBeverages
                                    }
                                    disabled={true}
                                />
                            </div>
                            <div className="text-base text-gray-500 text-left mt-2 break-words">
                                {profileExtra?.dietaryPreferredBeveragesComment}
                            </div>
                        </div>
                        <div className="col-span-6 lg:col-span-3">
                            <div className="flex max-w-xs">
                                <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                    Alcohol
                                </div>
                                <ButtonGroupSwitchInput
                                    id="dietaryAlcohol"
                                    name="dietaryAlcohol"
                                    value={profileExtra?.dietaryAlcohol}
                                    disabled={true}
                                />
                            </div>
                            <Paragraph className="text-base text-gray-500 text-left mt-2">
                                {profileExtra?.dietaryAlcoholComment}
                            </Paragraph>
                        </div>
                        <div className="col-span-6 lg:col-span-3">
                            <div className="flex max-w-xs">
                                <div className="font-semibold text-base text-left text-gray-600 flex-grow self-center">
                                    Other Comments
                                </div>
                            </div>
                            <Paragraph className="text-base text-gray-500 text-left mt-2">
                                {profileExtra?.dietaryOtherComment}
                            </Paragraph>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
