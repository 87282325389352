import React, { FC, InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import SelectInput from "./SelectInput";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: any;
  register?: any;
  defaultValue?: any;
  className?: string;
  control?: any;
  isMulti?: boolean;
  options: Array<any>;
  isDisabled?: boolean;
  selectedOptionsMaxLength?: number;
  closeMenuOnSelect?: boolean;
  markRequired?: boolean;
  value?: any;
}

const SelectInputWithValid: FC<InputProps> = ({
  name,
  error,
  label,
  control,
  defaultValue,
  onChange,
  ...rest
}) => {
  const handleChange = (other: any, handleOnChangeValidator: Function) => {
    if (onChange) onChange(other);
    if (handleOnChangeValidator) handleOnChangeValidator(other);
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, ref } }: any) => (
        <SelectInput
          name={name}
          label={label}
          onChange={(a: any) => {
            handleChange(a, onChange);
          }}
          defaultValue={defaultValue}
          onBlur={onBlur}
          ref={ref}
          error={error && Object.keys(error).length > 0 && "Required field"}
          {...rest}
        />
      )}
    />
  );
};

export default SelectInputWithValid;
