import { FC, InputHTMLAttributes } from "react";
import PhoneInput from "./PhoneInput";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    error?: any;
    register?: any;
    control?: any;
    className?: string;
    markRequired?: boolean;
}

const PhoneInputWithValid: FC<InputProps> = ({
    control,

    name,
    error,
    label,
    ...rest
}) => {
    return (
        <PhoneInput
            aria-invalid={error ? "true" : "false"}
            control={control}
            error={error}
            label={label}
            name={name}
            maxLength={20}
            {...rest}
        />
    );
};

export default PhoneInputWithValid;
