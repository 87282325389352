import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import { getAllPlans, selectAdminPlans } from "../adminSlice";
import AdminPlansList from "./components/AdminPlansList";
import PlanPermissionsForm from "./components/PlanPermissionsForm";

export default function PlansAdmin(props: any) {
    const dispatch = useAppDispatch();
    const plans = useAppSelector(selectAdminPlans);
    const [selectedPlan, setSelectedPlan] = useState<any>(null);

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_PLANS })
        );
        fetchPlans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const editPermissionsMenuOption = {
        icon: "AccountSettingsIcon",
        label: "Edit Permissions",
        key: "EDIT_PERMISSIONS",
    };

    const fetchPlans = () => {
        dispatch(getAllPlans());
    };

    const onDotsMenuClick = (action: string, element: any) => {
        setSelectedPlan(element);
    };
    return (
        <>
            {!selectedPlan ? (
                <AdminPlansList
                    handleClickDotsMenu={onDotsMenuClick}
                    plans={plans || []}
                    menuOptions={[editPermissionsMenuOption]}
                />
            ) : (
                <PlanPermissionsForm
                    plan={selectedPlan}
                    onClose={() => setSelectedPlan(null)}
                />
            )}
        </>
    );
}
