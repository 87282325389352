import InsightDetailItem from "../../../../../components/InsightDetailItem";
import { ContactTypeOptions } from "../../../../../../../../constants/insight";
import SelectInput from "../../../../../../../../components/InputSelect/SelectInput";
import Input from "../../../../../../../../components/Input";
import Button from "../../../../../../../../components/ButtonSquare";
import TextAreaInput from "../../../../../../../../components/TextArea";
import PhoneInputWithValid from "../../../../../../../../components/PhoneInput";
import InsightFormContainer from "../../components/InsightFormContainer";
import InsightInputContainer from "../../components/InsightInputContainer";
import { BlockModal } from "../../../../../../../../components/Modal/BllockerModal";

interface ContactItemProps {
    showForm: boolean;
    register: any;
    errors: any;
    activeItem?: any;
    handleCancel: () => void;
    setType: any;
    control: any;
    isValid: boolean;
    isDirty: boolean;
}
export const FormContactItem = ({
    showForm,
    register,
    errors,
    activeItem,
    handleCancel,
    setType,
    control,
    isValid,
    isDirty,
}: ContactItemProps) => {
    if (!showForm) return null;
    return (
        <>
            <div className="col-span-6 sm:col-span-3 mt-3">
                <InsightDetailItem label="">
                    <InsightFormContainer className="gap-8">
                        <InsightInputContainer>
                            <Input
                                register={register}
                                label="Name"
                                type="text"
                                name="name"
                                markRequired
                                error={errors["name"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <SelectInput
                                onChange={setType}
                                label="Type"
                                options={ContactTypeOptions}
                                name="type"
                                markRequired
                                defaultValue={activeItem?.type}
                                error={errors["type"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <Input
                                register={register}
                                label="Title"
                                type="text"
                                name="title"
                                markRequired
                                error={errors["title"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <Input
                                register={register}
                                label="Email"
                                type="text"
                                name="email"
                                error={errors["email"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <PhoneInputWithValid
                                register={register}
                                label="Phone number"
                                type="text"
                                name="phone"
                                control={control}
                                error={errors["phone"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <PhoneInputWithValid
                                register={register}
                                label="Mobile number"
                                type="text"
                                name="mobile"
                                control={control}
                                error={errors["mobile"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <TextAreaInput
                                register={register}
                                label="Comments"
                                type="text"
                                name="comments"
                                error={errors["comments"]?.message}
                            />
                        </InsightInputContainer>
                    </InsightFormContainer>
                </InsightDetailItem>
            </div>
            <div className="p-8 text-right flex gap-5 lg:justify-end">
                <BlockModal shouldBlock={isDirty} />
                <Button onClick={handleCancel} outlined label="Cancel" />
                <Button type="submit" label={"Save"} disabled={!isValid} />
            </div>
        </>
    );
};
