import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { JsxElement } from "typescript";
import { useAppSelector } from "../app/hooks";
import { selectProfile } from "../features/authentication/userSlice";

type Props = {
    children?: React.ReactNode | React.ReactChild | JsxElement;
};

const RequiresAdmin = ({ children }: Props): any => {
    const location: any = useLocation();
    const profile = useAppSelector(selectProfile);
    const redirectPath = "/home";

    return profile?.isAdmin ? (
        children
    ) : (
        <Navigate
            to={redirectPath}
            replace
            state={{ path: location.pathname }}
        />
    );
};

export default RequiresAdmin;
