import { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import AllTrips from "./AllTrips";

export default function TripsAdminHome(props: any) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_TRIPS })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AllTrips />;
}
