import Avatar from "../../../../components/Avatar";
import { PAGE_ROWS, TripUserRoleOptions } from "../../../../constants";
import Paginator from "../../../../components/Paginator";
import Table from "../../../../components/Table";
import StatusBadgeComponent from "../../../../components/StatusBadge/StatusBadgeComponent";
import DotsMenu from "../../../../components/DotsMenu";
import { getUserOnTripMenuOptions } from "../../../../bussiness/trip";
import { useMemo } from "react";

interface ITripUsersTable {
    tripUsers: any;
    canEdit: boolean;
    meOnTrip: any;
    showRoleSelectInput: Function;
    handleUserClick: Function;
    handleUserRoleChange: Function;
    handlePaginatorChange: Function;
    handleClickUserDotsMenu: Function;
    isAdmin: boolean;
    showDocuments: boolean;
}
export default function TripUsersTable({
    tripUsers,
    canEdit,
    meOnTrip,
    showRoleSelectInput,
    handleUserClick,
    handleUserRoleChange,
    handlePaginatorChange,
    handleClickUserDotsMenu,
    isAdmin,
    showDocuments,
}: ITripUsersTable) {
    const {
        data,
        count,
        isLoading,
        currentPage,
        totalPages,
        take,
        requirements,
    } = tripUsers;

    const tableHeaders = useMemo(() => {
        return ["Name / Email", "Company Name", "Status", "Role"].concat(
            showDocuments ? ["Documents", ""] : [""]
        );
    }, [showDocuments]);
    return (
        <>
            <Table headerClass="bg-white" headers={tableHeaders} isLoading={isLoading}>
                {data &&
                    data.map((u: any) => {
                        const roleLabel = TripUserRoleOptions.find(
                            (rO) => rO.value === u?.role
                        );
                        const menuOptions = getUserOnTripMenuOptions(
                            meOnTrip,
                            u,
                            isAdmin
                        );
                        return (
                            <tr
                                key={u.userEmail}
                                className={canEdit ? "hover:bg-neutral-50" : ""}
                            >
                                <td
                                    className="cursor-pointer"
                                    onClick={() => handleUserClick(u)}
                                >
                                    <div className="flex gap-3 items-center justify-start">
                                        <Avatar
                                            src={
                                                !!u?.user?.avatar_thumb?.length
                                                    ? u?.user?.avatar_thumb
                                                    : u?.user?.avatar
                                            }
                                            className="w-6 h-6"
                                        />

                                        <span className="text-left">
                                            {u.user?.firstName
                                                ? `${u.user?.firstName} ${u.user?.lastName}`
                                                : u?.userEmail}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-start">
                                        <span className="w-full text-left">
                                            {u.user?.agency}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="text-left flex justify-start">
                                        <StatusBadgeComponent
                                            status={u?.status}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="flex gap-3 text-left items-center justify-start">
                                        <div className="w-full">
                                            <div>{roleLabel?.label}</div>
                                        </div>
                                    </div>
                                </td>
                                {!!showDocuments && (
                                    <td
                                        className="text-neutral-900 text-sm text-center cursor-pointer"
                                        onClick={() => handleUserClick(u)}
                                    >
                                        <div>
                                            {u?.requirementFilesCount || 0}/
                                            {requirements || 0}
                                        </div>
                                    </td>
                                )}
                                <td>
                                    <div
                                        className="flex justify-end"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        {menuOptions?.length > 0 && (
                                            <div className="">
                                                <DotsMenu
                                                    options={menuOptions}
                                                    handleClickMenu={(
                                                        k: any
                                                    ) => {
                                                        handleClickUserDotsMenu(
                                                            k,
                                                            u
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
            </Table>
            <div className="w-full mt-8">
                {count > PAGE_ROWS && (
                    <Paginator
                        count={count}
                        take={take}
                        onChange={handlePaginatorChange}
                        totalPages={totalPages}
                        currentPage={currentPage}
                    />
                )}
            </div>
        </>
    );
}
