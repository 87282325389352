import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch } from "../../../../../../../../app/hooks";
import { PAGE_SECTIONS } from "../../../../../../../../constants";
import {
    addHotelWellnessInsight,
    removeHotelWellnessInsight,
} from "../../../../../../../hotelInsight/hotelInsightSlice";
import { useSelector } from "react-redux";
import { selectActivityInsight } from "../../../../../../tripSlice";
import { FormWellnessItem } from "./FormWellnessItem";
import {
    WELLNESS_TYPE,
    WellnessTypeOptions,
} from "../../../../../../../../constants/insight";
import { ListInsightItems } from "../../components/ListInsightItems";
import InsightGenericView from "../../components/InsightGenericView";
import WellnessInsightDetails from "./WellnessInsightDetails";

const schema = yup
    .object({
        comments: yup.string().max(5000).nullable().notRequired(),
        name: yup.string().max(500).required("This field is required"),
        type: yup.string().max(500).required("This field is required"),
        typeOther: yup
            .string()
            .max(500)
            .when("type", {
                is: WELLNESS_TYPE.OTHER,
                then: yup.string().required("This field is required"),
            }),
    })
    .required("This field is required");

export default function WellnessInsightReview() {
    const dispatch = useAppDispatch();
    const activityInsight = useSelector(selectActivityInsight);
    const hotelInsight = activityInsight?.hotelInsight;
    const { activityId, tripId } = useParams();

    const listWellnessInsight = hotelInsight?.wellnessInsights || [];

    const handleSubmitForm = async (data: any) => {
        const payload = {
            ...data,
            type: data.type,
            typeOther: data.type === WELLNESS_TYPE.OTHER ? data.typeOther : "",
        };
        await dispatch(
            addHotelWellnessInsight(
                Number(tripId),
                Number(activityId),
                activityInsight?.id,
                payload
            )
        );
    };

    const getItemTitle = (item: any) => {
        if (item.type === WELLNESS_TYPE.OTHER) {
            return item.typeOther;
        }
        const typeLabel = WellnessTypeOptions.find(
            (option) => option.value === item.type
        )?.label;
        return typeLabel || "";
    };

    const handleRemoveInsight = (itemId: number) => {
        dispatch(
            removeHotelWellnessInsight(
                Number(tripId),
                Number(activityId),
                activityInsight?.id,
                itemId
            )
        );
    };

    return (
        <InsightGenericView
            currentSection={
                PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_WELLNESS
            }
            label="Wellness"
            items={listWellnessInsight}
            commentsKey="wellnessComments"
            imagesKey="wellnessImages"
            itemDetailsView={WellnessInsightDetails}
            onRemove={handleRemoveInsight}
            listInsightItems={(props) => (
                <ListInsightItems
                    {...props}
                    getItemTitle={getItemTitle}
                    subtitleKey="name"
                    icon="SpaIcon"
                />
            )}
            formInsightItem={FormWellnessItem}
            onSubmit={handleSubmitForm}
            schema={schema}
        />
    );
}
