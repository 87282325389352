import { useState } from "react";

export default function IconBox(props: any) {
    const [showHover, setShowHover] = useState<boolean>(false)
    const { children, onClick, title, hover = "", ...others } = props;
    const onMouseOver = () => setShowHover(true)

    const onMouseLeave = () => setShowHover(false)
    return (
        <div
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            className="rounded-lg bg-white shadow-lg hover:scale-110 p-2 cursor-pointer"
            {...others}
        >
            <div>{children} {showHover && <label className="text-orange-600 text-base p-1 cursor-pointer">{title}</label>}</div>
            {showHover && <div className="absolute pt-3 text-left text-xs text-orange-600 w-64">{hover}</div>}
        </div>
    );
}
