import FamguruLogo from "../../../../assets/svg/LogoSquared.svg";
import FamguruText from "../../../../assets/svg/Famguru.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { signOut as signOutProfile } from "../../userSlice";
import { useAuthStateContext } from "../../../../context/Auth";
export const DeletedAccount = () => {
    const { signOut } = useAuthStateContext();
    const dispatch = useDispatch();
    useEffect(() => {
        signOut && signOut();
        dispatch(signOutProfile());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="w-full min-h-screen mb-12 flex flex-col items-center justify-center">
                <div className="flex flex-row items-center gap-4">
                    <a href="/">
                        <img src={FamguruLogo} alt="" />
                    </a>
                    <a href="/">
                        <img src={FamguruText} alt="" />
                    </a>
                </div>
                <div className="mt-10 text-2xl text-center font-semibold text-neutral-900">
                    Your account
                </div>
                <div className="text-2xl text-center font-semibold text-neutral-900">
                    has been disabled.
                </div>
                <div className="mt-6 text-base text-center font-semibold text-neutral-400">
                    Thanks for using our product.
                </div>
                <div className="mt-2 text-base text-center font-semibold text-neutral-400 ">
                    We hope you enjoyed your time with us.
                </div>
                <div className="mt-24 text-base text-center font-semibold text-neutral-900">
                    Connect with us
                </div>
                <div className="text-base text-center font-semibold text-neutral-400">
                    www.famguru.app
                </div>
            </div>
        </div>
    );
};
