import { useEffect, useState } from "react";
import DropImageFileInput2 from "../../../../components/DropImageFileInput2/DropImageFileInput2";
import { uploadTempFile } from "../../tripSlice";
import GalleryImage from "../../../../components/ImagesGallery/Image";
import Modal, { Modal_Size } from "../../../../components/Modal";
import TextAreaInput from "../../../../components/TextArea/TextArea";
import ButtonSquared from "../../../../components/ButtonSquare";
import { ButtonSize } from "../../../../constants";

interface InsightImagesProps {
    handleUploadFile: any;
    images?: ImagesUploadedInterface;
}

export interface ImagesInterface {
    path: string;
    src?: string;
    id: number;
    originalName?: string;
    description?: string;
    current?: boolean;
    modified?: boolean;
    deleted?: boolean;
}

interface ImagesUploadedInterface extends ImagesInterface {
    url: string;
}

interface ImagesLoaded {
    newImages?: ImagesInterface[];
    deletedImages?: string[];
    modifiedImages?: ImagesInterface[];
}

const normalizeImagesListInputData = (imag: ImagesInterface[] = []) => {
    let newImages: ImagesInterface[] = [];
    let deletedImages: string[] = [];
    let modifiedImages: ImagesInterface[] = [];
    imag.forEach((img) => {
        if (!img.current) {
            newImages.push(img);
        } else if (img.deleted) {
            deletedImages.push(img.originalName || "");
        } else {
            modifiedImages.push(img);
        }
    });
    return {
        newImages,
        modifiedImages,
        deletedImages,
    };
};

export const InsightImages = ({
    handleUploadFile,
    images,
}: InsightImagesProps) => {
    const [imagesUploaded, setImagesUploaded] = useState<
        ImagesUploadedInterface[]
    >([]);
    const [selectedFile, setSelectedFile] = useState<any>([]);
    const [imageModalOpen, setImageModalOpen] = useState<any>(false);
    const [newImageDescription, setNewImageDescription] = useState<
        string | undefined
    >("");

    const onSelectFile = async (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        setSelectedFile([...selectedFile, e.target.files[0]]);
        const response = await uploadTempFile(
            e.target.files[0],
            [...selectedFile, e.target.files[0]].length as number
        );

        const newImage: ImagesInterface = {
            path: response,
            id: new Date().getTime(),
        };
        setImagesUploaded((prev) => [
            ...prev,
            {
                ...newImage,
                url: URL.createObjectURL(e.target.files[0]),
            },
        ]);

        const payloadImages: ImagesLoaded = normalizeImagesListInputData([
            ...imagesUploaded,
            newImage,
        ]);
        handleUploadFile && handleUploadFile(payloadImages);
    };

    const handlePreviewImages = () => {
        if (!images) return [];
        const imgs = Object.values(images).map((img) => ({
            ...img,
            current: true,
        }));
        return imgs?.length ? imgs : [];
    };

    const removeImage = (image: ImagesInterface) => {
        if (image.current) {
            setImagesUploaded((prev) => {
                const newImagesUploaded = [...prev];
                const index = newImagesUploaded.findIndex(
                    (img) => img.originalName === image.originalName
                );
                newImagesUploaded[index] = {
                    ...newImagesUploaded[index],
                    deleted: true,
                };
                return newImagesUploaded;
            });
        } else {
            const index = imagesUploaded.findIndex(
                (img) => img.id === image.id
            );
            URL.revokeObjectURL(imagesUploaded[index].url);
            setImagesUploaded((prev) => {
                return prev.filter((img) => img.id !== image.id);
            });
        }
    };

    const updateImageDescription = (image: ImagesInterface) => {
        setImageModalOpen(false);
        if (image.current) {
            setImagesUploaded((prev) => {
                const newImagesUploaded = [...prev];
                const index = newImagesUploaded.findIndex(
                    (img) => img.originalName === image.originalName
                );
                newImagesUploaded[index] = {
                    ...newImagesUploaded[index],
                    description: newImageDescription,
                };
                return newImagesUploaded;
            });
        } else {
            const index = imagesUploaded?.findIndex(
                (img) => img.id === image.id
            );
            let images = imagesUploaded;
            images[index] = {
                ...images[index],
                description: newImageDescription,
            };
            setImagesUploaded(() => {
                return [...images];
            });
        }
    };

    useEffect(() => {
        const payloadImages: ImagesLoaded = normalizeImagesListInputData([
            ...imagesUploaded,
        ]);
        handleUploadFile && handleUploadFile(payloadImages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imagesUploaded]);

    useEffect(() => {
        setImagesUploaded(handlePreviewImages());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(images)]);

    const imagesPreview = imagesUploaded?.filter((img) => !img.deleted);

    const handleDotsMenu = (key: string, image: any) => {
        switch (key) {
            case "DESCRIPTION":
                setImageModalOpen(image);
                setNewImageDescription(image?.description);
                break;

            default:
                break;
        }
    };

    return (
        <div className="col-span-6 sm:col-span-3">
            <Modal
                open={!!imageModalOpen}
                size={Modal_Size.md}
                onClose={() => {
                    setImageModalOpen(false);
                }}
            >
                <div className="flex flex-col items-center justify-center mx-6">
                    <img
                        className="rounded-xs w-[700px] h-[500px] object-cover"
                        src={imageModalOpen?.src || imageModalOpen?.url || imageModalOpen?.path || ""}
                        alt=""
                    />
                    <div className="mx-6 mt-6 flex flex-col w-full">
                        <TextAreaInput
                            label="Comments"
                            id="reply"
                            name="reply"
                            defaultValue={imageModalOpen?.description}
                            onChange={(e: any) =>
                                setNewImageDescription(e?.target?.value)
                            }
                        />
                        <div className="flex flex-row justify-end items-end">
                            <ButtonSquared
                                onClick={() => {
                                    setImageModalOpen(false);
                                }}
                                className="px-6 mr-4"
                                size={ButtonSize.FIT}
                                outlined
                                label="Cancel"
                            />
                            <ButtonSquared
                                className="px-6"
                                size={ButtonSize.FIT}
                                label="Save"
                                onClick={() => {
                                    updateImageDescription(imageModalOpen);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="font-semibold text-base text-neutral-900 mb-5">
                Photos
            </div>
            <div className="flex items-end gap-4 flex-wrap">
                <div className="max-h-[124px] mb-2">
                    <DropImageFileInput2
                        imgClass={
                            "h-[124px] w-[200px] rounded-sm overflow-hidden box-border"
                        }
                        className="!py-4 !px-4"
                        iconClass="!w-12 !h-12"
                        onChange={onSelectFile}
                    />
                </div>
                {imagesPreview.map((image, index) => (
                    <GalleryImage
                        key={`insight_image_${index}`}
                        src={image?.src || image?.url || image?.path}
                        description={image?.description}
                        imageClass="h-[82px] z-1011111 object-cover mb-2"
                        containerClass="flex-[0_0_auto]"
                        onImageClick={() => {}}
                        onImageRemove={() => {
                            removeImage(image);
                        }}
                        threeDots={[
                            {
                                icon: "PlusIcon",
                                label: "Add description",
                                key: "DESCRIPTION",
                            },
                            {
                                icon: "TrashIcon",
                                label: "Delete",
                                key: "DELETE",
                            },
                        ]}
                        handleDotsMenu={(k: any) => handleDotsMenu(k, image)}
                    />
                ))}
            </div>
        </div>
    );
};
