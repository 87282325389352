import Icon from "../../../../../../../../assets/svg/Icon";

export interface IContactItem {
    id: number;
    name: string;
    title?: string;
    email?: string;
    phone?: {
        phone: string;
    };
    mobile?: {
        mobile: string;
    };
}

export interface CommonProps {
    activeItem: IContactItem | null;
    handleEdit: (item: IContactItem) => void;
    handleShowDetails: (item: IContactItem) => void;
}

export interface ContactItemProps extends CommonProps {
    item: IContactItem;
    index: number;
}

export interface ListInsightContactsProps extends CommonProps {
    list: IContactItem[];
}

const ContactItem = ({
    item,
    index,
    activeItem,
    handleEdit,
    handleShowDetails,
}: ContactItemProps) => {
    const isActiveClass =
        activeItem?.id === item.id ? "border-primary_orange-500" : "";
    const contactItems = [
        {
            key: "email",
            icon: "EmailIcon",
            value: item.email || "-",
            link: `mailto:${item.email}`,
        },
        {
            key: "phone",
            icon: "PhoneIcon",
            value: item.phone?.phone || "-",
            link: `tel:${item.phone?.phone}`,
        },
        {
            key: "mobile",
            icon: "CellphoneIcon",
            value: item.mobile?.mobile || "-",
            link: `tel:${item.mobile?.mobile}`,
        },
    ];
    return (
        <div
            onClick={() => handleShowDetails(item)}
            className={`flex flex-col bg-neutral-100 gap-2 border p-4 sm:w-[296px] w-[97%] rounded-lg cursor-pointer ${isActiveClass}`}
            key={index}
        >
            <div className="flex items-center gap-2 justify-between">
                <div className="text-sm text-neutral-900 font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis">
                    {item.name}
                </div>
                <Icon
                    icon="PencilIconOutline"
                    className="w-[24px] cursor-pointer text-orange-600"
                    onClick={(ev) => {
                        ev.stopPropagation();
                        ev.preventDefault();
                        handleEdit(item);
                    }}
                />
            </div>
            <div className="text-sm text-neutral-500 whitespace-nowrap overflow-hidden overflow-ellipsis">
                {item.title}
            </div>
            {contactItems.map((contactItem, index) => (
                <div className="flex items-center gap-2 border-t-[1px] border-t-gray-200 pt-1">
                    <Icon icon={contactItem.icon} className="w-[16px]" />
                    <a
                        href={contactItem.value && contactItem.link}
                        className="text-sm text-pink-500 whitespace-nowrap overflow-hidden overflow-ellipsis"
                    >
                        {contactItem.value}
                    </a>
                </div>
            ))}
        </div>
    );
};

export const ListInsightContacts = ({
    list,
    ...rest
}: ListInsightContactsProps) => {
    if (!list?.length) return null;

    return (
        <div className="flex items-end gap-4 flex-wrap mostly-customized-scrollbar overflow-y-auto max-h-[460px] my-6">
            {list.map((item, index) => (
                <ContactItem
                    key={`list_insight_contact_${item.id}`}
                    item={item}
                    index={index}
                    {...rest}
                />
            ))}
        </div>
    );
};
