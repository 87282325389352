import React, { useEffect, useRef, RefObject } from "react";
import OtherUserMessageBubble from "./bubbles/OtherUserMessageBubble";
import CurrentUserMessageBubble from "./bubbles/CurrentUserMessageBubble";
import SystemMessageBubbles from "./bubbles/SystemMessageBubbles";
import ImageMessageBubble from "./bubbles/ImageMessageBubble"; // Import the new component
import { IChatMessage, IContact } from "../../../types/chat.type";
import { useChatForm } from "../hooks/useChat.hook";

interface Props {
    messages: IChatMessage[];
    currentUser: any;
    messagesEndRef: RefObject<HTMLDivElement>;
    isGroup: boolean;
    chatId: string;
    members: IContact[];
    handleShowParticipants: (value: boolean) => void;
}

const ChatMessageList: React.FC<Props> = ({
    messages,
    currentUser,
    messagesEndRef,
    isGroup,
    chatId,
    members,
    handleShowParticipants
}) => {
    const messageContainerRef = useRef<HTMLDivElement>(null);
    const { saveImageToTripGallery, onSelectedUser } = useChatForm();

    const deletedMembers = members.filter((member) => member.isDeleted);

    useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop =
                messageContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSaveImage = (messageId: string) => {
        // Logic to save image to trip gallery
        console.log(`Save image from message ID: ${messageId}`);
        saveImageToTripGallery(true, messageId, chatId, currentUser.uid);
    };

    const handleDontSaveImage = (messageId: string) => {
        // Logic to not save image to trip gallery
        console.log(`Don't save image from message ID: ${messageId}`);
        saveImageToTripGallery(false, messageId, chatId, currentUser.uid);
    };

    const handleMemberClick = (user: IContact) => {
        onSelectedUser(user);
        handleShowParticipants(false);
    }

    return (
        <div
            ref={messageContainerRef}
            className="flex-grow p-4 overflow-y-auto"
            style={{ maxHeight: "calc(100vh - 140px)", scrollbarWidth: "none" }}
        >
            <SystemMessageBubbles />

            {messages.length > 0 ? (
                messages.map((msg: IChatMessage) => (
                    <div
                        key={msg.id}
                        className={`message ${
                            currentUser?.uid === msg.sender?.uid
                                ? "sent"
                                : "received"
                        }`}
                    >
                        {msg.attachments?.length ? (
                            <ImageMessageBubble
                                currentUser={currentUser?.uid === msg.sender?.uid}
                                onSave={() => handleSaveImage(msg.id)}
                                onDontSave={() => handleDontSaveImage(msg.id)}
                                isGroup={isGroup}
                                message={msg}
                                onMemberClick={handleMemberClick}
                            />
                        ) : currentUser?.uid === msg.sender?.uid && msg.content !== "New group created"  ? (
                            <CurrentUserMessageBubble message={msg} />
                        ) : msg.content === "New group created" ? (
                                // <SystemMessageBubbles />
                                <></>
                        ) : (
                            <OtherUserMessageBubble
                                message={msg}
                                isGroup={isGroup}
                                onMemberClick={handleMemberClick}
                                membersDeleted={deletedMembers.map((member) => member.uid)}
                            />
                        )}
                    </div>
                ))
            ) : (
                <p>No messages yet.</p>
            )}
            <div ref={messagesEndRef} />
        </div>
    );
};

export default ChatMessageList;
