import { useState, ChangeEvent, useEffect, useRef } from "react";
import Icon from "../../../assets/svg/Icon";
import { ChatForm } from "../type/chatFormType";
import FileInputButton from "./FileInputButton";
import FilePreview from "./FilePreview";
import { useChatForm } from "../hooks/useChat.hook";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { chatMessageSchema } from "../schema/schema";

const ChatInput = () => {
    const { selectedFiles, handleFileSelection, removeSelectedFile, onSendMessage } = useChatForm();
    const [showDropdown, setShowDropdown] = useState(false);
    const [messageContent, setMessageContent] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const {
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<ChatForm>({
        resolver: yupResolver(chatMessageSchema),
        defaultValues: {
            content: "",
            files: [],
        }
    });
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSendMessage = async (data: ChatForm) => {
        try {
            setIsLoading(true);
            await onSendMessage(data);
            setMessageContent("");
            handleFileSelection([]);
            setValue("content", "");
            setValue("files", []);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            handleFileSelection(Array.from(e.target.files));
            setShowDropdown(false);
            setValue("files", Array.from(e.target.files));
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <form
            autoComplete="off"
            action="#"
            method="POST"
            onSubmit={handleSubmit((data) => handleSendMessage({ ...data, content: messageContent, files: selectedFiles }))}
            className="flex flex-col w-full mt-auto"
        >
            <div className="px-4">
                {selectedFiles.length > 0 && (
                    <div className="mt-2 ml-9">
                        <FilePreview files={selectedFiles} removeFile={removeSelectedFile} />
                    </div>
                )}
                <div className="flex items-center relative">
                    <div ref={dropdownRef}>
                        <FileInputButton
                            showDropdown={showDropdown}
                            toggleDropdown={toggleDropdown}
                            onFileInputClick={() => document.getElementById('fileInput')?.click()}
                        />
                    </div>

                    <input
                        type="file"
                        id="fileInput"
                        multiple
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />

                    <div className="flex-grow p-2 pl-3 border border-gray-300 rounded-full pr-10 relative flex items-center overflow-hidden">
                        <input
                            type="text"
                            value={messageContent}
                            onChange={(e) => {
                                setMessageContent(e.target.value);
                                setValue("content", e.target.value);
                            }}
                            className="flex-grow border-none focus:outline-none"
                            placeholder="iMessage"
                        />
                        <button
                            type="submit"
                            className="absolute right-0 top-0 mt-2 mr-2"
                            disabled={isLoading}
                        >
                            <Icon
                                icon="SendArrowIcon"
                                className="w-6 h-6 m-0 fill-gray-400 text-gray-400"
                            />
                        </button>
                    </div>
                </div>
                
            </div>
        </form>
    );
};

export default ChatInput;
