import List from "../../../../components/List";
import FileItem from "./FileItem";
interface IFilesList {
    handleDelete: Function;
    handleNext: any;
    files: any;
    canEdit: boolean;
    handleClick: Function;
}
export default function TripFilesList({
    handleDelete,
    handleNext,
    files,
    canEdit,
    handleClick,
}: IFilesList) {
    const { data } = files || {};

    const Item = ({item}: any) => (
        <div className="my-4">
            <FileItem
                key={`tripFileListItem_${item?.id}`}
                canEdit={canEdit}
                file={item}
                handleClick={handleClick}
                handleRemove={handleDelete}
            />
        </div>
    );
    return (
        data && (
            <List
                itemKey={(file: any) => { return `fileListItem_${file?.id}`}}
                items={files}
                handleNext={handleNext}
                renderItem={Item}
            />
        )
    );
}
