import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import InputPlaceAutocomplete from "../../components/InputPlaceAutocomplete";
import Input from "../../components/Input";
import Button from "../../components/ButtonSquare";
import GoogleMap from "../../components/Map";
import { useAuthStateContext } from "../../context/Auth";
import { useAppDispatch } from "../../app/hooks";
import TextareaWithValid from "../../components/TextArea";
import { createLocalPartner } from "./localPartnerSlice";
import SelectInput from "../../components/InputSelect";
import { localPartnerTypeOptions } from "../../constants";

const schema = yup
    .object({
        name: yup.string().required("This field is required").min(2),
        description: yup.string().max(500).required("This field is required").min(2),
        type: yup.string().required("This field is required"),
        website: yup.string(),
        address: yup
            .object({
                street_number: yup.string(),
                route: yup.string(),
                postal_code: yup.string().nullable(),
                postal_code_suffix: yup.string().nullable(),
                locality: yup.string().required("This field is required"),
                administrative_area_level_1: yup.string().required("This field is required"),
                country: yup.string().required("This field is required"),
                fullAddress: yup.string().required("This field is required"),
                lat: yup.number().required("This field is required"),
                lng: yup.number().required("This field is required"),
            })
            .required("This field is required"),
    })
    .required("This field is required");

export default function LocalPartnerForm() {
    const [mapMarker, setmapMarker] = useState<any>(null);
    const [isUpdate, setIsUpdate] = useState(false);

    const dispatch = useAppDispatch();
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const handleAddressChange = (address: any) => {
        if (address?.lat) {
            const marker = {
                position: {
                    lat: address.lat,
                    lng: address.lng,
                },
            };
            setmapMarker(marker);
        }
    };

    const handleSubmitForm = async (data: any) => {
        const payload = {
            name: data.name,
            description: data.description,
            type: data.type,
            fullAddress: data.address.fullAddress,
            streetAddress: data.address.route,
            streetNumber: data.address.street_number,
            city: data.address.locality,
            state: data.address.administrative_area_level_1,
            country: data.address.country,
            zipCode: data.address.postal_code,
            lat: data.address.lat,
            lng: data.address.lng,
            website: data.website,
        };
        await dispatch(createLocalPartner( payload));
    };

    return (
        <>
            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(handleSubmitForm)}
                >
                    <div className="lg:grid lg:grid-cols-1 lg:gap-6">
                        <div className="col-span-6 sm:col-span-6 mt-3">
                            <Input
                                register={register}
                                // defaultValue={selectedHotel?.name}
                                label="Name"
                                id="name"
                                name="name"
                                type="text"
                                error={errors["name"]?.message}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-6 mt-3">
                            <TextareaWithValid
                                register={register}
                                //  defaultValue={selectedHotel?.name}
                                label="Description"
                                id="description"
                                name="description"
                                error={errors["description"]?.message}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-6 mt-3">
                            <SelectInput
                                control={control}
                                options={localPartnerTypeOptions}
                                //  defaultValue={selectedHotel?.name}
                                label="type"
                                id="type"
                                name="type"
                                error={errors["type"]?.message}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-6 mt-3">
                            <InputPlaceAutocomplete
                                control={control}
                                onChange={handleAddressChange}
                                label="Address"
                                id="address-autocomplete"
                                name="address"
                                // placeholder="Search here"
                                error={errors?.address}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-6 mt-3">
                            {mapMarker && <GoogleMap marker={mapMarker} />}
                        </div>
                        <div className="col-span-6 sm:col-span-6 mt-3">
                            <Input
                                register={register}
                                //defaultValue={selectedHotel?.website}
                                label="Website"
                                id="website"
                                name="website"
                                type="url"
                                error={errors["website"]?.message}
                            />
                        </div>
                        <div className="col-span-6 px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <Button
                                type="submit"
                                label={isUpdate ? "Update" : "Create"}
                            />
                        </div>
                    </div>
                </form>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>
        </>
    );
}
