export const normalizeProfile = (profile: any) => {
    return {
        ...profile,
        destinationWant: Array.isArray(profile?.destinationWant)
            ? profile?.destinationWant.join(",")
            : "",
        destinationSell: Array.isArray(profile?.destinationSell)
            ? profile?.destinationSell.join(",")
            : "",
        specialization: Array.isArray(profile?.specialization)
            ? profile?.specialization.join(",")
            : "",
    };
};

export const deNormalizeProfile = (profile: any) => {
    let defaultDate = profile?.birthDate
        ? new Date(profile.birthDate).toISOString().substring(0, 10)
        : null;

    return {
        ...profile,
        birthDate: defaultDate,
        destinationWant:
            profile?.destinationWant && profile?.destinationWant.split
                ? profile?.destinationWant.split(",")
                : [],
        destinationSell:
            profile?.destinationSell && profile?.destinationSell.split
                ? profile?.destinationSell.split(",")
                : [],
        specialization:
            profile?.specialization && profile?.specialization.split
                ? profile?.specialization.split(",")
                : [],
    };
};

export const deNormalizeSettings = (settings: any) => {
    return {
        ...(settings || {}),
        notificationRequiredDocuments:
            settings?.notificationRequiredDocuments !== undefined
                ? settings?.notificationRequiredDocuments
                : { push: true, email: true },
        notificationItinerary:
            settings?.notificationItinerary !== undefined
                ? settings?.notificationItinerary
                : { push: false, email: false },
        notificationInsights:
            settings?.notificationInsights !== undefined
                ? settings?.notificationInsights
                : { push: false, email: false },
        notificationReviews:
            settings?.notificationReviews !== undefined
                ? settings?.notificationReviews
                : { push: false, email: false },
        notificationTripImages:
            settings?.notificationTripImages !== undefined
                ? settings?.notificationTripImages
                : { push: false, email: false },
        notificationTripFiles:
            settings?.notificationTripFiles !== undefined
                ? settings?.notificationTripFiles
                : { push: true, email: false },
        notificationTripPublicReview:
            settings?.notificationTripPublicReview !== undefined
                ? settings?.notificationTripPublicReview
                : { push: true, email: false },
    };
};
