import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch } from "../../../../../../../../app/hooks";
import { PAGE_SECTIONS } from "../../../../../../../../constants";

import {
    addHotelRoomInsight,
    removeHotelRoomInsight,
} from "../../../../../../../hotelInsight/hotelInsightSlice";
import {
    ROOM_VIEW_TYPE,
} from "../../../../../../../../constants/insight";
import { useSelector } from "react-redux";
import { selectActivityInsight } from "../../../../../../tripSlice";
import { FormRoomItem } from "./FormRoomItem";
import { ListInsightItems } from "../../components/ListInsightItems";
import InsightGenericView from "../../components/InsightGenericView";
import RoomInsightDetails from "./RoomInsightDetails";
import InsightFormContainer from "../../components/InsightFormContainer";
import InsightInputContainer from "../../components/InsightInputContainer";
import Input from "../../../../../../../../components/Input";
import InputSwitch from "../../../../../../../../components/InputSwitch/InputSwitch";
import HotelItemDetail from "../../components/HotelItemDetail/HotelItemDetail";

const schema = yup
    .object({
        comments: yup.string().max(5000).nullable().notRequired(),
        name: yup.string().max(500).nullable().notRequired(),
        category: yup.string().max(500).required("This field is required"),
        view: yup.string().max(500).nullable().notRequired(),
        viewOther: yup.string().max(500).nullable().notRequired(),
    })
    .required("This field is required");

export default function RoomInsightReview() {
    const dispatch = useAppDispatch();
    const { activityId, tripId } = useParams();
    const activityInsight = useSelector(selectActivityInsight);
    const hotelInsight = activityInsight?.hotelInsight;
    const listRoomInsight = hotelInsight?.roomInsights || [];

    const handleSubmitForm = async (data: any) => {
        const payload = {
            ...data,
            view: data.view || ROOM_VIEW_TYPE.OTHER,
            connecting:
                !data.connecting || data.connecting === "false" ? false : true,
            viewOther: data.view === ROOM_VIEW_TYPE.OTHER ? data.viewOther : "",
        };

        await dispatch(
            addHotelRoomInsight(
                Number(tripId),
                Number(activityId),
                activityInsight?.id,
                payload
            )
        );
    };

    const handleRemoveInsight = (itemId: number) => {
        dispatch(
            removeHotelRoomInsight(
                Number(tripId),
                Number(activityId),
                activityInsight?.id,
                itemId
            )
        );
    };

    const renderRoomInputs = ({
        register,
        errors,
        setValue,
        trigger,
        showComentsForm,
    }: any) =>
        showComentsForm ? (
            <InsightFormContainer className="gap-y-2 sm:gap-8">
                <InsightInputContainer>
                    <Input
                        register={register}
                        label="Property size (Number of rooms)"
                        type="text"
                        name="roomsCount"
                        defaultValue={hotelInsight?.roomsCount || null}
                        error={errors["roomsCount"]?.message}
                        numeric
                    />
                </InsightInputContainer>
                <InsightInputContainer>
                    <div className="flex flex-row gap-4 items-center">
                        <div className="text-neutral-600 font-medium text-sm w-fit mb-1">
                            Connecting rooms
                        </div>
                        <InputSwitch
                            name="connectingRooms"
                            defaultChecked={hotelInsight?.connectingRooms}
                            error={errors["connectingRooms"]?.message}
                            onClick={(e: any) => {
                                setValue("connectingRooms", e.target.checked, {
                                    shouldDirty: true,
                                });
                                trigger && trigger("connecting");
                            }}
                        />
                    </div>
                </InsightInputContainer>
            </InsightFormContainer>
        ) : (
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-0">
                {!!hotelInsight?.roomsCount && (
                    <HotelItemDetail
                        className="w-full lg:w-2/5"
                        label="Property Size (Number of rooms)"
                        value={`${hotelInsight?.roomsCount} rooms` || ""}
                    />
                )}
                <div className="flex flex-row gap-4 items-start">
                    <div className="text-neutral-600 font-medium sm:text-base text-sm w-fit mb-1">
                        Connecting rooms
                    </div>
                    <InputSwitch
                        name="connectingRooms"
                        value={hotelInsight?.connectingRooms ? "true" : "false"}
                        defaultChecked={hotelInsight?.connectingRooms}
                        disabled
                    />
                </div>
            </div>
        );

    return (
        <InsightGenericView
            currentSection={
                PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_ROOMS
            }
            label="Room"
            items={listRoomInsight}
            commentsKey="roomComments"
            imagesKey="roomImages"
            itemDetailsView={RoomInsightDetails}
            onRemove={handleRemoveInsight}
            listInsightItems={(props) => (
                <ListInsightItems
                    {...props}
                    titleKey="category"
                    subtitleKey="name"
                    icon="RoomIcon"
                />
            )}
            customComponent={renderRoomInputs}
            formInsightItem={FormRoomItem}
            onSubmit={handleSubmitForm}
            schema={schema}
            backButtonLabel="Back to Rooms"
        />
    );
}
