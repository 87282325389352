import React from "react";
import { IChatMessage, IContact } from "../../../../types/chat.type";
import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentUser } from "../../chatSlice";
import { useChatForm } from "../../hooks/useChat.hook";
import Avatar from "../../../../components/Avatar";

const OtherUserMessageBubble: React.FC<{
    message: IChatMessage;
    isGroup: boolean;
    onMemberClick?: (memberId: IContact) => void;
    membersDeleted: string[];
}> = ({ message, isGroup, onMemberClick, membersDeleted }) => {
    const currentUser = useAppSelector(selectCurrentUser);
    const isCurrentUser = message.sender?.uid === currentUser?.uid;
    const { getFormattedDate } = useChatForm();
    const formattedDate = getFormattedDate(message.timestamp);

    if (isCurrentUser || !message.content) return null;

    const handleUserClick = () => {
        onMemberClick && onMemberClick(message?.sender);
    }
    const isDeleted = membersDeleted.includes(message.sender?.uid);

    return (
        <div className="flex mb-4 items-center">
            {isGroup && (
                <span onClick={handleUserClick}><Avatar src={message.sender?.avatar} className="mr-2 w-7 h-7 cursor-pointer" /></span>
            )}
            <div>
                {isGroup && (
                    <div className="mb-0 text-start">
                        <span className="text-xs text-gray-400 cursor-pointer" onClick={handleUserClick}>
                            {message.sender?.firstName} {message.sender?.lastName}
                        </span>
                        {isDeleted && (
                            <span className="text-xs text-red-500 ml-1">
                                (Deleted)
                            </span>
                        )}
                    </div>
                )}
                <div className="relative bg-[#f4f3f3] p-3 pb-1 rounded-tr-xl rounded-tl-xl rounded-br-xl shadow-sm max-w-lg">
                    <div className="flex items-end">
                        <p className="mr-2 font-medium text-start">
                            {message.content}
                        </p>
                        <div className="flex justify-end">
                            <span className="text-xs text-gray-400 min-w-max">
                                {formattedDate}
                            </span>
                        </div>
                    </div>

                    <div
                        className="absolute bottom-[-6px] left-[-1.5px] bg-[#f4f3f3] w-2 h-2 rounded-br-lg rounded-tl-sm"
                        style={{
                            clipPath:
                                "polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%, 15% 60%, 0% 0%)",
                            transform: "rotate(20deg)",
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default OtherUserMessageBubble;
