import { useEffect, useState } from "react";
import Modal, { Modal_Size } from ".";
import { ButtonSize, OrganizationType } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import { useDispatch, useSelector } from "react-redux";
import { addSubscriptionToUser } from "../../features/admin/adminSlice";
import Loader from "../Loader";
import { getOrganizations, selectOrganizations } from "../../features/organizations/organizationSlice";
import { OrganizationItem } from "../OrganizationItem/OrganizationItem";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { IOrganization } from "../../types/IOrganization";

export interface ChooseOrganizationModalProps {
    open?: boolean;
    handleClose: () => void;
    onChangeOrganization?: (organizationId: number) => void
    userType: string;
    tripId?: number;
    organization?: IOrganization;
}

export enum VariantOrganization {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
}

export const ChooseOrganizationModal: React.FunctionComponent<ChooseOrganizationModalProps> = (
    props
) => {
    const { open, handleClose, onChangeOrganization, organization} =
        props;
    const navigate = useNavigate();

    const [activeOrganization, setActiveOrganization] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>();
    const [_organization, _setOrganization] = useState<any>({});

    const dispatch = useDispatch();

    const organizations = useAppSelector(selectOrganizations);
    const fetchOrganizations = async () => {
        setIsLoading(true);
        await dispatch(
            getOrganizations(true)
        );
        setIsLoading(false);
    };
    useEffect(() => {
        fetchOrganizations();
        if (organization) {
            _setOrganization(organization)
            setActiveOrganization(organization.id)
        }
    }, []);

    const handleNewOrganization = () => {
        navigate("/organizations/new");
    }

    const handleChangeOrganization = () => {
        onChangeOrganization && onChangeOrganization(_organization?.id);
    };

    return (
        <Modal open={open} hideCloseButton size={Modal_Size.medium}>
            <div className="p-6">
                <div className="text-xl text-center font-semibold mb-6">
                    Assign Trip
                </div>
                <div className=" text-neutral-400 font-medium text-base text-center mb-6">
                    Choose the organization this trip belongs to.
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    {!!isLoading && (
                        <div className="flex flex-row items-center justify-center w-full">
                            <Loader />
                        </div>
                    )}
                    {!isLoading &&
                        organizations?.map((organization: any) => {
                            return (
                                <OrganizationItem
                                    key={organization?.id}
                                    organization={organization}
                                    checked={activeOrganization === organization.id}
                                    handleClick={() => {
                                        _setOrganization(organization);
                                        setActiveOrganization(organization.id);
                                    }}
                                />
                            );
                        })}
                </div>
                <div className="text-start mt-4" onClick={handleNewOrganization}>
                    <span className="text-sm cursor-pointer font-semibold mb-6 whitespace-nowrap text-orange-500">
                            Create new organization
                    </span>
                </div>
                <div className="flex flex-row gap-4 mt-4 w-full justify-end text-right">
                    <ButtonSquared
                        outlined
                        label="Cancel"
                        onClick={handleClose}
                        className="!p-3"
                        size={ButtonSize.FIT}
                    />
                    <ButtonSquared
                        disabled={!activeOrganization && activeOrganization !== 0}
                        label="Confirm"
                        className="!p-3"
                        onClick={handleChangeOrganization}
                        size={ButtonSize.SMALL}
                    />
                </div>
            </div>
        </Modal>
    );
};
