import { ReactNode } from "react";

const InsightFormContainer = ({ children, className }: { children?: ReactNode, className?: string }) => {
    return (
        <div className={`grid grid-cols-6 xl:gap-x-32 gap-x-6 gap-y-12 ${className}`}>
            {children}
        </div>
    );
};

export default InsightFormContainer;
