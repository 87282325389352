import { useMemo } from "react";
import Avatar from "../../../components/Avatar";
import moment from "moment";
import {
    FacebookUrlRegex,
    InstagramUrlRegex,
    LinkedinUrlRegex,
    OrganizationTypeOptions,
    UserStatusOnOrganization,
    userRoleOrganizationFilterOptions,
} from "../../../constants";
import { DATE_FORMAT } from "../../../helpers";
import { useSelector } from "react-redux";
import { RequestChangeRole } from "./RequestChangeRole";
import { RequestChangeEmail } from "./RequestChangeEmail";
import defaultImage from "../../../assets/images/placeholder.png";
import { useConstantsContext } from "../../../context/Constants";
import { useNavigate } from "react-router";

export default function ProfileDetails(props: any) {
    const { profile, hideAvatar, hidePersonalInfo } = props;
    const userProfile = useSelector((state: any) => state?.user?.profile);
    const { constants } = useConstantsContext();
    const navigate = useNavigate();
    const socialInfo = useMemo(() => {
        return {
            instagram: profile?.instagram && {
                value: profile.instagram,
                url: InstagramUrlRegex.test(profile.instagram)
                    ? profile?.instagram
                    : `https://www.instagram.com/${profile.instagram}`,
            },
            facebook: profile?.facebook && {
                value: profile.facebook,
                url: FacebookUrlRegex.test(profile.facebook)
                    ? profile?.facebook
                    : `https://www.facebook.com/${profile.facebook}`,
            },
            linkedin: profile?.linkedin && {
                value: profile.linkedin,
                url: LinkedinUrlRegex.test(profile.linkedin)
                    ? profile?.linkedin
                    : `https://www.linkedin.com/in/${profile.linkedin}`,
            },
        };
    }, [profile?.instagram, profile?.facebook, profile?.linkedin]);

    const orgsInfo = useMemo(() => {
        return (
            constants?.featureFlags?.ORGANIZATIONS &&
            profile?.organizations
                ?.map((o: any) => {
                    return {
                        ...(o || {}),
                        organization: {
                            ...(o?.organization || {}),
                            type: OrganizationTypeOptions.find(
                                (org) => org.value === o?.organization?.type
                            )?.label,
                        },
                        role: userRoleOrganizationFilterOptions.find(
                            (org) => org.value === o?.role
                        )?.label,
                    };
                })
                .filter(
                    (o: any) => o.status === UserStatusOnOrganization.CONFIRMED
                )
        );
    }, [constants?.featureFlags?.ORGANIZATIONS, profile?.organizations]);

    const isMe = useMemo(() => {
        return userProfile?.email === profile?.email;
    }, [profile?.email, userProfile?.email]);

    return (
        <>
            <div className="flex flex-col">
                <div className=" flex flex-row gap-x-4 flex-wrap text-left">
                    {!hideAvatar && (
                        <div className="flex mb-6">
                            <label htmlFor="file-upload" className="text-left">
                                <Avatar
                                    src={profile?.avatar}
                                    className="w-24 h-24"
                                />
                            </label>
                        </div>
                    )}
                    <div className="flex flex-col">
                        <div className="text-xl font-semibold">
                            {profile?.firstName || "-"}{" "}
                            {profile?.lastName || "-"}
                        </div>
                        <div className="text-base text-neutral-800">
                            {profile?.agency || ""}
                        </div>
                        {isMe && !hidePersonalInfo && (
                            <RequestChangeRole
                                userType={userProfile?.userType}
                            />
                        )}
                    </div>
                </div>

                <div className="flex flex-row mt-4 md:grid md:grid-cols-3 gap-6 lg:grid-cols-6 flex-wrap justify-between">
                    <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                        <span className="block font-medium text-neutral-600 text-xs">
                            First name
                        </span>
                        <span className="text-base block mt-1 mb-2">
                            {profile?.firstName || "-"}
                        </span>
                    </div>
                    <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Last name
                        </span>
                        <span className="text-base block mt-1 mb-2">
                            {profile?.lastName || "-"}
                        </span>
                    </div>
                    {!hidePersonalInfo && (
                        <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Birth Date
                            </span>
                            <span className="text-base block mt-1 mb-2">
                                {profile.birthDate
                                    ? moment(profile.birthDate).format(
                                          DATE_FORMAT
                                      )
                                    : "-"}
                            </span>
                        </div>
                    )}
                    <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                        <span className="block font-medium text-neutral-600 text-xs">
                            My Location
                        </span>
                        <span className="text-base block mt-1 mb-2">
                            {profile.fullAddress ? profile.fullAddress : "-"}
                        </span>
                    </div>
                    <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Mobile Phone Number
                        </span>
                        <span className="text-base block mt-1 mb-2">
                            {profile?.phone || "-"}
                        </span>
                    </div>
                    <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Email address
                        </span>
                        <span className="flex flex-row flex-wrap text-base items-baseline mt-1 mb-2 gap-x-2">
                            <span>{profile?.email || "-"}</span>
                            {userProfile?.email === profile?.email &&
                                !hidePersonalInfo && (
                                    <RequestChangeEmail
                                        email={profile?.email}
                                    />
                                )}
                        </span>
                    </div>
                    <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Company Name
                        </span>
                        <span className="text-base block mt-1 mb-2">
                            {profile?.agency || "-"}
                        </span>
                    </div>
                    <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                        <span className="block font-medium text-neutral-600 text-xs">
                            Instagram
                        </span>
                        {socialInfo?.instagram ? (
                            <a
                                className="text-base block mt-1 mb-2"
                                target="_blank"
                                rel="noreferrer"
                                href={socialInfo.instagram.url}
                            >
                                @{socialInfo?.instagram.value}
                            </a>
                        ) : (
                            <span className="text-base block mt-1 mb-2">
                                {"-"}
                            </span>
                        )}
                    </div>
                    {socialInfo?.linkedin && (
                        <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                            <span className="block font-medium text-neutral-600 text-xs">
                                LinkedIn
                            </span>

                            <a
                                className="text-base block mt-1 mb-2"
                                target="_blank"
                                rel="noreferrer"
                                href={socialInfo?.linkedin?.url}
                            >
                                {socialInfo?.linkedin?.value || "-"}
                            </a>
                        </div>
                    )}
                    {socialInfo?.facebook && (
                        <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-6">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Facebook
                            </span>

                            <a
                                className="text-base block mt-1 mb-2"
                                target="_blank"
                                rel="noreferrer"
                                href={socialInfo?.facebook?.url}
                            >
                                {socialInfo?.facebook?.value}
                            </a>
                        </div>
                    )}
                </div>
                {!!orgsInfo?.length && (
                    <>
                        <span className="text-left font-semibold text-neutral-700 text-md my-4">
                            Organizations
                        </span>
                        <div className="flex-1 flex flex-row w-full flex-wrap gap-4">
                            {orgsInfo?.map((org: any) => {
                                return (
                                    <div
                                        onClick={
                                            isMe
                                                ? () => {
                                                      navigate(
                                                          `/organizations/${org?.organizationId}`
                                                      );
                                                  }
                                                : undefined
                                        }
                                        className={`flex flex-row gap-2 items-center border border-1 border-neutral-100 rounded-lg p-3 ${
                                            isMe
                                                ? "cursor-pointer hover:bg-neutral-50"
                                                : ""
                                        }`}
                                        key={org?.organization?.id}
                                    >
                                        <img
                                            className="w-[50px] h-[50px] object-cover rounded-sm"
                                            src={
                                                org?.organization
                                                    ?.image_thumb ||
                                                org?.organization?.image ||
                                                defaultImage
                                            }
                                            alt=""
                                        />

                                        <div className="flex flex-col flex-1 w-[225px]">
                                            <div className="flex flex-row items-center gap-x-4">
                                                <div className="text-xs font-semibold text-neutral-900 text-left w-[175px] truncate">
                                                    {org?.organization?.name}
                                                </div>
                                                <div className="flex-1 text-right text-[10px] font-semibold text-neutral-800">
                                                    {org?.role}
                                                </div>
                                            </div>

                                            <div className="text-left text-xs font-semibold text-neutral-800 w-[175px] truncate">
                                                {org?.organization?.type}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}

                {!hidePersonalInfo && (
                    <div className="flex flex-row mt-4 md:grid md:grid-cols-3 gap-6 lg:grid-cols-6 flex-wrap justify-between">
                        <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-6">
                            <span className="block font-semibold text-neutral-700 text-md">
                                Emergency contact information
                            </span>
                        </div>
                        <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Emergency contact name
                            </span>
                            <span className="text-base block mt-1 mb-2">
                                {profile?.emergencyContactName || "-"}
                            </span>
                        </div>
                        <div className="text-left min-w-[50%] lg:max-w-xs break-words col-span-3">
                            <span className="block font-medium text-neutral-600 text-xs">
                                Emergency contact phone
                            </span>
                            <span className="text-base block mt-1 mb-2">
                                {profile?.emergencyContactPhone || "-"}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
