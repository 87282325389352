import { Route } from "react-router-dom";
import ComplaintsAdminHome from "../features/admin/complaints";
import HotelsAdminHome from "../features/admin/elements/hotels";
import OthersAdminHome from "../features/admin/elements/others";
import RestaurantsAdminHome from "../features/admin/elements/restaurants";
import InsightsAdminHome from "../features/admin/insights";
import OverviewAdminHome from "../features/admin/overview";
import ReviewsAdminHome from "../features/admin/reviews";
import TripsAdminHome from "../features/admin/trips";
import UsersAdminHome from "../features/admin/users";
import RequiresAdmin from "../hooks/RequiresAdmin";
import RequireAuth from "../hooks/RequiresAuth";
import PlansAdmin from "../features/admin/plans";
import IncomingTripsAdminHome from "../features/admin/trips/IncomingTrips";
import SupportModulesAdmin from "../features/admin/supportModules";

export const AdminRoutes = (
    <Route path="/admin">
        <Route
            path="trips"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <TripsAdminHome />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="incoming-trips"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <IncomingTripsAdminHome />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="overview"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <OverviewAdminHome />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="users"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <UsersAdminHome />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="insights"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <InsightsAdminHome />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="reviews"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <ReviewsAdminHome />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="hotels"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <HotelsAdminHome />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="support-modules"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <SupportModulesAdmin />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="restaurants"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <RestaurantsAdminHome />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="others"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <OthersAdminHome />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="complaints"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <ComplaintsAdminHome />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
        <Route
            path="plans"
            element={
                <RequireAuth protectedRoute={true}>
                    <RequiresAdmin>
                        <PlansAdmin />
                    </RequiresAdmin>
                </RequireAuth>
            }
        />
    </Route>
);
