import { useMemo } from "react";
import NotificationItem from "./NotificationItem";
const NotificationsLoading = () => {
    const renderLoading = useMemo(() => {
        return (
            <>
                {[...Array(5)].map((a: any, index: number) => {
                    return (
                        <NotificationItem
                            key={`notificationsItem_${index}`}
                            isLoading={true}
                            title={""}
                            description={""}
                            read={true}
                            date={""}
                        />
                    );
                })}
            </>
        );
    }, []);

    return renderLoading;
};

export default NotificationsLoading;
