import React, { useState } from "react";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import ReportModal from "./modals/ReportModal";
import { IContact } from "../../../types/chat.type";

const ActionPopup: React.FC<{
    onBlock: () => void;
    onAccept: () => void;
    onReport: ( reason: string) => void;
    otherMember: IContact;
}> = ({ onBlock, onReport, onAccept, otherMember }) => {
    const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);

    // Report
    const handleReportClick = () => {
        setIsReportModalOpen(true);
    };
    const handleReportConfirm = (reason: string) => {
        onReport(reason);
        setIsReportModalOpen(false);
    };
    const handleReportCancel = () => {
        setIsReportModalOpen(false);
    };

    // Block
    const handleBlockClick = () => {
        setIsBlockModalOpen(true);
    };
    const handleBlockConfirm = () => {
        onBlock();
        setIsBlockModalOpen(false);
    };
    const handleBlockCancel = () => {
        setIsBlockModalOpen(false);
    };

    // Accept
    const handleAcceptUser = () => {
        onAccept();
    };

    return (
        <div className="p-4 bg-gray-50  text-center mt-4">
            <p>
                {" "}
                {otherMember.firstName} {otherMember.lastName} sent you a
                message. Would you like to chat?
            </p>
            <div className="mt-4 flex justify-center space-x-4">
                <button
                    onClick={handleBlockClick}
                    className="text-orange-500 hover:text-orange-600 text-xs cursor-pointer font-semibold bg-white rounded-full px-4 py-2"
                >
                    Block
                </button>
                <button
                    onClick={handleReportClick}
                    className="text-orange-500 hover:text-orange-600 text-xs cursor-pointer font-semibold bg-white rounded-full px-4 py-2"
                >
                    Report
                </button>
            </div>
            <div className="mt-4 flex justify-center">
                <button
                    onClick={handleAcceptUser}
                    className="text-orange-500 hover:text-orange-600 text-xs cursor-pointer font-semibold rounded-full px-6 py-2"
                >
                    Accept
                </button>
            </div>
            <ModalConfirmation
                open={isBlockModalOpen}
                title={`Are you sure you want to block ${otherMember?.firstName} ${otherMember?.lastName}?`}
                description={`Blocking ${otherMember?.firstName} ${otherMember?.lastName} will prevent them from messaging you.`}
                okButtonText="Confirm"
                cancelButtonText="Cancel"
                handleCancel={handleBlockCancel}
                handleSubmit={handleBlockConfirm}
                className="min-h-[250px]"
            />
            <ReportModal
                open={isReportModalOpen}
                handleCancel={handleReportCancel}
                handleSubmit={handleReportConfirm}
            />
        </div>
    );
};

export default ActionPopup;
