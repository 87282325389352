import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../app/hooks";
import Button from "../../../../components/ButtonSquare";
import { PAGE_SECTIONS } from "../../../../constants";
import { useAuthStateContext } from "../../../../context/Auth";
import { setCurrentSection } from "../../../layout/layoutSlice";
import { selectTripLocalPartners } from "../../tripSlice";

export default function LocalPartnersOnTripList(props: any) {
  const localPartners = useSelector(selectTripLocalPartners);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token } = useAuthStateContext();

  useEffect(() => {
    dispatch(
      setCurrentSection({ currentSection: PAGE_SECTIONS.TRIP_LOCAL_PARTNERS })
    );
  }, []);

  const handleNewClick = (e: any) => {
    navigate("new");
  };

  const handleLPClick = (lpId: number) => {
    navigate(`${lpId}`);
  };

  return (
    <div className="min-w-full flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="min-w-full flex justify-end py-2 px-2">
            <Button label="New Local Partner" onClick={handleNewClick} />
          </div>

          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Start Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    End Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {localPartners &&
                  localPartners.map((localPartner: any) => (
                    <tr
                      key={localPartner.id}
                      className="cursor-pointer"
                      onClick={(e) => {
                        handleLPClick(localPartner?.id);
                      }}
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {localPartner?.localPartner.name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {localPartner?.localPartner.description}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {localPartner?.startDate}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {localPartner?.endDate}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {localPartner?.localPartner.type}
                        </span>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
