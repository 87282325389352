import React, { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import Icon from "../../assets/svg/Icon";
import Breadcrumbs from "../../components/Breadcrumbs";
import { SupportIcon } from "../../components/SupportIcon";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentSection } from "./layoutSlice";
import server from "../../api/server";
import ModuleSupport from "../../components/ModuleSupport";
import {
    AdminEndpoints,
    PAGE_SECTIONS,
    SUPPORT_MODULE_SECTIONS_OPTIONS,
    SupportPlatform,
} from "../../constants";
interface IMain {
    title: string;
    description: string;
    icon: any;
    withSidebar: boolean;
    withHeaderBar: boolean;
    grayBackground: boolean;
    minHeight?: string;
    tooltip?: string;
    fullWidth?: boolean;
    extraClassContent?: string;
    extraClassHeaders?: string;
}
function Main(props: IMain) {
    const {
        tooltip,
        title,
        description,
        icon,
        withSidebar,
        minHeight,
        grayBackground,
        withHeaderBar,
        fullWidth,
        extraClassContent = "",
        extraClassHeaders = "",
    } = props;
    const extraClass = withSidebar ? "lg:ml-[200px] xl:ml-[256px]" : "";
    const [openSupportModal, setOpenSupportModal] = useState<boolean>(false);
    const currentSection = useAppSelector(selectCurrentSection);
    const [videoList, setVideoList] = useState([]);

    const getModuleSupportList = async (section: string) => {
        const url = `${AdminEndpoints.supportModules}?section=${section}&take=1000&skip=0&platform=${SupportPlatform.WEB}`;
        try {
            const response = await server.requestForAdmin(url, "GET");
            const supportModules = response?.data?.data;
            setVideoList(supportModules || []);
        } catch (error) {
            setVideoList([]);
        }
    };

    const supportSection = useMemo(() => {
        switch (currentSection) {
            case PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_DETAILS:
            case PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_CONTACTS:
            case PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_EXPERIENCE:
            case PAGE_SECTIONS.TRIP_ITINERARY_OTHER_PRIVATE_REVIEW_DETAILS:
            case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DETAILS:
            case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_CONTACTS:
            case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_ROOMS:
            case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DINNING:
            case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_WELLNESS:
            case PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_COMMON:
                return PAGE_SECTIONS.TRIP_ITINERARY_PRIVATE_REVIEW;
            case PAGE_SECTIONS.TRIP_EDIT:
            case PAGE_SECTIONS.TRIP_NEW:
                return PAGE_SECTIONS.TRIP_NEW;
            case PAGE_SECTIONS.ORGANIZATION_LIBRARY_DETAILS:
            case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_HOTEL:
            case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_VISIT:
            case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_RESTAURANT:
            case PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_OTHER:
                return PAGE_SECTIONS.ORGANIZATION_LIBRARY;
            default:
                return currentSection;
        }
    }, [currentSection]);

    useEffect(() => {
        if (supportSection) {
            getModuleSupportList(supportSection);
        }
    }, [supportSection]);

    const showSupportButton = useMemo(() => {
        return SUPPORT_MODULE_SECTIONS_OPTIONS.find(
            (s) => s.value === supportSection
        );
    }, [supportSection]);
    return (
        <div
            className={
                withHeaderBar ? `bg-[#F3F7FA] main ${extraClass}` : extraClass
            }
        >
            <ModuleSupport
                videoList={videoList}
                tooltip={"" || tooltip}
                title={title}
                open={openSupportModal}
                onClose={() => {
                    setOpenSupportModal(false);
                }}
            />

            {withHeaderBar && (
                <div
                    className={`px-6 pt-9 pb-6 lg:pb-[120px] bg-bg_header ${
                        !withSidebar ? "lg:pl-[80px] pr-6" : ""
                    }`}
                >
                    <div className="flex flex-row">
                        <div className="flex-grow">
                            <Breadcrumbs />
                        </div>
                        <div className="text-right items-end justify-end self-end">
                            <SupportIcon
                                showIcon={!!showSupportButton}
                                handleOpenModal={() => {
                                    setOpenSupportModal(true);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-x-2 items-center mb-1">
                        {icon && (
                            <Icon
                                icon={icon}
                                color="white"
                                className="text-white fill-white w-6 h-6"
                            />
                        )}
                        <h1 className="text-left text-white font-semibold text-lg">
                            {title}
                        </h1>
                    </div>
                    <h2 className="text-left text-white font-semibold text-sm">
                        {description}
                    </h2>
                </div>
            )}
            <div
                className={
                    withHeaderBar
                        ? `bg-[#F3F7FA] w-full pb-16 lg:pb-auto ${extraClassContent} ${
                              (!!withSidebar &&
                                  "lg:pl-5 lg:px-[40px] xl:pr-[80px]") ||
                              `${fullWidth ? "lg:px-[10px]" : "lg:px-[80px]"}`
                          } `
                        : ""
                }
            >
                <div
                    className={
                        withHeaderBar
                            ? `${
                                  grayBackground
                                      ? "bg-transparent lg:bg-neutral-100"
                                      : "bg-white"
                              } flex flex-col lg:rounded-xl  py-6 px-6 xl:px-12 lg:shadow-md relative lg:top-[-90px] ${extraClassHeaders} ${
                                  minHeight || "min-h-[70vh]"
                              }`
                            : ""
                    }
                >
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Main;
