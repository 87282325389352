import { useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import Avatar from "../../components/Avatar";
import ButtonSquared from "../../components/ButtonSquare";
import {
    OrganizationTypeOptions,
    UserStatusOnOrganization,
} from "../../constants";
import { selectProfile } from "../authentication/userSlice";
import defaultImage from "../../assets/images/placeholder.png";

interface IOrgItemProps {
    organization: any;
    handleAccept?: any;
    handleDecline?: any;
    handleClick?: any;
}
export default function OrganizationsListItem({
    organization,
    handleAccept,
    handleDecline,
    handleClick,
}: IOrgItemProps) {
    const profile = useAppSelector(selectProfile);
    const confirmedMembers = useMemo(() => {
        return organization?.users.filter((u: any) => {
            return u.status === UserStatusOnOrganization.CONFIRMED;
        });
    }, [organization?.users?.length]);

    const members = useMemo(() => {
        return confirmedMembers?.slice(0, 3) || [];
    }, [confirmedMembers?.length]);

    const orgTypeLabel = useMemo(() => {
        return OrganizationTypeOptions.find(
            (t) => t.value === organization?.type
        )?.label;
    }, [organization?.type]);
    const meOnOrg = useMemo(() => {
        return organization?.users?.find(
            (m: any) => m.userEmail === profile?.email
        );
    }, [organization?.users, profile?.email]);
    const showInviteButtons =
        meOnOrg && meOnOrg.status === UserStatusOnOrganization.PENDING;

    return (
        <div className="border border-gray-200 hover:border-2 hover:border-orange-500 rounded-xl p-5 w-full lg:w-[284px] overflow-hidden cursor-pointer select-none">
            <div
                onClick={handleClick}
                className="flex gap-x-4 flex-nowrap overflow-hidden whitespace-nowrap"
            >
                <Avatar
                    squared
                    src={
                        organization?.image_thumb ||
                        organization?.image ||
                        defaultImage
                    }
                    className="w-14 h-14"
                />
                <div className="text-left max-w-full overflow-hidden whitespace-nowrap">
                    <div className="font-semibold text-gray-600 text-sm max-w-full overflow-hidden whitespace-nowrap text-ellipsis break-all">
                        {organization?.name}
                    </div>
                    <div className="font-medium text-gray-500 text-sm mt-2 truncate">
                        {orgTypeLabel}
                    </div>
                </div>
            </div>
            <div
                onClick={handleClick}
                className="mt-2 flex justify-between items-center"
            >
                <div className="font-semibold text-orange-600 text-sm text-left">
                    {`${confirmedMembers?.length} Members`}
                </div>
                <div className="">
                    {members.map((member: any, index: number) => {
                        const aClass = `w-8 h-8 bg-white ${
                            index ? "-ml-[10px]" : ""
                        }`;
                        return (
                            <Avatar
                                key={`orgMember_${member?.userEmail}`}
                                src={
                                    !!member?.user?.avatar_thumb?.length
                                        ? member?.user?.avatar_thumb
                                        : member?.user?.avatar
                                }
                                className={aClass}
                            />
                        );
                    })}
                </div>
            </div>
            {showInviteButtons && (
                <div className="flex gap-4 flex-nowrap mt-4">
                    <ButtonSquared
                        label="Accept Invite"
                        className="w-fit px-2 z-10 max-h-10"
                        onClick={handleAccept}
                    />
                    <ButtonSquared
                        outlined
                        label="Decline"
                        className="w-fit px-2 z-10 max-h-10"
                        onClick={handleDecline}
                    />
                </div>
            )}
        </div>
    );
}
