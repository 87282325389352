import {
    ComplaintStatus,
    StatusBadge,
    UserStatusOnOrganization,
    UserStatusOnTrip,
} from "../../constants";
import Badge from "../Badge/Badge";

export default function StatusBadgeComponent(props: {
    status: UserStatusOnTrip | UserStatusOnOrganization | ComplaintStatus;
    customText?: string;
    outlined?: boolean;
    size?: string;
    canEdit?: boolean;
}) {
    const { status, outlined, size, customText } = props;

    return (
        <div className="flex flex-col items-center justify-center">
            <Badge
                color={StatusBadge[status as any]?.color as any}
                text={customText || StatusBadge[status as any]?.text}
                size={size}
                outlined={outlined}
            />
        </div>
    );
}
