export const UsersDotMenuKeys = {
    edit: "edit",
    makeAdmin: "makeAdmin",
    revokeAdmin: "revokeAdmin",
    getAdminToken: "getAdminToken",
    addSubscription: "addSubscription",
    extendSubscription: "extendSubscription",
    activateUser: "activateUser",
};

const commonDotAdminMenuOptions: any = [
    {
        icon: "AccountDetailsIcon",
        label: "Info",
        key: UsersDotMenuKeys.edit,
    },
    {
        icon: "AccountHardHatIcon",
        label: "Add Subscription",
        key: UsersDotMenuKeys.addSubscription,
    },
    {
        icon: "AccountHardHatIcon",
        label: "Extend days to subscription",
        key: UsersDotMenuKeys.extendSubscription,
    },
    {
        icon: "AccountDetailsIcon",
        label: "Activate User",
        key: UsersDotMenuKeys.activateUser,
    },
    {
        icon: "AccountDetailsIcon",
        label: "Login as",
        key: UsersDotMenuKeys.getAdminToken,
    },
];

export const UsersDotAdminMenuOptions: any = [
    {
        icon: "AccountArrowDownIcon",
        label: "Revoke Admin",
        key: UsersDotMenuKeys.revokeAdmin,
    },
    ...commonDotAdminMenuOptions,
];

export const UsersDotUserMenuOptions: any = [
    {
        icon: "AccountHardHatIcon",
        label: "Make Admin",
        key: UsersDotMenuKeys.makeAdmin,
    },
    ...commonDotAdminMenuOptions,
];

export const ReviewsDotMenuKeys = {
    edit: "edit",
    delete: "delete",
    view: "view",
};

export const ReviewsDotMenuOptions: any = [
    {
        icon: "PencilIcon",
        label: "Edit",
        key: ReviewsDotMenuKeys.edit,
    },
    {
        icon: "TrashIcon",
        label: "Delete",
        key: ReviewsDotMenuKeys.delete,
    },
];

export const ComplaintsDotMenuKeys = {
    view: "view",
    delete: "delete",
};
export const ComplaintsDotMenuOptions = [
    {
        icon: "PencilIcon",
        label: "view",
        key: ComplaintsDotMenuKeys.view,
    },
    {
        icon: "TrashIcon",
        label: "Delete",
        key: ComplaintsDotMenuKeys.delete,
    },
];

export const TripsDotMenuKeys = {
    edit: "edit",
    delete: "delete",
    view: "view",
};
export const TripsDotMenuOptions: any = [
    {
        icon: "PencilIcon",
        label: "Edit",
        key: ReviewsDotMenuKeys.edit,
    },
    {
        icon: "TrashIcon",
        label: "Delete",
        key: ReviewsDotMenuKeys.delete,
    },
];

export const TripsDotMenuOptionsForAdmin: any = [

    {
        icon: "CrownIcon",
        label: "Administration",
        key: ReviewsDotMenuKeys.view,
    },
    {
        icon: "TrashIcon",
        label: "Delete",
        key: ReviewsDotMenuKeys.delete,
    },
];

export const ElementAdminDotMenuKeys = {
    replace: "replace",
    delete: "delete",
    edit: "edit",
};
export const ElementAdminDotMenuOptions: any = [
    {
        icon: "PencilIcon",
        label: "Edit",
        key: ElementAdminDotMenuKeys.edit,
    },
    {
        icon: "FileReplaceIcon",
        label: "Replace",
        key: ElementAdminDotMenuKeys.replace,
    },
    {
        icon: "TrashIcon",
        label: "Delete",
        key: ElementAdminDotMenuKeys.delete,
    },
];


export const ElementBasicAdminDotMenuOptions: any = [
    {
        icon: "PencilIcon",
        label: "Edit",
        key: ElementAdminDotMenuKeys.edit,
    },
    {
        icon: "TrashIcon",
        label: "Delete",
        key: ElementAdminDotMenuKeys.delete,
    },
];
