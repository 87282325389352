//import React, { useEffect, useMemo, useState } from "react";
import { useMemo } from "react";
import Icon from "../../assets/svg/Icon";

const ResultsListItem = (props: any) => {
    const { selected, header, subHeader, handleOptionSelect } = props;

    const contClass = useMemo(() => {
        return `min-h-[80px] box-border group shadow z-10 bg-white text-sm text-neutral-800 text-left rounded-md border flex gap-2 items-center justify-between px-9 cursor-pointer py-4 hover:bg-gray-50 border-b hover:border-orange-600 ${
            selected ? "border-orange-600" : ""
        }`;
    }, [selected]);

    const buttonClass = useMemo(() => {
        return `px-4 py-2 bg-orange-600 text-white rounded-md group-hover:block ${
            selected ? "block" : "hidden"
        }`;
    }, [selected]);

    return (
        <div onClick={handleOptionSelect} className={`mb-4`}>
            <div className={contClass}>
                <div className="flex gap-2 items-center">
                    <div>
                        <Icon icon={"MarkerIcon"} className="w-6 h-6" />
                    </div>
                    <div>
                        <div className="text-sm font-medium text-gray-600">
                            {header}
                        </div>
                        <div className="text-xs font-medium text-gray-400 pt-1">
                            {subHeader}
                        </div>
                    </div>
                </div>

                <div className={buttonClass}>Select</div>
            </div>
        </div>
    );
};

export default ResultsListItem;
