import { Route } from "react-router-dom";
import Profile from "../features/authentication/profile";
import { ResetPassword } from "../features/authentication/resetPassword";
import { SignIn } from "../features/authentication/signin";
import { Signup } from "../features/authentication/signup";
import UserNotificationsList from "../features/notifications";
import UserPreferences from "../features/preferences";
import UserProfile from "../features/UserProfile/UserProfile";
import RequireAuth from "../hooks/RequiresAuth";
import UserPlans from "../features/plans";
import { AccountManagement } from "../features/authentication/accountManagement";
import { DeletedAccount } from "../features/authentication/accountManagement/deleteUser/DeletedAccount";
import { DeleteAccount } from "../features/authentication/accountManagement/deleteUser/DeleteAccount";
import { ExpiredLinkView } from "../features/authentication/SSO/ExpiredLinkView";
import UserChatsList from "../features/chat";

export const ExpiredLinkRoute = (
    <Route path="/auth/expired" element={<ExpiredLinkView />} />
);
export const DeleteAccountRoute = (
    <Route path="/profile/delete" element={<DeleteAccount />} />
);
export const DeletedAccountRoute = (
    <Route path="/profile/deleted" element={<DeletedAccount />} />
);
export const ProfileRoute = (
    <Route
        path="/profile"
        element={
            <RequireAuth protectedRoute={true}>
                <Profile />
            </RequireAuth>
        }
    />
);

export const PreferencesRoute = (
    <Route
        path="/preferences"
        element={
            <RequireAuth protectedRoute={true}>
                <UserPreferences />
            </RequireAuth>
        }
    />
);

export const PlansRoute = (
    <Route
        path="/plans"
        element={
            <RequireAuth protectedRoute={true}>
                <UserPlans />
            </RequireAuth>
        }
    />
);

export const AccountRoute = (
    <Route
        path="/account"
        element={
            <RequireAuth protectedRoute={true}>
                <AccountManagement />
            </RequireAuth>
        }
    />
);

export const UserProfileRoute = (
    <Route
        path="/users/:userId"
        element={
            <RequireAuth protectedRoute={true}>
                <UserProfile />
            </RequireAuth>
        }
    />
);

export const NotificationsRoute = (
    <Route
        path="/notifications"
        element={
            <RequireAuth protectedRoute={true}>
                <UserNotificationsList />
            </RequireAuth>
        }
    />
);

export const ChatRoute = (
    <Route
        path="/chat"
        element={
            <RequireAuth protectedRoute={true}>
                <UserChatsList />
            </RequireAuth>
        }
    />
);

export const SigninRoute = (
    <Route
        path="/signin"
        element={
            <RequireAuth protectedRoute={false}>
                <SignIn />
            </RequireAuth>
        }
    />
);

export const SignupRoute = (
    <Route
        path="/signup"
        element={
            <RequireAuth protectedRoute={false}>
                <Signup />
            </RequireAuth>
        }
    />
);

export const ResetPasswordRoute = (
    <Route
        path="/reset-password"
        element={
            <RequireAuth protectedRoute={false}>
                <ResetPassword />
            </RequireAuth>
        }
    />
);
