interface InsightCommentBlockProps {
    children: React.ReactNode;
    title: string;
    className?: string;
}

const InsightCommentBlock = ({ children, title, className }: InsightCommentBlockProps) => (
    <div className={`flex flex-col gap-1 leading-8 sm:text-base text-sm whitespace-break-spaces ${className}`}>
        <span className="font-medium text-gray-500 mr-2">{title}</span>{" "}
        {children}
    </div>
);

export default InsightCommentBlock;