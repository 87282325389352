import { useEffect } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { PAGE_SECTIONS } from "../../../../constants";
import { setCurrentSection } from "../../../layout/layoutSlice";
import AllHotelsList from "./AllHotelsList";

export default function HotelsAdminHome(props: any) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_HOTELS })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AllHotelsList />;
}
