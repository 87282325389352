import React, { FC, InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import CountrySelectInput from "./CountrySelectInput";

const SelectInputCountryWithValid: FC<any> = ({
  name,
  error,
  label,
  control,
  defaultValue,
  onChange,
  ...rest
}) => {
  const handleChange = (value: any, handleOnChangeValidator: Function) => {
    if (onChange) onChange(value);
    if (handleOnChangeValidator) handleOnChangeValidator(value);
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, ref, value } }: any) => (
        <CountrySelectInput
          name={name}
          label={label}
          onChange={(a: any) => {
            handleChange(a, onChange);
          }}
         //value={value}
          defaultValue={defaultValue}
          onBlur={onBlur}
          ref={ref}
          error={error && Object.keys(error).length > 0 && "Required field"}
          {...rest}
        />
      )}
    />
  );
};

export default SelectInputCountryWithValid;
