import Icon from "../../../assets/svg/Icon";
import DotsMenu from "../../../components/DotsMenu";
import { ActivityType } from "../../../constants";
import { ILibraryElement } from "../../../types/library.type";


interface LibraryElementItemProps {
    item: ILibraryElement;
    onClick: (item: ILibraryElement) => void;
    handleClickMenu?: (option: string, item: ILibraryElement) => void;
    isSelected?: boolean;
}

const menuOptions = [
    {
        key: "add-to-trip",
        label: "Add to trip",
        icon: "PlusIcon"
    },
    {
        key: "edit",
        label: "Edit",
        icon: "PencilIconOutline"
    },
    {
        key: "duplicate",
        label: "Duplicate",
        icon: "CopyIcon"
    },
    {
        key: "delete",
        label: "Delete",
        icon: "TrashIcon"
    },
];

const LibraryElementItem = ({
    item,
    handleClickMenu,
    onClick,
    isSelected,
}: LibraryElementItemProps) => {
    const iconName = (() => {
        if (item.isVisit) {
            return "BuildingIcon";
        }
        const icons: Record<string, string> = {
            [ActivityType.HOTEL]: "BedIcon",
            [ActivityType.RESTAURANT]: "RestaurantIcon",
            [ActivityType.OTHER]: "CalendarPlusIcon",
        };

        return icons[item.type] || "BedIcon";
    })();

    const fullAdress =
        item.hotel?.fullAddress ||
        item.restaurant?.fullAddress ||
        item.other?.fullAddress;

    return (
        <div
            className={`p-3 ${
                isSelected ? "bg-rose" : "bg-neutral-100"
            } rounded-lg border-2 ${
                isSelected ? "border-primary_orange-500" : "border-neutral-100"
            } cursor-pointer hover:text-primary_orange-500`}
            onClick={() => onClick(item)}
        >
            <div className="flex justify-between">
                <div className="flex items-center gap-2">
                    <Icon
                        icon={iconName}
                        className="w-5 h-5 text-primary_orange-500"
                    />
                    <div className="text-lg font-semibold">{item.title}</div>
                </div>
                {handleClickMenu && (
                    <DotsMenu
                        className={"text-neutral-900 hover:text-neutral-900"}
                        options={menuOptions}
                        handleClickMenu={(option: string) =>
                            handleClickMenu(option, item)
                        }
                    />
                )}
            </div>
            <div className="text-sm text-neutral-600 text-left mt-1">
                {fullAdress && fullAdress !== 'undefined' && fullAdress}
            </div>
        </div>
    );
};

export default LibraryElementItem;