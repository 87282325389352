import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    addUsersToTrip,
    getTripMembers,
    getTripMembersMobile,
    getTripUsersXls,
    selectedTrip,
    selectedTripUsers,
    updateUserOnTrip,
} from "../../tripSlice";
import SelectInput from "../../../../components/InputSelect/SelectInput";
import {
    ButtonSize,
    PAGE_ROWS,
    PAGE_SECTIONS,
    UserRoleOnTrip,
    userRoleTripFilterOptions,
    userStatusTripFilterOptions,
} from "../../../../constants";
import { useEffect, useMemo, useState } from "react";
import {
    selectCurrentBreakpoint,
    setCurrentSection,
} from "../../../layout/layoutSlice";
import Modal from "../../../../components/Modal";
import InputSearch from "../../../../components/InputSearch";
import ButtonSquared from "../../../../components/ButtonSquare";
import AddUsersForm from "../../../../components/AddUsersForm";
import { selectProfile } from "../../../authentication/userSlice";
import TripUsers from "./TripUsers";
import { UpgradePlanModal } from "../../../../components/Modal/UpgradePlanModal";

export default function TripUsersWrapper() {
    const [search, setSearch] = useState("");
    const [newPage, setNewPage] = useState<any>({});
    const [status, setStatus] = useState("");
    const [role, setRole] = useState("");
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);
    const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
    const [usersLimitReached, setusersLimitReached] = useState(false);
    const tripUsers = useAppSelector(selectedTripUsers);
    const profile = useAppSelector(selectProfile);
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_PARTICIPANTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (trip?.id) {
            getMembers(
                PAGE_ROWS,
                newPage.skip,
                newPage.currentPage,
                search,
                status,
                role
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, search, trip, status, role]);

    const handleAddUserClick = (e: any) => {
        setAddUserModalOpen(true);
    };

    const { currentPage, skip } = tripUsers;

    const getMembers = (
        take: number,
        newSkip: number,
        newPage: number,
        search: string,
        status: any,
        role: any
    ) => {
        if (trip?.id) {
            const actionDispatch = !isDesktop
                ? getTripMembersMobile
                : getTripMembers;
            dispatch(
                actionDispatch(
                    Number(trip?.id),
                    take,
                    newSkip,
                    newPage,
                    search,
                    status,
                    role
                )
            );
        }
    };

    const handleSearchChange = (value: string) => {
        setSearch(value);
    };
    const handleRoleFilterChange = (value: string) => {
        const formattedValue = value === "ALL" ? "" : value;
        setRole(formattedValue);
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };
    const handleStatusFilterChange = (value: string) => {
        const formattedValue = value === "ALL" ? "" : value;
        setStatus(formattedValue);
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleModalClose = () => {
        setAddUserModalOpen(false);
    };

    const maxMembers = useMemo(() => {
        return trip?.planPermissions?.find((i: any) => {
            return i.name === "maxMembersPerTrip";
        });
    }, [trip?.planPermissions]);

    const maxAdmins = useMemo(() => {
        return trip?.planPermissions?.find((i: any) => {
            return i.name === "maxAdminsPerTrip";
        });
    }, [trip?.planPermissions]);

    const handleFinishAdd = async (users: any) => {
        const canAddMembers =
            ((users?.length || 0) + tripUsers?.count || 0) <=
            Number(maxMembers?.value || 0);

        const canAddAdmins =
            !!Number(maxAdmins?.value || 0) &&
            ((users?.filter((u: any) => u.role === UserRoleOnTrip.ADMIN)
                ?.length || 0) + tripUsers?.adminsCount || 0) <=
                Number(maxAdmins?.value || 0);
        setusersLimitReached(!canAddMembers);
        const triesToAddAdmin = users?.filter(
            (u: any) => u.role === UserRoleOnTrip.ADMIN
        )?.length;
        if (
            !profile?.isAdmin &&
            (!canAddMembers || (triesToAddAdmin && !canAddAdmins))
        ) {
            setOpenUpgradePlanModal(true);
        } else {
            if (trip?.id) {
                await dispatch(addUsersToTrip(Number(trip?.id), users));
                handleModalClose();
            }
            getMembers(PAGE_ROWS, skip, currentPage, search, status, role);
        }
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const handleDownloadUsersXls = () => {
        dispatch(getTripUsersXls(trip?.id));
    };

    const renderActionButton = useMemo(
        () =>
            ((trip?.canEdit && !trip?.suspended) || profile?.isAdmin) && (
                <div className="flex flex-grow flex-row justify-end text-end mt-1">
                    <div
                        className="flex-shrink"
                        title="Export participants info"
                    >
                        <ButtonSquared
                            outlined
                            size={ButtonSize.SMALL}
                            label={"Export List"}
                            onClick={handleDownloadUsersXls}
                        />
                    </div>
                    <div className="ml-2">
                        <ButtonSquared
                            size={ButtonSize.SMALL}
                            label={"Invite Participants"}
                            onClick={handleAddUserClick}
                        />
                    </div>
                </div>
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [trip?.canEdit, profile?.isAdmin]
    );

    const handleUserRoleChange = async (
        newrole: UserRoleOnTrip,
        email: string
    ) => {
        if (
            newrole !== UserRoleOnTrip.ADMIN ||
            (!!Number(maxAdmins?.value || 0) &&
                (tripUsers?.adminsCount || 0) <= Number(maxAdmins?.value || 0))
        ) {
            await dispatch(
                updateUserOnTrip(Number(trip?.id), email, { role: newrole })
            );
            getMembers(PAGE_ROWS, skip, currentPage, search, status, role);
        } else {
            setOpenUpgradePlanModal(true);
        }
    };

    return (
        <>
            <UpgradePlanModal
                open={openUpgradePlanModal}
                handleClose={() => {
                    setOpenUpgradePlanModal(false);
                }}
                addingAdmins={!usersLimitReached}
                usersLimit={
                    usersLimitReached ? maxMembers?.value : maxAdmins?.value
                }
            />
            <Modal open={addUserModalOpen} onClose={handleModalClose}>
                <AddUsersForm
                    formType="trip"
                    handleClose={handleModalClose}
                    handleFinishAdd={handleFinishAdd}
                />
            </Modal>
            <div className="flex flex-row flex-wrap-reverse">
                <div className="grid grid-cols-6  lg:grid-cols-4 items-end gap-5">
                    <div className="col-span-6 lg:col-span-2">
                        <InputSearch
                            onChange={handleSearchChange}
                            name="search"
                            id="search"
                            placeholder="Search"
                        />
                    </div>
                    <div className="col-span-3 lg:col-span-1">
                        <SelectInput
                            onChange={handleStatusFilterChange}
                            label="Status"
                            options={userStatusTripFilterOptions}
                            defaultValue={userStatusTripFilterOptions[0].value}
                        />
                    </div>
                    <div className="col-span-3 lg:col-span-1">
                        <SelectInput
                            label="Role"
                            onChange={handleRoleFilterChange}
                            options={userRoleTripFilterOptions}
                            defaultValue={userRoleTripFilterOptions[0].value}
                        />
                    </div>
                </div>
                {renderActionButton}
            </div>

            <TripUsers
                handleUserRoleChange={handleUserRoleChange}
                handlePaginatorChange={handlePaginatorChange}
                updateData={() =>
                    getMembers(
                        PAGE_ROWS,
                        skip,
                        currentPage,
                        search,
                        status,
                        role
                    )
                }
            />
        </>
    );
}
