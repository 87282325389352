import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { PAGE_SECTIONS, PlanType } from "../../constants";
import { setCurrentSection } from "../layout/layoutSlice";
import { CurrentPlan } from "./CurrentPlan";
import { getAvailablePlans, getCurrentPlan } from "./plansSlice";
import { isLoading } from "../trips/tripSlice";
import { useSelector } from "react-redux";

export default function UserPlans() {
    const user = useSelector((state: any) => state?.user?.profile);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.PLAN_USER,
            })
        );
    }, []);

    const fetchPlans = async () => {
        dispatch(isLoading(true));
        await dispatch(getCurrentPlan());
        await dispatch(
            getAvailablePlans(
                PlanType.USER,
                (user?.userType &&
                    user?.userType !== "null" &&
                    user?.userType) ||
                    "SUPPLIER"
            )
        );
        dispatch(isLoading(false));
    };

    useEffect(() => {
        setTimeout(() => {
            fetchPlans();
        }, 300);
    }, []);

    return <CurrentPlan />;
}
