import { useAppSelector } from "../../../app/hooks";
import { selectCurrentSection } from "../layoutSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ButtonSquared from "../../../components/ButtonSquare";
import TextareaWithValid from "../../../components/TextArea";
import { ComplaintType } from "../../../constants";
import { useDispatch } from "react-redux";
import { sendFeedback } from "../../admin/adminSlice";

const schema = yup.object({
    comments: yup.string().required("This field is required"),
});

export const FeedbackForm = (props: { onFinish?: Function }) => {
    const currentSection = useAppSelector(selectCurrentSection);
    const userProfile = useAppSelector((state: any) => state?.user?.profile);
    const dispatch = useDispatch();

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const handleSubmitForm = async (data: any) => {
        const payload = {
            comments: data?.comments,
            itemType: ComplaintType.USER_FEEDBACK,
            reason: currentSection || "General",
            itemId: userProfile?.id,
            title: `Feedback from (${userProfile?.email})`,
        };
        await dispatch(sendFeedback(payload));
        props.onFinish && props.onFinish();
    };

    return (
        <div className="mx-6">
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="text-lg text-neutral-900 font-semibold">
                    How can we help?
                </div>
                <div className="flex flex-col">
                    <div className="mt-3 w-full">
                        <TextareaWithValid
                            register={register}
                            label="Report or feedback"
                            type="text"
                            id="comments"
                            name="comments"
                            error={errors["comments"]?.message}
                        />
                    </div>
                </div>
                <div className="my-6 items center justify-center flex gap-5">
                    <ButtonSquared
                        outlined
                        type="button"
                        label="Cancel"
                        onClick={() => {
                            props.onFinish && props.onFinish();
                        }}
                    />
                    <ButtonSquared type="submit" label="Send" />
                </div>
            </form>
        </div>
    );
};
