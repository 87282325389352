import React, { FC, useState } from "react";
import Icon from "../../../../assets/svg/Icon";
import ModalConfirmation from "../../../../components/Modal/ConfirmationModal";

const ContactCard: FC<any> = ({
    contact,
    handleClick,
    handleEdit,
    handleRemove,
    hideEdit,
    ...rest
}) => {
    const { firstName, lastName, email, phone, mobilePhone, role } =
        contact || {};
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    return (
        <>
            <ModalConfirmation
                open={deleteModalOpen}
                title="Are you sure?"
                description={`You are about to remove ${firstName} ${lastName}`}
                handleCancel={() => {
                    setDeleteModalOpen(false);
                }}
                handleSubmit={(() => {handleRemove(); setDeleteModalOpen(false);})}
            />
            <div className="shadow-sm border p-6 border-gray-200 flex flex-col rounded-lg w-[300px] h-[175px]">
                <div className="flex">
                    <div
                        title={`${firstName} ${lastName}`}
                        className="text-lg font-bold truncate"
                    >
                        {firstName} {lastName}
                    </div>
                  { !hideEdit && <div className="flex flex-grow justify-end content-end">
                        <Icon
                            icon={"PencilIcon"}
                            className={`w-5 hover:text-black cursor-pointer text-neutral-600 mr-4`}
                            onClick={handleEdit}
                        />
                        <Icon
                            icon={"TrashIcon"}
                            className={`w-7 hover:text-black cursor-pointer text-white bg-orange-500 rounded-full p-1`}
                            onClick={() => {
                                setDeleteModalOpen(true);
                            }}
                        />
                    </div>}
                </div>

                <div className="pb-2 text-base font-semibold text-neutral-600">
                    {role}
                </div>
                <div className="pb-1 flex text-xs text-neutral-600">
                    <Icon icon={"EmailIcon"} className={`w-4 mr-4`} />
                    {email}
                </div>
                <div className="pb-1 flex text-xs text-neutral-600">
                    <Icon icon={"PhoneIcon"} className={`w-4 mr-4`} />
                    {phone || '-'}
                </div>
                <div className="flex text-xs text-neutral-600">
                    <Icon icon={"CellphoneIcon"} className={`w-4 mr-4`} />
                    {mobilePhone || '-'}
                </div>
            </div>
        </>
    );
};

export default ContactCard;
