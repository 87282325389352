import Button from "../../../../../components/ButtonSquare";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";
import { ButtonSize } from "../../../../../constants";
import FileItem from "../FileItem";

interface FileDetailProps {
    file: any;
    goBack: any;
    startEditting: any;
}

export default function FileDetails(props: FileDetailProps) {
    const { file, goBack, startEditting } = props;
    return (
        <>
            <div className="flex flex-wrap mb-8">
                <div className="text-left font-semibold text-lg flex-grow">
                    File
                </div>
                <span>
                    <Button
                        size={ButtonSize.SMALL}
                        outlined={true}
                        onClick={startEditting}
                        label="Edit"
                    />
                </span>
            </div>
            <div className="flex flex-col">
                <div className="text-left lg:max-w-lg">
                    <FileItem file={file} />
                </div>
                <div className="text-left lg:max-w-lg break-words mt-6">
                    <span className="block text-neutral-600 mb-1 text-sm font-medium">
                        Name
                    </span>
                    <span className="text-base font-normal">{file.name}</span>
                </div>
                <div className="text-left lg:max-w-lg break-words mt-6">
                    <span className="block text-neutral-600 mb-1 text-sm font-medium">
                        Description
                    </span>
                    <Paragraph className="text-base font-normal">
                        {file.description}
                    </Paragraph>
                </div>
                <div className="text-left lg:max-w-lg break-words mt-6">
                    <span className="block text-neutral-600 mb-1 text-sm font-medium">
                        Visible to
                    </span>
                    <span className="text-base font-normal">
                        {file?.permission?.rolesAllowed?.join(', ') || "-"}
                    </span>
                </div>
                <span className="text-left mt-24">
                    <Button onClick={goBack} label="Back" />
                </span>
            </div>
        </>
    );
}
