import React, { useEffect, useMemo, useState } from "react";
import InputErrorLabel from "../InputErrorLabel";
import InputLabel from "../InputLabel";
import Select from "react-select";

const SelectInput = React.forwardRef((props: any, ref: any) => {
    const [value, setValue] = useState<any>();
    const {
        label,
        id,
        name,
        type,
        placeholder,
        defaultValue,
        error,
        onChange,
        options,
        selectedOptionsMaxLength,
        markRequired,
        value: val,
        ...others
    } = props;

    useEffect(() => {
        setValue(val);
    }, [val]);

    const handleChange = (values: any) => {
        const newValues = Array.isArray(values)
            ? values.map((v) => v.value)
            : values?.value;
        if (onChange) onChange(newValues);
        setValue(values);
    };
    const getDefaultValue = (defValue: any, options: Array<any>) => {
        if (!defValue) return null;
        let df;
        if (typeof defValue === "string")
            df = defValue
                ? {
                      value: defValue,
                      label: options.find((o: any) => o.value === defValue)
                          ?.label,
                  }
                : null;
        if (Array.isArray(defValue))
            df = defValue.map((val) => {
                return {
                    value: val,
                    label: options.find((o: any) => o.value === val)?.label,
                };
            });
        return df;
    };

    const defValue = useMemo(() => {
        if (defaultValue && options) {
            const newValue = getDefaultValue(defaultValue, options);
            setValue(newValue);
            return newValue;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, options]);

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            cursor: "pointer",
            color: state.isSelected ? "white" : "black",
            backgroundColor: state.isSelected ? "#E26027" : "",
            "&:hover": {
                backgroundColor: !state.isSelected ? "#E6E6E6" : "",
            },
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            minWidth: "fit-content",
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            padding: "0px",
        }),
        control: (base: any, state: any) => ({
            ...base,
            paddingTop: ".2rem",
            background: others?.style?.background || "white",
            cursor: "pointer",
            // Overwrittes the different states of border
            border: "none",
            borderBottom: "1px solid #E5E5E5",
            borderRadius: "0px",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "#E26027" : "#E26027",
            },
        }),
    };

    return (
        <>
            <InputLabel
                className="!mb-0"
                inputId={id}
                label={label}
                error={error}
                markRequired={markRequired}
            />
            <Select
                isOptionDisabled={() =>
                    selectedOptionsMaxLength &&
                    value &&
                    value.length >= selectedOptionsMaxLength
                }
                components={{
                    IndicatorSeparator: () => null,
                }}
                styles={customStyles}
                options={options}
                onChange={handleChange}
                innerRef={ref}
                id={id}
                name={name}
                value={value}
                defaultValue={defValue}
                {...others}
            ></Select>
            <InputErrorLabel error={error} />
        </>
    );
});

export default SelectInput;
