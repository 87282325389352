import Icon from "../../assets/svg/Icon";

export enum UserTypeVariant {
    ADVISOR = "ADVISOR",
    SUPPLIER = "SUPPLIER",
}

interface UserTypeProps {
    variant: UserTypeVariant;
    checked?: boolean;
    handleClick: () => void;
    title: string;
    description: string;
    icon: string;
}

export const UserType = ({
    title,
    description,
    icon,
    checked: _checked = false,
    handleClick,
}: UserTypeProps) => {
    return (
        <div
            className={`w-full cursor-pointer  flex-initial justify-center text-center border-2 ${
                _checked ? "border-orange-500" : "hover:border-neutral-300"
            }
            ${_checked ? "bg-[#FDF2EE]" : "bg-neutral-50"}
             rounded-lg  mb-6 p-4`}
            onClick={handleClick}
        >
            <div className="flex flex-row">
                <div className="flex flex-row flex-grow items-center">
                    <img src={icon} height={30} width={30} alt="" />
                    <span className="ml-2 text-lg font-semibold"> {title}</span>
                </div>
                <div className="col-span-1">
                    {_checked ? (
                        <Icon
                            className="w-[32px] mr-1 text-orange-600"
                            icon={"CheckCircleIcon"}
                        />
                    ) : (
                        <Icon
                            className="w-[32px] mr-1 text-neutral-300"
                            icon={"BlankCircleIcon"}
                        />
                    )}
                </div>
            </div>

            <div className="font-medium text-sm text-left mt-4 text-neutral-300">
                {description}
            </div>
        </div>
    );
};
