import FB from "../api/firebase";
import { IChatEntity, IContact, IResponseChatMessage } from "../types/chat.type";
import { v4 as uuidv4 } from "uuid";
import serverApi from "../api/server";

const firebase = new FB();
const database = firebase.database;

export const mapUserMembers = async (tripId: number) => {
    const responseContacts = await serverApi.getTripsUsers(tripId);

    const members: IContact[] = responseContacts.data.map((u: any) => ({
        avatar: u.avatar,
        avatarThumb: u.avatarThumb,
        email: u.email,
        firstName: u.firstName,
        id: u.id,
        lastName: u.lastName,
        status: "CONFIRMED",
        uid: u.uid,
    }));

    for (let i = 0; i < members.length; i++) {
        const member = members[i];
        const deletedMembersRef = database.ref(
            `conversations/trip-${tripId}/deletedMembers/${member.uid}`
        );
        const deletedMembersSnapshot = await deletedMembersRef.once("value");
        if (deletedMembersSnapshot.exists()) {
            await deletedMembersRef.remove();
        }
    }

    return members;
}

export const createNewGroupConversation = async (
    tripId: number,
    tripTitle: string,
    avatar: string,
    currentUser: any
): Promise<void> => {
    const conversationId = `trip-${tripId}`;
    const id = uuidv4();
    const newConversation = {
        title: tripTitle,
        members: mapUserMembers(tripId),
        messages: {
            [id.toString()]: {
                id: id,
                content: "New group created",
                senderId: currentUser?.uid,
                timestamp: Date.now(),
                readBy: { [currentUser?.uid]: true },
            } as IResponseChatMessage,
        },
        isGroup: true,
        avatar,
    };

    const conversationRef = database.ref(`conversations/${conversationId}`);
    await conversationRef.set(newConversation);
};

export const updateGroupConversation = async (
    tripId: number,
    avatar: string,
    title: string
): Promise<IChatEntity> => {

    const members: IContact[] = await mapUserMembers(tripId);

    const conversationId = `trip-${tripId}`;
    const conversationRef = database.ref(`conversations/${conversationId}`);
    await conversationRef.update({
        members: members.reduce((acc, member) => {
            acc[member.uid] = member;
            return acc;
        }, {} as { [uid: string]: IContact }),
        title,
        avatar,
    });

    const setChat: IChatEntity = {
        id: conversationId,
        members,
        deletedMembers: [],
        messages: [],
        isGroup: true,
        title,
        avatar,
        lastMessageTimestamp: Date.now(),
        isBlockedBy: "",
        isFriend: false,
    };

    return setChat;
};

export const checkConversationExists = async (
    tripId: number
): Promise<boolean> => {
    try {
        const conversationId = `trip-${tripId}`;
        const conversationRef = database.ref(`conversations/${conversationId}`);
        const snapshot = await conversationRef.once("value");
        return snapshot.exists();
    } catch (error) {
        return false;
    }
};

export const addUsersToGroupConversation = async (
    tripId: number,
    newMembers: IContact[]
): Promise<void> => {
    const conversationId = `trip-${tripId}`;
    const conversationRef = database.ref(
        `conversations/${conversationId}/members`
    );

    newMembers.forEach(async (member) => {
        const deletedMembersRef = database.ref(
            `conversations/${conversationId}/deletedMembers/${member.uid}`
        );
        const deletedMembersSnapshot = await deletedMembersRef.once("value");
        if (deletedMembersSnapshot.exists()) {
            await deletedMembersRef.remove();
        }
        await conversationRef.child(member.uid).set(member);
    });
};


export const removeUserFromGroupConversation = async (
    tripId: number,
    user: IContact | undefined
): Promise<IChatEntity> => {
    if (!user) {
        return {} as IChatEntity;
    }
    try {
        const conversationId = `trip-${tripId}`;

        const conversationRef = database.ref(
            `conversations/${conversationId}/members/${user.uid}`
        );
        const deletedMembersRef = database.ref(
            `conversations/${conversationId}/deletedMembers/${user.uid}`
        );
        await conversationRef.remove();
        await deletedMembersRef.set(user);
        const refConversation = database.ref(`conversations/${conversationId}`);
        const snapshot = await refConversation.once("value");
        const conversation = snapshot.val();
        const formatConversation: IChatEntity = {
            id: conversationId,
            members: conversation.members,
            deletedMembers: conversation.deletedMembers,
            messages: conversation.messages,
            isGroup: conversation.isGroup,
            title: conversation.title,
            avatar: conversation.avatar,
            lastMessageTimestamp: conversation.lastMessageTimestamp,
            isBlockedBy: conversation.isBlockedBy,
            isFriend: conversation.isFriend,
        };
        return formatConversation as IChatEntity;
    } catch (error) {
        
        console.error(error);
        return {} as IChatEntity;
    }
};
