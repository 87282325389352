import axios from "axios";
import { useState } from "react";
import { uploadTempFile } from "../../features/trips/tripSlice";
import DropImageFileInput from "../DropImageFileInput/DropImageFileInput";
import GalleryImage from "../ImagesGallery/Image";
import ProgressBar from "../ProgressBar/ProgressBar";
import { resizeImageFile } from "../../helpers";

interface ImagesPreviewWithUploadProps {
    onChange: (data: {
        newImages: any[] | undefined;
        deletedImages: any[] | undefined;
    }) => void;
    images: any;
    label?: string;
}

export default function ImagesPreviewWithUpload({
    onChange,
    images,
    label,
}: ImagesPreviewWithUploadProps) {
    const [imagesProgress, setImagesProgress] = useState<any[]>([]);
    const [tempImages, setTempImages] = useState<any[]>([]);
    const [deletedImagesState, setDeletedImagesState] = useState<any[]>([]);
    const [imagesPreviews, setImagesPreviews] = useState<Array<any>>(
        images.map((i: any) => {
            return {
                name: i.originalName,
                src: i.url,
                width: i.width,
                height: i.height,
                isCurrent: true,
            };
        })
    );

    const handleChange = (data: { newImages: any[]; deletedImages: any[] }) => {
        const newImages = data.newImages.map((i) => i.path);
        onChange &&
            onChange({
                newImages: newImages?.length ? newImages : undefined,
                deletedImages: data?.deletedImages?.length
                    ? data.deletedImages
                    : undefined,
            });
    };

    const onImageProgress = (id: number, progress: number) => {
        setImagesProgress((prevImgProgress) => {
            return prevImgProgress.map((img) => {
                return {
                    ...img,
                    progress: id === img.id ? progress : img.progress,
                };
            });
        });
    };

    const removeProgressImage = (id: number, cancel = false) => {
        setImagesProgress((prevTmpStateArray) => {
            const index = prevTmpStateArray.findIndex((img) => img.id === id);
            URL.revokeObjectURL(prevTmpStateArray[index].file);
            cancel && prevTmpStateArray[index].cancelToken.cancel();
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };
    const handleImageRemove = (index: number) => {
        const newDeletedImagesState = [
            ...deletedImagesState,
            imagesPreviews[index].name,
        ];
        setDeletedImagesState([...newDeletedImagesState]);
        const spliceArray = imagesPreviews;
        spliceArray.splice(index, 1);
        setImagesPreviews(spliceArray);
        handleChange({
            newImages: tempImages,
            deletedImages: newDeletedImagesState,
        });
    };

    const removeTempImage = (id: number) => {
        let index = tempImages.findIndex((img) => img.id === id);
        URL.revokeObjectURL(tempImages[index].file);
        setTempImages((prevTmpStateArray) => {
            return prevTmpStateArray.filter((img) => img.id !== id);
        });
    };

    const onSelectImages = async (e: any) => {
        if (e.target.files) {
            const filesArray: any[] = Array.from(e.target.files);
            for(const file of filesArray) {

                const imageResized = await resizeImageFile(file);
                const newtempImg = {
                    id: tempImages.length,
                    file: imageResized,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };
                setImagesProgress((prevArray) => {
                    return [...prevArray, newtempImg];
                });

                uploadTempFile(
                    imageResized,
                    newtempImg.id,
                    onImageProgress,
                    newtempImg.cancelToken
                ).then((response) => {
                    removeProgressImage(newtempImg.id);
                    setTempImages((prev) => {
                        const newTempImagesState = [
                            ...prev,
                            {
                                file: URL.createObjectURL(file),
                                path: response,
                                id: newtempImg.id,
                            },
                        ];
                        handleChange({
                            newImages: newTempImagesState,
                            deletedImages: deletedImagesState,
                        });
                        return newTempImagesState;
                    });
                });
            }

            return;
        }
    };
    return (
        <div className="flex flex-col">
            {label && (
                <div className="text-sm text-left font-semibold mb-4">
                    {label}
                </div>
            )}
            <div>
                <DropImageFileInput
                    iconClass={"w-16"}
                    className={"!p-4"}
                    multiple={true}
                    onChange={onSelectImages}
                />
            </div>

            {imagesProgress.map((img) => {
                return (
                    <ProgressBar
                        key={img.id}
                        progress={img.progress}
                        name={img.file.name}
                        onRemove={() => removeProgressImage(img.id, true)}
                    />
                );
            })}

            <div className="flex flex-wrap gap-4 mt-8">
                {imagesPreviews.map((i, index: number) => {
                    return (
                        <GalleryImage
                            key={i.src}
                            src={i.src}
                            onImageClick={() => {}}
                            // onImageAdd={(e: any) => handleImageAdd(index)}
                            onImageRemove={() => {
                                handleImageRemove(index);
                            }}
                        />
                    );
                })}
                {tempImages.map((i) => {
                    return (
                        <GalleryImage
                            key={i.id}
                            src={i.file}
                            onImageClick={() => {}}
                            // onImageAdd={(e: any) => handleImageAdd(index)}
                            onImageRemove={() => {
                                removeTempImage(i.id);
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
}
