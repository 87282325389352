import InsightDetailItem from "../../../../../components/InsightDetailItem";
import { WELLNESS_TYPE, WellnessTypeOptions } from "../../../../../../../../constants/insight";
import SelectInput from "../../../../../../../../components/InputSelect/SelectInput";
import Input from "../../../../../../../../components/Input";
import Button from "../../../../../../../../components/ButtonSquare";
import { InsightImages } from "../../../../../components/InsightImages";
import TextAreaInput from "../../../../../../../../components/TextArea";
import InsightFormContainer from "../../components/InsightFormContainer";
import InsightInputContainer from "../../components/InsightInputContainer";

interface WellnessItemProps {
    showForm: boolean;
    register: any;
    errors: any;
    activeItem?: any;
    handleCancel: () => void;
    setType: any;
    handleUploadFile: (e: any) => void;
    watch: (name: string) => any;
    isValid: boolean;
}
export const FormWellnessItem = ({
    showForm,
    register,
    errors,
    activeItem,
    handleCancel,
    setType,
    handleUploadFile,
    watch,
    isValid,
}: WellnessItemProps) => {
    const typeOther = watch("type");
    if (!showForm) return null;
    return (
        <>
            <InsightImages
                handleUploadFile={handleUploadFile}
                images={activeItem?.images}
            />
            <div className="col-span-6 sm:col-span-3 mt-3">
                <InsightDetailItem label="Wellness types">
                    <InsightFormContainer className="gap-8">
                        <InsightInputContainer>
                            <Input
                                register={register}
                                label="Name"
                                type="text"
                                markRequired
                                defaultValue={activeItem?.name}
                                name="name"
                                error={errors["name"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <SelectInput
                                onChange={setType}
                                label="Type"
                                markRequired
                                options={WellnessTypeOptions}
                                name="type"
                                defaultValue={activeItem?.type}
                                error={errors["type"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            {typeOther === WELLNESS_TYPE.OTHER && (
                                <Input
                                    register={register}
                                    label="Other type"
                                    type="text"
                                    markRequired
                                    defaultValue={activeItem?.typeOther}
                                    name="typeOther"
                                    error={errors["typeOther"]?.message}
                                />
                            )}
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <TextAreaInput
                                register={register}
                                label="Comments"
                                type="text"
                                defaultValue={activeItem?.comments}
                                name="comments"
                                error={errors["comments"]?.message}
                            />
                        </InsightInputContainer>
                    </InsightFormContainer>
                    <div className="grid grid-cols-6 xl:gap-x-32 gap-x-6 gap-y-12"></div>
                </InsightDetailItem>
            </div>
            <div className="p-8 text-right flex lg:justify-end w-full gap-4">
                <Button onClick={handleCancel} outlined label="Cancel" />
                <Button disabled={!isValid} type="submit" label={"Save"} />
            </div>
        </>
    );
};
