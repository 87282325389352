import { PAGE_SECTIONS } from "../../../../../../../../constants";
import {
    addRestaurantContactInsight,
    removeRestaurantContactInsight,
} from "../../../../../../../hotelInsight/hotelInsightSlice";
import InsightContactView from "../../components/InsightContactsView/InsightContactView";

export default function ContactInsightReview() {
    const currentSection =
        PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_CONTACTS;

    return (
        <InsightContactView
            insightKey="restaurantInsight"
            currentSection={currentSection}
            addContact={addRestaurantContactInsight}
            removeContact={removeRestaurantContactInsight}
        />
    );
}
