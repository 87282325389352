import { useState } from "react";
import useMeasure from "react-use-measure";
import Icon from "../../assets/svg/Icon";
import { ComplaintType } from "../../constants";
import Badge from "../Badge/Badge";
import ReportComplaintForm from "../Complaints/ReportComplaintForm";
import DotsMenu from "../DotsMenu";
import GalleryImage from "../ImagesGallery/Image";
import Modal, { Modal_Size } from "../Modal";
import StarsRating from "../StarsRating/StarsRating";
import { Paragraph } from "../Paragraph/Paragraph";

interface IReview {
    id: number;
    title: string;
    formattedDate: string;
    comments: string;
    stars: number;
    images: { url: string; thumb_url: string }[];
    keywordsArray: string[];
    activityDate: string;
}

export default function ReviewDetails(props: {
    review: IReview;
    showReportButton?: boolean;
    expandedDefault?: boolean;
}) {
    const { review, showReportButton, expandedDefault } = props;
    const [expanded, setExpanded] = useState(expandedDefault);
    const [openReportModal, setOpenReportModal] = useState(false);
    const [ref, bounds] = useMeasure();

    return (
        <>
            <Modal
                size={Modal_Size.lg}
                open={openReportModal}
                onClose={() => {}}
            >
                <ReportComplaintForm
                    onClose={() => {
                        setOpenReportModal(false);
                    }}
                    title={`${review.title}`}
                    complaintType={ComplaintType.INSIGHT}
                    itemId={review?.id}
                />
            </Modal>
            <div className="w-full flex flex-col">
                <div className="w-full flex items-center justify-between">
                    <div
                        className={`${
                            !expanded ? "w-[calc(100%-.5rem)]" : "w-full"
                        } flex gap-4 items-center`}
                    >
                        <Icon
                            icon={
                                !expanded ? "ChevronUpIcon" : "ChevronDownIcon"
                            }
                            className={`w-7 text-neutral-400 cursor-pointer`}
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                        />
                        <div
                            className={`text-lg font-semibold text-left ${
                                !expanded ? "truncate" : ""
                            }`}
                        >
                            {review?.title}
                        </div>
                        <div className="text-xs text-neutral-400">
                            {review?.activityDate}
                        </div>
                    </div>
                    {showReportButton && (
                        <div className="flex justify-end relative">
                            <DotsMenu
                                position={"bottom"}
                                options={[{ label: "Report", key: "REPORT" }]}
                                handleClickMenu={() => {
                                    setOpenReportModal(true);
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="flex items-center justify-start m-1">
                    <div className="mr-5">
                        <StarsRating
                            className="w4 h-4"
                            rating={review?.stars}
                        />
                    </div>
                    <div className="text-xs text-neutral-400">
                        {`Reviewed ${review?.formattedDate}`}
                    </div>
                </div>
                <div
                    className={`transition[height] ease-in-out duration-500 overflow-hidden`}
                    style={{ height: expanded ? bounds.height : "0px" }}
                >
                    <div ref={ref} className="flex flex-col w-full">
                        <Paragraph className="my-3 text-base text-neutral-900 text-left min-h-[30px]">
                            {review?.comments}
                        </Paragraph>
                        <div className="flex flex-wrap gap-2">
                            {review?.keywordsArray?.map(
                                (keyword, index) =>
                                    !!keyword.length && (
                                        <div key={keyword + index} className="">
                                            <Badge
                                                size="xs"
                                                text={keyword}
                                                color="gray"
                                                outlined
                                                notBold={true}
                                            />
                                        </div>
                                    )
                            )}
                        </div>

                        <div className="mt-4 flex flex-wrap gap-2">
                            {review?.images?.map((img, index) => (
                                <GalleryImage
                                    key={"img" + index}
                                    src={img.url}
                                    thumb={img.thumb_url}
                                    onImageClick={() => {}}
                                    imageClass="!h-[50px] object-cover"
                                    viewOnly
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
