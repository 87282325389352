import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { handleError } from "../authentication/userSlice";

export interface ILocalPartnerState {
    isLoading: Boolean;
    localPartners: Array<any>;
    selectedLocalPartner: any | null;
    error: string;
}

const initialState: ILocalPartnerState = {
    isLoading: false,
    localPartners: [],
    selectedLocalPartner: null,
    error: "",
};

export const localPartnersSlice = createSlice({
    name: "localPartner",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setLocalPartners: (
            state,
            action: PayloadAction<{ localPartners: any }>
        ) => {
            state.localPartners = [...action.payload.localPartners];
        },
        setSelectedLocalPartner: (
            state,
            action: PayloadAction<{ selectedLocalPartner: Object | null }>
        ) => {
            state.selectedLocalPartner = action.payload.selectedLocalPartner
                ? { ...action.payload.selectedLocalPartner }
                : null;
        },
    },
});

export const {
    isLoading,
    setLocalPartners,
    setSelectedLocalPartner,
    setError,
} = localPartnersSlice.actions;
export const selectLocalPartners = (state: RootState) =>
    state.localPartner.localPartners;
export const selectedLocalPartner = (state: RootState) =>
    state.localPartner.selectedLocalPartner;

export const getLocalPartners =
    ( text: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getLocalPartners( text);
            dispatch(setLocalPartners({ localPartners: response.data }));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error))
        }
    };

export const createLocalPartner =
    (formData: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(true));
            await serverApi.addLocalPartner( formData);
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error))
            return;
        }
    };

export default localPartnersSlice.reducer;
