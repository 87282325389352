import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectedTrip } from "../../tripSlice";
import { ButtonSize, PAGE_ROWS, PAGE_SECTIONS } from "../../../../constants";
import { useEffect, useState, useMemo } from "react";
import { setCurrentSection } from "../../../layout/layoutSlice";
import Modal from "../../../../components/Modal";
import ButtonSquared from "../../../../components/ButtonSquare";
import { getTripMessages, sendMessageToAllTripUsers } from "./messagesSlice";
import SendMessageForm from "./SendMessageForm";
import TripMessages from "./TripMessages";
import { selectProfile } from "../../../authentication/userSlice";

export default function TripMessagesWrapper() {
    const [newPage, setNewPage] = useState<any>({});
    const profile = useAppSelector(selectProfile);
    const isAdmin = profile?.isAdmin;
    const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const [loadData, setLoadData] = useState(false);

    const messages = useAppSelector((state) => {
        return state.messages.messages;
    });

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_MESSAGES,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (trip) {
            dispatch(getTripMessages(trip.id, "", PAGE_ROWS, newPage?.skip));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip, dispatch, loadData]);

    const handleSendMessageClick = (e: any) => {
        setSendMessageModalOpen(true);
    };

    const handleModalClose = () => {
        setSendMessageModalOpen(false);
    };

    const handleSendMessage = async (subject: string, body: string) => {
        if (trip?.id) {
            await dispatch(
                sendMessageToAllTripUsers({ tripId: trip.id, subject, body })
            );
        }
        handleModalClose();
        setLoadData(!loadData);
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const renderSendMessageButton = useMemo(() => {
        if (
            ((trip?.role === "OWNER" || trip?.role === "ADMIN") &&
                !trip?.suspended) ||
            isAdmin
        ) {
            return (
                <div className="w-full flex justify-end mb-6">
                    <ButtonSquared
                        size={ButtonSize.SMALL}
                        label={"Add New"}
                        onClick={handleSendMessageClick}
                    />
                </div>
            );
        }
        return null;
    }, [isAdmin, trip?.role, trip?.suspended]);

    return (
        <>
            <Modal open={sendMessageModalOpen} onClose={handleModalClose}>
                <SendMessageForm
                    handleClose={handleModalClose}
                    handleSendMessage={handleSendMessage}
                />
            </Modal>
            {renderSendMessageButton}
            <TripMessages
                messages={messages}
                newPage={newPage}
                handlePaginatorChange={handlePaginatorChange}
            />
        </>
    );
}
