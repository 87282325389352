import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Badge from "../Badge/Badge";
import ButtonSquared from "../ButtonSquare";
import ImagesPreviewWithUpload from "../ImagesPreviewWithUpload/ImagesPreviewWithUpload";
import InputWithValid from "../Input";
import InputLabel from "../InputLabel";
import InputSwitch from "../InputSwitch/InputSwitch";
import StarsRating from "../StarsRating/StarsRating";
import TextareaWithValid from "../TextArea";

interface IReview {
    review?: {
        id?: number;
        title: string;
        date: string;
        comments: string;
        stars: number;
        images: { url: string; thumb_url: string }[];
        keywords: string[];
        reported: boolean;
    };
    onCancel: any;
    onSubmit: any;
    isAdmin?: boolean;
}

export default function ReviewForm(props: IReview) {
    const { review, onCancel, onSubmit } = props;
    const [imagesData, setImagesData] = useState({
        newImages: undefined,
        deletedImages: undefined,
    });
    const schema = yup
        .object({
            stars: yup.number().max(10).notRequired(),
            comments: yup.string().max(500).required("This field is required"),
            keywords: yup.string().notRequired(),
        })
        .required("This field is required");
    const {
        register,
        formState: { errors },
        setValue,
        watch,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setValue("stars", review?.stars);
        setValue("keywords", review?.keywords);
        setValue("comments", review?.comments);
        setValue("reported", review?.reported);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [review?.stars, review?.keywords, review?.comments]);

    const keywords = watch("keywords");
    const stars = watch("stars");

    const handleImagesChange = (images: any) => {
        setImagesData(images);
    };
    const handleSaveReview = (data: any) => {
        onSubmit({
            id: review?.id,
            ...data,
            keywords: data.keywords.split(","),
            newImages: imagesData?.newImages,
            deletedImages: imagesData?.deletedImages,
        });
    };

    return (
        <form
            action="#"
            method="POST"
            onSubmit={handleSubmit(handleSaveReview)}
            className="flex flex-col"
        >
            <div className="flex items-center">
                <div className="text-lg font-semibold">{review?.title}</div>
            </div>
            <div className="flex items-center mt-2">
                <StarsRating
                    className="cursor-pointer w-4"
                    rating={stars}
                    onChange={(star: number) => {
                        setValue("stars", star);
                    }}
                />
            </div>

            <div className="flex flex-col">
                <div className="my-2 text-base text-neutral-900 text-left whitespace-normal break-normal">
                    <TextareaWithValid
                        register={register}
                        label="Comments"
                        type="text"
                        defaultValue={review?.comments}
                        name="comments"
                        error={errors["comments"]?.message}
                    />
                </div>
                <div className="flex flex-wrap gap-2 my-6">
                    <InputWithValid
                        register={register}
                        label="keywords"
                        type="text"
                        name="keywords"
                        error={errors["keywords"]?.message}
                        defaultValue={keywords}
                    />
                </div>
                <div className="flex flex-wrap gap-2">
                    {keywords &&
                        keywords?.split(",").map(
                            (keyword: any, index: number) =>
                                !!keyword.trim().length && (
                                    <div key={keyword + index} className="">
                                        <Badge
                                            size="xs"
                                            text={keyword}
                                            color="gray"
                                            outlined
                                            notBold={true}
                                        />
                                    </div>
                                )
                        )}
                </div>

                <div className="mt-4 flex flex-wrap gap-2">
                    <ImagesPreviewWithUpload
                        images={review?.images}
                        onChange={handleImagesChange}
                    />
                </div>
                <div className="mt-4 flex flex-wrap gap-2">
                <InputLabel inputId={"reported"} label={"Reported"} />
                    <InputSwitch
                        id="dietaryFoodAllergies"
                        name="dietaryFoodAllergies"
                        defaultChecked={review?.reported}
                        onClick={(e: any) => {
                            setValue(
                                "reported",
                                e.target.checked
                            );
                        }}
                    />

                </div>
            </div>

            <div className="flex gap-3 justify-end px-4 py-3 text-right mt-12">
                <ButtonSquared
                    onClick={onCancel}
                    outlined
                    type="button"
                    label="Cancel"
                />
                <ButtonSquared type="submit" label="Save" />
            </div>
        </form>
    );
}
