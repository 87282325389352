import Paginator from "../../../../../components/Paginator";
import StatusBadgeComponent from "../../../../../components/StatusBadge/StatusBadgeComponent";
import Table from "../../../../../components/Table";
import {
    DocumentRequirementsStatus,
    GeneralStatus,
    PAGE_ROWS,
} from "../../../../../constants";
import { formatDate } from "../../../../../helpers";

interface ParticipantDocumentsTableProps {
    handleClickDocument: Function;
    handlePaginatorChange: Function;
    tripRequirements: any;
}
export default function ParticipantDocumentsTable({
    handleClickDocument,
    handlePaginatorChange,
    tripRequirements,
}: ParticipantDocumentsTableProps) {
    const tableHeaders = ["Name", "Description", "Deadline", "Status"];
    const tableHeadersClasses = ["", "", "", "text-center"];
    const { data, count, isLoading, currentPage, totalPages } =
        tripRequirements;

    return (
        <>
            <Table
                headers={tableHeaders}
                tableHeadersClasses={tableHeadersClasses}
                isLoading={isLoading}
            >
                {data &&
                    data.map((req: any) => {
                        return (
                            <tr
                                key={req.id}
                                onClick={() => handleClickDocument(req)}
                                className="hover:bg-neutral-50 cursor-pointer"
                            >
                                <td className="text-left max-w-[250px] truncate">
                                    {req.name}
                                </td>
                                <td className="text-left max-w-[250px] pl-6 truncate">
                                    {req.description}
                                </td>
                                <td className="text-left pl-6">
                                    {formatDate(req.deadline, "MM/DD/YY")}
                                </td>
                                <td className="text-left">
                                    <div className="flex justify-start">
                                        <span className="w-full text-left">
                                            <StatusBadgeComponent
                                                status={
                                                    req?.status ===
                                                    DocumentRequirementsStatus.PENDING
                                                        ? GeneralStatus.AWAITING_APPROVAL
                                                        : req?.status
                                                }
                                            />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
            </Table>
            <div className="w-full mt-8">
                {count > PAGE_ROWS && (
                    <Paginator
                        count={count}
                        take={PAGE_ROWS}
                        onChange={handlePaginatorChange}
                        totalPages={totalPages}
                        currentPage={currentPage}
                    />
                )}
            </div>
        </>
    );
}
