/* This example requires Tailwind CSS v2.0+ */

import {  useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ActivityType, PAGE_SECTIONS } from "../../../../../constants";
import HotelActivityForm from "./EditHotelActivityForm";
import OtherActivityForm from "./EditOtherActivityForm";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { addActivityToTrip, selectedTrip, selectedTripActivity, setActivityForm, setSelectedTripActivity, updateTripActivity } from "../../../tripSlice";
import EditRestaurantActivityForm from "./EditRestaurantActivityForm";
import ActivityFormProvider from "./context/ActivityForm.context";
import moment from "moment";

export default function EditActivityForm() {
  const { activityType } = useParams();
    const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const trip = useAppSelector(selectedTrip);
  const activity = useAppSelector(selectedTripActivity);
  const dispatch = useAppDispatch();
    const location = useLocation();
    const fromLibrary =
        new URLSearchParams(location.search).get("fromLibrary") === "true";
  const { type } = activity || {};
  const typeN = activityType || type
  const activityId = activity?.id;

  const navigateBack = (_activityId?: number) => {
    const activityId = _activityId || (!fromLibrary ? activity?.id : undefined);
    const date = searchParams.get("date");
    const formattedDate = moment.utc(date).format("yyyy-MM-DD")
      return navigate(
          `/trips/${trip.id}/itinerary${activityId ? "/" + activityId : ""}${
              fromLibrary ? `?date=${formattedDate}` : ""
          }`
      );
  };

  const onSubmit = async (activity: any) => {
    if (fromLibrary) {
      const newActivity = await dispatch(
        addActivityToTrip(Number(trip.id), { activity })
      )
      dispatch(setSelectedTripActivity({ selectedTripActivity: newActivity }));
      return navigateBack(newActivity.id);
    }

    await dispatch(
        updateTripActivity(Number(trip.id), Number(activityId), {
            activity,
        })
    );
    navigateBack();
  }

  return (
      <>
          <ActivityFormProvider>
              {typeN === ActivityType.HOTEL || typeN === ActivityType.VISIT ? (
                  <HotelActivityForm
                      type={typeN}
                      activity={activity}
                      currentSection={
                          typeN === ActivityType.VISIT || activity?.isVisit
                              ? PAGE_SECTIONS.TRIP_ITINERARY_VISIT
                              : PAGE_SECTIONS.TRIP_ITINERARY_HOTEL
                      }
                      navigateBack={navigateBack}
                      onSubmit={onSubmit}
                  />
              ) : typeN === ActivityType.RESTAURANT ? (
                  <EditRestaurantActivityForm
                      activity={activity}
                      currentSection={PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT}
                      navigateBack={navigateBack}
                      onSubmit={onSubmit}
                  />
              ) : (
                  <OtherActivityForm
                      activity={activity}
                      currentSection={PAGE_SECTIONS.TRIP_ITINERARY_OTHER}
                      navigateBack={navigateBack}
                      onSubmit={onSubmit}
                  />
              )}
          </ActivityFormProvider>
      </>
  );
}
