import { MouseEventHandler, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ButtonSquared from "../../components/ButtonSquare";
import { ButtonSize } from "../../constants";
interface INotificationItem {
    title: string;
    description: string;
    read: boolean;
    handleAccept?: MouseEventHandler<HTMLButtonElement>;
    handleDecline?: MouseEventHandler<HTMLButtonElement>;
    date: string;
    handleClick?: MouseEventHandler<HTMLDivElement>;
    isLoading?: boolean;
    showViewComments?: boolean;
}
const NotificationItem = (props: INotificationItem) => {
    const {
        description,
        title,
        read,
        handleAccept,
        handleDecline,
        handleClick,
        date,
        isLoading,
        showViewComments,
    } = props;

    const [showLoading, setShowLoading] = useState(!!(isLoading === true));

    useEffect(() => {
        setShowLoading(!!(isLoading === true));
    }, [isLoading]);

    return (
        <div
            className={`flex flex-col text-left py-5 border-b ${
                showViewComments ? "" : "cursor-pointer"
            }`}
            onClick={!showViewComments ? handleClick : undefined}
        >
            <div className="w-full flex flex-row items-start">
                <div className="mr-4">
                    <div
                        className={`rounded-full ${
                            !read ? "bg-orange-600" : "bg-transparent"
                        } w-2 h-2 mt-2`}
                    />
                </div>

                <div className="w-full flex flex-col truncate">
                    <div className="flex flex-row flex-wrap gap-5 items-center">
                        <div className="font-semibold text-base break-before-all whitespace-pre-line">
                            {showLoading ? <Skeleton width={200} /> : title}
                        </div>
                        <div className="text-xs">
                            {showLoading ? <Skeleton width={50} /> : date}
                        </div>
                    </div>
                    <div className="flex w-full flex-row items-center mt-3">
                        <div className="text-xs text-left truncate">
                            {showLoading ? (
                                <Skeleton width={400} />
                            ) : (
                                description
                            )}
                        </div>
                        {showViewComments && (
                            <div
                                onClick={handleClick}
                                className="text-orange-500 font-semibold text-xs ml-2 cursor-pointer"
                            >
                                View coments
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-4 lg:mt-0 flex gap-5">
                {handleAccept && (
                    <ButtonSquared
                        label="Decline"
                        size={ButtonSize.SMALL}
                        outlined
                        onClick={handleDecline}
                    />
                )}
                {handleDecline && (
                    <ButtonSquared
                        label="Accept"
                        size={ButtonSize.SMALL}
                        onClick={handleAccept}
                    />
                )}
            </div>
        </div>
    );
};

export default NotificationItem;
