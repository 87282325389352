import { FC, InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import RestaurantsAutocomplete from "./RestaurantsAutocomplete";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: object;
  register?: any;
  className?: string;
  control?: any;
  onTextChange?: any;
  city? : any;
  onChangeCity?: Function;
  handleSelect?: Function;
}

const RestaurantsAutocompleteWithValid: FC<InputProps> = ({
  name,
  error,
  label,
  control,
  onChange,
  onTextChange,
  ...rest
}) => {

  const handleChange = (other: any, handleOnChangeValidator: Function) => {
    if (onChange) onChange(other);
    if (handleOnChangeValidator) handleOnChangeValidator(other);
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }: any) => (
        <RestaurantsAutocomplete
          name={name}
          label={label}
          onTextChange={onTextChange}
          onChange={(a: any) => {
            handleChange(a, onChange);
          }}
          onBlur={onBlur}
          ref={ref}
          error={error && Object.keys(error).length > 0 && "Required field"}
          type="text"
          {...rest}
        />
      )}
    />
  );
};

export default RestaurantsAutocompleteWithValid;
