/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import {
    duplicateLibraryElement,
    getOrganizationLibraryElements,
    removeLibraryElement,
    selectedOrganization,
    selectOrganizationLibraryElements,
} from "../organizationSlice";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import ButtonSquared from "../../../components/ButtonSquare";
import {
    ActivityType,
    ButtonSize,
    PAGE_ROWS,
    PAGE_SECTIONS,
} from "../../../constants";
import InputSearch from "../../../components/InputSearch";
import Paginator from "../../../components/Paginator";
import Icon from "../../../assets/svg/Icon";
import { mdiArrowLeftThin } from "@mdi/js";
import LibraryElement from "./LibraryElementItem";
import Skeleton from "react-loading-skeleton";
import { setCurrentSection } from "../../layout/layoutSlice";
import { ILibraryElement } from "../../../types/library.type";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import AddLibraryElementToTrip from "./AddLibraryElementToTrip/AddLibraryElementToTrip";
import { libraryElementToActivityPayload } from "./library.util";
import EmptyBox from "../../../assets/images/EmptyBox.png";
import { selectProfile } from "../../authentication/userSlice";

export default function Library(props: any) {
    const organization = useAppSelector(selectedOrganization);
    const [search, setSearch] = useState<string>("");
    const [newPage, setNewPage] = useState<any>({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { activityType } = useParams();
    const [libraryElementIdToDelete, setLibraryElementIdToDelete] =
        useState<number>();
    const [libraryElementToAddToTrip, setLibraryElementToAddToTrip] =
        useState<ILibraryElement>();
    const libraryElements = useAppSelector(selectOrganizationLibraryElements);
    const profile = useAppSelector(selectProfile);

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.ORGANIZATION_LIBRARY,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (organization?.id) {
            fetchLibraryElements();
        }
    }, [search, organization?.id, newPage.currentPage]);

    // If activityType is not unde ActivityType enum, redirect to the library page
    useEffect(() => {
        if (
            activityType &&
            activityType !== ActivityType.HOTEL &&
            activityType !== ActivityType.RESTAURANT &&
            activityType !== ActivityType.OTHER
        ) {
            navigate(`/organizations/${organization.id}/library`);
        }
    }, [activityType]);

    const fetchLibraryElements = async () => {
        const filters: Record<string, any> = {
            search,
            take: newPage?.take || PAGE_ROWS,
            skip: newPage?.skip || 0,
            activityType: activityType || ActivityType.HOTEL,
        };
        await dispatch(
            getOrganizationLibraryElements(organization.id, filters)
        );
    };

    const handleSearchChange = (query: string) => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
        setSearch(query);
    };

    const handleAddNewLibraryElements = () => {
        navigate("new");
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const renderCreateNewButton = () =>
        (organization?.canEdit || profile?.isAdmin) && (
            <div
                className={`col-span-1 mb-6 lg:col-span-6 w-full flex justify-end`}
            >
                <ButtonSquared
                    size={ButtonSize.SMALL}
                    label={"Create Element"}
                    onClick={handleAddNewLibraryElements}
                />
            </div>
        );

    const onClickLibraryElement = (item: ILibraryElement, edit = false) => {
        // dispatch(setSelectedLibraryElement({ selectedLibraryElement: item }));
        const link = edit ? `${item.id}/edit` : `${item.id}`;
        navigate(link);
    };

    const handleClickMenu = (key: string, item: ILibraryElement) => {
        const actions: Record<string, Function> = {
            "add-to-trip": () => {
                const elementToAddToTrip =
                    libraryElementToActivityPayload(item);
                setLibraryElementToAddToTrip(elementToAddToTrip);
            },
            edit: () => onClickLibraryElement(item, true),
            duplicate: async () => {
                await dispatch(
                    duplicateLibraryElement(item.organizationId, item.id)
                );
                fetchLibraryElements();
            },
            delete: () => {
                setLibraryElementIdToDelete(item.id);
            },
        };

        return actions[key]() || null;
    };

    const goBack = () => {
        navigate(`/organizations/${organization.id}/library`);
    };

    const title = (() => {
        switch (activityType) {
            case ActivityType.HOTEL:
                return "Hotels";
            case ActivityType.RESTAURANT:
                return "Restaurants";
            case ActivityType.OTHER:
                return "Others";
            default:
                return "Library elements";
        }
    })();

    const isLibraryEmpty = useMemo(
        () =>
            !organization?.isLoading &&
            !libraryElements.isLoading &&
            !libraryElements?.data?.length,
        [organization, libraryElements]
    );

    return (
        <>
            <ModalConfirmation
                open={!!libraryElementIdToDelete}
                title="Delete library element"
                description="Are you sure you want to delete this library element?"
                handleSubmit={async () => {
                    await dispatch(
                        removeLibraryElement(
                            organization.id,
                            Number(libraryElementIdToDelete)
                        )
                    );
                    fetchLibraryElements();
                    setLibraryElementIdToDelete(undefined);
                }}
                handleCancel={() => setLibraryElementIdToDelete(undefined)}
            />
            <AddLibraryElementToTrip
                open={!!libraryElementToAddToTrip}
                setOpen={() => setLibraryElementToAddToTrip(undefined)}
                activityElement={libraryElementToAddToTrip}
            />
            <div
                className="flex items-center sm:text-base text-orange-500 font-semibold cursor-pointer mb-1 w-fit"
                onClick={goBack}
            >
                <Icon
                    materialIcon={mdiArrowLeftThin}
                    className="h-7 text-orange-500"
                />
                Go back to library elements
            </div>
            <div className="text-xl font-semibold my-5 text-left">{title}</div>
            <div className="grid grid-cols-1 lg:grid-cols-12 items-end gap-6 lg:gap-10">
                <div className={`col-span-1 lg:col-span-6`}>
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>
                {/* <div className="col-span-1 lg:col-span-2 mb-6">
                    <button className="bg-neutral-200 border-neutral-300 text-neutral-500 inline-flex items-center py-1 px-3 text-md font-semibold rounded-md">
                        <Icon
                            className="w-5 h-5 text-neutral-500 mr-1"
                            materialIcon={mdiFilterOutline}
                        />
                        Filters
                    </button>
                </div> */}
                {renderCreateNewButton()}
            </div>
            <div className="flex flex-col gap-5 w-full">
                {!libraryElements.isLoading &&
                    libraryElements &&
                    libraryElements?.data?.map((item: any) => (
                        <LibraryElement
                            key={item.id}
                            item={item}
                            handleClickMenu={handleClickMenu}
                            onClick={onClickLibraryElement}
                        />
                    ))}
                {libraryElements.isLoading &&
                    [...Array(5)].map((_, index) => (
                        <Skeleton
                            key={index}
                            height={75}
                            className="!rounded-lg"
                        />
                    ))}
            </div>
            {libraryElements?.count > PAGE_ROWS && (
                <div className="w-full mt-8">
                    <Paginator
                        count={libraryElements?.count}
                        take={libraryElements?.take}
                        onChange={handlePaginatorChange}
                        totalPages={libraryElements?.totalPages}
                        currentPage={libraryElements?.currentPage}
                    />
                </div>
            )}
            {isLibraryEmpty && (
                <div className="flex flex-col justify-center items-center h-full gap-3 flex-1">
                    <img
                        alt="empty"
                        src={EmptyBox}
                        className="h-28 m-0 mb-6"
                    />
                    <div className="text-gray-500">Your library is empty.</div>
                    <div className="text-gray-500 font-semibold w-56">
                        Create reusable elements for your future trips.
                    </div>
                    <div
                        className="text-primary_orange-500 font-semibold cursor-pointer"
                        onClick={handleAddNewLibraryElements}
                    >
                        Create new element
                    </div>
                </div>
            )}
        </>
    );
}
