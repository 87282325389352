import { FC, HTMLAttributes } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface ITable extends HTMLAttributes<HTMLTableElement> {
    headers: Array<string>;
    isLoading: boolean;
    tableHeadersClasses?: string[];
    loadingRows?: number;
    headerClass?: string;
}
const TableLoading = (props: any) => {
    const { rows, headers } = props;
    return (
        <>
            {[...Array(rows)].map((r, index) => (
                <TableRowLoading
                    key={`loading-${r}-${index}`}
                    headers={headers}
                />
            ))}
        </>
    );
};
const TableRowLoading = (props: any) => {
    const { headers } = props;
    return (
        <tr>
            {headers.map((h: string, index: number) => (
                <td key={`loading-row-${h}-${index}`}>
                    <Skeleton />
                </td>
            ))}
        </tr>
    );
};
const Table: FC<ITable> = ({
    headers,
    isLoading,
    tableHeadersClasses,
    loadingRows,
    children,
    headerClass,
    ...rest
}) => {
    return (
        <table {...rest} className="w-full table-auto">
            <thead className={`bg-white ${headerClass}`}>
                <tr>
                    {headers.map((header: string, index: number) => (
                        <th
                            scope="col"
                            key={`th_${header}_${index}`}
                            className={
                                tableHeadersClasses &&
                                tableHeadersClasses[index]
                                    ? tableHeadersClasses[index]
                                    : ""
                            }
                        >
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {isLoading && (
                    <TableLoading headers={headers} rows={loadingRows || 5} />
                )}
                {!isLoading && children}
            </tbody>
        </table>
    );
};

export default Table;
