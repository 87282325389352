import InsightDetailItem from "../../../../../components/InsightDetailItem";
import {
    ROOM_VIEW_TYPE,
    RoomViewTypeOptions,
} from "../../../../../../../../constants/insight";
import SelectInput from "../../../../../../../../components/InputSelect/SelectInput";
import Input from "../../../../../../../../components/Input";
import Button from "../../../../../../../../components/ButtonSquare";
import { InsightImages } from "../../../../../components/InsightImages";
import InputSwitch from "../../../../../../../../components/InputSwitch/InputSwitch";
import TextAreaInput from "../../../../../../../../components/TextArea";
import InsightFormContainer from "../../components/InsightFormContainer";
import InsightInputContainer from "../../components/InsightInputContainer";

interface RoomItemProps {
    showForm: boolean;
    register: any;
    errors: any;
    activeItem?: any;
    handleCancel: () => void;
    setType: any;
    handleUploadFile: (e: any) => void;
    setValue: any;
    watch: (name: string) => any;
    isValid: boolean;
}
export const FormRoomItem = ({
    showForm,
    register,
    errors,
    activeItem,
    handleCancel,
    setType,
    handleUploadFile,
    setValue,
    watch,
    isValid,
}: RoomItemProps) => {
    const viewValue = watch("view");
    if (!showForm) return null;
    return (
        <>
            <InsightImages
                handleUploadFile={handleUploadFile}
                images={activeItem?.images}
            />
            <div className="col-span-6 sm:col-span-3 mt-3">
                <InsightDetailItem label="Rooms types">
                    <InsightFormContainer className="gap-8">
                        <InsightInputContainer>
                            <Input
                                register={register}
                                label="Category"
                                type="text"
                                markRequired
                                defaultValue={activeItem?.category}
                                name="category"
                                error={errors["category"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <Input
                                register={register}
                                label="Number/Name"
                                type="text"
                                defaultValue={activeItem?.name}
                                name="name"
                                error={errors["name"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <SelectInput
                                onChange={(value: string) =>
                                    setType(value, "view", {})
                                }
                                label="View"
                                options={RoomViewTypeOptions}
                                name="view"
                                defaultValue={activeItem?.view}
                                error={errors["view"]?.message}
                            />
                        </InsightInputContainer>
                        <InsightInputContainer>
                            {viewValue === ROOM_VIEW_TYPE.OTHER && (
                                <Input
                                    register={register}
                                    label="Other view"
                                    type="text"
                                    defaultValue={activeItem?.viewOther}
                                    name="viewOther"
                                    error={errors["viewOther"]?.message}
                                />
                            )}
                        </InsightInputContainer>
                        <InsightInputContainer>
                            <div className="text-neutral-600 text-base font-medium w-fit mb-1">
                                Connecting rooms
                            </div>
                            <InputSwitch
                                name="connectingRooms"
                                defaultChecked={activeItem?.connecting}
                                error={errors["connecting"]?.message}
                                onClick={(e: any) => {
                                    setValue("connecting", e.target.checked, { shouldDirty: true });
                                }}
                            />
                        </InsightInputContainer>
                    </InsightFormContainer>
                    <InsightFormContainer className="mt-8">
                        <InsightInputContainer>
                            <TextAreaInput
                                register={register}
                                label="General comments"
                                type="text"
                                defaultValue={activeItem?.comments}
                                name="comments"
                                error={errors["comments"]?.message}
                            />
                        </InsightInputContainer>
                    </InsightFormContainer>
                </InsightDetailItem>
            </div>
            <div className="p-8 text-right flex lg:justify-end w-full gap-4">
                <Button onClick={handleCancel} outlined label="Cancel" />
                <Button disabled={!isValid} type="submit" label={"Save"} />
            </div>
        </>
    );
};
