import { useSelector } from "react-redux";
import { selectActivityInsight } from "../../../../../../tripSlice";
import FormRestaurantExperience from "./FormRestaurantExperience";
import DetailColumn from "../../components/InsightDetailColumn";
import DetailItem from "../../components/InsightDetailItem";
import {
    PAGE_SECTIONS,
    restaurantAmbianceOptions,
    restaurantCuisineOptions,
} from "../../../../../../../../constants";
import {
    joinRestaurantOptions,
    restaurantDetailComments,
} from "./restaurantExperience.util";
import InsightDetailWrapper from "../../components/InsightDetailWrapper";
import InsightCommentBlock from "../../components/InsightCommentBlock";
import Icon from "../../../../../../../../assets/svg/Icon";

export default function DetailsInsightReview() {
    const activityInsight = useSelector(selectActivityInsight);
    const restaurantInsight = activityInsight?.restaurantInsight;

    const experienceFields = restaurantDetailComments.every(
        (comment) => !restaurantInsight?.[comment.key]
    ) && !restaurantInsight?.cuisine?.length && !restaurantInsight?.ambiance?.length && !restaurantInsight?.chef;

    return (
        <InsightDetailWrapper
            title={
                <div className="flex flex-grow gap-2 sm:text-xl text-lg items-center text-neutral-900 font-semibold">
                    <Icon icon={"LockIcon"} className={`w-5 h-5 `} />
                    Restaurant experience
                </div>
            }
            form={FormRestaurantExperience}
            insight={restaurantInsight}
            showFormCondition={experienceFields}
            currentSection={
                PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_DETAILS
            }
        >
            {restaurantDetailComments.map(
                (comment) =>
                    restaurantInsight?.[comment.key] && (
                        <DetailColumn className="mt-4" key={comment.key}>
                            <InsightCommentBlock title={comment.label}>
                                {restaurantInsight?.[comment.key]}
                            </InsightCommentBlock>
                        </DetailColumn>
                    )
            )}
            {restaurantInsight?.cuisine &&
                !!restaurantInsight?.cuisine.length && (
                    <DetailColumn className="mt-4">
                        <DetailItem label="Cuisine types:">
                            {joinRestaurantOptions(
                                restaurantCuisineOptions,
                                restaurantInsight?.cuisine
                            )}
                        </DetailItem>
                    </DetailColumn>
                )}
            {restaurantInsight?.ambiance &&
                !!restaurantInsight?.ambiance.length && (
                    <DetailColumn className="mt-4">
                        <DetailItem label="Ambiance:">
                            {joinRestaurantOptions(
                                restaurantAmbianceOptions,
                                restaurantInsight?.ambiance
                            )}
                        </DetailItem>
                    </DetailColumn>
                )}
            {restaurantInsight?.chef && (
                <DetailColumn className="mt-4">
                    <DetailItem label="Chef Name:">
                        {restaurantInsight?.chef}
                    </DetailItem>
                </DetailColumn>
            )}
        </InsightDetailWrapper>
    );
}
