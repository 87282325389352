import { PAGE_SECTIONS } from "../../../../../../../../constants";
import { addTripActivityRestaurantInsight } from "../../../../../../../hotelInsight/hotelInsightSlice";
import InsightNotesView from "../../components/InsightNotesView/InsightNotesView";

export default function RestaurantNotesInsightReview() {
    const currentSection = PAGE_SECTIONS.TRIP_ITINERARY_RESTAURANT_PRIVATE_REVIEW_DETAILS;

    return (
        <InsightNotesView
            insightKey="restaurantInsight"
            currentSection={currentSection}
            getDefaultValues={(insight) => {
                const { contactInsights, ...initialRestaurantInsight } =
                    insight || {};
                return initialRestaurantInsight;
            }}
            addInsightNotes={addTripActivityRestaurantInsight}
        />
    );
}
