import { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import AllUsers from "./AllUsers";

export default function UsersAdminHome(props: any) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_USERS })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AllUsers />;
}
