import { Route } from "react-router-dom";
import OrganizationsHome from "../features/organizations";
import OrganizationsList from "../features/organizations/OrganizationsList";
import OrganizationHome from "../features/organizations/Organization";
import OrganizationForm from "../features/organizations/Organization/OrganizationForm";
import OrganizationDetail from "../features/organizations/Organization/OrganizationDetail";
import OrganizationUsers from "../features/organizations/OrganizationUsers";
import OrganizationUserProfile from "../features/organizations/OrganizationUsers/OrganizationUserProfile";
import OrganizationTrips from "../features/organizations/Trips";
import OrganizationLibrary from "../features/organizations/Library/OrganizationLibrary";
import Library from "../features/organizations/Library";
import NewLibraryElement from "../features/organizations/Library/NewLibraryElement";
import EditLibraryElement from "../features/organizations/Library/EditLibraryElement";
import LibraryElement from "../features/organizations/Library/LibraryElement";
import { LibraryElementDetails } from "../features/organizations/Library/LibraryElementDetails";
import Chat from "../features/organizations/Chat";

export const OrganizationRoutes = (
    <>
        <Route path="/organizations" element={<OrganizationsHome />}>
            <Route path="" element={<OrganizationsList />} />
            <Route path="new" element={<OrganizationForm />} />
        </Route>
        <Route
            path="/organizations/:organizationId"
            element={<OrganizationHome />}
        >
            <Route path="" element={<OrganizationDetail />} />
            <Route path="members" element={<OrganizationUsers />} />
            <Route
                path="members/:userId"
                element={<OrganizationUserProfile />}
            />
            <Route path="trips" element={<OrganizationTrips />} />
            <Route path="library" element={<OrganizationLibrary />} />
            <Route path="chat" element={<Chat />} />
            <Route path="edit" element={<OrganizationForm />} />
            <Route path="library/:activityType" element={<Library />} />
            <Route
                path="library/:activityType/new"
                element={<NewLibraryElement />}
            />
            <Route path="library/:activityType/:activityId" element={<LibraryElement/>}>
                <Route
                    path=""
                    element={<LibraryElementDetails />}
                />
                <Route path="edit" element={<EditLibraryElement />} />
            </Route>
        </Route>
    </>
);
