import { useState, useEffect } from "react";

// Recuerda siempre usar la palabra "use" al principio de cada custom hook
const useActivityContact = (defaultContacts: Array<any>) => {
    const [contactsModalOpen, setContactModalOpen] = useState(false);
    const [contacts, setContacts] = useState<Array<any>>([]);
    const [selectedContact, setSelectedContact] = useState<any | null>(null);

    useEffect(() => {
        setContacts(defaultContacts || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContactToggle = (open: boolean) => {
        setContactModalOpen(open);
    };

    const handleContactSubmit = (contact: any) => {
        if (selectedContact !== null)
            handleContactEdit(contact, selectedContact);
        else handleContactNew(contact);
        handleContactToggle(false);
        return;
    };

    const handleContactNew = (contact: any) => {
        const newContacts = contacts ? [...contacts, contact] : [contact];
        setContacts(newContacts);
    };

    const handleContactEdit = (contact: any, index: number) => {
        const newContacts = [...contacts];
        newContacts[index] = contact;
        setContacts(newContacts);
        setSelectedContact(null);
    };

    const handleContactEditClick = (index: number) => {
        setSelectedContact(index);
        handleContactToggle(true);
    };

    const handleContactRemoveClick = (index: number) => {
        const newContacts = [...contacts];
        newContacts.splice(index, 1);
        setContacts(newContacts);
        handleContactToggle(false);
    };

    return {
        contacts,
        contactsModalOpen,
        handleContactSubmit,
        handleContactEditClick,
        handleContactRemoveClick,
        handleContactToggle,
        selectedContact,
        setSelectedContact,
        setContacts,
    };
};

export default useActivityContact;
