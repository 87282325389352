import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { handleError } from "../authentication/userSlice";

export interface OthersState {
    isLoading: Boolean;
    others: Array<any>;
    selectedOther: any | null;
    error: string;
}

const initialState: OthersState = {
    isLoading: false,
    others: [],
    selectedOther: null,
    error: "",
};

export const othersSlice = createSlice({
    name: "others",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string }>) => {
            state.error = action.payload.error;
        },
        setOthers: (state, action: PayloadAction<{ others: any }>) => {
            state.others = [...action.payload.others];
        },
        setSelectedOther: (
            state,
            action: PayloadAction<{ selectedOther: Object | null }>
        ) => {
            state.selectedOther = action.payload.selectedOther
                ? { ...action.payload.selectedOther }
                : null;
        },
    },
});

export const { isLoading, setOthers, setSelectedOther, setError } =
    othersSlice.actions;
export const selectOthers = (state: RootState) => state.others.others;
export const selectedOther = (state: RootState) => state.others.selectedOther;
export const selectedOrganization = (state: RootState) =>
    state.organization?.selectedOrganization;
export const selectedOrganizationUsers = (state: RootState) =>
    state.organization?.selectedOrganization?.users;

export const getOthers =
    (text: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getOthers(text);
            dispatch(setOthers({ others: response.data }));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const createOther =
    (formData: any): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addOther(formData);
            dispatch(setSelectedOther({selectedOther: response.data}));
            dispatch(isLoading(false));
            return response.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const updateOther =
    (otherId: number, formData: any): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.updateOther(otherId, formData);
            dispatch(setSelectedOther({selectedOther: response.data}));
            dispatch(isLoading(false));
            return response.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export default othersSlice.reducer;
