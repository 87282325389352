import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { PAGE_SECTIONS } from "../../../../constants";
import { useAuthStateContext } from "../../../../context/Auth";
import { setCurrentSection } from "../../../layout/layoutSlice";

import { selectedTrip } from "../../tripSlice";
import { useChatForm } from "../../../chat/hooks/useChat.hook";
import { UserChatsList } from "../../../chat/components/UserChatsList";

export default function TripChat() {
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const { onSelectedChat } = useChatForm();

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_CHAT,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (trip) {
            onSelectedChat(`trip-${trip.id}`);
        }
    }, [trip]);

    return (
        <div>
            <UserChatsList leftComponent={false} />
        </div>
    );
}
