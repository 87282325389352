import { useMemo } from "react";
import Icon from "../../../assets/svg/Icon";
import DotsMenu from "../../../components/DotsMenu";
import ListWithPagination from "../../../components/ListWithPagination";
import { ActivityIcon } from "../../../constants";
import { ReviewsDotMenuOptions } from "../../../constants/admin";
import { normalizeReviews } from "../../../helpers";
interface IAllReviews {
    reviews: any;
    handleClickDotsMenu: Function;
    handlePaginatorChange: Function;
}
const AllReviewsList = (props: IAllReviews) => {
    const { reviews, handleClickDotsMenu, handlePaginatorChange } = props;

    const reviewsNormalized = useMemo(() => {
        const dataN = (reviews?.data || [])?.map((review: any) => {
            return { ...review, ...normalizeReviews(review) };
        });
        return { ...reviews, data: dataN };
    }, [reviews?.data]);

    const renderItem = ({ item: review }: any) => (
        <div className="py-4 border-b border-b-gray-300">
            <div className="flex items-center justify-between">
                <div className="text-left flex flex-row items-center">
                    {review?.type && (
                        <Icon
                            className="w-[24px] mr-4 text-orange-600"
                            icon={
                                ActivityIcon[
                                    review?.type as keyof typeof ActivityIcon
                                ]
                            }
                        />
                    )}
                    <div className="max-w-[200px] truncate text-gray-600">
                        {review?.title}
                    </div>
                </div>
                <div className="text-left">
                    <DotsMenu
                        options={ReviewsDotMenuOptions}
                        handleClickMenu={(k: any) => {
                            handleClickDotsMenu(k, review);
                        }}
                    />
                </div>
            </div>

            <div className="text-left text-xs text-gray-400">
                {review?.formattedDate}
            </div>
            <div className="text-left max-w-[200px] truncate text-xs text-gray-400">
                {review?.location}
            </div>
            <div className="text-left max-w-[200px] truncate text-xs text-gray-400">
                {review?.user}
            </div>
        </div>
    );
    return (
        <ListWithPagination
            renderItem={renderItem}
            items={reviewsNormalized}
            handlePaginatorChange={handlePaginatorChange}
            itemKey={(review: any) => {
                return `adminReviewsItem_${review?.id}`;
            }}
        />
    );
};

export default AllReviewsList;
