import placeholder from "../../../../assets/images/placeholder.png";
import Icon from "../../../../assets/svg/Icon";
import { mdiArrowRightThin } from "@mdi/js";
import {
    TRIP_DEFAULT_IMAGES,
} from "../../../../constants";
import moment from "moment";

interface ILibraryTripCardProps {
    trip: any;
    onClick: () => void;
    isSelected: boolean;
}

const LibraryTripCard = ({
    trip,
    onClick,
    isSelected,
}: ILibraryTripCardProps) => {
    const coverImage =
        trip?.coverImage ||
        (
            trip?.coverImage_default &&
            TRIP_DEFAULT_IMAGES.find((i) => i.id === trip?.coverImage_default)
        )?.src;

    const startDate = moment.utc(trip.startDate).format("MM/DD/YYYY");
    const endDate = moment.utc(trip.endDate).format("MM/DD/YYYY");
    return (
        <div
            className={`flex flex-row items-center w-full ${
                isSelected ? "bg-rose" : "bg-neutral-100"
            } rounded-lg border-2 ${
                isSelected ? "border-primary_orange-500" : "border-neutral-100"
            } p-3 gap-3 cursor-pointer hover:text-primary_orange-500 hover:bg-rose hover:border-text-primary_orange-500`}
            onClick={onClick}
        >
            <img
                src={coverImage || placeholder}
                className="rounded-xl w-11 h-11 object-cover"
                alt=""
            />
            <div className="flex flex-col">
                <div className="text-base font-semibold">{trip.title}</div>
                <div className="flex gap-1 items-center text-sm text-neutral-400">
                    <div>{startDate}</div>
                    <Icon
                        materialIcon={mdiArrowRightThin}
                        className="text-neutral-400 w-4 h-4"
                    />
                    <div>{endDate}</div>
                </div>
            </div>
        </div>
    );
};

export default LibraryTripCard;