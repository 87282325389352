import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import TripCard from "../../trips/Trip/TripCard";
import ShowMoreButton from "../../../components/ShowMoreButton";

export default function HomeTripsCard(props: {
    title: string;
    trips: any[];
    narrowCard?: boolean;
    isLoading?: boolean;
    showMoreClick?: any;
}) {
    const { title, trips, isLoading, narrowCard, showMoreClick } = props;
    const navigate = useNavigate();
    const openTrip = (tripId: number) => {
        navigate(`/trips/${tripId}`);
    };

    if (isLoading) {
        return (
            <Skeleton
                className={`rounded-xl ${
                    narrowCard ? "min-h-[300px]" : "min-h-[432px]"
                } w-full h-full`}
            />
        );
    }

    return (
        <div
            className={`w-full flex flex-col bg-white shadow-card rounded-xl pb-2 pt-4 px-6 ${
                narrowCard ? "lg:min-h-[300px]" : "lg:min-h-[432px]"
            }`}
        >
            <div className="text-lg text-neutral-900 text-left font-semibold mb-4">
                {title}
            </div>
            {trips && !trips?.length && (
                <div className="mt-12 w-full text-center text-sm text-neutral-400">
                    There are no trips to show.
                </div>
            )}
            <div className="grid grid-cols-2 text-left gap-8 w-full h-full">
                {trips?.map((trip) => {
                    return (
                        <div
                            key={`tripHome_${trip?.id}`}
                            className="text-left flex-grow col-span-2 md:col-span-1"
                        >
                            <TripCard
                                trip={trip}
                                onClick={() => {
                                    return openTrip(trip?.id);
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <ShowMoreButton onClick={showMoreClick} />
        </div>
    );
}
