import React, { useMemo } from "react";
import Icon from "../../../assets/svg/Icon";
import { atLeast1Number, atLeast1UpperCaseLetter } from "../../../helpers";

const PasswordItem = ({ children, valid, disabled }: any) => {
    const currentIcon = disabled || valid ? "CheckIcon" : "CloseIcon";
    const textColor = disabled ? "text-gray-400" : "text-gray-600";
    const iconColor = disabled
        ? "text-gray-400"
        : valid
        ? "text-green-600"
        : "text-red-600";

    const textClass = `pl-2 text-xs font-normal ${textColor}`;
    const iconClass = `${iconColor} w-4 h-4`;
    return (
        <div className="flex flex-row items-center justify-start">
            <Icon icon={currentIcon} className={iconClass} />
            <div className={textClass}>{children}</div>
        </div>
    );
};

const PasswordRequirements = (props: any) => {
    const { password = "" } = props;
    const disabled = !(
        typeof password !== "undefined" &&
        password !== null &&
        password.length > 0
    );
    const passLengthValid = password?.length >= 8;
    const passUpperCaseValid = useMemo(() => {
        return atLeast1UpperCaseLetter(password);
    }, [password]);
    const passNumberValid = useMemo(() => {
        return atLeast1Number(password);
    }, [password]);
    return (
        <div>
            <PasswordItem disabled={disabled} valid={passUpperCaseValid}>
                At least 1 Upper Case letter (A-Z)
            </PasswordItem>
            <PasswordItem disabled={disabled} valid={passNumberValid}>
                At least 1 number (0-9)
            </PasswordItem>
            <PasswordItem disabled={disabled} valid={passLengthValid}>
                At least 8 characters{" "}
            </PasswordItem>
        </div>
    );
};
export default PasswordRequirements;
