import { useEffect, useState } from "react";
import server from "../../../../api/server";
import { useNavigate } from "react-router";
import Loader from "../../../../components/Loader";
import FamguruLogo from "../../../../assets/svg/LogoSquared.svg";
import FamguruText from "../../../../assets/svg/Famguru.svg";
import { useSearchParams } from "react-router-dom";
export const DeleteAccount = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [error, setError] = useState(false);
    const token = searchParams.get("token");
    useEffect(() => {
        const getData = async () => {
            try {
                await server.removeAccount(token || "");
                navigate("/profile/deleted");
            } catch (error) {
                setError(true);
            }
        };
        getData();
    }, []);
    return error ? (
        <div>
            <div className="w-full min-h-screen mb-12 flex flex-col items-center justify-center">
                <div className="flex flex-row items-center gap-4">
                    <a href="/">
                        <img src={FamguruLogo} alt="" />
                    </a>
                    <a href="/">
                        <img src={FamguruText} alt="" />
                    </a>
                </div>
                <div className="mt-10 text-2xl text-center font-semibold text-orange-800">
                    There has an error while
                </div>
                <div className="text-2xl text-center font-semibold text-orange-800">
                    proccessing your request
                </div>
                <div className="mt-6 text-base text-center font-semibold text-neutral-400">
                    Please try again in a few minutes.
                </div>
            </div>
        </div>
    ) : (
        <div>
            <div className="w-full min-h-screen mb-12 flex flex-col items-center justify-center">
                <div className="w-fit">
                    <Loader />
                </div>
            </div>
        </div>
    );
};
