import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { IRestaurantBody, IRestaurantUpdateBody } from "../../constants";
import { handleError } from "../authentication/userSlice";
export interface RestaurantState {
    isLoading: Boolean;
    restaurants: Array<any>;
    selectedRestaurant: any | null;
}

const initialState: RestaurantState = {
    isLoading: false,
    restaurants: [],
    selectedRestaurant: null,
};

export const restaurantSlice = createSlice({
    name: "restaurant",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setRestaurants: (
            state,
            action: PayloadAction<{ restaurants: Array<any> }>
        ) => {
            state.restaurants = [...action.payload.restaurants];
        },
        setSelectedRestaurant: (
            state,
            action: PayloadAction<{ selectedRestaurant: Object | null }>
        ) => {
            state.selectedRestaurant = action.payload.selectedRestaurant
                ? { ...action.payload.selectedRestaurant }
                : null;
        },
    },
});

export const { isLoading, setRestaurants, setSelectedRestaurant } =
    restaurantSlice.actions;
export const selectRestaurants = (state: RootState) =>
    state.restaurant?.restaurants;
export const selectedRestaurant = (state: RootState) =>
    state.restaurant?.selectedRestaurant;

export const getRestaurants =
    (searchText: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getRestaurants(searchText);
            dispatch(setRestaurants({ restaurants: response.data }));
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const createRestaurant =
    (data: IRestaurantBody): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.addRestaurant(data);
            dispatch(
                setSelectedRestaurant({ selectedRestaurant: response.data })
            );
            dispatch(isLoading(false));
            return response.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const updateRestaurant =
    (
        restaurantId: number,
        fields: IRestaurantUpdateBody
    ): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.updateRestaurant(
                restaurantId,
                fields
            );
            dispatch(
                setSelectedRestaurant({ selectedRestaurant: response.data })
            );
            dispatch(isLoading(false));
            return response.data;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export const getRestaurant =
    (restaurantId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(isLoading(true));
            const response = await serverApi.getRestaurant(restaurantId);
            dispatch(
                setSelectedRestaurant({ selectedRestaurant: response.data })
            );
            dispatch(isLoading(false));
            return;
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(handleError(error));
        }
    };

export default restaurantSlice.reducer;
