import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import ReviewForm from "../../../components/Reviews/ReviewForm";
import { PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import {
    deleteReviewAdmin,
    getAdminReviews,
    updateReviewAdmin,
} from "../adminSlice";
import AllReviews from "./AllReviews";

export default function ReviewsAdminHome(props: any) {
    const dispatch = useAppDispatch();
    const [selectedReview, setSelectedReview] = useState();
    const [reviewToDelete, setReviewToDelete] = useState<any>();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const reviewsListRef: any = useRef();

    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ADMIN_REVIEWS })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchReviews = (filters: any) => {
        if (filters) {
            dispatch(
                getAdminReviews(
                    filters.take,
                    filters.skip,
                    filters.type,
                    filters.search,
                    filters.startDate,
                    filters.endDate
                )
            );
        }
    };

    const handleSubmitReview = (data: any) => {
        dispatch(updateReviewAdmin(data)).then((success) => {
            success && setSelectedReview(undefined);
        });
    };

    const handleDeleteReview = () => {
        dispatch(deleteReviewAdmin(reviewToDelete?.id)).then(() => {
            reviewsListRef?.current?.reFetch();
        });
        setDeleteModalOpen(false);
    };

    return (
        <>
            <ModalConfirmation
                open={deleteModalOpen}
                title="Are you sure?"
                description={`You are about to remove a review of ${reviewToDelete?.title}`}
                handleCancel={() => {
                    setDeleteModalOpen(false);
                }}
                handleSubmit={handleDeleteReview}
            />
            {selectedReview ? (
                <ReviewForm
                    review={selectedReview}
                    onCancel={() => {
                        setSelectedReview(undefined);
                    }}
                    onSubmit={handleSubmitReview}
                    isAdmin
                />
            ) : (
                <AllReviews
                    ref={reviewsListRef}
                    fetchData={fetchReviews}
                    onSelectReview={setSelectedReview}
                    onDelete={(review: any) => {
                        setReviewToDelete(review);
                        setDeleteModalOpen(true);
                    }}
                />
            )}
        </>
    );
}
