import { MouseEventHandler, useMemo } from "react";
import Avatar from "../../../../components/Avatar";
import StatusBadgeComponent from "../../../../components/StatusBadge/StatusBadgeComponent";
import Table from "../../../../components/Table";
import { UserRoleOnTrip } from "../../../../constants";

const ParticipantRole = {
    [UserRoleOnTrip.ADMIN]: "Admin",
    [UserRoleOnTrip.MEMBER]: "Advisor",
    [UserRoleOnTrip.OWNER]: "Owner",
    [UserRoleOnTrip.VIEWER]: "Viewer",
};

const tableHeadersClasses = ["", "", "", "text-center", "text-center"];

interface IParticipantsTable {
    isLoading: boolean;
    members: Array<any> | undefined | null;
    handleClickMember: MouseEventHandler;
    requirements?: any;
    canEdit?: boolean;
    showDocuments: boolean;
}
export const ParticipantsTable = ({
    isLoading,
    members,
    handleClickMember,
    requirements,
    canEdit,
    showDocuments,
}: IParticipantsTable) => {
    const tableHeaders = useMemo(() => {
        return ["PARTICIPANT", "AGENCY", "ROLE", "STATUS"].concat(
            showDocuments ? ["DOCUMENTATION"] : []
        );
    }, [showDocuments]);
    return (
        <Table
            tableHeadersClasses={tableHeadersClasses}
            headers={tableHeaders}
            isLoading={isLoading}
            loadingRows={3}
            className=""
            headerClass="bg-white"
        >
            {members?.map((prt) => {
                return (
                    <tr
                        key={prt?.userEmail}
                        onClick={() => {
                            return handleClickMember(prt);
                        }}
                        className="hover:bg-neutral-50 cursor-pointer"
                    >
                        <td className="text-neutral-900 text-sm flex pl-6">
                            <div className="items-baseline">
                                <Avatar
                                    src={
                                        !!prt?.user?.avatar_thumb?.length
                                            ? prt?.user?.avatar_thumb
                                            : prt?.user?.avatar
                                    }
                                    className="w-6 h-6"
                                />
                            </div>

                            <span className="text-left text-sm ml-4">
                                {prt.user?.firstName
                                    ? `${prt.user?.firstName} ${prt.user?.lastName}`
                                    : prt?.userEmail}
                            </span>
                        </td>
                        <td className="text-neutral-900 text-sm pl-6">
                            {prt.user?.agency}
                        </td>
                        <td className="text-neutral-900 text-sm pl-6">
                            {ParticipantRole[prt.role as UserRoleOnTrip]}
                        </td>
                        <td className="text-neutral-900 text-sm">
                            <StatusBadgeComponent status={prt.status} />
                        </td>
                        {!!showDocuments && (
                            <td className="text-neutral-900 text-sm text-center">
                                {prt?.requirementFilesCount || 0}/
                                {requirements || 0}
                            </td>
                        )}
                    </tr>
                );
            })}
        </Table>
    );
};
