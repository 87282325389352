import Icon from "../../../assets/svg/Icon";
import placeholder from "../../../assets/images/placeholder.png";
import Badge from "../../../components/Badge/Badge";
import { presenterTrip } from "../../../bussiness/trip";
import { useMemo } from "react";
import { Paragraph } from "../../../components/Paragraph/Paragraph";
import { mdiArrowRight } from "@mdi/js";
interface TripCardProps {
    trip: any;
    onClick: any;
    pending?: boolean;
}

export default function TripCard(props: TripCardProps) {
    const { trip, onClick, pending } = props;

    const tripPresenter = useMemo(() => {
        return trip && presenterTrip(trip);
    }, [trip]);

    return (
        <div
            onClick={onClick}
            className="flex flex-col text-left w-full rounded-xl h-[270px] cursor-pointer hover:bg-neutral-50 relative"
        >
            <div className="h-[150px] overflow-hidden rounded-xl bg-gray-200">
                <img
                    src={tripPresenter?.coverImage || placeholder}
                    className="rounded-xl min-h-[180px] centered-image"
                    width={"100%"}
                    alt=""
                />
            </div>
            {pending && (
                <div className="absolute right-1 top-1 z-20">
                    <Badge text="Awaiting Confirmation" color="orange" />
                </div>
            )}
            <div className="mt-2 flex flex-col flex-grow gap-y-2">
                <div className="font-semibold text-neutral-900 text-lg truncate">
                    {tripPresenter?.title}
                </div>

                <span className="flex flex-row items-center text-sm text-neutral-600">
                    {tripPresenter?.startDate}
                    <Icon materialIcon={mdiArrowRight} className="w-[16px]" />
                    {tripPresenter?.endDate}
                </span>

                <div className="text-sm flex flex-grow">
                    <Paragraph className="line-clamp-2 overflow-hidden text-neutral-800 h-[40px]">
                        {tripPresenter?.description}
                    </Paragraph>
                </div>
            </div>
        </div>
    );
}
