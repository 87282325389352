interface HotelItemDetailProps {
    label: string;
    value: string;
    className?: string;
}
let isString = (value: any) =>
    typeof value === "string" || value instanceof String;
const HotelItemDetail = ({ label, value, className }: HotelItemDetailProps) => (
    <div className={`flex flex-col items-start ${className}`}>
        <div className="font-medium sm:text-base text-sm text-gray-500">
            {label}
        </div>
        <div className="sm:text-base font-normal text-neutral-900 text-sm whitespace-break-spaces">
            {isString(value) && !!value?.length ? value : "-"}
        </div>
    </div>
);

export default HotelItemDetail;
