import { mdiMagnify} from '@mdi/js';
import { Icon as MDIcon } from "@mdi/react";

const SearchIcon = ({
    width = 20,
    height = 20,
    color = "black",
    ...otherProps
}) => (
    <MDIcon path={mdiMagnify} {...otherProps}/>
);

export default SearchIcon;
