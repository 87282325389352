import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyScreen from "../../components/EmptyScreen/EmptyScreen";
import { NOTIFICATION_TYPES } from "../../constants/notifications";
import NotificationItem from "./NotificationItem";
import NotificationsLoading from "./NotificationsLoading";
import ModalConfirmation from "../../components/Modal/ConfirmationModal";
import { useState } from "react";
import { Modal_Size } from "../../components/Modal";
import { hasComments } from "./utils";

interface INotificationsList {
    isLoading?: boolean;
    userNotifications: any[];
    notificationsPageCounter: any;
    fetchNotifications: () => Promise<void>;
    handleClickNotification: (params: any) => void;
    handleClickAccept: (params: any) => void;
    handleClickDecline: (params: any) => void;
}

const NotificationsList = (props: INotificationsList) => {
    const [selectedNotification, setSelectedNotification] = useState<any>(null);
    const {
        isLoading,
        userNotifications,
        fetchNotifications,
        notificationsPageCounter,
        handleClickNotification,
        handleClickAccept,
        handleClickDecline,
    } = props;

    const openNotification = (notification: any) => {
        if (hasComments(notification?.type)) {
            setSelectedNotification(notification);
        } else {
            handleClickNotification(notification);
        }
    };

    if (!isLoading && userNotifications?.length === 0) {
        return <EmptyScreen text="There are no notifications to show." />;
    }
    return (
        <>
            <InfiniteScroll
                dataLength={userNotifications?.length || 0}
                next={fetchNotifications}
                hasMore={
                    notificationsPageCounter.currentPage <
                    notificationsPageCounter.totalPages
                }
                loader={""}
            >
                {userNotifications.map((notification: any) => {
                    const {
                        showCTA,
                    }: { showCTA: boolean; type: NOTIFICATION_TYPES } =
                        notification;
                    const { data = {} } = notification || {};
                    return (
                        <NotificationItem
                            key={notification?.id}
                            title={notification?.title || data?._title}
                            description={notification?.body || data?._body}
                            read={notification.read}
                            date={moment(notification.date).fromNow()}
                            handleClick={() => openNotification(notification)}
                            showViewComments={hasComments(notification?.type)}
                            handleAccept={
                                showCTA
                                    ? () => handleClickAccept(notification)
                                    : undefined
                            }
                            handleDecline={
                                showCTA
                                    ? () => handleClickDecline(notification)
                                    : undefined
                            }
                        />
                    );
                })}
            </InfiniteScroll>
            {isLoading && <NotificationsLoading />}
            {!!userNotifications?.length &&
                !isLoading &&
                notificationsPageCounter.currentPage <
                    notificationsPageCounter.totalPages && (
                    <div
                        onClick={() => {
                            fetchNotifications();
                        }}
                        className="text-orange-600 hover:text-orange-500 cursor-pointer mt-12 font-semibold"
                    >
                        Load more
                    </div>
                )}
            <ModalConfirmation
                open={!!selectedNotification}
                title={selectedNotification?.data?.title}
                description={selectedNotification?.data?.description}
                cancelButtonText="Close"
                size={Modal_Size.medium}
                handleCancel={() => {
                    setSelectedNotification(null);
                }}
            />
        </>
    );
};

export default NotificationsList;
