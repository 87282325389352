import { useEffect, useState } from "react";
import InputSwitch from "./InputSwitch/InputSwitch";

export const SettingItem = ({
    name,
    title,
    value,
    description,
    last,
    handleChange,
    hideEmailSwitch,
}: any) => {
    const [defaultCheckedEmail, setDefaultCheckedEmail] = useState(
        value?.email
    );
    const [defaultCheckedPush, setDefaultCheckedPush] = useState(value?.push);

    useEffect(() => {
        setDefaultCheckedEmail(value?.email);
        setDefaultCheckedPush(value?.push);
    }, [value?.email, value?.push]);

    const containerClass = `" ${!last ? "border-b border-b-gray-300 " : ""}`;
    const handleChangeEmail = () => {
        const newVal = {
            email: !value?.email,
            push: value?.push,
        };
        if (handleChange) handleChange(name, newVal);
    };

    const handleChangePush = () => {
        const newVal = {
            email: value?.email,
            push: !value?.push,
        };
        if (handleChange) handleChange(name, newVal);
    };
    return (
        <div className={"col-span-6 pb-8 " + containerClass}>
            <div className="w-full grid grid-cols-3 gap-4">
                <div className="col-span-3 lg:col-span-1 font-semibold text-base text-left text-gray-600 flex-grow">
                    {title}
                    <div className="text-sm font-medium text-gray-400 text-left mt-1 break-words">
                        {description}
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-1 grid grid-cols-2 gap-10">
                    <div className="text-neutral-600 text-base font-medium w-fit col-span-1 whitespace-nowrap">
                        Push Notifications
                    </div>
                    <div className="col-span-1">
                        <InputSwitch
                            id={name}
                            name={name}
                            defaultChecked={defaultCheckedPush}
                            onClick={handleChangePush}
                        />
                    </div>
                </div>
                {!hideEmailSwitch && (
                    <div className="col-span-3 lg:col-span-1 grid grid-cols-2 gap-10">
                        <div className="text-neutral-600 text-base font-medium w-fit">
                            Emails
                        </div>
                        <InputSwitch
                            id={name}
                            name={name}
                            defaultChecked={defaultCheckedEmail}
                            onClick={handleChangeEmail}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
