import { OrganizationsCard } from "../OrganizationsCard/OrganizationsCard";
import { useAppSelector } from "../../app/hooks";
import { selectOrganizations } from "../../features/organizations/organizationSlice";
import { useNavigate } from "react-router";
import ShowMoreButton from "../ShowMoreButton";
import { useConstantsContext } from "../../context/Constants";

interface OrganizationsCardProps {
    isLoading: boolean;
    addNewClick: any;
}

export const OrganizationsList = (props: OrganizationsCardProps) => {
    const { isLoading } = props;
    const organizations = useAppSelector(selectOrganizations);
    const { constants } = useConstantsContext();

    const displayLimit = 4;
    const navigate = useNavigate();
    const showMoreClick = () => {
        navigate("/organizations");
    };

    if (!constants?.featureFlags?.ORGANIZATIONS) {
        return null;
    }

    if (!organizations || isLoading)
        return (
            <div className="react-loading-skeleton h-full w-full lg:min-h-[432px] shadow-sm rounded-xl">
                {" "}
            </div>
        );

    return (
        <div className="text-left flex flex-col bg-white rounded-xl shadow-card">
            <div className="flex flex-col  pb-2 pt-4 px-4 flex-grow">
                <div className="font-semibold text-lg mb-2">Organizations</div>
                {!organizations?.length && (
                    <div className="my-6 text-center text-sm text-neutral-400">
                        There are no organizations to show.
                    </div>
                )}
                {organizations
                    .slice(0, displayLimit)
                    .map((organization: any, index: number) => (
                        <OrganizationsCard
                            key={index}
                            organization={organization}
                        />
                    ))}
                <ShowMoreButton onClick={showMoreClick} />
            </div>
        </div>
    );
};
