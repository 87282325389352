import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
    settingOptions,
} from "../../authentication/userSlice";
import { ItemSettings } from "../ItemSetting";

export default function NotificationsSettingsView() {
    const {settings} = useAppSelector((state: RootState) => state.user);
    return (
        <>
            <div className="flex flex-col">
                <div className="grid grid-cols-6 xl:gap-x-32 gap-x-6 gap-y-6">
                    {settingOptions.map(item => <ItemSettings key={item.name} value={settings[item.name]} {...item} />)}
                </div>
            </div>
        </>
    );
}
