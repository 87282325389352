import { PAGE_SECTIONS } from "../../../../../../../../constants";
import {
    addHotelContactInsight,
    removeHotelContactInsight,
} from "../../../../../../../hotelInsight/hotelInsightSlice";
import InsightContactView from "../../components/InsightContactsView/InsightContactView";

export default function ContactInsightReview() {
    const currentSection = PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_CONTACTS

    return (
        <InsightContactView
            insightKey="hotelInsight"
            currentSection={currentSection}
            addContact={addHotelContactInsight}
            removeContact={removeHotelContactInsight}
        />
    );
}
