import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    getTokenFromCustomToken,
    setAuth,
    setProfile,
    signInWithCustomToken,
    signOut as signOutProfile,
} from "../userSlice";
import { setUser } from "../../UserProfile/UserProfileSlice";
import { SSO_ACTIONS } from "../../../constants/notifications";
import { useAuthStateContext } from "../../../context/Auth";
import Loader from "../../../components/Loader";
export const SSO = () => {
    const { signOut } = useAuthStateContext();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const otp = searchParams.get("otp");
    const action = searchParams.get("action");
    const redirectUrl = searchParams.get("redirectUrl");
    const dispatch = useDispatch();
    const getToken = useCallback(async () => {
        let url = "/";
        if (action === SSO_ACTIONS.REMOVE_USER) {
            dispatch(
                getTokenFromCustomToken(otp || "", (token: string | null) => {
                    if (token) {
                        url = `/profile/delete?token=${token}`;
                    } else {
                        url = "/auth/expired";
                    }
                    const siteURL = `${window.location.protocol}//${window.location.host}`;
                    window.location.replace(`${siteURL}${url}`);
                })
            );
        } else {
            dispatch(
                signInWithCustomToken(otp || "", (token: string | null) => {
                    if (token) {
                        url = redirectUrl || "/";
                        const siteURL = `${window.location.protocol}//${window.location.host}`;
                        window.location.replace(`${siteURL}${url}`);
                    } else {
                        try {
                            signOut && signOut();
                        } catch (error) {}

                        dispatch(signOutProfile());
                        navigate(url);
                    }
                })
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otp]);

    useEffect(() => {
        if (!!otp?.length) {
            dispatch(setAuth({ auth: null }));
            dispatch(setUser({ user: null }));
            dispatch(setProfile({ profile: null }));
            getToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getToken, otp]);

    return (
        <div>
            <div className="w-full min-h-screen mb-12 flex flex-col items-center justify-center">
                <div className="w-fit">
                    <Loader />
                </div>
            </div>
        </div>
    );
};
