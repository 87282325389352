import React, { useEffect, useMemo, useState } from "react";
import { ActivityType, ButtonSize, PAGE_SECTIONS } from "../../../constants";
import DetailItem from "../../trips/Trip/TripItinerary/Activity/Insight/components/InsightDetailItem";
import GoogleMap from "../../../components/Map";
import { parsePhoneNumber } from "../../../helpers";
import GalleryImage from "../../../components/ImagesGallery/Image";
import ContactCard from "../../trips/Trip/TripItinerary/ContactCard";
import ButtonSquared from "../../../components/ButtonSquare";
import FilePreview from "../../../components/DropFileInput/FilePreview";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import {
    removeLibraryElement,
    selectedLibraryElement,
    selectedOrganization,
} from "../organizationSlice";
import { setCurrentSection } from "../../layout/layoutSlice";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";

export const LibraryElementDetails = () => {
    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.ORGANIZATION_LIBRARY_DETAILS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const organization = useAppSelector(selectedOrganization);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [libraryElementIdToDelete, setLibraryElementIdToDelete] =
        useState<number>();
    const libraryElementSelected = useAppSelector(selectedLibraryElement);
    const {
        title,
        type,
        images,
        files,
        phone,
        website,
        description,
        contacts,
    } = libraryElementSelected || {};

    const { name, fullAddress, zipCode, lat, lng } =
        (libraryElementSelected &&
            type &&
            libraryElementSelected[type.toLowerCase()]) ||
        {};

    const [mapMarker, setmapMarker] = useState<any | null>(null);
    const hasAddress = useMemo(() => {
        return !!lat && fullAddress !== "undefined";
    }, [lat, fullAddress]);

    useEffect(() => {
        if (lat && lng) {
            const marker = {
                position: {
                    lat: lat,
                    lng: lng,
                },
                title: name,
            };
            setmapMarker(marker);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);

    const imagesArray =
        images &&
        Object.keys(images).map((oKey) => {
            return {
                original: images[oKey].url,
                thumbnail: images[oKey].thumb,
            };
        });

    const filesArray =
        files &&
        Object.keys(files).map((oKey) => {
            return {
                name: files[oKey].originalName,
                url: files[oKey].url,
            };
        });
    const contactsNormalized = contacts
        ? Object.keys(contacts).map((cKey) => contacts[cKey])
        : [];

    const goBack = () => {
        navigate(`/organizations/${organization.id}/library/${type}`);
    };
    const onEdit = () => {
        navigate(`edit`);
    };

    return (
        <div className="flex flex-col text-left">
            <ModalConfirmation
                open={!!libraryElementIdToDelete}
                title="Delete library element"
                description="Are you sure you want to delete this library element?"
                handleSubmit={async () => {
                    await dispatch(
                        removeLibraryElement(
                            organization.id,
                            Number(libraryElementIdToDelete)
                        )
                    );
                    setLibraryElementIdToDelete(undefined);
                    goBack();
                }}
                handleCancel={() => setLibraryElementIdToDelete(undefined)}
            />
            <div className="flex flex-row justify-between">
                <div className="mb-6">
                    <ButtonSquared
                        size={ButtonSize.FIT}
                        className="px-4"
                        onClick={goBack}
                        outlined
                        label="Back"
                    />
                </div>
                <div className="flex flex-row gap-x-6">
                    <div className="mb-6">
                        <ButtonSquared
                            outlined
                            size={ButtonSize.FIT}
                            className="px-4"
                            onClick={() => {
                                setLibraryElementIdToDelete(
                                    libraryElementSelected.id
                                );
                            }}
                            label="Delete"
                        />
                    </div>
                    <div className="mb-6">
                        <ButtonSquared
                            size={ButtonSize.FIT}
                            className="px-4"
                            onClick={onEdit}
                            label="Edit"
                        />
                    </div>
                </div>
            </div>
            <div className="flex-grow text-left text-lg font-semibold text-neutral-900">
                {title}
            </div>
            {name && (
                <>
                    {type !== ActivityType.OTHER && (
                        <div className="col-span-6 sm:col-span-3 mt-3">
                            <DetailItem label={type}>{name}</DetailItem>
                        </div>
                    )}
                    <div className="col-span-6 sm:col-span-3 mt-3">
                        {hasAddress && mapMarker && (
                            <GoogleMap marker={mapMarker} />
                        )}
                    </div>
                    <div className="grid grid-cols-6 gap-x-12">
                        {hasAddress && (
                            <div className="mt-6 col-span-6 lg:col-span-3">
                                <DetailItem label="Address">
                                    {fullAddress}
                                </DetailItem>
                            </div>
                        )}
                        {!!zipCode?.length && zipCode !== "undefined" && (
                            <div className="mt-6 col-span-6 lg:col-span-3">
                                <DetailItem label="Postal code">
                                    {zipCode}
                                </DetailItem>
                            </div>
                        )}
                        {phone && (
                            <div className="mt-6 col-span-6 lg:col-span-3">
                                <DetailItem label="Phone number">
                                    {parsePhoneNumber(phone)}
                                </DetailItem>
                            </div>
                        )}
                        {website && (
                            <div className="col-span-6 mt-3 text-left">
                                <DetailItem label="Website">
                                    <a
                                        className="text-base block mt-1 mb-2 break-all"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={website}
                                    >
                                        {website}
                                    </a>
                                </DetailItem>
                            </div>
                        )}
                    </div>
                    {description && (
                        <div className="mt-8 col-span-6">
                            <DetailItem label="Description">
                                {description}
                            </DetailItem>
                        </div>
                    )}
                    {!!contactsNormalized?.length && (
                        <>
                            <div className="text-sm font-semibold mb-4 mt-8">
                                Contacts
                            </div>
                            {contactsNormalized && (
                                <div className="flex gap-6 flex-wrap">
                                    {contactsNormalized.map(
                                        (c: any, index: number) => (
                                            <ContactCard
                                                hideEdit
                                                contact={c}
                                                key={index + c.email}
                                            />
                                        )
                                    )}
                                </div>
                            )}
                        </>
                    )}

                    {!!imagesArray?.length && (
                        <>
                            <div className="text-sm font-semibold mt-8 mb-4">
                                Photos
                            </div>
                            <div className="flex flex-wrap gap-4">
                                {imagesArray.map((i: any, index: number) => {
                                    return (
                                        <GalleryImage
                                            key={i.original}
                                            src={i.original}
                                            thumb={i.thumbnail}
                                            onImageClick={() => {}}
                                            viewOnly
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}

                    {!!filesArray?.length && (
                        <>
                            <div className="text-sm font-semibold mt-8 mb-4">
                                Files
                            </div>
                            <div className="flex flex-wrap gap-4">
                                {filesArray.map((file: any, index: number) => {
                                    return (
                                        <a
                                            key={index}
                                            href={file.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            download={true}
                                        >
                                            <FilePreview
                                                viewOnly
                                                name={file.name}
                                            />
                                        </a>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
