import { useState } from "react";
import moment from "moment";
import Modal, { Modal_Size } from ".";
import { ButtonSize } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import DatePickerMaterial from "../DatePickerMaterial/DatePickerMaterial";

export interface ModalProps {
    userFullName: string;
    handleClose: () => void;
    handleExtend?: (days: number) => void;
    open?: boolean;
}

export const PlanModalExtend: React.FunctionComponent<ModalProps> = (props) => {
    const { userFullName, handleClose, handleExtend, open} = props;
    const [startDate, setStartDate] = useState<any>(moment().startOf("day").toDate())

    const [selectNumberDays, setSelectNumberDays] = useState<number>(30);

    const handleExtendPlan = () => {
        handleExtend && handleExtend(selectNumberDays);
    };

    const handleStartDateChange = (value: moment.Moment) => {
        setStartDate(value.startOf("day").toDate());
        moment().diff(value.startOf("day"), "days")
        setSelectNumberDays(value.diff(moment().startOf("day"), "days"))
    };

    return (
        <Modal open={open} hideCloseButton size={Modal_Size.xs}>
            <div className="p-2">
                <div className="text-xl text-center font-semibold mb-6">
                    {"Extend paid plan"}
                </div>
                <div className="flex flex-col lg:flex-row gap-2 text-center">
                    Select the amount of extra days to {userFullName} enjoy their current paid plan. This will extend their access to all the features.
                </div>

                <div className="flex flex-col lg:flex-row gap-2 text-center m-4">
                    <DatePickerMaterial
                        name="startDate"
                        value={startDate}
                        id="startDate"
                        label="Extend:"
                        onChange={handleStartDateChange}
                    />
                    
                </div>

                <div className="flex flex-row gap-4 mt-4 w-full justify-end text-right">
                    <ButtonSquared
                        outlined
                        label="Cancel"
                        onClick={handleClose}
                        className="p-4"
                        size={ButtonSize.FIT}
                    />
                    <ButtonSquared
                        disabled={!(selectNumberDays > 0)}
                        label="Extend Plan"
                        onClick={handleExtendPlan}
                        size={ButtonSize.SMALL}
                    />
                </div>
            </div>
        </Modal>
    );
};
