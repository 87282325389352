import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthStateContext } from "../../../../context/Auth";
import Input from "../../../../components/Input";
import Button from "../../../../components/ButtonSquare";
import { useAppDispatch } from "../../../../app/hooks";
import GoogleMap from "../../../../components/Map";
import TextAreaInput from "../../../../components/TextArea";
import { addLocalPartnerToTrip, updateTripActivity } from "../../tripSlice";
import { UserAddIcon } from "@heroicons/react/solid";
import Modal from "../../../../components/Modal";
import useActivityContact from "../../../../hooks/useActivityContact";
import LocalPartnersAutocomplete from "../../../../components/LocalPartnersAutocomplete";
import ContactCard from "../TripItinerary/ContactCard";
import ContactForm from "../TripItinerary/ContactForm";

const schema = yup
    .object({
        startDate: yup.date().required("This field is required"),
        endDate: yup.date().required("This field is required"),
        contacts: yup.array(),
        localPartner: yup
            .object({
                id: yup.number().required("This field is required"),
                name: yup.string().required("This field is required"),
                description: yup.string().max(500).required("This field is required"),
                zipCode: yup.string().required("This field is required"),
                streetNumber: yup.string(),
                streetAddress: yup.string().required("This field is required"),
                state: yup.string().required("This field is required"),
                country: yup.string().required("This field is required"),
                fullAddress: yup.string().required("This field is required"),
                lat: yup.number().required("This field is required"),
                lng: yup.number().required("This field is required"),
            })
            .required("This field is required"),
    })
    .required("This field is required");

export default function LocalPartnerTripForm(props: any) {
    const [selectedLP, setSelectedLP] = useState<any | null>(null);
    const [mapMarker, setmapMarker] = useState<any>(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const dispatch = useAppDispatch();
    let { tripId, activityId } = useParams();
    const {
        contacts,
        contactsModalOpen,
        handleContactEditClick,
        handleContactRemoveClick,
        handleContactSubmit,
        handleContactToggle,
        selectedContact,
    } = useActivityContact([]);
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setValue("contacts", contacts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts]);

    const handleSubmitForm = async (data: any) => {
        if (!isUpdate) await handleAddForm(data);
        else await handleUpdateForm(data);
    };

    const handleAddForm = async (data: any) => {
        const payload = {
            localPartnerId: data.localPartner.id,
            startDate: data.startDate,
            endDate: data.endDate,
            contacts: data.contacts,
        };
        return await dispatch(addLocalPartnerToTrip(Number(tripId), payload));
    };
    const handleUpdateForm = async (data: any) => {
        const activity = {
            startDate: data.startDate,
            endDate: data.endDate,
            startHour: data.startHour,
            endHour: data.endHour,
            contacts: data.contacts,
        };

        return await dispatch(
            updateTripActivity(Number(tripId), Number(activityId), activity)
        );
    };
    const handleSelect = (lp: any) => {
        setSelectedLP(lp);
        const marker = {
            position: {
                lat: lp?.lat,
                lng: lp?.lng,
            },
            title: lp?.name,
        };
        setmapMarker(marker);
    };
    return (
        <>
            <Modal open={contactsModalOpen}>
                <ContactForm
                    onClose={() => handleContactToggle(false)}
                    onSubmit={handleContactSubmit}
                    contact={contacts && contacts[selectedContact]}
                />
            </Modal>
            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="lg:grid lg:grid-cols-3 lg:gap-6">
                    <div className="mt-6 lg:mt-0 lg:col-span-6">
                        <form
                            action="#"
                            method="POST"
                            onSubmit={handleSubmit(handleSubmitForm)}
                        >
                            {!isUpdate && (
                                <div className="col-span-6 sm:col-span-3 mt-3">
                                    <LocalPartnersAutocomplete
                                        control={control}
                                        onChange={handleSelect}
                                        label="Search Local Partners"
                                        id="localPartner"
                                        name="localPartner"
                                        type="text"
                                        placeholder="Search here"
                                    />
                                </div>
                            )}
                            {selectedLP && (
                                <>
                                    <div className="col-span-6 sm:col-span-3 mt-3">
                                        <Input
                                            register={register}
                                            defaultValue={selectedLP?.name}
                                            label="Name"
                                            id="name"
                                            name="name"
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 mt-3">
                                        <TextAreaInput
                                            register={register}
                                            defaultValue={
                                                selectedLP?.description
                                            }
                                            label="Description"
                                            id="description"
                                            name="description"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 mt-3">
                                        <Input
                                            register={register}
                                            defaultValue={
                                                selectedLP?.fullAddress
                                            }
                                            label="Address"
                                            id="address"
                                            name="address"
                                            type="text"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 mt-3">
                                        <Input
                                            register={register}
                                            defaultValue={new Date().toLocaleDateString(
                                                "en-CA"
                                            )}
                                            label="Start Date"
                                            id="startDate"
                                            name="startDate"
                                            type="date"
                                            error={errors.startDate?.message}
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 mt-3">
                                        <Input
                                            register={register}
                                            defaultValue={new Date().toLocaleDateString(
                                                "en-CA"
                                            )}
                                            label="End Date"
                                            id="endDate"
                                            name="endDate"
                                            type="date"
                                            error={errors.endDate?.message}
                                        />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3 mt-3">
                                        {mapMarker && (
                                            <GoogleMap marker={mapMarker} />
                                        )}
                                    </div>

                                    <div className="flex mt-6 mb-3">
                                        Contacts
                                        <UserAddIcon
                                            className="cursor-pointer"
                                            width={24}
                                            onClick={() =>
                                                handleContactToggle(true)
                                            }
                                        />
                                    </div>

                                    {contacts && (
                                        <div className="flex gap-3">
                                            {contacts.map(
                                                (c: any, index: number) => (
                                                    <ContactCard
                                                        handleEdit={() => {
                                                            handleContactEditClick(
                                                                index
                                                            );
                                                        }}
                                                        handleRemove={() => {
                                                            handleContactRemoveClick(
                                                                index
                                                            );
                                                        }}
                                                        contact={c}
                                                        key={`${c.firstName}-${c.phone}`}
                                                    />
                                                )
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <Button
                                    type="submit"
                                    label={isUpdate ? "Update" : "Create"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>
        </>
    );
}
