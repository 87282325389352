import ReactDOM from "react-dom";
import Smartlook from "smartlook-client";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Firebase from "./api/firebase";
import AuthStateProvider from "./context/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from "./config";
import ConstantsProvider from "./context/Constants";
import TableAdminProvider from "./context/TableAdmin";

moment.updateLocale("en", {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "seconds",
        m: "1m",
        mm: "%dm",
        h: "1 hour",
        hh: "%d hours",
        d: "1d",
        dd: "%dd",
        M: "1 month",
        MM: "%d months",
        y: "1 year",
        yy: "%d years",
    },
});

Sentry.init({
    environment: config.fbProjectId,
    dsn: "https://e9ac79ecb1fd4dc3b61d733aa14de3b1@o1310488.ingest.sentry.io/6557977",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
});

Smartlook.init(`${process.env.REACT_APP_SMARTLOOK_KEY}`);
const router = createBrowserRouter([
    {
        path: "*",
        element: (
            <AuthStateProvider Firebase={new Firebase()}>
                <ConstantsProvider>
                    <TableAdminProvider>
                        <ToastContainer limit={1} />
                        <App />
                    </TableAdminProvider>
                </ConstantsProvider>
            </AuthStateProvider>
        ),
    },
]);
ReactDOM.render(
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
