import Avatar from "../../../components/Avatar";
import {
    ORG_USERS_LIST_OPTIONS,
    ORG_USERS_LIST_OPTIONS_KEYS,
    PAGE_ROWS,
    UserRoleOnOrganization,
} from "../../../constants";
import Paginator from "../../../components/Paginator";
import Table from "../../../components/Table";
import StatusBadgeComponent from "../../../components/StatusBadge/StatusBadgeComponent";
import DotsMenu from "../../../components/DotsMenu";

const tableHeaders = ["Name / Email", "Status", "Role", ""];
interface IOrgUsersTable {
    users: any;
    handleClickUserDotsMenu: Function;
    handlePaginatorChange: Function;
    handleViewUser?: Function;
}
export default function OrganizationUsersTable({
    users,
    handleClickUserDotsMenu,
    handlePaginatorChange,
    handleViewUser,
}: IOrgUsersTable) {
    const { data, count, isLoading, currentPage, totalPages, take } = users;

    return (
        <>
            <Table headers={tableHeaders} isLoading={isLoading}>
                {data &&
                    data.map((u: any) => {
                        const isOwner = u.role === UserRoleOnOrganization.OWNER;
                        const ActualUserIsOwner = u?.permission?.canMakeOwner;
                        const canEdit = u?.permission?.canEdit;

                        const menuOptions = ActualUserIsOwner
                            ? ORG_USERS_LIST_OPTIONS[u.status]
                            : ORG_USERS_LIST_OPTIONS[u.status]?.filter(
                                  (option: { key: string }) => {
                                      return !(
                                          option.key ===
                                          ORG_USERS_LIST_OPTIONS_KEYS.MAKE_OWNER
                                      );
                                  }
                              );

                        return (
                            <tr key={u.userEmail}>
                                <td>
                                    <div
                                        onClick={() => {
                                            handleViewUser && handleViewUser(u?.user);
                                        }}
                                        className="flex gap-3 items-center justify-start cursor-pointer"
                                    >
                                        <Avatar
                                            src={
                                                !!u?.user?.avatar_thumb?.length
                                                    ? u?.user?.avatar_thumb
                                                    : u?.user?.avatar
                                            }
                                            className="w-6 h-6"
                                        />

                                        <span className="text-left">
                                            {u.user?.firstName
                                                ? `${u.user?.firstName} ${u.user?.lastName}`
                                                : u?.userEmail}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex justify-start">
                                        <div className="flex justify-start">
                                            <span className="w-full text-left">
                                                <StatusBadgeComponent
                                                    status={u?.status}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex gap-3 items-center justify-start">
                                        <div className="w-full text-left">
                                            <div>{u.role}</div>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div className="w-full flex justify-end">
                                        {!isOwner && canEdit && (
                                            <div className="">
                                                <DotsMenu
                                                    options={menuOptions}
                                                    handleClickMenu={(
                                                        k: any
                                                    ) => {
                                                        handleClickUserDotsMenu(
                                                            k,
                                                            u
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
            </Table>
            {count > PAGE_ROWS && (
                <div className="w-full mt-8">
                    <Paginator
                        count={count}
                        take={take}
                        onChange={handlePaginatorChange}
                        totalPages={totalPages}
                        currentPage={currentPage}
                    />
                </div>
            )}
        </>
    );
}
