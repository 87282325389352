import moment from "moment";
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import ModalConfirmation from "../../../components/Modal/ConfirmationModal";

import {
    deleteSharedInsight,
    downloadInsightPDF,
    getSharedInsights,
    selectSharedInsights,
} from "../insightsSlice";
import Insights from "./components/Insights";
import { DATE_FORMAT } from "../../../helpers";

export const normalizeSharedInsight = (sharedInsight: any) => {
    const insight = sharedInsight?.insight;
    const activity =
        insight.activity?.hotelActivity?.hotel ||
        insight.activity?.otherActivity?.other ||
        insight.activity?.restaurantActivity?.restaurant;
    return {
        sharedInsightId: sharedInsight?.id,
        type: insight?.activity?.type,
        status: sharedInsight?.status,
        role: sharedInsight?.role,
        id: sharedInsight?.insightId,
        title: insight.activity?.title || activity?.name,
        date: moment(insight.activity?.startDate).format(DATE_FORMAT),
        location: `${activity?.city}, ${activity?.country}`,
        trip: insight?.activity?.trip?.title,
    };
};

const DotMenuKeys = {
    remove: "REMOVE",
    export: "EXPORT",
    details: "details",
};
const DotMenuOptions: any = [
    {
        icon: "TrashIcon",
        label: "Remove from list",
        key: DotMenuKeys.remove,
    },
    {
        icon: "DownloadIcon",
        label: "Export PDF",
        key: DotMenuKeys.export,
    },
];

export default function SharedInsightsList(props: any) {
    const dispatch = useAppDispatch();
    const [selectedInsight, setSelectedInsight] = useState<any>();
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const insights = useAppSelector(selectSharedInsights);
    const insightsListRef: any = useRef();

    const getData = (filters: any) => {
        dispatch(
            getSharedInsights(
                filters.take,
                filters.skip,
                filters.type,
                filters.search,
                filters.startDate,
                filters.endDate
            )
        );
    };

    const handleClickDotsMenu = (option: any, element: any) => {
        switch (option) {
            case DotMenuKeys.remove:
                setSelectedInsight(element);
                setDeleteModalOpen(true);
                break;
            case DotMenuKeys.export:
                dispatch(downloadInsightPDF(element.id));
                break;

            default:
                break;
        }
    };

    const onRemove = async () => {
        setLoading(true);
        await deleteSharedInsight(selectedInsight.sharedInsightId);
        setLoading(false);
        setDeleteModalOpen(false);
        insightsListRef?.current?.reFetch();
    };

    return (
        <>
            <ModalConfirmation
                open={deleteModalOpen}
                title="Are you sure?"
                description={`You are about to remove ${selectedInsight?.title}`}
                handleCancel={() => {
                    setDeleteModalOpen(false);
                }}
                handleSubmit={onRemove}
                isLoading={loading}
            />
            <Insights
                insights={insights}
                ref={insightsListRef}
                handleClickDotsMenu={handleClickDotsMenu}
                fetchData={getData}
                DotMenuOptions={DotMenuOptions}
                insightsNormalizer={normalizeSharedInsight}
            />
        </>
    );
}
