import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import server from "../../../../api/server";
import Icon from "../../../../assets/svg/Icon";
import ButtonSquared from "../../../../components/ButtonSquare";
import InputSearch from "../../../../components/InputSearch";
import { ActivityIcon, ActivityType, PAGE_ROWS } from "../../../../constants";
import { IPaginatedDataInitialState } from "../../../../types/dataTypes";
import { handleError } from "../../../authentication/userSlice";
import {
    replaceHotel,
    replaceOther,
    replaceRestaurant,
} from "../../adminSlice";
import ElementsAdminList from "./ElementsAdminList";

interface IElementsReplaceFormProps {
    onCancel?: Function;
    element: any;
    type: ActivityType;
    onSubmit: Function;
}

const ElementsReplaceForm = ({
    onCancel,
    element,
    onSubmit,
    type,
}: IElementsReplaceFormProps) => {
    const [elementSelected, setElementSelected] = useState<any>(null);
    const [elements, setElements] = useState<any>(IPaginatedDataInitialState);
    const [searchText, setSearchText] = useState<any>(null);
    const [newPage, setNewPage] = useState<any>({});
    const dispatch = useDispatch();
    const normalizedElements = useMemo(() => {
        return {
            ...elements,
            data: (elements?.data || []).map((e: any) => {
                return {
                    ...e,
                    name: e.name,
                    date: moment(e.createdAt).format("DD MMM YY"),
                    location: `${e?.city || e?.state} - ${e?.country}`,
                    user:
                        e.createdBy?.lastName || e.createdBy?.firstName
                            ? `${e.createdBy?.firstName} ${e.createdBy?.lastName}`
                            : e.createdBy?.email,
                };
            }),
        };
    }, [elements]);

    useEffect(() => {
        if (searchText !== null) {
            fetchData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, searchText]);

    const resetPage = () => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleSearchChange = (query: string) => {
        resetPage();
        setSearchText(query);
    };

    const fetchData = async () => {
        setElements({ ...IPaginatedDataInitialState, isLoading: true });
        try {
            let response;
            switch (type) {
                case ActivityType.HOTEL:
                    response = await server.getAdminHotels(
                        PAGE_ROWS,
                        newPage.skip,
                        searchText
                    );
                    break;
                case ActivityType.RESTAURANT:
                    response = await server.getAdminRestaurants(
                        PAGE_ROWS,
                        newPage.skip,
                        searchText
                    );
                    break;
                case ActivityType.OTHER:
                    response = await server.getAdminOthers(
                        PAGE_ROWS,
                        newPage.skip,
                        searchText
                    );
                    break;
            }
            setElements(response?.data);
        } catch (error) {
            setElements({ ...IPaginatedDataInitialState, isLoading: false });
            handleError(error);
        }
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const handleSubmit = async () => {
        let replaceFunction: any;
        switch (type) {
            case ActivityType.HOTEL:
                replaceFunction = replaceHotel;
                break;
            case ActivityType.RESTAURANT:
                replaceFunction = replaceRestaurant;
                break;
            case ActivityType.OTHER:
                replaceFunction = replaceOther;
                break;
        }
        try {
            await dispatch(replaceFunction(element.id, elementSelected.id));
            onSubmit && onSubmit(elementSelected);
        } catch (e) {}
    };
    return (
        <div className="flex flex-col text-left">
            <div className="flex w-full justify-start items-center border rounded-md p-4 mb-4">
                <Icon
                    className="w-[24px] cursor-pointer ml-4 text-blue-800"
                    icon="InfoIcon"
                />
                <span className="text-left text-xs text-gray-500 pl-4">
                    This element will be replaced with the one provided in all
                    its linked activities.
                </span>
            </div>
            <div className="flex flex-col md:m-4 pb-4  border-b border-b-neutral-200">
                <div className="flex flex-row gap-x-2 items-center">
                    <Icon
                        className="w-[32px] text-orange-600"
                        icon={ActivityIcon[type as keyof typeof ActivityIcon]}
                    />
                    <span className="text-xl font-semibold">
                        {element.name}
                    </span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[16px] mr-1 text-neutral-400"
                        icon="MarkerIcon"
                    />

                    <span className="truncate text-sm">
                        {element?.location}
                    </span>
                </div>
                <div className="flex flex-row text-left mt-1">
                    <Icon
                        className="w-[16px] mr-1 text-neutral-400"
                        icon="ParticipantsIcon"
                    />

                    <span className="truncate text-sm">{element?.user}</span>
                </div>
            </div>
            <div className="mt-4 md:mx-4 text-base font-semibold">
                To be replaced with:
            </div>
            {!elementSelected ? (
                <div className="mt-2 flex flex-col gap-y-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                    {elements?.data && (
                        <ElementsAdminList
                            elements={normalizedElements}
                            handlePaginatorChange={handlePaginatorChange}
                            onSelect={setElementSelected}
                        />
                    )}
                </div>
            ) : (
                <div className="flex flex-col mt-4 md:m-4">
                    <div className="flex flex-row gap-x-2 items-center flex-wrap">
                        <Icon
                            className="w-[32px] text-orange-600"
                            icon={
                                ActivityIcon[type as keyof typeof ActivityIcon]
                            }
                        />
                        <span className="text-xl font-semibold truncate">
                            {elementSelected.name}
                        </span>
                        <Icon
                            className="w-[24px] cursor-pointer text-neutral-900"
                            icon="CloseIcon"
                            onClick={() => {
                                setElementSelected(null);
                            }}
                        />
                    </div>
                    <div className="flex flex-row text-left mt-1">
                        <Icon
                            className="w-[16px] mr-1 text-neutral-400"
                            icon="MarkerIcon"
                        />

                        <span className="truncate text-sm">
                            {elementSelected?.location}
                        </span>
                    </div>
                    <div className="flex flex-row text-left mt-1">
                        <Icon
                            className="w-[16px] mr-1 text-neutral-400"
                            icon="ParticipantsIcon"
                        />

                        <span className="truncate text-sm">
                            {elementSelected?.user}
                        </span>
                    </div>
                </div>
            )}
            <div className="flex flex-row gap-6 justify-end flex-wrap mt-6">
                <ButtonSquared
                    outlined
                    onClick={() => onCancel && onCancel()}
                    label="Cancel"
                />
                {elementSelected && (
                    <ButtonSquared onClick={handleSubmit} label="Replace" />
                )}
            </div>
        </div>
    );
};

export default ElementsReplaceForm;
