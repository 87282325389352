import React from "react";
import InputErrorLabel from "../InputErrorLabel";
import InputLabel from "../InputLabel";

const inputClasses = {
    normal: "border-b border-gray-200 focus-within:border-b focus-within:border-orange-600 hover:border-orange-600 pr-3 pb-2 block w-full text-base font-normal",
    error: "border-b border-alert_red py-2 block w-full text-base font-normal",
};

const InputInstagram = React.forwardRef((props: any, ref: any) => {
    const {
        label,
        id,
        name,
        type,
        placeholder,
        defaultValue,
        error,
        className,
        autoComplete,
        inputStyle,
        ...others
    } = props;
    return (
        <>
            <InputLabel inputId={id} label={label} error={error} />
            <div className="flex w-full items-center">
                <div className="mb-2 pb-[1px]">@</div>
                <input
                    style={inputStyle}
                    ref={ref}
                    type={type}
                    name={name}
                    id={id}
                    autoComplete={autoComplete}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    className={`${className} ${
                        error ? inputClasses.error : inputClasses.normal
                    }`}
                    {...others}
                />
            </div>

            <InputErrorLabel error={error} />
        </>
    );
});
export default InputInstagram;
