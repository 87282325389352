import Icon from "../../../../assets/svg/Icon";

const TripListEmptyView = ({ onAdd }: {
    onAdd: () => void;
}) => {
    return (
        <div className="flex flex-col justify-center items-center gap-3 my-5">
            <Icon icon={"BoxEmpty"} className="w-20 h-20 text-gray-400 mx-auto" />
            <div className="text-gray-500">
                You don't have any active trips yet.
            </div>
        </div>
    );
};

export default TripListEmptyView;
