import moment from "moment";
import * as yup from "yup";

export const schemaActivityRestaurant = yup.object({
    startDate: yup.date().when("isLibraryElement", {
        is: false,
        then: yup.date().required("This field is required"),
        otherwise: yup.date(),
    }),
    startHour: yup.mixed().when("isLibraryElement", {
        is: false,
        then: yup
            .mixed()
            .test(`test-hour-format`, "Invalid date format", function (value) {
                return !!moment(value).isValid();
            })
            .required("This field is required"),
        otherwise: yup.mixed(),
    }),
    contacts: yup.array(),
    name: yup.string(),
    title: yup.string(),
    description: yup.string().max(5000).nullable(),
    website: yup.string().url("Enter a valid url").nullable(),
    phone: yup.string().nullable(),
    restaurant: yup.object({
        name: yup.string(),
        googlePlaceId: yup.string().nullable(),
        streetNumber: yup.string().nullable(),
        streetAddress: yup.string().nullable(),
        zipCode: yup.string().nullable(),
        city: yup.string().nullable(),
        state: yup.string().nullable(),
        website: yup.string().nullable(),
        country: yup.string().nullable(),
        fullAddress: yup.string().nullable(),
        lat: yup.number().nullable(),
        lng: yup.number().nullable(),
    }),
});

export const schemaOtherActivity = yup
    .object({
        startDate: yup.date().required("This field is required"),
        startHour: yup
            .mixed()
            .test(`test-hour-format`, "Invalid date format", function (value) {
                return !!moment(value).isValid();
            })
            .required("This field is required"),
        endHour: yup
            .mixed()
            .test(`test-hour-format`, "Invalid date format", function (value) {
                return !!moment(value).isValid();
            })
            .required("This field is required"),
        contacts: yup.array(),
        other: yup.object().required("This field is required"),
        search: yup.mixed().nullable(),
        title: yup.string().required("This field is required"),
        website: yup.string().url("Enter a valid url").nullable(),
        description: yup.string().max(5000).nullable(),
    })
    .required("This field is required");

export const schemaHotel = (isVisit?: boolean) => yup.object({
    startDate: yup.date().when("isLibraryElement", {
        is: true,
        then: yup.date(),
        otherwise: yup.date().required("This field is required"),
    }),
    endDate: yup.date().when("isLibraryElement", {
        is: true,
        then: yup.date(),
        otherwise: yup
            .date()
            .when("startDate", (startDate, schema) => {
                if (!isVisit && startDate) {
                    // This can be calculated in many ways. Just be sure that its type is `Date` object
                    const dayAfter = new Date(startDate.getTime() + 86400000);

                    return schema.min(
                        dayAfter,
                        "End date must be after Start date"
                    );
                }

                return schema;
            })
            .required("This field is required"),
    }),
    startHour: yup.mixed().when("isLibraryElement", {
        is: false,
        then: yup
            .mixed()
            .test(`test-hour-format`, "Invalid date format", function (value) {
                return !!moment(value).isValid();
            })
            .required("This field is required"),
        otherwise: yup.mixed(),
    }),
    endHour: yup.mixed().when("isLibraryElement", {
        is: false,
        then: yup
            .mixed()
            .test(`test-hour-format`, "Invalid date format", function (value) {
                return !!moment(value).isValid();
            })
            .required("This field is required"),
        otherwise: yup.mixed(),
    }),
    contacts: yup.array(),
    name: yup.string(),
    title: yup.string(),
    description: yup.string().max(5000).nullable(),
    website: yup.string().url("Enter a valid url").nullable(),
    phone: yup.string().nullable(),
    hotel: yup
        .object({
            name: yup.string(),
            googlePlaceId: yup.string().nullable(),
            streetNumber: yup.string().nullable(),
            streetAddress: yup.string().nullable(),
            zipCode: yup.string().nullable(),
            city: yup.string().nullable(),
            state: yup.string().nullable(),
            website: yup.string().nullable(),
            country: yup.string().required("This field is required"),
            fullAddress: yup.string().required("This field is required"),
            lat: yup.number().required("This field is required"),
            lng: yup.number().required("This field is required"),
        })
        .required("This field is required"),
    isLibraryElement: yup.boolean(),
});
