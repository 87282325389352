import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface IList {
    items: any;
    handleNext: any;
    renderItem: any;
    itemKey?: any;
}
export default function List({
    items,
    itemKey,
    handleNext,
    renderItem: Item,
}: IList) {
    const { data, isLoading, currentPage, totalPages } =
        items;
    const hasMore = !isLoading && currentPage < totalPages;
    const onNext = () => {
        const newPage = (currentPage || 0) + 1;
        const newSkip = data?.length || 0;
        handleNext(newPage, newSkip);
    };

    const Loader = () => {
        return (
            <div>
                <Skeleton count={3} className="h-20 my-4" />
            </div>
        );
    };

    return (
        data && (
            <>
                <InfiniteScroll
                    dataLength={data?.length} //This is important field to render the next data
                    next={onNext}
                    hasMore={hasMore}
                    loader={<Loader />}
                >
                    {data?.map((u: any, index: number) => {
                        const key = itemKey && itemKey(u);
                        return <Item item={u} key={key} index={index} />;
                    })}
                </InfiniteScroll>
                {!!data?.length && !isLoading && hasMore && (
                    <div
                        onClick={() => {
                            onNext();
                        }}
                        className="text-orange-600 hover:text-orange-500 cursor-pointer mt-12 font-semibold"
                    >
                        Load more
                    </div>
                )}
            </>
        )
    );
}
