import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import serverApi from "../../api/server";
import { normalizeError, normalizeReviews, sendToast } from "../../helpers";
import { PAGE_ROWS, TOAST_TYPE } from "../../constants";
import { signOut } from "../authentication/userSlice";

interface IPaginatedData {
    data: Array<any>;
    count: number;
    currentPage: number;
    totalPages: number;
    skip: number;
    take: number;
    isLoading: boolean;
    requirements?: number;
}

export const IPaginatedDataInitialState = {
    data: [],
    count: 0,
    currentPage: 1,
    totalPages: 1,
    skip: 0,
    take: PAGE_ROWS,
    isLoading: false,
};

export interface UserState {
    isLoading: Boolean;
    error: string | null;
    user: any;
    reviews: IPaginatedData;
}

const initialState: UserState = {
    isLoading: false,
    error: "",
    user: {},
    reviews: IPaginatedDataInitialState,
};

export const userSlice = createSlice({
    name: "userprofile",
    initialState,
    reducers: {
        isLoading: (state, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<{ error: string | null }>) => {
            state.error = action.payload.error;
        },
        setUser: (state, action: PayloadAction<{ user: any }>) => {
            state.user = { ...action.payload.user };
        },
        setUserReviews: (state, action: PayloadAction<{ reviews: any }>) => {
            state.reviews = { ...action.payload.reviews };
        },
    },
});

export const { isLoading, setUser, setError, setUserReviews } =
    userSlice.actions;

//SELECTORS
export const selectuser = (state: RootState) => state.userprofile?.user;
export const selectError = (state: RootState) => state.userprofile?.error;
export const selectUserReviews = (state: RootState) =>
    state.userprofile?.reviews;

export const getUser =
    (userId: number, showLoading = true): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(showLoading));
            dispatch(setUser({ user: null }));
            const response = await serverApi.getUser(userId);
            dispatch(setUser({ user: response.data }));
            dispatch(isLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const getUserById =
    (userId: number, showLoading = true): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(isLoading(showLoading));
            dispatch(setUser({ user: null }));
            const response = await serverApi.getUser(userId);
            dispatch(isLoading(false));
            return response?.data;
        } catch (error) {
            dispatch(handleError(error));
            dispatch(isLoading(false));
        }
    };

export const clearUserProfile = (): AppThunk => async (dispatch) => {
    dispatch(setUser({ user: null }));
    dispatch(setError({ error: null }));
};

export const getUserReviews =
    (userId: number, take: number = 10, skip: number = 0): AppThunk =>
    async (dispatch, getState) => {
        const currentState = getState().userprofile.reviews;
        try {
            dispatch(
                setUserReviews({
                    reviews: { ...currentState, isLoading: true },
                })
            );
            const response = await serverApi.getUserReviews(userId, take, skip);
            const { data, ...others } = response.data;
            const reviews = data.map((r: any) => normalizeReviews(r));
            dispatch(
                setUserReviews({
                    reviews: {
                        ...others,
                        data: currentState.data.concat(reviews),
                        isLoading: false,
                    },
                })
            );

            return response?.data;
        } catch (error) {
            dispatch(
                setUserReviews({
                    reviews: { ...currentState, isLoading: false },
                })
            );
            dispatch(handleError(error));
        }
    };

export const handleError =
    (error: any): AppThunk =>
    async (dispatch, getState, Firebase: any) => {
        const statusCode = error?.response?.status;
        if (statusCode === 401) dispatch(signOut(true));
        const nError = normalizeError(error);
        sendToast(TOAST_TYPE.ERROR, nError);
    };
export default userSlice.reducer;
