import { mdiBellOutline } from "@mdi/js";
import { Icon as MDIcon } from "@mdi/react";
import { HTMLAttributes } from "react";

interface BellProps extends HTMLAttributes<HTMLBaseElement> {
    notReadCount: number;
    width?: number;
    height?: number;
    label?: string;
}

export default function Bellbadge(props: BellProps) {
    const { notReadCount, label, ...otherProps } = props;
    return (
        <div className="flex flex-row items-center gap-1">
            <div className="relative m-0 text-neutral-400 no-underline cursor-pointer flex justify-center align-center items-center text-center">
                {notReadCount ? (
                    <div className="text-white rounded-full w-2 h-2 bg-orange-600 absolute right-0 lg:right-[35%] top-0 lg:top-1" />
                ) : null}
                <MDIcon path={mdiBellOutline} {...otherProps} />
            </div>
            <div className="font-normal text-xs text-neutral-400 mt-[2px] text-center hidden lg:block">
                {label}
            </div>
        </div>
    );
}
