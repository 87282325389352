import { createContext } from "react";
import { ChatForm } from "../type/chatFormType";
import { IContact, IChatEntity, IChatMessage } from "../../../types/chat.type";

interface IChatFormProps {
    chats: IChatEntity[], 
    isLoading: boolean,
    contactSearchLists: IContact[],
    error: string | null,
    selectedChat: IChatEntity | null,
    selectedMessages: IChatMessage[],
    selectedUser: IContact | null,
    onSelectedChat: (
        conversation_id: string
    ) => Promise<IChatEntity[]>;
    onSendMessage: (
        message: ChatForm
    ) => Promise<void>;
    onExistingChat: (
        id: string
    ) => IChatEntity;
    onSelectedUser: (
        user: IContact
    ) => void;
    getUsersContacts: (
        value: string
    ) => void;
    getInitials: (name: string) => string;
    getFormattedDate: (timestamp: number) => string;
    showUsersList: boolean;
    setShowUsersList: (show: boolean) => void;
    handleClearSearch: () => void;
    handleFileSelection: (files: File[]) => void;
    removeSelectedFile: (index: number) => void;
    selectedFiles: File[];
    saveImageToTripGallery: (save: boolean, messageId: string, chatId: string,  userUid: string) => void;
    onBlockUser: () => void;
    onAcceptUser: () => void;
    onReportUser: (reason: string) => void;
}

export const ChatFormContext = createContext<IChatFormProps>({
    chats: [] as IChatEntity[],
    isLoading: false,
    error: null,
    contactSearchLists: [],
    selectedChat: null,
    selectedMessages: [] as IChatMessage[],
    selectedUser: {} as IContact,
    onSelectedChat: () => Promise.resolve([] as IChatEntity[]),
    onSendMessage: () => Promise.resolve(),
    onExistingChat: () => ({} as IChatEntity),
    onSelectedUser: () => {},
    getUsersContacts: (value: string) => {},
    getInitials: (name: string) => "",
    getFormattedDate: (timestamp: number) => "",
    showUsersList: false,
    setShowUsersList: () => {},
    handleClearSearch: () => {},
    handleFileSelection: () => {},
    removeSelectedFile: () => {},
    selectedFiles: [],
    saveImageToTripGallery: () => {},
    onBlockUser: () => {},
    onAcceptUser: () => {},
    onReportUser: (reason: string) => {},
});