import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Paginator from "../../../../components/Paginator";
import { TripType } from "../../../../constants";
import { selectCurrentBreakpoint } from "../../../layout/layoutSlice";
import {
    getTripMembers,
    selectedTrip,
    selectedTripUsers,
} from "../../tripSlice";
import { ParticipantsList } from "./ParticipantsList";
import { ParticipantsTable } from "./ParticipantsTable";

const PAGE_SIZE = 3;

export const ParticipantsCard = () => {
    const [newPage, setNewPage] = useState<any>({});
    const navigate = useNavigate();
    const tripUsers = useAppSelector(selectedTripUsers);
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);

    const {
        data,
        count,
        isLoading,
        currentPage,
        totalPages,
        take,
        requirements,
    } = tripUsers;

    useEffect(() => {
        getMembers(PAGE_SIZE, newPage.skip, newPage.currentPage, "", "", "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, trip?.id]);

    const getMembers = (
        take: number,
        newSkip: number,
        newPage: number,
        search: string,
        status: any,
        role: any
    ) => {
        if (trip?.id) {
            dispatch(
                getTripMembers(
                    Number(trip?.id),
                    take,
                    newSkip,
                    newPage,
                    search,
                    status,
                    role
                )
            );
        }
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const handleViewAllClick = () => {
        navigate(`/trips/${trip.id}/users/`);
    };

    const handleOpenProfile = (id: number) => {
        navigate(`/trips/${trip.id}/users/${encodeURIComponent(id)}`);
    };

    const handleOpenNonRegisteredProfile = (email: number) => {
        navigate(`/trips/${trip.id}/nonuser/${encodeURIComponent(email)}`);
    };

    const handleClickMember = (prt: any) => {
        prt?.user?.firstName
            ? handleOpenProfile(prt.user?.id)
            : handleOpenNonRegisteredProfile(prt?.userEmail);
    };

    return (
        <>
            <div className="flex flex-col text-left bg-white shadow-sm pb-2 rounded-xl w-full">
                <div className="overflow-hidden rounded-xl mb-4">
                    {isDesktop ? (
                        <ParticipantsTable
                            showDocuments={trip?.type === TripType.ORGANIZER}
                            members={data}
                            isLoading={isLoading}
                            handleClickMember={handleClickMember}
                            requirements={requirements}
                        />
                    ) : (
                        <ParticipantsList
                            members={data}
                            isLoading={isLoading}
                            handleClickMember={handleClickMember}
                        />
                    )}
                </div>
                <div className="flex flex-wrap text-center pt-2 px-6 !bg-white min-h-[2.25rem] relative">
                    <div className="flex-grow">
                        <div className="flex-grow lg:left-0 lg:right-0 lg:absolute font-semibold">
                            <span
                                onClick={handleViewAllClick}
                                className="text-orange-500 hover:text-orange-600 text-sm cursor-pointer self-center"
                            >
                                View all
                            </span>
                        </div>
                    </div>

                    {count > PAGE_SIZE && (
                        <div className="self-end text-xs z-20 mt-4">
                            <Paginator
                                count={count}
                                take={take}
                                onChange={handlePaginatorChange}
                                totalPages={totalPages}
                                currentPage={currentPage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
