import { FC, InputHTMLAttributes } from "react";
import Input from "./Input";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    error?: any;
    register?: any;
    className?: string;
    instructions?: string;
    inputStyle?: any;
    trim?: boolean;
    numeric?: boolean;
    markRequired?: boolean;
}

const InputWithValid: FC<InputProps> = ({
    register,
    name,
    error,
    label,
    inputStyle,
    type,
    trim,
    numeric,
    ...rest
}) => {
    const handleRegister = register(name);

    const onChange = (e: any) => {
        if (type === "number" || numeric) {
            e.target.value = e?.target?.value?.replace(/[^0-9]/g, "") || "";
        }
        if (trim) {
            e.target.value = e.target.value.trim();
        }
        handleRegister.onChange(e);
    };
    return (
        <Input
            inputStyle={inputStyle}
            aria-invalid={error ? "true" : "false"}
            {...handleRegister}
            onChange={onChange}
            error={error}
            label={label}
            name={name}
            type={type}
            {...rest}
        />
    );
};

export default InputWithValid;
