import Icon from "../../../../assets/svg/Icon";
import { mdiLockOutline } from "@mdi/js";

const SystemMessagesBubble = () => {
    return (
        <div className="flex justify-center my-4">
            <div className="flex items-center bg-gray-100 border border-gray-300 rounded-full py-2 px-4">
                <Icon materialIcon={mdiLockOutline} className="h-5 w-5"/>
                <span className="text-sm text-gray-600" >
                    Your chat is safe & private. Messages are end-to-end encrypted.
                </span>
            </div>
        </div>
    );
};

export default SystemMessagesBubble;
