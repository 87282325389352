import moment from "moment";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSquared from "../../../../components/ButtonSquare";
import Modal from "../../../../components/Modal";
import ModalConfirmation from "../../../../components/Modal/ConfirmationModal";
import { ActivityType, ButtonSize, PAGE_ROWS } from "../../../../constants";
import { ElementAdminDotMenuKeys } from "../../../../constants/admin";
import RestaurantForm from "../../../restaurants/RestaurantForm";
import { setSelectedRestaurant } from "../../../restaurants/restaurantSlice";
import {
    deleteRestaurant,
    getAdminRestaurants,
    selectAdminRestaurants,
    updateRestaurant,
} from "../../adminSlice";
import { ElementsAdminTable } from "../components/ElementsAdminTable";
import ElementsReplaceForm from "../components/ElementsReplaceForm";
import { parseMomentUtc } from "../../../../helpers";

export default function AllRestaurantsList(props: any) {
    const dispatch = useAppDispatch();
    const [newPage, setNewPage] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    const restaurants = useAppSelector(selectAdminRestaurants);
    const [startDate, setStartDate] = useState<any>(
        moment().subtract(6, "months").startOf("day").toDate()
    );
    const [elementToReplace, setElementToReplace] = useState<any>(null);
    const [elementToDelete, setElementToDelete] = useState<any>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [endDate, setEndDate] = useState<any>(moment().endOf("day").toDate());
    const [editRestaurantModalOpen, setEditRestaurantModalOpen] =
        useState(false);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, search, startDate, endDate]);

    const getData = () => {
        dispatch(
            getAdminRestaurants(
                PAGE_ROWS,
                newPage.skip,
                search,
                parseMomentUtc(startDate).startOf("day").toDate(),
                parseMomentUtc(endDate).endOf("day").toDate()
            )
        );
    };

    const resetPage = () => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleSearchChange = (query: string) => {
        resetPage();
        setSearch(query);
    };
    const handleStartDateChange = (value: moment.Moment) => {
        resetPage();
        setStartDate(value.startOf("day").toDate());
    };
    const handleEndDateChange = (value: moment.Moment) => {
        resetPage();
        setEndDate(value.startOf("day").toDate());
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const handleClickDotsMenu = (key: string, element: any) => {
        switch (key) {
            case ElementAdminDotMenuKeys.edit:
                dispatch(
                    setSelectedRestaurant({ selectedRestaurant: element })
                );
                setEditRestaurantModalOpen(true);
                break;
            case ElementAdminDotMenuKeys.replace:
                setElementToReplace(element);
                break;
            case ElementAdminDotMenuKeys.delete:
                setElementToDelete(element);
                setDeleteModalOpen(true);
                break;
        }
    };

    const onRemove = () => {
        dispatch(deleteRestaurant(elementToDelete.id));
        setDeleteModalOpen(false);
    };

    const handleNew = () => {
        dispatch(setSelectedRestaurant({ selectedRestaurant: null }));
        setEditRestaurantModalOpen(true);
    };

    return (
        <>
            <Modal
                open={editRestaurantModalOpen}
                onClose={() => {
                    setEditRestaurantModalOpen(false);
                }}
            >
                <RestaurantForm
                    onCancel={() => {
                        setEditRestaurantModalOpen(false);
                    }}
                    onChange={(restaurant: any) => {
                        dispatch(updateRestaurant({ restaurant }));
                        setEditRestaurantModalOpen(false);
                    }}
                />
            </Modal>
            <ModalConfirmation
                open={deleteModalOpen}
                title="Are you sure?"
                description={`You are about to remove ${elementToDelete?.name}`}
                handleCancel={() => {
                    setDeleteModalOpen(false);
                    setElementToDelete(null);
                }}
                handleSubmit={onRemove}
            />
            <div className="flex flex-row justify-end">
                <ButtonSquared
                    size={ButtonSize.SMALL}
                    onClick={handleNew}
                    label="Add new"
                />
            </div>
            {!elementToReplace ? (
                <ElementsAdminTable
                    title="Restaurants List"
                    elements={restaurants}
                    startDate={startDate}
                    endDate={endDate}
                    handleEndDateChange={handleEndDateChange}
                    handleStartDateChange={handleStartDateChange}
                    handlePaginatorChange={handlePaginatorChange}
                    handleSearchChange={handleSearchChange}
                    handleClickDotsMenu={handleClickDotsMenu}
                />
            ) : (
                <ElementsReplaceForm
                    element={elementToReplace}
                    onCancel={() => setElementToReplace(null)}
                    onSubmit={() => setElementToReplace(null)}
                    type={ActivityType.RESTAURANT}
                />
            )}
            {!restaurants?.isLoading && !restaurants?.data?.length && (
                <div className="text-neutral-400 text-sm py-6">
                    There are no restaurants to show.
                </div>
            )}
        </>
    );
}
