import ButtonSquared from "../ButtonSquare";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonSize, ComplaintStatus } from "../../constants";
import ComplaintView from "./ComplaintView";
import TextAreaInput from "../TextArea";
import Icon from "../../assets/svg/Icon";
import { useMemo, useState } from "react";
import ModalConfirmation from "../Modal/ConfirmationModal";
import { normalizeComplaint } from "../../helpers";

interface IComplaintReplyForm {
    complaint: any;
    onCancel: any;
    onSubmit: any;
}

export default function ComplaintReplyForm(props: IComplaintReplyForm) {
    const { complaint, onCancel, onSubmit } = props;
    const [requestReplySubmit, setrequestReplySubmit] = useState<any>();
    const schema = yup
        .object({
            status: yup.string(),
            reply: yup.string().required("Please enter a reply."),
        })
        .required();
    const {
        register,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

        defaultValues: {
            status: complaint?.status,
            reply: complaint?.reply || "",
        },
    });

    const handleSubmitComplaint = (status: ComplaintStatus) => {
        const { reply } = getValues();
        onSubmit && onSubmit({ id: complaint?.id, status, reply });
        setrequestReplySubmit(null);
    };

    const openConfirmationModal = (status: ComplaintStatus) => {
        setrequestReplySubmit(status);
    };

    const disableForm = useMemo(() => {
        return [ComplaintStatus.ACCEPTED, ComplaintStatus.REJECTED].includes(
            complaint?.status
        );
    }, [complaint?.status]);
    const normalizedComplaint = useMemo(() => {
        return complaint && normalizeComplaint(complaint);
    }, [complaint]);

    return (
        <>
            <ModalConfirmation
                open={!!requestReplySubmit}
                title={`${
                    requestReplySubmit === ComplaintStatus.ACCEPTED
                        ? "Accept"
                        : "Reject"
                } request`}
                description={`Are you sure you want to ${
                    requestReplySubmit === ComplaintStatus.ACCEPTED
                        ? "accept"
                        : "reject"
                } ${
                    normalizedComplaint.userFormatted
                }'s request. This action can not be undone.`}
                handleCancel={() => {
                    setrequestReplySubmit(null);
                }}
                okButtonText='Confirm'
                handleSubmit={() => {
                    handleSubmitComplaint(requestReplySubmit);
                }}
            />
            <form className="flex flex-col">
                <div className="flex flex-row mb-6">
                    <div
                        onClick={onCancel}
                        className="text-primary_orange-900 cursor-pointer w-fit flex flex-row items-center"
                    >
                        <Icon
                            icon={"ChevronLeftIcon"}
                            className={`w-7 text-primary_orange-900`}
                        />
                        Back
                    </div>
                </div>
                <div className="text-neutral-900 text-lg text-left font-semibold mb-6">
                    Request Details
                </div>
                <ComplaintView complaint={complaint} setValue={setValue} />

                {(!disableForm || complaint?.reply) && (
                    <div className="w-full mt-6">
                        <TextAreaInput
                            disabled={disableForm}
                            register={register}
                            label="Comments"
                            id="reply"
                            name="reply"
                            error={errors["reply"]?.message}
                        />
                    </div>
                )}

                {!disableForm && (
                    <div className="flex flex-row justify-startgap-2 flex-wrap mt-6 gap-4">
                        <ButtonSquared
                            className="bg-red-600 hover:border-red-500 hover:bg-red-500"
                            size={ButtonSize.SMALL}
                            onClick={() => {
                                openConfirmationModal(ComplaintStatus.REJECTED);
                            }}
                            type="button"
                            label="Reject"
                        />
                        <ButtonSquared
                            className="bg-green-600 hover:border-green-500 hover:bg-green-500"
                            size={ButtonSize.SMALL}
                            label="Accept"
                            type="button"
                            onClick={() => {
                                openConfirmationModal(ComplaintStatus.ACCEPTED);
                            }}
                        />
                    </div>
                )}
            </form>
        </>
    );
}
