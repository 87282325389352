interface IOption {
    label: string;
    key: string;
    disabled?: boolean;
}
export default function ButtonGroup(props: {
    options: { label: string; key: string }[];
    onSelectOption: any;
    selectedOption?: string;
}) {
    const { options, onSelectOption, selectedOption } = props;
    const handleClick = (option: IOption) => {
        !option.disabled &&
            option.key !== selectedOption &&
            onSelectOption(option.key);
    };
    return (
        <div className="flex flex-row">
            {options.map((option, index) => (
                <div
                    onClick={() => handleClick(option)}
                    key={option.key}
                    className={`cursor-pointer hover:bg-neutral-50  border border-neutral-200 py-2 px-4 ${
                        index === 0 ? "rounded-l-lg" : ""
                    } ${index === options.length - 1 ? "rounded-r-lg" : ""}
                    ${
                        selectedOption === option.key
                            ? "bg-neutral-100 border-neutral-100"
                            : ""
                    }`}
                >
                    <span className="font-semibold">{option.label}</span>
                </div>
            ))}
        </div>
    );
}
