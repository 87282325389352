import { UserRolesOptions } from "../../constants";
import SelectInput from "../InputSelect/SelectInput";

interface IChangeRoleReplyProps {
    handleChangeRole: any;
    item: any;
}

export const ChangeRoleReplyForm = (props: IChangeRoleReplyProps) => {
    const { handleChangeRole } = props;
    return (
        <div className="text-left flex flex-col gap-4 mt-6">
            <div className="font-semibold text-neutral-900 text-sm">
                Please select the new role for the user.
            </div>
            <div className="max-w-xs">
                <SelectInput
                    isMulti={false}
                    label="Role"
                    options={UserRolesOptions}
                    id="userRole"
                    name="userRole"
                    defaultValue={props?.item?.data?.role}
                    onChange={handleChangeRole}
                />
            </div>
            <div className="text-sm font-semibold text-neutral-900 mt-2">
                Now, process the change request by updating the status and
                adding a comment to explain your decision.
            </div>
        </div>
    );
};
