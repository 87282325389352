import { useEffect } from "react";
import { UserChatsList } from "./components/UserChatsList";
import { PAGE_SECTIONS } from "../../constants";
import { setCurrentSection } from "../layout/layoutSlice";
import { useAppDispatch } from "../../app/hooks";
import { useConstantsContext } from "../../context/Constants";

const Chat = () => {
    const dispatch = useAppDispatch();
    const { constants } = useConstantsContext();
    useEffect(() => {
        dispatch(setCurrentSection({ currentSection: PAGE_SECTIONS.CHAT }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!(constants?.featureFlags?.FEATURE_CHATS === "true")) {
        return null;
    }
    return <UserChatsList />;
};

export default Chat;
