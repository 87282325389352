import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import DropImageFileInput from "../../../../components/DropImageFileInput/DropImageFileInput";
import Input from "../../../../components/Input/Input";
import TextAreaInput from "../../../../components/TextArea/TextArea";
import {
    addTripCustomInvite,
    getTripCustomInvite,
    selectedTrip,
    selectedTripCustomInvite,
    updateTripCustomInvite,
} from "../../tripSlice";
import ButtonSquared from "../../../../components/ButtonSquare";

const schema = yup
    .object({
        title: yup.string().required("This field is required"),
        description: yup.string().max(5000).required("This field is required"),
    })
    .required("This field is required");

export default function TripCustomInviteForm() {
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState<string | undefined>();
    const navigate = useNavigate();

    const customInvite = useAppSelector(selectedTripCustomInvite);

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const dispatch = useAppDispatch();
    const trip = useAppSelector(selectedTrip);
    let { tripId } = useParams();

    const isUpdate = !!customInvite?.id;

    const imageSrc = useMemo(() => {
        if (customInvite?.image) {
            return customInvite?.image + "&" + new Date().valueOf();
        }
        return undefined;
    }, [customInvite]);

    useEffect(() => {
        if (customInvite?.id) {
            const imageSrc = customInvite?.image
                ? customInvite?.image + "&" + new Date().valueOf()
                : undefined;

            reset({
                title: trip?.title,
                description: customInvite?.description,
            });
            setPreview(imageSrc);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripId, customInvite?.id]);

    useEffect(() => {
        if (!selectedFile) {
            setPreview(isUpdate ? imageSrc : undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile]);

    const handleSubmitForm = async (data: any) => {
        const formData = new FormData();
        if (selectedFile) formData.append("file", selectedFile);
        formData.append("title", trip.title);
        formData.append("description", data.description);

        if (isUpdate) {
            await dispatch(updateTripCustomInvite(Number(tripId), formData));
            await dispatch(getTripCustomInvite(Number(tripId)));
        } else {
            await dispatch(addTripCustomInvite(Number(tripId), formData));
        }
        goBack();
    };

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0]);
    };

    const goBack = () => {
        navigate(`/trips/${tripId}/invites`);
    };

    return (
        <div>
            <div className="flex flex-wrap mb-12">
                <div className="text-left font-semibold text-lg flex-grow text-gray-600">
                    Custom Email Invitation
                </div>
            </div>

            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="grid grid-cols-6 xl:gap-x-32 gap-x-6 gap-y-12 lg:mx-24">
                    <div className="col-span-6">
                        <div className="m-auto rounded-xl">
                            <DropImageFileInput
                                preview={preview}
                                imgComponentClass={
                                    "rounded-xl object-top object-cover"
                                }
                                onChange={onSelectFile}
                            />
                        </div>
                    </div>

                    <div className="col-span-6">
                        <TextAreaInput
                            {...register("description", { required: true })}
                            label="Description"
                            id="description"
                            name="description"
                            comment={"Brief description of the trip"}
                            error={errors["description"]?.message}
                        />
                    </div>
                </div>

                <div className="px-4 pt-24 text-right">
                    <span className="mr-6">
                        <ButtonSquared
                            onClick={goBack}
                            outlined
                            label="Cancel"
                        />
                    </span>

                    <ButtonSquared
                        type="submit"
                        label={isUpdate ? "Save" : "Create"}
                    />
                </div>
            </form>
        </div>
    );
}
