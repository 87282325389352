import { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import {
    selectError,
    signInFacebook,
    signInGoogle,
    signUpEmailPassword,
} from "../userSlice";
import { useSelector } from "react-redux";
import SocialLoginButton from "../../../components/SocialLoginButton";
import * as yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setCurrentSection } from "../../layout/layoutSlice";
import { PAGE_SECTIONS } from "../../../constants";
import Input from "../../../components/Input";
import ButtonSquared from "../../../components/ButtonSquare";
import { Link } from "react-router-dom";
import PasswordRequirements from "./PasswordRequirements";

const schema = yup
    .object({
        firstName: yup.string().min(2).required("Required"),
        lastName: yup.string().min(2).required("Required"),
        email: yup.string().email("Invalid email format").required("Required"),
        password: yup.string().required("Password is required"),
        repeatPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    .required("This field is required");

export function Signup() {
    const dispatch = useAppDispatch();
    const error = useSelector(selectError);
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const password = watch("password");
    useEffect(() => {
        dispatch(setCurrentSection({ currentSection: PAGE_SECTIONS.SIGN_UP }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const signUp = async (data: FieldValues) => {
        const email = data.email?.trim();
        const password = data.password;
        const firstName = data.firstName?.trim();
        const lastName = data.lastName?.trim();
        await dispatch(
            signUpEmailPassword(email, password, firstName, lastName)
        );
    };

    const handleSubmitGoogle = async (e: any) => {
        e.preventDefault();
        if (signInGoogle) {
            dispatch(signInGoogle());
        }
    };

    const handleSubmitFacebook = async (e: any) => {
        e.preventDefault();
        if (signInFacebook) {
            dispatch(signInFacebook());
        }
    };

    return (
        <>
            <div className="min-h-full min-w-full px-4 pt-6 sm:px-6 lg:px-8">
                <h2 className="text-center text-4xl font-bold text-neutral-700">
                    Create Account
                </h2>
                <div className="w-full flex items-start justify-center my-14">
                    <div className="w-80">
                        <div className="flex gap-10 justify-center">
                            <SocialLoginButton
                                onClick={handleSubmitFacebook}
                                icon="FacebookIcon"
                            />
                            <SocialLoginButton
                                onClick={handleSubmitGoogle}
                                icon="GoogleIcon"
                            />
                        </div>
                        <div className="text-neutral-300 text-lg mt-8">
                            or use your email account:
                        </div>
                        <form
                            className="w-full"
                            onSubmit={handleSubmit(signUp)}
                        >
                            <div className="mt-8">
                                <Input
                                    register={register}
                                    label="First Name"
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    placeholder=""
                                    error={errors["firstName"]?.message}
                                />
                            </div>
                            <div className="mt-8">
                                <Input
                                    register={register}
                                    label="Last Name"
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    placeholder=""
                                    error={errors["lastName"]?.message}
                                />
                            </div>
                            <div className="mt-8">
                                <Input
                                    register={register}
                                    label="Email address"
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    placeholder="Email address"
                                    error={errors["email"]?.message}
                                />
                            </div>

                            <div className="mt-8">
                                <Input
                                    register={register}
                                    label="Password"
                                    id="password"
                                    name="password"
                                    type="password"
                                    error={errors["password"]?.message}
                                />
                                <div className="mt-4">
                                    <PasswordRequirements password={password} />
                                </div>
                            </div>

                            <div className="mt-8">
                                <Input
                                    register={register}
                                    label="Repeat Password"
                                    id="repeatPassword"
                                    name="repeatPassword"
                                    type="password"
                                    error={errors["repeatPassword"]?.message}
                                />
                            </div>
                            <div className="my-6">
                                <ButtonSquared label="Sign up" type="submit" />
                                <p className="text-sm	font-semibold text-alert_red text-left mt-4">
                                    {error}
                                </p>
                            </div>
                            <p className="mt-6 text-center text-sm text-neutral-400">
                                Go back to <Link to="/signin">Sign in</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
