import { useState } from "react";
import { UserRequestChangeEmailModal } from "../../../components/Modal/UserRequestChangeEmailModal";

export const RequestChangeEmail = (props: { email: string }) => {
    const [openUserEmailModal, setOpenUserEmailModal] = useState(false);

    const handleCloseUserEmailModal = () => {
        setOpenUserEmailModal(false);
    };

    return (
        <>
            <span
                className="text-sm mt-1 mb-2 text-orange-600 font-bold cursor-pointer whitespace-nowrap break-keep"
                onClick={() => {
                    setOpenUserEmailModal(true);
                }}
            >
                Change
            </span>

            {openUserEmailModal && (
                <UserRequestChangeEmailModal
                    email={props.email}
                    open={openUserEmailModal}
                    handleClose={handleCloseUserEmailModal}
                />
            )}
        </>
    );
};
