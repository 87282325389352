import { useFieldArray, useForm } from "react-hook-form";
import ButtonSquared from "../../../../components/ButtonSquare";
import { useEffect } from "react";
import { PlansPermissionsInputArray } from "../../../plans/constants";
import Input from "../../../../components/Input/Input";
import { useDispatch } from "react-redux";
import { savePlanPermissions } from "../../adminSlice";

export default function PlanPermissionsForm({
    plan,
    onClose,
}: {
    plan: { name: string; planPermissions: any[]; id: number };
    onClose: any;
}) {
    const dispatch = useDispatch();
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({});
    const { fields, append, remove } = useFieldArray({
        control,
        name: "permissions",
    });
    useEffect(() => {
        remove();
        PlansPermissionsInputArray.forEach((p) => {
            let inputValue = p;
            const currentPermission = plan?.planPermissions?.find(
                (per) => per.name === p.name
            );

            if (currentPermission) {
                inputValue.defaultValue =
                    p.inputType === "number"
                        ? Number(currentPermission.value)
                        : currentPermission.value;
            }
            append(inputValue);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan]);

    const handleSubmitForm = async (data: any) => {
        const request = PlansPermissionsInputArray.map((p) => {
            return {
                name: p.name,
                description: p.description,
                value: data[p.name],
            };
        });
        dispatch(savePlanPermissions(plan.id, request, onClose));
    };

    return (
        <div className="lg:mx-24">
            <div className="flex flex-col flex-wrap mb-4">
                <div className="text-left font-semibold text-lg flex-grow mb-6">
                    {plan.name}
                </div>
                <div className="text-left font-semibold text-base flex-grow text-neutral-900">
                    Edit plan permissions
                </div>
            </div>

            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="flex flex-col gap-4">
                    {fields.map((field, index) => {
                        const name = (field as any)?.name;
                        const description = (field as any)?.description;
                        const defaultValue = (field as any)?.defaultValue;
                        const inputType = (field as any)?.inputType;
                        return (
                            <div className="flex flex-col">
                                <Input
                                    label={description}
                                    type={inputType}
                                    defaultValue={defaultValue}
                                    key={field.id} // important to include key with field's id
                                    {...register(name)}
                                    error={errors[name]}
                                    required
                                />
                            </div>
                        );
                    })}
                </div>

                <div className="px-4 pt-24 text-right">
                    <span className="mr-6">
                        <ButtonSquared
                            onClick={onClose}
                            outlined
                            label="Cancel"
                        />
                    </span>

                    <ButtonSquared type="submit" label="Save" />
                </div>
            </form>
        </div>
    );
}
