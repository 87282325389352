import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import {
    activityFormCurrentStep,
    selectActivityForm,
    selectedTrip,
    submitActivityFormStep,
} from "../../../../../tripSlice";
import {
    selectedHotel,
    setSelectedHotel,
} from "../../../../../../hotels/hotelSlice";
import { ActivityType, ButtonSize, PAGE_SECTIONS } from "../../../../../../../constants";
import Modal, { Modal_Size } from "../../../../../../../components/Modal";
import HotelsAutocompleteWithValid from "../../../../../../../components/HotelsAutocomplete";
import HotelForm from "../../../../../../hotels/HotelForm";
import ButtonSquared from "../../../../../../../components/ButtonSquare";
import SelectFromLibraryModal from "../../../Components/SelectFromLibrary";
import { useConstantsContext } from "../../../../../../../context/Constants";

const schema = yup.object({
    hotel: yup
        .object({
            googlePlaceId: yup.string().nullable(),
            streetNumber: yup.string().nullable(),
            streetAddress: yup.string().nullable(),
            zipCode: yup.string().nullable(),
            city: yup.string().nullable(),
            state: yup.string().nullable(),
            website: yup.string().nullable(),
            phone: yup.string().nullable(),
            country: yup.string().required("This field is required"),
            fullAddress: yup.string().required("This field is required"),
            lat: yup.number().required("This field is required"),
            lng: yup.number().required("This field is required"),
        })
        .required("The full hotel address is required"),
});

const HotelSelectionForm = React.forwardRef((props: any, ref: any) => {
    const activityForm = useAppSelector(selectActivityForm);
    const [searchValue, setSearchValue] = useState<any>("");
    const [city, setCity] = useState<any>(activityForm?.city);
    const [addHotelModalOpen, setAddHotelModalOpen] = useState(false);
    const [selectFromLibraryModal, setSelectFromLibraryModal] = useState(false);
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    let { activityType } = useParams();
    const { constants } = useConstantsContext();
    const { isLibraryElement } = props;
    const currentStep = useAppSelector(activityFormCurrentStep);
    const currentHotel = useAppSelector(selectedHotel);

    const { formState, handleSubmit, control, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    const { isValid } = formState;

    useEffect(() => {
        setValue("hotel", currentHotel);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentHotel?.id, currentHotel?.name]);

    useEffect(() => {
        dispatch(
            setSelectedHotel({ selectedHotel: activityForm?.hotel || null })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityForm?.hotel]);

    const handleSubmitForm = (data: any) => {
        saveData(data?.hotel, activityType);
    };

    const saveData = (hotel: any, actType: string | undefined) => {
        const activity: any = {
            type: actType,
            hotel: hotel,
            city: city,
        };

        dispatch(
            submitActivityFormStep(
                activity,
                activityType || ActivityType.HOTEL,
                currentStep || ""
            )
        );
    };

    const onSelectFromLibrary = (element: any) => {
        dispatch(setSelectedHotel({ selectedHotel: element.hotel }));
        element.type = activityType || ActivityType.HOTEL;
        dispatch(
            submitActivityFormStep(
                element,
                activityType || ActivityType.HOTEL,
                currentStep || ""
            )
        );
        setSelectFromLibraryModal(false);
    }

    const handleOpenSelectFromLibraryModal = () => {
        dispatch(setSelectedHotel({ selectedHotel: null }));
        setSelectFromLibraryModal(true);
    }

    const handleSelectHotel = (hotel: any, setSearchResults?: Function) => {
        setSearchResults && setSearchResults([]);
        if (hotel) saveData(hotel, activityType);
    };

    const handleHotelModalToggle = (open: boolean) => {
        setAddHotelModalOpen(open);
    };

    const handleOnchangeHotelSearch = (val: any) => {
        setSearchValue(val);
    };

    return (
        <div className="lg:min-h-full lg:pb-16" ref={ref}>
            <Modal
                open={addHotelModalOpen}
                size={Modal_Size.lg}
                onClose={() => {
                    handleHotelModalToggle(false);
                }}
            >
                <HotelForm
                    name={searchValue}
                    onCancel={() => {
                        handleHotelModalToggle(false);
                    }}
                    onChange={(newHotel: any) => {
                        handleHotelModalToggle(false);
                        handleSelectHotel(newHotel);
                    }}
                />
            </Modal>
            <SelectFromLibraryModal
                isOpen={selectFromLibraryModal}
                onClose={() => setSelectFromLibraryModal(false)}
                activityType={ActivityType.HOTEL}
                onSelectFromLibrary={onSelectFromLibrary}
            />
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
                className="text-left lg:px-6 lg:min-h-full"
            >
                <div className="text-lg font-semibold mb-6">
                    Search for the hotel you would like to visit
                </div>

                <div className="">
                    <HotelsAutocompleteWithValid
                        control={control}
                        name="hotel"
                        className={`py-2 pl-12 !border-[.5px] rounded-[40px] border-[#E5E5E5] block w-full text-base font-normal bg-[left_12px_top_12px] !bg-[url('./assets/svg/MagnifyIcon.svg')] bg-no-repeat`}
                        handleSelect={handleSelectHotel}
                        onTextChange={handleOnchangeHotelSearch}
                        type="text"
                        defaultValue={activityForm?.hotel || currentHotel}
                        placeholder="Search"
                        city={city}
                        onChangeCity={(city: any) => {
                            setCity(city);
                        }}
                    />
                </div>

                {searchValue && (
                    <div className="ml-4 mt-4 flex text-sm">
                        Couldn't find the hotel? You can{" "}
                        <span
                            onClick={() => {
                                dispatch(
                                    setSelectedHotel({ selectedHotel: null })
                                );
                                handleHotelModalToggle(true);
                            }}
                            className="mx-2 cursor-pointer font-semibold text-orange-600"
                        >
                            add
                        </span>
                        one
                    </div>
                )}
                {constants?.featureFlags?.LIBRARY && !isLibraryElement && !searchValue && !!trip?.organization?.id && (
                    <div className="mt-8">
                        <div className="text-lg font-semibold mb-6">
                            Or choose from library
                        </div>
                        <ButtonSquared
                            outlined
                            label="Select element from library"
                            onClick={handleOpenSelectFromLibraryModal}
                            size={ButtonSize.FIT}
                            className="py-2 px-7"
                        />
                    </div>
                )}
                <div className="flex gap-3 justify-end px-4 py-3 text-right mt-12 lg:absolute right-4 bottom-4">
                    <ButtonSquared
                        onClick={props.navigateBack}
                        outlined
                        type="button"
                        label="Cancel"
                    />
                    <ButtonSquared
                        type="submit"
                        label="Next"
                        disabled={!isValid}
                    />
                </div>
            </form>
        </div>
    );
});

export default HotelSelectionForm;
