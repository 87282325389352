import React from 'react';
import Icon from '../../../../../../../../assets/svg/Icon';


const SidebarMenu = ({
    title,
    sections,
    currentStep
}: any) => {

    return (
        <div className="w-full">
            <div className="text-neutral-400 font-medium mb-4">{title}</div>
            {sections.map((section: any) => (
                <div
                    key={section.id}
                    className={`${
                        currentStep === section.id
                            ? "text-primary_orange-500 bg-rose"
                            : "text-neutral-900 hover:text-primary_orange-500"
                    } cursor-pointer rounded-lg px-3 py-4 font-semibold flex justify-between w-full`}
                    onClick={section.onClick}
                >
                    {section.label}
                    <Icon className="w-[25px]" icon={"ChevronRightIcon"} />
                </div>
            ))}
        </div>
    );
}

export default SidebarMenu;