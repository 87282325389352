/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Button from "../../components/ButtonSquare";
import Modal from "../../components/Modal";
import HotelForm from "./HotelForm";
import { getHotel, selectedHotel } from "./hotelSlice";

export default function HotelDetail() {
    const [editHotelModalOpen, setEditHotelModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    const hotel = useAppSelector(selectedHotel);
    let { hotelId } = useParams();

    useEffect(() => {
        if (hotelId) {
            dispatch(getHotel( Number(hotelId)));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotelId]);

    const handleEditHotelClick = (e: any) => {
        setEditHotelModalOpen(true);
    };

    return (
        <>
            <Modal
                open={editHotelModalOpen}
                onClose={() => {
                    setEditHotelModalOpen(false);
                }}
            >
                <HotelForm
                    onCancel={() => {
                        setEditHotelModalOpen(false);
                    }}
                    onChange={() => {
                        setEditHotelModalOpen(false);
                    }}
                />
            </Modal>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="min-w-full flex justify-end py-2 px-2">
                    <Button label="Edit" onClick={handleEditHotelClick} />
                </div>
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-left">
                        Hotel Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500 text-left">
                        Full details.
                    </p>
                </div>

                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Name
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {hotel?.name}
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Description
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre break-words">
                                {hotel?.description}
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Address
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {hotel?.fullAddress}
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Phone
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {hotel?.phone}
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Contact
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {hotel?.contact}
                            </dd>
                        </div>
                        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Created At
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {hotel?.createdAt}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </>
    );
}
