import { useMemo, useState } from "react";
import Icon from "../../assets/svg/Icon";

interface StarsRatingProps {
    rating?: number;
    className?: string;
    onChange?: any;
    error?: string;
}

export default function StarsRating({
    rating = 0,
    className,
    onChange,
    error,
}: StarsRatingProps) {
    const styles = className || "w-4";
    const [currentStarHovered, setCurrentStarHovered] = useState(-1);
    const handleClick = (value: number) => {
        onChange && onChange(value);
    };

    const renderError = useMemo(() => {
        if (error?.length) {
            return (
                <div className="border-orange-500 text-orange-700" role="alert">
                    <p>{error}</p>
                </div>
            );
        }
    }, [error]);
    return (
        <>
            <div className="flex">
                {[1, 2, 3, 4, 5].map((i) => (
                    <div key={i}>
                        <Icon
                            icon={"StarIcon"}
                            onClick={() => handleClick(i)}
                            onMouseOver={() => setCurrentStarHovered(i)}
                            onMouseLeave={() => setCurrentStarHovered(-1)}
                            className={`${styles} ${
                                rating >= i || currentStarHovered >= i
                                    ? "text-orange-600"
                                    : "text-neutral-300"
                            } hover:text-orange-800`}
                        />
                    </div>
                ))}
            </div>
            {renderError}
        </>
    );
}
