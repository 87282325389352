import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentBreakpoint } from "../../../layout/layoutSlice";
import AllTripsList from "../AllTripsList";
import AllTripsTable from "../AllTripsTable";

interface TripsTableI {
    trips: any;
    handlePaginatorChange: any;
    handleClickDotsMenu: any
}
export const TripsTable = (props: TripsTableI) => {
    const {trips, handlePaginatorChange, handleClickDotsMenu} = props;
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);

    if (isDesktop) return <AllTripsTable
        trips={trips}
        handlePaginatorChange={handlePaginatorChange}
        handleClickDotsMenu={handleClickDotsMenu}
    />

    return <AllTripsList
            trips={trips}
            handlePaginatorChange={handlePaginatorChange}
            handleClickDotsMenu={handleClickDotsMenu}
        />
}