import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { useAuthStateContext } from "../../../context/Auth";
import { getRestaurant } from "../restaurantSlice";

export default function RestaurantHome(props: any) {
    const dispatch = useAppDispatch();
    let { restaurantId } = useParams();

    useEffect(() => {
        if (restaurantId) {
            dispatch(getRestaurant(Number(restaurantId)));
        }
    }, [restaurantId]);

    return <Outlet />;
}
