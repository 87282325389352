import moment from "moment";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import { useAppSelector } from "../../../../app/hooks";
import DatePickerMaterial from "../../../../components/DatePickerMaterial/DatePickerMaterial";
import InputSearch from "../../../../components/InputSearch";
import SelectInput from "../../../../components/InputSelect/SelectInput";
import { ActivityTypeFilterOptions, PAGE_ROWS } from "../../../../constants";
import { IPaginatedData } from "../../../../types/dataTypes";
import { selectCurrentBreakpoint } from "../../../layout/layoutSlice";
import InsightsResponsiveList from "./InsightsResponsiveList";
import InsightsTable from "./InsightsTable";
import { parseMomentUtcToLocal } from "../../../../helpers";

interface InsightsListProps {
    DotMenuOptions: any;
    fetchData: any;
    handleClickDotsMenu: any;
    insights: IPaginatedData;
    insightsNormalizer: any;
    onInsightClick?: any;
}

export const InsightsList = forwardRef((props: InsightsListProps, ref) => {
    const {
        DotMenuOptions,
        fetchData,
        handleClickDotsMenu,
        insights,
        insightsNormalizer,
        onInsightClick,
    } = props;

    const [newPage, setNewPage] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [startDate, setStartDate] = useState<any>(
        moment().subtract(6, "months").startOf("day").toDate()
    );
    const [endDate, setEndDate] = useState<any>(moment().endOf("day").toDate());
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);

    useImperativeHandle(ref, () => ({
        reFetch() {
            getData();
        },
    }));

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, search, type, startDate, endDate]);

    const getData = () => {
        fetchData({
            take: PAGE_ROWS,
            skip: newPage.skip,
            type,
            search,
            startDate,
            endDate: parseMomentUtcToLocal(endDate).endOf("day").toDate(),
        });
    };

    const resetPage = () => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleSearchChange = (query: string) => {
        resetPage();
        setSearch(query);
    };
    const handleElementFilterChange = (value: string) => {
        const formattedValue = value === "ALL" ? "" : value;
        resetPage();
        setType(formattedValue);
    };
    const handleStartDateChange = (value: moment.Moment) => {
        resetPage();
        setStartDate(value.startOf("day").toDate());
    };
    const handleEndDateChange = (value: moment.Moment) => {
        resetPage();
        setEndDate(value.startOf("day").toDate());
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const insightsNormalized = useMemo(() => {
        return {
            ...insights,
            data: (insights?.data || [])?.map((insight) => {
                return insightsNormalizer
                    ? insightsNormalizer(insight)
                    : insights.data;
            }),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insights, insightsNormalizer]);

    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-1 lg:grid-cols-6 items-end gap-10">
                <div className="col-span-1 lg:col-span-2">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>

                <div className="col-span-1">
                    <DatePickerMaterial
                        name="startDate"
                        value={startDate}
                        id="startDate"
                        label="From date"
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className="col-span-1">
                    <DatePickerMaterial
                        name="endDate"
                        value={endDate}
                        id="startDate"
                        label="To date"
                        onChange={handleEndDateChange}
                    />
                </div>
                <div className="col-span-1">
                    <SelectInput
                        onChange={handleElementFilterChange}
                        label="Element"
                        options={ActivityTypeFilterOptions}
                        defaultValue={ActivityTypeFilterOptions[0].value}
                    />
                </div>
            </div>
            {!isDesktop ? (
                <InsightsResponsiveList
                    onInsightClick={onInsightClick}
                    insights={insightsNormalized}
                    handlePaginatorChange={handlePaginatorChange}
                    DotMenuOptions={DotMenuOptions}
                    handleClickDotsMenu={handleClickDotsMenu}
                />
            ) : (
                <InsightsTable
                    onInsightClick={onInsightClick}
                    insights={insightsNormalized}
                    handlePaginatorChange={handlePaginatorChange}
                    DotMenuOptions={DotMenuOptions}
                    handleClickDotsMenu={handleClickDotsMenu}
                />
            )}
            {!insightsNormalized.isLoading &&
                !insightsNormalized?.data.length && (
                    <div className="text-neutral-400 text-sm py-6">
                        There are no insights to show.
                    </div>
                )}
        </div>
    );
});

export default InsightsList;
