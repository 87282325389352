import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
    activityFormCurrentStep,
    backFormStep,
    selectActivityForm,
    submitActivityFormStep,
    uploadTempFile,
} from "../../../../tripSlice";
import { ActivityType } from "../../../../../../constants";
import DropImageFileInput from "../../../../../../components/DropImageFileInput/DropImageFileInput";
import ProgressBar from "../../../../../../components/ProgressBar/ProgressBar";
import GalleryImage from "../../../../../../components/ImagesGallery/Image";
import DropFileInput from "../../../../../../components/DropFileInput/DropFileInput";
import fileImage from "../../../../../../assets/images/files.png";
import FilePreview from "../../../../../../components/DropFileInput/FilePreview";
import ButtonSquared from "../../../../../../components/ButtonSquare";
import { resizeImageFile } from "../../../../../../helpers";

const schema = yup.object({
    contacts: yup.array(),
});

export default function ActivityDetailsForm(props: any) {
    const [imagesProgress, setImagesProgress] = useState<any[]>([]);
    const [filesProgress, setFilesProgress] = useState<any[]>([]);
    const [tempFiles, setTempFiles] = useState<any[]>([]);
    const [tempImages, setTempImages] = useState<any[]>([]);
    const [libraryElementImages, setLibraryElementImages] = useState<any[]>([]);
    const [libraryElementFiles, setLibraryElementFiles] = useState<any[]>([]);
    const dispatch = useAppDispatch();
    const activityForm = useAppSelector(selectActivityForm);
    const currentStep = useAppSelector(activityFormCurrentStep);
    const { formState, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    const { isValid } = formState;
    let { activityType } = useParams();
    const { type, images, files, imagesFromLibraryElement, filesFromLibraryElement } = activityForm;
    useEffect(() => {
        const imgTmps = images || [];
        const filesTmps = files || [];
        setTempImages((prev) => {
            return imgTmps;
        });
        setTempFiles((prev) => {
            return filesTmps;
        });
        setLibraryElementImages(imagesFromLibraryElement || []);
        setLibraryElementFiles(filesFromLibraryElement || []);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmitForm = async (data: any) => {
        const activity: any = {
            images: tempImages || [],
            files: tempFiles || [],
            imagesFromLibraryElement: libraryElementImages || [],
            filesFromLibraryElement: libraryElementFiles || [],
        };

        dispatch(
            submitActivityFormStep(
                activity,
                activityType || ActivityType.HOTEL,
                currentStep || ""
            )
        );
    };

    const navigateBack = () => {
        dispatch(backFormStep(type, currentStep || ""));
    };

    const onImageProgress = (id: number, progress: number) => {
        setImagesProgress((prevImgProgress) => {
            return prevImgProgress.map((img) => {
                return {
                    ...img,
                    progress: id === img.id ? progress : img.progress,
                };
            });
        });
    };

    const onFileProgress = (id: number, progress: number) => {
        setFilesProgress((prevFilePogressArray) => {
            return prevFilePogressArray.map((file) => {
                return {
                    ...file,
                    progress: id === file.id ? progress : file.progress,
                };
            });
        });
    };

    const removeTempImage = (id: number) => {
        let index = tempImages.findIndex((img) => img.id === id);
        URL.revokeObjectURL(tempImages[index].file);
        setTempImages((prevTmpStateArray) => {
            return prevTmpStateArray.filter((img) => img.id !== id);
        });
    };

    const removeTempFile = (id: number) => {
        let index = tempFiles.findIndex((img) => img.id === id);
        URL.revokeObjectURL(tempFiles[index].file);
        setTempFiles((prevTmpStateArray) => {
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const removeProgressImage = (id: number, cancel = false) => {
        setImagesProgress((prevTmpStateArray) => {
            const index = prevTmpStateArray.findIndex((img) => img.id === id);
            URL.revokeObjectURL(prevTmpStateArray[index]?.file);
            cancel && prevTmpStateArray[index].cancelToken.cancel();
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const removeProgressFile = (id: number, cancel = false) => {
        setFilesProgress((prevTmpStateArray) => {
            const index = prevTmpStateArray.findIndex((file) => file.id === id);
            cancel && prevTmpStateArray[index].cancelToken.cancel();
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const removeLibraryElementImage = (name: string) => {
        setLibraryElementImages((prevTmpStateArray) => {
            return prevTmpStateArray.filter((img) => img.originalName !== name);
        });
    }

    const removeLibraryElementFile = (name: string) => {
        setLibraryElementFiles((prevTmpStateArray) => {
            return prevTmpStateArray.filter((file) => file.originalName !== name);
        });
    }

    const fileCounter = useMemo(() => {
        return tempFiles.length + tempImages.length;
    }, [tempFiles.length, tempImages.length]);

    const onSelectImages = async (e: any) => {
        if (e.target.files) {
            const filesArray: any[] = Array.from(e.target.files);
            for (const file of filesArray) {
                const imageResized = await resizeImageFile(file);
                const newtempImg = {
                    id: fileCounter,
                    file: imageResized,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };
                setImagesProgress((prevArray) => {
                    return [...prevArray, newtempImg];
                });

                uploadTempFile(
                    imageResized,
                    newtempImg.id,
                    onImageProgress,
                    newtempImg.cancelToken
                ).then((response) => {
                    removeProgressImage(newtempImg.id);
                    setTempImages((prev) => {
                        return [
                            ...prev,
                            {
                                file: URL.createObjectURL(imageResized),
                                path: response,
                                id: newtempImg.id,
                            },
                        ];
                    });
                });
            }

            return;
        }
    };

    const onSelectFiles = (e: any) => {
        if (e.target.files) {
            Array.from(e.target.files).forEach((file: any, index: number) => {
                const newtempFile = {
                    id: fileCounter,
                    file: file,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };
                setFilesProgress((prevArray) => {
                    return [...prevArray, newtempFile];
                });

                uploadTempFile(
                    e.target.files[index],
                    newtempFile.id,
                    onFileProgress,
                    newtempFile.cancelToken
                ).then((response) => {
                    removeProgressFile(newtempFile.id);
                    setTempFiles((prev) => {
                        return [
                            ...prev,
                            {
                                file: file,
                                path: response,
                                id: newtempFile.id,
                            },
                        ];
                    });
                });
            });
            return;
        }
    };

    return (
        <form
            action="#"
            method="POST"
            onSubmit={handleSubmit(handleSubmitForm)}
            className="text-left lg:px-6 pb-16"
        >
            <div className="mb-12 grid grid-cols-6 gap-x-12">
                <div className="text-sm font-semibold mt-8 mb-4">Photos</div>
                <div className="col-span-6">
                    <DropImageFileInput
                        iconClass={"w-16"}
                        className={"!p-4"}
                        multiple={true}
                        onChange={onSelectImages}
                    />
                </div>
                <div className="col-span-6">
                    {imagesProgress?.map((img) => {
                        return (
                            <ProgressBar
                                key={img.id}
                                progress={img.progress}
                                name={img.file.name}
                                onRemove={() =>
                                    removeProgressImage(img.id, true)
                                }
                            />
                        );
                    })}
                </div>

                <div className="w-full flex flex-wrap gap-4 mt-8 col-span-6">
                    {tempImages?.map((i) => {
                        return (
                            <div className="">
                                <GalleryImage
                                    key={i.id}
                                    src={i.file}
                                    onImageClick={() => {}}
                                    imageClass="object-cover"
                                    // onImageAdd={(e: any) => handleImageAdd(index)}
                                    onImageRemove={() => {
                                        removeTempImage(i.id);
                                    }}
                                    description={i?.description}
                                />
                            </div>
                        );
                    })}
                    {libraryElementImages?.map((i: any) => {
                        return (
                            <div className="">
                                <GalleryImage
                                    key={i.id}
                                    src={i.url}
                                    thumb={i.thumb_url}
                                    onImageClick={() => {}}
                                    imageClass="object-cover"
                                    // onImageAdd={(e: any) => handleImageAdd(index)}
                                    onImageRemove={() => {
                                        removeLibraryElementImage(i.originalName);
                                    }}
                                    description={i?.description}
                                />
                            </div>
                        );
                    })}
                </div>

                <div className="text-sm font-semibold mt-8 mb-4">Files</div>
                <div className="col-span-6">
                    <DropFileInput
                        iconSrc={fileImage}
                        iconClass={"w-16"}
                        className={"!p-4"}
                        multiple={true}
                        onChange={onSelectFiles}
                    />
                </div>
                <div className="col-span-6">
                    {filesProgress.map((file) => {
                        return (
                            <ProgressBar
                                key={file.id}
                                progress={file.progress}
                                name={file.file.name}
                                onRemove={() =>
                                    removeProgressFile(file.id, true)
                                }
                            />
                        );
                    })}
                </div>

                <div className="flex flex-wrap gap-4 mt-8 col-span-6">
                    {tempFiles?.map((file) => {
                        return (
                            <FilePreview
                                key={file.id}
                                onRemove={() => {
                                    removeTempFile(file.id);
                                }}
                                name={file.file.name}
                            />
                        );
                    })}
                    {libraryElementFiles?.map((file: any) => {
                        return (
                            <FilePreview
                                key={file.id}
                                name={file.originalName}
                                onRemove={() => {
                                    removeLibraryElementFile(file.originalName);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="flex gap-3 justify-end px-4 py-3 text-right mt-12 lg:absolute right-4 bottom-4">
                <ButtonSquared
                    onClick={navigateBack}
                    outlined
                    type="button"
                    label="Back"
                />
                <ButtonSquared type="submit" label="Next" disabled={!isValid} />
            </div>
        </form>
    );
}
