import { useState, useEffect } from "react";
import { ILocation } from "../constants";

// Recuerda siempre usar la palabra "use" al principio de cada custom hook
const useGoogleMaps = () => {
    const [isLoading, setIsloading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    //const [map, setMap] = useState<any>();
    const [placesService, setPlacesService] =
        useState<google.maps.places.PlacesService>();
    const [autoCompleteService, setAutoCompleteService] =
        useState<google.maps.places.AutocompleteService>();
    useEffect(() => {
        initialize();
    }, []);

    const initialize = () => {
        var pyrmont = new google.maps.LatLng(-33.8665433, 151.1956316);

        const m = new google.maps.Map(document.createElement("div"), {
            center: pyrmont,
            zoom: 15,
        });
        // setMap(m);

        const service = new google.maps.places.PlacesService(m);
        setPlacesService(service);
        setAutoCompleteService(new google.maps.places.AutocompleteService());
        setInitialized(true);
    };

    const getLatLng = (place: any) => {
        const { lat, lng } = place?.geometry?.location || {};
        return {
            lat: lat && lat(),
            lng: lng && lng(),
        };
    };

    const fillInAddress = (place: any) => {
        const location = getLatLng(place);
        const fullAddress = place.formatted_address;
        const addressC = place?.address_components || [];
        let addressComponents: any = {};
        addressC.forEach((component: any) => {
            const { types, ...rest } = component;
            const aI = component?.types[0];
            addressComponents[aI] = {
                ...rest,
            };
        });
        let locationNormalized: ILocation = {
            streetNumber:
                addressComponents["street_number"]?.long_name ||
                addressComponents["street_number"]?.short_name,
            streetAddress:
                addressComponents["route"]?.long_name ||
                addressComponents["route"]?.short_name,
            zipCode:
                addressComponents["postal_code"]?.long_name ||
                addressComponents["postal_code"]?.short_name,
            city:
                addressComponents["locality"]?.long_name ||
                addressComponents["locality"]?.short_name ||
                addressComponents["administrative_area_level_2"]?.long_name ||
                addressComponents["administrative_area_level_2"]?.short_name ||
                addressComponents["sublocality"]?.long_name ||
                addressComponents["sublocality"]?.short_name,
            state:
                addressComponents["administrative_area_level_1"]?.long_name ||
                addressComponents["administrative_area_level_1"]?.short_name,
            country:
                addressComponents["country"]?.long_name ||
                addressComponents["country"]?.short_name,
            lat: location.lat,
            lng: location.lng,
            fullAddress: fullAddress,
            name: place.name,
            googlePlaceId: place.place_id,
            images: place.images,
            website: place.website,
        };
        return JSON.parse(JSON.stringify(locationNormalized));
    };

    const getPlacePredictions = (text: string, opts = {}) =>
        new Promise((resolve, reject) => {
            try {
                setIsloading(true);
                if (placesService) {
                    var request = {
                        input: text,
                        language: 'en',
                        ...opts,
                    };
                    autoCompleteService?.getPlacePredictions(
                        request,
                        function (results: any, status: any) {
                            if (
                                status ===
                                google.maps.places.PlacesServiceStatus.OK
                            ) {
                                setIsloading(false);
                                return resolve(results);
                            }
                            setIsloading(false);
                            return reject([]);
                        }
                    );
                } else {
                    setIsloading(false);
                    return reject([]);
                }
            } catch (error) {
                setIsloading(false);
                return reject([]);
            }
        });
    const findPlaces = (text: string, opts = {}) =>
        new Promise((resolve, reject) => {
            try {
                setIsloading(true);
                if (placesService) {
                    var request = {
                        language: 'en',
                        query: text,
                        fields: [
                            "formatted_address",
                            "name",
                            "place_id",
                            "types",
                            "geometry",
                        ],
                        ...opts
                    };

                    placesService?.findPlaceFromQuery(
                        request,
                        function (results: any, status: any) {
                            if (
                                status ===
                                google.maps.places.PlacesServiceStatus.OK
                            ) {
                                const resultsNormalized =
                                    results &&
                                    results.map((r: any) => ({
                                        ...r,
                                        googlePlaceId: r.place_id,
                                        id: r.place_id,
                                        formatted_address: r.formatted_address,
                                    }));
                                setIsloading(false);
                                return resolve(resultsNormalized);
                            }
                            setIsloading(false);
                            return reject([]);
                        }
                    );
                } else {
                    setIsloading(false);
                    return reject([]);
                }
            } catch (error) {
                setIsloading(false);
                return reject([]);
            }
        });

    const getPlaceDetails = async (
        placeId: string,
        imagesOptions: object = {}
    ) =>
        new Promise((resolve, reject) => {
            try {
                setIsloading(true);
                var request = {
                    language: 'en',
                    placeId: placeId,
                    fields: [
                        "place_id",
                        "name",
                        "address_components",
                        "geometry",
                        "photos",
                        "formatted_address",
                        "website",
                    ],
                };
                if (placesService) {
                    placesService.getDetails(
                        request,
                        async (place: any, status: any) => {
                            setIsloading(false);
                            resolve(fillInAddress({ ...place, images: [] }));
                        }
                    );
                } else {
                    setIsloading(false);
                    reject({});
                }
            } catch (error) {
                setIsloading(false);
                reject(error);
            }
        });

    const getPhotos = async (
        place: any,
        options: object = {},
        max: number = 5
    ) => {
        setIsloading(false);
        let promises = [];
        const length = place?.photos?.length
            ? place?.photos?.length < max
                ? place?.photos?.length
                : max
            : 0;
        for (let index = 0; index < length; index++) {
            promises.push(place.photos[index].getUrl());
        }
        const responses = await Promise.all(promises);
        setIsloading(false);
        return responses;
    };
    return {
        findPlaces,
        getLatLng,
        getPlaceDetails,
        isLoading,
        initialized,
        getPhotos,
        getPlacePredictions
    };
};

export default useGoogleMaps;
