import Icon from "../../assets/svg/Icon";
import { PAGE_SECTIONS } from "../../constants";
import { SidebarOption } from "./SidebarOption";

interface IBottomBar {
    items: Array<any>;
    basePath: string;
    currentSection: PAGE_SECTIONS | null;
    handleSignOut?: any;
}

function BottomBar(props: IBottomBar) {
    const { items, basePath, currentSection, handleSignOut } = props;

    return (
        <div className="w-full lg:hidden py-5 px-5 bg-neutral-100 shadow-lg flex flex-row items-center justify-between fixed bottom-0 z-[100]">
            {items?.map((i: any, index: number) => {
                return (
                    <SidebarOption
                        key={`sidebar_${i.key}${index}`}
                        basePath={basePath}
                        icon={i.icon}
                        label={i.label}
                        url={i.url}
                        selected={i.key === currentSection}
                    />
                );
            })}
            {currentSection === PAGE_SECTIONS.PROFILE && (
                <div
                    onClick={handleSignOut}
                    className={`no-underline flex items-center text-left gap-4 text-base font-semibold text-neutral-400 hover:text-primary_orange-900 cursor-pointer`}
                >
                    <Icon icon={"LogOutIcon"} className={`w-6`} />
                    <span className="hidden lg:block">{"Log Out"}</span>
                </div>
            )}
        </div>
    );
}

export default BottomBar;
