import { getUserOnTripMenuOptions } from "../../../../bussiness/trip";
import ListWithPagination from "../../../../components/ListWithPagination";
import ParticipantsListCard from "../../../../components/ParticipantListCard/ParticipantListCard";
import {
    TripUserRoleOptions,
    UserRoleOnTrip,
    UserStatusOnTrip,
} from "../../../../constants";

interface ITripUsersList {
    tripUsers: any;
    canEdit: boolean;
    meOnTrip: any;
    handlePaginatorChange: any;
    handleUserClick: Function;
    handleClickUserDotsMenu: Function;
    isAdmin: boolean;
}
export default function TripUsersList({
    tripUsers,
    meOnTrip,
    canEdit,
    handlePaginatorChange,
    handleUserClick,
    handleClickUserDotsMenu,
    isAdmin,
}: ITripUsersList) {
    const ItemRender = ({ item }: any) => {
        const isOwner = item.role === UserRoleOnTrip.OWNER;
        const confirmedUser = item.status === UserStatusOnTrip.CONFIRMED;
        const roleLabel = TripUserRoleOptions.find(
            (s) => s.value === item?.role
        )?.label;
        return (
            <ParticipantsListCard
                user={item}
                handleClickUserDotsMenu={
                    (!isOwner && canEdit) ? handleClickUserDotsMenu : undefined
                }
                handleUserClick={handleUserClick}
                dotMenuOptions={getUserOnTripMenuOptions(meOnTrip, item, isAdmin)}
                showRole={confirmedUser}
                showStatus={!confirmedUser}
                roleLabel={roleLabel}
            />
        );
    };
    return (
        <div>
            <ListWithPagination
                itemKey={(item: any) => `tripUserItem_${item?.userEmail}`}
                items={tripUsers}
                handlePaginatorChange={handlePaginatorChange}
                renderItem={ItemRender}
            />
        </div>
    );
}
