import ButtonSquared from "../ButtonSquare";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextAreaInput from "../TextArea";
import { ComplaintReasonsOptions, ComplaintType, ComplaintTypeToShow } from "../../constants";
import { useDispatch } from "react-redux";
import { reportComplaint } from "../../features/admin/adminSlice";
import SelectInput from "../InputSelect";

interface IReportComplaintForm {
    title: string;
    complaintType: ComplaintType;
    itemId: any;
    onClose: any;
}

export default function ReportComplaintForm(props: IReportComplaintForm) {
    const { onClose, itemId, complaintType, title } = props;
    const dispatch = useDispatch();
    const schema = yup
        .object({
            comments: yup.string().max(500).nullable(),
            reason: yup.string().max(500).required("This field is required"),
        })
        .required();
    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),

        defaultValues: {
            comments: undefined,
            reason: undefined
        },
    });

    const handleSubmitComplaint = (data: any) => {
        const payload = {
            comments: data?.comments,
            reason: data?.reason,
            itemType: complaintType,
            itemId,
            title,
        };
        dispatch(reportComplaint(payload));
        onClose();
    };

    return (
        <>
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitComplaint)}
                className="flex flex-col"
            >
                <div className="text-base font-semibold mb-4 truncate">
                    Report {ComplaintTypeToShow[complaintType]} - {title}
                </div>
                <div className="col-span-6 md:col-span-4 mb-4">
                <SelectInput
                            control={control}
                            isMulti={false}
                            register={register}
                            label="Reason"
                            placeholder="Select reason"
                            options={ComplaintReasonsOptions}
                            id="reason"
                            name="reason"
                            error={errors["reason"]?.message}
                        />
                </div>
                <div className="col-span-6 md:col-span-4 mb-4">
                    <TextAreaInput
                        register={register}
                        label="Comments"
                        type="text"
                        id="comments"
                        name="comments"
                        error={errors["comments"]?.message}
                    />
                </div>
                <div className="flex flex-row justify-end gap-2 flex-wrap">
                    <ButtonSquared outlined onClick={onClose} label="Cancel" />
                    <ButtonSquared type="submit" label="Save" />
                </div>
            </form>
        </>
    );
}
