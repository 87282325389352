import { useEffect, useRef, useState } from "react";
import { IMarker } from "../../constants";

const GoogleMap: React.FC<{ marker?: IMarker }> = ({ marker, ...others }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [prevMarker, setPrevMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    zoom: 15,
                    center: {lat: 40.74846023537938, lng: -73.9852390676},
                })
            );
        }
    }, [ref, map]);

    useEffect(() => {
        if(prevMarker) {
            prevMarker.setMap(null);
        }
        if (ref.current && map && marker) {
            const newMarker = new google.maps.Marker({
                map,
                ...marker,
            });
            map.setCenter(new google.maps.LatLng(marker.position))
            setPrevMarker(newMarker);
        }
    }, [ref, map, marker]);

    return <div ref={ref} className="w-full h-48 rounded-xl" />;
};
export default GoogleMap;
