import { NOTIFICATION_TYPES } from "../constants/notifications";

export const getLinkFromNotification = (notification: any) => {
    switch (notification.type) {
        case NOTIFICATION_TYPES.NEW_USER_REQUEST:
            return `/admin/complaints`;
        case NOTIFICATION_TYPES.ORGANIZATION_INVITATION:
        case NOTIFICATION_TYPES.ORGANIZATION_INVITATION_RESPONSE:
            return notification?.data?.organizationId
                ? `/organizations/${notification?.data?.organizationId}`
                : "";

        case NOTIFICATION_TYPES.TRIP_INVITATION:
            return notification?.data?.tripId
                ? `/trips/${notification?.data?.tripId}`
                : "";
        case NOTIFICATION_TYPES.TRIP_MESSAGES:
            return notification?.data?.tripId
                ? `/trips/${notification?.data?.tripId}/notifications`
                : "";

        case NOTIFICATION_TYPES.TRIP_INVITATION_RESPONSE:
        case NOTIFICATION_TYPES.TRIP_INVITATION_CHANGE_ROLE_RESPONSE:
            return notification?.data?.tripId
                ? `/trips/${notification?.data?.tripId}/users`
                : "";

        case NOTIFICATION_TYPES.TRIP_ACTIVITY_REVIEW_ADD:
            return notification?.data?.tripId
                ? `/trips/${notification?.data?.tripId}/users/${
                      notification?.data?.fromId || ""
                  }`
                : "";

        case NOTIFICATION_TYPES.TRIP_IMAGE_ADD:
            return notification?.data?.tripId
                ? `/trips/${notification?.data?.tripId}/images`
                : "";

        case NOTIFICATION_TYPES.TRIP_REQUIREMENT_ADD:
        case NOTIFICATION_TYPES.TRIP_REQUIREMENT_FILE_ADD:
        case NOTIFICATION_TYPES.TRIP_REQUIREMENT_FILE_RESPONSE:
        case NOTIFICATION_TYPES.TRIP_REQUIREMENT_FILE_REJECTED:
            return notification?.data?.tripId &&
                notification?.data?.requirementId
                ? `/trips/${notification?.data?.tripId}/requirements/${notification?.data?.requirementId}`
                : "";

        case NOTIFICATION_TYPES.REMINDER_REQUIREMENTS:
            return `/trips/${notification?.data?.tripId}/requirements`;

        case NOTIFICATION_TYPES.TRIP_ACTIVITY_ADD:
        case NOTIFICATION_TYPES.TRIP_ACTIVITY_UPDATE_DATE:
        case NOTIFICATION_TYPES.TRIP_ACTIVITY_UPDATE_PLACE:
        case NOTIFICATION_TYPES.TRIP_ACTIVITY_UPDATE_TIME:
        case NOTIFICATION_TYPES.REMINDER_PUBLIC_REVIEW:
        case NOTIFICATION_TYPES.REMINDER_INSIGHT:
            return notification?.data?.tripId && notification?.data?.activityId
                ? `/trips/${notification?.data?.tripId}/itinerary/${notification?.data?.activityId}`
                : "";
        case NOTIFICATION_TYPES.TRIP_ITINERARY_HIDDEN:
        case NOTIFICATION_TYPES.TRIP_ITINERARY_PUBLISHED:
        case NOTIFICATION_TYPES.TRIP_ACTIVITY_REMOVED:
            return notification?.data?.tripId
                ? `/trips/${notification?.data?.tripId}/itinerary/`
                : "";
        case NOTIFICATION_TYPES.TRIP_FILE_ADD:
            return `/trips/${notification?.data?.tripId}/files`;
        case NOTIFICATION_TYPES.NEW_CHAT_NOTIFICATION:
            return `/chat`;
        default:
            return notification?.data?.tripId
                ? `/trips/${notification?.data?.tripId}`
                : "";
    }
};
