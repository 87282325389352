import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import InputPlaceAutocomplete from "../../components/InputPlaceAutocomplete";
import Button from "../../components/ButtonSquare";
import GoogleMap from "../../components/Map";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createOther, selectedOther, updateOther } from "./othersSlice";
import { selectProfile } from "../authentication/userSlice";
import PhoneInput from "../../components/PhoneInput";
import InputWithValid from "../../components/Input";
import ButtonSquared from "../../components/ButtonSquare";
import { ButtonSize } from "../../constants";

const schema = yup
    .object({
        name: yup.string().required("This field is required").min(2),
        phone: yup.string().min(2),
        website: yup.string().url("Enter a valid url").nullable(),
        addressAuto: yup
            .object({
                street_number: yup.string().nullable(),
                route: yup.string(),
                postal_code: yup.string().nullable(),
                postal_code_suffix: yup.string().nullable(),
                locality: yup.string().nullable(),
                administrative_area_level_1: yup.string().nullable(),
                country: yup.string().nullable(),
                fullAddress: yup.string().nullable(),
                lat: yup.number().nullable(),
                lng: yup.number().nullable(),
            })
            .nullable(),
    })
    .required("This field is required");

interface OtherFormProps {
    onChange?: any;
    onCancel?: any;
    name?: string;
    addNew?: boolean;
    currentOther?: any;
}

export default function OtherForm(props: OtherFormProps) {
    const { onChange, onCancel } = props;
    const [mapMarker, setmapMarker] = useState<any>(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [placeName, setPlaceName] = useState<any>(null);

    const otherSelected = useAppSelector(selectedOther);
    const dispatch = useAppDispatch();
    const profile = useAppSelector(selectProfile);
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        control,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const values = getValues();

    const other: any = useMemo(() => {
        if (props?.currentOther) {
            return props?.currentOther;
        }
        return !props?.addNew && typeof selectedOther !== "function"
            ? selectedOther
            : undefined;
    }, [props?.addNew, otherSelected, props?.currentOther]);

    useEffect(() => {
        if (other) {
            setIsUpdate(true);
            reset({
                name: other?.name,
                website: other?.website,
                addressAuto: {
                    street_number: other?.streetNumber,
                    route: other?.streetAddress,
                    postal_code: other?.zipCode,
                    locality: other?.city,
                    administrative_area_level_1: other?.state,
                    country: other?.country,
                    lat: other?.lat,
                    lng: other?.lng,
                    fullAddress: other?.fullAddress,
                },
                phone: other?.phone,
            });
            const marker = {
                position: {
                    lat: other?.lat,
                    lng: other?.lng,
                },
                title: other?.name,
            };
            setmapMarker(marker);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [other, other?.lat, other?.lng, other?.name]);

    const changeAddress = (e: any) => {
        setPlaceName(e.name);
        if (e.lat && e.lng) {
            const marker = {
                position: {
                    lat: e?.lat,
                    lng: e?.lng,
                },
                title: values?.name,
            };
            setmapMarker(marker);
        }
    };

    const clearPlace = () => {
        reset({ addressAuto: {} });
        setPlaceName(null);
        setmapMarker(null);
    };

    const handleSubmitForm = async (data: any) => {
        const payload = {
            createdBy: profile.id,
            name: data.name,
            website: data.website,
            description: "",
            phone: data.phone,
            fullAddress: data.addressAuto.fullAddress,
            streetAddress: data.addressAuto.route,
            streetNumber: data.addressAuto.street_number,
            city: data.addressAuto.locality,
            state: data.addressAuto.administrative_area_level_1,
            country: data.addressAuto.country,
            zipCode: data.addressAuto.postal_code,
            lat: data.addressAuto.lat,
            lng: data.addressAuto.lng,
        };

        const formData = new FormData();

        Object.keys(payload).forEach((pKey: any) => {
            const value = payload[pKey as keyof typeof payload];
            formData.append(pKey, value);
        });
        let updatedOther;
        if (isUpdate && other?.id) {
            updatedOther = await dispatch(updateOther(other.id, payload));
        } else {
            updatedOther = await dispatch(createOther(formData));
        }
        onChange && onChange(updatedOther);
    };

    return (
        <div className="mx-6">
            <div className="flex flex-wrap">
                <div className="text-left font-semibold text-lg flex-grow">
                    {isUpdate ? "Edit other" : "Add other"}
                </div>
            </div>
            <form
                action="#"
                method="POST"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <div className="flex flex-col">
                    <div className="my-5">
                        <div className="mt-3 lg:w-[400px]">
                            <InputWithValid
                                register={register}
                                label="Name"
                                type="text"
                                id="name"
                                name="name"
                                error={errors["name"]?.message}
                                defaultValue={other?.name || props.name}
                            />
                        </div>
                        <div className="mt-6 w-full flex flex-row">
                            <InputPlaceAutocomplete
                                className="flex-grow"
                                control={control}
                                onChange={changeAddress}
                                label="Address"
                                id="address-autocomplete"
                                name="addressAuto"
                                placeholder=""
                                error={errors["address"]?.message}
                                defaultValue={other?.fullAddress}
                            />
                            {placeName && (
                                <div className="m-auto">
                                    <ButtonSquared
                                        onClick={clearPlace}
                                        className="p-4 w-fit"
                                        type="button"
                                        label="Clear"
                                        size={ButtonSize.SMALL}
                                    />
                                </div>
                            )}
                        </div>
                        {!!placeName && (
                            <div className="mt-8 flex flex-col">
                                <span>{placeName}</span>

                                {<GoogleMap marker={mapMarker} />}
                            </div>
                        )}
                        <div className="flex flex-wrap w-full">
                            <div className="mt-6 w-full lg:w-[400px]">
                                <span className="text-left block font-medium text-neutral-600 text-sm">
                                    Phone number
                                </span>
                                <PhoneInput
                                    control={control}
                                    id="phone"
                                    name="phone"
                                    error={errors["phone"]?.message}
                                />
                            </div>
                            <div className="lg:flex-grow"> </div>
                            <div className="mt-6 w-full lg:w-[400px] self-end">
                                <InputWithValid
                                    register={register}
                                    label="Website"
                                    type="text"
                                    id="website"
                                    name="website"
                                    error={errors["website"]?.message}
                                    defaultValue={other?.website}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 text-right sm:px-6">
                        <span className="mr-4">
                            <Button
                                type="button"
                                outlined
                                label="Cancel"
                                onClick={onCancel}
                                size={ButtonSize.SMALL}
                            />
                        </span>
                        <Button
                            type="submit"
                            label="Save"
                            size={ButtonSize.SMALL}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}
