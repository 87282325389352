import { Route } from "react-router-dom";
import HomePage from "../features/home";
import RequireAuth from "../hooks/RequiresAuth";
import { SSO } from "../features/authentication/SSO/SSO";

export const DefaultRoute = (
    <Route path="/">
        <Route
            path=""
            element={
                <RequireAuth protectedRoute={true}>
                    <HomePage />
                </RequireAuth>
            }
        />
    </Route>
);

export const SSORoute = <Route path="/otp" element={<SSO />} />;
export const HomeRoute = (
    <Route
        path="/home"
        element={
            <RequireAuth protectedRoute={true}>
                <HomePage />
            </RequireAuth>
        }
    />
);
