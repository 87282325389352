import { PAGE_SECTIONS, goodForWhichClientsOptions } from "../../../../../../../../constants";
import { addTripActivityHotelInsight } from "../../../../../../../hotelInsight/hotelInsightSlice";
import InsightNotesView from "../../components/InsightNotesView/InsightNotesView";

export default function NotesInsightReview() {
    const currentSection = PAGE_SECTIONS.TRIP_ITINERARY_HOTEL_PRIVATE_REVIEW_DETAILS;

    return (
        <InsightNotesView
            insightKey="hotelInsight"
            currentSection={currentSection}
            getDefaultValues={(insight) => ({
                stars: insight?.stars,
                roomsCount: insight?.roomsCount,
                connectingRooms: insight?.connectingRooms,
                generalComments: insight?.generalComments,
                privateComments: insight?.privateComments,
                roomComments: insight?.roomComments,
                dinningComments: insight?.dinningComments,
                wellnessComments: insight?.wellnessComments,
                commonComments: insight?.commonComments,
                goodForWhichClients: insight?.goodForWhichClients,
                specificClients: insight?.specificClients,
            })}
            addInsightNotes={addTripActivityHotelInsight}
        />
    );
}
