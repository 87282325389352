import Icon from "../assets/svg/Icon";

const AdminToast = () => {
    return (
        <div className="absolute bottom-20 mb-4 p-1 bg-white shadow-lg border border-primary_magenta-500 rounded-md max-w-xs md:max-w-none w-full md:w-auto">
            <div className="flex items-center">
                <div className="flex-grow flex flex-col items-center">
                    <Icon icon="CrownIcon" className="fill-gray-900 text-neutral-900 w-7" />
                </div>
            </div>
            <div className="flex items-start">
                <div className="w-6/6">
                    <h5 className="text-primary_magenta-500 font-semibold">Admin mode</h5>
                </div>
            </div>
        </div>
    );
};

export default AdminToast;
