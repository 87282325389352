import moment from "moment";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import { useAppSelector } from "../../../app/hooks";
import DatePickerMaterial from "../../../components/DatePickerMaterial/DatePickerMaterial";
import InputSearch from "../../../components/InputSearch";
import SelectInput from "../../../components/InputSelect/SelectInput";
import { ActivityTypeFilterOptions, PAGE_ROWS } from "../../../constants";
import { ReviewsDotMenuKeys } from "../../../constants/admin";
import { normalizeReviews, parseMomentUtc } from "../../../helpers";
import { selectCurrentBreakpoint } from "../../layout/layoutSlice";
import { selectAdminReviews } from "../adminSlice";
import AllReviewsList from "./AllReviewsList";
import AllReviewsTable from "./AllReviewsTable";

const AllReviews = forwardRef((props: any, ref) => {
    const { onSelectReview, onDelete, fetchData } = props;
    const [newPage, setNewPage] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    const [type, setType] = useState<string>("");
    const reviews = useAppSelector(selectAdminReviews);
    const [startDate, setStartDate] = useState<any>(
        moment().subtract(6, "months").startOf("day").toDate()
    );
    const [endDate, setEndDate] = useState<any>(moment().endOf("day").toDate());
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);

    useImperativeHandle(ref, () => ({
        reFetch() {
            getData();
        },
    }));

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPage.skip, search, type, startDate, endDate]);

    const handleClickDotsMenu = (key: string, review: any) => {
        switch (key) {
            case ReviewsDotMenuKeys.edit:
                onSelectReview(review);
                break;
            case ReviewsDotMenuKeys.delete:
                onDelete(review);
                break;
        }
    };

    const getData = () => {
        fetchData({
            take: PAGE_ROWS,
            skip: newPage.skip,
            type,
            search,
            startDate: parseMomentUtc(startDate).startOf("day").toDate(),
            endDate: parseMomentUtc(endDate).endOf("day").toDate(),
        });
    };

    const resetPage = () => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleSearchChange = (query: string) => {
        resetPage();
        setSearch(query);
    };
    const handleElementFilterChange = (value: string) => {
        const formattedValue = value === "ALL" ? "" : value;
        resetPage();
        setType(formattedValue);
    };
    const handleStartDateChange = (value: moment.Moment) => {
        resetPage();
        setStartDate(value.startOf("day").toDate());
    };
    const handleEndDateChange = (value: moment.Moment) => {
        resetPage();
        setEndDate(value.startOf("day").toDate());
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const reviewsNormalized = useMemo(() => {
        return (reviews?.data || [])?.map((review: any) => {
            return { ...review, ...normalizeReviews(review) };
        });
    }, [reviews?.data]);

    return (
        <>
            <div className="text-neutral-900 text-left font-semibold mb-6">
                Reviews List
            </div>
            <div className="grid grid-cols-12 items-end gap-5 md:gap-8">
                <div className="col-span-12 lg:col-span-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        placeholder="Search"
                    />
                </div>

                <div className="col-span-6 lg:col-span-3">
                    <DatePickerMaterial
                        name="startDate"
                        value={startDate}
                        id="startDate"
                        label="From date"
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className="col-span-6 lg:col-span-3">
                    <DatePickerMaterial
                        name="endDate"
                        value={endDate}
                        id="startDate"
                        label="To date"
                        onChange={handleEndDateChange}
                    />
                </div>
                <div className="col-span-6 lg:col-span-2">
                    <SelectInput
                        onChange={handleElementFilterChange}
                        label="Element"
                        options={ActivityTypeFilterOptions}
                        defaultValue={ActivityTypeFilterOptions[0].value}
                    />
                </div>
            </div>
            {isDesktop ? (
                <AllReviewsTable
                    reviews={reviews}
                    handlePaginatorChange={handlePaginatorChange}
                    handleClickDotsMenu={handleClickDotsMenu}
                />
            ) : (
                <AllReviewsList
                    reviews={reviews}
                    handlePaginatorChange={handlePaginatorChange}
                    handleClickDotsMenu={handleClickDotsMenu}
                />
            )}

            {!reviews?.isLoading && !reviewsNormalized.length && (
                <div className="text-neutral-400 text-sm py-6">
                    There are no reviews to show.
                </div>
            )}
        </>
    );
});

export default AllReviews;
