import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import {
    deleteComplaint,
    getAdminComplaints,
    updateComplaint,
} from "../adminSlice";
import { selectAdminComplaints } from "../adminSlice";
import { ComplaintsDotMenuKeys } from "../../../constants/admin";
import AllComplaints from "./AllComplaints";
import ComplaintReplyForm from "../../../components/Complaints/ComplaintReplyForm";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";

export default function ComplaintsAdminHome(props: any) {
    const dispatch = useAppDispatch();
    const [selectedComplaint, setSelectedComplaint] = useState<any>(null);
    const [complaintToDelete, setComplaintToDelete] = useState<any>(null);
    const complaints = useAppSelector(selectAdminComplaints);

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.ADMIN_COMPLAINTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickDotsMenu = (key: string, item: any) => {
        switch (key) {
            case ComplaintsDotMenuKeys.view:
                setSelectedComplaint(item);
                break;
            case ComplaintsDotMenuKeys.delete:
                setComplaintToDelete(item);
                break;
        }
    };

    const fetchComplaints = (filters: any) => {
        if (filters) {
            dispatch(
                getAdminComplaints(
                    filters.take,
                    filters.skip,
                    filters.status,
                    filters.search,
                    filters.startDate,
                    filters.endDate,
                    filters.itemType
                )
            );
        }
    };
    const handleDeleteComplaint = async () => {
        await dispatch(deleteComplaint(complaintToDelete?.id));
        setComplaintToDelete(null);
    };

    const onUpdateComplaint = async (data: any) => {
        try {
            dispatch(updateComplaint(data));
            setSelectedComplaint(null);
        } catch (error) {}
    };

    return (
        <>
            <ModalConfirmation
                open={!!complaintToDelete}
                title="Are you sure?"
                description={`You are about to remove a acomplaint on ${complaintToDelete?.title}`}
                handleCancel={() => {
                    setComplaintToDelete(null);
                }}
                handleSubmit={handleDeleteComplaint}
            />
            {!selectedComplaint ? (
                <AllComplaints
                    complaints={complaints}
                    fetchData={fetchComplaints}
                    handleClickDotsMenu={handleClickDotsMenu}
                />
            ) : (
                <ComplaintReplyForm
                    complaint={selectedComplaint}
                    onCancel={() => {
                        setSelectedComplaint(null);
                    }}
                    onSubmit={onUpdateComplaint}
                />
            )}
        </>
    );
}
