import { mdiLock, mdiLockOutline } from "@mdi/js";
import Icon from "../../assets/svg/Icon";
import CheckIcon from "../../assets/svg/CheckIcon";
import { ListOption } from "../ListOption/ListOption";

interface PlanItemProps {
    offerData: any;
    myPlanData: any;
    checked?: boolean;
    handleClick: () => void;
    lockedItem?: boolean;
}

export const PlanItem = ({
    offerData,
    myPlanData,
    checked = false,
    handleClick,
    lockedItem = false,
}: PlanItemProps) => {
    return (
        <div
            className={`flex flex-col flex-grow min-h-[300px] ${
                myPlanData ? "" : "lg:w-[50%]"
            } align-baseline border rounded-lg p-4  ${
                !lockedItem
                    ? checked
                        ? "border-orange-500"
                        : "hover:border-neutral-300"
                    : ""
            }
            ${checked ? "bg-[#FDF2EE]" : "bg-neutral-50"}
            ${
                lockedItem
                    ? "!bg-neutral-500 text-neutral-900 relative"
                    : "cursor-pointer text-neutral-900"
            }`}
            onClick={() => {
                !lockedItem && handleClick();
            }}
        >
            <div className="flex-grow flex-col">
                {lockedItem && (
                    <div className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <div className="flex flex-col">
                            <Icon
                                materialIcon={mdiLockOutline}
                                className={`w-15`}
                            />
                            <div className="font-semibold text-2xl">
                                Expired
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex flex-row">
                    <div className=" flex flex-col font-semibold text-xl  flex-grow">
                        {!!offerData?.recommended && (
                            <span
                                className={`border rounded-full w-fit px-2 font-medium text-xs border-orange-500 text-orange-500 mb-2`}
                            >
                                Recommended
                            </span>
                        )}
                        <span>{offerData?.title1}</span>
                        <span>{offerData?.title2}</span>
                    </div>
                    {!lockedItem && (
                        <div className="ml-4">
                            {checked ? (
                                <Icon
                                    className="w-[32px] mr-1 text-orange-600"
                                    icon={"CheckCircleIcon"}
                                />
                            ) : (
                                <Icon
                                    className="w-[32px] mr-1 text-neutral-300"
                                    icon={"BlankCircleIcon"}
                                />
                            )}
                        </div>
                    )}
                </div>
                {!!offerData?.description && (
                    <div
                        className={`${
                            lockedItem ? "text-neutral-900" : "text-neutral-400"
                        } font-medium text-base mt-2`}
                    >
                        {offerData?.description}
                    </div>
                )}
                {!!offerData?.offerText && (
                    <span className={`font-semibold`}>
                        {offerData?.offerText}
                    </span>
                )}
            </div>
            <div className="flex flex-row align-center mt-2">
                <span
                    className={`text-3xl font-bold mr-2 ${
                        checked ? "text-orange-600" : ""
                    }`}
                >
                    ${offerData?.price}
                </span>
                <span className={`font-base pt-1 text-neutral-900`}>
                    {!!offerData?.priceDescription?.length && "/"}
                    {offerData?.priceDescription}
                </span>
            </div>
            <div
                className={`text-sm  whitespace-pre-line font-normal mt-2 ${
                    lockedItem ? "text-neutral-900" : "text-neutral-400"
                }`}
            >
                {offerData?.planInfo}
            </div>

            {myPlanData?.planBenefits?.length && (
                <div className="pt-4 border-t border-t-neutral-300 mt-4">
                    <ListOption
                        options={myPlanData?.planBenefits}
                        itemStyle={"!mb-1 items-center text-neutral-600"}
                        icon={
                            <CheckIcon
                                width={12}
                                height={12}
                                className="text-orange-500 w-5"
                            />
                        }
                    />
                </div>
            )}
        </div>
    );
};
