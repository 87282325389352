import { useNavigate, useParams } from "react-router-dom";
import { ActivityType, PAGE_SECTIONS } from "../../../constants";
import HotelActivityForm from "../../trips/Trip/TripItinerary/Activity/EditHotelActivityForm";
import OtherActivityForm from "../../trips/Trip/TripItinerary/Activity/EditOtherActivityForm";
import EditRestaurantActivityForm from "../../trips/Trip/TripItinerary/Activity/EditRestaurantActivityForm";
import { useAppSelector } from "../../../app/hooks";
import { selectedLibraryElement, selectedOrganization, updateLibraryElement } from "../organizationSlice";
import ActivityFormProvider from "../../trips/Trip/TripItinerary/Activity/context/ActivityForm.context";
import { useDispatch } from "react-redux";

export default function EditLibraryElement() {
    const { activityType } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const organization = useAppSelector(selectedOrganization);
    const libraryElementSelected = useAppSelector(selectedLibraryElement);
    const { type } = libraryElementSelected || {};
    const typeN = activityType || type;
    const libraryElementId = libraryElementSelected?.id;

    const navigateBack = () => {
        navigate(-1);
    };

    const onSubmit = async (activity: any) => {
        await dispatch(
            updateLibraryElement(organization?.id, libraryElementId, activity)
        );
        navigateBack();
    };

    if (!libraryElementSelected) return null;

    const { FormComponent, currentSection, libraryElement } = (() => {
        switch (type) {
            case ActivityType.HOTEL:
                return {
                    FormComponent: HotelActivityForm,
                    currentSection: libraryElementSelected.isVisit ? 
                        PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_VISIT :
                        PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_HOTEL,
                    libraryElement: {
                        ...libraryElementSelected,
                        hotelActivity: {
                            hotel: {
                                ...libraryElementSelected.hotel,
                            },
                        },
                    },
                };
            case ActivityType.RESTAURANT:
                return {
                    FormComponent: EditRestaurantActivityForm,
                    currentSection:
                        PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_RESTAURANT,
                    libraryElement: {
                        ...libraryElementSelected,
                        restaurantActivity: {
                            restaurant: {
                                ...libraryElementSelected.restaurant,
                            },
                        },
                    },
                };
            default:
                return {
                    FormComponent: OtherActivityForm,
                    currentSection:
                        PAGE_SECTIONS.ORGANIZATION_NEW_LIBRARY_ELEMENT_OTHER,
                    libraryElement: {
                        ...libraryElementSelected,
                        otherActivity: {
                            other: {
                                ...libraryElementSelected.other,
                            },
                        },
                    },
                };
        }
    })();

    return (
        <>
            <ActivityFormProvider>
                <FormComponent
                    isLibraryElement
                    activity={libraryElement}
                    type={typeN}
                    currentSection={currentSection}
                    navigateBack={navigateBack}
                    onSubmit={onSubmit}
                />
            </ActivityFormProvider>
        </>
    );
}
