import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import Button from "../../components/ButtonSquare";
import Modal, { Modal_Size } from "../../components/Modal";
import { useAuthStateContext } from "../../context/Auth";
import HotelForm from "./HotelForm";
import { getHotels, selectHotels, setSelectedHotel } from "./hotelSlice";
import { Paragraph } from "../../components/Paragraph/Paragraph";

export default function HotelsList(props: any) {
    const [addHotelModalOpen, setaddHotelModalOpen] = useState(false);
    const hotels = useSelector(selectHotels);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchHotels = async () => {
            return await dispatch(getHotels(""));
        };
        fetchHotels();

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNewHotelClick = async (e: any) => {
        await dispatch(setSelectedHotel({ selectedHotel: null }));
        setaddHotelModalOpen(true);
    };

    const handleHotelClick = (hotelId: number) => {
        navigate(`/hotels/${hotelId}`);
    };

    const handleModalClose = () => {
        setaddHotelModalOpen(false);
    };

    const onNewHotel = async () => {
        handleModalClose();
        return await dispatch(getHotels(""));
    };

    return (
        <>
            <Modal
                size={Modal_Size.lg}
                open={addHotelModalOpen}
                onClose={handleModalClose}
            >
                <HotelForm onCancel={handleModalClose} onChange={onNewHotel} />
            </Modal>
            <div className="min-w-full flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="min-w-full flex justify-end py-2 px-2">
                            <Button
                                label="New Hotel"
                                onClick={handleNewHotelClick}
                            />
                        </div>

                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Contact
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Status
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Address
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative px-6 py-3"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {hotels.map((hotel) => (
                                        <tr
                                            key={hotel.id}
                                            className="cursor-pointer"
                                            onClick={(e) => {
                                                handleHotelClick(hotel?.id);
                                            }}
                                        >
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0 h-10 w-10">
                                                        <img
                                                            className="h-10 w-10 rounded-full"
                                                            src={hotel.image}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="text-sm font-medium text-gray-900">
                                                            {hotel?.name}
                                                        </div>
                                                        <Paragraph className="text-sm text-gray-500">
                                                            {hotel?.description}
                                                        </Paragraph>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">
                                                    {hotel?.contact}
                                                </div>
                                                <div className="text-sm text-gray-500">
                                                    {hotel.phone}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {hotel?.status}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {hotel?.fullAddress}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
