import React, { useState } from "react";
import {
    IAttachment,
    IChatMessage,
    IContact,
} from "../../../../types/chat.type";
import { ButtonSize } from "../../../../constants";
import ButtonSquared from "../../../../components/ButtonSquare";
import ImageModal from "../modals/ImageModal";
import Avatar from "../../../../components/Avatar";
import { useChatForm } from "../../hooks/useChat.hook";

interface ImageMessageBubbleProps {
    message: IChatMessage;
    currentUser: boolean;
    onSave: () => void;
    onDontSave: () => void;
    onMemberClick?: (user: IContact) => void;
    isGroup?: boolean;
}

const ImageMessageBubble: React.FC<ImageMessageBubbleProps> = ({
    message: { content, attachments, sender, timestamp, attachOnTrip },
    currentUser,
    onSave,
    onDontSave,
    isGroup,
    onMemberClick,
}) => {
    const images: IAttachment[] = attachments || [];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showOptions, setShowOptions] = useState<boolean>( !attachOnTrip );
    const { getFormattedDate } = useChatForm();
    const formattedDate = getFormattedDate(timestamp);

    const openModal = (index: number) => {
        setCurrentImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const showNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const showPrevImage = () => {
        setCurrentImageIndex(
            (prevIndex) => (prevIndex - 1 + images.length) % images.length
        );
    };

    const onSaveImage = () => {
        onSave();
        setShowOptions(false);
    };

    const onDontSaveImage = () => {
        onDontSave();
        setShowOptions(false);
    };

    const handleUserClick = () => {
        onMemberClick && onMemberClick(sender);
    }

    return (
        <div
            className={`flex mb-4  ${
                currentUser ? "justify-end" : "items-start"
            }`}
        >
            {isGroup && !currentUser && (
                <span onClick={handleUserClick}><Avatar src={sender?.avatar} className="mr-2 w-7 h-7 cursor-pointer" /></span>
            )}
            <div className="flex flex-col">
                {isGroup && !currentUser && (
                    <div className="mb-0 text-start">
                        <span className="text-xs text-gray-400 cursor-pointer" onClick={handleUserClick}>
                            {sender?.firstName} {sender?.lastName}
                        </span>
                    </div>
                )}
                <div
                    className={`p-2 mb-4 rounded-lg w-60 shadow-md ${
                        currentUser ? "bg-[#ffeae1]" : "bg-[#f4f3f3]"
                    }`}
                >
                    <p className="mb-1 font-medium text-start">{content}</p>
                    <div
                        className="grid grid-cols-2 gap-3 cursor-pointer"
                        onClick={() => openModal(0)}
                    >
                        {images.slice(0, 3).map((img, index) => (
                            <img
                                key={index}
                                src={img.thumbnail}
                                alt=""
                                className="w-full h-auto object-cover rounded-md"
                            />
                        ))}
                        {images.length > 3 && (
                            <div className="relative w-25 h-25">
                                <img
                                    src={images[3].thumbnail}
                                    alt="More images"
                                    className="w-full h-full object-cover rounded-md"
                                />
                                <div className="absolute inset-0 bg-black opacity-40 rounded-md"></div>
                                <div className="absolute inset-0 flex items-center justify-center text-white text-lg font-bold">
                                    +{images.length - 3}
                                </div>
                            </div>
                        )}
                    </div>
                    { showOptions && isGroup && currentUser && (
                        <div>
                            <p className="mt-2 text-justify font-semibold">
                                Do you also want to save it to your trip
                                gallery?
                            </p>
                            <div className="mt-2 flex justify-between w-full gap-3">
                                <ButtonSquared
                                    outlined={true}
                                    label={"No"}
                                    onClick={onDontSaveImage}
                                    size={ButtonSize.SMALL}
                                    className="text-xs"
                                />
                                <ButtonSquared
                                    outlined={false}
                                    label={"Yes"}
                                    onClick={onSaveImage}
                                    size={ButtonSize.SMALL}
                                    className="text-xs"
                                />
                            </div>
                        </div>
                    )}
                    <div className="flex justify-end pt-1.5">
                        <span className="text-xs text-gray-400 min-w-max">
                            {formattedDate}
                        </span>
                    </div>
                </div>
                <ImageModal
                    isOpen={isModalOpen}
                    images={images}
                    currentImageIndex={currentImageIndex}
                    onClose={closeModal}
                    onNext={showNextImage}
                    onPrev={showPrevImage}
                    sender={sender}
                    timestamp={timestamp}
                />
            </div>
        </div>
    );
};

export default ImageMessageBubble;
