export const COMMON_TYPE = {
    LOBBY: 'LOBBY',
    BAR: 'BAR',
    POOL: 'POOL',
    TERRANCE: 'TERRANCE',
    BEACH: 'BEACH',
    GROUNDS: 'GROUNDS',
    RECEPTION: 'RECEPTION',
    CONCIERGE: 'CONCIERGE',
    KIDS_CLUB: 'KIDS_CLUB',
    OTHER: 'OTHER',
};

export const CommonTypeOptions = [
    {label: 'Bar', value: COMMON_TYPE.BAR},
    {label: 'Beach', value: COMMON_TYPE.BEACH},
    {label: 'Concierge', value: COMMON_TYPE.CONCIERGE},
    {label: 'Grounds', value: COMMON_TYPE.GROUNDS},
    {label: 'Kids Club', value: COMMON_TYPE.KIDS_CLUB},
    {label: 'Lobby', value: COMMON_TYPE.LOBBY},
    {label: 'Pool', value: COMMON_TYPE.POOL},
    {label: 'Reception', value: COMMON_TYPE.RECEPTION},
    {label: 'Terrance', value: COMMON_TYPE.TERRANCE},
    {label: 'Other', value: COMMON_TYPE.OTHER},
  ];

  export const WELLNESS_TYPE = {
    SPA: 'SPA',
    GYM: 'GYM',
    OTHER: 'OTHER',
  };

  export const WellnessTypeOptions = [
    {label: 'Gym', value: WELLNESS_TYPE.GYM},
    {label: 'Spa', value: WELLNESS_TYPE.SPA},
    {label: 'Other', value: WELLNESS_TYPE.OTHER},
  ];

  export const CONTACT_TYPE = {
    PRIMARY: 'PRIMARY',
    OTHER: 'OTHER',
  };

  export const ContactTypeOptions = [
    {label: 'Primary', value: CONTACT_TYPE.PRIMARY},
    {label: 'Other', value: CONTACT_TYPE.OTHER},
  ];

  export const ROOM_VIEW_TYPE = {
    GARDEN: 'GARDEN',
    MOUNTAIN: 'MOUNTAIN',
    GOLF: 'GOLF',
    WATER: 'WATER',
    OCEAN: 'OCEAN',
    CITY: 'CITY',
    OTHER: 'OTHER',
  };

  export const RoomViewTypeOptions = [
    {label: 'Garden', value: ROOM_VIEW_TYPE.GARDEN},
    {label: 'Golf', value: ROOM_VIEW_TYPE.GOLF},
    {label: 'Mountain', value: ROOM_VIEW_TYPE.MOUNTAIN},
    {label: 'City', value: ROOM_VIEW_TYPE.CITY},
    {label: 'Ocean', value: ROOM_VIEW_TYPE.OCEAN},
    {label: 'Water', value: ROOM_VIEW_TYPE.WATER},
    {label: 'Other', value: ROOM_VIEW_TYPE.OTHER},
  ];