import {
    ORG_USERS_LIST_OPTIONS,
    ORG_USERS_LIST_OPTIONS_KEYS,
    UserRoleOnOrganization,
    userRoleOrganizationFilterOptions,
    UserStatusOnOrganization,
} from "../../../constants";

import ParticipantsListCard from "../../../components/ParticipantListCard/ParticipantListCard";
import ListWithPagination from "../../../components/ListWithPagination";

interface IOrgUsersList {
    users: any;
    handleClickUserDotsMenu?: Function;
    handleUserClick?: Function;
    handlePaginatorChange: any;
    canEdit: boolean;
}
export default function OrganizationUsersList({
    users,
    handleClickUserDotsMenu,
    handlePaginatorChange,
    canEdit,
    handleUserClick,
}: IOrgUsersList) {
    const { data } = users || {};

    const userItemRender = ({ item }: any) => {
        const isOwner = item.role === UserRoleOnOrganization.OWNER;
        const confirmedUser =
            item.status === UserStatusOnOrganization.CONFIRMED;
        const roleLabel = userRoleOrganizationFilterOptions.find(
            (s) => s.value === item?.role
        )?.label;

        let dotMenuOptions = ORG_USERS_LIST_OPTIONS[item.status]?.filter((option: { key: string; }) => {
            if (isOwner && option.key === ORG_USERS_LIST_OPTIONS_KEYS.MAKE_OWNER) {
                return false;
            }
            return true;
        });

        return (
            <ParticipantsListCard
                user={{ ...item }}
                handleClickUserDotsMenu={
                    (!isOwner && canEdit) ? handleClickUserDotsMenu : undefined
                }
                dotMenuOptions={dotMenuOptions}
                showRole={confirmedUser}
                showStatus={!confirmedUser}
                roleLabel={roleLabel}
                handleUserClick={handleUserClick}
            />
        );
    };
    return (
        data && (
            <ListWithPagination
                items={users}
                handlePaginatorChange={handlePaginatorChange}
                renderItem={userItemRender}
                itemKey={(user: any) => `user_${user.userEmail}`}
            />
        )
    );
}
