import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Avatar from "../../components/Avatar";
import EmptyScreen from "../../components/EmptyScreen/EmptyScreen";
import ReviewDetails from "../../components/Reviews/ReviewDetails";
import TabComponent from "../../components/TabComponent";
import { PAGE_SECTIONS, PAGE_ROWS } from "../../constants";
import { setCurrentSection } from "../layout/layoutSlice";
import {
    clearUserProfile,
    getUser,
    getUserReviews,
    IPaginatedDataInitialState,
    selectuser,
    selectUserReviews,
    setUserReviews,
} from "./UserProfileSlice";

export default function UserProfile() {
    let { userId } = useParams();
    const user = useAppSelector(selectuser);
    const reviews = useAppSelector(selectUserReviews);
    const dispatch = useAppDispatch();
    const tabItemsIndex = {
        info: "info",
        reviews: "reviews",
    };
    const tabItems = [
        {
            id: tabItemsIndex.info,
            text: "Info",
        },
        {
            id: tabItemsIndex.reviews,
            text: "Reviews",
        },
    ];
    const [selectedTab, setSelectedTab] = useState<string | number>(
        tabItemsIndex.info
    );
    const changeTab = (tabIndex: string | number) => {
        setSelectedTab(tabIndex);
    };

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.PROFILE,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userId) {
            dispatch(getUser(Number(userId)));
            fetchReviews(true);
        }

        return () => {
            dispatch(clearUserProfile());
            dispatch(
                setUserReviews({
                    reviews: IPaginatedDataInitialState,
                })
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const fetchReviews = async (first?: boolean) => {
        await dispatch(
            getUserReviews(
                Number(userId),
                PAGE_ROWS,
                first ? 0 : reviews.data.length
            )
        );
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-wrap mb-6">
                <TabComponent
                    className="my-4 flex-grow text-left"
                    onChange={changeTab}
                    items={tabItems}
                    defaultSelected={selectedTab}
                />
            </div>
            {selectedTab === tabItemsIndex.info && (
                <div>
                    <div className="flex items-center mb-6">
                        <div className="mr-6">
                            <Avatar src={user?.avatar} className="w-24 h-24" />
                        </div>
                        <div className="flex flex-col text-left text-neutral-400">
                            <span className="text-lg font-semibold">
                                {user?.firstName} {user?.lastName}
                            </span>
                            <span className="text-base">{user?.email}</span>
                        </div>
                    </div>
                </div>
            )}
            {selectedTab === tabItemsIndex.reviews && (
                <>
                    <InfiniteScroll
                        dataLength={reviews?.data?.length} //This is important field to render the next data
                        next={fetchReviews}
                        hasMore={reviews.currentPage < reviews.totalPages}
                        loader={""}
                    >
                        <div className="grid grid-cols-6 gap-y-6 gap-x-24">
                            {reviews?.data.map((rev) => {
                                return (
                                    <div key={rev?.id} className="col-span-3">
                                        <ReviewDetails
                                            review={rev}
                                        ></ReviewDetails>
                                    </div>
                                );
                            })}
                        </div>
                    </InfiniteScroll>
                    {!!reviews?.data?.length &&
                        !reviews.isLoading &&
                        reviews.currentPage < reviews.totalPages && (
                            <div
                                onClick={() => {
                                    fetchReviews();
                                }}
                                className="text-orange-600 hover:text-orange-500 cursor-pointer mt-12 font-semibold"
                            >
                                Load more
                            </div>
                        )}
                    {reviews.isLoading && (
                        <div className="grid grid-cols-6 gap-y-6 gap-x-24">
                            <div className="col-span-3 ">
                                <Skeleton
                                    className={`rounded-xl h-[50px] w-full`}
                                />
                            </div>
                            <div className="col-span-3 ">
                                <Skeleton
                                    className={`rounded-xl h-[50px] w-full`}
                                />
                            </div>
                        </div>
                    )}
                    {!reviews.isLoading && !reviews?.data?.length && (
                        <EmptyScreen text="There are no reviews to show." />
                    )}
                </>
            )}
        </div>
    );
}
