import EmptyIcon from "../../assets/images/EmptyScreenIcon.png";
import EmptyBox from "../../assets/images/EmptyBox.png";
import Icon from "../../assets/svg/Icon";
import ButtonSquared from "../ButtonSquare";

interface EmptyScreenProps {
    text: string;
    title?: string;
    buttonText?: string;
    action?: any;
    icon?: any;
    box?: boolean;
}

export default function EmptyScreen(props: EmptyScreenProps) {
    const { text, buttonText, action } = props;
    return (
        <div className="my-24 flex flex-col text-neutral-400 items-center justify-center text-center">
            {props?.icon ? (
                <Icon
                    icon={props.icon}
                    className="w-28 h-28 text-gray-400 mx-auto"
                />
            ) : (
                <img
                    alt="empty"
                    src={props?.box ? EmptyBox : EmptyIcon}
                    className="h-28 m-0 mb-12"
                />
            )}
            {!!props.title?.length && (
                <div className="text-lg font-semibold text-neutral-900">
                    {props.title}
                </div>
            )}
            <div className="text-sm w-96">{text}</div>
            {!!buttonText && !!action && (
                <div className="mt-6">
                    <ButtonSquared
                        onClick={action}
                        type="button"
                        label={buttonText}
                    />
                </div>
            )}
        </div>
    );
}
