import { useEffect } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { changePassword, selectError } from "../../userSlice";
import { useSelector } from "react-redux";
import { ButtonSize, PAGE_SECTIONS } from "../../../../constants";
import { setCurrentSection } from "../../../layout/layoutSlice";
import ButtonSquared from "../../../../components/ButtonSquare";
import Input from "../../../../components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const schema = yup
    .object({
        currentPassword: yup.string().required("Password is required"),
        newPassword: yup.string().required("New password is required"),
        repeatPassword: yup
            .string()
            .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
    })
    .required("This field is required");

export function ChangePassword({ onClose }: any) {
    const dispatch = useAppDispatch();
    const error = useSelector(selectError);
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.MANAGE_ACCOUNT })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePassword = async (data: any) => {
        const currentPassword = data?.currentPassword;
        const newPassword = data?.newPassword;
        const success = await dispatch(
            changePassword(currentPassword, newPassword)
        );
        if (success) {
            reset({
                currentPassword: null,
                newPassword: null,
                repeatPassword: null,
            });
            onClose();
        }
    };

    return (
        <>
            <div className="min-h-full min-w-full px-4 pt-6 sm:px-6 lg:px-8">
                <h1 className="text-center text-2xl font-bold text-neutral-700">
                    Reset Password
                </h1>
                <div className="w-full flex items-start justify-center">
                    <div className="w-80">
                        <form
                            className="mt-8 w-full"
                            onSubmit={handleSubmit(handleChangePassword)}
                        >
                            <div className="mt-6">
                                <Input
                                    register={register}
                                    label="Current password"
                                    //ref={passwordRef}
                                    id="currentPassword"
                                    name="currentPassword"
                                    type="password"
                                    autoComplete="currentPassword"
                                    required
                                    placeholder="Current password"
                                    error={errors["currentPassword"]?.message}
                                />
                            </div>
                            <div className="mt-6">
                                <Input
                                    register={register}
                                    label="New password"
                                    //ref={passwordRef}
                                    id="newPassword"
                                    name="newPassword"
                                    type="password"
                                    autoComplete="none"
                                    required
                                    placeholder="New password"
                                    error={errors["newPassword"]?.message}
                                />
                            </div>
                            <div className="mt-6">
                                <Input
                                    register={register}
                                    label="Repeat password"
                                    //ref={passwordRef}
                                    id="repeatPassword"
                                    name="repeatPassword"
                                    type="password"
                                    autoComplete="none"
                                    required
                                    placeholder="Repeat password"
                                    error={errors["repeatPassword"]?.message}
                                />
                            </div>
                            <div className="pt-12 w-full items-center">
                                <div className="flex flex-row gap-x-6 items-center justify-center">
                                    <ButtonSquared
                                        outlined
                                        label="Cancel"
                                        onClick={() => onClose()}
                                        type="button"
                                        size={ButtonSize.FIT}
                                        className="px-4"
                                    />
                                    <ButtonSquared
                                        label="Change password"
                                        type="submit"
                                        className="px-4"
                                        size={ButtonSize.FIT}
                                    />
                                </div>
                                <p className="text-sm	font-semibold text-alert_red text-left mt-4">
                                    {error}
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
