import { useState } from "react";
import profileImage from "../../assets/images/profile.png";
interface AvatarProps {
    src?: string;
    alt?: string;
    className?: string;
    squared?: boolean;
}

export default function Avatar(props: AvatarProps) {
    const [errorImage, setErrorImage] = useState<any>();
    return (
        <img
            onError={() => {
                setErrorImage(profileImage);
            }}
            className={`inline-block object-cover ${
                props?.squared ? "rounded-lg" : "rounded-full"
            } ring-2 ring-white ${props.className}`}
            alt=""
            src={errorImage || (props.src ? props.src : profileImage)}
        />
    );
}
