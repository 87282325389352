import Icon from "../assets/svg/Icon";
interface IPaginator {
    count: number;
    take: number;
    onChange: Function;
    totalPages: number;
    currentPage: number;
}
export default function Paginator(props: IPaginator) {
    const { onChange, take, totalPages, currentPage } = props;

    const nextPage = () => {
        if (currentPage && totalPages && currentPage < totalPages) {
            const nPage = currentPage + 1;
            onChange(nPage, (currentPage) * take);
        }
    };

    const prevPage = () => {
        if (currentPage && currentPage > 1) {
            const nPage = currentPage - 1;
            onChange(nPage, (nPage - 1) * take);
        }
    };
    return (
        <div className="flex space-x-4 items-center text-[#9E9E9E] w-full justify-end">
            <Icon
                icon={"ChevronLeftIcon"}
                className={`w-7 ${
                  currentPage && currentPage > 1
                        ? "text-primary_orange-900 cursor-pointer"
                        : "text-[#9E9E9E]"
                }`}
                onClick={prevPage}
            />
            <span>{currentPage}</span>
            <span>of</span>
            <span>{totalPages}</span>
            <Icon
                icon={"ChevronRightIcon"}
                className={`w-7 cursor-pointer ${
                  currentPage && totalPages && currentPage < totalPages
                        ? "text-primary_orange-900"
                        : "text-[#9E9E9E]"
                }`}
                onClick={nextPage}
            />
        </div>
    );
}
