/* This example requires Tailwind CSS v2.0+ */
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Button from "../../../components/ButtonSquare";
import { useAuthStateContext } from "../../../context/Auth";
import { getRestaurant, selectedRestaurant } from "../restaurantSlice";


export default function RestaurantDetail() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const restaurant = useAppSelector(selectedRestaurant);
  let { restaurantId } = useParams();

  useEffect(() => {
    if (restaurantId) {
      dispatch(getRestaurant( Number(restaurantId)));
    }
  }, [restaurantId]);

  const handleEditClick = (e: any) => {
    navigate(`/restaurants/${restaurantId}/edit`);
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="min-w-full flex justify-end py-2 px-2">
        <Button label="Edit" onClick={handleEditClick} />
      </div>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 text-left">
          Restaurant Information
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500 text-left">
          Full details.
        </p>
      </div>

      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {restaurant?.name}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Description</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre break-words">
              {restaurant?.description}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Address</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {restaurant?.fullAddress}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Phone</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {restaurant?.phone}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Contact</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {restaurant?.contact}
            </dd>
          </div>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Created At</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {restaurant?.createdAt}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
