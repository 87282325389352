import { useState } from "react";
import ChatMessages from "./ChatMessages";
import UserContactListModal from "./modals/UserContactListModal";
import { useChatForm } from "../hooks/useChat.hook";
import ParticipantsList from "./ParticipantsList";
import { LeftComponent } from "./LeftMenu";

interface props {
    leftComponent?: boolean;
}
//const ParticipantsList: React.FC<ParticipantsListProps> = ({ chat })
export const UserChatsList: React.FC<props> = ({ leftComponent = true }) => {
    const { selectedChat } = useChatForm();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showParticipants, setShowParticipants] = useState(false);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleTitleClick = () => {
        if (selectedChat?.isGroup) setShowParticipants(!showParticipants);
    };

    return (
        <div className="flex w-full max-h-[700px]">
            {!showParticipants && leftComponent && <LeftComponent handleOpenModal={setIsModalOpen} />}
            <div className="flex-1 flex flex-col relative">
                <ChatMessages
                    onTitleClick={() => handleTitleClick()}
                    handleShowParticipants={setShowParticipants}
                />
            </div>
            {showParticipants && (
                <div className="right-0 top-0 bottom-0 w-96 border-l border-gray-200 bg-white">
                    <ParticipantsList chat={selectedChat}  setShowParticipants={setShowParticipants}/>
                </div>
            )}
            {isModalOpen && <UserContactListModal onClose={handleCloseModal} />}
        </div>
    );
};

