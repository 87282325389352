import { createContext, useContext, useState, useMemo } from "react";
import axios from "axios";
import { uploadTempFile } from "../../../../tripSlice";
import { resizeImageFile } from "../../../../../../helpers";

interface IActivityFormContext {
    imagesProgress: any[];
    filesProgress: any[];
    tempFiles: any[];
    tempImages: any[];
    deletedImagesState: any[];
    deletedFilesState: any[];
    imagesPreviews: any[];
    filesPreviews: any[];
    handleImagesAndFilePreview: Function;
    onFileProgress: Function;
    removeTempImage: Function;
    removeTempFile: Function;
    removeProgressImage: Function;
    onImageProgress: Function;
    removeProgressFile: Function;
    onSelectImages: Function;
    onSelectFiles: Function;
    handleFileRemove: Function;
    handleImageRemove: Function;
    fileCounter: number;
}

const ActivityFormContext = createContext<IActivityFormContext>({
    imagesProgress: [],
    filesProgress: [],
    tempFiles: [],
    tempImages: [],
    deletedImagesState: [],
    deletedFilesState: [],
    imagesPreviews: [],
    filesPreviews: [],
    handleImagesAndFilePreview: () => {},
    onFileProgress: () => {},
    removeTempImage: () => {},
    removeTempFile: () => {},
    removeProgressImage: () => {},
    onImageProgress: () => {},
    removeProgressFile: () => {},
    onSelectImages: () => {},
    onSelectFiles: () => {},
    handleFileRemove: () => {},
    handleImageRemove: () => {},
    fileCounter: 0,
});

export default function ActivityFormProvider({ children }: any): JSX.Element {
    const [imagesProgress, setImagesProgress] = useState<any[]>([]);
    const [filesProgress, setFilesProgress] = useState<any[]>([]);
    const [tempFiles, setTempFiles] = useState<any[]>([]);
    const [tempImages, setTempImages] = useState<any[]>([]);
    const [deletedImagesState, setDeletedImagesState] = useState<any[]>([]);
    const [deletedFilesState, setDeletedFilesState] = useState<any[]>([]);
    const [imagesPreviews, setImagesPreviews] = useState<Array<any>>([]);
    const [filesPreviews, setFilesPreviews] = useState<Array<any>>([]);

    const handleImagesAndFilePreview = (images: any, files: any) => {
        const currentImagesNormalized: Array<any> =
            Object.values(images || {}) || [];
        const currentFilesNormalized: Array<any> =
            Object.values(files || {}) || [];

        setImagesPreviews(
            currentImagesNormalized.map((i) => {
                return {
                    name: i.originalName,
                    src: i.url,
                    width: i.width,
                    height: i.height,
                    isCurrent: true,
                };
            })
        );

        setFilesPreviews(
            currentFilesNormalized.map((i) => {
                return {
                    src: i.url,
                    name: i.originalName,
                    isCurrent: true,
                };
            })
        );
    };

    const onImageProgress = (id: number, progress: number) => {
        setImagesProgress((prevImgProgress) => {
            return prevImgProgress.map((img) => {
                return {
                    ...img,
                    progress: id === img.id ? progress : img.progress,
                };
            });
        });
    };

    const onFileProgress = (id: number, progress: number) => {
        setFilesProgress((prevFilePogressArray) => {
            return prevFilePogressArray.map((file) => {
                return {
                    ...file,
                    progress: id === file.id ? progress : file.progress,
                };
            });
        });
    };

    const removeTempImage = (id: number) => {
        let index = tempImages.findIndex((img) => img.id === id);
        URL.revokeObjectURL(tempImages[index].file);
        setTempImages((prevTmpStateArray) => {
            return prevTmpStateArray.filter((img) => img.id !== id);
        });
    };

    const removeTempFile = (id: number) => {
        let index = tempFiles.findIndex((img) => img.id === id);
        URL.revokeObjectURL(tempFiles[index].file);
        setTempFiles((prevTmpStateArray) => {
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const removeProgressImage = (id: number, cancel = false) => {
        setImagesProgress((prevTmpStateArray) => {
            const index = prevTmpStateArray.findIndex((img) => img.id === id);
            if (index === -1) return prevTmpStateArray;
            URL.revokeObjectURL(prevTmpStateArray[index].file);
            cancel && prevTmpStateArray[index].cancelToken.cancel();
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const removeProgressFile = (id: number, cancel = false) => {
        setFilesProgress((prevTmpStateArray) => {
            const index = prevTmpStateArray.findIndex((file) => file.id === id);
            cancel && prevTmpStateArray[index].cancelToken.cancel();
            return prevTmpStateArray.filter((file) => file.id !== id);
        });
    };

    const fileCounter = useMemo(() => {
        return tempFiles.length + tempImages.length;
    }, [tempFiles.length, tempImages.length]);



    const onSelectImages = async (e: any) => {
        if (e.target.files) {
            const filesArray: any[] = Array.from(e.target.files);
            for (const file of filesArray) {
                const imageResized = await resizeImageFile(file);
                const newtempImg = {
                    id: fileCounter,
                    file: imageResized,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };
                setImagesProgress((prevArray) => {
                    return [...prevArray, newtempImg];
                });

                uploadTempFile(
                    imageResized,
                    newtempImg.id,
                    onImageProgress,
                    newtempImg.cancelToken
                ).then((response) => {
                    removeProgressImage(newtempImg.id);
                    setTempImages((prev) => {
                        return [
                            ...prev,
                            {
                                file: URL.createObjectURL(imageResized),
                                path: response,
                                id: newtempImg.id,
                            },
                        ];
                    });
                });
            }

            return;
        }
    };

    const onSelectFiles = (e: any) => {
        if (e.target.files) {
            Array.from(e.target.files).forEach((file: any, index: number) => {
                const newtempFile = {
                    id: fileCounter,
                    file: file,
                    progress: 0,
                    cancelToken: axios.CancelToken.source(),
                };
                setFilesProgress((prevArray) => {
                    return [...prevArray, newtempFile];
                });

                uploadTempFile(
                    e.target.files[index],
                    newtempFile.id,
                    onFileProgress,
                    newtempFile.cancelToken
                ).then((response) => {
                    removeProgressFile(newtempFile.id);
                    setTempFiles((prev) => {
                        return [
                            ...prev,
                            {
                                file: file,
                                path: response,
                                id: newtempFile.id,
                            },
                        ];
                    });
                });
            });

            //     setFilesProgressState(fileProgressArray);

            return;
        }
    };
    const handleImageRemove = (index: number) => {
        setDeletedImagesState([
            ...deletedImagesState,
            imagesPreviews[index].name,
        ]);
        const spliceArray = imagesPreviews;
        spliceArray.splice(index, 1);
        setImagesPreviews(spliceArray);
    };

    const handleFileRemove = (index: number) => {
        setDeletedFilesState([...deletedFilesState, filesPreviews[index].name]);
        const spliceArray = filesPreviews;
        spliceArray.splice(index, 1);

        setFilesPreviews([...spliceArray]);
    };

    return (
        <ActivityFormContext.Provider
            value={{
                imagesProgress,
                filesProgress,
                tempFiles,
                tempImages,
                deletedImagesState,
                deletedFilesState,
                imagesPreviews,
                filesPreviews,
                handleImagesAndFilePreview,
                onFileProgress,
                removeTempImage,
                removeTempFile,
                removeProgressImage,
                onImageProgress,
                removeProgressFile,
                fileCounter,
                onSelectImages,
                onSelectFiles,
                handleFileRemove,
                handleImageRemove,
            }}
        >
            {children}
        </ActivityFormContext.Provider>
    );
}

export const useActivityFormContext = () => useContext(ActivityFormContext);
