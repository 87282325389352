import { useAppSelector } from "../../../../app/hooks";
import List from "../../../../components/List";
import { selectProfile } from "../../../authentication/userSlice";
import { selectedTrip, selectMeOnTrip } from "../../tripSlice";
import TripRequirementsListItem from "./TripRequirementsListItem";

export default function TripRequirementsList({
    requirements,
    handleAddFileClick,
    handleRemoveClick,
    handleViewClick,
    handleNext,
}: any) {
    const { canEdit, suspended } = useAppSelector(selectedTrip) || {};
    const profile = useAppSelector(selectProfile);
    const meOnTrip = useAppSelector(selectMeOnTrip(profile?.email)) || {};
    const { data } = requirements || {};

    const ItemRender = ({ item }: any) => (
        <TripRequirementsListItem
            meOnTrip={meOnTrip}
            canEdit={profile?.isAdmin || (canEdit && !suspended)}
            key={`requirementList_${item?.id}`}
            requirement={item}
            handleViewClick={handleViewClick}
            handleAddFileClick={handleAddFileClick}
            handleRemoveClick={handleRemoveClick}
        />
    );
    return (
        <div>
            {data && (
                <List
                    itemKey={(req: any) => {
                        return `requirementListItem_${req?.id}`;
                    }}
                    items={requirements}
                    handleNext={handleNext}
                    renderItem={ItemRender}
                />
            )}
        </div>
    );
}
