import { useEffect, useMemo, useState } from "react";
import Modal, { Modal_Size } from ".";
import { ButtonSize, PlanType } from "../../constants";
import ButtonSquared from "../ButtonSquare";
import { PlanItem } from "../PlanItem/PlanItem";
import { useDispatch, useSelector } from "react-redux";
import { addSubscriptionToUser } from "../../features/admin/adminSlice";
import { getAvailablePlans } from "../../features/plans/plansSlice";
import Loader from "../Loader";
import _ from "lodash";

export interface ChoosePlanModalProps {
    open?: boolean;
    handleClose: () => void;
    onChangePlan?: (plan?: any) => void;
    handleEvent?: (planId: string) => void;
    title?: string;
    userType: string;
    closeText?: string;
    showDefault?: boolean;
}

export enum VariantPlan {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
}

export const ChoosePlanModal: React.FunctionComponent<ChoosePlanModalProps> = (
    props
) => {
    const {
        open,
        handleClose,
        onChangePlan,
        handleEvent,
        title,
        userType,
        closeText,
        showDefault,
    } = props;

    const [activePlan, setActivePlan] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>();
    const [link, setLink] = useState("");
    const [_plan, _setPlan] = useState<any>({});

    const dispatch = useDispatch();
    const currentPlan = useSelector((state: any) => state.plans.currentPlan);
    const storedPlans = useSelector((state: any) => state?.plans?.plans);
    const fetchPlans = async () => {
        if (open) {
            setIsLoading(true);
            await dispatch(
                getAvailablePlans(
                    PlanType.USER,
                    (userType !== "null" && userType) || undefined
                )
            );
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchPlans();
    }, [open]);

    const plans = useMemo(() => {
        let plansArray =
            storedPlans?.filter((p: any) => showDefault || !p.defaultPlan) ||
            [];
        if (showDefault) {
            plansArray = _.sortBy(storedPlans, (a) => !a.defaultPlan);
        }
        return plansArray;
    }, [showDefault, storedPlans]);

    const handleChangePlan = () => {
        if (_plan?.defaultPlan) {
            return onChangePlan && onChangePlan(_plan);
        }
        if (handleEvent) {
            return handleEvent(_plan?.subscriptionId);
        }
        !link
            ? dispatch(addSubscriptionToUser(_plan.subscriptionId))
            : window.open(link, "_blank");
        onChangePlan && onChangePlan();
    };

    return (
        <Modal
            open={open}
            hideCloseButton
            size={
                (!!plans?.length &&
                    (plans?.length > 1 ? Modal_Size.lg : Modal_Size.small)) ||
                Modal_Size.lg
            }
        >
            <div className="p-2">
                <div className="text-xl text-center font-semibold mb-6">
                    {title || "Choose your plan"}
                </div>
                <div className="flex flex-col lg:flex-row gap-2">
                    {!!isLoading && (
                        <div className="flex flex-row items-center justify-center w-full">
                            <Loader />
                        </div>
                    )}
                    {!isLoading &&
                        plans?.map((plan: any) => {
                            return (
                                <PlanItem
                                    lockedItem={
                                        !plan?.defaultPlan &&
                                        !!currentPlan?.subscriptionId &&
                                        !plan?.data?.offerData?.price
                                    }
                                    key={plan?.id}
                                    offerData={plan?.data.offerData}
                                    myPlanData={
                                        plans?.length === 1 &&
                                        plan?.data.myPlanData
                                    }
                                    checked={activePlan === plan.id}
                                    handleClick={() => {
                                        _setPlan(plan);
                                        setActivePlan(plan.id);
                                        setLink(plan.link);
                                    }}
                                />
                            );
                        })}
                </div>
                {/* <div className="text-center mt-4">
                    <span className="text-sm font-semibold mb-6 whitespace-nowrap text-orange-500">
                        <a
                            href="https://famguru.app/pricing"
                            target="_blank"
                            rel="noreferrer"
                            className="underline-offset-4 text-sm font-semibold mb-6 whitespace-nowrap text-orange-500"
                        >
                            {"I want to know more about Pricing Plans >"}
                        </a>
                    </span>
                </div> */}
                <div className="flex flex-row gap-4 mt-4 w-full justify-end text-right">
                    <ButtonSquared
                        outlined
                        label={closeText || "Back"}
                        onClick={handleClose}
                        className="p-4"
                        size={ButtonSize.FIT}
                    />
                    <ButtonSquared
                        disabled={!activePlan && activePlan !== 0}
                        label="Continue"
                        onClick={handleChangePlan}
                        size={ButtonSize.SMALL}
                    />
                </div>
            </div>
        </Modal>
    );
};
