import { useEffect, useRef } from "react";

export default function useTurnOffOutsideClick(
    initialIsVisible: boolean,
    handleClickOutside: Function
) {
    const ref: any = useRef(null);

    const handleClick = (event: Event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            handleClickOutside();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick, true);
        return () => {
            document.removeEventListener("click", handleClick, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { ref };
}
