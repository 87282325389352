import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    createTrip,
    getTrip,
    getTripHome,
    getTripNotifications,
    selectedTrip,
    updateTrip,
} from "../tripSlice";
import { PAGE_SECTIONS } from "../../../constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setCurrentSection } from "../../layout/layoutSlice";
import TripFormComponent from "./TripFormComponent";
import TripUpdateDatesProvider from "./TripUpdateDatesContext";
import { redirectToAddLibraryElementToTrip } from "../../organizations/organizationSlice";

export default function TripForm() {
    const [isUpdate, setIsUpdate] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const trip = useAppSelector(selectedTrip);
    let { tripId } = useParams();
    const location: any = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const organizationId = Number(searchParams.get("oId"));
    const fromLibrary = searchParams.get("fromLibrary") === "true";

    useEffect(() => {
        const isUpd = !!trip?.id;
        setIsUpdate(isUpd);
        if (isUpd)
            dispatch(
                setCurrentSection({ currentSection: PAGE_SECTIONS.TRIP_EDIT })
            );
        else
            dispatch(
                setCurrentSection({ currentSection: PAGE_SECTIONS.TRIP_NEW })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip?.id]);

    const handleSubmit = async (data: any) => {
        try {
            let new_trip;
            if (isUpdate) {
                await dispatch(updateTrip(Number(tripId), data));
            } else {
                new_trip = await dispatch(createTrip(data));
            }
            goBack(new_trip?.id);
        } catch (error) {}
    };

    const goBack = (_tripId?: number) => {
        if (isUpdate || _tripId) {
            dispatch(getTrip(Number(tripId || _tripId), true));
            dispatch(getTripHome(Number(tripId || _tripId), false));
            dispatch(getTripNotifications(Number(tripId || _tripId), 5, 0));
            dispatch(setCurrentSection({ currentSection: PAGE_SECTIONS.TRIP }));

            if (fromLibrary) {
                dispatch(
                    redirectToAddLibraryElementToTrip(
                        Number(tripId || _tripId),
                        navigate
                    )
                );
                return;
            }

            navigate(`/trips/${tripId || _tripId}`);
        } else {
            navigate(`/trips`);
        }
    };

    return (
        <TripUpdateDatesProvider>
            <TripFormComponent
                trip={trip}
                fromLibrary={fromLibrary}
                organizationId={organizationId}
                onSubmit={handleSubmit}
                onCancel={goBack}
            />
        </TripUpdateDatesProvider>
    );
}
