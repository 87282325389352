import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    getTripCustomInvite,
    selectedTripId,
} from "../../tripSlice";
import { PAGE_SECTIONS } from "../../../../constants";
import { setCurrentSection } from "../../../layout/layoutSlice";
import { Outlet } from "react-router";
const TripCustomInvite = (props: any) => {
    const tripId = useAppSelector(selectedTripId);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_CUSTOM_INVITE,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tripId) dispatch(getTripCustomInvite(Number(tripId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripId]);

    return (
        <Outlet />
    );
};

export default TripCustomInvite;
