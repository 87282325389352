import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    addUsersToOrganization,
    getOrganizationMembers,
    removeUserFromOrganization,
    resendOrganizationUserInvitation,
    selectedOrganization,
    selectedOrganizationUsers,
    updateUserRoleOnOrganization,
} from "../organizationSlice";
import SelectInput from "../../../components/InputSelect/SelectInput";
import {
    ButtonSize,
    OrganizationUserRoleOptions,
    ORG_USERS_LIST_OPTIONS_KEYS,
    PAGE_ROWS,
    userRoleOrganizationFilterOptions,
    userStatusOrganizationFilterOptions,
    PAGE_SECTIONS,
} from "../../../constants";
import { useEffect, useState } from "react";
import InputSearch from "../../../components/InputSearch";
import ButtonSquared from "../../../components/ButtonSquare";
import Modal from "../../../components/Modal";
import ModalConfirmation from "../../../components/Modal/ConfirmationModal";
import AddUsersForm from "../../../components/AddUsersForm";
import OrganizationUsersTable from "./OrganizationUsersTable";
import {
    selectCurrentBreakpoint,
    setCurrentSection,
} from "../../layout/layoutSlice";
import OrganizationUsersList from "./OrganizationUsersList";
import { useNavigate } from "react-router";
import { selectProfile } from "../../authentication/userSlice";

export default function OrganizationUsers(props: any) {
    const [search, setSearch] = useState("");
    const [newPage, setNewPage] = useState<any>({});
    const [status, setStatus] = useState("");
    const [role, setRole] = useState("");
    const [addUserModalOpen, setAddUserOrgModalOpen] = useState(false);
    const [changeRoleModalOpen, setChangeRoleModalOpen] = useState(false);
    const [makeOwnerConfirmationModal, setMakeOwnerConfirmationModal] =
        useState(false);
    const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [newRole, setNewRole] = useState<any>(null);
    const orgUsers = useAppSelector(selectedOrganizationUsers);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    const organization = useAppSelector(selectedOrganization);
    const profile = useAppSelector(selectProfile);

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.ORGANIZATION_MEMBERS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);

    const handleGetMembers = () => {
        getMembers(
            PAGE_ROWS,
            newPage.skip,
            newPage.currentPage,
            search,
            status,
            role
        );
    };

    useEffect(() => {
        handleGetMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        newPage.skip,
        search,
        organization?.id,
        status,
        role,
        changeRoleModalOpen,
        makeOwnerConfirmationModal,
    ]);

    const handleAddUserClick = (e: any) => {
        if (handleAddUser) handleAddUser();
    };

    const getMembers = (
        take: number,
        newSkip: number,
        newPage: number,
        search: string,
        status: any,
        role: any
    ) => {
        if (organization?.id) {
            dispatch(
                getOrganizationMembers(
                    organization.id,
                    take,
                    newSkip,
                    newPage,
                    search,
                    status,
                    role,
                    organization?.canEdit
                )
            );
        }
    };

    const handleUserRoleChange = async (role: any, email: string) => {
        setChangeRoleModalOpen(false);
        await dispatch(
            updateUserRoleOnOrganization(Number(organization.id), email, role)
        );
        handleGetMembers();
    };
    const handleMakeOwner = (role: any, email: string) => {
        dispatch(
            updateUserRoleOnOrganization(
                Number(organization.id),
                email,
                role,
                handleGetMembers
            )
        );
        setMakeOwnerConfirmationModal(false);
    };
    const handleRemoveUserModal = (user: any) => {
        setSelectedUser(user);
        setDeleteUserModalOpen(true);
    };

    const handleCloseRemoveUserModal = () => {
        setSelectedUser(null);
        setDeleteUserModalOpen(false);
    };
    const handleRemoveUserFromOrg = () => {
        dispatch(
            removeUserFromOrganization(
                Number(organization.id),
                selectedUser?.userEmail
            )
        );
        setDeleteUserModalOpen(false);
        //getMembers(PAGE_ROWS, skip, currentPage, search, status, role);
    };

    const handlePaginatorChange = (newPage: number, skip: number) => {
        setNewPage({
            skip: skip,
            currentPage: newPage,
        });
    };

    const handleSearchChange = (value: string) => {
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
        setSearch(value);
    };

    const handleRoleFilterChange = (value: string) => {
        const formattedValue = value === "ALL" ? "" : value;
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
        setRole(formattedValue);
    };
    const handleStatusFilterChange = (value: string) => {
        const formattedValue = value === "ALL" ? "" : value;
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
        setStatus(formattedValue);
    };

    const handleAddUser = () => {
        setAddUserOrgModalOpen(true);
    };

    const handleModalClose = () => {
        setAddUserOrgModalOpen(false);
        setChangeRoleModalOpen(false);
    };

    const handleFinishAdd = async (users: any) => {
        await dispatch(
            addUsersToOrganization(Number(organization?.id), users as any)
        );
        handleModalClose();
        setNewPage({
            skip: 0,
            currentPage: 1,
        });
    };

    const handleResendInvite = (userEmail: string) => {
        dispatch(resendOrganizationUserInvitation(organization.id, userEmail));
    };

    const handleClickUserDotsMenu = (optionId: string, user: any) => {
        setSelectedUser(user);
        setNewRole(user?.role);
        switch (optionId) {
            case ORG_USERS_LIST_OPTIONS_KEYS.REMOVE_USER:
                handleRemoveUserModal(user);
                break;
            case ORG_USERS_LIST_OPTIONS_KEYS.RESEND_INVITE:
                handleResendInvite(user?.userEmail);
                break;

            case ORG_USERS_LIST_OPTIONS_KEYS.CHANGE_ROLE:
                setChangeRoleModalOpen(true);
                break;
            case ORG_USERS_LIST_OPTIONS_KEYS.MAKE_OWNER:
                setMakeOwnerConfirmationModal(true);
                break;

            default:
                break;
        }
        return;
    };

    const handleViewUser = (user: any) => {
        if (user) {
            navigate(`${user.id}`);
        }
    };

    const renderAddButton = () => {
        return (
            (organization?.canEdit || profile?.isAdmin) && (
                <div className="col-span-1 md:col-span-2 w-full flex justify-end">
                    <ButtonSquared
                        size={ButtonSize.SMALL}
                        label={"Invite Members"}
                        onClick={handleAddUserClick}
                    />
                </div>
            )
        );
    };

    return (
        <>
            <ModalConfirmation
                open={deleteUserModalOpen}
                title="Are you sure?"
                description={`You are about to remove ${selectedUser?.userEmail}`}
                handleCancel={handleCloseRemoveUserModal}
                handleSubmit={handleRemoveUserFromOrg}
            />
            <Modal open={addUserModalOpen} onClose={handleModalClose}>
                <AddUsersForm
                    formType="organization"
                    handleClose={handleModalClose}
                    handleFinishAdd={handleFinishAdd}
                />
            </Modal>
            <Modal
                hideCloseButton
                open={changeRoleModalOpen}
                onClose={handleModalClose}
            >
                <div className="flex flex-col m-2">
                    <div className="font-semibold mb-2">Change role</div>
                    <div className="text-neutral-600 text-sm font-semibold">
                        Choose a new role for this member.
                    </div>
                    <div className="flex flex-row w-full items-center">
                        <div className="text-neutral-900 text-sm font-semibold flex-1">
                            {selectedUser?.user?.firstName}{" "}
                            {selectedUser?.user?.lastName}
                        </div>
                        {selectedUser && (
                            <SelectInput
                                onChange={(value: any) => {
                                    setNewRole(value);
                                }}
                                defaultValue={newRole}
                                options={OrganizationUserRoleOptions}
                            />
                        )}
                    </div>

                    <div className="text-neutral-500 text-sm font-semibold">
                        {selectedUser?.userEmail}
                    </div>

                    <div className="flex flx-row gap-4 justify-end mt-4">
                        <ButtonSquared
                            outlined
                            size={ButtonSize.SMALL}
                            label={"Cancel"}
                            onClick={handleModalClose}
                        />
                        <ButtonSquared
                            label={"Confirm"}
                            size={ButtonSize.SMALL}
                            onClick={() =>
                                handleUserRoleChange(
                                    newRole,
                                    selectedUser?.userEmail
                                )
                            }
                        />
                    </div>
                </div>
            </Modal>
            <ModalConfirmation
                open={makeOwnerConfirmationModal}
                title="Are you sure?"
                description={`You are about to transfer the ownership of this organization to ${selectedUser?.userEmail}. Your role will be changed to Admin.`}
                handleCancel={() => setMakeOwnerConfirmationModal(false)}
                handleSubmit={() =>
                    handleMakeOwner("OWNER", selectedUser?.userEmail)
                }
                okButtonText={"Confirm"}
            />
            <div className="grid grid-cols-1 md:grid-cols-6 items-start gap-5 md:gap-10 mt-6 mb-12">
                {!isDesktop && renderAddButton()}
                <div className="col-span-1 md:col-span-2 mt-4">
                    <InputSearch
                        onChange={handleSearchChange}
                        name="search"
                        id="search"
                        label=" "
                        placeholder="Search"
                    />
                </div>
                <div className="col-span-1 md:col-span-1">
                    <SelectInput
                        onChange={handleStatusFilterChange}
                        label="Status"
                        options={userStatusOrganizationFilterOptions}
                        defaultValue={
                            userStatusOrganizationFilterOptions[0].value
                        }
                    />
                </div>
                <div className="col-span-1 md:col-span-1">
                    <SelectInput
                        label="Role"
                        onChange={handleRoleFilterChange}
                        options={userRoleOrganizationFilterOptions}
                        defaultValue={
                            userRoleOrganizationFilterOptions[0].value
                        }
                    />
                </div>
                {isDesktop && renderAddButton()}
            </div>
            {isDesktop ? (
                <OrganizationUsersTable
                    users={orgUsers}
                    handleClickUserDotsMenu={handleClickUserDotsMenu}
                    handlePaginatorChange={handlePaginatorChange}
                    handleViewUser={handleViewUser}
                />
            ) : (
                <OrganizationUsersList
                    users={orgUsers}
                    handleClickUserDotsMenu={handleClickUserDotsMenu}
                    handlePaginatorChange={handlePaginatorChange}
                    canEdit={organization?.canEdit}
                />
            )}
        </>
    );
}
