import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    resetPassword,
    selectEmailSent,
    selectError,
    setEmailSent,
    setError,
} from "../userSlice";
import { Link } from "react-router-dom";
import Input from "../../../components/Input";
import ButtonSquared from "../../../components/ButtonSquare";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const schema = yup
    .object({
        email: yup.string().email("Invalid email format").required("Required"),
    })
    .required("This field is required");

export function ResetPassword() {
    const dispatch = useAppDispatch();
    const error = useAppSelector(selectError);
    const emailSent = useAppSelector(selectEmailSent);

    useEffect(() => {
        return () => {
            dispatch(setEmailSent({ emailSent: false }));
            dispatch(setError({ error: "" }));
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const handleReset = async (data: any) => {
        const email = data?.email;
        await dispatch(resetPassword(email));
    };

    return (
        <>
            <div className="min-h-full flex items-center justify-center px-4 pt-6 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <h1 className="text-center text-4xl font-bold text-neutral-700">
                        Reset Password
                    </h1>
                    {!emailSent && (
                        <>
                            <p>
                                Please enter your email address and we will send
                                you a reset link.
                            </p>
                            <form
                                className="mt-8 space-y-8"
                                onSubmit={handleSubmit(handleReset)}
                            >
                                <div className="pt-6">
                                    <Input
                                        register={register}
                                        id="email-address"
                                        name="email"
                                        type="text"
                                        autoComplete="email"
                                        label="Email Address"
                                        error={errors["email"]?.message}
                                    />
                                </div>

                                <ButtonSquared label="Send" type="submit" />
                                <p className="text-sm	font-semibold text-alert_red text-left">
                                    {error}
                                </p>

                                <p className="mt-2 text-center text-sm text-gray-600">
                                    Or <Link to="/signin">Back to signin</Link>
                                </p>
                            </form>
                        </>
                    )}
                    {emailSent && (
                        <div className="pt-12 text-base text-orange-600 font-semibold">
                            <p>A reset link was sent to you email address.</p>
                            <p className="mt-12 text-center text-sm text-gray-600">
                                <Link to="/signin"><ButtonSquared label="back to sign in" /></Link>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
