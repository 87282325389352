import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
    getTripRequirementUsers,
    selectedTrip,
    selectedTripRequirement,
    selectedTripRequirementUsers,
} from "../../../tripSlice";
import {
    selectCurrentBreakpoint,
    setCurrentSection,
} from "../../../../layout/layoutSlice";
import {
    ButtonSize,
    PAGE_SECTIONS,
} from "../../../../../constants";
import moment from "moment";
import ButtonSquared from "../../../../../components/ButtonSquare";
import { DATE_FORMAT, getStatusFromFiles } from "../../../../../helpers";
import Modal from "../../../../../components/Modal";
import DocumentRequirementsModal from "../../TripUsers/Participant/RequirementFiles/DocumentRequirementsModal";
import RequirementUsersTable from "./RequirementUserTable";
import RequirementUsersList from "./RequirementUserList";
import FilePreview from "../../../../../components/DropFileInput/FilePreview";
import { Paragraph } from "../../../../../components/Paragraph/Paragraph";

export default function TripRequirementAdmin() {
    let { tripId } = useParams();
    const requirement = useAppSelector(selectedTripRequirement);
    const participants = useAppSelector(selectedTripRequirementUsers());
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openRequirementModal, setOpenRequirementModal] =
        useState<boolean>(false);
    const [selectedUserReqData, setSelectedUserReqData] = useState<any>();
    const { isDesktop } = useAppSelector(selectCurrentBreakpoint);
    const onEdit = () => {
        navigate("edit");
    };

    const onUserClick = (user: any) => {
        setSelectedUserReqData({
            ...requirement,
            files: user?.requirementFiles || [],
            status: getStatusFromFiles(user?.requirementFiles || []),
            userEmail: user?.userEmail,
        });
        setOpenRequirementModal(true);
    };

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_REQUIREMENTS,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //TODO: move efects to parent OUTLET
    useEffect(() => {
        if (requirement?.id && tripId) {
            getUsersData(Number(tripId), Number(requirement?.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripId, requirement?.id]);

    const getUsersData = (tripId: number, requirementId: number) => {
        dispatch(getTripRequirementUsers(tripId, requirementId));
    };

    const onUpdateRequirement = () => {
        getUsersData(Number(tripId), Number(requirement?.id));
        setOpenRequirementModal(false);
    };

    const filesPreviews = useMemo(() => {
        return Object.values(requirement?.requirementIncludedFiles || {});
    }, [requirement?.requirementIncludedFiles]);

    const canEdit = trip?.canEdit;
    return (
        <>
            {canEdit && (
                <Modal
                    open={openRequirementModal}
                    onClose={() => {
                        setOpenRequirementModal(false);
                    }}
                >
                    <DocumentRequirementsModal
                        onUpdate={onUpdateRequirement}
                        requirement={selectedUserReqData}
                    />
                </Modal>
            )}

            {!!requirement?.name && (
                <div className="flex flex-wrap">
                    <div className="flex flex-col flex-grow">
                        <div className="text-left font-semibold text-lg">
                            {requirement?.name} -{" "}
                            {moment
                                .utc(requirement?.deadline)
                                .format(DATE_FORMAT)}
                        </div>
                    </div>
                    <div>
                        {canEdit && (
                            <ButtonSquared
                                size={ButtonSize.SMALL}
                                outlined={true}
                                onClick={onEdit}
                                label="Edit"
                            />
                        )}
                    </div>
                </div>
            )}
            <Paragraph className="text-left">
                {requirement?.description}
            </Paragraph>
            <div className="flex flex-wrap gap-4 mt-4">
                {filesPreviews.map((file: any) => {
                    return (
                        <a
                            className="block cursor-pointer"
                            key={`file_${file?.originalName}`}
                            href={file.url}
                            target="_blank"
                            rel="noreferrer"
                            download={true}
                        >
                            <FilePreview viewOnly name={file?.originalName} />
                        </a>
                    );
                })}
            </div>

            {!!requirement &&
            (!participants || participants?.length > 0) &&
            canEdit ? (
                <div className="mt-6">
                    {isDesktop ? (
                        <RequirementUsersTable
                            participants={participants}
                            onUserClick={onUserClick}
                        />
                    ) : (
                        <RequirementUsersList
                            participants={participants}
                            onUserClick={onUserClick}
                        />
                    )}
                </div>
            ) : null}
        </>
    );
}
