import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useEffect } from "react";
import { setCurrentSection } from "../../../layout/layoutSlice";
import { PAGE_ROWS, PAGE_SECTIONS } from "../../../../constants";
import { selectedTrip } from "../../tripSlice";
import { getTripMessages } from "./messagesSlice";
import TripMessagesTable from "./TripMessagesTable";

export default function TripMessages({
    handlePaginatorChange,
    newPage,
    messages,
}: any) {
    const trip = useAppSelector(selectedTrip);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            setCurrentSection({
                currentSection: PAGE_SECTIONS.TRIP_MESSAGES,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (trip) {
            dispatch(getTripMessages(trip.id, "", PAGE_ROWS, newPage?.skip));
        }
    }, [newPage.skip, trip, dispatch]);

    return (
        <div>
            <TripMessagesTable
                messages={messages}
                handlePaginatorChange={handlePaginatorChange}
                canEdit={false}
            />
        </div>
    );
}
