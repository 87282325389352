import { useEffect, useMemo, useState } from "react";
import { getComplaintItem } from "../../features/admin/adminSlice";
import { normalizeReviews } from "../../helpers";
import Loader from "../Loader";
import ReviewDetails from "../Reviews/ReviewDetails";
import { ComplaintType } from "../../constants";
import { EmailChangeViewComponent } from "./components/EmailChangeViewComponent";

interface IComplaintReplyForm {
    complaint: any;
    setValue?: any;
}

export default function ComplaintItem(props: IComplaintReplyForm) {
    const { complaint } = props;
    const [item, setItem] = useState<any>();
    const [loading, setLoading] = useState<boolean>();
    useEffect(() => {
        if (complaint) {
            getItem();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complaint]);

    const getItem = async () => {
        if (complaint.itemType === ComplaintType.REVIEW) {
            setLoading(true);
            try {
                const data = await getComplaintItem(complaint);
                setItem(data);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
    };

    const itemRendered = useMemo(() => {
        switch (complaint.itemType) {
            case ComplaintType.REVIEW:
                return item ? (
                    <ReviewDetails
                        review={normalizeReviews(item)}
                        expandedDefault
                    />
                ) : null;
            case ComplaintType.USER_EMAIL_CHANGE:
                return (
                    <EmailChangeViewComponent email={complaint?.data?.email} />
                );
        }
    }, [item]);

    if (!loading)
        return (
            <div className="flex flex-col text-left p-4 border border-neutral-200 rounded-md">
                {itemRendered}
                <div className="flex flex-row justify-end text-xs">
                    {item?.user?.firstName} {item?.user?.lastName}
                </div>
                <div className="flex flex-row justify-end text-xs">
                    {item?.user?.email}
                </div>
            </div>
        );
    else
        return (
            <div className="flex flex-row flex-grow justify-center">
                <Loader />
            </div>
        );
}
