import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PAGE_SECTIONS } from "../../../constants";
import { setCurrentSection } from "../../layout/layoutSlice";
import { getOrganization, setSelectedOrganization } from "../organizationSlice";
import { Outlet, useParams } from "react-router";
import { selectProfile } from "../../authentication/userSlice";

export default function OrganizationHome(props: any) {
    const dispatch = useAppDispatch();
    const { organizationId } = useParams();
    const { email } = useAppSelector(selectProfile);
    useEffect(() => {
        dispatch(
            setCurrentSection({ currentSection: PAGE_SECTIONS.ORGANIZATIONS })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (email) dispatch(getOrganization(Number(organizationId)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            dispatch(setSelectedOrganization({ selectedOrganization: {} }));
        };
    }, [organizationId, email]);

    return <Outlet />;
}
