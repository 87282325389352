import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    ButtonSize,
    COMMA_SEPARATED_EMAILS_REGEX,
    OrganizationUserRoleOptions,
    TripUserRoleOptionsWithoutOwner,
    UserRoleOnOrganization,
    UserRoleOnSharedInsight,
    UserRoleOnTrip,
} from "../../constants";
import SelectInput from "../InputSelect/SelectInput";
import Button from "../ButtonSquare";
import Input from "../Input";
import Icon from "../../assets/svg/Icon";

export interface IUser {
    email: string;
    role: UserRoleOnOrganization | UserRoleOnTrip | UserRoleOnSharedInsight;
}

const schema = yup
    .object({
        members: yup
            .string()
            .matches(COMMA_SEPARATED_EMAILS_REGEX, "Is not in correct format")
            .notRequired()
            .nullable(),
    })
    .notRequired();

export default function AddUsersForm(props: any) {
    const [users, setUsers] = useState<Array<IUser> | null>(null);

    const {
        handleClose,
        handleFinishAdd,
        formType,
        hideRole,
        title,
        disclaimer,
    } = props;
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const handleSubmitForm = async (event: any) => {
        event.preventDefault();
        if (users && users.length > 0) {
            handleFinishAdd(users);
        }
    };
    const removeDuplicated = (users: Array<IUser>) => {
        return [
            ...new Map(users.map((item) => [item["email"], item])).values(),
        ];
    };
    const getEmailsFromCommaSeparatedString = (value: string) => {
        return value
            ?.trim()
            .replace(/^\s+|\s+$/gm, "")
            .split(",")
            .map((email) => email.trim().toLowerCase())
            .filter((u: any) => u && u.length > 0);
    };

    const addUserToPreliminaryList = (emails: Array<string>) => {
        const newUsers = emails.map((em: string) => {
            return {
                email: em.trim(),
                role: UserRoleOnOrganization.MEMBER,
            };
        });
        let finalU = users ? [...users] : [];
        setUsers(removeDuplicated(finalU.concat(newUsers)));
        return;
    };

    const removeUserFromPreliminaryList = (email: string) => {
        const newUsers = users ? users?.filter((u) => u.email !== email) : [];
        return setUsers(newUsers);
    };
    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            const value = event.target.value;
            event.preventDefault();
            addUser(value);
        }
    };

    const addUser = (newVal?: string) => {
        if (!newVal) return;

        const value = newVal;
        const emailsArray = getEmailsFromCommaSeparatedString(value);

        if (value && !errors.members) {
            addUserToPreliminaryList(emailsArray);
            setValue("email", null);
            return;
        }
    };

    const handleOnchangeRole = (role: any, email: string) => {
        const newRole = role;
        const newUsers = users
            ? users?.map((u) => {
                  return u.email === email
                      ? {
                            email: u.email,
                            role: newRole,
                        }
                      : u;
              })
            : [];
        setUsers(newUsers);
    };
    const handleAddUserClick = () => {
        const { members } = getValues();
        addUser(members);
    };

    return (
        <>
            <div className="w-full p-2 lg:p-4">
                <h1 className="text-neutral-900 text-lg font-semibold">
                    {title || "Add Participant"}
                </h1>
                <div className="w-full">
                    <form
                        autoComplete="off"
                        action="#"
                        method="POST"
                        onSubmit={handleSubmitForm}
                    >
                        <div className="w-full flex mt-3 mb-12 gap-4">
                            <div className="w-full lg:w-auto lg:flex-grow">
                                <Input
                                    role="presentation"
                                    autoComplete="new-password"
                                    className="lowercase w-full"
                                    onKeyPress={handleKeyPress}
                                    register={register}
                                    label=""
                                    type="text"
                                    id="email"
                                    name="members"
                                    placeholder="example@example.com,example2@example2.com"
                                    error={errors["email"]?.message}
                                    instructions={
                                        "Please type the emails separated by comma and press 'Add'"
                                    }
                                />
                            </div>

                            <Button
                                className="max-w-fit p-4 h-fit"
                                type="button"
                                label="Add"
                                onClick={handleAddUserClick}
                            />
                        </div>

                        <div className="min-w-full mb-6">
                            {users &&
                                users.map((u: any) => {
                                    return (
                                        <div
                                            key={`${u.email}${
                                                !hideRole ? " - " + u.role : ""
                                            }`}
                                            className="grid grid-cols-6 mt-6 gap-3 text-left items-center"
                                        >
                                            <div className="col-span-3">
                                                <span className="font-normal text-sm text-gray-900 text-left">
                                                    {u.email}
                                                </span>
                                            </div>

                                            {!hideRole && (
                                                <div className="col-span-2">
                                                    <SelectInput
                                                        label=""
                                                        onChange={(role: any) =>
                                                            handleOnchangeRole(
                                                                role,
                                                                u.email
                                                            )
                                                        }
                                                        defaultValue={u.role}
                                                        options={
                                                            formType ===
                                                            "organization"
                                                                ? OrganizationUserRoleOptions
                                                                : TripUserRoleOptionsWithoutOwner
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <div className="col-span-1 w-full flex justify-end">
                                                <Icon
                                                    tabIndex={0}
                                                    icon={"TrashIcon"}
                                                    className="w-5 h-5 cursor-pointer"
                                                    onClick={(e) =>
                                                        removeUserFromPreliminaryList(
                                                            u.email
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        {users?.length && (
                            <div className="mb-2 text-xs font-thin text-neutral-800">
                                {disclaimer ||
                                    `*By adding participants, they will
                                automatically receive an email invitation to
                                sign up for FamGuru and accept your trip. Please
                                be sure you are ready for them to
                                receive the invitation before you add them here.`}
                            </div>
                        )}
                        <div className=" text-right gap-6 flex w-full justify-end">
                            <Button
                                size={ButtonSize.SMALL}
                                outlined
                                label="Cancel"
                                type="button"
                                onClick={handleClose}
                            />
                            <Button
                                size={ButtonSize.SMALL}
                                type="submit"
                                label="Save"
                                disabled={!users || users?.length === 0}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
